import api from '../utils/api'
import { setAlert } from './alert'
import {
    GET_LOYALTIES,
    LOYALTY_ERROR,
    ADD_LOYALTY,
    DELETE_LOYALTY,
    GET_YARD_FORMEN
} from './types'

// loyalty plans grouped
export const getLoyaltyGroup = () => async (dispatch) => {
    try {
        const { data } = await api.get('/grouployalty?foreman=true')

        const lealtad = data.map(plan => {
            return {
                seller: plan._id.seller,
                foreman: plan._id.foreman,
                foremanName: plan._id.foremanName,
                month: plan._id.month,
                year: plan._id.year,
                yards: plan.yards
            }
        })
        dispatch({
            type: GET_LOYALTIES,
            payload: lealtad
        })
    } catch (err) {
        dispatch({
            type: LOYALTY_ERROR,
            payload:  { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Create new loyalty
export const createLoyalty = (formData) => async (dispatch) => {
    try {
        const { data } = await api.post('/loyalty', formData)        
        
        dispatch({
            type: ADD_LOYALTY,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: LOYALTY_ERROR,
            payload:  { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// delete loyalty plan to foreman
export const deleteFormanLoyalty = formanId => async(dispatch) => {
    try {
        const { data } = await api.delete(`/deleteforeman/${formanId}`)
        dispatch({
            type: DELETE_LOYALTY,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: LOYALTY_ERROR,
            payload:  { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get yards sold grouped by foreman
export const getLoyaltyYards = () => async (dispatch) => {
    try {
        const { data } = await api.get('jobgroupforeman')
        const yardas = data.map(yd => {
            return {
                seller: yd._id.seller,
                year: yd._id.year,
                month: yd._id.month,
                yards: yd.yards,
                foreman: yd._id.foreman ? yd._id.foreman : ''
            }
        })
        dispatch({
            type: GET_YARD_FORMEN,
            payload: yardas
        })
    } catch (err) {
        dispatch({
            type: LOYALTY_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}