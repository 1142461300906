import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'

var QRCode = require('qrcode.react')

const DriverQr = props => {
    useEffect(() => {
        getDriver()
    }, [])

    const [name, setName] = useState('')
    const [id, setId] = useState(null)

    const getDriver = async () => {
        const url = api + '/driver/get/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            console.log(data)
            setName(data.name)
            setId(data._id)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    return (
        <Layout
            title='Driver'
            description='QR Code'
            className="container col-md-8 offset-md-2"
        >
            <h3>Driver: {name} </h3>
            {
                id &&
                <QRCode size={400} value={`${id}`} />
            }
        </Layout>
    )
}

export default DriverQr