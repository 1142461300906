import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DataGrid from 'react-data-grid';
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { CSVLink } from "react-csv"
import { api } from '../utils/Api'
import formatDate from '../utils/FormatDate'
import { toCurrency } from '../utils/Convert'


const TopLocalities = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [dateChange, setDateChange] = useState(true)
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(10)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [sortColumns, setSortColumns] = useState([{ key: 'jobs', direction: 'ASC' }, { key: 'yards', direction: 'ASC' }, { key: 'amount', direction: 'ASC' }]);

    const columna = [
        { key: 'locality', name: 'Locality', resizable: true, },
        { key: 'jobs', name: 'Job Count', resizable: true, sortable: true, },
        { key: 'yards', name: 'Yards', resizable: true, sortable: true, },
        { key: 'amount', name: 'Amount ($)', resizable: true, sortable: true, },
    ]

    const headers = () => {
        return columna.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    useEffect(() => {
        let fechaIni = formatDate(startDate)
        let fechaFin = formatDate(endDate)

        if (endDate < startDate) {
            setEndDate(startDate)
            fechaFin = formatDate(startDate)
        }

        if (dateChange) {
            getLocalities(fechaIni, fechaFin)
        }

    }, [startDate, endDate])

    const getLocalities = async (startDate, endDate) => {
        const url = api + `/joblocation?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            //console.log(data)
            getTotals(data)
            setColumns(columna)
            setLoading(false)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getTotals = (jobs) => {

        let list = [] // list of object by locality, yd3, amount

        jobs.forEach((job, i) => {

            let loadYD3 = job.loadYD3 ? job.loadYD3 : 0
            let amount = job.unitPrice ? job.unitPrice : 0
            let locality = job.locality ? job.locality : "NA"

            if (list.some(l => l.locality === locality)) {
                let index = list.findIndex(l => { return l.locality === locality })
                list[index].yards += loadYD3
                list[index].amount += amount * loadYD3
                list[index].jobs += 1
            } else {
                list.push({ locality: locality, jobs: 1, yards: loadYD3, amount: amount })
            }
        })

        // Sort list by Yards (DESC)
        list.sort((a, b) => (a.yards < b.yards) ? 1 : -1)

        // Add currency and separate yards by comma
        list.forEach((job) => {
            //job.amount = job.amount * job.yards
            job.amount = toCurrency(job.amount)
            job.yards = job.yards.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })

        // Used to modify in case of limit change
        setOriginalData(list)

        // Set limited list by 10
        let listLimit = (limit >= 0 ? (limit <= list.length ? limit : list.length) : 0)

        setRows(list.slice(0, listLimit))
    }



    const changeLimit = (x) => {
        setLimit(x)

        // 0 -> limit <- list.length
        let listLimit = (x > 0 ? (x <= originalData.length ? x : originalData.length) : 1)

        setRows(originalData.slice(0, listLimit))
    }

    const ReportParameters = () => (
        <div>
            <div className="form-group row">
                <div className='col-sm-4'>
                    <label className='text-muted'>Start Date</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date)
                            setDateChange(true)
                        }}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>End Date</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(date)
                            setDateChange(true)
                        }}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Limit</label>
                    <input
                        onChange={(e) => changeLimit(e.target.value)}
                        type="number"
                        value={limit}
                        name='limit'
                    />
                </div>
            </div>
        </div>
    )

    const Grid = () => {
        const sortRows = (initialRows, sortColumn) => rows => {

            const key = sortColumn[0].columnKey
            const direction = sortColumn[0].direction
            let index = sortColumns.findIndex(l => { return l.key === key })

            // Use sortColumns to manage the ASC or DESC
            const columnDir = sortColumns[index].direction

            const comparer = (a, b) => {
                //                              remove ',' from yards                                       remove '$' and ',' from amount
                const l = key === 'yards' ? parseFloat(a[key].replace(/,/g, '')) : (key === 'amount' ? parseFloat(a[key].replace(/\$/g, '').replace(/,/g, '')) : a[key])
                const r = key === 'yards' ? parseFloat(b[key].replace(/,/g, '')) : (key === 'amount' ? parseFloat(b[key].replace(/\$/g, '').replace(/,/g, '')) : b[key])

                if (columnDir === "ASC") {
                    return l > r ? 1 : -1;
                } else if (columnDir === "DESC") {
                    return l < r ? 1 : -1;
                }
            }

            // Used for updating the ASC and DESC 
            const setNewDirection = (newDirection) => {
                const newArr = sortColumns.map(obj => {
                    if (obj.key === key) {
                        return { ...obj, direction: newDirection };
                    }
                    return obj;
                })
                setSortColumns(newArr)
            }

            if (columnDir === "ASC") {
                setNewDirection("DESC")
            } else if (columnDir === "DESC") {
                setNewDirection("ASC")
            }

            // Uses all of original data to sort, used to limit the sorted data  
            let listLimit = (limit > 0 ? (limit <= originalData.length ? limit : originalData.length) : 1)

            // returns either original values or sorted OriginalData with limit
            return direction === "NONE" ? initialRows : [...originalData].sort(comparer).slice(0, listLimit)
        }

        return (
            <DataGrid
                className='rdg-light'
                columns={columna}
                rows={rows}
                rowGetter={i => rows[i]}
                rowsCount={rows.length ? rows.length : 0}
                sortColumn={sortColumns}
                onSortColumnsChange={(sortColumn) =>
                    setRows(sortRows(rows, sortColumn))}
            />
        )
    }

    return (
        <Layout
            title='Top Localities'
            description='Report'
        >
            <ReportParameters />
            <br />
            {loading
                ? <Spinner />
                : <Grid />
            }
            <br />
            <CSVLink data={rows} headers={headers()} filename={"57concrete-report.csv"}>
                Download report
            </CSVLink>
        </Layout>
    )
}

export default TopLocalities
