import React, { Component, Fragment } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import JobItem from '../job/JobItem'
import { api } from '../utils/Api'
import { jobIdFilter } from '../utils/JobIdFilter'

export default class DailyAppointment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            sumLoadYd3: 0,
            sumTarget: 0,
            sumBalance: 0,
            date: new Date(),
            endDate: null,
            changeDate: false,
            loading: true,
            userMail: localStorage.getItem('email'),
            jobId: ''
        }
    }

    onChangeDate = date => {
        this.setState({
            date: date,
            changeDate: true
        })
    }

    // filter jobNumber
    setJobId = e => {
        const jobNum = e.target.value
        this.setState({
            jobId: jobNum
        })

        if (jobNum.length === 4) {
            const filteredJobs = jobIdFilter(jobNum, this.state.jobs)
            if (filteredJobs.length > 0) {
                this.setState({
                    jobs: filteredJobs
                })
            }
        }
    }

    getJobs = async () => {
        // mes hay que sumarle uno
        const fechaInicio = `${this.state.date.getFullYear()}-${this.state.date.getMonth() + 1}-${this.state.date.getDate()}`

        const url = api + `/jobdate?date=${fechaInicio}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.assignData(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Assign API response to local array "jobs" & calculate totals
    assignData = (data) => {
        // suma de importe ($)
        const totalLoad = data.reduce((acc, elJob) => acc + elJob.loadYD3, 0)
        localStorage.setItem('loadSum', totalLoad)

        // suma de target
        var suma = 0
        var sumBalance = 0
        var sumWait = 0
        data.forEach(element => {
            let operandos = typeof element.targetYD3 === "string" ? element.targetYD3.split('+') : element.targetYD3
            if (element.jobStatus !== 'Quote' && element.jobStatus !== 'Waiting Inspection' && element.jobStatus !== 'Wait Inspection') {
                suma = suma + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
            }

            if (element.jobStatus === 'Waiting Inspection' || element.jobStatus === 'Wait Inspection') {
                sumWait = sumWait + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
            }

            var balance = element.balance ? element.balance : 0
            sumBalance = sumBalance + parseFloat(balance)
        })
        // console.log(`YD: ${totalLoad} Target: ${suma}, Balance: ${sumBalance} `)
        localStorage.setItem('targetSum', suma)
        localStorage.setItem('sumBalance', sumBalance)
        localStorage.setItem('waitingInspection', sumWait)

        // console.log(data)
        this.setState({
            loading: false,
            jobs: data,
            sumLoadYd3: totalLoad,
            sumTarget: suma,
            sumBalance: sumBalance
        })
    }

    buscarJobs() {
        if (this.state.jobId.length === 0) {
            this.getJobs()
        }
    }

    // lifecycle method
    componentDidUpdate() {
        if (this.state.changeDate) {
            // this.getJobs()
            console.log('cambio de fecha')
            this.buscarJobs()

            this.setState({
                changeDate: false
            })
        }
    }

    // lifecycle method
    componentDidMount() {
        this.getJobs()
        this.interval = setInterval(() => {
            // this.getJobs()
            this.buscarJobs()
        }, 2000)
    }

    // lifecycle method
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    // call component for table row drawing status !== done
    losJobs() {
        if (this.state.jobs.length > 0) {
            var suma = 0

            return this.state.jobs
                .filter(jb => (jb.jobStatus !== 'Operations done (AR)') &&
                    (jb.jobStatus !== 'Quote') &&
                    (jb.jobStatus != 'ok'))
                .map((currentJob, i) => {
                    return <JobItem job={currentJob} key={i} action={this.regreso} />
                })
        } else {
            return (
                <tr>
                    <td colSpan="16" style={{ 'textAlign': 'center' }}>
                        No jobs for selected date
                    </td>
                </tr>
            )
        }
    }

    // call component for table row drawing status 
    jobsForStatus(status) {
        if (this.state.jobs.length > 0) {
            return this.state.jobs
                .filter(jb => jb.jobStatus === status)
                .map((currentJob, i) => {
                    return <JobItem job={currentJob} key={i} action={this.regreso} />
                })
        }
    }

    regreso() {
        console.log('en el call back')
    }

    renderLoading() {
        return <Spinner />
    }

    renderJobs() {
        return (
            <Fragment>
                <h3>
                    {`Total YD: ${localStorage.getItem('loadSum')}, 
                    Total Target: ${localStorage.getItem('targetSum')}, 
                    Total Balance: ${localStorage.getItem('sumBalance')},
                    Waiting Inspection: ${localStorage.getItem('waitingInspection')}`}
                </h3>
                <div className="form-group row">
                    <div className="col-sm-3">
                        Date:
                        <DatePicker
                            selected={this.state.date}
                            onChange={this.onChangeDate}
                            startDate={this.state.date}
                            endDate={this.state.endDate}
                        />
                    </div>
                    <div className="col-sm-4">
                        <label className='text-muted'>JobId</label>
                        <input
                            type='text'
                            value={this.state.jobId}
                            onChange={this.setJobId}
                        />
                    </div>
                </div>
                <div
                    style={{ fontSize: '13px' }}
                >
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Confirm</th>
                                <th style={{ width: '10%' }}>Customer</th>
                                <th>JobID</th>
                                <th>Time</th>
                                <th>Job Address</th>
                                <th>Load YD3</th>
                                <th>Target YD3</th>
                                <th>Dest. time</th>
                                <th style={{ width: '4%' }}>Mix Design</th>
                                <th>Slump</th>
                                <th>Pour/Type</th>
                                <th>Status</th>
                                <th>Seller</th>
                                {
                                    (this.state.userMail === "mv@57concrete.com" || this.state.userMail === 'ec@57concrete.com' || this.state.userMail === "eliud@gmail.com") &&
                                    <th>Hold</th>
                                }
                                <th style={{ width: '6%' }}>Comments</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.losJobs()}
                            {this.jobsForStatus('Operations done (AR)')}
                            {this.jobsForStatus('Quote')}
                            {this.jobsForStatus('ok')}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <Layout
                title='Daily Appointment'
                description='Job list'
            >
                {
                    this.state.loading ?
                        this.renderLoading()
                        : this.renderJobs()
                }
            </Layout>
        )
    }
}