import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../layout/Layout'
import { dateFormat } from '../utils/Convert'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'

const UploadList = () => {
    return (
        <Layout
            title='Reconciliation Upload'
            description='Reconciliation report'
            className='container col-md-8 offset-md-2'
         >
            <h4>Admin</h4>
            <Link to='/salesmixlist'>Admin File list</Link>
            <br />
            <Link to='/salesmixupload'>Admin File Upload</Link>
            <br />
            <h4>Production</h4>
            <Link to='/ordersummarylist'>Production File list</Link>
            <br/>
            <Link to='/ordersummaryupload'>Production File Upload</Link>
            <br />
            {
                //Acceso usuarios con privilegios de hacer reportes
                 ( localStorage.getItem('email') === "ec@57concrete.com" 
                 || localStorage.getItem('email') === "bp@gmail.com"
                 || localStorage.getItem('email') === "eliud@gmail.com") &&
                 <div>
                     <h4> Create reconciliation report </h4>
                      <Link to='/reconciliationreport'>Reconciliation Report</Link>
                 </div>
                     
                     
            }
            
        </Layout>
    )
}

export default UploadList


