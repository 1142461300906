import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getEmailList, deleteEmailList, updateEmailList } from '../../actions/emails'
import { validEmail } from '../utils/Validate'

const EmailListEdit = ({ match, emails: { email, loading }, getEmailList, deleteEmailList, updateEmailList, history }) => {

    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [emailListing, setEmailListing] = useState([])
    const [validError, setValidError] = useState(false)

    useEffect(() => {
        getEmailList(match.params.id)
    }, [])


    useEffect(() => {
        if (email) {
            console.log(email)
            setName(email.name ? email.name : "")
            setDescription(email.description ? email.description : "")
            setEmailListing(email.receipient ? email.receipient : [])
        }
    }, [email])

    const addField = () => {
        const list = emailListing.concat({ email: '' })
        setEmailListing(list)
    }

    const removeField = (i) => {
        if (emailListing.length != 1) {
            const list = [...emailListing]
            list.splice(i, 1)
            setEmailListing(list)
        }
    }

    const changeEmailListing = (i, e) => {
        const values = [...emailListing]
        try {
            values[i][e.target.name] = e.target.value
            setEmailListing(values)
        } catch (err) {
            console.error(err)
        }
    }

    const onSubmit = e => {
        e.preventDefault()

        let validEmails = true

        emailListing.forEach((mail) => {
            if (validEmail(mail.email) === false) {
                validEmails = false
            }
        })

        if (validEmails) {
            setValidError(false)
            let formData = {
                name: name,
                description: description,
                receipient: emailListing,
            }

            console.log(formData)
            updateEmailList(match.params.id, formData)
            history.push('/emaillist')
        } else {
            setValidError(true)
        }
    }

    const deleteList = () => {
        deleteEmailList(match.params.id)
        history.push('/emaillist')
    }

    const EmailListForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">List Name</label>
                    <input
                        onChange={e => setName(e.target.value)}
                        type="text"
                        name='name'
                        className="form-control"
                        value={name}
                        placeholder="Project Name"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Description</label>
                    <input
                        type="text"
                        name='description'
                        className='form-control'
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                        placeholder='Description'
                        required
                    />
                </div>
            </div>
            <div>
                {emailListing.map((emailList, i) => {
                    return <div key={i} className="form-group row">
                        <div className='col-sm-4'>
                            <label className="text-muted">Email Address</label>
                            <input
                                type="text"
                                name='email'
                                label='email'
                                className={validEmail(emailListing[i].email) ? "form-control border border-success" : "form-control border border-danger"}
                                onChange={e => changeEmailListing(i, e)}
                                value={emailListing[i].email}
                                placeholder="e-mail"
                            />
                        </div>
                        {
                            i + 1 === emailListing.length &&
                            <div className='col-sm-1'>
                                <label className="text-muted"></label>
                                <label className="form-control btn-success text-center" onClick={() => addField()}> + </label>
                            </div>
                        }
                        <div className='col-sm-1'>
                            <label className="text-muted"></label>
                            <label className="form-control btn-danger text-center" onClick={() => removeField(i)}>  <FontAwesomeIcon icon={faTrashAlt} /> </label>
                        </div>
                    </div>
                })}
            </div>
            <div className="my-2">
                <input className='btn btn-primary' placeholder='Edit' type='submit' />
                &nbsp; &nbsp; &nbsp;
                <label className="btn btn-danger " onClick={() => deleteList()}> Delete list </label>
            </div>
        </form>
    )

    return (
        <Layout
            title='E-mail'
            description='Update e-mail list'
            className='container col-md-8 offset-md-2'
        >
            {
                validError
                    ? <div className="form-group row">
                        <label className="form-control btn-danger text-center"> Not all e-mails are valid </label>
                    </div>
                    : null
            }
            {
                loading
                    ? <Spinner />
                    : email ? EmailListForm() : "No data"
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    emails: state.emails
})

export default connect(mapStateToProps, { getEmailList, deleteEmailList, updateEmailList })(EmailListEdit)
