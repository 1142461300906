import React, { useState, useEffect } from 'react'  
import Alert from 'react-bootstrap/Alert'
import { Link } from 'react-router-dom'

const Confirmation = () => {
    return (
        <div style={{ 
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/fondo2.jpeg'})`,
            height: '1078px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
            <img src="http://57concrete.com/assets/images/logo/logo-light.png" alt="Logo" style={{width:'20%'}} /> 
            <center>
                <h2>Order received</h2>
            </center>
            <Alert key='success' variant='success'>
                A sales representative will contact you
            </Alert>
            <Link to='/' className='btn btn-primary' >
                Home
            </Link>
        </div>
    )
}

export default Confirmation