import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'

const QuoteListing = () => {

    const [loading, setLoading] = useState(true)
    const [quotes, setQuotes] = useState([])

    useEffect(() => {
        getQuotes()
    }, [])

    const getQuotes = async () => {
        const yearDate = new Date()
        const url = api + `/quotejobs?startDate=2022-02-14&endDate=${yearDate.getFullYear()}-12-31`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let { data } = await axios.get(url)
            setLoading(false)
            data = filterToQuotes(data)
            setQuotes(data)
        } catch (err) {
            console.error(err)
            setQuotes([])
            setLoading(false)
            return null
        }
    }

    const filterToQuotes = (data) => {
        return data.filter(d => d.isJob === false)
    }


    const Quote = (job) => {
        job = job.job

        return (
            <tr>
                <td>
                    <Link to={'/quote/' + job._id}>
                        {job.customer ? job.customer.customerNumber + ' ' + job.customer.name : "No Customer"}
                    </Link>
                </td>
                <td>{job.number} </td>
                <td>
                    {
                        `${job.shipAddress} ${job.shipCity} ${job.shipState} ${job.shipZip}`
                    }
                </td>
                <td>
                    {new Date(job.scheduledDate).toLocaleDateString() + ' ' + new Date(job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}
                </td>
                <td>{job.psi ? job.psi : "NA"} </td>
                <td>{job.targetYD3 ? job.targetYD3 : "NA"} </td>
                <td>{`${job.jobPoor ? job.jobPoor : "NA"}/${job.jobType ? job.jobType : "NA"}`} </td>

            </tr>
        )
    }

    const DrawList = () => {
        return quotes.map((job, i) => {
            return <Quote job={job} key={i} />
        })
    }

    const RenderQuotes = () => (
        <div>
            <div style={{ fontSize: '13px' }}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>JobID</th>
                            <th>Ship to Address</th>
                            <th>Date</th>
                            <th>Mix Design</th>
                            <th>Target YD3</th>
                            <th>Pour/Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <DrawList />
                    </tbody>
                </table>
            </div>
        </div>
    )

    return (
        <Layout
            title='Quotes'
            description='List of Quotes'
        >
            {
                loading ? <Spinner /> : (quotes.length > 0 ? <RenderQuotes /> : "No Quotes Available")
            }
        </Layout>
    )
}

export default QuoteListing


/*
import React, { Component, Fragment } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import Layout from '../layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { api } from '../utils/Api'

const Quote = props => (
    // console.log(props)
    <tr>
        <td>
            <Link to={'/job/' + job._id}>
                {job.customer.customerNumber + ' ' + job.customer.name}
            </Link>
        </td>
        <td>{job.number} </td>
        <td>
            {
                `${job.shipAddress} ${job.shipCity} ${job.shipState} ${job.shipZip}`
            }
        </td>
        <td>{job.jobStatus}</td>
        <td>
            {
                job.paid && <FontAwesomeIcon icon={faCheckSquare} />
            }
        </td>
        <td>
            {new Date(job.scheduledDate).toLocaleDateString() + ' ' + new Date(job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}
        </td>
    </tr>
)


export default class ListaCliente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            loading: true
        }
    }

    getQuotes = async () => {
        const url = api + '/jobquote'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            console.log(data)
            this.setState({
                loading: false,
                jobs: data
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }

    componentDidMount() {
        this.getQuotes()
    }

    quoteNew = () => {
        this.history.push('/quotenew')
    }

    renderLoading() {
        return <Spinner />
    }

    losClientes() {
        return this.state.jobs.map((job, i) => {
            return <Quote job={job} key={i} />
        })
    }

    renderClients() {
        return (
            <Fragment>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Job ID</th>
                            <th>Address</th>
                            <th>Job Status</th>
                            <th>Paid</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.losClientes()}
                    </tbody>
                </table>
                <button className='btn btn-success' onClick={this.quoteNew}>
                    New Quote
                </button>
            </Fragment>
        )
    }

    render() {
        return (
            <Layout
                title='Quotes'
                description='List of Quotes'
            >
                {
                    this.state.loading ?
                        this.renderLoading()
                        : this.renderClients()
                }
            </Layout>
        )
    }
}
*/