import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { createEmailList } from '../../actions/emails'
import { validEmail } from '../utils/Validate'

const EmailNew = ({ createEmailList, history }) => {

    const [emailListing, setEmailListing] = useState([{ email: '' },])
    const [validError, setValidError] = useState(false)

    const initialState = {
        name: '',
        description: '',
        receipient: emailListing,
    }

    const [formData, setFormData] = useState(initialState)

    const {
        name,
        description,
        receipient,
    } = formData


    const addField = () => {
        const list = emailListing.concat({ email: '' })
        setEmailListing(list)

        setFormData({ ...formData, receipient: emailListing })
    }

    const removeField = (i) => {
        if (emailListing.length != 1) {
            const list = [...emailListing]
            list.splice(i, 1)
            setEmailListing(list)
        }

        setFormData({ ...formData, receipient: emailListing })
    }

    const changeEmailListing = (i, e) => {
        const values = [...emailListing]
        try {
            values[i][e.target.name] = e.target.value
            validEmail(e.target.value)
            setEmailListing(values)
        } catch (err) {
            console.error(err)
        }

        setFormData({ ...formData, receipient: values })
    }

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault()

        let validEmails = true

        emailListing.forEach((mail) => {
            if (validEmail(mail.email) === false) {
                validEmails = false
            }
        })

        if (validEmails) {
            setValidError(false)
            // current formData is missing last value
            let fullFormData = { ...formData, receipient: emailListing }
            createEmailList(formData)
            history.push('/emaillist')
        } else {
            setValidError(true)
        }
    }

    const EmailListForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">List Name</label>
                    <input
                        onChange={onChange}
                        type="text"
                        name='name'
                        className="form-control"
                        value={name}
                        placeholder="Project Name"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Description</label>
                    <input
                        type="text"
                        name='description'
                        className='form-control'
                        onChange={onChange}
                        value={description}
                        placeholder='Description'
                        required
                    />
                </div>
            </div>
            <div>
                {emailListing.map((emailList, i) => {
                    return <div key={i} className="form-group row">
                        <div className='col-sm-4'>
                            <label className="text-muted">Email Address</label>
                            <input
                                type="text"
                                name='email'
                                label='email'
                                className={validEmail(emailListing[i].email) ? "form-control border border-success" : "form-control border border-danger"}
                                onChange={e => changeEmailListing(i, e)}
                                value={emailListing[i].email}
                                placeholder="e-mail"
                            />
                        </div>
                        {
                            i + 1 === emailListing.length &&
                            <div className='col-sm-1'>
                                <label className="text-muted"></label>
                                <label className="form-control btn-success text-center" onClick={() => addField()}> + </label>
                            </div>
                        }
                        <div className='col-sm-1'>
                            <label className="text-muted"></label>
                            <label className="form-control btn-danger text-center" onClick={() => removeField(i)}>  <FontAwesomeIcon icon={faTrashAlt} /> </label>
                        </div>
                    </div>
                })}
            </div>
            <div className="my-2">
                <input className='btn btn-primary' placeholder='New' type='submit' />
            </div>
        </form>
    )


    return (
        <Layout
            title='E-mail'
            description='Create e-mail list'
            className='container col-md-8 offset-md-2'
        >
            {
                validError
                    ? <div className="form-group row">
                        <label className="form-control btn-danger text-center"> Not all e-mails are valid </label>
                    </div>
                    : null
            }
            {EmailListForm()}
        </Layout>
    )
}

const mapStateToProps = state => ({
    user: state.auth.usuario
})

export default connect(mapStateToProps, { createEmailList })(EmailNew)
