import React, { useState } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import DatePicker from 'react-datepicker'

const SalesMix = () => {
    const [file, setFile] = useState('')  
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [lines, setLines] = useState(0)

    const onSubmit = async(e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', file)

        const url = api + '/uploadsalesxlsx' 
        // const url = 'https://jap.57concrete.com/api/uploadsalesxlsx'

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

        if (file.type !== excelType) {
            alert('Invalid file type! You must select an excel file file')
            return null
        }

        const day = (selectedDate.getDate() < 10 ? `0${selectedDate.getDate()}` : `${selectedDate.getDate()}`)
        const month = (selectedDate.getMonth() + 1 < 10 ? `0${selectedDate.getMonth() + 1}` : `${selectedDate.getMonth() + 1}`)
        const year = selectedDate.getFullYear()

        const fileDate = `${year}` + month + day

        formData.append('fileDate', fileDate)

        try {
            const {data} = await axios.post(url, formData, config)
            console.log(data)
            setLines(data.dataRows)
        } catch (err) {
            alert('File upload error')
            console.log(err.message)
            return null
        }
    }

    const picked = (e) => {
        setFile(e.target.files[0])
        setIsFilePicked(true)
    }

    const SalesMixUploadForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className="form-group column">
                <div className='col-sm-4'>
                    <label className='text-muted'>Select File Date</label>
                </div>
                <div className='col-sm-4'>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date)
                        }}
                    />
                </div>
            </div>
            <br />
            <div className='form-group'>
                <input
                    type='file'
                    name='file'
                    id="files"
                    style={{display:"none"}}
                    onChange={picked}
                />
                <label htmlFor="files" className='btn btn-primary'>Select "sales mix" Excel file to upload</label>
            </div>
            {isFilePicked ? (
				<div>
					<p>Filename: {file.name}</p>
					<p>Size in Kbytes: {Math.floor(file.size/1024)}</p>
					<p>
						lastModifiedDate:{' '}
						{file.lastModifiedDate.toLocaleDateString()}
					</p>
                    <input className='btn btn-success' value='Upload' type='submit' />
				</div>                
			) : (
				<p>No file selected</p>
			)}            
            {
                lines > 0 &&
                <div>
                    File <strong>{file.name}</strong> uploaded with <strong>{lines} lines </strong> 
                    of valid data.
                </div>
            }
        </form>
    )

    return (
        <Layout
            title='Sales Mix'
            description='Select file to upload'
            className="container col-md-8 offset-md-2"
        >   
            <SalesMixUploadForm />         
        </Layout>
    )
}

export default SalesMix