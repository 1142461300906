import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getBuildDevelopments } from '../../actions/buildDevelopment'
import Spinner from '../layout/Spinner'


const BuildDevelopmentList = ({ getBuildDevelopments, build_development: { build_developments, loading } }) => {

    let length = build_developments.length

    useEffect(() => {
        getBuildDevelopments()
    }, [])

    useEffect(() => {
        length = build_developments.length
    }, [build_developments])

    // When state is updated, get new builds
    if (build_developments.length === undefined) {
        getBuildDevelopments()
    }

    const DrawList = () => {
        return build_developments.map((build) => {
            return <tr key={build._id}>
                <td>
                    <Link to={`/builddevelopment/${build._id}`} >
                        {build.name}
                    </Link>
                </td>
                <td>
                    {build.description || 'NA'}
                </td>
            </tr>
        })
    }

    const RenderBuilds = () => (
        <table className='table'>
            <thead>
                <tr>
                    <td>Name</td>
                    <td>Description</td>
                </tr>
            </thead>
            <tbody>
                <DrawList />
            </tbody>
        </table>
    )

    return (
        <Layout
            title='Build Development List'
            description='Listing of Build Developments'
            className='container col-md-8 offset-md-2'
        >
            {loading || build_developments.length === undefined ? <Spinner /> : (length > 0 ? <RenderBuilds /> : "No builds available")}
            <div>
                <Link to='/newbuilddevelopment' className='btn btn-success'>Add Build Development</Link>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    build_development: state.build_development
})


export default connect(mapStateToProps, { getBuildDevelopments })(BuildDevelopmentList)