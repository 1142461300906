import React from 'react'
import Select from 'react-select'

const DescriptionPicker = ({action, value}) => {

    const options = [
        { value: '16 closed', label: 'Training Rate' },
        { value: '16 closed', label: 'Training Course' },
        { value: '16 closed', label: 'Holiday' },
        { value: '16 closed', label: 'Vacations' },
        { value: '0 open', label: 'Bonus' },
        { value: '0 open', label: 'Cash Advance' },
        { value: '0 open', label: 'Cash Advance Repayment' },
        { value: '16 closed', label: 'Sick Days' },
        { value: '40 closed', label: 'Weather Relief' },
        { value: '23.75 closed', label: 'Contractor Meals 1' },
        { value: '26.25 closed', label: 'Contractor Meals 2' },
        { value: '200 closed', label: 'Employee Recommendation Bonus' },
    ]

    return (
        <div>
            <Select
                value={value}
                options={options}
                placeholder='Select description...'
                onChange={action}
                isSearchable={true}
            />
        </div>
    )
}

export default DescriptionPicker