import api from '../utils/api'
import { setAlert } from './alert'
import {
    GET_PLANTS,
    ADD_PLANT,
    UPDATE_PLANT,
    PLANT_ERROR,
    GET_PLANT
} from './types'

// Create new plant
export const createPlant = (formData) => async dispatch => {
    try {
        const {data} = await api.post('/plant', formData)

        dispatch({
            type: ADD_PLANT,
            payload: data
        })
    } catch (err) {
        const errors = err.response
        console.log(errors)

        if (errors) {
            dispatch(setAlert('Error creating plant. Review number', 'danger'))
        }

        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// update plants attribs
export const updatePlant = (plantId, formData) => async dispatch => {
    try {
        const { data } = await api.patch(`/plant/${plantId}`, formData)

        dispatch({
            type: UPDATE_PLANT,
            payload: data
        })

        dispatch(setAlert('Plant Updated', 'success'))
    } catch (err) {
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// full plant list
export const getPlantList = () => async (dispatch) => {
    try {
        const { data } = await api.get('/plant')
        dispatch({
            type: GET_PLANTS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get plant by id
export const getPlant = id => async (dispatch) => {
    try {
        const { data } = await api.get(`/plant/${id}`)
        dispatch({
            type: GET_PLANT,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get plant by number
export const getPlantNumber = id => async (dispatch) => {
    try {
        const { data } = await api.get(`/plantnumber/${id}`)
        dispatch({
            type: GET_PLANT,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}
