import React, { Component } from 'react'
import {  
    Document, 
    PDFViewer,     
    Page,  
    View,
    Image,
    Text,   
    StyleSheet } from '@react-pdf/renderer'
import Signature from './Signature'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'

const styles = StyleSheet.create({
    page: {
      padding: 4,
      margin: 4,
      flexDirection: 'column'
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      '@media max-width: 360': {
        flexDirection: 'column',
      },
    },
    renglonStyle: {
      flexDirection: 'row',
      width: '95%'
    },
    textPageTitle: {
        fontFamily: 'Helvetica',
        fontSize: 30,
        textAlign: 'right'
    },
    textTerms: {
        fontFamily: 'Helvetica',
        fontSize: 8,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 8,
        textAlign: 'justify',              
    },
    text2Col: {
        fontFamily: 'Helvetica',
        fontSize: 12,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        width: '50%',
        marginTop: 15
    },
    text3Col: {
        fontFamily: 'Helvetica',
        fontSize: 12,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        width: '33%',
        marginTop: 15
    },
    text4Col: {
        fontFamily: 'Helvetica',
        fontSize: 12,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        width: '25%',
        marginTop: 15
    },
    textSubtitle: {
        ontFamily: 'Helvetica',
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',                
        width: '100%',
        marginTop: 20,
        textAlign: 'center',
    },
    textRow: {
        fontFamily: 'Helvetica',
        fontSize: 14,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        width: '100%',
        marginTop: 15
    },
    textTermFooter: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 9,
        textAlign: 'center',
        marginTop: 20
    },
    textTermSignature: {
        fontFamily: 'Helvetica',
        fontSize: 9
    },
    textTermsTitle: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 10,
        textAlign: 'center'
    },
    leftImage: {
        marginLeft: 2,
        marginTop: 3
    },
    image: {
        width:'30%',
        padding: 5        
    }
  })

  export default class CreditForm extends Component {
    constructor (props) {
      super(props)
  
      this.state = {
        credit: {}
      }
    }

    getCredit = async () => {
        const url = api + '/credit/' + this.props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)

            this.setState({
                credit:data
            })
            console.log(this.state.credit)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {        
        this.getCredit()
    }

    render() {
        const CreditPrint = () => (
            <Document
                author='57concrete'
                keywords='57concrete'
                subject='57concrete Credit Application'
                title='Credit Application'
            >
                <Page
                    size='A4'
                    wrap={false}
                    style={styles.page}
                >                      
                    <View style={styles.leftImage}>
                        <Image    
                            style={styles.image}                          
                            src={process.env.PUBLIC_URL + '/images/concrete57.png'}               
                        /> 
                    </View>                                               
                    <View style={styles.renglonStyle}>
                        <Text style={styles.textPageTitle}>Credit Application</Text>
                    </View>
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>LEGAL COMPANY NAME: {this.state.credit.legalCompanyName} </Text>
                    <Text style={styles.text2Col}>BUSINESS TYPE: {this.state.credit.businessType} </Text>
                  </View>  
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textRow}>
                        ADDRESS: {this.state.credit.address}{' '} 
                        {this.state.credit.city}{' '}
                        {this.state.credit.county}{' '}
                        {this.state.credit.state}{' '}
                        {this.state.credit.zip}
                    </Text>
                  </View>  
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textRow}>
                        MAILING ADDRESS: {this.state.credit.mailingAddress}{' '} 
                        {this.state.credit.mailingCity}{' '}
                        {this.state.credit.mailingCounty}{' '}
                        {this.state.credit.mailingState}{' '}
                        {this.state.credit.mailingZip}
                    </Text>
                  </View>    
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text3Col}>
                        EMAIL: {this.state.credit.email}
                    </Text>
                    <Text style={styles.text3Col}>
                        PHONE: {this.state.credit.phone}
                    </Text>
                    <Text style={styles.text3Col}>
                        FAX: {this.state.credit.fax}
                    </Text>
                  </View>     
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text3Col}>
                        FEDERAL TAX ID NUMBER: {this.state.credit.fedTaxIdNumber}
                    </Text>
                    <Text style={styles.text3Col}>
                        SALES TAX EXEMPT: {this.state.credit.phone}
                    </Text>
                    <Text style={styles.text3Col}>
                        Attach certificate to application 
                    </Text>
                  </View>    
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>
                        TYPE OF BUSINESS: {this.state.credit.typeOfBusiness} 
                    </Text>
                    <Text style={styles.text2Col}>
                        CONTRACTOR TYPE: {this.state.credit.contractorType} 
                    </Text>
                  </View>  
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text3Col}>
                        BUSINESS START DATE: {this.state.credit.businessStartDate}
                    </Text>
                    <Text style={styles.text3Col}>
                        NUMBER OF EMPLOYEES: {this.state.credit.employeeCount}
                    </Text>
                    <Text style={styles.text3Col}>
                        SHIP COMPLETE 
                    </Text>
                  </View>   
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>
                        BUSINESS LICENSE/REGISTRATION #: {this.state.credit.businessLicense} 
                    </Text>
                    <Text style={styles.text2Col}>
                        OPEN LIENS OR JUDGEMENTS: {this.state.credit.openLiens}
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textSubtitle}>
                        AP Information
                    </Text>
                  </View>     
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text3Col}>
                        AP CONTACT: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text3Col}>
                        PHONE: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text3Col}>
                        FAX: {this.state.credit.apFax}
                    </Text>
                  </View>  
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>
                        PO REQUIRED: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text2Col}>
                        INVOICES BY EMAIL: {this.state.credit.apPhone}
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>
                        PRIMARY EMAIL: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text2Col}>
                        SECONDARY EMAIL: {this.state.credit.apPhone}
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textSubtitle}>
                        Officers
                    </Text>
                  </View>   
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>
                        PRESIDENT: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text2Col}>
                        VICEPRESIDENT: {this.state.credit.apPhone}
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text2Col}>
                        TREASURER: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text2Col}>
                        OTHER OFFICER: {this.state.credit.apPhone}
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textSubtitle}>
                        TRADE REFERENCES
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text4Col}>
                        BUSINESS NAME: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text4Col}>
                        ACCT#: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text4Col}>
                        PHONE: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text4Col}>
                        FAX: {this.state.credit.apPhone}
                    </Text>
                  </View>   
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text4Col}>
                        BUSINESS NAME: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text4Col}>
                        ACCT#: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text4Col}>
                        PHONE: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text4Col}>
                        FAX: {this.state.credit.apPhone}
                    </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text4Col}>
                        BUSINESS NAME: {this.state.credit.apContact}
                    </Text>
                    <Text style={styles.text4Col}>
                        ACCT#: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text4Col}>
                        PHONE: {this.state.credit.apPhone}
                    </Text>
                    <Text style={styles.text4Col}>
                        FAX: {this.state.credit.apPhone}
                    </Text>
                  </View>   
                  <View style={styles.renglonStyle}>
                      <Text style={styles.textTerms}>
                        Buyer certifies that it is solvent and capable of meeting its obligations hereunder, and that all information (including any requested financial statements) provided to Filegonia Materials LLC and/or its subsidiaries and affiliates (“Seller”) is true, accurate and complete. All such information has been submitted for the purpose of obtaining credit. Buyer and each Guarantor authorize Seller to request credit reports from credit bureaus (including consumer reporting agencies) regarding their respective commercial or personal credit and otherwise to investigate their respective creditworthiness before extending credit now or at any time in the future. Buyer also agrees to comply with all applicable bulk sales laws. Buyer agrees that all Terms and Conditions of Sale on the reverse side of this Credit Application, as the same may be amended from time to time, shall apply to all sales and extensions of credit made to Buyer by Seller.
                      </Text>
                  </View>  
                  <View style={styles.renglonStyle}>
                      <Text style={styles.textTerms}>
                        Buyer agrees that all Terms and Conditions of Sale on the Page 2 of this Credit Application, as the same may be amended from time to time, shall apply to all sales and extensions of credit made to Buyer by Seller.                                           
                      </Text>
                  </View>                   
                </Page>   
                <Page
                     size='A4'
                     wrap={false}
                     style={styles.page}
                > 
                    <View>
                        <Text style={styles.textTermsTitle}>
                            TERMS AND CONDITIONS OF SALE ("Terms")
                        </Text>
                        <Text style={styles.textTerms}>
                            1.      All references in this document to “Seller” shall include 57 Concrete LLC and / or any parent, subsidiary or affiliate of 57 Concrete LLC (including any division of the foregoing) whether or not performing any or all of the scope hereunder or specifically identified herein. All references to “Buyer” shall include all parent(s), subsidiaries and affiliates of the entity placing the order. Buyer and Seller may be referred to individually as a “Party” and collectively as “Parties”.
                        </Text>
                        <Text style={styles.textTerms}>
                            2.      All sales to Buyer are subject to these Terms, which shall prevail over any inconsistent terms of Buyer’s purchase order or other documents. Additional or different terms and conditions in any way altering or modifying these Terms are expressly objected to and shall not be binding upon Seller unless specifically accepted in writing by Seller’s authorized representative. No modification or alteration of these Terms shall result by Seller’s shipment of goods following receipt of Buyer’s purchase order, or other documents containing additional, conflicting or inconsistent terms. There are no terms, conditions, understandings, or agreements other than those stated herein, and all prior proposals and negotiations are merged herein. These Terms are binding on the Parties, their successors, and permitted assigns.
                        </Text>
                        <Text style={styles.textTerms}>
                            3.      Prices in Seller quotes are subject to change without notice, and all such prices expire and become invalid if not accepted within 10 calendar days from the date of issue, unless otherwise noted by Seller in writing. Price extensions if made must be in writing only. All prices and other terms provided to Buyer shall be kept confidential except to the extent a Party is required by law to disclose the same.
                        </Text>
                        <Text style={styles.textTerms}>
                            4.      Seller shall not be liable for delay or default in delivery resulting from any cause beyond Seller’s reasonable control, including, but not limited to, governmental action, strikes or other labor troubles, fire, damage or destruction of goods, wars (declared or undeclared), acts of terrorism, manufacturers’ shortages, availability or timeliness of transportation, materials, fuels, or supplies, and acts of God (each a “Force Majeure Event”). Upon the occurrence of a Force Majeure Event: (a) the time for Seller’s performance shall be extended reasonably and the Parties shall adjust all affected dates accordingly; (b) the purchase price shall be adjusted for any increased costs to Seller resulting from such Force Majeure Event; and (c) Buyer shall not be entitled to any other remedy.
                        </Text>
                        <Text style={styles.textTerms}>
                            5.      Buyer shall indemnify, defend, and hold Seller its officers, directors, employees and agents harmless from any and all costs (including attorneys’ and accountants’ fees and expenses), liabilities and damages resulting from or related to any third party (including Buyer’s employees) claim, complaint and/or judgment arising from Buyer’s use of any goods furnished hereunder, as well as any negligent, intentional, or tortious act or omission of Buyer or any material breach by Buyer of these Terms.
                        </Text>
                        <Text style={styles.textTerms}>
                            6.      When materials are delivered to Buyer, Buyer shall sign each individual delivery ticket to ensure proper accounting at the time of invoicing. Title and risk of loss shall pass to Buyer at the applicable delivery location. All claims as to which Seller has the risk of loss shall be waived unless Buyer, within 2 calendar days after receipt of the material, gives Seller written notice fully describing the alleged claim.
                        </Text>
                        <Text style={styles.textTerms}>
                            7.      Any change in product specifications, quantities, destinations, shipping schedules, or any other aspect of the scope of goods must be agreed to in writing by Seller, and may result in a price and delivery adjustment by Seller. No credit for goods returned by Buyer shall be given without Seller’s written authorization.
                        </Text>
                        <Text style={styles.textTerms}>
                            8.      Unless otherwise agreed in writing, payment terms are net 30 days from delivery, payable in United States of America (“U.S.”) dollars. Notwithstanding the foregoing, all orders are subject to Seller’s continuing approval of Buyer’s credit. If Buyer’s credit is not approved or becomes unsatisfactory to Seller then Seller, in its sole discretion, may suspend or cancel performance, or require different payment terms, including but not limited to cash on delivery or in advance of shipment. Payments due hereunder shall be made in the form of cash, check, wire transfer or money order, or other tender approved in writing by Seller. Seller may, in its sole discretion, apply Buyer’s payment against any open charges. Past due accounts bear interest at the lesser of 1.5% per month or the maximum rate permitted by applicable law, continuing after Seller obtains judgment against Buyer. Seller may exercise setoff or recoupment to apply to or satisfy Buyer’s outstanding debt. Buyer shall have no right of setoff hereunder, the same being expressly waived hereby. Seller expressly reserves its right to file liens if payment is not received for its materials and expressly disclaims any waiver of lien rights language which may be contained in any future agreements between the Parties hereto. Seller reserves all rights to invoice and be paid for materials provided to Buyer and any terms contained in any of Buyer’s purchase orders or other documents that purport to limit in any way the time or manner within which Seller may invoice are hereby waived by Buyer.
                        </Text>
                        <Text style={styles.textTerms}>
                            9.      Buyer shall pay Seller all costs and expenses of collection, suit, or other legal action brought as a result of the commercial relationship between them, including, but not limited to, all actual attorneys’ and paralegals’ fees, and collection costs, incurred pre-suit, through trial, on appeal, and in any administrative or bankruptcy proceedings. Any cause of action that Seller has against Buyer may be assigned without Buyer’s consent to 57 Concrete LLC or to any affiliate, parent or subsidiary of 57 Concrete LLC
                        </Text>
                        <Text style={styles.textTerms}>
                            10.         This Agreement, Buyer’s account, and the business relationship between Buyer and Seller shall be governed by and construed in accordance with the laws of the state where the applicable project is located without regard to conflicts of laws rules, and specifically excluding the UN Convention on Contracts for the International Sale of Goods. The Parties agree that any legal action arising under or related to this Agreement may be brought in the applicable federal or state court where the project is located, and any right to object to such venue or to assert the inconvenience of such forum is hereby waived.
                        </Text>
                        <Text style={styles.textTerms}>
                            11.         If Buyer fails to comply with these Terms, Seller may terminate or restrict any order immediately upon notice to Buyer. Buyer certifies that it is solvent and that it will advise Seller immediately if it becomes insolvent. Buyer agrees to send Seller written notice of any changes in the form of ownership of Buyer’s business within 5 days of such changes. Buyer and Seller are the only intended beneficiaries of this document, and there are no third party beneficiaries.
                        </Text>
                        <Text style={styles.textTerms}>
                            12.         The invalidity or unenforceability of all or part of these Terms will not affect the validity or enforceability of the other terms. The parties agree to replace any void or unenforceable term with a new term that achieves substantially the same practical and economic effect and is valid and enforceable.
                        </Text>
                        <Text style={styles.textTerms}>
                            13.         The following provisions shall survive termination, cancellation and completed performance of this Agreement as long as necessary to allow the aggrieved party to fully enforce such clauses: 5, 6, 9, 10, 11 and 12.
                        </Text>
                        <Text style={styles.textTermFooter}>
                            57 Concrete, LLC Terms and Conditions of Sale
                        </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.textTermSignature}>
                            Signature                            
                        </Text>
                        <Signature picUrl={api + `/creditsignature/${this.props.match.params.id}`}  />
                        <Text style={styles.textTermSignature}>
                            Name
                        </Text>
                        <Text style={styles.textTermSignature}>
                            Date
                        </Text>
                    </View>                 
                </Page>                       
                <Page
                     size='A4'
                     wrap={false}
                     style={styles.page}
                >
                    <View style={styles.formTitle}>
                      <Text>PERSONAL GUARANTY</Text>
                    </View>  
                    <View style={styles.renglonStyle}>
                        <Text style={styles.textSubtitle}>
                            GUARANTORS for Purchases of Buyer
                        </Text>
                    </View>                     
                    <View>
                        <Text style={styles.textTerms}>
                            To induce the extension of credit to Buyer, Guarantor (jointly and severally, if more than one) hereby guarantees payment of all existing and future indebtedness of Buyer to 57 Concrete ("Seller"), including any costs, expenses, and reasonable attorneys’ fees payable as a consequence of Seller’s collection efforts. This personal guaranty is absolute, complete, irrevocable and continuing and it shall not be necessary for Seller to give notice to Guarantor of any extension of credit to Buyer, any renewal thereof, any modification of the terms thereof, or Seller’s arrangements with any other Guarantor. Guarantor may by written notice to Seller at the address above (Attn: Credit Dept.) terminate its guaranty as to any new extensions of credit to Buyer made more than ten days after such written notice, but Guarantor shall continue to be obligated in respect of any credit extended within ten days after Seller’s receipt of such notice and any credit Seller remains obligated to extend to Buyer at the close of business on the tenth day following Seller’s receipt of such notice. Guarantor agrees to provide personal financial information as reasonably requested by Seller.
                        </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text3Col}>
                            FIRST GUARANTOR:
                        </Text>
                        <Text style={styles.text3Col}>
                             S/S NUMBER:
                        </Text>
                        <Text style={styles.text3Col}>
                            PHONE:
                        </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text4Col}>
                            ADDRESS:
                        </Text>
                        <Text style={styles.text4Col}>
                            CITY:
                        </Text>
                        <Text style={styles.text4Col}>
                            STATE:
                        </Text>
                        <Text style={styles.text4Col}>
                            ZIP:
                        </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text3Col}>
                            SECOND GUARANTOR:
                        </Text>
                        <Text style={styles.text3Col}>
                             S/S NUMBER:
                        </Text>
                        <Text style={styles.text3Col}>
                            PHONE:
                        </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text4Col}>
                            ADDRESS:
                        </Text>
                        <Text style={styles.text4Col}>
                            CITY:
                        </Text>
                        <Text style={styles.text4Col}>
                            STATE:
                        </Text>
                        <Text style={styles.text4Col}>
                            ZIP:
                        </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.textSubtitle}>
                          SIGNATURES 
                        </Text>
                    </View>
                </Page>       
            </Document>
        )

        return (
            <Layout
                title='Credit PDF'
                description='Credit Form PDF'
                className="container col-md-12"
            >                   
                <PDFViewer width="95%" style={{ height: '90vh' }}>
                  <CreditPrint />
                </PDFViewer>
            </Layout>
        )
    }
}