import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Tag, Button, Tooltip, Checkbox, Statistic } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
import { statusAllOptions } from '../../constants/Inventory'
import dayjs from 'dayjs'
const { Text } = Typography

const RequestInventoryList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [inventoryRequestedList, setInventoryRequestedList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            render: (_, record) => {
              return <Button 
              type="primary" 
              icon={<EditFilled twoToneColor={'white'}/>} 
              onClick={e=> history.push(`/inventoryrequested/${record.id}`)}
              />
            }
        },
        {
            title: 'Creation Date',
            dataIndex: 'creationDate',
            key: 'creationDate',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Name',
            dataIndex: 'inventory',
            key: 'inventory',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            key: 'provider',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Quantity Requested',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (value) => {
                // return <Text >{value}</Text>
                return <Statistic title="" value={value} valueStyle={{fontSize: 14}} />

            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (value) => {
                // return <Text >{value}</Text>
                return <Statistic title="" value={value} prefix={'$'} precision={2} valueStyle={{fontSize: 14}} />

            }
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            render: (value) => {
                return <Checkbox checked={value} />
            }
        },
        {
            title: 'Approved By',
            dataIndex: 'approvedBy',
            key: 'approvedBy',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
    ]

    useEffect(() => {
        getRequestInventoryList('')
    }, [])
    
    const getRequestInventoryList = async(term) => {
        setLoading(true)

        const url = api + `/inventoryrequested/search?term=${term}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {

                list.push({
                    key: index,
                    id: item._id,
                    creationDate: dayjs(item.createdAt).format('MM/DD/YYYY HH:mm'),
                    inventory: item.inventory.name,
                    provider: item.provider.name,
                    quantity: item.quantity,
                    amount: item.amount,
                    approved: item.approved,
                    approvedBy: item.approvedBy ? item.approvedBy.name : '-'
                })
            })
            setInventoryRequestedList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            getRequestInventoryList(value)
        }
    }   

    const RenderSearch = () => {
        return <CustomSearch 
            onSearch={onSearch}
            loading={loading}
        />
    }

    const addNewInventoryRequested = () => {
        history.push('/newinventoryrequested')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') &&
                <Button
                    onClick={addNewInventoryRequested}
                    type="primary"
                    style={{
                        width: 170,
                    }}
                    icon={<PlusCircleFilled />}
                >
                    Request Inventory
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={inventoryRequestedList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Inventory Requested List'
            description='Inventory Requested'
        >
            <Flex vertical gap={'large'}>
                <RenderSearch />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default RequestInventoryList