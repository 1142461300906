import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InspectionTypePicker from './InspectionTypePicker'
import { CSVLink } from "react-csv"

const QualityControlReport = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(true)
    const [term, setTerm] = useState('')
    const [type, setType] = useState('ALL')
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        getJobs()
    }

    const columna = [
        { key: 'number', name: 'Job No.'},
        { key: 'customer', name: 'Customer'},
        { key: 'plant', name: 'Plant'},
        { key: 'subplant', name: 'Sublant'},
        { key: 'scheduledDate', name: 'Scheduled Date'},
        { key: 'shipAddress', name: 'Job Address'},
        { key: 'seller', name: 'Seller'},
    ]

    const headers = () => {
        return columna.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const getJobs = async() => {
        setLoading(true)
        const start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        const url = api + `/qualitycontroljobs/search?startDate=${start}&endDate=${end}&term=${term}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            jobData(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const jobData = (jobs) => {
        let list = []
        jobs.map((job) => {
            list.push({
                number: job.number,
                customer: job.customer.name,
                plant: job.plant.name,
                subplant: job.subplant ? job.subplant : 'None',
                scheduledDate: job.scheduledDate ? job.scheduledDate.substring(0,10) : 'No Date',
                shipAddress: job.shipAddress,
                seller: job.seller.name,
            })
        })
        setRows(list)
        setColumns(columna)
        setLoading(false)
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const RenderTable = () => {
        return <div style={{maxWidth: '100%', marginTop: 20, marginBottom: 20}}>
            <table className="table">
                <thead>
                    <tr>
                        {
                            columna.map((column) => {
                                return <th>{column.name}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length === 0 ?
                        <tr>
                            <td colSpan={7}>
                                <p className='text-center'>No jobs available</p>
                            </td>
                        </tr>
                        :
                        rows.map((job, index) => {
                            return (
                                <tr key={index}>
                                    <td>{job.number}</td>
                                    <td>{job.customer}</td>
                                    <td>{job.plant}</td>
                                    <td>{job.subplant}</td>
                                    <td>{job.scheduledDate}</td>
                                    <td>{job.shipAddress}</td>
                                    <td>{job.seller}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <CSVLink data={rows} headers={headers()} filename={"57concrete-quality-control-report.csv"}>
                Download report
            </CSVLink>
        </div>
    }    

    const SearchCustomer = () => (
        <div className="form-group row">
            <div className="col-sm-3">
                <label className='text-muted'>&nbsp;</label>
                <input
                    type='text'
                    name='term'
                    size='40'
                    className="form-control"
                    placeholder="Search..."
                    defaultValue={term}
                    onBlur={(e) => setTerm(e.target.value)}
                />
            </div>
            <div className='col-sm-2'>
                <label className='text-muted'>Start Date</label>
                <DatePicker
                    selected={startDate}
                    minDate={new Date('01/01/2022')}
                    maxDate={new Date()}
                    onChange={(date) => setStartDate(date)}
                />
            </div>
            <div className='col-sm-2'>
                <label className='text-muted'>End Date</label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                    maxDate={new Date()}
                />
            </div>
            <div className="col-sm-2">
                <label className='text-muted'>&nbsp;</label>
                <button onClick={search} className='btn btn-primary w-100'>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                </button>
            </div>
        </div>
    )

    return (
        <Layout
            title='QC Inspection Report'
            description='Report'
            className='container col-md-10'
        >
            <SearchCustomer/>
            {
                loading ? <RenderLoading/> : <RenderTable/>
            } 

        </Layout>
    )
}

export default QualityControlReport