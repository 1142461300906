import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'
import WarningPicker from './WarningPicker';
import OffensePicker from './OffensePicker';

const WriteUpRender = ({currentWriteUp, writeups, setWriteUps, toggleModal, edit, index}) => {

    const [date, setDate] = useState(new Date());
    const [warning, setWarning] = useState('');
    const [other, setOther] = useState('');
    const [offenses, setOffenses] = useState([]);
    const [description, setDescription] = useState('');
    const [planImprovement, setPlanImprovement] = useState('');
    const [consequences, setConsequences] = useState('');

    useEffect(() => {
        setCurrentWriteUp()
    }, [edit])
    

    const setCurrentWriteUp = () => {
        if(edit){
            setWarning(currentWriteUp.warning);
            setOffenses(currentWriteUp.offenses);
            setOther(currentWriteUp.other);
            setDescription(currentWriteUp.description);
            setPlanImprovement(currentWriteUp.planImprovement);
            setConsequences(currentWriteUp.consequences);
            setDate(new Date(currentWriteUp.date));
        }
    }

    const onChangeWarning = (selectedWarning) => {
        if (selectedWarning) {
            setWarning(selectedWarning.value)
        }
    }

    const onChangeOffenses = (selectedOffenses) => {
        if (selectedOffenses) {
            setOffenses(selectedOffenses)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newWriteUp = {
            warning,
            offenses,
            other,
            description,
            planImprovement,
            consequences,
            date
        };
        if(edit){
            writeups[index].warning = warning;
            writeups[index].offenses = offenses;
            writeups[index].other = other;
            writeups[index].description = description;
            writeups[index].planImprovement = planImprovement;
            writeups[index].consequences = consequences;
            writeups[index].date = date;
        }else{
            writeups.push(newWriteUp);
        }
        setWriteUps(writeups);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Date</label>
                    <DatePicker
                        selected={date}
                        required
                        readOnly
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Type of Warning</label>
                    <WarningPicker value={warning} action={onChangeWarning} isRequired={true}/>
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Offenses</label>
                    <OffensePicker value={offenses} action={onChangeOffenses}/>
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Other</label>
                    <input className='form-control'
                        name='other'
                        type='text'
                        value={other}
                        onChange={e => setOther(e.target.value)}
                        placeholder='Other'
                    />
                </div>
            </div>
            <div className='form-group'>
                <label className='text-muted'>Description</label>
                <input className='form-control'
                    name='description'
                    type='text'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder='Description'
                    required
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Description</label>
                <input className='form-control'
                    name='planImprovement'
                    type='text'
                    value={planImprovement}
                    onChange={e => setPlanImprovement(e.target.value)}
                    placeholder='Plan Improvement'
                    required
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Consequences</label>
                <input className='form-control'
                    name='consequences'
                    type='text'
                    value={consequences}
                    onChange={e => setConsequences(e.target.value)}
                    placeholder='Consequences'
                    required
                />
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
            
        </form>
    )
}

export default WriteUpRender