import Spinner from 'react-bootstrap/Spinner'

const SpinnerButton = ({type}) => (
    <button className={`btn btn-${type}`} disabled >
        <Spinner animation="border" role="status" size='sm'>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </button>
)

export default SpinnerButton
