import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Tag, Radio, Button, notification, Table } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import { statusConstants, titlesConstants, statusAllEnglishOptions } from '../../constants/workOrders'
import {
    EditFilled,
    DeleteOutlined
} from '@ant-design/icons'
const { Text, Link } = Typography

const dateFormat = 'MM/DD/YYYY'

const WorkOrders = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [loadingTable, setLoadingTable] = useState(false)
    const [woList, setWOList] = useState([])
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [language, setLanguage] = useState(0)
    const [status, setStatus] = useState('All')
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            render: (_, record) => {
              return <Flex gap={16}>
                    <Button 
                    type="primary" 
                    icon={<EditFilled twoToneColor={'white'}/>} 
                    onClick={e=> history.push(`/workorder/${record.id}`)}
                    />
                    {
                        (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com') &&
                        <Button 
                        type="primary" 
                        danger
                        icon={<DeleteOutlined twoToneColor={'white'}/>} 
                        onClick={e=> deleteWO(record.id)}
                        />
                    }
              </Flex>
            }
        },
        {
            title: titlesConstants['id'][language],
            dataIndex: 'number',
            key: 'number',
            rowScope: 'row',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: titlesConstants['priority'][language],
            dataIndex: 'priority',
            key: 'priority',
            render: (value) => {
                switch (value) {
                    case 'Low':
                        return <Link target="_blank">{value}</Link>
                    case 'Medium':
                        return <Text type='success'>{value}</Text>
                    case 'High':
                        return <Text type='warning'>{value}</Text>
                    default:
                        return <Text type='secondary'>None</Text>
                }
            }
        },
        {
            title: titlesConstants['category'][language],
            dataIndex: 'category',
            key: 'category',
            render: (value) => {
                return <Text type={value ?? 'secondary'}>{value ? value : 'None'}</Text>
            }
        },
        {
            title: titlesConstants['status'][language],
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
                let color = 'default'
                let text = 'None'
                if(value === 'Approved'){
                    color = 'green'
                    text = statusConstants['approved'][language]
                }else if(value === 'Overdue'){
                    color = 'red'
                    text = statusConstants['overdue'][language]
                }else if(value === 'Cancelled'){
                    color = 'default'
                    text = statusConstants['cancelled'][language]
                }else if(value === 'In Progress'){
                    color = 'magenta'
                    text = statusConstants['inProgress'][language]
                }else if(value === 'On Hold'){
                    color = 'cyan'
                    text = statusConstants['onHold'][language]
                }else if(value === 'Pending'){
                    color = 'gold'
                    text = statusConstants['pending'][language]
                }else if(value === 'Reopened'){
                    color = 'lime'
                    text = statusConstants['reopened'][language]
                }else if(value === 'Rejected'){
                    color = 'orange'
                    text = statusConstants['rejected'][language]
                }else if(value === 'Finished'){
                    color = 'blue'
                    text = statusConstants['finished'][language]
                }else if(value === 'Closed'){
                    color = 'geekblue'
                    text = statusConstants['closed'][language]
                }

                return <Tag color={color}>{text}</Tag>
            }
        },
        {
            title: titlesConstants['dueDate'][language],
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (value) => {
                return <Text>{`${value}`}</Text>
            }
        },
        {
            title: titlesConstants['staff'][language],
            dataIndex: 'mechanics',
            key: 'mechanics',
            render: (_, { mechanics }) => (
                <>
                {
                    mechanics.length > 0 ? 
                    mechanics.map((mechanic, key) => {
                        return (
                        <Tag color={'blue'} key={key}>
                            {mechanic.name.toUpperCase()}
                        </Tag>
                        );
                    })
                    :
                    <Tag color={'default'}>
                        {'None'}
                    </Tag>
                    }
                </>
              ),
        },
        {
            title: titlesConstants['buildingGroup'][language],
            dataIndex: 'buildingGroup',
            key: 'buildingGroup',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: titlesConstants['buildings'][language],
            dataIndex: 'buildings',
            key: 'buildings',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: titlesConstants['active'][language],
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Tag color='green'>{`Truck ${value} - Active`}</Tag>
            }
        },
        {
            title: titlesConstants['estimatedHours'][language],
            dataIndex: 'estimatedHours',
            key: 'estimatedHours',
            render: (value) => {
                return <Text>{`${Math.floor(value)} hours`}</Text>
            }
        },
    ]

    useEffect(() => {
        getWOList('')
    }, [status])

    const deleteWO = async(id) => {
        setLoadingTable(true)

        const url = api + '/wo/' + id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)

            let index = woList.findIndex(item => item.id === id)
            delete woList[index]
            setWOList(woList)


            openNotification('info', 'Info', 'WO Deleted')
            setLoadingTable(false)
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }
    
    const getWOList = async(term) => {
        setLoading(true)

        // const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        // const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/wo/search?status=${status}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    number: item.number,
                    priority: item.priority,
                    category: item.category,
                    status: item.status,
                    dueDate: item.dueDate ? dayjs(item.dueDate).format(dateFormat) : '-',
                    mechanics: item.mechanics,
                    buildingGroup: item.inspection.truck ? (item.inspection.truck.plant ? item.inspection.truck.plant.name : '-') : '-',
                    buildings: item.inspection.truck ? (item.inspection.truck.subplant ? item.inspection.truck.subplant : '-') : '-',
                    truck: item.inspection.truck ? item.inspection.truck.number: 'None',
                    estimatedHours: item.estimatedHours ? item.estimatedHours : 0
                })

            })
            setWOList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onLanguageChange = (event) => {
        setLanguage(event.target.value)
    }

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const onChangePicker = (value) => {
        setStatus(value)
    }

    const RenderLanguage = () => {
        return <Flex gap={'medium'}>
            <Radio.Group buttonStyle='solid' size='large' defaultValue={language} onChange={onLanguageChange}>
                <Radio.Button value={0}>English</Radio.Button>
                <Radio.Button value={1}>Spanish</Radio.Button>
            </Radio.Group>
        </Flex>
    }

    const RenderSearch = () => {
        return <CustomSearch 
            // onRangeChange={onRangeChange}
            // startDate={startDate}
            // endDate={endDate}
            // dateFormat={dateFormat}
            labelPicker={'status'}
            optionsPicker={statusAllEnglishOptions}
            onChangePicker={onChangePicker}
            defaultValuePicker={status}
            pickerWidth={'200px'}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'}>
            <Table 
            columns={columns}
            dataSource={woList}
            loading={loadingTable}
            style={{width: '100%'}}
            />
        </Flex>
    }

    return (
        <Layout
            title='Work Orders'
            description='Work Orders'
        >
            <Flex vertical gap={'large'}>
                <RenderLanguage/>
                <RenderSearch />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
            {contextHolder}
        </Layout>
    )
}

export default WorkOrders