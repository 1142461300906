import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'
import ClientPaymentPicker from '../../client/ClientPaymentPicker';

const ExpensesRender = ({currentExpense, expenses, setExpenses, toggleModal, edit, index}) => {

    const [yards, setYards] = useState(0);
    const [vendor, setVendor] = useState('');
    const [concept, setConcept] = useState('');
    const [customer, setCustomer] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [amount, setAmount] = useState(0);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        setCurrentExpense()
    }, [edit])
    

    const setCurrentExpense = () => {
        if(edit){
            setYards(currentExpense.yards);
            setVendor(currentExpense.vendor);
            setConcept(currentExpense.concept);
            setAmount(currentExpense.amount);
            setCustomer(currentExpense.customer);
            setCustomerName(currentExpense.customerName);
            setDate(new Date(currentExpense.date));
        }
    }

    const onChangeCustomer = (selectedCustomer) => {
        if (selectedCustomer) {
            const values = selectedCustomer.value.split(',')
            const number = selectedCustomer.label.split(' ', 1)
            let customerName = selectedCustomer.label
            customerName = customerName.replace(`${number} `, '')

            setCustomer(values[0])
            setCustomerName(customerName)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newExpense = {
            yards,
            vendor,
            concept,
            amount,
            customer,
            customerName,
            date
        };
        if(edit){
            expenses[index].yards = yards;
            expenses[index].vendor = vendor;
            expenses[index].concept = concept;
            expenses[index].amount = amount;
            expenses[index].customer = customer;
            expenses[index].customerName = customerName;
            expenses[index].date = date;
        }else{
            expenses.push(newExpense);
        }
        setExpenses(expenses);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Date</label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Customer</label>
                    <ClientPaymentPicker selectedOption={customer} action={onChangeCustomer} required={true}/>
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Yards</label>
                    <input className='form-control'
                        name='yards'
                        type='number'
                        value={yards}
                        onChange={e => setYards(e.target.valueAsNumber)}
                        placeholder='Yards'
                        min={0}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Vendor</label>
                    <input className='form-control'
                        name='vendor'
                        type='text'
                        value={vendor}
                        onChange={e => setVendor(e.target.value)}
                        placeholder='Vendor'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Concept</label>
                    <input className='form-control'
                        name='concept'
                        type='text'
                        value={concept}
                        onChange={e => setConcept(e.target.value)}
                        placeholder='Concept'
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Amount</label>
                    <input className='form-control'
                        name='amount'
                        type='number'
                        value={amount}
                        onChange={e => setAmount(e.target.valueAsNumber)}
                        placeholder='Amount'
                        min={0}
                        required
                    />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
            
        </form>
    )
}

export default ExpensesRender