import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateForecast } from '../../actions/forecast'
import Spinner from '../layout/Spinner'
import SellerPicker from '../seller/SellerPicker'
import DatePicker from 'react-datepicker'
import ClientPicker from '../client/ClientPicker'

import { api } from '../utils/Api'
import axios from 'axios'


const UpdateForecastModal = ({ updateForecast, seller, customer, period, yards, forecastID, parentCallback }) => {

    const [loading, setLoading] = useState(false)
    const [yardsExpected, setYardsExpected] = useState(yards)

    const customerName = useRef('')

    // useEffect(() => {
    //     // getForecast()
    //     // console.log(forecastID)
    // }, [])

    // To get current forecast's ID
    /*
    const getForecast = async () => {
        const url = api + `/forecast?period=${period}&seller=${seller}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            const forecastFound = data.filter((d) => d.period.substring(0, 10) === period && (d.customer ? d.customer === customer : d.customerName === customer))
            // console.log(forecastFound[0])
            forecastID.current = forecastFound[0]._id
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    */

    const onSubmit = (e) => {

        e.preventDefault()

        const formData = {
            yardsExpected: yardsExpected,
            status: 'update'
        }

        // console.log(formData)

        // updateForecast(forecastID.current, formData)
        updateForecastAxios(formData)

        parentCallback('edit')
    }

    const updateForecastAxios = async (formData) => {
        const url = api + `/forecast/${forecastID}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            console.log(formData)
            setLoading(true)
            const { data } = await axios.patch(url, formData)
            setLoading(false)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const EditForm = () => (
        <div>
            <form className='form'>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Customer</label>
                        <label className="form-control"> {customer} </label>
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Period</label>
                        <label className="form-control"> {period.substring(0, 7)} </label>
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Yards Expected</label>
                        <input
                            onChange={(e) => setYardsExpected(e.target.value)}
                            type="number"
                            name='yardsExpected'
                            className="form-control"
                            value={yardsExpected}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="form-group row">
                    <div className="col-6">
                        <button className='btn btn-primary' onClick={onSubmit}> Edit </button>
                    </div>
                </div>
            </form>
        </div>
    )

    return (
        <div>
            {
                loading
                    ? <Spinner />
                    : EditForm()
            }
        </div>
    )
}

const mapStateToProps = state => ({
    forecast: state.forecast
})

export default connect(mapStateToProps, { updateForecast })(UpdateForecastModal)
