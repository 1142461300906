import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button, Tag } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
const { Text } = Typography

const typeOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Active',
        value: 'Active'
    },
    {
        label: 'Inactive',
        value: 'Inactive'
    },
]

const DriverList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [active, setActive] = useState('All')
    const [driverList, setDriverList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return (localStorage.getItem('email') === 'ec@57concrete.com' || 
                    localStorage.getItem('email') === 'eliud@gmail.com' ||
                    localStorage.getItem('email') === 'fc@57concrete.com' ||
                    localStorage.getItem('email') === 'jp@57concrete.com' ||
                    localStorage.getItem('email') === 'ji@57concrete.com' 
                    ) ? 
                <Button 
                type="primary" 
                icon={<EditFilled twoToneColor={'white'}/>} 
                onClick={e=> history.push(`/driver/${record.id}`)}
                size='large'
                />
                :
                ''
            }
        },
        {
            title: '',
            dataIndex: 'active',
            render: (value) => {
              return <Tag color={value ? 'green' : 'red'}>{value ? 'Active' : 'Inactive'}</Tag>
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'License',
            dataIndex: 'license',
            key: 'license',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Working Hours',
            dataIndex: 'workingHours',
            key: 'workingHours',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (value) => {
                return <Text>{value}</Text>
            }
        }
    ]

    useEffect(() => {
        getDriverList(active)
    }, [active])
    
    const getDriverList = async(active) => {
        setLoading(true)

        const url = api + '/driver/list/active/' + active
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    name: item.name,
                    phone: item.phone,
                    license: item.license ? item.license : 'N/A',
                    plant: item.plant ? item.plant.name : 'N/A',
                    workingHours: item.workingHours,
                    comment: item.comment ? item.comment : 'N/A',
                    active: item.active ? item.active : false
                })
            })
            setDriverList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewDriver = () => {
        history.push('/drivernew')
    }

    const onChangePicker = (value) => {
        setActive(value)
    }

    const RenderType = () => {
        return <CustomSearch 
            labelPicker={'type'}
            optionsPicker={typeOptions}
            onChangePicker={onChangePicker}
            defaultValuePicker={active}
            pickerWidth={'200px'}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || 
                localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com' ||
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ji@57concrete.com' 
                ) &&
                <Button
                    onClick={addNewDriver}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                    size='large'
                >
                    New Driver
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={driverList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Driver'
            description='Driver List'
        >
            <Flex vertical gap={'large'}>
                <RenderType />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default DriverList