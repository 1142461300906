import React, { useRef, useState } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { createPlant } from '../../actions/plant'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import AddressGeolocation from '../map/AddressGeolocation'
import Modal from 'react-bootstrap/esm/Modal'
import axios from 'axios'
import { api } from '../utils/Api'
import SubplantModal from './SubplantModal'

const PlantNew = ({ createPlant, history }) => {
    const initialState = {
        number: '',
        name: '',
        address: '',
        // timeToLoadTruck: '',
        // productionCapacity: '',
        // slumpRackTime: '',
        // washoutOnSite: '',
        // startTime: new Date(),
        // closingTime: new Date(),
        // radioAttention: '',
        // dieselPerformance: '',
        subplants: [],
        latitude: '',
        longitude: '',
        haulingCost: ''
    }

    const [formData, setFormData] = useState(initialState)
    const [searchAddress, setSearchAddress] = useState(false)
    const [latitudeValue, setLatitudeValue] = useState('')
    const [longitudeValue, setLongitudeValue] = useState('')
    const [addressValue, setAddressValue] = useState('')

    const [subplants, setSubplants] = useState([])
    const [subplantModal, setSubplantModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const subplant = useRef('')
    const edit  = useRef(false)

    const {
        number,
        name,
        address,
        // timeToLoadTruck,
        // productionCapacity,
        // slumpRackTime,
        // washoutOnSite,
        // startTime,
        // closingTime,
        // radioAttention,
        // dieselPerformance,
        latitude,
        longitude,
        haulingCost,
    } = formData

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
    //2030 Turner St San Benito Tx

    const onSubmit = e => {
        e.preventDefault()
        formData['subplants'] = subplants
        const { body } = createPlant(formData)
        createHaulingcost(body)
        history.push('/plantlist')
    }

    const createHaulingcost = async (formData) => {
        const url = api + '/haulingcost'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const haulingCost = {
                cost: formData.haulingCost,
                plant: formData._id,
                createdBy: localStorage.getItem('userId'),
            }
            await axios.post(url, haulingCost)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // show/hide search modal
    const onShowModal = (e) => {
        setSearchAddress(!searchAddress)
    }

    // show/hide subplant modal
    const onShowSubplantModal = () => {
        setSubplantModal(!subplantModal)
        edit.current = false;
    }

    const selectCurrentSubplant = (currentSubplant) => {
        subplant.current = currentSubplant;
        edit.current = true;
        setSubplantModal(!subplantModal)
    }

    const removeCurrentSubplant = (index) => {
        setSubplants(subplants.filter((v, i) => i !== index));
    }

    // address from modal
    const handleCallback = (childData) => {
        setFormData({ ...formData, ['address']: childData.address, ['latitude']: parseFloat(childData.destLat), ['longitude']: parseFloat(childData.destLng) })

        setLatitudeValue(childData.destLat)
        setLongitudeValue(childData.destLng)
        setAddressValue(childData.address)
        setSearchAddress(!searchAddress)
    }

    const RenderSubplants = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Production Capacity</th>
                        <th>Slump Rack Time</th>
                        <th>Time To Load Truck</th>
                        <th>Washout On Site</th>
                        <th>Start Time</th>
                        <th>Closing Time</th>
                        <th>Radio Attention</th>
                        <th>Diesel Performance</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        subplants.length === 0 ?
                        <tr>
                            <td colSpan={10} className='text-center'>No subplants registered</td>
                        </tr>
                        :
                        subplants.map((subplant, index) => (
                            <tr key={index}>
                                <td>{subplant.name}</td>
                                <td>{subplant.productionCapacity}</td>
                                <td>{subplant.slumpRackTime}</td>
                                <td>{subplant.timeToLoadTruck}</td>
                                <td>{subplant.washoutOnSite}</td>
                                <td>{`${new Date(subplant.startTime).getHours()} : ${new Date(subplant.startTime).getMinutes()}`}</td>
                                <td>{`${new Date(subplant.closingTime).getHours()} : ${new Date(subplant.closingTime).getMinutes()}`}</td>
                                <td>{subplant.radioAttention}</td>
                                <td>{subplant.dieselPerformance}</td>
                                <td>
                                    <input type='checkbox' checked={subplant.active} readOnly />
                                </td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        selectCurrentSubplant(subplant);
                                        setCurrentIndex(index)
                                    }}
                                    >Edit</button>
                                    {/* &nbsp;
                                    <button className='btn btn-danger' onClick={() => removeCurrentSubplant(index)}>Delete</button> */}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    const PlantForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Number</label>
                    <input
                        onChange={onChange}
                        type="number"
                        name='number'
                        className="form-control"
                        value={number}
                        placeholder="Plant Number"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Name</label>
                    <input
                        type="text"
                        name='name'
                        className='form-control'
                        onChange={onChange}
                        value={name}
                        placeholder='Plant Name'
                        required
                    />
                </div>
            </div>
            <div>
                <label className="text-muted"></label>
            </div>
            <div className="form-group row">
                <div className='col-sm-4'>
                    <button className='btn btn-primary' onClick={onShowModal}>
                        Search address <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                {
                    searchAddress &&
                    <Modal show={searchAddress} animation={false} onHide={onShowModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressGeolocation
                                search={searchAddress}
                                parentCallback={handleCallback}
                                single={true}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
            <div className="form-group row">
                <div className='col-sm-12'>
                    <label className="text-muted">Address</label>
                    <input
                        onChange={onChange}
                        type="text"
                        name='address'
                        className="form-control"
                        value={addressValue}
                        placeholder="Plant Address"
                        onKeyPress={() => {return false}}
                        required
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Latitude</label>
                    <input
                        onChange={onChange}
                        type="number"
                        name='latitude'
                        className="form-control"
                        value={latitudeValue}
                        placeholder="Latitude"
                        onKeyPress={() => {return false}}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Longitude</label>
                    <input
                        type="number"
                        name='longitude'
                        className='form-control'
                        onChange={onChange}
                        value={longitudeValue}
                        placeholder='Longitude'
                        onKeyPress={() => {return false}}
                        required
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Hauling Cost</label>
                    <input
                        type="number"
                        name='haulingCost'
                        className='form-control'
                        onChange={onChange}
                        value={haulingCost}
                        placeholder='Hauling Cost'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Subplants</label>
                        <div>
                            <button type='button' className='btn btn-primary' onClick={onShowSubplantModal}>+ Add</button>
                        </div>
                        <RenderSubplants />
                    </div>
                </div>
            {/* <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Time to load Truck</label>
                    <input
                        type="number"
                        name='timeToLoadTruck'
                        className='form-control'
                        onChange={onChange}
                        value={timeToLoadTruck}
                        placeholder='Time to load truck'
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Production Capacity</label>
                    <input
                        type="number"
                        name='productionCapacity'
                        className='form-control'
                        onChange={onChange}
                        value={productionCapacity}
                        placeholder='Production capacity'
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Slump Rack Time</label>
                    <input
                        type="number"
                        name='slumpRackTime'
                        className='form-control'
                        onChange={onChange}
                        value={slumpRackTime}
                        placeholder='Slump Rack Time'
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Washout on site</label>
                    <input
                        type="number"
                        name='washoutOnSite'
                        className='form-control'
                        onChange={onChange}
                        value={washoutOnSite}
                        placeholder='Washout On Site'
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Start Time</label>
                    <DatePicker
                    id='startTime'
                    selected={startTime}
                    onChange={e => setFormData({ ...formData, ['startTime']: e })}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Closing Time</label>
                    <DatePicker
                    id='closingTime'
                    selected={closingTime}
                    onChange={e => setFormData({ ...formData, ['closingTime']: e })}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Radio Attention</label>
                    <input
                        type="number"
                        name='radioAttention'
                        className='form-control'
                        onChange={onChange}
                        value={radioAttention}
                        placeholder='Radio Attention'
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Diesel Performance</label>
                    <input
                        type="number"
                        name='dieselPerformance'
                        className='form-control'
                        onChange={onChange}
                        value={dieselPerformance}
                        placeholder='Diesel Performance'
                    />
                </div>
            </div> */}
            <div className="my-2">
                <input className='btn btn-primary' placeholder='New' type='submit' />
            </div>
        </form>
    )

    return (
        <Layout
            title='Plant'
            description='New Plant registration form'
            className='container col-md-8 offset-md-2'
        >
            {PlantForm()}
            {
                subplantModal &&
                <Modal show={subplantModal} animation={false} onHide={onShowSubplantModal} size='sm' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Subplant</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SubplantModal subplants={subplants} setSubplants={setSubplants} toggleModal={onShowSubplantModal} currentPlant={subplant.current} edit={edit.current} index={currentIndex} />
                    </Modal.Body>
                </Modal>
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    user: state.auth.usuario
})

export default connect(mapStateToProps, { createPlant })(PlantNew)