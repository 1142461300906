import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import { forecastBySeller } from '../../actions/forecast'
import { groupSeller } from '../../actions/job'
import { toThousands, porcentage, getDaysInMonth } from '../utils/Convert'
import { api } from '../utils/Api'
import axios from 'axios'
import { Link } from 'react-router-dom'

const SellerReport = ({ forecastBySeller, groupSeller, forecast: { forecasts, loading }, yards }) => {

    const [sellerList, setSellerList] = useState([])
    const [realValues, setRealValues] = useState()
    const [expectedValues, setExpectedValues] = useState([])
    const [users, setUsers] = useState([])

    const [didMount, setDidMount] = useState(false)

    const today = new Date()

    useEffect(() => {
        groupSeller('false')
        forecastBySeller('false')
        getUsers()
        setDidMount(true)
    }, [])


    useEffect(() => {
        if (didMount) {
            if (yards) filterYards()
        }
    }, [yards])

    useEffect(() => {
        if (didMount) {
            if (forecasts) {
                const filteredForecasts = forecasts.filter((x) => x._id.year === today.getFullYear() && x._id.month === (today.getMonth() + 1))
                setExpectedValues(filteredForecasts)
            }
        }
    }, [forecasts])


    const getUsers = async () => {
        const url = api + `/userlist/Seller`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setUsers(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const filterYards = () => {

        let currentYear = today.getFullYear()
        let currentMonth = today.getMonth() + 1

        const real = new Map()
        const expected = new Map()

        const filteredYards = yards.filter((yard) => yard._id.year === currentYear && yard._id.month === currentMonth)
        // const filteredForecasts = forecasts.filter((x) => x._id.year === currentYear && x._id.month === currentMonth)

        filteredYards.forEach((elem) => {

            const seller = elem._id.seller
            const yards = elem.yards

            if (real.has(seller)) {
                let currYards = real.get(seller)
                currYards += yards
                real.set(seller, currYards)
            } else {
                real.set(seller, yards)
            }

        })

        setRealValues(real)
    }
    

    const DrawList = () => {

        const displayList = expectedValues.map((current, i) => {

            let name = users.filter(user => user._id === current._id.seller)
            let sellerName = name[0] ? name[0].name : current._id.seller
            const seller = current._id.seller
            const target = Math.ceil(realValues.get(seller))
            const real = current.yards

            const percentYards = porcentage(real, target)

            const required = Math.ceil(today.getDate() / getDaysInMonth(today.getMonth() + 1) * 100)
            const percentStatus = required - percentYards
            const yardsStatus = Math.ceil(real * (percentStatus/100))

            return (
                <tr key={sellerName}>

                    <td>
                        <Link to={`selleraccomplishment/${current._id.seller}`} >
                            {sellerName}
                        </Link>
                    </td>
                    <td style={{ textAlign: "right"}}> {toThousands(real)} </td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}> {toThousands(target)} </td>
                    <td style={{ textAlign: "right" }}> 
                        {`${percentYards}%`}
                    </td>
                    <td style={{ textAlign: "right" }}> {required}% </td>
                    {
                        (real < target) ? 
                        <td className="text-success" style={{ textAlign: "right" }}> {toThousands(yardsStatus)} </td> 
                        : <td className="text-danger" style={{ textAlign: "right" }}> {toThousands(yardsStatus)} </td>
                    }
                    {
                        percentStatus >= required ? 
                        <td className="text-success" style={{ textAlign: "right" }}> {percentStatus}% </td> 
                        : <td className="text-danger" style={{ textAlign: "right" }}> {percentStatus}% </td>
                    }                    
                </tr>
            )
        })

        displayList.length > 0 && displayList.sort((a, b) => a.key > b.key ? 1 : -1)

        return displayList
    }

    const RenderTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Seller</th>
                        <th>Plan</th>
                        <th>Real</th>
                        <th>%</th>
                        <th>Required</th>
                        <th>Status (YD3)</th>
                        <th>Status (%)</th>                        
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
    )


    return (
        <div>
            {
                loading ? <Spinner /> : (realValues && <RenderTable />)
            }
        </div>
    )
}

const mapStateToProps = state => ({
    forecast: state.forecast,
    yards: state.job.yards
})

export default connect(mapStateToProps, { forecastBySeller, groupSeller })(SellerReport)