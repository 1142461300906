import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DataGrid from 'react-data-grid';
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import Modal from 'react-bootstrap/Modal'
import { CSVLink } from "react-csv"
import { api } from '../utils/Api'
import { Link, useHistory } from 'react-router-dom';
import StatusPicker from './StatusPicker'
import { toCurrency, toThousands } from '../utils/Convert'
import DescriptionPicker from './DescriptionPicker';

const ReportPaymentDriver = () => {
    const [columns, setColumns] = useState([])
    const [columns2, setColumns2] = useState([])
    const [rows, setRows] = useState([])
    const [rows2, setRows2] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [dateChange, setDateChange] = useState(true)
    const [driverName, setDriverName] = useState('')
    const [fileName, setFileName] = useState('')
    const [loads, setLoads] = useState([])
    const [modal, setModal] = useState([])
    const [trips, setTrips] = useState(0)
    const [payment, setPayment] = useState(0)
    const [showLoad, setShowLoad] = useState(false)
    const [loading, setLoading] = useState(true)
    const [masterChecked, setMasterChecked] = useState(false)
    const [selectedList, setSelectedList] = useState([])
    const [conceptList, setConceptList] = useState([]);
    const [description, setDescription] = useState('');
    const [comment, setComment] = useState('');
    const [amount, setAmount] = useState('');
    const [update, setUpdate] = useState(false);
    let history = useHistory()

    useEffect(() => {
        chargeLoads()
    }, [startDate, endDate])

    const chargeLoads = () => {
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        if (endDate < startDate) {
            setEndDate(startDate)
            fechaFin = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        }

        if (dateChange) {
            setLoading(true)
            getLoads(fechaIni, fechaFin)
        }
    }

    const handleNewModal = (e) => {
        const loadsMod = loads.find((load) => load[0] === e.driver)

        const sizeLoads = loadsMod[1].length - 1

        if(sizeLoads >= 0 && loadsMod[1][sizeLoads].date === 'Total'){
            loadsMod[1].pop()
        }

        let totalObj = {
            date: 'Total',
            yards: '',
            driver: '',
            deliveryTicket: '',
            orderCod: '',
            shipCity: '',
            truckNumber: '',
            cityFee: e.total
        }
        loadsMod[1].push(totalObj)

        setTrips(e.trips)
        setPayment(e.total)
        setModal(loadsMod[0])
        setRows2(loadsMod[1])

        setSelectedList(loadsMod[1].filter((e) => e.selected && e.statusPayment !== 'paid'))

        setDriverName(e.driver)
        setFileName(`57concrete-${e.driver}-report.csv`)
        setShowLoad(true)

    }

    const columna1 = [
        { key: 'driver', name: 'Driver', resizable: true },
        { key: 'trips', name: 'Total Trips' },
        { key: 'total', name: 'Total Payment' }
    ]

    const columna2 = [
        { key: 'shipCity', name: 'City', resizable: true },
        { key: 'deliveryTicket', name: 'Ticket' },
        { key: 'truckNumber', name: 'Truck Number' },
        { key: 'date', name: 'Date', resizable: true },
        { key: 'cityFee', name: 'City Fee' },
    ]

    const headers1 = () => {
        return columna1.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const headers2 = () => {
        return columna2.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    // get only required attributes for report renderer
    const mapData = (loads, cities) => {
        const nuevo = loads.map((dato) => {
            const cityFound = cities.find((city) => city.city === dato.job.shipCity.toLowerCase())
            if (typeof cityFound === 'undefined') {
                return {
                    date: new Date(dato.date).toLocaleDateString(),
                    yards: dato.yards,
                    driver: dato.driver,
                    deliveryTicket: dato.deliveryTicket,
                    orderCode: dato.orderCode,
                    shipCity: dato.job.shipCity,
                    truckNumber: dato.truckNumber,
                    cityFee: 0,
                    selected: dato.selected,
                    statusPayment: dato.statusPayment,
                    loadId: dato._id
                }
            } else {
                return {
                    date: new Date(dato.date).toLocaleDateString(),
                    yards: dato.yards,
                    driver: dato.driver,
                    deliveryTicket: dato.deliveryTicket,
                    orderCode: dato.orderCode,
                    shipCity: dato.job.shipCity,
                    truckNumber: dato.truckNumber,
                    cityFee: cityFound.unitPrice,
                    selected: dato.selected,
                    statusPayment: dato.statusPayment,
                    loadId: dato._id
                }
            }

        })

        const result = nuevo.reduce(function (r, a) {
            r[a.driver] = r[a.driver] || [];
            r[a.driver].push(a);
            return r;
        }, Object.create(null));

        const resArr = Object.entries(result);

        const paySummary = resArr.map((driver) => {
            let sumTrip = 0
            let total = 0
            for (let i = 0; i < driver[1].length; i++) {
                const load = driver[1][i];
                sumTrip += 1
                total += load.cityFee
            }
            return {
                driver: driver[0],
                trips: toThousands(sumTrip),
                total: toCurrency(total, 2)
            }
        });
        setRows(paySummary.sort((a, b) => sortArr(a, b)))
        setLoads(resArr)
    }

    const sortArr = (a, b) => {
        if (a.trips < b.trips) {
            return 1
        } else if (a.trips > b.trips) {
            return -1
        } else {
            return a.driver.localeCompare(b.driver)
        }
    }

    const getLoads = async (startDate, endDate) => {
        const url = api + `/loadcity?startDate=${startDate}&endDate=${endDate}`
        const url2 = api + `/deliveryfee`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const req1 = axios.get(url)
        const req2 = axios.get(url2)

        axios.all([req1, req2]).then(axios.spread((...responses) => {
            const res1 = responses[1]
            const res2 = responses[0]
            mapData(res2.data, res1.data)
            setColumns(columna1)
            setColumns2(columna2)
            setLoading(false)
        })).catch(errors => {
            console.log(errors)
            return null
        })
    }

    // Select/ UnSelect Table rows
    const onMasterCheck = (e) => {
        let tempList = rows2
        // Check/ UnCheck All Items
        tempList.map((load) => {
            if(load.date !== 'Total' && load.statusPayment !== 'paid') load.selected = e.target.checked
        });
        //Update State
        setRows2(tempList)
        setMasterChecked(e.target.checked)
        setSelectedList(tempList.filter((e) => e.selected && e.statusPayment !== 'paid'))
    }

    // Update List Item's state and Master Checkbox State
    const onItemCheck = (e, item) => {
        let tempList = rows2;
        tempList.map((load) => {
        if (load.deliveryTicket === item.deliveryTicket) {
            load.selected = e.target.checked;
        }
        });

        //To Control Master Checkbox State
        const totalItems = tempList.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        setMasterChecked(totalItems === totalCheckedItems)
        setRows2(tempList)
        setSelectedList(tempList.filter((e) => e.selected))
    }

    const addField = () => {
        if(description !== '' && amount !== ''){
            const list = conceptList
            list.push({description: description, amount: Number(amount), comment: comment})
            setConceptList(list)
            setDescription('')
            setComment('')
            setAmount('')
        }
        setUpdate(!update)
    }

    const removeField = (i) => {
        const list = [...conceptList]
        list.splice(i, 1)
        setConceptList(list)
    }

    const updateLoad = async(load) => {
        const url = api + `/load/${load.loadId}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const bodyLoad = {
                'statusPayment': load.statusPayment,
                'selected': load.selected
            }
            await axios.patch(url, bodyLoad)
            
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const createDriverPayment = async() => {
        const url = api + '/driverpayment'
        const url2 = api + '/paymentnumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const response = await axios.get(url2)

            if(response){
                const loads = selectedList.map((item) => {
                    const newItem = {'load': item.loadId, 'cityFee': item.cityFee}
                    return newItem
                })

                selectedList.map((load) => {
                    load.statusPayment = 'paid'
                    updateLoad(load)
                })
                
                const user = localStorage.getItem('userId')
                const bodyLoad = {
                    'driver': driverName,
                    'date': new Date(),
                    'startDate': startDate,
                    'endDate': endDate,
                    'paymentNumber': response.data.paymentNumber.toString(),
                    'loads': loads,
                    'extras': conceptList,
                    'createdBy': user,
                    'updatedBy': user,
                }

                const {data} = await axios.post(url, bodyLoad)

                history.push({
                    pathname: `/driverinvoice/${data._id}`, 
                })
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const onSave = () =>{
        rows2.map((load, i) => {
            if(i < rows2.length-1){
                updateLoad(load)
            }
        })
        chargeLoads()
        setShowLoad(false)
        chargeLoads()
    }

    const preInvoice = () => {
        rows2.map((load, i) => {
            if(i < rows2.length-1){
                updateLoad(load)
            }
        })
        history.push({
            pathname: '/driverpreinvoice', 
            state:{concepts: conceptList, selectedList: selectedList, startDate: startDate, endDate: endDate, driver: driverName}
        })
    }

    const createInvoice = () => {
        createDriverPayment()
    }

    const showInvoices = () => {
        history.push({
            pathname: '/driverinvoices', 
            state:{driver: driverName}
        })
    }

    const onChangeDescription = selectedDescription => {
        if(selectedDescription){
            setDescription(selectedDescription.label)
            const values = selectedDescription.value.split(" ")
            setAmount(parseFloat(values[0]))
        }
    }

    const TableRenderer = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col'>
                            <input
                            type="checkbox"
                            className="form-check-input"
                            checked={masterChecked}
                            id="mastercheck"
                            onChange={(e) => onMasterCheck(e)}
                            />
                        </th>
                        {
                            columns2.map((column)=>{
                                return <th scope='col' key={column.key}>{column.name}</th>
                            })
                        }
                        <th scope='col'>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows2.map((row, key)=>{
                            return <tr key={key} className={row.selected ? "selected" : ""}>
                                {
                                    key < rows2.length-1 ? 
                                    (
                                        row.statusPayment === 'paid' ? 
                                        <th></th>
                                        :
                                        <th scope="row">
                                            <input
                                                type="checkbox"
                                                checked={row.selected}
                                                className="form-check-input"
                                                id={`rowcheck${row.deliveryTicket}`}
                                                onChange={(e) => onItemCheck(e, row)}
                                            />
                                        </th>
                                    )
                                    :
                                    <th></th>
                                }
                                <td>{row.shipCity}</td>
                                <td>{row.deliveryTicket}</td>
                                <td>{row.truckNumber}</td>
                                <td>{row.date}</td>
                                <td>{toCurrency(row.cityFee,2)}</td>
                                {
                                    key < rows2.length-1 ? 
                                    row.statusPayment === 'paid' ?
                                    <td>Paid</td>
                                    :
                                    <td><StatusPicker load={row} /></td>
                                    :
                                    <td></td>
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table>
        )
    }

    const ConceptRenderer = () =>{
        return <div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Default Descriptions</label>
                    <DescriptionPicker action={onChangeDescription}  />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Description</label>
                    <input
                        type="text"
                        name='description'
                        label='description'
                        className="form-control"
                        onBlur={e => setDescription(e.target.value)}
                        defaultValue={description}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className="text-muted">Comment</label>
                    <input
                        type="text"
                        name='comment'
                        label='comment'
                        className="form-control"
                        onBlur={e => setComment(e.target.value)}
                        defaultValue={comment}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className="text-muted">Amount</label>
                    <input
                        type="number"
                        name='amount'
                        label='amount'
                        className="form-control"
                        onBlur={e => setAmount(e.target.value)}
                        defaultValue={amount}
                    />
                </div>
                <div className='col-sm-2'>
                    <label className="text-muted"></label>
                    <button className="form-control bg-success text-center text-white" onClick={() => addField()}> + </button>
                </div>
            </div>

            {
                conceptList.map((concept, i) => {
                    return (
                        <div key={i} className='form-group row'>
                            <div className='col-sm-4'>
                                <label className="text-muted">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={concept.description}
                                    disabled
                                />
                            </div>
                            <div className='col-sm-4'>
                                <label className="text-muted">Comment</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={concept.comment}
                                    disabled
                                />
                            </div>
                            <div className='col-sm-2'>
                                <label className="text-muted">Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={concept.amount}
                                    disabled
                                />
                            </div>
                            <div className='col-sm-2'>
                                <label className="text-muted"></label>
                                <button className="form-control bg-danger text-center text-white" onClick={() => removeField(i)}> - </button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    }

    return (
        <Layout
            title='Report'
            description='Driver Payment Report'
        >
            <div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className='text-muted'>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>End Date</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                </div>
            </div>
            <br />
            {
                loading ? <Spinner /> :
                    <div>
                        * Select a row to see the report for a driver
                        <DataGrid
                            className='rdg-light'
                            columns={columns}
                            rows={rows}
                            onRowClick={(e) => handleNewModal(e)}
                            style={{ cursor: "pointer" }}
                        />
                        <Modal show={showLoad} animation={false} onHide={() => setShowLoad(false)} size='xl'>
                            <Modal.Header closeButton>
                                <Modal.Title>{modal} <br />Total trips =  {trips}  <br /> Total payment =  {payment}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <TableRenderer />
                                <ConceptRenderer />
                                &nbsp;
                                <div >
                                    <CSVLink data={rows2} headers={headers2()} filename={fileName} className='btn btn-primary'>
                                        Download report
                                    </CSVLink>
                                    &nbsp;
                                    <button className="btn btn-secondary" onClick={preInvoice} disabled={selectedList.length === 0}>Pre-Invoice</button>
                                    &nbsp;
                                    <button className="btn btn-warning" onClick={createInvoice} disabled={selectedList.length === 0}>Invoice</button>
                                    &nbsp;
                                    <button className="btn btn-dark" onClick={showInvoices}>Show Invoices</button>
                                    &nbsp;
                                    <button className="btn btn-success" onClick={onSave}>Save</button>
                                </div>

                            </Modal.Body>
                        </Modal>
                        <CSVLink data={rows} headers={headers1()} filename={"57concrete-report.csv"}>
                            Download report
                        </CSVLink>
                    </div>
            }
        </Layout>
    )
}

export default ReportPaymentDriver