import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'

const Job = props => (
    <tr>                
        <td><Link to={'/job/'+props.job._id}>{props.job.number} </Link></td>  
        <td>{props.job.shipAddress}</td>
        <td>{props.job.shipCity}</td>
        <td>{props.job.seller.name}</td>
        <td>{props.job.loadYD3}</td>
        <td>{props.job.targetYD3}</td>
        <td>{new Date(props.job.scheduledDate).toLocaleDateString()}</td>        
    </tr>
)

export default class JobForCustomer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            customerId: '',
            loading: true
        }

        this.goCreateJob = this.goCreateJob.bind(this)
    }

    getJobs = async () => {
        const url = api + '/joblist/' + this.props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            this.setState({
                loading: false,
                jobs: data,
                customerId: this.props.match.params.id
            })

            
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    componentDidMount() {
        this.getJobs()
    }

    goCreateJob() {
        const url = '/jobnuevo'
        this.props.history.push(url)    
    }

    losJobs() {
        return this.state.jobs.map((currentJob, i) => {
            return <Job job={currentJob} key={i} />
        })
    }

    renderLoading() {
        return <Spinner />
    }

    renderJobs() {
        return (
            <Fragment>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Job</th>
                            <th>Ship Address</th>
                            <th>Locality</th>
                            <th>Seller</th>
                            <th>Load YD3</th>
                            <th>Target YD3</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.losJobs() }
                    </tbody>
                </table>
                <button className='btn btn-success' onClick={this.goCreateJob}>Create job</button>                                
            </Fragment>
        )
    }

    render() {
        return (
            <Layout
                title='Job '
                description='Job list'
            >   
                {
                    this.state.loading ?
                    this.renderLoading()
                    : this.renderJobs()
                }
            </Layout>
        )
    }
}