import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, DatePicker, Table, Button, Modal, Image, Statistic } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import {
    FileImageOutlined,
    CheckCircleOutlined,
    FilePdfOutlined,
    DeleteFilled,
    EditFilled
} from '@ant-design/icons'
const { Text, Title } = Typography

const WebLogisticsDashboard = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [loadingTable, setLoadingTable] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [sandList, setSandList] = useState([])
    const [gravelList, setGravelList] = useState([])
    const [peaGravelList, setPeaGravelList] = useState([])
    const [date, setDate] = useState(dayjs())
    const [evidence, setEvidence] = useState(null)
    const [openEvidenceModal, setOpenEvidenceModal] = useState(false)
    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Mine',
            dataIndex: 'mine',
            key: 'mine',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Quantity (ton)',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (value) => {
                return <Statistic title="" value={value} precision={2} valueStyle={{fontSize: 14}} />
            }
        },
        {
            title: 'Ticket',
            dataIndex: 'ticket',
            key: 'ticket',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Started Date',
            dataIndex: 'startedDate',
            key: 'startedDate',
            render: (value) => {
                return <Text>{value}</Text>                
            }
        },
        {
            title: 'Received Date',
            dataIndex: 'receivedDate',
            key: 'receivedDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            width: 100,
            render: (_, record) => {
              return <Flex gap={'small'}>
                {
                    !record.received &&
                    <Button 
                    type="primary" 
                    style={{backgroundColor: 'green'}}
                    icon={<CheckCircleOutlined twoToneColor={'white'}/>} 
                    onClick={e => updateRecord(record.id)}
                    loading={loadingButton}
                    disabled={loadingButton}
                    >Received</Button>
                }
                
                {
                    record.evidence &&
                    <Button 
                    type="primary" 
                    icon={<FileImageOutlined twoToneColor={'white'}/>} 
                    onClick={e => handleEvidenceOpen(record.evidence)}
                    loading={loadingButton}
                    disabled={loadingButton}
                    >Evidence</Button>
                }
                {
                    record.receivedDate !== '-' &&
                    <Button 
                    type="primary" 
                    style={{backgroundColor: 'red'}}
                    icon={<FilePdfOutlined twoToneColor={'white'}/>} 
                    onClick={e => history.push(`/logisticspdf/${record.id}`)}
                    loading={loadingButton}
                    disabled={loadingButton}
                    >PDF</Button>
                }
                {
                    (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'vm@57concrete.com') &&
                    <Button 
                    type="primary" 
                    style={{backgroundColor: 'red'}}
                    icon={<DeleteFilled twoToneColor={'white'}/>} 
                    onClick={e => deleteRecord(record.id)}
                    loading={loadingButton}
                    disabled={loadingButton}
                    >Delete</Button>
                }
                {
                    (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'vm@57concrete.com' || (localStorage.getItem('email') === 'jip@57concrete.com' && record.plant === 'San Benito')) &&
                    <Button 
                    type="primary" 
                    // style={{backgroundColor: 'blue'}}
                    icon={<EditFilled twoToneColor={'white'}/>} 
                    onClick={e => history.push(`/logisticstrip/${record.id}`)}
                    loading={loadingButton}
                    disabled={loadingButton}
                    >Edit</Button>
                }
              </Flex>
            }
        },
    ]

    useEffect(() => {
        let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`
        getTripList(dateString)
    }, [])
    
    const getTripList = async(date) => {
        setLoading(true)
        const url = api + `/logisticstrip/list?date=${date}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            
            let list = []
            data.map((item, index) => {

                let duration = '0'
                if(item.startedDate && item.receivedDate){
                    var diffMs = new Date(item.receivedDate) - new Date(item.startedDate)
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                    duration = `${diffHrs} hours, ${diffMins} minutes`
                }   

                list.push({
                    key: index,
                    id: item._id,
                    mine: item.mine,
                    material: item.material,
                    quantity: item.quantity,
                    ticket: item.ticket,
                    plant: item.plant.name,
                    driver: item.driver.name,
                    truck: item.truck.number,
                    evidence: item.evidence || null,
                    startedDate: item.startedDate ? `${dayjs(item.startedDate).toDate().toLocaleDateString()} - ${dayjs(item.startedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-',
                    receivedDate: item.receivedDate ? `${dayjs(item.receivedDate).toDate().toLocaleDateString()} - ${dayjs(item.receivedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-',
                    duration: duration,
                    received: item.received
                })
            })

            let dupes ={}
            list.forEach((item, index) => {
                dupes[item.ticket] = dupes[item.ticket] || []
                dupes[item.ticket].push(index)
            })
            
            Object.entries(dupes).forEach((item, index) => {
                if(item[1].length > 1){
                    item[1].map(val => list[val].duplicated = true)
                }
            })

            let sandList = list.filter(trip => trip.material === 'Sand')

            let gravelList = list.filter(trip => trip.material === 'Gravel')

            let peaGravelList = list.filter(trip => trip.material === 'Pea Gravel')

            setSandList(sandList)
            setGravelList(gravelList)
            setPeaGravelList(peaGravelList)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateRecord = async (id) => {
        setLoadingTable(true)
        setLoadingButton(true)
        const url = api + `/logisticstrip/update/jap/${id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let body = {
                received: true
            }
            const { data } = await axios.patch(url, body)
            let index = -1
            if(data.material === 'Sand'){
                index = sandList.findIndex(item => item.id === id)
                sandList[index].received = true
                sandList[index].receivedDate = `${dayjs(data.receivedDate).toDate().toLocaleDateString()} - ${dayjs(data.receivedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}`
                setSandList(sandList)
            }else if(data.material === 'Gravel'){
                index = gravelList.findIndex(item => item.id === id)
                gravelList[index].received = true
                gravelList[index].receivedDate = `${dayjs(data.receivedDate).toDate().toLocaleDateString()} - ${dayjs(data.receivedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}`
                setGravelList(gravelList)
            }else if(data.material === 'Pea Gravel'){
                index = peaGravelList.findIndex(item => item.id === id)
                peaGravelList[index].received = true
                peaGravelList[index].receivedDate = `${dayjs(data.receivedDate).toDate().toLocaleDateString()} - ${dayjs(data.receivedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}`
                setPeaGravelList(peaGravelList)
            }
            setLoadingTable(false)
            setLoadingButton(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const deleteRecord = async (id) => {
        setLoadingTable(true)
        const url = api + `/logisticstrip/delete/${id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`
            getTripList(dateString)
            setLoadingTable(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const RenderTables = () => {
        return <Flex gap='medium' justify='center' vertical>
            <Table 
            title={() => <Title level={2}>Sand Orders</Title>}
            columns={columns}
            dataSource={sandList}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
            loading={loadingTable}
            onRow={(record, index) => ({
                style: {
                    background: record.duplicated ?  '#b9d3fa' : 'white',
                }
            })}
            />
            <Table 
            title={() => <Title level={2}>Gravel Orders</Title>}
            columns={columns}
            dataSource={gravelList}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
            loading={loadingTable}
            onRow={(record, index) => ({
                style: {
                    background: record.duplicated ?  '#b9d3fa' : 'white',
                }
            })}
            />
            <Table
            title={() => <Title level={2}>Pea Gravel Orders</Title>} 
            columns={columns}
            dataSource={peaGravelList}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
            loading={loadingTable}
            onRow={(record, index) => ({
                style: {
                    background: record.duplicated ?  '#b9d3fa' : 'white',
                }
            })}
            />
        </Flex>
    }

    const onChangeDate = (date) => {
        let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`
        getTripList(dateString)
        setDate(date)
    }

    const handleEvidenceCancel = () => {
        setLoadingButton(false)
        setOpenEvidenceModal(false)
    }

    const handleEvidenceOpen = (evidence) => {
        setLoadingButton(true)
        setEvidence(evidence)
        setOpenEvidenceModal(true)
    }

    const RenderEvidence = () => {
        return <Modal title="Evidence Image" open={openEvidenceModal} onCancel={handleEvidenceCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <Flex align='center' justify='center'>
                <Image
                id='evidence'
                width={300}
                src={evidence}
                />
            </Flex>
        </Modal>
    }

    const RenderDate = () => {
        return <DatePicker value={date} format={'MM/DD/YYYY'} onChange={onChangeDate} size='large' allowClear={false} style={{width: 200}} />
    }

    return (
        <Layout
            title='Logistics Dashboard'
            description='Dashboard'
        >
            <Flex vertical gap={'small'}>
                <RenderDate />
                {
                    loading ? <CustomSpinner/> : <RenderTables/>
                } 
            </Flex>
            <RenderEvidence />
        </Layout>
    )
}

export default WebLogisticsDashboard