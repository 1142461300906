import React, { useState } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import DatePicker from 'react-datepicker'

const OrderSummary = () => {
    const [file, setFile] = useState('')
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [lines, setLines] = useState(0)

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', file)
        console.log(file)

        const url = api + '/txtfile'

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const day = (selectedDate.getDate() < 10 ? `0${selectedDate.getDate()}` : `${selectedDate.getDate()}`)
        const month = (selectedDate.getMonth() + 1 < 10 ? `0${selectedDate.getMonth() + 1}` : `${selectedDate.getMonth() + 1}`)
        const year = selectedDate.getFullYear()

        const fileDate = `${year}` + month + day

        formData.append('fileDate', fileDate)

        if (file.type !== 'text/plain') {
            alert('Invalid file type! You must select a txt file')
            return null
        }
        
        try {
            const { data } = await axios.post(url, formData, config)
            console.log(data)
            setLines(data.dataRows)
        } catch(err) {
            alert('File upload error')
            console.error(err.message)
            return null
        }        
    }

    const picked = (e) => {
        setFile(e.target.files[0])
        setIsFilePicked(true)
    }

    const OrderSummaryUploadForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className="form-group column">
                <div className='col-sm-4'>
                    <label className='text-muted'>Select File Date</label>
                </div>
                <div className='col-sm-4'>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date)
                        }}
                    />
                </div>
            </div>
            <br />
            <div className='form-group'>
                <input
                    type='file'
                    name='file'
                    id="files"
                    style={{ display: "none" }}
                    onChange={picked}
                />
                <label htmlFor="files" className='btn btn-primary'>Select "Order summary" txt file to upload</label>
            </div>
            {isFilePicked ? (
                <div>
                    <p>Filename: {file.name}</p>
                    {/* <p>Filetype: {file.type}</p> */}
                    <p>Size in Kbytes: {Math.floor(file.size / 1024)}</p>
                    <p>
                        Date of last file modification:{' '}
                        {file.lastModifiedDate ? file.lastModifiedDate.toLocaleDateString() : ''}
                    </p>
                    <input className='btn btn-success' value='Upload' type='submit' />
                </div>
            ) : (
                    <p>No file selected</p>
                )}           
            {
                lines > 0 &&
                <div>
                    File <strong>{file.name}</strong> uploaded with <strong>{lines} lines </strong> 
                    of valid data.
                </div>
            }
        </form>
    )

    return (
        <Layout
            title='Order Summary'
            description='File upload'
            className="container col-md-8 offset-md-2"
        >
            <OrderSummaryUploadForm />
        </Layout>
    )
}

export default OrderSummary