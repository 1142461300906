import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import CustomerTypePicker from '../customertype/CustomerTypePicker'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import PaymentTermPicker from '../paymentterm/PaymentTermPicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'
import { api } from '../utils/Api'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import { components } from "react-select"
import QuadrantPicker from '../quadrant/QuadrantPicker'
import Modal from 'react-bootstrap/Modal'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default class Prospection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            sms: '',
            address: '',
            prospectionNumber: '',
            contactType: 'Email',
            notifiableEvents: [],
            city: '',
            state: '',
            zip: '',
            contactPhone: '',
            contactFax: '',
            prospectionId: '',
            seller: '',
            prospectionType: '',
            contactName: '',
            accountsPayableContact: '',
            accountsPayableEmail: '',
            accountsPayablePhone: '',
            accountsPayableFax: '',
            creditAmount: '',
            paymentTerm: '',
            paymentType: '',
            creditRequested: false,
            loading: true,
            quadrantType: '',
            showErrorModal: false,
            messageModal: '',
            titleModal: '',
            customerCreation: null,
            prospectionStatus: '',
            showDate: false,
            scheduledDate: null,
            appointmentDate: null,
            proposalExist: false,
            proposal: ''
        }

        // fields
        this.onSubmit = this.onSubmit.bind(this)
    }

    // api request
    getProspection = async () => {
        const url = api + '/prospection/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                name: data.name,
                email: data.email,
                sms: data.sms,
                contactType: data.contactType,
                notifiableEvents: data.notifiableEvents,
                prospectionNumber: data.prospectionNumber,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                contactFax: data.contactFax,
                prospectionId: data._id,
                loading: false,
                prospectionType: data.prospectionType,
                contactName: data.contact ? data.contact.name : data.contactName,
                contactPhone: data.contact ? data.contact.phone : data.contactPhone,
                accountsPayableContact: data.accountsPayableContact,
                accountsPayableEmail: data.accountsPayableEmail,
                accountsPayablePhone: data.accountsPayablePhone,
                accountsPayableFax: data.accountsPayableFax,
                creditAmount: data.creditAmount,
                paymentTerm: data.paymentTerm,
                paymentType: data.paymentType,
                creditRequested: data.creditRequested,
                quadrantType: data.quadrantType ? data.quadrantType : '',
                customerCreation: data.customerCreation,
                prospectionStatus: data.prospectionStatus,
                appointmentDate: data.appointmentDate ? new Date(data.appointmentDate) : null,
                scheduledDate: data.appointmentDate ? new Date(data.appointmentDate) : new Date(),
                seller: data.createdBy._id
            })

            this.getProposal(data._id)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // change field values
    updateProspection = async (form, button) => {
        const url = api + '/prospection/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, form)

            if(button === 'save') this.props.history.push('/opportunitystatus')

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }


    onChangeSMS = e => {
        this.setState({
            sms: e
        });
    }
    // Notifications selection
    onChangeNotifiable = e => {
        if (e) {
            this.setState({
                notifiableEvents: e
            })
        }
    }

    // prospection type select
    onChangeProspectionType = selectedProspectionType => {
        if (selectedProspectionType) {
            this.setState({
                prospectionType: selectedProspectionType.label
            })
        }
    }

    // quadrant type select
    onChangeQuadrantType = selectedQuadrantType => {
        if (selectedQuadrantType) {
            this.setState({
                quadrantType: selectedQuadrantType.value
            })
        }
    }

    // payment type picker
    onChangePaymentType = selectedPayment => {
        if (selectedPayment) {
            this.setState({
                paymentType: selectedPayment.label
            })
        }
    }

    // payment term picker
    onChangePaymentTerm = selectedTerm => {
        if (selectedTerm) {
            this.setState({
                paymentTerm: selectedTerm.label
            })
        }
    }

    onChangeDate = date => {
        this.setState({
            scheduledDate: date
        })
    }

    onCreditRequest = e => {
        this.setState({
            creditRequested: !this.state.creditRequested
        })
    }

    deleteProspection = async () => {
        const url = api + '/prospection/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            this.props.history.push('/prospectionlist')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    getProposal = async (prospection) => {
        const url = api + `/proposalexist/${prospection}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                proposalExist: true,
                proposal: data._id
            })
        } catch (err) {
            this.setState({
                proposalExist: false
            })
            console.error(err.message)
            return null
        }
    }

    onSubmit(e) {
        e.preventDefault()

        if (this.state.name &&
            this.state.prospectionType &&
            this.state.quadrantType &&
            this.state.contactPhone && 
            (this.state.email || this.state.sms) &&
            this.state.address &&
            this.state.city &&
            this.state.state &&
            this.state.zip &&
            this.state.accountsPayableContact &&
            this.state.accountsPayablePhone &&
            this.state.paymentType &&
            this.state.paymentTerm) {
                const prospection = {
                    name: this.state.name,
                    email: this.state.email,
                    sms: this.state.sms,
                    contactType: this.state.contactType,
                    notifiableEvents: this.state.notifiableEvents,
                    address: this.state.address,
                    city: this.state.city,
                    state: this.state.state,
                    zip: this.state.zip,
                    contactPhone: this.state.contactPhone,
                    contactFax: this.state.contactFax,
                    prospectionType: this.state.prospectionType,
                    accountsPayableContact: this.state.accountsPayableContact,
                    accountsPayableEmail: this.state.accountsPayableEmail,
                    accountsPayablePhone: this.state.accountsPayablePhone,
                    accountsPayableFax: this.state.accountsPayableFax,
                    creditAmount: this.state.creditAmount,
                    paymentTerm: this.state.paymentTerm,
                    creditRequested: this.state.creditRequested,
                    quadrantType: this.state.quadrantType,
                    paymentType: this.state.paymentType
                }
                this.updateProspection(prospection, 'save')
        } else {
            let missingFields = []
            if(!this.state.name) missingFields.push('Company Name')
            if(!this.state.prospectionType) missingFields.push('Prospection Type')
            if(!this.state.quadrantType) missingFields.push('Quadrant Type')
            if(!this.state.contactPhone) missingFields.push('Contact Phone')
            if(this.state.contactType === 'Email'){
                if(!this.state.email) missingFields.push('Email')
            }else{
                if(!this.state.sms) missingFields.push('SMS')
            }
            if(!this.state.address) missingFields.push('Address')
            if(!this.state.city) missingFields.push('City')
            if(!this.state.state) missingFields.push('State')
            if(!this.state.zip) missingFields.push('ZIP')
            if(!this.state.accountsPayableContact) missingFields.push('A/P Contact')
            if(!this.state.accountsPayablePhone) missingFields.push('A/P Phone')
            if(!this.state.paymentType) missingFields.push('Payment Type')
            if(!this.state.paymentTerm) missingFields.push('Payment Term')

            let customeMessage = ''
            for(let i=0; i<missingFields.length; i++){
                if(i !== missingFields.length-1){
                    customeMessage += missingFields[i]+', '
                }else{
                    customeMessage += missingFields[i]
                }
            }

            this.setState({
                messageModal: `Missing fields: ${customeMessage}`,
                titleModal: 'Missing fields to fill'
            })
            this.toggleErrorModal()
        }
    }

    //Toggle error modal
    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal
        })
    }

    toggleDateModal = () => {
        this.setState({
            showDate: !this.state.showDate
        })
    }

    saveDate = () => {

        this.setState({
            appointmentDate: this.state.scheduledDate
        })

        const prospection = {
            appointmentDate: this.state.scheduledDate,
            prospectionStatus: 'Appointment'
        }

        this.updateProspection(prospection, 'date')
        this.toggleDateModal()
    }

    prospectionForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <div className='form-group'>
                <label className='text-muted'>Prospection Number</label>
                <input
                    type='text'
                    className='form-control'
                    readOnly
                    defaultValue={this.state.prospectionNumber}
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Company name</label>
                <input
                    type='text'
                    name="name"
                    className='form-control'
                    onChange={this.inputChangeHandler}
                    value={this.state.name}
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Prospection Type: {this.state.prospectionType} </label>
                <CustomerTypePicker action={this.onChangeProspectionType} placeholder={'Prospection Type...'}/>
            </div>
            <div className='form-group'>
                <label className='text-muted'>Quadrant Type: {this.state.quadrantType} </label>
                <QuadrantPicker action={this.onChangeQuadrantType} />
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Contact Name</label>
                    <input
                        name="name"
                        type="text"
                        readOnly
                        className="form-control"
                        defaultValue={this.state.contactName}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className="text-muted">Contact Phone</label>
                    <input
                        name="contactPhone"
                        type="text"
                        className="form-control"
                        value={this.state.contactPhone}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Type (*):</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='Email'
                                checked={this.state.contactType === "Email"}
                                onChange={this.inputChangeHandler}
                            />
                            Email
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='SMS'
                                checked={this.state.contactType === "SMS"}
                                onChange={this.inputChangeHandler}
                            />
                        </label>
                        SMS
                    </div>
                </div>
            </div>
            {this.state.contactType == "Email"
                ? <div className="form-group">
                    <input
                        name="email"
                        onChange={this.inputChangeHandler}
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        placeholder="Contact email"
                    />
                </div>
                : <div className="form-group">
                    <PhoneInput
                        name="sms"
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Prospection phone"
                        value={this.state.sms}
                        onChange={this.onChangeSMS}
                    />
                </div>
            }
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Address</label>
                    <input
                        onChange={this.inputChangeHandler}
                        type="text"
                        name='address'
                        className="form-control"
                        value={this.state.address}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>City</label>
                    <input
                        type="text"
                        name='city'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.city}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>State</label>
                    <input
                        type='text'
                        name='state'
                        className='form-control'
                        value={this.state.state}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>ZIP</label>
                    <input
                        type='text'
                        name='zip'
                        className='form-control'
                        value={this.state.zip}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Fax</label>
                    <input
                        type='text'
                        name='contactFax'
                        className='form-control'
                        value={this.state.contactFax}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Contact</label>
                    <input
                        type='text'
                        name='accountsPayableContact'
                        className='form-control'
                        placeholder='A/P Contact person'
                        value={this.state.accountsPayableContact}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Email</label>
                    <input
                        type='text'
                        name='accountsPayableEmail'
                        className='form-control'
                        value={this.state.accountsPayableEmail}
                        onChange={this.inputChangeHandler}
                        placeholder='A/P contact email'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Phone</label>
                    <input
                        type='text'
                        name='accountsPayablePhone'
                        className='form-control'
                        placeholder='A/P contact phone'
                        value={this.state.accountsPayablePhone}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Fax</label>
                    <input
                        type='text'
                        name='accountsPayableFax'
                        className='form-control'
                        value={this.state.accountsPayableFax}
                        onChange={this.inputChangeHandler}
                        placeholder='A/P contact fax'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Credit Amount</label>
                    <input
                        type='text'
                        name='creditAmount'
                        className='form-control'
                        placeholder='$0.0'
                        value={this.state.creditAmount}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Payment Term: {this.state.paymentTerm} </label>
                    <PaymentTermPicker action={this.onChangePaymentTerm} />
                </div>
            </div>
            <div className='form-group'>
                <input name="credit"
                    type="checkbox"
                    checked={this.state.creditRequested}
                    onChange={this.onCreditRequest}
                />
                <label>&nbsp;Request Credit</label>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Appointment Date</label>
                    {
                        this.state.appointmentDate ? 
                        <DatePicker
                                    selected={this.state.appointmentDate}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    disabled
                                />
                        :
                        <div>No appointment date</div>
                    }
                </div>
            </div>


            <div className="my-2">
                {
                    (this.state.customerCreation === null || this.state.customerCreation === undefined) && (this.state.prospectionStatus !== 'Accepted' && this.state.prospectionStatus !== 'Denied') &&
                    <input className='btn btn-success' value='Save' type='submit' />
                }
                &nbsp;
                {
                    (this.state.customerCreation === null || this.state.customerCreation === undefined) && (this.state.prospectionStatus === 'Contact' || this.state.prospectionStatus === 'Appointment') &&
                    <input className='btn btn-warning' value='Schedule Date' type='button' onClick={this.toggleDateModal} />
                }
                &nbsp;
                {
                    <Link to={{pathname:this.state.proposalExist ? `/proposal/${this.state.proposal}` : '/newproposal', state:{seller: this.state.seller, prospection: this.state.prospectionId}}} className='btn btn-primary' > Proposal </Link>
                }
                &nbsp;
                {
                    // this.state.role === 'Admin' && (this.state.customerCreation === null || this.state.customerCreation === undefined) &&
                    // <input
                    //     type="text"
                    //     className="btn btn-danger"
                    //     readOnly
                    //     value="Delete Prospection"
                    //     onClick={(e) => { if (window.confirm('Are you sure you want to delete the Prospection?')) this.deleteProspection() }}
                    // />
                }
            </div>
        </form>
    )

    componentDidMount() {
        this.getProspection()
    }

    render() {
        return (
            <Layout
                title={this.state.prospectionNumber + ' ' + this.state.name}
                description='Prospection detail'
                className="container col-md-8 offset-md-2"
            >
                {this.prospectionForm()}
                <Modal
                    show={this.state.showErrorModal}
                    animation={false}
                    onHide={this.toggleErrorModal}
                    size='lg'
                    centered
                    dialogClassName='modal-width'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.titleModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.messageModal}</p>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showDate}
                    animation={false}
                    onHide={this.toggleDateModal}
                    size='lg'
                    centered
                    dialogClassName='modal-width'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Appointment Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group row">
                            <div className='col-sm-6 pb-1'>
                                <div>
                                    <label className="text-muted">Schedule date/time</label>
                                </div>
                                <DatePicker
                                    selected={this.state.scheduledDate}
                                    minDate={this.state.scheduledDate}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    onChange={this.onChangeDate}
                                />
                            </div>
                            <div>
                                <button className='btn btn-success' onClick={this.saveDate}>Save</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Layout>
        )
    }
}