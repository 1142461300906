import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_BUILD_DEVELOPMENT,
    GET_BUILD_DEVELOPMENT,
    GET_BUILD_DEVELOPMENTS,
    DEVELOPMENT_ERROR,
    UPDATE_BUILD_DEVELOPMENT,
    DELETE_BUILD_DEVELOPMENT,
    BUILD_DEVELOPMENT_ERROR
} from './types'

// create new build development
export const createBuildDevelopment = (formData) => async (dispatch) => {
    try {
        const { data } = await api.post('/builddevelopment', formData)

        dispatch({
            type: ADD_BUILD_DEVELOPMENT,
            payload: data
        })

        dispatch(setAlert('Added', 'success'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: BUILD_DEVELOPMENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get build development using its ID
export const getBuildDevelopment = (id) => async (dispatch) => {
    try {
        const { data } = await api.get(`/builddevelopment/${id}`)

        dispatch({
            type: GET_BUILD_DEVELOPMENT,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: BUILD_DEVELOPMENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get list of all build developments
export const getBuildDevelopments = () => async (dispatch) => {
    try {
        const { data } = await api.get(`/builddevelopment`)

        dispatch({
            type: GET_BUILD_DEVELOPMENTS,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: BUILD_DEVELOPMENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// update a build development
export const updateBuildDevelopment = (id, formData) => async (dispatch) => {
    try {
        const { data } = await api.patch(`/builddevelopment/${id}`, formData)

        dispatch({
            type: UPDATE_BUILD_DEVELOPMENT,
            payload: data
        })

        dispatch(setAlert('Updated', 'success'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: BUILD_DEVELOPMENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// delete a build development
export const deleteBuildDevelopment = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/builddevelopment/${id}`)

        dispatch({
            type: DELETE_BUILD_DEVELOPMENT,
            payload: data
        })

        dispatch(setAlert('Deleted', 'danger'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: BUILD_DEVELOPMENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}