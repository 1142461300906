import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { createLoyalty } from '../../actions/loyalty'
import ForemanPicker from '../foreman/ForemanPicker'
import { api } from '../utils/Api'
import axios from 'axios'

const CreateLoyalty = ({seller, sellerName, createLoyalty, updateCB}) => {
    const year = '2022'
    const [jan, setJan] = useState(0)
    const [feb, setFeb] = useState(0)
    const [mar, setMar] = useState(0)
    const [apr, setApr] = useState(0)
    const [may, setMay] = useState(0)
    const [jun, setJun] = useState(0)
    const [jul, setJul] = useState(0)
    const [aug, setAug] = useState(0)
    const [sep, setSep] = useState(0)
    const [oct, setOct] = useState(0)
    const [nov, setNov] = useState(0)
    const [dec, setDec] = useState(0)
    const [foreman, setForeman] = useState('')
    const [foremanName, setForemanName] = useState('')

    const onChangeForeman = selectedForeman => {
        const val = selectedForeman.value
        console.log(selectedForeman)
        const sep = val.split(',')
        console.log(sep[0])
        setForemanName(selectedForeman.label)
        setForeman(sep[0])
    }

    const onSubmit = e => {
        e.preventDefault()
        const monthList = [
            { month: '01',yards: jan },
            { month: '02', yards: feb }, 
            { month: '03', yards: mar }, 
            { month: '04', yards: apr }, 
            { month: '05', yards: may }, 
            { month: '06', yards: jun }, 
            { month: '07', yards: jul }, 
            { month: '08', yards: aug }, 
            { month: '09', yards: sep }, 
            { month: '10', yards: oct }, 
            { month: '11', yards: nov }, 
            { month: '12', yards: dec }
        ]

        monthList.forEach(mes => {
            const period = `${year}-${mes.month}-01`
            const plan = {
                foreman,
                foremanName,
                period,
                yardsExpected: mes.yards,
                seller
            }
            createLoyalty(plan)
        })
        updateCB('create')
    }

    return (
        <div>
            <form className='form'>
                <div className='form-group'>
                    Seller: {sellerName}
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Foreman</label>
                        <ForemanPicker action={onChangeForeman} selectedOption={foremanName} />
                    </div>
                    <div className='col-sm-4'>
                        <label className="text-muted">Period</label>
                        <input
                            type='text'
                            className='form-control'
                            defaultValue={year}
                            readOnly
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">JAN</label>
                        <input
                            onChange={(e) => setJan(e.target.value)}
                            type="number"
                            name='JAN'
                            className="form-control"
                            value={jan}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">FEB</label>
                        <input
                            onChange={(e) => setFeb(e.target.value)}
                            type="number"
                            name='FEB'
                            className="form-control"
                            value={feb}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">MAR</label>
                        <input
                            onChange={(e) => setMar(e.target.value)}
                            type="number"
                            name='MAR'
                            className="form-control"
                            value={mar}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">APR</label>
                        <input
                            onChange={(e) => setApr(e.target.value)}
                            type="number"
                            name='APR'
                            className="form-control"
                            value={apr}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">MAY</label>
                        <input
                            onChange={(e) => setMay(e.target.value)}
                            type="number"
                            name='MAY'
                            className="form-control"
                            value={may}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">JUN</label>
                        <input
                            onChange={(e) => setJun(e.target.value)}
                            type="number"
                            name='JUN'
                            className="form-control"
                            value={jun}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">JUL</label>
                        <input
                            onChange={(e) => setJul(e.target.value)}
                            type="number"
                            name='JUL'
                            className="form-control"
                            value={jul}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">AUG</label>
                        <input
                            onChange={(e) => setAug(e.target.value)}
                            type="number"
                            name='AUG'
                            className="form-control"
                            value={aug}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">SEP</label>
                        <input
                            onChange={(e) => setSep(e.target.value)}
                            type="number"
                            name='SEP'
                            className="form-control"
                            value={sep}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">OCT</label>
                        <input
                            onChange={(e) => setOct(e.target.value)}
                            type="number"
                            name='OCT'
                            className="form-control"
                            value={oct}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">NOV</label>
                        <input
                            onChange={(e) => setNov(e.target.value)}
                            type="number"
                            name='NOV'
                            className="form-control"
                            value={nov}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">DEC</label>
                        <input
                            onChange={(e) => setDec(e.target.value)}
                            type="number"
                            name='DEC'
                            className="form-control"
                            value={dec}
                            placeholder='Yards expected...'
                            required
                        />
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-6">
                        <button className='btn btn-primary' onClick={onSubmit}> Save </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default connect(null, {createLoyalty})(CreateLoyalty)