import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import CustomerTypePicker from '../customertype/CustomerTypePicker'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import PaymentTermPicker from '../paymentterm/PaymentTermPicker'
import PhoneInput from 'react-phone-number-input'
import { api } from '../utils/Api'
import Modal from 'react-bootstrap/Modal'
import QuadrantPicker from '../quadrant/QuadrantPicker'

export default class ProspectionRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactName: '',
            email: '',
            sms: '',
            contactType: 'Email',
            contactPhone: '',
            contactFax: '',
            companyName: '',
            address: '',
            city: '',
            state: 'TX',
            zip: '',
            accountsPayableContact: '',
            accountsPayableEmail: '',
            accountsPayablePhone: '',
            accountsPayableFax: '',
            prospectionType: '',
            creditAmount: 0,
            paymentType: 'PrePay',
            paymentTerm: '',
            creditRequested: false,
            contact: '',
            quadrantType: '',
            prospectionNumber: '',
            showErrorModal: false,
            messageModal: '',
            titleModal: '',
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeSMS = e => {
        this.setState({
            sms: e
        });
    }

    // custprospectionomer type select
    onChangeProspectionType = selectedProspectionType => {
        if (selectedProspectionType) {
            this.setState({
                prospectionType: selectedProspectionType.label
            })
        }else{
            this.setState({
                prospectionType: ''
            })
        }
    }

    // quadrant type select
    onChangeQuadrantType = selectedQuadrantType => {
        if (selectedQuadrantType) {
            this.setState({
                quadrantType: selectedQuadrantType.value
            })
        }else{
            this.setState({
                quadrantType: ''
            })
        }
    }

    // payment type picker
    onChangePaymentType = selectedPayment => {
        if (selectedPayment) {
            this.setState({
                paymentType: selectedPayment.label
            })
        }else{
            this.setState({
                paymentType: ''
            })
        }
    }

    // payment term picker
    onChangePaymentTerm = selectedTerm => {
        if (selectedTerm) {
            this.setState({
                paymentTerm: selectedTerm.label
            })
        }else{
            this.setState({
                paymentTerm: ''
            })
        }
    }

    onCreditRequest = e => {
        this.setState({
            creditRequested: !this.state.creditRequested
        })
    }

    duplicatedUser = async () => {
        let correo = ''
        if (this.state.email) {
            correo = this.state.email
        } else {
            correo = this.state.contactPhone + '@gmail.com'
        }

        const url = api + '/user/' + correo
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    validateUser = async () => {
        const duplicado = await this.duplicatedUser()
        if (duplicado) {
            this.setState({
                messageModal: 'User already exist with Email / Contact Phone',
                titleModal: 'Duplicated user'
            })
            this.toggleErrorModal()
        } else {
            await this.createNewCompany()
        }
    }

    createNewCompany = async () => {
        try {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const user = localStorage.getItem('userId')
            const company = {
                ...this.state,
                'name': this.state.companyName,
                'createdBy': user,
                'updatedBy': user,
            }

            let url = api + '/prospection'

            await axios.post(url, company)

            this.props.history.push(`/prospectionlist`)

        } catch (err) {
            console.error(err)
            return null
        }
    }

    onSubmit(e) {
        e.preventDefault()

        if (this.state.companyName &&
            this.state.prospectionType &&
            this.state.quadrantType &&
            this.state.contactName &&
            this.state.contactPhone &&
            (this.state.email || this.state.sms) &&
            this.state.address &&
            this.state.city &&
            this.state.state &&
            this.state.zip &&
            this.state.accountsPayableContact &&
            this.state.accountsPayablePhone &&
            this.state.paymentType &&
            this.state.paymentTerm) {
                this.validateUser()
        } else {
            let missingFields = []
            if (!this.state.companyName) missingFields.push('Company Name')
            if (!this.state.prospectionType) missingFields.push('Prospection Type')
            if (!this.state.quadrantType) missingFields.push('Quadrant Type')
            if (!this.state.contactName) missingFields.push('Contact Name')
            if (!this.state.contactPhone) missingFields.push('Contact Phone')
            if (this.state.contactType === 'Email') {
                if (!this.state.email) missingFields.push('Email')
            } else {
                if (!this.state.sms) missingFields.push('SMS')
            }
            if (!this.state.address) missingFields.push('Address')
            if (!this.state.city) missingFields.push('City')
            if (!this.state.state) missingFields.push('State')
            if (!this.state.zip) missingFields.push('ZIP')
            if (!this.state.accountsPayableContact) missingFields.push('A/P Contact')
            if (!this.state.accountsPayablePhone) missingFields.push('A/P Phone')
            if (!this.state.paymentType) missingFields.push('Payment Type')
            if (!this.state.paymentTerm) missingFields.push('Payment Term')

            let customeMessage = ''
            for (let i = 0; i < missingFields.length; i++) {
                if (i !== missingFields.length - 1) {
                    customeMessage += missingFields[i] + ', '
                } else {
                    customeMessage += missingFields[i]
                }
            }

            this.setState({
                messageModal: `Missing fields: ${customeMessage}`,
                titleModal: 'Missing fields to fill'
            })
            this.toggleErrorModal()
        }
    }

    //Toggle error modal
    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal
        })
    }

    getProspectionNumber = async () => {
        const url = api + '/customernumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                prospectionNumber: 'A' + data.customerNumber.toString(),
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getProspectionNumber()
    }

    ClientForm = () => (
        <form className="form" onSubmit={this.onSubmit}>
            <div className="form-group">
                <label className="text-muted">Company Name (*)</label>
                <input
                    onChange={this.inputChangeHandler}
                    type="text"
                    name="companyName"
                    className="form-control"
                    placeholder="Name of the Company"
                    value={this.state.companyName}
                    minLength='3'
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Prospection Type (*)</label>
                <CustomerTypePicker action={this.onChangeProspectionType} placeholder={'Prospection Type...'} />
            </div>
            <div className="form-group">
                <label className="text-muted">Quadrant Type (*)</label>
                <QuadrantPicker action={this.onChangeQuadrantType} />
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Contact Name (*)</label>
                    <input
                        name="contactName"
                        onChange={this.inputChangeHandler}
                        type="text"
                        className="form-control"
                        value={this.state.contactName}
                        placeholder="Contact person"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className="text-muted">Contact Phone (*)</label>
                    <input
                        name="contactPhone"
                        onChange={this.inputChangeHandler}
                        type="text"
                        className="form-control"
                        value={this.state.contactPhone}
                        placeholder="Contact Phone Number"
                    />
                </div>
            </div>
            <div className="form-group">
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Type (*):</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='Email'
                                checked={this.state.contactType == "Email"}
                                onChange={this.inputChangeHandler}
                            />
                            Email
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='SMS'
                                checked={this.state.contactType == "SMS"}
                                onChange={this.inputChangeHandler}
                            />
                        </label>
                        SMS
                    </div>
                </div>
            </div>
            {this.state.contactType == "Email"
                ? <div className="form-group">
                    <input
                        name="email"
                        onChange={this.inputChangeHandler}
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        placeholder="Contact email"
                    />
                </div>
                : <div className="form-group">
                    <PhoneInput
                        name="sms"
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Prospection phone"
                        value={this.state.sms}
                        onChange={this.onChangeSMS}
                    />
                </div>
            }
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Address (*)</label>
                    <input
                        name="address"
                        onChange={this.inputChangeHandler}
                        type="text"
                        className="form-control"
                        value={this.state.address}
                        placeholder="Company Address"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>City (*)</label>
                    <input
                        type="text"
                        name='city'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.city}
                        placeholder='Company City Address'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>State (*)</label>
                    <input
                        type='text'
                        name='state'
                        className='form-control'
                        value={this.state.state}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>ZIP (*)</label>
                    <input
                        type='text'
                        name='zip'
                        className='form-control'
                        value={this.state.zip}
                        onChange={this.inputChangeHandler}
                        placeholder='Company ZIP'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Fax</label>
                    <input
                        type='text'
                        name='contactFax'
                        className='form-control'
                        value={this.state.contactFax}
                        onChange={this.inputChangeHandler}
                        placeholder='Contact Fax Number'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Contact (*)</label>
                    <input
                        type='text'
                        name='accountsPayableContact'
                        className='form-control'
                        placeholder='A/P Contact person'
                        value={this.state.accountsPayableContact}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Email</label>
                    <input
                        type='text'
                        name='accountsPayableEmail'
                        className='form-control'
                        value={this.state.accountsPayableEmail}
                        onChange={this.inputChangeHandler}
                        placeholder='A/P contact email'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Phone (*)</label>
                    <input
                        type='text'
                        name='accountsPayablePhone'
                        className='form-control'
                        placeholder='A/P contact phone'
                        value={this.state.accountsPayablePhone}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Fax</label>
                    <input
                        type='text'
                        name='accountsPayableFax'
                        className='form-control'
                        value={this.state.accountsPayableFax}
                        onChange={this.inputChangeHandler}
                        placeholder='A/P contact fax'
                    />
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted">Payment Type (*)</label>
                <PaymentTypePicker action={this.onChangePaymentType} />
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Credit Amount</label>
                    <input
                        type='text'
                        name='creditAmount'
                        className='form-control'
                        placeholder='$0.0'
                        value={this.state.creditAmount}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Payment Term (*)</label>
                    <PaymentTermPicker action={this.onChangePaymentTerm} />
                </div>
            </div>
            <div className='form-group'>
                <input name="credit"
                    type="checkbox"
                    checked={this.state.creditRequested}
                    onChange={this.onCreditRequest}
                />
                <label>&nbsp;Request Credit.</label>
            </div>
            <div>
                <small>(*) required fields</small>
            </div>
            <div className="my-2">
                <button className="btn btn-success">Save</button>
            </div>
        </form>
    )

    render() {
        return (
            <Layout
                title='Prospection Registration'
                description='Fill in the form in order to register'
                className="container col-md-8 offset-md-2"
            >
                {this.ClientForm()}
                <Modal
                    show={this.state.showErrorModal}
                    animation={false}
                    onHide={this.toggleErrorModal}
                    size='lg'
                    centered
                    dialogClassName='modal-width'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.titleModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.messageModal}</p>
                    </Modal.Body>
                </Modal>
            </Layout>
        )
    }
}
