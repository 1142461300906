import { Button, Card, Col, DatePicker, Divider, Flex, Form, Modal, Row, Select, Statistic, Table, Typography, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, XAxis, YAxis, Tooltip } from 'recharts'
import { api } from '../utils/Api'
import axios from 'axios'
import CountUp from 'react-countup'
import { ArrowUpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv"
import dayjs from 'dayjs'
import { CustomSpinner } from '../utils/CustomComponents'

const { Text, Title } = Typography
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
const formatter = (value) => <CountUp end={value} duration={0} separator="," />


/**
 ** Options header (columns)
*/
const columnsYards = [
    {
        title: '',
        dataIndex: 'name',
        key: 'name',
        render: (value) => {
            return <Text style={{fontSize: 10}}>{value}</Text>
        }
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'JAN',
        dataIndex: 'jan',
        key: 'jan',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'FEB',
        dataIndex: 'feb',
        key: 'feb',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'MAR',
        dataIndex: 'mar',
        key: 'mar',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'APR',
        dataIndex: 'apr',
        key: 'apr',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'MAY',
        dataIndex: 'may',
        key: 'may',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'JUN',
        dataIndex: 'jun',
        key: 'jun',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'JUL',
        dataIndex: 'jul',
        key: 'jul',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'AUG',
        dataIndex: 'aug',
        key: 'aug',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'SEP',
        dataIndex: 'sep',
        key: 'sep',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'OCT',
        dataIndex: 'oct',
        key: 'oct',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'NOV',
        dataIndex: 'nov',
        key: 'nov',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'DEC',
        dataIndex: 'dec',
        key: 'dec',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
]

const columnsQuarter = [
    {
        title: 'Month',
        dataIndex: 'mes',
        key: 'mes',
        render: (value) => {
            return <Text style={{fontSize: 10}}>{value}</Text>
        }
    },
    {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'Sold',
        dataIndex: 'sold',
        key: 'sold',
        render: (value) => {
            return <Statistic title="" value={value} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
        }
    }
]

const columnsWeek = [
    {
        title: 'Seller',
        dataIndex: 'name',
        key: 'name',
        render: (value) => {
            return <Text style={{fontSize: 10}}>{value}</Text>
        }
    },
    {
        title: 'Load YD3',
        dataIndex: 'yards',
        key: 'yards',
        render: (value) => {
            return <Statistic title="" value={value} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
    {
        title: 'Target YD3',
        dataIndex: 'target',
        key: 'target',
        render: (value) => {
            return <Statistic title="" value={value} precision={2} valueStyle={{fontSize: 10}} />
        }
    },
]

const columnsWeekly = [
    {
        title: 'Week',
        dataIndex: 'week',
        key: 'week',
        render: (value) => {
            return <Text style={{fontSize: 10}}>{value}</Text>
        }
    },
    {
        title: 'Yards of the week',
        dataIndex: 'yards',
        key: 'yards',
        render: (value) => {
            return <Statistic title="" value={value} precision={2} valueStyle={{fontSize: 10}} />
        }
    }
]

const sellerColumn = [
    { key: 'name', label: 'Seller' },
    { key: 'yards', label: 'Loads' },
    { key: 'target', label: 'Target' }
]

const columnReportYards = [
    { key: 'mes', label: 'month' },
    { key: 'plan', label: 'plan' },
    { key: 'sold', label: 'real' }
]

const yardsColumn = [
    { key: 'week', label: 'Weeks' },
    { key: 'yards', label: 'Yards of the week' }
]

const optionsQuarters = [
    { value: 'q1', label: 'Q1 (JAN-MAR)' },
    { value: 'q2', label: 'Q2 (APR-JUN)' },
    { value: 'q3', label: 'Q3 (JUL-SEP)' },
    { value: 'q4', label: 'Q4 (OCT-DEC)' }
]


const YearChart2 = ({history}) => {
    const [chartData, setChartData] = useState([])
    const [quarterChartData, setQuarterChartData] = useState([])
    const [dataYards, setDataYards] = useState([])
    const [salesPerWeek, setSalesPerWeek] = useState([])
    const [sellerResidentialRow, setSellerResidentialRow] = useState(0)
    const [sellerCommercialRow, setSellerCommercialRow] = useState(0)
    const [sellerRow, setSellerRow] = useState([])
    const [yardsRow, setYardsRow] = useState([])
    const [quarters, setQuarters] = useState(['q1', 'q2', 'q3', 'q4'])
    const [groupQ, setGroupQ] = useState([])
    const [yearDate, setYearDate] = useState(dayjs())
    const [apiNotification, contextHolder] = notification.useNotification()

    const [formQuarter] = Form.useForm()

    // Ref
    const refButtonWeekAll = useRef()
    const refButtonWeekCommercial = useRef()
    const refButtonWeekResidential = useRef()
    const refButtonWeekly = useRef()
    const refButtonQuarter = useRef()


    // Modals
    const [openCurrentWeekModal,setOpenCurrentWeekModal] = useState()
    const [openWeeklyModal,setOpenWeeklyModal] = useState()
    const [openQuarterModal,setOpenQuarterModal] = useState()

    // Loadings
    const [loadingChartData, setLoadingChartData] = useState(true)

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getSalesData()
        getSellerSales()
        getData()
    }, [])

    const weekNum = () => {
        var currentDate = new Date();
        var startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));

        var weekNumber = Math.ceil(days / 7);

        return weekNumber;
    }

    const getSalesData = async () => {
        const url = api + '/jobgroupedweek'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const { data } = await axios.get(url).catch(errors => console.log(errors))
        setSalesPerWeek(data)

        const arr = []
        const weeksSorted = data.sort(function (a, b) { return a._id - b._id })
        weeksSorted.map((wk, i) => {
            let weekObj = {
                week: wk._id,
                yards: wk.yards,
                key: i
            }

            arr.push(weekObj)
        })

        setYardsRow(arr)

    }
    
    // get forecast and real yards sold
    const getData = async () => {
        const url = api + '/forecastyear'
        const url2 = api + '/groupedyards'
        const url3 = api + '/jobgrpcustomer?startDate=2023-01-01&endDate=2023-12-31'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        // llamada simultanea con axios
        const req1 = axios.get(url)
        const req2 = axios.get(url2)
        const req3 = axios.get(url3)

        axios.all([req1, req2, req3]).then(axios.spread((...responses) => {
            const { data } = responses[0]
            const plan = data
            let res = responses[1]
            const yarda = res.data
            const past = responses[2].data
            const pasado = []

            past.forEach(dato => {
                const found = pasado.findIndex(e => e.mes === dato._id.month)

                if (found !== -1) {
                    const valor = pasado[found].yard + dato.yards 
                    pasado[found].yard = valor
                } else {
                    const item = {
                        mes: dato._id.month,
                        yard: dato.yards
                    }
                    pasado.push(item)
                }
            })
     
            groupData(plan, yarda, pasado)
        })).catch(errors => {
            console.log(errors)
            return null
        })
    }

    const groupData = async (targets, yardsSold, yardsPast) => {
        const today = new Date()
        const thisYear = today.getFullYear()

        const ordenado = targets.sort((a, b) => (a._id > b._id) ? 1 : -1)

        const nuevo = ordenado.map(period => {
            const [year, month] = period._id.split('-')

            if (parseInt(year) === thisYear) {
                const real = yardsSold.filter(valor => valor._id === period._id)
                const sold = typeof real[0] === 'undefined' ? 0 : real[0].yards
                const idx = yardsPast.findIndex(value => value.mes === parseInt(month))
                const past = yardsPast[idx].yard === 'undefined' ? 0 : yardsPast[idx].yard
                return {
                    month,
                    mes: months[parseInt(month) - 1],
                    plan: period.yards,
                    sold,
                    past
                }
            }
        })

        setChartData(nuevo)
        setQuarterChartData(nuevo)
        assignDataTable(nuevo)
        setGroupQ([targets, yardsSold])
        setLoadingChartData(false)
    }

    const assignDataTable = (data) => {
        let list = []
        const plan = data.map(dat => dat.plan)
        const sumPlan = plan.reduce((acc, item) => acc + item, 0)
        list.push({
            key: 'plan',
            name: 'Plan',
            total: sumPlan,
            jan: data.find(obj => obj.mes === 'JAN').plan,
            feb: data.find(obj => obj.mes === 'FEB').plan,
            mar: data.find(obj => obj.mes === 'MAR').plan,
            apr: data.find(obj => obj.mes === 'APR').plan,
            may: data.find(obj => obj.mes === 'MAY').plan,
            jun: data.find(obj => obj.mes === 'JUN').plan,
            jul: data.find(obj => obj.mes === 'JUL').plan,
            aug: data.find(obj => obj.mes === 'AUG').plan,
            sep: data.find(obj => obj.mes === 'SEP').plan,
            oct: data.find(obj => obj.mes === 'OCT').plan,
            nov: data.find(obj => obj.mes === 'NOV').plan,
            dec: data.find(obj => obj.mes === 'DEC').plan,
        })

        const sold = data.map(dat => dat.sold)
        const sumSold = sold.reduce((acc, item) => acc + item, 0)
        list.push({
            key: 'sold',
            name: 'Sold',
            total: sumSold,
            jan: data.find(obj => obj.mes === 'JAN').sold,
            feb: data.find(obj => obj.mes === 'FEB').sold,
            mar: data.find(obj => obj.mes === 'MAR').sold,
            apr: data.find(obj => obj.mes === 'APR').sold,
            may: data.find(obj => obj.mes === 'MAY').sold,
            jun: data.find(obj => obj.mes === 'JUN').sold,
            jul: data.find(obj => obj.mes === 'JUL').sold,
            aug: data.find(obj => obj.mes === 'AUG').sold,
            sep: data.find(obj => obj.mes === 'SEP').sold,
            oct: data.find(obj => obj.mes === 'OCT').sold,
            nov: data.find(obj => obj.mes === 'NOV').sold,
            dec: data.find(obj => obj.mes === 'DEC').sold,
        })

        const lastYear = data.map(dat => dat.past)
        const sumLastYear = lastYear.reduce((acc, item) => acc + item, 0)
        list.push({
            key: 'past',
            name: 'Last Year',
            total: sumLastYear,
            jan: data.find(obj => obj.mes === 'JAN').past,
            feb: data.find(obj => obj.mes === 'FEB').past,
            mar: data.find(obj => obj.mes === 'MAR').past,
            apr: data.find(obj => obj.mes === 'APR').past,
            may: data.find(obj => obj.mes === 'MAY').past,
            jun: data.find(obj => obj.mes === 'JUN').past,
            jul: data.find(obj => obj.mes === 'JUL').past,
            aug: data.find(obj => obj.mes === 'AUG').past,
            sep: data.find(obj => obj.mes === 'SEP').past,
            oct: data.find(obj => obj.mes === 'OCT').past,
            nov: data.find(obj => obj.mes === 'NOV').past,
            dec: data.find(obj => obj.mes === 'DEC').past,
        })
        setDataYards(list)
    }

    const getFirstDayOfWeek = () => {
        const date = new Date();
        const day = date.getDay();
        const diff = date.getDate() - day;

        return new Date(date.setDate(diff));
    }

    const getSellerSales = async () => {
        const startDate = getFirstDayOfWeek()
        const endDate = new Date()
        const url = api + `/jobscheduled?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const { data } = await axios.get(url)
            .catch(errors => console.log(errors))

        SoldPerWkSellers(data)
    }

    const SoldPerWkSellers = (salesPerSeller) => {
        let corpObj = {
            name: 'CORPORATIVO',
            yds: 0,
            target: 0
        }
        const result = salesPerSeller.reduce(function (r, a) {
            r[a.seller.name] = r[a.seller.name] || [];
            r[a.seller.name].push(a);
            return r;
        }, Object.create(null));

        const resultArr = Object.keys(result).map((key) => [(key), result[key]]);

        const sellerArr = resultArr.map((seller) => {
            let corporate = false
            const initialValue = 0;

            var sumY = seller[1].reduce(function (accumulator, curValue) {
                if (curValue.seller.corporateSales) {
                    corporate = true
                    return accumulator + curValue.loadYD3
                } else {
                    return accumulator + curValue.loadYD3
                }
            }, initialValue)

            var sumT = seller[1].reduce(function (accumulator, curValue) {
                if (curValue.jobStatus !== "Quote") {
                    return parseInt(accumulator) + parseInt(curValue.targetYD3)
                } else {
                    return parseInt(accumulator) + 0
                }
            }, initialValue)

            if (corporate) {
                corpObj.yds += sumY
                corpObj.target += sumT
                return corpObj
            } else {
                let sellerObj = {
                    name: seller[0],
                    yds: sumY,
                    target: sumT,
                }
                return sellerObj
            }
        })

        let filteredArr = sellerArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.name === value.name
            )),
        )

        const datosSeller = []
        filteredArr.map((dato) => {
            let sellerObj = {
                name: dato.name,
                yards: dato.yds,
                target: dato.target
            }

            datosSeller.push(sellerObj)
        })
        setSellerRow(datosSeller)

        const datosSellerCommercial = []
        filteredArr.filter((value) => 
        value.name.toUpperCase() === 'JUAN HERNANDEZ' || 
        value.name.toUpperCase() === 'CARLOS MANUEL CASTAÑOS'
        ).map((dato, i) => {
            let sellerObj = {
                key: i,
                name: dato.name,
                yards: dato.yds,
                target: dato.target
            }
            datosSellerCommercial.push(sellerObj)
        })

        setSellerCommercialRow(datosSellerCommercial)

        const datosSellerResidential = []
        filteredArr.filter((value) => 
        value.name.toUpperCase() === 'CORPORATIVO' || 
        value.name.toUpperCase() === 'JESUS CANTU' || 
        value.name.toUpperCase() === 'EDGAR BRIONES' || 
        value.name.toUpperCase() === 'CRISTIAN TORRES' || 
        value.name.toUpperCase() === 'FERNANDO RAMIREZ' ||
        value.name.toUpperCase() === 'EDUARDO SALGADO' ||
        value.name.toUpperCase() === 'DAVID SANTAMARIA' ||
        value.name.toUpperCase() === 'ERIK PECINA' ||
        value.name.toUpperCase() === 'OSCAR VALADEZ'
        ).map((dato, i) => {
            let sellerObj = {
                key: i,
                name: dato.name,
                yards: dato.yds,
                target: dato.target
            }
            datosSellerResidential.push(sellerObj)
        })
        setSellerResidentialRow(datosSellerResidential)
    }

    const handleCurrentWeekCancel = () => {
        setOpenCurrentWeekModal(false)
    }

    const handleCurrentWeekOpen = () => {
        setOpenCurrentWeekModal(true)
    }

    const summaryWeek = (pageData) => {
        let totalYards = 0;
        let totalTarget = 0;
    
        pageData.forEach(({ yards, target }) => {
            totalYards += yards;
            totalTarget += target;
        })
        
        return (
            <>
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text style={{fontSize: 10}} strong>{'Total'}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalYards} style={{fontWeight: 'bold'}} precision={2} valueStyle={{fontSize: 10}} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalTarget} style={{fontWeight: 'bold'}} precision={2} valueStyle={{fontSize: 10}} />
                </Table.Summary.Cell>
            </Table.Summary.Row>
            </>
        )
    }

    const RenderCurrentWeekModal = () => {
        
        const week = weekNum()
        const yardsOfCurrentWeek = salesPerWeek.filter(data => data._id === weekNum())
        const yards = yardsOfCurrentWeek.length > 0 ? yardsOfCurrentWeek[0].yards : 0

        const bestWeek = salesPerWeek.sort((a, b) => (a.yards > b.yards) ? -1 : 1).slice(0, 1)
        const bestWeekNumber = bestWeek.length > 0 ? bestWeek[0]._id : 0
        const bestWeekYards = bestWeek.length > 0 ? bestWeek[0].yards : 0
        
        return <Modal title="Yards sold by week" open={openCurrentWeekModal} onCancel={handleCurrentWeekCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <Row gutter={16}>
                <Col span={12}>
                <Card bordered={false}>
                    <Statistic
                    title={`Current Week ${week}`}
                    value={yards}
                    precision={2}
                    prefix={<InfoCircleOutlined />}
                    suffix="YD3"
                    />
                </Card>
                </Col>
                <Col span={12}>
                <Card bordered={false}>
                    <Statistic
                    title={`Best Sales Week ${bestWeekNumber}`}
                    value={bestWeekYards}
                    precision={2}
                    valueStyle={{
                        color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="YD3"
                    />
                </Card>
                </Col>
            </Row>
            <Table 
            dataSource={sellerCommercialRow}
            columns={columnsWeek}
            pagination={false}
            title={() => <Title level={3}>Commercial Team</Title>}
            summary={summaryWeek}
            /> 
            <Table 
            dataSource={sellerResidentialRow}
            columns={columnsWeek}
            pagination={false}
            title={() => <Title level={3}>Residential Team</Title>}
            summary={summaryWeek}
            />
            <CSVLink 
            data={sellerRow} 
            headers={sellerColumn} 
            filename={"57concrete-weekly_seller-report.csv"}
            hidden
            ref={refButtonWeekAll}
            >
                Download report
            </CSVLink>
            <CSVLink 
            data={sellerCommercialRow} 
            headers={sellerColumn} 
            filename={"57concrete-weekly_seller_commercial-report.csv"}
            hidden
            ref={refButtonWeekCommercial}
            >
                Download commercial report
            </CSVLink>
            <CSVLink 
            data={sellerResidentialRow} 
            headers={sellerColumn} 
            filename={"57concrete-weekly_seller_residential-report.csv"}
            hidden
            ref={refButtonWeekResidential}
            >
                Download residential report
            </CSVLink>

            <Row gutter={16}>
                <Col span={24}>
                    <Button type='primary' style={{width: '100%'}} onClick={e => refButtonWeekAll.current.link.click()}>Download Full Report </Button>
                </Col>
                <Divider />

                <Col span={24}>
                    <Flex justify='space-between'>
                        <Button type='primary' onClick={e => refButtonWeekCommercial.current.link.click()}>Download Commercial Report </Button>
                        <Button type='primary' onClick={e => refButtonWeekResidential.current.link.click()}>Download Residential Report </Button>
                    </Flex>
                </Col>
            </Row>
            
        </Modal>
    }

    const handleWeeklyCancel = () => {
        setOpenWeeklyModal(false)
    }

    const handleWeeklyOpen = () => {
        setOpenWeeklyModal(true)
    }

    const RenderWeeklyModal = () => {
        return <Modal title="Yards sold by week of the year" open={openWeeklyModal} onCancel={handleWeeklyCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <Table 
            dataSource={yardsRow}
            columns={columnsWeekly}
            pagination={false}
            /> 

            <Divider /> 

            <CSVLink 
            data={yardsRow} 
            headers={yardsColumn} 
            filename={"57concrete-weekly_yards-report.csv"}
            hidden
            ref={refButtonWeekly}
            >
                Download report
            </CSVLink>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type='primary' style={{width: '100%'}} onClick={e => refButtonWeekly.current.link.click()}>Download Report </Button>
                </Col>
            </Row>
            
        </Modal>
    }

    const handleQuarterCancel = () => {
        setOpenQuarterModal(false)
    }

    const handleQuarterOpen = () => {
        setOpenQuarterModal(true)
    }

    const summaryQuarter = (pageData) => {
        let totalPlan = 0;
        let totalSold = 0;
    
        pageData.forEach(({ plan, sold }) => {
            totalPlan += plan;
            totalSold += sold;
        })
        
        return (
            <>
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text style={{fontSize: 10}} strong>{'Total'}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalPlan} style={{fontWeight: 'bold'}} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalSold} style={{fontWeight: 'bold'}} formatter={formatter} precision={2} valueStyle={{fontSize: 10}} />
                </Table.Summary.Cell>
            </Table.Summary.Row>
            </>
        )
    }


    const onFinishQuarters = (values) => {
        let { quarters, yearDate} = values
        setQuarters(quarters)
        setYearDate(yearDate)
        if(quarters.length === 0){
            setQuarterChartData([])
            openNotification('info', 'Info', 'Please input one quarter at least!')
        }else{
            const ordenado = groupQ[0].sort((a, b) => (a._id > b._id) ? 1 : -1)

            const nuevo = ordenado.map(period => {
                const [year, month] = period._id.split('-')

                if (parseInt(year) === dayjs(yearDate).year()) {
                    const real = groupQ[1].filter(valor => valor._id === period._id)
                    const sold = typeof real[0] === 'undefined' ? 0 : real[0].yards

                    return {
                        month,
                        mes: months[parseInt(month) - 1],
                        plan: period.yards,
                        sold
                    }
                }
            })

            if (typeof nuevo[0] === 'undefined') {
                formQuarter.setFieldValue('yearDate', dayjs())
                openNotification('info', 'Info', 'Please input a valid year! (No data registered)')
                return
            }

            let newChartData = []

            quarters.sort((a, b) => a.localeCompare(b))

            nuevo.map((e) => {
                for (let i = 0; i < quarters.length; i++) {
                    const quarter = quarters[i];
                    if ((e.mes === "JAN" || e.mes === "FEB" || e.mes === "MAR") && (quarter === "q1")) {
                        newChartData.push(e)
                    } else if ((e.mes === "APR" || e.mes === "MAY" || e.mes === "JUN") && (quarter === "q2")) {
                        newChartData.push(e)
                    } else if ((e.mes === "JUL" || e.mes === "AUG" || e.mes === "SEP") && (quarter === "q3")) {
                        newChartData.push(e)
                    } else if ((e.mes === "OCT" || e.mes === "NOV" || e.mes === "DEC") && (quarter === "q4")) {
                        newChartData.push(e)
                    }
                }
            })
            setQuarterChartData(newChartData)
        }
    }

    const RenderQuarterModal = () => {
        return <Modal title="Plan vs Real (yards) by quarters" open={openQuarterModal} onCancel={handleQuarterCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} width={600}>
            <Form
            form={formQuarter}
            layout='vertical'
            onFinish={onFinishQuarters}
            initialValues={{
                yearDate,
                quarters
            }}
            >
                <Form.Item
                name='yearDate'
                >
                    <DatePicker 
                    picker="year" 
                    allowClear={false} 
                    size='large' 
                    style={{width: '100%'}} />
                </Form.Item>
                <Form.Item
                name='quarters'
                >
                    <Select
                    mode="multiple"
                    allowClear={false}
                    style={{
                        width: '100%',
                    }}
                    size='large'
                    placeholder="Please select quarters"
                    options={optionsQuarters}
                    />
                </Form.Item>
                <Button size='large' htmlType='submit' type='primary'>Set Quarters</Button>
            </Form>


            {
                false ?
                <CustomSpinner />
                :
                <div>
                    <ComposedChart width={500} height={400} data={quarterChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="mes" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="plan" fill="#1e387d" />
                        <Line
                            type="monotone"
                            dataKey="sold"
                            stroke="#eb1a20"
                            strokeWidth={4}
                        />
                    </ComposedChart>
                    
                    <Table 
                    dataSource={quarterChartData}
                    columns={columnsQuarter}
                    pagination={false}
                    summary={summaryQuarter}
                    /> 

                    <Divider /> 

                    <CSVLink 
                    data={quarterChartData} 
                    headers={columnReportYards} 
                    filename={"57concrete-quarter-report.csv"}
                    hidden
                    ref={refButtonQuarter}
                    >
                        Download report
                    </CSVLink>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type='primary' style={{width: '100%'}} onClick={e => refButtonQuarter.current.link.click()}>Download Report</Button>
                        </Col>
                    </Row>
                </div>
            }
            
        </Modal>
    }

    const handleHeatmap = () => {
        history.push('/heatmapsellers')
    }

    return (
        <Flex gap={20} justify='center'>
            {
                loadingChartData ?
                <CustomSpinner />
                :
                <Flex gap={20} justify='space-between'>
                    <div>
                        <ComposedChart width={500} height={300} data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="mes" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="plan" fill="#1e387d" />
                            <Line
                                type="monotone"
                                dataKey="sold"
                                stroke="#eb1a20"
                                strokeWidth={4}
                            />
                            <Line
                                type="monotone"
                                dataKey="past"
                                strokeWidth={3}
                                stroke="#807e7c"
                            />
                        </ComposedChart>
                    </div>
                    <div>
                        <Table 
                        dataSource={dataYards}
                        columns={columnsYards}
                        pagination={false}
                        />
                        <Divider /> 
                        <CSVLink data={chartData} headers={columnReportYards} filename={"57concrete-report.csv"}>
                            Download report
                        </CSVLink>
                        <Divider /> 
                        <Row gutter={16}>
                            <Col><Button size='large' type='primary' onClick={handleCurrentWeekOpen}>Current Week</Button></Col>
                            <Col><Button size='large' type='primary' onClick={handleWeeklyOpen}>Weekly</Button></Col>
                            <Col><Button size='large' type='primary' onClick={handleQuarterOpen}>Quarter Year</Button></Col>
                            <Col><Button size='large' type='primary' onClick={handleHeatmap}>Sellers Heatmap</Button></Col>
                        </Row>
                    </div>
                </Flex>
            }
            
            <RenderCurrentWeekModal />
            <RenderWeeklyModal />
            <RenderQuarterModal />
            {contextHolder}
        </Flex>
    )
}

export default YearChart2