import React, { useState, useEffect } from 'react'
import {  
    Document, 
    PDFViewer,     
    Page,  
    View,
    Image,
    Text,   
    StyleSheet } from '@react-pdf/renderer'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
const styles = StyleSheet.create({
    page: {
      padding: 4,
      margin: 4,
      flexDirection: 'column'
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      '@media max-width: 360': {
        flexDirection: 'column',
      },
    },
    renglonStyle: {
      flexDirection: 'row',
      width: '98%'
    },
    colStyle1: {
        flexDirection: 'column',
        width: '25%'
    },
    colStyle2: {
        flexDirection: 'column',
        width: '40%'
    },
    colStyle3: {
        flexDirection: 'column',
        width: '35%'
    },
    lastRow: {
        flexDirection: 'row',
        width: '98%',
        marginTop: 160
    },
    timesRow: {
        flexDirection: 'row',
        width: '100%'
    },
    textPageTitle: {
        fontFamily: 'Helvetica',
        fontSize: 30,
        textAlign: 'right'
    },
    textTerms: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        textAlign: 'justify',              
    },
    text2Col: {
        fontFamily: 'Helvetica',
        fontSize: 12,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        width: '50%',
        marginTop: 15
    },
    text3Col: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        width: '66%',
        marginTop: 15,
        textTransform: 'uppercase'
    },
    text3ColRight: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        width: '33%',
        marginTop: 15,
        textTransform: 'uppercase'
    },
    textReq: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    text6col: {
        fontFamily: 'Helvetica',
        width: '16.66%',
        fontSize: 10,
        marginTop: 20,
        textTransform: 'uppercase',
        backgroundColor: '#d9d9d9',
        borderColor: 'grey'
    },
    text6colVar: {
        fontFamily: 'Helvetica',
        width: '16.6666%',
        fontSize: 10,
        marginTop: 10,
        textTransform: 'uppercase',
    },
    textBatch: {
        fontFamily: 'Helvetica',
        textTransform: 'uppercase',
        fontSize: 10,
        backgroundColor: '#d9d9d9',
        marginTop: 20,
        width: '100%',
    },
    textSubtitle: {
        fontFamily: 'Helvetica',
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',                
        width: '100%',
        marginTop: 20,
        textAlign: 'center',
    },
    textRow: {
        fontFamily: 'Helvetica',
        fontSize: 14,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        width: '100%',
        marginTop: 15
    },
    textTermFooter: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 9,
        textAlign: 'center',
        marginTop: 20,
        paddingLeft: 22,
        paddingRight: 22,
    },
    textTermSignature: {
        fontFamily: 'Helvetica',
        fontSize: 9
    },
    textGalon: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        width: '100%',
        textAlign: 'right',
        borderBottom: 'solid',    
        borderBottomColor: 'black',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    textTermsTitle: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 10,
        textAlign: 'center',
        marginTop: 80,
        paddingTop: 10,
    },
    textTruckColTitle: {
        fontFamily: 'Helvetica',
        textTransform: 'uppercase',
        fontSize: 10,
        width: '60%'
    },
    textSignature: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        marginTop: 70,
        marginLeft: 50,
        textTransform: 'uppercase',
        borderTopColor: 'black',
        borderTopWidth: 1,
        textAlign: 'center',
        width: '70%',
        borderTopStyle: 'solid'
    },
    textSmall: {
        fontFamily: 'Helvetica',
        fontSize:8,
        width: '60%',
        textAlign: 'center',
        marginTop:14,
        marginLeft: 100,
    },
    textAddress: {
        fontFamily: 'Helvetica',
        fontSize: 11,
    },
    textTimes: {
        fontFamily: 'Helvetica',
        textTransform: 'uppercase',
        fontSize: 10,
        textAlign: 'right',
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black'
    },
    textCalculatic: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        textAlign: 'center'
    },
    leftImage: {
        marginLeft: 2,
        marginTop: 3
    },
    image: {
        width:'30%',
        padding: 5        
    }
  })

const ReferralPdf = props => {
    useEffect(() => {
        getReferral()
    }, [])

    const [formData, setFormData] = useState([])

    const {
        plant,
        customerName,
        projectName,
        refDate,
        deliveryTicket,
        deliveryAddress,
        loadSize,
        quantity,
        batchData,
        customerCode,
        orderCode,
        orderPONumber,
        yards,
        slump,
        codeMix,
        driver,
        truckNumber,
        cumulativeQuantity,
        decreasingQuantity,
        productDescription,
        unitPrice,
        amount,
        authorizedName,
        waterAdded,
        timeToJob,
        arriveJobTime,
        startUnloadTime,
        finishUnloadTime,
        arriveTime
    } = formData

    const getReferral = async () => {
        const url = api + '/referral/' + props.match.params.id 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}        

        try {
            const { data } = await axios.get(url)
            console.log(data)        
            setFormData(data)      
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const ReferralPrint = () => (
        <Document
                author='57concrete'
                keywords='57concrete'
                subject='57concrete Referral'
                title='Referral Form'
            >
                <Page
                    size='A4'
                    wrap={false}
                    style={styles.page}
                >                      
                    <View style={styles.leftImage}>
                        <Image    
                            style={styles.image}                          
                            src={process.env.PUBLIC_URL + '/images/concrete57.png'}               
                        /> 
                        <View style={styles.colStyle2}>
                            <Text style={styles.textAddress}>
                                57 CONCRETE LLC
                            </Text>
                            <Text style={styles.textAddress}>
                                4877 WESTERN RD.
                            </Text>
                            <Text style={styles.textAddress}>
                                MISSION TX, 78574
                            </Text>
                            <Text style={styles.textAddress}>
                                Ph. (956)878-5303
                            </Text>
                            <Text style={styles.textAddress}>
                                www.57concrete.com
                            </Text>
                        </View>
                    </View>                                               
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text3Col}>Customer Name: {customerName} </Text>
                        <Text style={styles.text3ColRight}>Customer Code: {customerCode} </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text3Col}>Project Name: {projectName} </Text>
                        <Text style={styles.text3ColRight}>Order Code: {orderCode} </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text3Col}>Delivery Address: {deliveryAddress} </Text>
                        <Text style={styles.text3ColRight}>Order P.O. number: {orderPONumber} </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text6col}>Load Size</Text>
                        <Text style={styles.text6col}>Yards Ordered</Text>
                        <Text style={styles.text6col}>slump</Text>
                        <Text style={styles.text6col}>code mix design</Text>
                        <Text style={styles.text6col}>drive</Text>
                        <Text style={styles.text6col}>truck</Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text6colVar}>{loadSize} </Text>
                        <Text style={styles.text6colVar}>{yards} </Text>
                        <Text style={styles.text6colVar}>{slump} </Text>
                        <Text style={styles.text6colVar}>{codeMix} </Text>
                        <Text style={styles.text6colVar}>{driver} </Text>
                        <Text style={styles.text6colVar}>{truckNumber} </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text6col}>quantity</Text>
                        <Text style={styles.text6col}>cumulative quantity</Text>
                        <Text style={styles.text6col}>decreasing qty</Text>
                        <Text style={styles.text6col}>product description</Text>
                        <Text style={styles.text6col}>unit price</Text>
                        <Text style={styles.text6col}>amount</Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.text6colVar}>{quantity} </Text>
                        <Text style={styles.text6colVar}>{cumulativeQuantity} </Text>
                        <Text style={styles.text6colVar}>{decreasingQuantity} </Text>
                        <Text style={styles.text6colVar}>{productDescription} </Text>
                        <Text style={styles.text6colVar}>{unitPrice} </Text>
                        <Text style={styles.text6colVar}>{amount} </Text>
                    </View>
                    <View style={styles.renglonStyle}>
                        <Text style={styles.textBatch}>batch data: {batchData} </Text>
                    </View>
                    <View style={styles.lastRow}>
                        <View style={styles.colStyle1}>
                            <Text style={styles.textBatch}>truck times</Text>
                            <View style={styles.timesRow}>
                                <Text style={styles.textTruckColTitle}>to job</Text>
                                <Text style={styles.textTimes}>{timeToJob} </Text>
                            </View>     
                            <View style={styles.timesRow}>
                                <Text style={styles.textTruckColTitle}>arive job</Text>
                                <Text style={styles.textTimes}>{arriveJobTime}</Text>
                            </View>   
                            <View style={styles.timesRow}>
                                <Text style={styles.textTruckColTitle}>start unload</Text>
                                <Text style={styles.textTimes}>{startUnloadTime}</Text>
                            </View>  
                            <View style={styles.timesRow}>
                                <Text style={styles.textTruckColTitle}>finish unload</Text>
                                <Text style={styles.textTimes}>{finishUnloadTime}</Text>
                            </View>              
                            <View style={styles.timesRow}>
                                <Text style={styles.textTruckColTitle}>arrive plant</Text>
                                <Text style={styles.textTimes}>{arriveTime}</Text>
                            </View>    
                        </View>
                        <View style={styles.colStyle2}>
                            <Text style={styles.textCalculatic}>
                                *Strata Must Verify Calculatic
                                Our Concrete Strength Guarentee per ASTM C94 will not be effective unless the field sampling has been done per ASTM C172 and ASTM C31 and unless the laboratory testing fully complies with ASTM C39 & ASTM C1077 & Certified through CMEC or Equal. CMEC=Concrete Materials Engineering Council
                            </Text>
                        </View>
                        <View style={styles.colStyle3}>
                            <Text style={styles.textBatch}>
                                water added in gallons
                            </Text>
                            <Text style={styles.textReq}>
                                requested in job by customer
                            </Text>
                            <Text style={styles.textGalon}>
                                {waterAdded} GLS
                            </Text>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.textTermsTitle}>
                        NOTE: EXCESS WATER IS DETRIMENTAL TO CONCRETE QUALITY. INCREASED WATER IN CONCRETE MIX REDUCES STRENGTH AND DURABILITY AND INCREASES SHRINKAGE. BUYER ACCEPTS RESPONSIBILITY FOR WATER ADDED ON JOB SITE, WHICH EXCEED ACCEPTED WATER/CEMENT RATIO AND WORKABILITY SPECIFICATIONS
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.textSignature}>
                            autorized signature                         name
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.textSmall}>
                            Signature above signities receipted and acceptance of the list material and acknowledgemente of and 
                            agreement to the 57 Concrete LLC terms and conditions
                        </Text>
                    </View>
                </Page>  
                <Page
                    size='A4'
                    wrap={false}
                    style={styles.page}
                >                                                                   
                    <View>
                        <Text style={styles.textTermsTitle}>
                            CONDITIONS OF SALES AND DELIVERY RECEIPT
                        </Text>
                        <Text style={styles.textTerms}>
                            Purchaser has ordered ready-mix concrete from 57Concrete LLC. Seller, and Seller hereby 
                            delivers the ready-mix concrete in accordance with the following conditions, the terms of 
                            which are set out below, which are agreed to by Purchaser as evidenced by the signing of the 
                            Delivery ticket.
                        </Text>
                        <Text style={styles.textTerms}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Addition of Water.</Text> The ready-mix concrete above described has been prepared in accordance with 
                            a specific recipe mix to produce the specified strength within 28days after the concrete has 
                            been poured. Seller's drivers are not authorized to add any water to the ready-mix concrete 
                            because the addition of water will reduce the strength of the concrete. If Purchaser requests 
                            that any water be added to the ready-mix concrete, Purchaser makes such request at its own risk 
                            with the understanding that if any water is added, seller will no longer guarantee that the 
                            concrete will produce the specified strength and any representation made by Seller or any 
                            agreement made as to the strength of the concrete is null and void.
                        </Text>
                        <Text style={styles.textTerms}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Demurrage</Text>. The normal time for ready-mix concrete truck to discharge its ready-mix concrete at the 
                            jobsite is less than one hour. If the truck is required to remain on the jobsite longer than one hour, 
                            Purchaser agrees to pay an additional $100.00 per hour as overtime. Any ready-mix concrete that is not 
                            discharged from the truck at the jobsite within two hours will be returned to the ready-mix concrete 
                            plant and dumped. Purchaser agrees to pay for such concrete.
                        </Text>
                        <Text style={styles.textTerms}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Indemnity</Text>. Upon arrival at the jobsite, the purchaser or purchaser's agent will direct the driver as to where to drive 
                            the truck, where to park the truck, where to place the chute to discharge the ready-mix concrete and 
                            where to wash the truck. Purchaser, therefore, assumes all liability for damage to any property such 
                            as sidewalks, driveways, pipelines or other property caused by driving the truck onto such property 
                            at the direction of Purchaser, and Purchaser agrees to indemnity and hold Seller harmless for all 
                            claims made against Seller for such damages.
                        </Text>
                        <Text style={styles.textTerms}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Additional Sales and Deliveries</Text>. Purchaser agrees that the terms and conditions of the delivery and sale, as set forth above, 
                            are binding on Purchaser in connection with all additional sales and deliveries from Seller, regardless of whether the 
                            Purchaser signs the other delivery tickets; provided, however, that if any water is added to the ready-mix at the job-site, 
                            a delivery ticket for that delivery must be signed by the Purchaser or purchaser's agent as an acknowledgment that the 
                            purchaser requested water to be added.
                        </Text>
                        <Text style={styles.textTerms}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Seller</Text> may, in its sole discretion, apply Buyer’s payment against any open charges. Past due accounts bear interest at the 
                            lesser of 2.0% per month or the maximum rate permitted by applicable law, continuing after Seller obtains judgment against 
                            Buyer. Seller may exercise setoff or recoupment to apply to or satisfy Buyer’s outstanding debt. Buyer shall have no right 
                            of setoff hereunder, the same being expressly waived hereby. Seller expressly reserves its right to file liens if payment 
                            is not received for its materials and expressly disclaims any waiver of lien rights language which may be contained in any 
                            future agreements between the Parties hereto. Seller reserves all rights to invoice and be paid for materials provided to 
                            Buyer and any terms contained in any of Buyer’s purchase orders or other documents that purport to limit in any way the time 
                            or manner within which Seller may invoice are hereby waived by Buyer.
                        </Text>
                        <Text style={styles.textTerms}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Buyer</Text> shall pay Seller all costs and expenses of collection, suit, or other legal action brought as a result of the 
                            commercial relationship between them, including, but not limited to, all actual attorneys’ and paralegals’ fees, and 
                            collection costs, incurred pre-suit, through trial, on appeal, and in any administrative or bankruptcy proceedings. 
                            Any cause of action that Seller has against Buyer may be assigned without Buyer’s consent to 57 Concrete LLC or to any 
                            affiliate, parent or subsidiary of 57 Concrete LLC.
                        </Text>
                        <Text style={styles.textTermFooter}>
                            THERE ARE NO UNDERSTANDINGS, AGREEMENTS, REPRESENTATIONS, ESPRESS OR IMPLIED (INCLUDING ANY REGARDING THE MERCHANTABILITY 
                            OR FITNESS FOR A PARTICULAR PURPOSE), NOT SPECIFIED HERE IN, RESPECTING THIS SALE. THIS DOCUMENT STATES ENTIRE OBLIGATION 
                            OF SELLER IN CONNECTION WITH THIS TRANSACTION.
                        </Text>
                    </View>
                </Page>
        </Document>
    ) 
    
      
    return (
        <Layout
            title='Referral PDF'
            description='Referral Form PDF'
            className="container col-md-12"
        >      
            <PDFViewer width="95%" style={{ height: '90vh' }}>
              <ReferralPrint />
            </PDFViewer>
        </Layout>
    )

}

export default ReferralPdf