import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const ContactsRender = ({currentContact, contacts, setContacts, toggleModal, edit, index}) => {

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [homePhone, setHomePhone] = useState('');
    const [workPhone, setWorkPhone] = useState('');
    const [relationship, setRelationship] = useState('');

    useEffect(() => {
        setCurrentContact()
    }, [edit])
    

    const setCurrentContact = () => {
        if(edit){
            setName(currentContact.name);
            setAddress(currentContact.address);
            setCity(currentContact.city);
            setState(currentContact.state);
            setZip(currentContact.zip);
            setHomePhone(currentContact.homePhone);
            setWorkPhone(currentContact.workPhone);
            setRelationship(currentContact.relationship);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newContact = {
            name,
            address,
            city,
            state,
            zip,
            homePhone,
            workPhone,
            relationship
        };
        console.log(newContact);
        if(edit){
            contacts[index].name = name;
            contacts[index].address = address;
            contacts[index].city = city;
            contacts[index].state = state;
            contacts[index].zip = zip;
            contacts[index].homePhone = homePhone;
            contacts[index].workPhone = workPhone;
            contacts[index].relationship = relationship;
        }else{
            contacts.push(newContact);
        }
        setContacts(contacts);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Name</label>
                    <input className='form-control'
                        name='contactName'
                        type='text'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Contact Name'
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Address</label>
                    <input className='form-control'
                        name='contactAddress'
                        type='text'
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        placeholder='Contact Address'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>City</label>
                    <input className='form-control'
                        name='contactCity'
                        type='text'
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        placeholder='Contact City'
                        required
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>State</label>
                    <input className='form-control'
                        name='contactState'
                        type='text'
                        value={state}
                        onChange={e => setState(e.target.value)}
                        placeholder='Contact State'
                        required
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>ZIP</label>
                    <input className='form-control'
                        name='contactZip'
                        type='text'
                        value={zip}
                        onChange={e => setZip(e.target.value)}
                        placeholder='Contact Zip'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Home Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Home Phone"
                        value={homePhone}
                        onChange={e => setHomePhone(e)}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Work Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Work phone"
                        value={workPhone}
                        onChange={e => setWorkPhone(e)}
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>How is this person related to you?</label>
                    <input className='form-control'
                        name='contactPersonRelated'
                        type='text'
                        value={relationship}
                        onChange={e => setRelationship(e.target.value)}
                        placeholder='Contact person related'
                        required
                    />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
            
        </form>
    )
}

export default ContactsRender