import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_FOREMAN,
    GET_FOREMAN,
    GET_FOREMANS,
    UPDATE_FOREMAN,
    DELETE_FOREMAN,
    FOREMAN_ERROR
} from './types'

// ADD new foreman
export const createForeman = (formData) => async (dispatch) => {
    try {
        const { data } = await api.post('/foreman', formData)

        dispatch({
            type: ADD_FOREMAN,
            payload: data
        })

        dispatch(setAlert('Foreman Added', 'success'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FOREMAN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// GET foreman using its ID
export const getForeman = (id) => async (dispatch) => {
    try {
        const { data } = await api.get(`/foreman/${id}`)

        dispatch({
            type: GET_FOREMAN,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FOREMAN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// GET list of all foremans
export const getForemans = () => async (dispatch) => {
    try {
        const { data } = await api.get(`/foreman`)

        dispatch({
            type: GET_FOREMANS,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FOREMAN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// UPDATE a foreman properties
export const updateForeman = (id, formData) => async (dispatch) => {
    try {
        const { data } = await api.patch(`/foreman/${id}`, formData)

        dispatch({
            type: UPDATE_FOREMAN,
            payload: data
        })

        dispatch(setAlert('Foreman Updated', 'success'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FOREMAN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// DELETE a foreman
export const deleteForeman = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/foreman/${id}`)

        dispatch({
            type: DELETE_FOREMAN,
            payload: data
        })

        dispatch(setAlert('Foreman Deleted', 'danger'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FOREMAN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}