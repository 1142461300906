import React from 'react'
import Layout from '../layout/Layout'
import YearChart from './YearChart'
import SalesIniciative from './SalesIniciative'
import YearChart2 from './YearChart2'

const SalesDashboard = () => {
    return (
        <Layout
            title='Sales Dashboard'
            description=''
            // className='container'
        >
            <h4>Plan vs Real (yards)</h4>
            <YearChart />
            {/* <YearChart2 /> */}
            <h4>Seller Status</h4>
            <SalesIniciative scope="all" />
            <h4>Commercial Team</h4>
            <SalesIniciative scope="commercial" />
            <h4>Residential Team</h4>
            <SalesIniciative scope="residential" />
        </Layout>
    )
}

export default SalesDashboard
