import React, { Component } from 'react';
import Select from 'react-select';

export default class EmployeeTypePicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isSearchable: true,
            options: props.type === 'all'  ? [
                {value: 'All', label: 'All'},
                {value: 'Applicant', label: 'Applicant'},
                {value: 'Employee', label: 'Employee'},
                {value: 'Supervisor', label: 'Supervisor'},
                {value: 'Manager', label: 'Manager'},
                {value: 'Director', label: 'Director'},
            ]
            :
            [
                {value: 'Applicant', label: 'Applicant'},
                {value: 'Employee', label: 'Employee'},
                {value: 'Supervisor', label: 'Supervisor'},
                {value: 'Manager', label: 'Manager'},
                {value: 'Director', label: 'Director'},
            ]
        }
    }

    render() {

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Type...'
                    value={this.state.options.filter((o) => { return o.value === this.props.value })}
                    onChange={this.props.action}
                    isSearchable={this.state.isSearchable}
                    options={this.state.options}
                />
            </div>
        )
    } 
}
