import { Component } from "react";
import Layout from "../../layout/Layout";
import DepartmentPicker from "../department/DepartmentPicker";
import { api } from "../../utils/Api";
import axios from "axios";

export default class Position extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            department:'',
            active: true
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async (e) => {
        e.preventDefault();

        let body = {
            name: this.state.name,
            active: this.state.active
        }

        if(this.state.department !== '') body.department = this.state.department

        const url = api + `/position/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, body)
            this.props.history.push('/positions')
        } catch (err) {
            console.error(err.message)
            return null
        }
        
    }

    getPosition = async () => {
        const url = api + `/position/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                department: data.department ? data.department : '',
                name: data.name,
                active: data.active
            })

        } catch (err) {
            console.error(err.message)
            return null
        } 
    }

    // change department
    onChangeDepartment = selectedDepartment => {
        if (selectedDepartment) {
            this.setState({
                department: selectedDepartment.value,
            })
        }
    }

    componentDidMount(){
        this.getPosition()
    }

    RenderPosition = () => (
        <form onSubmit={this.onSubmit}> 
            <div className="form-group row">
                <div className="col-sm-6">
                    <label className="text-muted">Name</label>
                    <input
                    name = 'name'
                    type = 'text'
                    value = {this.state.name}
                    onChange={e => this.setState({name:e.target.value})}
                    placeholder="Name"
                    className="form-control"
                    required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Department</label>
                    <DepartmentPicker action={this.onChangeDepartment} value={this.state.department} />
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>Active</label>
                    &nbsp;
                    <input 
                        type="checkbox"
                        checked={this.state.active}
                        onChange={(e) => this.setState({active: e.target.checked})}
                    />
                </div>
                &nbsp;
                &nbsp;
            </div>
            
            <button className="btn btn-success" type="submit">Save</button>
        </form>
    )

    render(){
        return <Layout
        title="Position"
        description="Edit Current Position"
        className={'container col-md-8 offset-md-2'}
        >
            {this.RenderPosition()}
        </Layout>
    }
}