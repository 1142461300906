import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../../utils/Api'

export default class PositionPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isSearchable: true,
            options: []
        }
    }

    getPositions = async () => {
        const url = api + '/position'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            this.setState({
                options: data
            })
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getPositions()
    }

    render() {
        const options = this.state.options.map((item) => {
            return {
                value: item._id,
                label: item.name
            }
        })

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Position...'
                    // value={this.state.value}
                    value={options.filter((o) => { return o.value === this.props.value })}
                    onChange={this.props.action}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    } 
}
