import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Tag, Button, Row, Col } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    FilePdfOutlined
} from '@ant-design/icons'
import { typeIsnpectionOptions } from '../../constants/Inspections'
import { CSVLink } from "react-csv"
const { Text } = Typography

const InspectionReport = ({history}) => { 
    const [loading, setLoading] = useState(true)
    const [inspectionList, setInspectionList] = useState([])
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [type, setType] = useState('ALL')

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 50,
            render: (_, record) => {
            return <Button 
            type="primary" 
            icon={<FilePdfOutlined twoToneColor={'white'}/>} 
            onClick={e=> history.push(`/inspection/${record.id}`)}
            />
            }
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                let color = 'red'
                if(value === 'PRE'){
                    color = 'green'
                }else if(value === 'POST'){
                    color = 'blue'
                }else{
                    color = 'red'
                }
                return <Tag color={color}>{value}</Tag>
            }
        },
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
      getInspectionList('')
    }, [])
    
    const getInspectionList = async(term) => {
        setLoading(true)

        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/inspections/search?startDate=${start}&endDate=${end}&term=${term}&type=${type}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    driver: item.driver ? item.driver.name : '',
                    plant: item.plant ? item.plant.name : '',
                    truck: item.truck,
                    type: item.type,
                    date: dayjs(item.createdAt).format('MM/DD/YYYY HH:mm A'),
                })
            })
            setInspectionList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            getInspectionList(value)
        }
    }       

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const onChangePicker = (value) => {
        setType(value)
    }

    const headers = () => {
        return columns.slice(1).map((item) => {
            return {
                key: item.key,
                label: item.title
            }
        })
    }

    const RenderSearch = () => {
        return <CustomSearch 
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        loading={loading}
        onRangeChange={onRangeChange}
        onSearch={onSearch}
        labelPicker={'status'}
        optionsPicker={typeIsnpectionOptions}
        onChangePicker={onChangePicker}
        defaultValuePicker={type}
        pickerWidth={'200px'}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'}>
            <CustomTable 
            columns={columns}
            data={inspectionList}
            />
        </Flex>
    }

    const RenderCSV = () => {
        return <CSVLink data={inspectionList} headers={headers()} filename={"57concrete-report-inspections.csv"}>
            Download report
        </CSVLink>
    }

    return (
        <Layout
            title='Inspections Report'
            description='Report'
        >
            <Flex vertical gap={'large'}>
                <RenderSearch />
                {
                    loading ? <CustomSpinner/> : 
                    <Row gutter={16}>
                        <Col span={24}>
                            <RenderTable/>
                        </Col>
                        <Col span={24}>
                            <RenderCSV />
                        </Col>
                    </Row>
                } 
            </Flex>
        </Layout>
    )
}

export default InspectionReport