import {
    ADD_COG,
    GET_COG,
    GET_COGS,
    COG_ERROR,
    UPDATE_COG,
    DELETE_COG
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    cogs: []
}

function cogReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_COG:
            return {
                ...state,
                cogs: payload,
                loading: false
            }
        case GET_COG:
            return {
                ...state,
                cog: payload,
                loading: false
            }
        case GET_COGS:
            return {
                ...state,
                cogs: payload,
                loading: false
            }
        case UPDATE_COG:
            return {
                ...state,
                cogs: payload,
                loading: false
            }
        case DELETE_COG:
            return {
                ...state,
                cogs: payload,
                loading: false
            }
        case COG_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default cogReducer