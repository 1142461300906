import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'

const JobPhotos = props => {
    const [photos, setPhotos] = useState([])
    const [picture, setPicture] = useState('')
    const [loadPhotos, setLoadPhotos] = useState('')

    useEffect(() => {
        getPhotos()
    }, [loadPhotos])

    const onFileChange = e => {
        setPicture(e.target.files[0])
    }

    // setFile = e => {        
    //     this.photo = e.target.files[0]
    //     console.log(this.photo)
    // }

    const getPhotos = async () => {
        const url = api + '/job/' + props.match.params.id 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            console.log(data.photos)     
            setPhotos(data.photos)
            console.log(photos)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const uploadPhoto = async () => {
        console.log(picture)
        const { name } = picture
        console.log('image name: ' + name)
        const url = api + '/job-photo/' + props.match.params.id 
        const urlArray = api + '/jobarray' 

        try {
            const newPhoto = {
                id: props.match.params.id,
                url: `https://57concrete-test.s3.amazonaws.com/${name}`
            }
            await axios.post(urlArray, newPhoto)

            const formData = new FormData()
            formData.append('image', picture)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const {data} = await axios.post(url, formData, config)
            console.log(data)
            getPhotos()
            // setPhotos(data)           
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSubmit = (e) => {
        e.preventDefault() 
        if (picture) {
            uploadPhoto()
        }        
    }

    const PhotoList = () => {
        if (photos.length > 0) {
            return photos.map((pic) => {
                return <div className='container' key={pic._id}>
                    <img 
                        src={pic.url}
                        className='img-thumbnail'                        
                        width='400px'
                        alt='Job at site'
                        key={pic._id}
                    />
                </div>
            })
        } else {
            return(
                <div>
                    No photos yet!
                </div>
            )
        }
    }


    const PhotoForm = () => (        
        <form className='form' onSubmit={onSubmit}>
            <div className='form-group'>
                <label className='text-muted'>Select image and click upload </label>
                <input 
                    type='file'                            
                    accept="image/*"
                    onChange={(e) => setPicture(e.target.files[0])}
                    // onClick={(e) => setPicture(e.target.files[0])}
                />
            </div>    
            <div>
                <input className='btn btn-success' value='Upload' type='submit' />
            </div>            
        </form>
    )

    return (           
        <Layout
            title='Job '
            description='Job Photos'
            className="container col-md-8 offset-md-2"
        >   
            <PhotoForm />
            <PhotoList />            
        </Layout>
    )
}

export default JobPhotos