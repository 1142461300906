import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import InvoiceItem from './InvoiceItem'
import { toCurrency } from '../utils/Convert'
import { api } from '../utils/Api'
import DatePicker from 'react-datepicker'
import formatDate from '../utils/FormatDate'
import { CSVLink } from "react-csv"

const DailySales = (props) => {
    const [invoiceList, setInvoiceList] = useState([])
    const [tableCsvContent, setTableCsvContent] = useState([])
    const [loading, setLoading] = useState(true)
    const [dailySale, setDailySale] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date())

    useEffect(() => {
        getInvoices()
    }, [selectedDate])

    const columns = [
        { key: 'invoice', name: 'Invoice', textAlign: 'left' },
        { key: 'invoiceNumber', name: 'Invoice Number', textAlign: 'left' },
        { key: 'customer', name: 'Customer', textAlign: 'left' },
        { key: 'psi', name: 'PSI', textAlign: 'left' },
        { key: 'yards', name: 'Total Yards', textAlign: 'right' },
        { key: 'subtotal', name: 'Subtotal', textAlign: 'right' },
        { key: 'tax', name: 'Tax', textAlign: 'right' },
        { key: 'total', name: 'Total', textAlign: 'right' },
        { key: 'pdf', name: 'PDF', textAlign: 'left' },
    ]

    const headers = () => {
        const columnsCSV = columns
        columnsCSV.pop()
        return columnsCSV.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const getInvoices = async () => {
        const tomorrow = new Date()
        tomorrow.setDate(selectedDate.getDate() + 1)
        const url = api + `/invoice?date=${formatDate(selectedDate)}&endDate=${formatDate(tomorrow)}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            const total = data.reduce((acc, venta) => acc + venta.invoiceTotal, 0)
            setInvoiceList(data)
            setDailySale(total)
            setLoading(false)
            createTableContent(data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const createTableContent = (data) => {
        let dataFormatted = []
        data.map((item) => {
            const row = {
                invoice: new Date(item.invoiceDate).toLocaleDateString(),
                invoiceNumber: item.invoiceNumber,
                customer: item.customer.customerNumber + ' ' + item.customer.name,
                psi: item.psi,
                yards: item.quantity,
                subtotal: item.amount,
                tax: item.taxAmount,
                total: item.invoiceTotal
            }
            dataFormatted.push(row)
        })
        setTableCsvContent(dataFormatted)
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const Ventas = () => {
        if (invoiceList.length > 0) {
            return invoiceList.map((currentSale) => {
                return <InvoiceItem sale={currentSale} key={currentSale._id} />
            })
        } else {
            return (
                <tr>
                    <td colspan="7">
                        No sales for selected date
                    </td>
                </tr>
            )
        }
    }

    const RenderInvoice = () => {
        return (
            <div>
                <h3>
                    Total Sale with taxes: {toCurrency(dailySale, 2)}
                </h3>
                <table className='table'>
                    <thead>
                        <tr>
                            {
                                columns.map((column) => {
                                    return <th style={{ textAlign: column.textAlign }}> {column.name} </th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <Ventas />
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Layout
            title='Daily Sales'
            description='Sale List'
        >
            <div className='col-sm-2'>
                <label className="text-muted">Select Date</label>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                />
                <br />
            </div>
            {
                loading ?
                    <RenderLoading />
                    : <RenderInvoice />
            }   
            <br />
            <CSVLink data={tableCsvContent} headers={headers()} filename={"57concrete-dailysales-report.csv"}>
                Download report
            </CSVLink>     
        </Layout>
    )
}

export default DailySales