import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import { getEmailLists } from '../../actions/emails'

const EmailList = ({ getEmailLists, emails: { emails, loading } }) => {

    let emailListSize = emails.length

    useEffect(() => {
        getEmailLists()
    }, [])

    useEffect(() => {
        emailListSize = emails.length
    }, [emails])

    // When state is updated, get new list
    if (emails.length === undefined) {
        getEmailLists()
    }

    const DrawList = () => {
        return emails.map((emailItem) => {
            return <tr key={emailItem._id}>
                <td>
                    <Link to={`/emaillist/${emailItem._id}`} >
                        {emailItem.name}
                    </Link>
                </td>
                <td>
                    {emailItem.description ? emailItem.description : "No description"}
                </td>
                <td>
                    {emailItem.receipient ? emailItem.receipient.length : 0}
                </td>
            </tr>
        })
    }

    const RenderEmails = () => (
        <table className='table'>
            <thead>
                <tr>
                    <td>Name</td>
                    <td>Description</td>
                    <td>Number of receipients</td>
                </tr>
            </thead>
            <tbody>
                <DrawList />
            </tbody>
        </table>
    )

    return (
        <Layout
            title='Email'
            description='Email-list List'
            className='container col-md-8 offset-md-2'
        >
            {loading
                ? <Spinner />
                : emails.length > 0 ? <RenderEmails /> : "No e-mail lists registered"
            }
            <div>
                <Link to='/newemaillist' className='btn btn-success'>Create Email List</Link>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    emails: state.emails
})

export default connect(mapStateToProps, { getEmailLists })(EmailList)
