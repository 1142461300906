import {
    ADD_PROJECT,
    GET_PROJECT,
    GET_PROJECTS,
    UPDATE_PROJECT,
    PROJECT_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    project: null,
    projects: []
}

function projectReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_PROJECT:
            return {
                ...state,
                project: payload,
                loading: false
            }
        case GET_PROJECT:
            return {
                ...state,
                project: payload,
                loading: false
            }
        case GET_PROJECTS:
            return {
                ...state,
                projects: payload,
                loading: false
            }
        case UPDATE_PROJECT:
            return {
                ...state,
                project: payload,
                loading: false
            }
        case PROJECT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default projectReducer
