import React, {useState} from 'react'
import Layout from '../layout/Layout'
import { Redirect } from 'react-router-dom'
// para usar el store, actions/reducers:
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert'
import { register } from '../../actions/auth'
import PropTypes from 'prop-types'


const Register = ({ setAlert, register, isAuthenticated }) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        password2: ''
    })

    const {name, email, password, password2} = values

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value})
    }

    const clickSubmit = async event => {
        event.preventDefault()

        if (password !== password2) {
            console.error('Passwords do not match')
            setAlert('Passwords do not match', 'danger')
        } else {
            // llamada en la action
            register({name, email, password})
        }        
    }

    const RegisterForm = () => (
        <form className="form">
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input 
                    onChange={handleChange('name')} 
                    type="text" 
                    className="form-control"
                    value={name}
                    placeholder="Write down your name"
                    required
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input 
                    onChange={handleChange('email')} 
                    type="text" 
                    className="form-control" 
                    value={email}
                    required
                    placeholder="Write down your email"
                />            
            </div>
            <div className="form-group">
                <label className="text-muted">Password</label>
                <input 
                    onChange={handleChange('password')} 
                    type="password" 
                    className="form-control" 
                    required
                    value={password}
                    minLength='5'
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Password Confirmation</label>
                <input 
                    onChange={handleChange('password2')} 
                    type="password" 
                    className="form-control" 
                    value={password2}                    
                    minLength='5'
                />
            </div>            
            <div className="my-2">
                <button onClick={clickSubmit} className="btn btn-primary">submit</button>
            </div>            
        </form>
    )

    // mandar un redirect cuando el usuario ya se autentico.
    if (isAuthenticated) {
        return <Redirect to='/dashboard' />
    }

    return (
        <Layout 
            title="Register" 
            description="New user registration"
            className="container col-md-8 offset-md-2"
        >            
            {RegisterForm()}
            {/* {JSON.stringify(values)}             */}
        </Layout>
    )
}

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

// al usar "conect" la exportacion siempre debe tener esta forma
export default connect(
    mapStateToProps, 
    { setAlert, register }
)(Register)

