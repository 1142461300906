import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api';
import axios from 'axios';
import { CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs';

const mineOptions = [
    {
        value: 'Texan',
        label: 'Texan'
    },
    {
        value: 'Mile 4',
        label: 'Mile 4'
    },
]

const materialOptions = [
    {
        value: 'Sand',
        label: 'Sand'
    },
    {
        value: 'Gravel',
        label: 'Gravel'
    },
    {
        value: 'Pea Gravel',
        label: 'Pea Gravel'
    },
    {
        value: 'Caliche',
        label: 'Caliche'
    },
    {
        value: 'Select Fill',
        label: 'Select Fill'
    },
    {
        value: 'Construction Debris',
        label: 'Construction Debris'
    },
    {
        value: 'Cyclone Sand',
        label: 'Cyclone Sand'
    },
]

const LogisticsTrip = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(true)
    const [plantOptions, setPlantOptions] = useState(true)
    const [truckOptions, setTruckOptions] = useState(true)
    const [driverOptions, setDriverOptions] = useState(true)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        Promise.all([getPlants(), getDrivers(), getTrucks()]).then(() => {
            getTrip()
        })
    }, [])

    const getPlants = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let plantList = []
            data.map((item) => {
                plantList.push({
                    label: item.name,
                    value: item._id
                })
            })
            setPlantOptions(plantList)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getDrivers = async () => {
        const url = api + '/logisticsdriver/list'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let driverList = []
            data.map((item) => {
                driverList.push({
                    label: item.name,
                    value: item._id
                })
            })
            setDriverOptions(driverList)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getTrucks = async () => {
        const url = api + '/logisticstruck/list'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let truckList = []
            data.map((item) => {
                truckList.push({
                    label: item.number,
                    value: item._id
                })
            })
            setTruckOptions(truckList)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getTrip = async () => {
        const url = api + '/logisticstrip/get/jap/' + props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)

            let duration = '0'
            if(data.startedDate && data.receivedDate){
                var diffMs = new Date(data.receivedDate) - new Date(data.startedDate)
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                duration = `${diffHrs} hours, ${diffMins} minutes`
            }  

            setInitialValues({
                ...initialValues,
                mine: data.mine,
                material: data.material,
                plant: data.plant._id,
                truck: data.truck._id,
                driver: data.driver._id,
                ticket: data.ticket,
                quantity: data.quantity,
                duration: duration,
                startedDate: data.startedDate ? `${dayjs(data.startedDate).toDate().toLocaleDateString()} - ${dayjs(data.startedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-',
                receivedDate: data.receivedDate ? `${dayjs(data.receivedDate).toDate().toLocaleDateString()} - ${dayjs(data.receivedDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-',
            })
            setLoadingForm(false)
        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to get truck')
            console.error(err.message)
            return null
        }
    }

    const deleteTrip = async () => {
        setLoading(true)

        const url = api + '/logisticstrip/delete/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Trip Deleted')
            setLoading(false)
            props.history.push('/logisticsdashboard')
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const updateTrip = async (data) => {
        const trip = {
            mine: data.mine,
            material: data.material,
            plant: data.plant,
            truck: data.truck,
            driver: data.driver,
            quantity: data.quantity,
        }

        try {
            let url = api + '/logisticstrip/update/jap/' + props.match.params.id  
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, trip)
            openNotification('success', 'Success', 'Trip updated')
            setLoading(false)
            props.history.push('/logisticsdashboard')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to update trip')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        updateTrip(values)
    }

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Ticket'
                    name='ticket'
                    rules={[
                        {
                            required: true,
                            message: 'Ticket is required'
                        }
                    ]}
                    >
                        <Input size='large' readOnly disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Mine'
                    name='mine'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a mine'
                        }
                    ]}
                    >
                        <Select
                        allowClear={false}
                        placeholder={`Select mine`}
                        size='large'
                        options={mineOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Driver'
                    name='driver'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a driver'
                        }
                    ]}
                    >
                        <Select
                        allowClear={false}
                        placeholder={`Select driver`}
                        size='large'
                        options={driverOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Truck'
                    name='truck'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a truck'
                        }
                    ]}
                    >
                        <Select
                        allowClear={false}
                        placeholder={`Select truck`}
                        size='large'
                        options={truckOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Material'
                    name='material'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a material'
                        }
                    ]}
                    >
                        <Select
                        allowClear={false}
                        placeholder={`Select material`}
                        size='large'
                        options={materialOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Plant'
                    name='plant'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a plant'
                        }
                    ]}
                    >
                        <Select
                        allowClear={false}
                        placeholder={`Select plant`}
                        size='large'
                        options={plantOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Started Date'
                    name='startedDate'
                    >
                        <Input size='large' readOnly disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Received Date'
                    name='receivedDate'
                    >
                        <Input size='large' readOnly disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Duration'
                    name='duration'
                    >
                        <Input size='large' readOnly disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Quantity'
                    name='quantity'
                    >
                        <InputNumber size='large' style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Col>
                {
                    (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'vm@57concrete.com') &&
                    <Col>
                        <Popconfirm
                            title="Delete trip"
                            description="Are you sure to delete this trip?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteTrip}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                        </Popconfirm>
                    </Col>
                }
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Logistics Trip'
        description='Edit Logistics Trip'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm />}
        </Layout>
    )
}

export default LogisticsTrip