import {
    ADD_CLIENT,
    GET_CLIENT,
    GET_CLIENTS,
    UPDATE_CLIENT,
    GET_CLIENTS_ALL,
    // DELETE_CLIENT,
    CLIENT_ERROR,
    CLIENT_GROUP
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    client: null
}

export default function clientReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_CLIENT:            
            return {
                ...state,
                client: payload,
                loading: false
            }
        case GET_CLIENT:
            return {
                ...state,
                client: payload,
                loading: false
            }
        case GET_CLIENTS:
        case GET_CLIENTS_ALL:
            return {
                ...state,                
                clients: payload,                
                loading: false
            }
        case UPDATE_CLIENT:
            return {
                ...state,
                client: payload,
                loading: false
            }
        case CLIENT_GROUP:
            return {
                ...state,
                group: payload,
                loading: false
            }
        case CLIENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}
