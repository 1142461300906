import { Component } from 'react';
import axios from 'axios';
import { api } from '../utils/Api';
import GoogleMap from './GoogleMap';
import './GoogleMap.css';
import MarkerComponent from './MarkerComponent'
import { CustomSpinner } from '../utils/CustomComponents';

class MultipleMap extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            trucks: [],
            center: {lat: 26.2787785, lng: -98.4021818},
            googleApi: process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU',
            type: this.props.type,
            loading: true
        };
    }
  
    getTruckList = async () => {
        const url = api + '/trucklistmap/type/'+this.state.type        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)   
            let coordinatesTrucks = []
            let centerCoordinates = {}

            data.map((item, index) => {
                if(item.latitude && item.longitude){
                    if(index === 0){
                        centerCoordinates = {lat: item.latitude, lng: item.longitude}
                    }
                    coordinatesTrucks.push({
                        key: index,
                        number: item.number,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        show: false,
                        hover: false,
                        direction: item.direction,
                        plant: item.plant
                    })
                }
            }) 

            this.setState({
                trucks: coordinatesTrucks,
                center: centerCoordinates,
                loading: false
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }
  
    componentDidMount() {
        this.getTruckList()
    }

    onChildClickCallback = (key) => {
        const index = this.state.trucks.findIndex((e) => e.number === key);
        this.state.trucks[index].show = !this.state.trucks[index].show; 
        this.setState({
            trucks: this.state.trucks
        })

    };
  
    onChildMouseEnter = (key) => {
        const index = this.state.trucks.findIndex((e) => e.number === key);
        this.state.trucks[index].hover = true; 
        this.state.trucks[index].show = true;
        this.setState({
            trucks: this.state.trucks
        })
    }

    onChildMouseLeave = (key) => {
        const index = this.state.trucks.findIndex((e) => e.number === key);
        this.state.trucks[index].hover = false; 
        this.state.trucks[index].show = false;
        this.setState({
            trucks: this.state.trucks
        })
    }

    render() {
    const { trucks, center, googleApi } = this.state;
  
      return (
        <div >
            {
                this.state.loading ? 
                <CustomSpinner />
                :
                <GoogleMap
                defaultZoom={12}
                defaultCenter={center}
                bootstrapURLKeys={{ key: googleApi }}
                onChildClick={this.onChildClickCallback}
                onChildMouseEnter={this.onChildMouseEnter}
                onChildMouseLeave={this.onChildMouseLeave}
                >
                {trucks.map((truck) => (
                    <MarkerComponent
                    key={truck.number}
                    lat={truck.latitude}
                    lng={truck.longitude}
                    show={truck.show}
                    hover={truck.hover}
                    truck={truck}
                    />
                ))}
            </GoogleMap>
            }
        </div>
      );
    }
}

export default MultipleMap;