import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <ConfigProvider
    theme={{
      token:{
        colorPrimary: '#233F86'
      }
    }}
  >
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
