import React, { useState, useEffect, useRef } from 'react'
import { api } from '../utils/Api'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { dateFormat } from '../utils/Convert'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'

const OrderList = () => {
    const [orderSummary, setOrderSummary] = useState([])
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        getOrderSummaryList()
    }, [update])

    const getOrderSummaryList = async () => {
        const url = api + '/ordersummaryfiles'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const {data} = await axios.get(url)
            const lista = data.map(file => {
                return {
                    fileDate: file._id.fileDate,
                    fileName: file._id.fileName,                    
                }
            })
            lista.sort((a,b) => a.fileDate > b.fileDate ? 1 : -1)
            setOrderSummary(lista)
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    } 

    const deleteFile = async(date) => {
        const url = api + `/ordersummarydate/${date}`
        console.log(url)
        try {
            const {data} = await axios.delete(url)
            console.log(data)
            setUpdate(!update)
        } catch (err) {
            console.error(err)
            return null
        }
    }
    
    const DrawList = () => {
        return orderSummary.map((orderSummary, i) => {
            return <tr key={i}>
                <td>
                    <Link to={'/orderlistdetail/'+orderSummary.fileDate}>
                        {orderSummary.fileName}
                    </Link>
                </td>
                <td>
                    {dateFormat(orderSummary.fileDate)}
                </td>
                <td>
                    <button 
                        className='btn btn-danger'
                        onClick= {() => deleteFile(orderSummary.fileDate)}
                    >
                        -
                    </button>
                </td>
            </tr>
        })
    }
 
    const FileTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>File</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList/>
                </tbody>

            </table>
        </div>
    )   

    return (
        <Layout
            title='Order Summary'
            description='File list'
            className='container col-md-8 offset-md-2'
        >
            {
                loading ? <Spinner /> : <FileTable />
            }
        </Layout>        
    )
}

export default OrderList