import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { typeClient, pagedClient, searchClient, groupedCustomers } from '../../actions/client'
import CountUp from 'react-countup'
import { Button, Card, Checkbox, Col, Flex, Row, Skeleton, Statistic, Typography } from 'antd'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
const {Title, Text} = Typography

const formatter = (value) => <CountUp end={value} duration={2} separator="," />


const ClientList = ({ history, typeClient, pagedClient, searchClient, groupedCustomers, client: { clients, group } }) => {
    const [newCustomer, setNewCustomer] = useState(0)
    const [devCustomer, setDevCustomer] = useState(0)
    const [porCustomer, setPorCustomer] = useState(0)
    const [customLoading, setCustomLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(true)
    const [customerList, setCustomerList] = useState([])

    /**
     ** Options header (columns)
    */
     const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 200,
            render: (_, record) => {
              return <Flex justify='space-between'>
                    <Button 
                    type="primary" 
                    icon={<EditFilled twoToneColor={'white'}/>} 
                    onClick={e=> history.push(`/client/${record.id}`)}
                    />
                    <Button 
                    type="primary"
                    color='red'
                    onClick={e=> history.push(`/clientsummary/${record.id}`)}
                    >
                        Summary
                    </Button>
                </Flex>
            }
        },
        {
            title: 'Customer',
            dataIndex: 'name',
            key: 'name',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Classification',
            dataIndex: 'classification',
            key: 'classification',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Credit Hold',
            dataIndex: 'creditHold',
            key: 'creditHold',
            render: (value) => {
                return <Checkbox checked={value} />
            }
        },
        {
            title: 'Request Credit',
            dataIndex: 'requestCredit',
            key: 'requestCredit',
            render: (value) => {
                return <Checkbox checked={value} />
            }
        },
        {
            title: 'Phone Quote',
            dataIndex: 'phoneQuote',
            key: 'phoneQuote',
            render: (value) => {
                return <Checkbox checked={value} />
            }
        },
    ]

    useEffect(() => {
        const initialCustomer = 0
        const endCustomer = 15
        groupedCustomers()
        pagedClient(initialCustomer, endCustomer)
    }, [])

    useEffect(() => {
        setCustomLoading(true)
        getCount()
    }, [group])

    useEffect(() => {
        setTableLoading(true)
        assignCustomerList()
    }, [clients])

    const assignCustomerList = () => {
        if(clients){
            let list = []
            clients.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    name: item.customerNumber + ' ' + item.name,
                    address: item.address,
                    phone: item.contactPhone ? item.contactPhone : '',
                    type: item.customerType,
                    classification: item.classification,
                    creditHold: item.creditHold ? item.creditHold : false,
                    requestCredit: item.creditRequested ? item.creditRequested : false,
                    phoneQuote: item.isProspect ? item.isProspect : false,
                })
            })
            setCustomerList(list)
        }else{
            setCustomerList([])
        }
        setTableLoading(false)
    }

    const getClassCustomers = async (type) => {
        await typeClient(type)
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            searchClient(value)
        }
    }  

    const getCount = () => {
        if (typeof group !== "undefined") {
            group.forEach(item => {
                if (item._id === 'new') {
                    setNewCustomer(item.count)
                } else if (item._id === 'development') {
                    setDevCustomer(item.count)
                } else {
                    setPorCustomer(item.count)
                }
            })
            setCustomLoading(false)
        }
    }
    const RenderSearch = () => {
        return <Flex gap={16}>
            <CustomSearch 
            loading={tableLoading}
            onSearch={onSearch}
            />
            <Button
                onClick={addNewCustomer}
                type="primary"
                size='large'
                style={{
                    width: 150,
                }}
                icon={<PlusCircleFilled />}
            >
                New Customer
            </Button>
        </Flex>
    }

    const ClientTable = () => (
        <Flex gap={'middle'} vertical>
            <CustomTable 
            columns={columns}
            data={customerList}
            />
        </Flex>
    )

    const ClientGroup = () => (
        <>
            <Row gutter={16} >
            <Col span={8}>
                <Card bordered style={{backgroundColor: '#1D64E2', cursor: 'pointer'}} onClick={(e) => getClassCustomers("new")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>New Customers</Title>
                            <Text>
                                <Statistic title="Customers" value={newCustomer} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={8}>
                <Card bordered style={{backgroundColor: '#E21D64', cursor: 'pointer'}} onClick={(e) => getClassCustomers("development")}>
                {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Development</Title>
                            <Row gutter={16} justify={'center'} align={'middle'} style={{width: '100%'}}> 
                                <Col span={6}>
                                    <Text>
                                        <Statistic title="Customers" value={devCustomer} formatter={formatter} valueStyle={{color:'white'}} />
                                    </Text>
                                </Col>

                                
                            </Row>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={8}>
                <Card bordered style={{backgroundColor: 'green', cursor: 'pointer'}} onClick={(e) => getClassCustomers("portfolio")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Portfolio</Title>
                            <Text>
                                <Statistic title="Customers" value={porCustomer} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
        </Row>
        </>
    )

    const addNewCustomer = () => {
        history.push('/nuevocliente')
    }

    return (
        <Layout
            title='Customer'
            description=''
        >
            <RenderSearch />
            <ClientGroup />
            {
                tableLoading ? <CustomSpinner /> : <ClientTable />
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    client: state.client,
})

export default connect(mapStateToProps, { typeClient, pagedClient, searchClient, groupedCustomers })(ClientList)