import React, { Component } from 'react';
import Select from 'react-select';

export default class RolePicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isSearchable: true,
            options: [
                {value: 'Driver', label: 'Driver'},
                {value: 'Admin', label: 'Admin'},
                {value: 'Seller', label: 'Seller'},
                {value: 'Finance', label: 'Finance'}
            ]
            
        }
    }

    render() {

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Role...'
                    value={this.state.options.filter((o) => { return o.value === this.props.value })}
                    onChange={this.props.action}
                    isSearchable={this.state.isSearchable}
                    options={this.state.options}
                />
                <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={this.props.value}
                required
                />
            </div>
        )
    } 
}
