import api from '../utils/api'
import { setAlert } from './alert'
import { 
    ADD_JOB,
    UPDATE_JOB,
    GET_JOB,
    GET_JOBS,
    DELETE_JOB,
    JOB_ERROR,
    GET_YARDS
} from './types'

// create new job
export const createJob = (formData) => async dispatch => {
    try {
        const res = await api.post('/job', formData)

        dispatch({
            type: ADD_JOB,
            payload: res.data
        })

        dispatch(setAlert('Job Added', 'success'))
    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
        }

        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// get a job by ID
export const getJob = id => async dispatch => {
    try {
        const res = await api.get(`/job/${id}`)
        console.log(res.data)

        dispatch({
            type: GET_JOB,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// get job list by customer id
export const getJobList = id => async dispatch => {
    try {
        const res = await api.get(`/joblist/${id}`)
        console.log(res.data)

        dispatch({
            type: GET_JOBS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// Get job list by date
export const getJobsDate = dateJob => async dispatch => {
    try {
        const peticion = `/jobdate?date=${dateJob}`
        const { data } = await api.get(peticion)
        const sumLoad = data.reduce((acc, job) => acc + job.loadYD3, 0 )
        const sumTarget = data.reduce((acc, job) => acc + (typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3) : job.targetYD3), 0) 
        const sumBalance = data.reduce((acc, job) => acc + job.balance, 0)
        console.log(`load: ${sumLoad}, target: ${sumTarget}, balance: ${sumBalance}`)
        dispatch({
            type: GET_JOBS,
            payload: data
        })
    } catch (err) {
        console.log(err.response)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// Get jobs list for today
export const getJobsToday = () => async dispatch => {
    try {
        const { data } = await api.get('/jobtoday')
        console.log(data)
        dispatch({
            type: GET_JOBS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Future jobs with stage
export const getFutureJobs = (stage) => async dispatch => {
    try {
        const { data } = await api.get('/jobfuture/Inspection')
        dispatch({
            type: GET_JOBS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Jobs from today to end of year
export const getJobsNowOn = () => async dispatch => {
    try {
        const { data } = await api.get('/jobnowon')

        dispatch({
            type: GET_JOBS,
            payload: data
        })
        
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// update job 
export const updateJob = (jobId, formData) => async dispatch => {
    try {
        const { data } = await api.patch(`/job/${jobId}`, formData)

        dispatch({
            type: UPDATE_JOB,
            payload: data
        })

        // dispatch(setAlert('Job Updated', 'success'))
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// delete job
export const deleteJob = jobId => async dispatch => {
    try {
        const { data } = await api.delete(`/job/${jobId}`)

        dispatch({
            type: DELETE_JOB,
            payload: data
        })

        dispatch(setAlert('Job Deleted', 'success'))
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Yards for each seller
export const groupSeller = (customer) => async(dispatch) => {
    try {
        const { data } = await api.get(`/jobgroupseller?customer=${customer}`)
        dispatch({
            type: GET_YARDS,
            payload: data
        })
    } catch (err) {
        console.error(err)
        dispatch({
            type: JOB_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })        
    }
}