import React, { useEffect, useState } from 'react';

const SchoolsRender = ({currentSchool, schools, setSchools, toggleModal, edit, index}) => {

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [years, setYears] = useState(0);
    const [degree, setDegree] = useState('');
    const [graduated, setGraduated] = useState('yes');

    useEffect(() => {
        setCurrentSchool()
    }, [edit])
    

    const setCurrentSchool = () => {
        if(edit){
            setName(currentSchool.name);
            setAddress(currentSchool.address);
            setYears(currentSchool.years);
            setDegree(currentSchool.degree);
            setGraduated(currentSchool.graduated ? 'yes' : 'no');
        }
    }

    const handleChange = event => {
        setGraduated(event.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newSchool = {
            name,
            address,
            years,
            degree,
            graduated: graduated === 'yes' ? true : false
        };
        if(edit){
            schools[index].name = name;
            schools[index].address = address;
            schools[index].years = years;
            schools[index].degree = degree;
            schools[index].graduated = graduated === 'yes' ? true : false;
        }else{
            schools.push(newSchool);
        }
        setSchools(schools);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Name</label>
                    <input className='form-control'
                        name='name'
                        type='text'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Name'
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Address</label>
                    <input className='form-control'
                        name='address'
                        type='text'
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        placeholder='Address'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'># Years</label>
                    <input className='form-control'
                        name='years'
                        type='number'
                        value={years}
                        onChange={e => setYears(e.target.valueAsNumber)}
                        placeholder='Years'
                        min={0}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Mayor/Degree</label>
                    <input className='form-control'
                        name='degree'
                        type='text'
                        value={degree}
                        onChange={e => setDegree(e.target.value)}
                        placeholder='Degree'
                        required
                    />
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>Graduated</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="yes"
                    name="choose"
                    value="yes"
                    checked={graduated === 'yes'}
                    onChange={handleChange}
                    />
                    <label htmlFor="yes">Yes</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="no"
                    name="choose"
                    value="no"
                    checked={graduated === 'no'}
                    onChange={handleChange}
                    />
                    <label htmlFor="no">No</label>
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
            
        </form>
    )
}

export default SchoolsRender