import React, { useState } from 'react'
import Layout from '../layout/Layout'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createClient } from '../../actions/client'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import { components } from "react-select"


const ClientNew = ({
    user,
    createClient,
    history
}) => {
    const initialState = {
        name: '',
        email: '',
        sms: '',
        contactType: 'Email',
        notifiableEvents: [],
        address: '',
        city: '',
        state: 'TX',
        zip: '',
        contactPhone: '',
        contactFax: '',
        accountsPayableContact: '',
        accountsPayableEmail: '',
        accountsPayablePhone: '',
        accountsPayableFax: '',
        paymentType: 'PrePay'
    }

    const [formData, setFormData] = useState(initialState)

    const {
        name,
        email,
        sms,
        contactType,
        notifiableEvents,
        address,
        city,
        state,
        zip,
        contactPhone,
        contactFax,
        accountsPayableContact,
        accountsPayableEmail,
        accountsPayablePhone,
        accountsPayableFax,
        paymentType
    } = formData

    //Labels for notifiable events
    const options = [
        { value: 'newJob', label: 'New Job' },
        { value: 'newLoad', label: 'New Load' },
        { value: 'newInvoice', label: 'New Invoice' },
        { value: 'newEticket', label: 'New Eticket' }
    ]
    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault()
        console.log(formData);
        createClient(formData)
        history.push('/listacliente')
    }

    // payment type picker
    const onChangePaymentType = selectedPayment => {
        if (selectedPayment) {
            console.log(selectedPayment.label)
            setFormData({ ...formData, paymentType: selectedPayment.label })
        }
    }

    // SMS number format
    const onChangeSMS = e => {
        if (e) {
            setFormData({ ...formData, sms: e })
        }
    }
    // Notifications selection
    const onChangeNotifiable = e => {
        if (e) {
            setFormData({ ...formData, notifiableEvents: e })
        }
    }


    const ClientForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className='form-group'>
                <label className='text-muted'>Company name (*)</label>
                <input
                    type='text'
                    name='name'
                    required
                    className='form-control'
                    onChange={onChange}
                    value={name}
                    placeholder='Company name'
                />
            </div>
            <div className="form-group">
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Type (*):</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='Email'
                                checked={contactType == "Email"}
                                onChange={onChange}
                            />
                            Email
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='SMS'
                                checked={contactType == "SMS"}
                                onChange={onChange}
                            />
                        </label>
                        SMS
                    </div>
                </div>
            </div>
            {contactType == "Email"
                ? <div className="form-group">
                    <input
                        name="email"
                        onChange={onChange}
                        type="text"
                        className="form-control"
                        value={email}
                        placeholder="Contact email"
                        required
                    />
                </div>
                : <div className="form-group">
                    <PhoneInput
                        name="sms"
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Customer phone"
                        value={sms}
                        onChange={onChangeSMS}
                        required
                    />
                </div>
            }
            <div className="form-group">
                <label className="text-muted">Notifiable events</label>
                <Select
                    options={options}
                    // placeholder='Select Fields '
                    onChange={onChangeNotifiable}
                    isMulti
                    value={notifiableEvents}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    components={{
                        Option
                    }}
                    allowSelectAll={true}
                />
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Address</label>
                    <input
                        onChange={onChange}
                        type="text"
                        name='address'
                        className="form-control"
                        value={address}
                        placeholder="Company Address"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>City</label>
                    <input
                        type="text"
                        name='city'
                        className='form-control'
                        onChange={onChange}
                        value={city}
                        placeholder='Company City Address'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>State</label>
                    <input
                        type='text'
                        name='state'
                        className='form-control'
                        value={state}
                        onChange={onChange}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>ZIP</label>
                    <input
                        type='text'
                        name='zip'
                        className='form-control'
                        value={zip}
                        onChange={onChange}
                        placeholder='Company ZIP'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <label className='text-muted'>Contact number (*)</label>
                <div className='col-sm-6'>
                    <input
                        type='text'
                        name='contactPhone'
                        required
                        className='form-control'
                        value={contactPhone}
                        onChange={onChange}
                        placeholder='Contact Phone Number'
                    />
                </div>
                <div className='col-sm-6'>
                    <input
                        type='text'
                        name='contactFax'
                        className='form-control'
                        value={contactFax}
                        onChange={onChange}
                        placeholder='Contact Fax Number'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Contact</label>
                    <input
                        type='text'
                        name='accountsPayableContact'
                        className='form-control'
                        placeholder='Contact person'
                        value={accountsPayableContact}
                        onChange={onChange}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Email</label>
                    <input
                        type='text'
                        name='accountsPayableEmail'
                        className='form-control'
                        value={accountsPayableEmail}
                        onChange={onChange}
                        placeholder='A/P contact email'
                    />
                </div>
            </div>
            {
                localStorage.getItem('role') === 'Finance' &&
                <div className="form-group">
                    <label className="text-muted">Payment Type:</label>
                    <PaymentTypePicker action={onChangePaymentType} />
                </div>
            }
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Phone</label>
                    <input
                        type='text'
                        name='accountsPayablePhone'
                        className='form-control'
                        placeholder='A/P contact phone'
                        value={accountsPayablePhone}
                        onChange={onChange}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>A/P Fax</label>
                    <input
                        type='text'
                        name='accountsPayableFax'
                        className='form-control'
                        value={accountsPayableFax}
                        onChange={onChange}
                        placeholder='A/P contact fax'
                    />
                </div>
            </div>
            <div className="my-2">
                <input className='btn btn-primary' placeholder='New' type='submit' />
            </div>
        </form>
    )

    return (
        <Layout
            title='New Customer'
            description='New Customer registration form'
            className='container col-md-8 offset-md-2'
        >
            {ClientForm()}
        </Layout>
    )
}


ClientNew.propTypes = {
    user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    user: state.auth.usuario
})

export default connect(mapStateToProps, { createClient })(ClientNew)
