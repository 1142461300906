import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { CustomSpinner } from '../utils/CustomComponents'
import EticketMarker from './EticketMarker'


const EticketGeolocation = props => {
    const [latitude, setLatitude] = useState(props.origin.latitude)
    const [longitude, setLongitude] = useState(props.origin.longitude)
    const [destLat, setDestLat] = useState(props.destination.latitude)
    const [destLng, setDestLng] = useState(props.destination.longitude)
    const [loading, setLoading] = useState(false)
    const [locations, setLocations] = useState(props.locations)

    const googleApi = process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU'

    const defaultMap = {
        center: {
            lat: locations[0].latitude, 
            lng: locations[0].longitude
        },
        zoom: 13
    }

    const fitBounds = (map, maps) => {
        var bounds = new maps.LatLngBounds()
        for (let marker of locations) {
          bounds.extend(
            new maps.LatLng(marker.latitude, marker.longitude)
          )
        }
        map.fitBounds(bounds)
    }


    return (
        <div>
            <div style={{ height: '60vh', width: '100%' }}>     
            {
                loading ? 
                <CustomSpinner />
                :
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApi }}
                    defaultCenter={defaultMap.center}
                    defaultZoom={defaultMap.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => fitBounds(map, maps)}
                >           
                    {
                        locations.map((location, index) => (
                                <EticketMarker 
                                    key={index}
                                    lat={location.latitude}
                                    lng={location.longitude}
                                    color='#021450'
                                />
                        ))
                    }   
                    <EticketMarker 
                        lat={latitude}
                        lng={longitude}
                        color='red'
                        name='57Concrete'
                    />    
                    <EticketMarker      
                        lat={destLat}
                        lng={destLng}
                        color='green'
                        name='Destination'
                    />          
                </GoogleMapReact>    
                
                }
            </div>
        </div>
    )

}

export default EticketGeolocation