import React from 'react'
import Select from 'react-select'

const TaxPicker = ({action, value}) => {
    const options = [
        { value: 'state', label: 'State' },
        { value: 'county', label: 'County' },
        { value: 'exempt', label: 'Exempt' }              
      ]
    return (
        <div>
            <Select
                value={value}
                options={options}
                placeholder='Select'
                onChange={action}
            />
        </div>
    )
}

export default TaxPicker