import React, { useState, useEffect } from 'react'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import {
    Line,
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    Legend,
    Tooltip
} from 'recharts'
import { toThousands, toThousandsWkS } from '../utils/Convert'
import { CSVLink } from "react-csv"
import Select from 'react-select'
import { components } from "react-select"
import DatePicker from 'react-datepicker'
import { jobIdFilter } from '../utils/JobIdFilter'
import { Link } from 'react-router-dom'
import { get } from 'mongoose';

const YearForecast = () => {
    const [chartData, setChartData] = useState([])
    const [quarterChartData, setQuarterChartData] = useState([])
    const [showSoldWeek, setShowSoldWeek] = useState(false)
    const [showSoldYear, setShowSoldYear] = useState(false)
    const [showSoldQuarter, setShowSoldQuarter] = useState(false)
    const [salesPerWeek, setSalesPerWeek] = useState([])
    const [salesPerSeller, setSalesPerSeller] = useState([])
    const [dataForModal, setDataForModal] = useState([])
    const [dataForModalSeller, setDataForModalSeller] = useState([])
    const [bestWk, setBestWk] = useState("")
    const [bestYds, setBestYds] = useState("")
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const [month, setMonth] = useState(months[0])
    const [rows, setRows] = useState([])
    const [rows2, setRows2] = useState([])
    const [sellerRow, setSellerRow] = useState([])
    const [yardsRow, setYardsRow] = useState([])
    const [quarters, setQuarters] = useState([])
    const [groupQ, setGroupQ] = useState([])
    const [yearDate, setYearDate] = useState(new Date())
    const [pastYards, setPastYards] = useState([])
    const [totalLoadsCommercial, setTotalLoadsCommercial] = useState(0)
    const [totalLoadsResidential, setTotalLoadsResidential] = useState(0)
    const [totalTargetCommercial, setTotalTargetCommercial] = useState(0)
    const [totalTargetResidential, setTotalTargetResidential] = useState(0)
    const [sellerResidentialRow, setSellerResidentialRow] = useState(0)
    const [sellerCommercialRow, setSellerCommercialRow] = useState(0)

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const column = [
        { key: 'mes', label: 'month' },
        { key: 'plan', label: 'plan' },
        { key: 'sold', label: 'real' }
    ]

    const sellerColumn = [
        { key: 'name', label: 'Seller' },
        { key: 'yards', label: 'Loads' },
        { key: 'target', label: 'Target' }
    ]

    const yardsColumn = [
        { key: 'week', label: 'Weeks' },
        { key: 'yards', label: 'Yards of the week' }
    ]

    //Labels for notifiable events
    const options = [
        { value: 'q1', label: 'Q1 (JAN-MAR)' },
        { value: 'q2', label: 'Q2 (APR-JUN)' },
        { value: 'q3', label: 'Q3 (JUL-SEP)' },
        { value: 'q4', label: 'Q4 (OCT-DEC)' }
    ]

    useEffect(() => {        
        getSalesData()        
        getSellerSales()
        getLastYearYards()
        getData()
    }, [])


    const weekNum = () => {
        var currentDate = new Date();
        var startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));

        var weekNumber = Math.ceil(days / 7);

        return weekNumber;
    }

    const getFirstDayOfWeek = () => {
        const date = new Date();
        const day = date.getDay();
        const diff = date.getDate() - day;

        return new Date(date.setDate(diff));
    }

    // get forecast and real yards sold
    const getData = async () => {
        const url = api + '/forecastyear'
        const url2 = api + '/groupedyards'
        const url3 = api + '/jobgrpcustomer?startDate=2023-01-01&endDate=2023-12-31'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        // llamada simultanea con axios
        const req1 = axios.get(url)
        const req2 = axios.get(url2)
        const req3 = axios.get(url3)

        axios.all([req1, req2, req3]).then(axios.spread((...responses) => {
            const { data } = responses[0]
            const plan = data
            let res = responses[1]
            const yarda = res.data
            const past = responses[2].data
            const pasado = []

            past.forEach(dato => {
                const found = pasado.findIndex(e => e.mes === dato._id.month)

                if (found !== -1) {
                    const valor = pasado[found].yard + dato.yards 
                    pasado[found].yard = valor
                } else {
                    const item = {
                        mes: dato._id.month,
                        yard: dato.yards
                    }
                    pasado.push(item)
                }
            })
     
            setPastYards(pasado)
            console.log('pasado')
            console.log(pasado)

            groupData(plan, yarda, pasado)
        })).catch(errors => {
            console.log(errors)
            return null
        })
    }

    // get yards sold previous year
    const getLastYearYards = async () => {
        const url = api + '/jobgrpcustomer?startDate=2023-01-01&endDate=2023-12-31'
        const pasado = []
        try {
            const {data} = await axios.get(url)
            data.forEach(dato => {
                const found = pasado.findIndex(e => e.mes === dato._id.month)

                if (found !== -1) {
                    const valor = pasado[found].yard + dato.yards
                    pasado[found].yard = valor  
                } else {    
                    const item = {
                        mes: dato._id.month,
                        yard: dato.yards 
                    }
                    pasado.push(item)
                }
            })
            setPastYards(pasado)
        } catch (err) {
            console.log(err)
            return null
        }
    }

    const getSalesData = async () => {
        setLoading(true)
        const url = api + '/jobgroupedweek'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const { data } = await axios.get(url)
            .catch(errors => console.log(errors))

        setSalesPerWeek(data)
        setLoading(false)

    }

    const getSellerSales = async () => {
        setLoading(true)
        const startDate = getFirstDayOfWeek()
        const endDate = new Date()
        const url = api + `/jobscheduled?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const { data } = await axios.get(url)
            .catch(errors => console.log(errors))

        setSalesPerSeller(data)
        setLoading(false)
    }

    const groupData = (targets, yardsSold, yardsPast) => {
        const today = new Date()
        const thisYear = today.getFullYear()
        console.log('yardsPast')
        console.log(yardsPast)
        console.log('yardSold')
        console.log(yardsSold)

        // _id: '2022-03'
        const ordenado = targets.sort((a, b) => (a._id > b._id) ? 1 : -1)

        const nuevo = ordenado.map(period => {
            const [year, month] = period._id.split('-')

            if (parseInt(year) === thisYear) {
                const real = yardsSold.filter(valor => valor._id === period._id)
                const sold = typeof real[0] === 'undefined' ? 0 : real[0].yards
                const idx = parseInt(month-1)
                const past = yardsPast[idx].yard === 'undefined' ? 0 : yardsPast[idx].yard
                console.log('idx:', idx)
                console.log(yardsPast[0].yard)

                console.log('month', month)
                console.log('mes', months[parseInt(month) -1])
                console.log('plan', period.yards)
                console.log('sold', sold)
                console.log('past', past)
             
                return {
                    month,
                    mes: months[parseInt(month) - 1],
                    plan: period.yards,
                    sold,
                    past
                }
            }
        })

        console.log('nuevo')
        console.log(nuevo)

        setChartData(nuevo)
        console.log(nuevo)
        setGroupQ([targets, yardsSold])
        setQuarterChartData(nuevo)
        setLoading(false)
    }

    const quarterGroupData = () => {
        setLoadingModal(true)

        if (quarters.length < 1) {
            return alert("Please input one quarter at least!")
        }

        // _id: '2022-03'
        const ordenado = groupQ[0].sort((a, b) => (a._id > b._id) ? 1 : -1)

        const nuevo = ordenado.map(period => {
            const [year, month] = period._id.split('-')

            if (parseInt(year) === yearDate.getFullYear()) {
                const real = groupQ[1].filter(valor => valor._id === period._id)
                const sold = typeof real[0] === 'undefined' ? 0 : real[0].yards

                return {
                    month,
                    mes: months[parseInt(month) - 1],
                    plan: period.yards,
                    sold
                }
            }
        })

        if (typeof nuevo[0] === 'undefined') {
            setYearDate(new Date())
            setLoadingModal(false)
            return alert("Please input a valid year! (No data registered)")
        }

        let newChartData = []

        quarters.sort((a, b) => a.value.localeCompare(b.value))

        nuevo.map((e) => {
            for (let i = 0; i < quarters.length; i++) {
                const quarter = quarters[i].value;
                if ((e.mes === "JAN" || e.mes === "FEB" || e.mes === "MAR") && (quarter === "q1")) {
                    newChartData.push(e)
                } else if ((e.mes === "APR" || e.mes === "MAY" || e.mes === "JUN") && (quarter === "q2")) {
                    newChartData.push(e)
                } else if ((e.mes === "JUL" || e.mes === "AUG" || e.mes === "SEP") && (quarter === "q3")) {
                    newChartData.push(e)
                } else if ((e.mes === "OCT" || e.mes === "NOV" || e.mes === "DEC") && (quarter === "q4")) {
                    newChartData.push(e)
                }
            }
        })
        setQuarterChartData(newChartData)
        dataToExportQuarter(newChartData)
        setLoadingModal(false)
    }
    const DrawPlanSold = () => {
        const plan = quarterChartData.map(dat => dat.plan)
        const suma1 = plan.reduce((acc, item) => acc + item, 0)
        plan.unshift(suma1)

        const sold = chartData.map(dat => dat.sold)
        const suma2 = sold.reduce((acc, item) => acc + item, 0)
        sold.unshift(suma2)

        return <table className='table'>
            <thead>
                <tr>
                    <td className="text-center"><strong>MONTH</strong></td>
                    <td className="text-center"><strong>PLAN</strong></td>
                    <td className="text-center"><strong>SOLD</strong></td>
                </tr>
            </thead>
            <tbody>
                {
                    quarterChartData.map((e, i) => {
                        return <tr>
                            <td style={{ textAlign: "center" }} key={i}>
                                {e.mes}
                            </td>
                            <td style={{ textAlign: "center" }} key={i}>
                                {toThousands(Math.ceil(e.plan))}
                            </td>
                            <td style={{ textAlign: "center" }} key={i}>
                                {toThousands(Math.ceil(e.sold))}
                            </td>
                        </tr>
                    })
                }
                <tr>
                    <td style={{ textAlign: "center" }}>
                        <strong>TOTAL</strong>
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {toThousands(Math.ceil(suma1))}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {toThousands(Math.ceil(suma2))}
                    </td>
                </tr>
            </tbody >
        </table>

    }
    const DrawPlan = () => {
        const plan = chartData.map(dat => dat.plan)
        const suma = plan.reduce((acc, item) => acc + item, 0)
        plan.unshift(suma)
        return plan.map((dato, i) => {
            return <td style={{ textAlign: "right" }} key={i}>
                {toThousands(Math.ceil(dato))}
            </td>
        })
    }

    const toggleModalWeekly = (i) => {
        setMonth(months[i])
        setShowSoldWeek(!showSoldWeek)
        if (!showSoldWeek) {
            setLoading(true)
            setDataForModal(SoldPerWk())
            setDataForModalSeller(SoldPerWkSellers())
            const currentWk = bestWeekYds()
            setBestYds(currentWk[0].yards)
            setBestWk(currentWk[0]._id)
            setLoading(false)
        }
    }

    const toggleModalYearWeekly = (i) => {
        setMonth(months[i])
        setShowSoldYear(!showSoldYear)
        if (!showSoldYear) {
            getCSVYearWeekly()
            setDataForModal(SoldPerWk())
            const currentWk = bestWeekYds()
            setBestYds(currentWk[0].yards)
            setBestWk(currentWk[0]._id)
        }
    }

    const toggleModalQuarter = () => {
        setShowSoldQuarter(!showSoldQuarter)
    }

    const getCSVYearWeekly = () => {
        const arr = []
        const weeksSorted = salesPerWeek.sort(function (a, b) { return a._id - b._id })
        weeksSorted.map((wk) => {
            let weekObj = {
                week: wk._id,
                yards: toThousands(wk.yards)
            }

            arr.push(weekObj)
        })

        setYardsRow(arr)
    }

    const DrawSold = () => {
        dataToExport(chartData)
        const sold = chartData.map(dat => dat.sold)
        const suma = sold.reduce((acc, item) => acc + item, 0)
        sold.unshift(suma)
        return sold.map((dato, i) => {
            return <td style={{ textAlign: "right" }} key={i}>
                {toThousands(Math.ceil(dato))}
            </td>
        })
    }

    const DrawPastSold = () => {
        const orden = pastYards.sort((a,b) => {return a.mes -b.mes})
        const dibuja = orden.map(dat => dat.yard)
        const suma = pastYards.reduce((acc, item) => acc + item.yard, 0)
        dibuja.unshift(suma)
        return dibuja.map((dato, i) => {
            return <td style={{ textAlign: "right" }} key={i}>
                {toThousands(Math.ceil(dato))}
            </td>
        })
    }

    const SoldPerWk = () => {
        const currentWk = salesPerWeek.filter(data => data._id === weekNum())
        return currentWk;
    }

    const SoldPerWkSellers = () => {
        let corpObj = {
            name: 'CORPORATIVO',
            yds: 0,
            target: 0
        }
        const result = salesPerSeller.reduce(function (r, a) {
            r[a.seller.name] = r[a.seller.name] || [];
            r[a.seller.name].push(a);
            return r;
        }, Object.create(null));

        const resultArr = Object.keys(result).map((key) => [(key), result[key]]);

        const sellerArr = resultArr.map((seller) => {
            let corporate = false
            const initialValue = 0;

            var sumY = seller[1].reduce(function (accumulator, curValue) {
                if (curValue.seller.corporateSales) {
                    corporate = true
                    return accumulator + curValue.loadYD3
                } else {
                    return accumulator + curValue.loadYD3
                }
            }, initialValue)

            var sumT = seller[1].reduce(function (accumulator, curValue) {
                if (curValue.jobStatus !== "Quote") {
                    return parseInt(accumulator) + parseInt(curValue.targetYD3)
                } else {
                    return parseInt(accumulator) + 0
                }
            }, initialValue)

            if (corporate) {
                corpObj.yds += sumY
                corpObj.target += sumT
                return corpObj
            } else {
                let sellerObj = {
                    name: seller[0],
                    yds: sumY,
                    target: sumT,
                }
                return sellerObj
            }
        })

        const filteredArr = sellerArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.name === value.name
            ))
        )

        const datosSeller = []
        filteredArr.map((dato) => {
            let sellerObj = {
                name: dato.name,
                yards: dato.yds,
                target: dato.target
            }

            datosSeller.push(sellerObj)
        })
        setSellerRow(datosSeller)

        let sumLoadsCommercial = 0
        let sumTargetCommercial = 0
        const datosSellerCommercial = []
        filteredArr.filter((value) => 
        value.name.toUpperCase() === 'JUAN HERNANDEZ' || 
        value.name.toUpperCase() === 'CARLOS MANUEL CASTAÑOS' ||
        value.name.toUpperCase() === 'FERMIN AVENDAÑO'
        ).map((dato, i) => {
            sumLoadsCommercial += dato.yds
            sumTargetCommercial += dato.target
            let sellerObj = {
                name: dato.name,
                yards: dato.yds,
                target: dato.target
            }
            datosSellerCommercial.push(sellerObj)
        })
        setTotalLoadsCommercial(sumLoadsCommercial)
        setTotalTargetCommercial(sumTargetCommercial)
        setSellerCommercialRow(datosSellerCommercial)

        let sumLoadsResidential = 0
        let sumTargetResidential = 0
        const datosSellerResidential = []
        filteredArr.filter((value) => 
        value.name.toUpperCase() === 'CORPORATIVO' || 
        value.name.toUpperCase() === 'JESUS CANTU' || 
        value.name.toUpperCase() === 'EDGAR BRIONES' || 
        value.name.toUpperCase() === 'CRISTIAN TORRES' || 
        value.name.toUpperCase() === 'FERNANDO RAMIREZ' ||
        value.name.toUpperCase() === 'EDUARDO SALGADO' ||
        value.name.toUpperCase() === 'DAVID SANTAMARIA' ||
        value.name.toUpperCase() === 'ERIK PECINA' ||
        value.name.toUpperCase() === 'OSCAR VALADEZ' ||
        value.name.toUpperCase() === 'HECTOR PERALTA' ||
        value.name.toUpperCase() === 'RAMON LUNA'
        ).map((dato, i) => {
            sumLoadsResidential += dato.yds
            sumTargetResidential += dato.target
            let sellerObj = {
                name: dato.name,
                yards: dato.yds,
                target: dato.target
            }
            datosSellerResidential.push(sellerObj)
        })
        setTotalLoadsResidential(sumLoadsResidential)
        setTotalTargetResidential(sumTargetResidential)
        setSellerResidentialRow(datosSellerResidential)

        return filteredArr;
    }

    const bestWeekYds = () => {
        const [...bestWk] = salesPerWeek.sort((a, b) => (a.yards > b.yards) ? -1 : 1).slice(0, 1)
        return bestWk;
    }

    const DrawSoldPerWk = () => {
        return dataForModal.map((dato, i) => {
            return <td style={{ textAlign: "center" }} key={i}>
                {toThousandsWkS(dato.yards)}
            </td>

        })
    }

    const DrawSellerPerWk = () => {
        return dataForModalSeller.map((dato, i) => {
            return <tr key={i}>
                <td>{dato.name.toUpperCase()}</td>
                <td style={{textAlign: 'right'}}>{toThousandsWkS(dato.yds)}</td>
                <td style={{textAlign: 'right'}}>{toThousandsWkS(dato.target)}</td>
            </tr>
        })
    }

    const DrawCommercialSellerPerWk = () => {
        return dataForModalSeller.filter((value) => 
            value.name.toUpperCase() === 'JUAN HERNANDEZ' || 
            value.name.toUpperCase() === 'CARLOS MANUEL CASTAÑOS' ||
            value.name.toUpperCase() === 'FERMIN AVENDAÑO'
            ).map((dato, i) => {
            return <tr key={i}>
                <td>{dato.name.toUpperCase()}</td>
                <td style={{textAlign: 'right'}}>{toThousandsWkS(dato.yds)}</td>
                <td style={{textAlign: 'right'}}>{toThousandsWkS(dato.target)}</td>
            </tr>
        })
    }

    const DrawResidentialSellerPerWk = () => {
        return dataForModalSeller.filter((value) => 
        value.name.toUpperCase() === 'CORPORATIVO' || 
        value.name.toUpperCase() === 'JESUS CANTU' || 
        value.name.toUpperCase() === 'EDGAR BRIONES' || 
        value.name.toUpperCase() === 'CRISTIAN TORRES' || 
        value.name.toUpperCase() === 'FERNANDO RAMIREZ' ||
        value.name.toUpperCase() === 'EDUARDO SALGADO' ||
        value.name.toUpperCase() === 'DAVID SANTAMARIA' ||
        value.name.toUpperCase() === 'ERIK PECINA' ||
        value.name.toUpperCase() === 'OSCAR VALADEZ' ||
        value.name.toUpperCase() === 'HECTOR PERALTA' ||
        value.name.toUpperCase() === 'RAMON LUNA'
        ).map((dato, i) => {
            return <tr key={i}>
                <td>{dato.name.toUpperCase()}</td>
                <td style={{textAlign: 'right'}}>{toThousandsWkS(dato.yds)}</td>
                <td style={{textAlign: 'right'}}>{toThousandsWkS(dato.target)}</td>
            </tr>
        })
    }

    const dataToExport = (data) => {
        const datosPlan = []
        const datosReal = []
        const datos = []

        let rowTitle = 'Plan'
        datosPlan.push(rowTitle)
        rowTitle = 'Real'
        datosReal.push(rowTitle)
        data.forEach(item => {
            const valPlan = item.plan
            const valReal = item.sold
            datosPlan.push(valPlan)
            datosReal.push(valReal)
        })

        const nuevo = data.map(item => {
            return {
                month: item.mes,
                plan: item.plan,
                sold: item.sold
            }
        })

        datos.push(datosPlan)
        datos.push(datosReal)
        setRows(data)
    }

    const dataToExportQuarter = (data) => {
        const datosPlan = []
        const datosReal = []
        const datos = []

        let rowTitle = 'Plan'
        datosPlan.push(rowTitle)
        rowTitle = 'Real'
        datosReal.push(rowTitle)
        data.forEach(item => {
            const valPlan = item.plan
            const valReal = item.sold
            datosPlan.push(valPlan)
            datosReal.push(valReal)
        })

        const nuevo = data.map(item => {
            return {
                month: item.mes,
                plan: item.plan,
                sold: item.sold
            }
        })

        datos.push(datosPlan)
        datos.push(datosReal)
        setRows2(data)
    }

    const yardsPerWeek = () => {
        const weeksSorted = salesPerWeek.sort(function (a, b) { return a._id - b._id })
        return weeksSorted.map((week) => {
            return <tr key={week._id}>
                <td><strong>{"Week " + week._id}</strong></td>
                <td style={{ textAlign: "right" }}>
                    {toThousands(week.yards)}
                </td>
            </tr>
        })
    }


    const YardYear = () => (
        <div className="form-group row">
            <div className='col-sm-5'>
                <ComposedChart width={400} height={300} data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="mes" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="plan" fill="#1e387d" />
                    <Line
                        type="monotone"
                        dataKey="sold"
                        stroke="#eb1a20"
                        strokeWidth={4}
                    />
                    <Line
                        type="monotone"
                        dataKey="past"
                        strokeWidth={3}
                        stroke="#807e7c"
                    />
                </ComposedChart>
            </div>
            <div className='col-sm-7' style={{ fontSize: '12px' }}>
                <table className='table'>
                    <thead>
                        <tr>
                            <td> </td>
                            <th>TOTAL</th>
                            <td className="text-center"><strong>JAN</strong></td>
                            <td className="text-center"><strong>FEB</strong></td>
                            <td className="text-center"><strong>MAR</strong></td>
                            <td className="text-center"><strong>APR</strong></td>
                            <td className="text-center"><strong>MAY</strong></td>
                            <td className="text-center"><strong>JUN</strong></td>
                            <td className="text-center"><strong>JUL</strong></td>
                            <td className="text-center"><strong>AUG</strong></td>
                            <td className="text-center"><strong>SEP</strong></td>
                            <td className="text-center"><strong>OCT</strong></td>
                            <td className="text-center"><strong>NOV</strong></td>
                            <td className="text-center"><strong>DEC</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Plan</td>
                            <DrawPlan />
                        </tr>
                        <tr>
                            <td>Sold</td>
                            <DrawSold />
                        </tr>
                        <tr>
                            <td>Last Year</td>
                            <DrawPastSold />
                        </tr>
                    </tbody>
                </table>
                <Modal show={showSoldWeek} animation={false} onHide={toggleModalWeekly}>
                    <Modal.Header closeButton>
                        <Modal.Title>Yards sold by week:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CSVLink data={sellerRow} headers={sellerColumn} filename={"57concrete-weekly_seller-report.csv"}>
                            Download report
                        </CSVLink>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td> </td>
                                    <td className="text-center"><strong>Yards of the week</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><strong>{"Week " + weekNum() + 1}</strong></td>
                                    <DrawSoldPerWk />
                                </tr>
                            </tbody>
                        </table>
                        {/* <table className='table'>
                            <thead>
                                <tr>
                                    <td><strong>Seller</strong></td>
                                    <td className="text-right" style={{ textAlign: "right" }}><strong>Loads</strong></td>
                                    <td className="text-right" style={{ textAlign: "right" }}><strong>Target</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                <DrawSellerPerWk />
                            </tbody>
                        </table> */}
                        <h4>Commercial Team</h4>
                        <CSVLink data={sellerCommercialRow} headers={sellerColumn} filename={"57concrete-weekly_seller_commercial-report.csv"}>
                            Download commercial report
                        </CSVLink>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td><strong>Seller</strong></td>
                                    <td className="text-right" style={{ textAlign: "right" }}><strong>Loads</strong></td>
                                    <td className="text-right" style={{ textAlign: "right" }}><strong>Target</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                <DrawCommercialSellerPerWk />
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>TOTAL</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{toThousandsWkS(totalLoadsCommercial)}</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{toThousandsWkS(totalTargetCommercial)}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <h4>Residential Team</h4>
                        <CSVLink data={sellerResidentialRow} headers={sellerColumn} filename={"57concrete-weekly_seller_residential-report.csv"}>
                            Download residential report
                        </CSVLink>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td><strong>Seller</strong></td>
                                    <td className="text-right" style={{ textAlign: "right" }}><strong>Loads</strong></td>
                                    <td className="text-right" style={{ textAlign: "right" }}><strong>Target</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                <DrawResidentialSellerPerWk />
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>TOTAL</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{toThousandsWkS(totalLoadsResidential)}</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{toThousandsWkS(totalTargetResidential)}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td> </td>
                                    <td className="text-center"><strong>Week</strong></td>
                                    <td className="text-center"><strong>Yds</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><strong> Best Sales Week</strong></td>
                                    <td style={{ textAlign: "center" }}>{bestWk}</td>
                                    <td style={{ textAlign: "center" }}>{toThousandsWkS(bestYds)}</td>

                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal >
                <Modal show={showSoldYear} animation={false} onHide={toggleModalYearWeekly}>
                    <Modal.Header closeButton>
                        <Modal.Title>Yards sold by week of the year:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CSVLink data={yardsRow} headers={yardsColumn} filename={"57concrete-weekly_yards-report.csv"}>
                            Download report
                        </CSVLink>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td> </td>
                                    <td className="text-end"><strong>Yards of the week</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {yardsPerWeek()}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
                <Modal size='lg' show={showSoldQuarter} animation={false} onHide={toggleModalQuarter}>
                    <Modal.Header closeButton>
                        <Modal.Title>Plan vs Real (yards) by quarters: </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DatePicker
                            selected={yearDate}
                            // minDate={new Date(yearDate.getFullYear(), 0, 1)}
                            onChange={(date) => {
                                setYearDate(date)
                            }}
                            dateFormat="yyyy"
                            showYearPicker
                        />
                        <div>
                            <p></p>
                        </div>
                        <Select
                            options={options}
                            onChange={(e) => setQuarters(e)}
                            isMulti
                            value={quarters}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            components={{
                                Option
                            }}
                            allowSelectAll={true}
                        />
                        <div>
                            <p></p>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={() => quarterGroupData()}>Set quarters</button>
                        <div>
                            <p></p>
                        </div>
                        {
                            loadingModal ?
                                <Spinner />
                                :
                                <div>
                                    <div className='form-group row justify-content-around align-content-center'>
                                        <ComposedChart width={400} height={300} data={quarterChartData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="mes" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="plan" fill="#1e387d" />
                                            <Line
                                                type="monotone"
                                                dataKey="sold"
                                                stroke="#eb1a20"
                                                strokeWidth={4}
                                            />
                                        </ComposedChart>
                                        <div className='col-sm-7' style={{ fontSize: '12px' }}>
                                            <DrawPlanSold />
                                        </div>
                                    </div>
                                    <CSVLink data={rows2} headers={column} filename={"57concrete-quarter-report.csv"}>
                                        Download report
                                    </CSVLink>
                                </div>

                        }
                    </Modal.Body>
                </Modal>
                <CSVLink data={rows} headers={column} filename={"57concrete-report.csv"}>
                    Download report
                </CSVLink>
                <br />
                <br />
                <div className='form-group row align-content-center'>
                    <div className='col-sm-2'>
                        <button type="button" className="btn btn-primary" onClick={() => toggleModalWeekly()}>Current Week</button>
                    </div>
                    <div className='col-sm-2'>
                        <button type="button" className="btn btn-success" onClick={() => toggleModalYearWeekly()}>Weekly</button>
                    </div>
                    <div className='col-sm-2'>
                        <button type="button" className="btn btn-secondary" onClick={() => toggleModalQuarter()}>Quarter year</button>
                    </div>
                    <div className='col-sm-2'>                    
                        <Link to='/heatmapsellers' className='btn btn-warning'>
                            Seller's Heatmap
                        </Link>
                    </div>
                </div>
            </div >

        </div >
    )

    return (
        <div>
            {
                loading ? <Spinner /> : <YardYear />
            }
        </div>
    )
}


export default YearForecast