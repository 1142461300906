import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'

const optionsUnit = [
    { value: 'oz', label: 'oz' },
    { value: 'lb', label: 'lb' },
    { value: 'ton', label: 'ton' },
    { value: 'gl', label: 'gl' },
]

const Cog = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [initialValues, setInitialValues] = useState({})
    const [optionsMeasurement, setOptionsMeasurement] = useState(optionsUnit.filter((o) => { return o.value === 'gl' || o.value === 'ton' || o.value === 'lb' }))
    const [optionsConversion, setOptionsConversion] = useState(optionsUnit)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getCog()
    }, [])

    const getCog = async (data) => {
        try {
            setLoading(true)
            let url = api + '/cog/' + props.match.params.id 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const {data} = await axios.get(url)
            setInitialValues({
                material: data.material,
                unitPrice: data.unitPrice,
                measurementUnit: data.unitOfMeasurement,
                unitPriceConversion: data.unitPriceConversion,
                conversionUnit: data.unitOfConversion,
            })
            setLoading(false)
        } catch (err) {
            setLoading(true)
            openNotification('error', 'Error', 'Error while trying to get cog')
            console.error(err.message)
            return null
        }
    }

    const updateCog = async (data) => {
        try {
            setLoading(true)

            const formData = {
                material: data.material,
                unitPrice: data.unitPrice,
                unitOfMeasurement: data.measurementUnit,
                unitPriceConversion: data.unitPriceConversion,
                unitOfConversion: data.conversionUnit,
                updatedBy: localStorage.getItem('userId'),
                history: {
                    unitPrice: data.unitPrice,
                    unitOfMeasurement: data.measurementUnit,
                    unitPriceConversion: data.unitPriceConversion,
                    unitOfConversion: data.conversionUnit,
                }
            }
        
            let url = api + '/cog/' + props.match.params.id  
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, formData)
            openNotification('success', 'Success', 'Cog updated')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/coglist')
            }, 1000);

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create cog')
            console.error(err.message)
            return null
        }
    }

    const sixPointDecimal = number => Number(number).toFixed(6);

    const onChangeUnit = (value) => {
        let price = form.getFieldValue('unitPrice') || 0
        if(value === 'ton'){
            setOptionsConversion(optionsUnit.filter((o) => { return o.value === 'lb' }))
            form.setFieldsValue({ conversionUnit: 'lb' })
            onChangeUnitPrice(price)
            setDisabled(true)
        }else if(value === 'lb'){
            setOptionsConversion(optionsUnit.filter((o) => { return o.value === 'lb' }))
            form.setFieldsValue({ conversionUnit: 'lb' })
            onChangeUnitPrice(price)
            setDisabled(true)
        }else if(value === 'gl'){
            setOptionsConversion(optionsUnit.filter((o) => { return o.value === 'gl' || o.value === 'oz' }))
            form.setFieldsValue({ conversionUnit: 'gl' })
            onChangeUnitPrice(price)
            setDisabled(false)
        }else if(value === 'oz'){
            setOptionsConversion(optionsUnit.filter((o) => { return o.value === 'gl' || o.value === 'oz' }))
            form.setFieldsValue({ conversionUnit: 'oz' })
            onChangeUnitPrice(price)
            setDisabled(false)
        }
    }

    const onChangeUnitConversion = (value) => {
        if(value === 'gl' || value === 'oz'){
            let price = form.getFieldValue('unitPrice') || 0
            onChangeUnitPrice(price)
        }
    }

    const onChangeUnitPrice = (e) => {
        let totalPrice = 0
        let measurement = form.getFieldValue('measurementUnit')
        let conversion = form.getFieldValue('conversionUnit')
        let price = e || 0
        if(measurement === 'ton' && conversion === 'lb'){
            totalPrice = parseFloat(price) / 2205
            form.setFieldsValue({ unitPriceConversion: sixPointDecimal(totalPrice) })
        }else if(measurement === 'lb' && conversion === 'lb'){
            totalPrice = parseFloat(price)
            form.setFieldsValue({ unitPriceConversion: sixPointDecimal(totalPrice) })
        }else if(measurement === 'gl' && conversion === 'oz'){
            totalPrice = parseFloat(price) / 128
            form.setFieldsValue({ unitPriceConversion: sixPointDecimal(totalPrice) })
        }
        else if(measurement === 'gl' && conversion === 'gl'){
            totalPrice = parseFloat(price)
            form.setFieldsValue({ unitPriceConversion: sixPointDecimal(totalPrice) })
        }else{
            form.setFieldsValue({ unitPriceConversion: 0 })
        }
    }

    const deleteCog = async () => {
        setLoading(true)

        const url = api + '/cog/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Cog Deleted')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/coglist')
            }, 1000);
            
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        updateCog(values)
    }

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Material Name'
                    name='material'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill material name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Measurement Unit'
                    name='measurementUnit'
                    rules={[
                        {
                            required: true,
                            message: 'Please select measurement unit'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsMeasurement}
                        placeholder="Please select unit"
                        onChange={onChangeUnit}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Conversion Unit'
                    name='conversionUnit'
                    rules={[
                        {
                            required: true,
                            message: 'Please select conversion unit'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsConversion}
                        placeholder="Please select unit"
                        disabled={disabled}
                        onChange={onChangeUnitConversion}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    name='unitPrice'
                    label='Unit Price'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill unit price'
                        }
                    ]}
                    >
                        <InputNumber 
                        size='large' 
                        style={{width: '100%'}} 
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        onChange={onChangeUnitPrice}
                        min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name='unitPriceConversion'
                    label='Unit Price Conversion'
                    >
                        <InputNumber 
                        size='large' 
                        style={{width: '100%'}} 
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        disabled  
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Col>
                { 
                    (
                        localStorage.getItem('email') === 'ec@57concrete.com' || 
                        localStorage.getItem('email') === 'eliud@gmail.com' || 
                        localStorage.getItem('email') === 'jp@57concrete.com' ||
                        localStorage.getItem('email') === 'vm@57concrete.com' ||
                        localStorage.getItem('email') === 'fc@57concrete.com'
                    )
                    &&
                    <Col>
                        <Popconfirm
                            title="Delete cog"
                            description="Are you sure to delete this cog?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteCog}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                        </Popconfirm>
                    </Col>
                }
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Edit Cog'
        description='Cog'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default Cog