import React, { useState, useEffect, useRef } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { customerWithInvoiceSearch } from '../../actions/client'
import Spinner from '../layout/Spinner'
import { Link } from 'react-router-dom'
import { toCurrency } from '../utils/Convert'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import PaymentJob from '../job/PaymentJob'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { api } from '../utils/Api'
import ClientWithInvoiceItem from './ClientWithInvoiceItem'

const ClientWithInvoice = ({customers, customerWithInvoiceSearch, loading }) => {
    const [term, setTerm] = useState('')
    const [date, setDate] = useState(new Date())
    const [showModal, setShowModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [taxes, setTaxes] = useState({})
    const jobId = useRef('')

    useEffect(() => {   
        getTax()
    }, [])

    useEffect(() => {   
        search()
    }, [update])

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const getTax = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const { data } = await axios.get(url)
            setTaxes({county: data.countyTax, state: data.stateTax, exempt: 0} )
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const calculateTotals = (invoice) => {
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = taxes['state']
                break
            case 'county':
                taxPercentaje = taxes['county']
                break
            case 'exempt':
                taxPercentaje = taxes['exempt']
                break
            default:
                taxPercentaje = taxes['state']
        }
        if(invoice.job){
            let yards = invoice.job.loadYD3 && invoice.job.loadYD3 > 0 ? invoice.job.loadYD3 : (typeof invoice.job.targetYD3 === "string" ? parseFloat(invoice.job.targetYD3) : invoice.job.targetYD3)
            const material = invoice.job.unitPrice * yards
            const trips = Math.ceil(yards/10)
            const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
            const delivery = invoice.shippingFee || invoice.job.deliveryFee      
            const color = invoice.color ? invoice.colorTicket : 0
            const fiber = invoice.fiber ? invoice.fiberBags : 0
            const size = invoice.size ? invoice.sizeBags : 0
            const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
            const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
            const taxas = subtotal * taxPercentaje
            const total = subtotal + taxas
            return total
        }
        return 0
    }

    const DrawListSearch = () => {
        if(customers){
            const groupByName = customers.reduce((group, invoice) => {
                const { name } = invoice.customer;
                group[name] = group[name] ?? [];
                group[name].push(invoice);
                return group;
            }, {});
            const arrayObjects = Object.values(groupByName)

            const sorted = arrayObjects.sort(function(a,b){
                var nameA = a[0].customer.name.toLowerCase(), nameB = b[0].customer.name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                return -1;
                if (nameA > nameB)
                return 1;
                return 0; //default return value (no sorting)
            })


            return arrayObjects.map((invoices, index) => {
                let totalInvoices = 0
                let totalYards = 0
                invoices.forEach((element) => {
                    totalInvoices += calculateTotals(element)
                    totalYards += element.quantity
                })
        
                return invoices[0].job && <div style={{ fontSize: '13px', paddingTop: 20 }} key={index}>
                    <div className='form-group row' style={{maxWidth: '100%'}}>
                        <div className='col-sm-4'><h5>{invoices[0].customer.customerNumber + ' ' + invoices[0].customer.name}  </h5></div>
                        <div className='col-sm-2'><h5>Invoices: {invoices.length} </h5></div>
                        <div className='col-sm-2'><h5>Yards: {totalYards} </h5></div>
                        <div className='col-sm-2'><h5>Total: {toCurrency(totalInvoices)} </h5></div>
                        <Link to={`/generalpayment/${invoices[0].customer._id}`} className='col-sm-2 btn btn-success'>General Payment</Link>
                    </div>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Invoice Number</th>
                                <th>Customer</th>
                                <th>Date</th>
                                <th>PSI</th>
                                <th>Yards</th>
                                <th style={{ textAlign: "right" }}>Subtotal</th>
                                <th style={{ textAlign: "right" }}>Tax</th>
                                <th style={{ textAlign: "right" }}>Total</th>
                                <th style={{ textAlign: "right" }}>Balance</th>
                                <th style={{ textAlign: "right" }}>Action</th>
                                <th>Payments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoices.map((data, key) => {
                                    return taxes && <ClientWithInvoiceItem invoice={data} taxes={taxes} pcb={pcb} key={key} />
                                })
                            }
                        </tbody>
                    </table> 
                </div>  
        }) 
        }else{
            return <div style={{paddingTop: 20, width: '100%', textAlign:'center'}}>No customers to display</div>
        }   
    }

    const pcb = (job) =>{
        jobId.current=job
        toggleModal()
    }

    const paymentsUpdated = () => {
        setShowModal(false)
        search()
        setUpdate(!update)
    }

    const search = () => {
        const year = date.getFullYear()
        const month = date.getMonth()+1
        customerWithInvoiceSearch(year, month, term)
    }

    const SearchCustomer = () => (
        <div className="form-group row">
            <div className="col-sm-3">
                <input
                    type='text'
                    name='term'
                    size='40'
                    className="form-control"
                    placeholder="Search..."
                    defaultValue={term}
                    onBlur={(e) => setTerm(e.target.value)}
                />
            </div>
            <div className="col-sm-2">
                <DatePicker
                    selected={date}
                    showMonthYearPicker
                    dateFormat= 'yyyy-MM'
                    onChange={(date) => setDate(date)}
                    minDate={new Date('2024-01-01')}
                    maxDate={new Date()}
                /> 
            </div>
            <div className="col-sm-2">
                <button onClick={search} className='btn btn-primary w-100'>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                </button>
            </div>
        </div>
    )

    return (
        <Layout
            title='Customer'
            description='Customers with invoices'
            className='container col-md-10'
        >
            <SearchCustomer/>
            {
                loading ? <Spinner /> : <DrawListSearch/>
            }
            <Modal
                show={showModal}
                animation={false}
                onHide={toggleModal}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Job Payments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PaymentJob jobId={jobId.current} pcb={paymentsUpdated}/>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

const mapStateToProps = state => ({
    customers: state.client.clients,
    loading: state.client.loading
})

export default connect(mapStateToProps, {customerWithInvoiceSearch})(ClientWithInvoice)