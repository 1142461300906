import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import axios from 'axios'
import { api } from '../utils/Api'
import ProspectionItem from '../prospection/ProspectionItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import ProposalItem from './ProposalItem'

const OpportunityStatusType = (props) => {
    const option = props.match.params.id
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [prospections, setProspections] = useState([])
    const [loading, setLoading] = useState(true)
    const [term, setTerm] = useState('')

    useEffect(() => {
        selectDescription()
        getProspections()
    }, [])

    const getProspections = async () => {
        let body = {
            term: term
        }
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
        switch (option) {
            case 'contact':
                body['status'] = 'Contact'
                break;
            case 'appointment':
                body['status'] = 'Appointment'
                body['startDate'] = fechaIni
                body['endDate'] = fechaFin
                break;
            case 'proposal':
                body['status'] = 'Proposal'
                body['startDate'] = fechaIni
                body['endDate'] = fechaFin
                break;
            case 'deniedproposals':
                body['status'] = 'Denied'
                body['startDate'] = fechaIni
                body['endDate'] = fechaFin
                break;
            case 'acceptedproposals':
                body['status'] = 'Accepted'
                body['startDate'] = fechaIni
                body['endDate'] = fechaFin
                break;
            default:
                break;
        }
        
        if(option === 'contact' || option === 'appointment'){
            const url = api + `/prospectionstatus`
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            try {
                setLoading(true)
                const { data } = await axios.post(url, body)
                setProspections(data)
                setLoading(false)
            } catch (err) {
                console.error(err.message)
                return null
            }
        }
        else if(option === 'proposal' || option === 'acceptedproposals' || option === 'deniedproposals'){
            const url = api + `/proposalstatus`
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            try {
                setLoading(true)
                const { data } = await axios.post(url, body)
                setProspections(data)
                setLoading(false)
            } catch (err) {
                console.error(err.message)
                return null
            }
        }
    }

    const selectDescription = () => {
        switch (option) {
            case 'contact':
                setDescription('Contact Prospections')
                break;
            case 'appointment':
                setDescription('Appointment Prospections')
                break;
            case 'proposal':
                setDescription('Proposals')
                break;
            case 'deniedproposals':
                setDescription('Denied Proposals')
                break;
            case 'acceptedproposals':
                setDescription('Accepted Proposals')
                break;
            default:
                setDescription('')
                break;
        }
    }

    const DatesRenderer = () => (
        <div className="form-group row">
            <div className='col-sm-2'>
                <label className='text-muted'>Start Date</label>
                <DatePicker
                    selected={startDate}
                    minDate={new Date('01/01/2022')}
                    maxDate={new Date()}
                    onChange={(date) => setStartDate(date)}
                />
            </div>
            <div className='col-sm-2'>
                <label className='text-muted'>End Date</label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                />
            </div>
        </div>
    )

    const search = () => {
        getProspections()
    }

    const SearchClient = () => (
        <table >
            <tr>
                <td>
                    <input
                        type='text'
                        name='term'
                        size='20'
                        className="form-control"
                        placeholder="Search Prospection..."
                        defaultValue={term}
                        onBlur={(e) => setTerm(e.target.value)}
                    />
                </td>
                <td>
                    <button onClick={search} className='btn btn-primary'>
                        <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                    </button>
                </td>
            </tr>
        </table>
    )

    const DrawList = () => {
        if (prospections && prospections.length !== 0) {
            return prospections.map((client, i) => {
                return <ProspectionItem prospection={client} key={i}/>
            })
        } else {
            return <tr>
                <td colSpan={10} className={'text-center'}>No data for {description}</td>
            </tr>
        }
    }

    const ClientTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th className='sticky-headers'>Prospection</th>
                        <th className='sticky-headers'>Address</th>
                        <th className='sticky-headers'>Phone</th>
                        <th className='sticky-headers'>Seller</th>
                        <th className='sticky-headers'>Type</th>
                        <th className='sticky-headers'>Payment Type</th>
                        <th className='sticky-headers'>Quadrant Type</th>
                        <th className='sticky-headers'>Prospection Creation</th>
                        <th className='sticky-headers'>Customer Creation</th>
                        <th className='sticky-headers'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
    )

    const ProposalTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                            <th style={{ width: '10%' }}>Prospection</th>
                            <th>Proposal</th>
                            <th>Time</th>
                            <th>Prospection Address</th>
                            <th>Target YD3</th>
                            <th>Dest. time</th>
                            <th style={{ width: '4%' }}>Mix Design</th>
                            <th>Slump</th>
                            <th>Pour/Type</th>
                            <th>Status</th>
                            <th>Seller</th>
                            <th>Proposal Creation</th>
                            <th>Job Creation</th>
                            <th style={{ width: '6%' }}>Comments</th>
                            <th>Action</th>
                        </tr>
                </thead>
                <tbody>
                    <DrawListProposal />
                </tbody>
            </table>
        </div>
    )

    const DrawListProposal = () => {
        if (prospections && prospections.length !== 0) {
            return prospections.map((proposal, i) => {
                return <ProposalItem proposal={proposal} key={i}/>
            })
        } else {
            return <tr>
                <td colSpan={15} className={'text-center'}>No data for {description}</td>
            </tr>
        }
    }

    return (
        <Layout
            title='Prospections'
            description={description}
        >
            { option !== 'contact' && <DatesRenderer /> }
            &nbsp;
            <SearchClient />
            {loading ? <Spinner /> : (option === 'contact' || option === 'appointment' ? <ClientTable /> : <ProposalTable />)}
        </Layout>
    )
}

export default OpportunityStatusType