import React, { Component, Fragment } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import Layout from '../layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { api } from '../utils/Api'

const Quote = props => (
    // console.log(props)
    <tr>
        <td>
            <Link to={'/job/' + props.job._id}>
                {props.job.customer ? props.job.customer.customerNumber + ' ' + props.job.customer.name : "No Customer"}
            </Link>
        </td>
        <td>{props.job.number} </td>
        <td>
            {
                `${props.job.shipAddress} ${props.job.shipCity} ${props.job.shipState} ${props.job.shipZip}`
            }
        </td>
        <td>{props.job.jobStatus}</td>
        <td>
            {
                props.job.paid && <FontAwesomeIcon icon={faCheckSquare} />
            }
        </td>
        <td>
            {new Date(props.job.scheduledDate).toLocaleDateString() + ' ' + new Date(props.job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}
        </td>
    </tr>
)


export default class ListaCliente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            loading: true
        }
    }

    getQuotes = async () => {
        const url = api + '/jobquote/2022-09-01'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            console.log(data)
            this.setState({
                loading: false,
                jobs: data
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }

    componentDidMount() {
        this.getQuotes()
    }

    quoteNew = () => {
        this.props.history.push('/quotenew')
    }

    renderLoading() {
        return <Spinner />
    }

    losClientes() {
        return this.state.jobs.map((currentJob, i) => {
            return <Quote job={currentJob} key={i} />
        })
    }

    renderClients() {
        return (
            <Fragment>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Job ID</th>
                            <th>Address</th>
                            <th>Job Status</th>
                            <th>Paid</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.losClientes()}
                    </tbody>
                </table>
                <button className='btn btn-success' onClick={this.quoteNew}>
                    New Quote
                </button>
            </Fragment>
        )
    }

    render() {
        return (
            <Layout
                title='In Call'
                description='List of In Calls'
            >
                {
                    this.state.loading ?
                        this.renderLoading()
                        : this.renderClients()
                }
            </Layout>
        )
    }
}
