import React, { useState } from 'react'
import axios from 'axios'
import { api } from '../utils/Api'
import PhoneInput from 'react-phone-number-input'

const SendLogisticsTrip = ({logisticsId, pcallback, isEmail}) => {
    const [destination, setDestination] = useState('')
    const [phone, setPhone] = useState('')

    const send = async (e) => {
        e.preventDefault()
        if(destination !== '' || phone !== ''){
            const payload = {
                destination: isEmail ? destination : phone
            }
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            if(isEmail){
                const url = api + `/logisticstrip/email/${logisticsId}`
                try {
                    await axios.post(url, payload)  
                    pcallback()          
                } catch (err) {
                    console.error(err.message)
                    return null
                }
            }else{
                const url = api + `/logisticstrip/sms/${logisticsId}`
                try {
                    await axios.post(url, payload)  
                    pcallback()          
                } catch (err) {
                    console.error(err.message)
                    return null
                }
            }
        } 
    }

    const changeDestination = e => {
        setDestination(e.target.value)
    }

    const onChangePhone = e => {
        setPhone(e)
    }

    return (
        <div>
            <form onSubmit={send}>
                {
                isEmail ?
                <input 
                    type='email'
                    required
                    className='form-control'
                    name='destination'
                    value={destination}
                    onChange={changeDestination}
                />
                :
                <PhoneInput
                    className="form-control"
                    defaultCountry="US"
                    country="US"
                    name='phone'
                    placeholder="Phone number"
                    value={phone}
                    onChange={onChangePhone}
                    required
                />
                }
                <button 
                    type='submit'
                    className='btn btn-primary'
                >
                    Send
                </button>
            </form>
        </div>                        
    )
}

export default SendLogisticsTrip