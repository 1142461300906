import React, { Component } from 'react';
import Layout from '../../layout/Layout';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { api } from '../../utils/Api';
import ExpensesRender from './ExpensesRender';

export default class EmployeeExpenses extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            expenses: [],
            showModalExpenses: false,
            expenseIndex: 0,
            editExpense: false,
            currentExpense: {},
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async (e) => {
        const body = {
            expenses: this.state.expenses
        }

        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, body)
            this.props.history.goBack()
        } catch (err) {
            console.log(err)
            console.error(err.message)
            return null
        }
    }

    navigatePdf = () => {
        this.props.history.push(`/expensespdf/${this.props.match.params.id}`)
    }

    getEmployeeData = async () => {
        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let currentExpenses = []

            data.expenses.map((expense) =>{
                currentExpenses.push({
                'yards': expense.yards,
                'vendor': expense.vendor,
                'concept': expense.concept,
                'amount': expense.amount,
                'customer': expense.customer._id,
                'customerName': expense.customer.name,
                'date': expense.date,
                })
            })

            this.setState({
                expenses: currentExpenses,
                name: data.name
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    toggleModalExpenses = () => {
        this.setState({
            showModalExpenses: !this.state.showModalExpenses,
            editExpense: false
        });
    }

    setExpenses = (newExpenses) => {
        this.setState({
            expenses: newExpenses
        });
    }

    removeCurrentExpense = (index) => {
        this.setState({
            expenses: this.state.expenses.filter((v, i) => i !== index)
        })
    }

    ExpensesTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalExpenses}>Add Expense</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Yards</th>
                        <th>Vendor</th>
                        <th>Concept</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.expenses && this.state.expenses.length !== 0 ? 
                        this.state.expenses.map((expense, index) => {
                            return <tr key={index}>
                                <td>{new Date(expense.date).toLocaleDateString()}</td>
                                <td>{expense.customerName}</td>
                                <td>{expense.yards}</td>
                                <td>{expense.vendor}</td>
                                <td>{expense.concept}</td>
                                <td>{expense.amount}</td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            expenseIndex: index,
                                            currentExpense: expense,
                                            editExpense: true,
                                            showModalExpenses: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentExpense(index)}>Delete</button>
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={6} className='text-center'>No expenses registered</td>
                        </tr>
                    }
                </tbody>
            </table>
            <button type='button' className='btn btn-success' onClick={this.onSubmit}>Save</button>
            &nbsp;
            <button type='button' className='btn btn-secondary' onClick={this.navigatePdf}>Expenses PDF</button>
        </div>
    }


    componentDidMount(){
        this.getEmployeeData()
    }


    render() {
        return <Layout
            title={`Employee: ${this.state.name}`}
            description='Employee Expenses'
            className='container col-md-8 offset-md-2'
        >
            {this.ExpensesTable()}
            <Modal
                show={this.state.showModalExpenses}
                animation={false}
                onHide={this.toggleModalExpenses}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Expenses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExpensesRender currentExpense={this.state.currentExpense} expenses={this.state.expenses} setExpenses={this.setExpenses} toggleModal={this.toggleModalExpenses} edit={this.state.editExpense} index={this.state.expenseIndex} />
                </Modal.Body>
            </Modal>
        </Layout>
    }
}