import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../utils/Api'

export default class DriverPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: true,
            isSearchable: true,
            drivers: []
        }
    }

    getDrivers = async () => {
        const url = api + '/driver/list'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            const filteredData = data.filter((item) => item.active === true)
            this.setState({
                drivers: filteredData
            })
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getDrivers()
    }

    render() {
        const options = this.state.drivers.map((driver) => {
            return {
                value: driver._id,
                label: driver.name.toUpperCase()
            }
        })

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Driver...'
                    value={this.state.value}
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    }
}


