import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import SlumpPicker from '../slump/SlumpPicker'
import JobTypePicker from '../job/JobTypePicker'
import JobPoorPicker from '../job/JobPoorPicker'
import { toCurrency } from '../utils/Convert'
import PsiPicker from '../psi/PsiPicker'
import 'react-datepicker/dist/react-datepicker.css'
import AddressGeolocation from '../map/AddressGeolocation'
import Modal from 'react-bootstrap/Modal'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { api } from '../utils/Api'
import ForemanPicker from '../foreman/ForemanPicker'
import CommentsModal from './CommentsModal'

export default class Proposal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prospectionName: '',
            prospectionNumber: '',
            prospection: '',
            prospectionPhone: '',
            sellerName: '',
            name: '',
            loadYD3: '',
            targetYD3: '',
            proposalDescription: '',
            proposalStatus: '',
            shipAddress: '',
            shipCity: '',
            shipState: 'TX',
            shipZip: '',
            proposalForeman: '',
            foremanPhone: '',
            apContact: '',
            apEmail: '',
            apPhone: '',
            scheduledDate: '',
            municipalProposal: false,
            dateChanged: false,
            scheduledTime: '',
            destinationMinutes: '',
            taxExemptProposal: false,
            contractor: '',
            contractorContact: '',
            contractorPhone: '',
            contractorAddress: '',
            contractorCity: '',
            contractorState: '',
            contractorZip: '',
            contractorType: '',
            comments: [],
            driver: '',
            po: '',
            proposalDuration: '',
            unitPrice: '',
            taxPrice: 0,
            slump: '',
            proposalPoor: '',
            proposalType: '',
            number: 0,
            date: new Date(),
            paid: false,
            isPhoneQuote: false,
            seller: '',
            psi: '',
            destinationTime: '',
            distance: '',
            latitude: '',
            longitude: '',
            searchAddress: false,
            paymentType: '',
            approved: false,
            inspectionTimeStamp: new Date(),
            hotShot: false,
            supervisorPhone: '',
            pmPhone: '',
            supervisorName: '',
            projectManager: '',
            foreman: '',
            prospectionModal: false,
            fuelSurcharge: 25,
            showLoad: false,
            loads: [],
            shippingFee: 300,
            fuelSurchargeGlobal: false,
            shippingGlobal: true,
            showComments: false,
            createdAt: '',
            prospectionStatus: '',
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === 'unitPrice') {
            this.setState({
                taxPrice: e.target.value * 1.0825
            })
        }

        if (e.target.name === 'shipAddress') {
            this.setState({
                latitude: null,
                longitude: null,
                destinationTime: null,
                distance: 0
            })
        }
    }

    unitPriceInput = e => {
        let value = e.target.value
        if (value >= 0 && value <= 2000) {
            this.setState({ [e.target.name]: value })
            this.setState({
                taxPrice: value * 1.0825
            })
        }
    }

    onChangeDate = date => {
        this.setState({
            date,
            dateChanged: true,
            scheduledDate: date,
        })
    }
    onChangeShowLoad = boolean => {
        this.setState({
            showLoad: boolean
        })
    }
    onChangeSlump = selectedSlump => {
        if (selectedSlump) {
            this.setState({
                slump: selectedSlump.value
            })
        }
    }

    // proposalPoor option
    onChangeProposalPoor = selectedPoor => {
        if (selectedPoor) {
            this.setState({
                proposalPoor: selectedPoor.label
            })
        }
    }

    // change PSI
    onChangePsi = selectedPsi => {
        if (selectedPsi) {
            this.setState({
                psi: selectedPsi.label,
                unitPrice: selectedPsi.value,
                taxPrice: selectedPsi.value * 1.0825
            })
        }
    }

    // payment type
    onChangePaymentType = selectedPayment => {
        if (selectedPayment) {
            this.setState({
                paymentType: selectedPayment.label
            })
        }
    }

    // proposal type option
    onChangeProposalType = selectedType => {
        if (selectedType) {
            this.setState({
                proposalType: selectedType.label
            })
        }
    }

    // prospection select
    onChangeProspection = selectedProspection => {
        if (selectedProspection) {

            const values = selectedProspection.value.split(',')
            const number = selectedProspection.label.split(' ', 1)
            let prospectionName = selectedProspection.label
            prospectionName = prospectionName.replace(`${number} `, '')

            this.setState({
                prospection: values[0],
                paymentType: values[1],
                prospectionName: prospectionName,
                prospectionNumber: number[0],
                cliente: number[0] + ' ' + prospectionName,
            })
        }
    }

    // change proposal status
    onChangeProposalStatus = selectedOption => {
        if (selectedOption) {
            this.setState({
                proposalStatus: selectedOption.label,
                approved: true
            })
        }
    }

    // new seller asignation
    onChangeSeller = selectedOption => {
        if (selectedOption) {
            this.setState({
                seller: selectedOption.value
            })
        }
    }

    // change paid
    onPaidChange = e => {
        this.setState({
            paid: !this.state.paid
        })
    }

    // show/hide search modal
    onShowModal = e => {
        this.setState({
            searchAddress: !this.state.searchAddress
        })
    }

    // foreman phone
    onChangeForemanPhone = e => {
        this.setState({
            foremanPhone: e
        })
    }

    // Foreman picker on change
    onChangeForeman = selectedForeman => {
        if (selectedForeman) {
            let option = selectedForeman.value.split(',')
            let foremanID = option[0]
            let foremanName = option[1]
            let foremanPhone = option[2]
            this.setState({
                foremanPhone: foremanPhone,
                proposalForeman: foremanName,
                foreman: foremanID,
            })
        }
    }

    //Show/hide client modal
    onShowNewClient = (e) => {
        this.setState({
            prospectionModal: !this.state.prospectionModal
        })
    }

    onNewClientNew = (nuevo) => {
        this.setState({
            //Cambios a datos para mostrar
            prospectionModal: false,
            prospectionName: nuevo.name,
            prospectionNumber: nuevo.prospectionNumber,
            prospection: nuevo._id
        })
    }

    onChangeSupervisorPhone = e => {
        this.setState({
            supervisorPhone: e
        })
    }

    onChangePMPhone = e => {
        this.setState({
            pmPhone: e
        })
    }

    onChangeInspectionDate = date => {
        this.setState({
            inspectionTimeStamp: date
        })
    }

    // address from modal
    handleCallback = (childData) => {
        this.setState({
            shipAddress: childData.address,
            destinationTime: childData.duration,
            distance: childData.distance,
            latitude: childData.destLat,
            longitude: childData.destLng,
            searchAddress: false
        })
    }

    updateProposal = async (fromButton) => {
        const proposal = {
            name: this.state.name,
            proposalDescription: this.state.proposalDescription,
            shipAddress: this.state.shipAddress,
            proposalForeman: this.state.proposalForeman,
            foreman: this.state.foreman,
            foremanPhone: this.state.foremanPhone,
            apContact: this.state.apContact,
            apPhone: this.state.apPhone,
            contractor: this.state.contractor,
            contractorContact: this.state.contractorContact,
            contractorAddress: this.state.contractorAddress,
            contractorCity: this.state.contractorCity,
            contractorPhone: this.state.contractorPhone,
            contractorState: this.state.contractorState,
            contractorType: this.state.contractorType,
            contractorZip: this.state.contractorZip,
            loadYD3: this.state.loadYD3,
            targetYD3: this.state.targetYD3,
            proposalStatus: this.state.proposalStatus,
            scheduledDate: this.state.scheduledDate,
            slump: this.state.slump,
            proposalType: this.state.proposalType,
            proposalPoor: this.state.proposalPoor,
            unitPrice: this.state.unitPrice,
            paid: this.state.paid,
            seller: this.state.seller,
            psi: this.state.psi,
            destinationTime: this.state.destinationTime,
            distance: this.state.distance,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            paymentType: this.state.paymentType,
            approved: this.state.approved,
            updatedBy: localStorage.getItem('userId'),
            supervisorPhone: this.state.supervisorPhone,
            pmPhone: this.state.pmPhone,
            supervisorName: this.state.supervisorName,
            projectManager: this.state.projectManager,
            fuelSurcharge: this.state.fuelSurcharge,
            deliveryFee: this.state.shippingFee,
            fuelSurchargeGlobal: this.state.fuelSurchargeGlobal,
            shippingGlobal: this.state.shippingGlobal,
            comments: this.state.comments
        }

        if (this.state.prospection) {
            proposal.prospection = this.state.prospection
        }

        if(fromButton === 'accept'){
            proposal.jobCreation = new Date()
            proposal.proposalStatus = 'Accepted'
        }
        if(fromButton === 'deny'){
            proposal.proposalStatus = 'Denied'
        }

        const url = api + '/proposal/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, proposal)
            this.props.history.push('/opportunitystatus')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // change field values
    updateProspection = async (form) => {
        const url = api + '/prospection/' + this.state.prospection
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, form)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    validatePsi = () => {
        if (this.state.psi) {
            return true
        } else {
            alert('PSI must be selected')
            return false
        }
    }

    validateSeller = () => {
        if (this.state.seller) {
            return true
        } else {
            alert('seller must be selected')
            return false
        }
    }

    validateAttributeDefinition = (attribute, msg) => {
        if (attribute) {
            return true
        } else {
            alert(msg)
            return false
        }
    }

    onSubmit(e) {
        e.preventDefault()
        if (this.validateAttributeDefinition(this.state.seller, 'Seller must be selected') &&
            this.validateAttributeDefinition(this.state.psi, 'PSI must be selected') &&
            this.validateAttributeDefinition(this.state.shipAddress, 'You need to define ship address')) {
            this.updateProposal('save')
        }
    }

    getProposal = async () => {
        const url = api + '/proposal/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                prospectionName: data.prospection.name,
                prospectionNumber: data.prospection.prospectionNumber,
                sellerName: data.seller ? data.seller.name : 'Unasigned seller',
                seller: data.seller ? data.seller._id : '',
                name: data.name,
                scheduledDate: data.scheduledDate,
                scheduledTime: data.scheduledTime,
                loadYD3: data.loadYD3,
                targetYD3: typeof data.targetYD3 === "string" ? parseFloat(data.targetYD3) : data.target,
                proposalDescription: data.proposalDescription,
                proposalStatus: data.proposalStatus,
                prospection: data.prospection._id,
                shipAddress: data.shipAddress,
                proposalForeman: data.proposalForeman,
                foreman: data.foreman,
                foremanPhone: data.foremanPhone,
                apContact: data.apContact,
                apEmail: data.apEmail,
                apPhone: data.apPhone,
                destinationMinutes: data.destinationMinutes,
                contractor: data.contractor,
                contractorContact: data.contractorContact,
                contractorPhone: data.contractorPhone,
                contractorAddress: data.contractorAddress,
                contractorCity: data.contractorCity,
                contractorState: data.contractorState,
                contractorZip: data.contractorZip,
                contractorType: data.contractorType,
                taxExemptProposal: data.taxExemptProposal,
                comments: data.comments,
                driver: data.driver,
                po: data.po,
                proposalDuration: data.proposalDuration,
                unitPrice: data.unitPrice,
                taxPrice: data.unitPrice * 1.0825,
                slump: data.slump,
                proposalType: data.proposalType,
                proposalPoor: data.proposalPoor,
                number: data.number,
                isPhoneQuote: data.isPhoneQuote,
                paid: data.paid,
                cliente: data.prospection.prospectionNumber + ' ' + data.prospection.name,
                psi: data.psi,
                destinationTime: data.destinationTime,
                distance: data.distance,
                latitude: data.latitude,
                longitude: data.longitude,
                paymentType: data.paymentType,
                approved: data.approved,
                hotShot: data.hotShot,
                supervisorPhone: data.supervisorPhone,
                pmPhone: data.pmPhone,
                supervisorName: data.supervisorName,
                projectManager: data.projectManager,
                fuelSurcharge: data.fuelSurcharge,
                shippingFee: data.deliveryFee,
                fuelSurchargeGlobal: data.fuelSurchargeGlobal,
                shippingGlobal: data.shippingGlobal,
                createdAt: data.createdAt,
                prospectionStatus: data.prospection.prospectionStatus,
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }

    deleteProposal = async () => {
        const url = api + '/proposal/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.delete(url)
            this.props.history.push('/opportunitystatus')
        } catch (err) {
            console.error(err)
            return null
        }
    }

    setProposalComments = (newComments) => {
        this.setState({
            comments: newComments
        })
    }

    showCommentsModal = () => {
        this.setState({
            showComments: !this.state.showComments
        })
    }

    createNewJob = (type) => {
        if(type === 'accept'){
            this.getProspection(this.state.prospection)
        }else if (type === 'deny') {
            this.updateProposal('deny')
            const prospection = {
                prospectionStatus: 'Denied'
            }
            this.updateProspection(prospection)
        }
    }

    getProspection = async (id) => {
        const url = api + '/prospection/' + id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.createNewCustomer(data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    createNewCustomer = async (prospectionData) => {
        let correo = ''
        if (prospectionData.email) {
            correo = prospectionData.email
        } else {
            correo = prospectionData.contactPhone + '@gmail.com'
        }

        const user = {
            name: prospectionData.name,
            email: correo,
            phone: prospectionData.contactPhone,
            password: prospectionData.password,
            role: 'Customer'
        }

        try {
            let url = api + '/users'
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const { data } = await axios.post(url, user)
            const contact = data.user._id
            const company = {
                ...prospectionData,
                'customerType': prospectionData.prospectionType,
                'customerNumber': prospectionData.prospectionNumber,
                'contact': contact
            }

            url = api + '/customer'

            const respuesta = await axios.post(url, company)
            this.createJob(respuesta.data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    // POST
    createJob = async (customer) => {
        const user = localStorage.getItem('userId')
        const job = {
            ...this.state,
            'firstShipmentDate': this.state.date,
            'scheduledTime': this.state.time,
            'taxExemptJob': this.state.taxExemptProposal,
            'createdBy': user,
            'updatedBy': user,
            'customer': customer._id,
            'jobDescription': this.state.proposalDescription,
            'jobForeman': this.state.proposalForeman,
            'jobDuration': this.state.proposalDuration,
            'jobPoor': this.state.proposalPoor,
            'jobType': this.state.proposalType,
            'proposalCreation':this.state.createdAt,
            'comments': ''
        }

        try {
            const url = api + '/job'
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const {data} = await axios.post(url, job)
            this.updateProposal('accept')
            const prospection = {
                customerCreation: new Date(),
                prospectionStatus: 'Accepted'
            }
            this.updateProspection(prospection)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    ProposalForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <label className='text-muted'>Proposal ID</label>
            <div className='form-group'>
                <input
                    type='text'
                    className='form-control'
                    readOnly
                    value={this.state.number}
                />
            </div>
            <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Prospection</label>
                        <input
                            type='text'
                            readOnly
                            value={this.state.cliente}
                            className='form-control'
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='text-muted'>Seller</label>
                        <input
                            type='text'
                            name='seller'
                            className='form-control'
                            readOnly
                            defaultValue={this.state.sellerName}
                        />
                    </div>
                </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Current Prospection Status </label>
                    <input
                        type='text'
                        name='status'
                        className='form-control'
                        readOnly
                        defaultValue={this.state.proposalStatus}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Proposal name</label>
                    <input
                        type='text'
                        name='name'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.name ? this.state.name : ''}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Proposal Description</label>
                    <input
                        type='text'
                        name='proposalDescription'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.proposalDescription ? this.state.proposalDescription : ''}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Proposal Type: {this.state.proposalType} </label>
                    <JobTypePicker action={this.onChangeProposalType} placeholder={'Proposal Type...'}/>
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Proposal Pour: {this.state.proposalPoor} </label>
                    <JobPoorPicker action={this.onChangeProposalPoor} placeholder={'Proposal Pour...'}/>
                </div>
            </div>
            <div className="form-group">
                <label className='text-muted'>PSI: {this.state.psi} </label>
                <PsiPicker action={this.onChangePsi} />
            </div>
            <div className="form-group row">
                <label className='text-muted'>Ship Address</label>
                <div className='col-sm-12'>
                    <input
                        type="text"
                        name='shipAddress'
                        className="form-control"
                        onChange={this.inputChangeHandler}
                        value={this.state.shipAddress}
                        required
                    />
                </div>
            </div>
            <div>
                {
                    this.state.searchAddress &&
                    <Modal show={this.state.searchAddress} animation={false} onHide={this.onShowModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressGeolocation
                                search={true}
                                parentCallback={this.handleCallback}
                                single={false}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
            <div className="form-group row">
                <div className="col-sm-6">
                    <label className="text-muted">Foreman</label>
                    <ForemanPicker action={this.onChangeForeman} selectedOption={this.state.proposalForeman} />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor</label>
                    <input
                        type='text'
                        name='supervisorName'
                        className='form-control'
                        placeholder='Supervisor name'
                        value={this.state.supervisorName}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='supervisorPhone'
                        placeholder="Phone number"
                        value={this.state.supervisorPhone}
                        onChange={this.onChangeSupervisorPhone}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Project Manager</label>
                    <input
                        type='text'
                        name='projectManager'
                        className='form-control'
                        placeholder='PM Name'
                        value={this.state.projectManager}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Project Manager Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='pmPhone'
                        placeholder="PM Phone"
                        value={this.state.pmPhone}
                        onChange={this.onChangePMPhone}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>AP Contact</label>
                    <input
                        type='text'
                        name='apContact'
                        className='form-control'
                        placeholder='Name'
                        value={this.state.apContact}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'> </label>
                    <input
                        type='text'
                        name='apPhone'
                        className='form-control'
                        placeholder='Email'
                        value={this.state.apPhone}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>

            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Tax Exempt Proposal</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='taxExemptProposal'
                                value='Yes'
                                onChange={this.inputChangeHandler}
                            />
                            Yes
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='taxExemptProposal'
                                value='No'
                                checked
                                onChange={this.inputChangeHandler}
                            />
                        </label>
                        No
                    </div>
                </div>
                {
                    localStorage.getItem('role') === 'Finance' &&
                    <div className='col-sm-6'>
                        <label className='text-muted'>
                            Payment type: {this.state.paymentType}
                        </label>
                        <PaymentTypePicker action={this.onChangePaymentType} />
                    </div>
                }
            </div>
            <hr />
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Target YD3</label>
                    <input
                        type="number"
                        name='targetYD3'
                        className='form-control'
                        required
                        onChange={this.inputChangeHandler}
                        value={this.state.targetYD3}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Proposal Duration</label>
                    <input
                        type="text"
                        name='proposalDuration'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <div>
                        <label className="text-muted">
                            Current Proposal Date: {new Date(this.state.scheduledDate).toLocaleDateString()}
                            &nbsp;
                            - Time: {new Date(this.state.scheduledDate).toLocaleTimeString()}
                        </label>
                    </div>
                    <div>
                        New date:
                        <DatePicker
                            selected={this.state.date}
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            onChange={this.onChangeDate}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">
                            Current Inspection Date: {new Date(this.state.inspectionTimeStamp).toLocaleDateString()}
                        </label>
                        <label className="text-muted">
                            Time: {new Date(this.state.inspectionTimeStamp).toLocaleTimeString()}
                        </label>
                        <div>
                            New Inspection:
                            <DatePicker
                                selected={this.state.inspectionTimeStamp}
                                showTimeSelect
                                timeFormat='HH:mm'
                                dateFormat="MM/dd/yyyy h:mm aa"
                                onChange={this.onChangeInspectionDate}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Time to destination (min)</label>
                    <input
                        type="text"
                        name='destinationMinutes'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.destinationMinutes}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Unit price</label>
                    <input
                        type="text"
                        name='unitPrice'
                        className="form-control"
                        value={this.state.unitPrice}
                        onChange={this.unitPriceInput}
                        placeholder="$"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Price with Tax</label>
                    <input
                        type="text"
                        name='taxPrice'
                        readOnly
                        className='form-control'
                        value={toCurrency(this.state.taxPrice, 2)}
                    />
                </div>
            </div>
            {
                // condicion original-> this.state.isPhoneQuote 
                localStorage.getItem('role') === 'Finance' &&
                <div className="my-2">
                    <input name="accept"
                        type="checkbox"
                        checked={this.state.paid}
                        onChange={this.onPaidChange}
                    />
                    <label>&nbsp;Paid</label>
                </div>
            }
            <div className='form-group row'>
                <div className='col-sm-2 d-flex justify-content-center' >
                    <input name="fuelSurchargeGlobal"
                        type="checkbox"
                        checked={this.state.fuelSurchargeGlobal}
                        onChange={(e) => this.setState({
                            fuelSurchargeGlobal: !this.state.fuelSurchargeGlobal
                        })}
                    />
                    <label className='mt-auto mb-auto'>&nbsp;Apply To Global</label>
                </div>
                <div className='col-sm-10'>
                    <label className='text-muted'>Fuel Surcharge</label>
                    <input
                        type='text'
                        name='fuelSurcharge'
                        className='form-control'
                        value={this.state.fuelSurcharge}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-2 d-flex justify-content-center' >
                    <input name="shippingGlobal"
                        type="checkbox"
                        checked={this.state.shippingGlobal}
                        onChange={(e) => this.setState({
                            shippingGlobal: !this.state.shippingGlobal
                        })}
                    />
                    <label className='mt-auto mb-auto'>&nbsp;Apply To Global</label>
                </div>
                <div className='col-sm-10'>
                    <label className='text-muted'>ShippingFee</label>
                    <input
                        type='text'
                        name='shippingFee'
                        className='form-control'
                        value={this.state.shippingFee}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className='form-group'>
                <div>
                    <label className="text-muted">Comments</label>
                </div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <button type='button' className="btn btn-primary" onClick={this.showCommentsModal}>Show Comments</button>
                    </div>
                </div>
                <Modal show={this.state.showComments} animation={false} onHide={this.showCommentsModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{`New comments`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CommentsModal comments={this.state.comments} pcb={this.setProposalComments}/>
                    </Modal.Body>
                </Modal>
            </div>
            <div className='form-group'>
                <label className='text-muted'>Current Slump: {this.state.slump} </label>
                <SlumpPicker action={this.onChangeSlump} />
            </div>
            <div className="my-2">
                { (this.state.proposalStatus !== 'Accepted' && this.state.proposalStatus !== 'Denied') && <button className='btn btn-success' type='submit'>Save</button> }
                {/* &nbsp;
                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Finance') && 
                    <button
                        type='button'
                        className='btn btn-danger'
                        onClick={(e) => { 
                            if (window.confirm('Are you sure you want to delete the proposal?')) { 
                                this.deleteProposal() 
                                const prospection = {
                                    prospectionStatus: 'Contact'
                                }
                                this.updateProspection(prospection)
                            } 
                        }}
                    >
                        Delete
                    </button>
                } */}
                &nbsp;
                {this.state.proposalStatus === 'Proposal' && <button className='btn btn-primary' type='button' onClick={() => this.createNewJob('accept')}>Accept Proposal</button>}
                &nbsp;
                {this.state.proposalStatus === 'Proposal' && <button className='btn btn-secondary' type='button' onClick={() => this.createNewJob('deny')}>Deny Proposal</button>}
                
            </div>
        </form>
    )

    componentDidMount() {
        this.getProposal()
    }

    render() {
        return (
            <Layout
                title={'Proposal ID: ' + this.state.number}
                description='View/Change proposal properties'
                className="container col-md-8 offset-md-2"
            >
                {this.ProposalForm()}
            </Layout>
        )
    }
}
