import React, { useState, useEffect } from 'react'
import { api } from '../utils/Api'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import Modal from 'react-bootstrap/Modal'
import ReadLoad from './ReadLoad'

const DailyReadOnlyItemOnTime = ({ job }) => {
    const [jobStatus, setJobStatus] = useState(job.jobStatus)
    const [loads, setLoads] = useState([])
    const [driver, setDriver] = useState("LOADING...")
    const [time, setTime] = useState("LOADING...")
    const [ticket, setTicket] = useState("LOADING...")
    const [code, setOrderCode] = useState("LOADING...")
    const [truck, setTruck] = useState("LOADING...")
    const [showLoad, setShowLoad] = useState(false)

    useEffect(() => {
        getLoadData()
    }, [])

    // link job status with color
    const selectColor = estatus => {
        switch (estatus) {
            case 'Trouble':
                return ('#7a7977')
            case 'Inactive':
                return ('#ffffff')
            case 'ok':
                return ('#7ad765')
            case 'Ready to Load':
                return ('#208928')
            case 'Active':
                return ('#fff569')
            case 'Date moved':
                return ('#d9a809')
            case 'Operations done (AR)':
                return ('#fce0a2')
            case 'Company cancellation':
                return ('#e087fa')
            case 'Quote':
                return ('#c3c2c0')
            case 'Confirm':
                return ('#31b9f7')
            default:
                return ('#fff569')
        }
    }

    const onChangeJobStatus = selected => {
        console.log(selected)
        setJobStatus(selected.label)
    }

    const handleNewModal = () => {
        setShowLoad(!showLoad)
    }

    const getLoadData = async () => {
        const url = api + '/load/' + job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            if (data.length !== 0) {
                setDriver(data[0].driver)
                setTime(new Date(data[0].createdAt).toLocaleTimeString())
                setOrderCode(data[0].orderCode)
                setTicket(data[0].deliveryTicket)
                setTruck(data[0].truckNumber)
            } else {
                setDriver("-")
                setTime("-")
                setOrderCode("-")
                setTicket("-")
                setTruck("-")
            }
            setLoads(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const DrawLoad = () => {
        if (loads.length > 0) {
            return loads.map((ld) => {
                return <tr key={ld._id}>
                    <td>{ld.driver}</td>
                    <td>{ld.truckNumber} </td>
                    <td>{ld.yards}</td>
                    <td>{ld.deliveryTicket} </td>
                    <td> {ld.orderCode} </td>
                    <td> {new Date(ld.createdAt).toLocaleDateString()} </td>
                    <td> {new Date(ld.createdAt).toLocaleTimeString()} </td>

                </tr>
            })
        } else {
            return (
                <tr>
                    <td colSpan="6" style={{ 'textAlign': 'center' }}>
                        No load yet
                    </td>
                </tr>
            )
        }
    }

    const style = {
        color: "blue",
        textDecoration: "underline blue"
    };
    return (
        <tr key={job._id} bgcolor={selectColor(jobStatus)} >
            <td>
                <Link to={'/job/' + job._id}>
                    {
                        job.customer.customerNumber + ' ' + job.customer.name + ' ph: ' + job.foremanPhone
                    }
                </Link>
            </td>
            <td>
                {job.number}
            </td>
            <td>
                {new Date(job.scheduledDate).toLocaleDateString()}
            </td>
            <td style={{ fontSize: '15px' }}>
                {new Date(job.scheduledDate).toLocaleTimeString()}
            </td>
            <td>
                {job.shipAddress}
            </td>
            <td>
                <a onClick={() => setShowLoad(true)} style={style}>
                    {job.loadYD3}
                </a>
                <ReadLoad handleModal={showLoad} close={() => setShowLoad(false)} loads={loads} jobID={job.number} loadTotal={job.loadYD3} />
            </td>
            <td>
                {job.balance ? (typeof job.targetYD3 === "string" ? job.targetYD3 : job.targetYD3.toString()) + ' + ' + job.balance : (typeof job.targetYD3 === "string" ? job.targetYD3 : job.targetYD3.toString()) + ' +  0'}
            </td>
            <td>
                {
                    job.destinationTime ?
                        job.destinationTime :
                        job.destinationMinutes
                }
            </td>
            <td>
                {jobStatus}
            </td>
            <td>
                {time}
            </td>
            <td>
                {driver}
            </td>
            <td>
                {ticket}
            </td>
            <td>
                {code}
            </td>
            <td>
                {truck}
            </td>
        </tr>
    )
}

export default DailyReadOnlyItemOnTime