import {
    GET_DRIVERS,
    DRIVER_ERROR
} from '../actions/types'

const initialState = {
    loading: true,
    drivers: []
}

function driverReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_DRIVERS:
            return {
                ...state,
                trucks: payload,
                loading: false
            }
        case DRIVER_ERROR: 
        return {
            ...state,
            error: payload,
            loading: false
        }
        default:
            return state
    }
}

export default driverReducer
