import {
    ADD_FOREMAN,
    GET_FOREMAN,
    GET_FOREMANS,
    UPDATE_FOREMAN,
    DELETE_FOREMAN,
    FOREMAN_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    foreman: null,
    foremans: []
}

function foremanReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_FOREMAN:
            return {
                ...state,
                foreman: payload,
                loading: false
            }
        case GET_FOREMAN:
            return {
                ...state,
                foreman: payload,
                loading: false
            }
        case GET_FOREMANS:
            return {
                ...state,
                foremans: payload,
                loading: false
            }
        case UPDATE_FOREMAN:
            return {
                ...state,
                foreman: payload,
                loading: false
            }
        case DELETE_FOREMAN:
            return {
                ...state,
                foreman: payload,
                loading: false
            }
        case FOREMAN_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default foremanReducer