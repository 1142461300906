import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_EMAIL,
    GET_EMAIL,
    GET_EMAILS,
    UPDATE_EMAIL,
    EMAIL_ERROR,
    DELETE_EMAIL
} from './types'

// Create new email listing
export const createEmailList = (formData) => async dispatch => {
    try {
        const { data } = await api.post('/emaillist', formData)

        dispatch({
            type: ADD_EMAIL,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: EMAIL_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Update email list attributes
export const updateEmailList = (id, formData) => async dispatch => {
    console.log(formData)
    try {
        const { data } = await api.patch(`/emaillist/${id}`, formData)

        dispatch({
            type: UPDATE_EMAIL,
            payload: data
        })

        dispatch(setAlert('Email list Updated', 'success'))
    } catch (err) {
        dispatch({
            type: EMAIL_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get list of all email lists
export const getEmailLists = () => async dispatch => {
    try {
        const { data } = await api.get(`/emaillist`)

        dispatch({
            type: GET_EMAILS,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: EMAIL_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get email list by id
export const getEmailList = id => async dispatch => {
    try {
        const { data } = await api.get(`/emaillist/${id}`)

        dispatch({
            type: GET_EMAIL,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: EMAIL_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Delete an email list
export const deleteEmailList = id => async (dispatch) => {
    try {
        const { data } = await api.delete(`/emaillist/${id}`)

        dispatch({
            type: DELETE_EMAIL,
            payload: data
        })

        dispatch(setAlert('Email list deleted', 'danger'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: EMAIL_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}