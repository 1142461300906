import React, {useState, useEffect} from 'react'
import PaymentTypeLoadPicker from '../paymenttype/PaymentTypeLoadPicker'
import { toCurrency } from '../utils/Convert'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { api } from '../utils/Api'

const ClientPayments = ({data}) => {
    const [client, setClient] = useState(data)
    const [cashBalance, setCashBalance] = useState(0)
    const [cardBalance, setCardBalance] = useState(0)
    const [checkBalance, setCheckBalance] = useState(0)
    const [payDate, setPayDate] = useState(new Date())
    const [pagos, setPagos] = useState([])
    const cardCommission = 0.04 

    const initialState = {
        amount: 0,
        balance: 0,
        at: payDate,
        type: 'Cash',
        reference: '',
        comments: ''
    }

    const [formData, setFormData] = useState(initialState)

    const {
        amount,
        balance,
        at, 
        type,
        reference,
        comments
    } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onChangePaymentType = selectedOption => {
        if (selectedOption) {
            setFormData({...formData, type: selectedOption.label})
        }
    } 

    useEffect(() => {
        getClient()
    }, [])

    const getClient = async () => {
        const url = api + '/customer/' + data._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setClient(data)
            setPagos(data.payments)
            setCashBalance(data.cashBalance ? data.cashBalance : 0)
            setCardBalance(data.cardBalance ? data.cardBalance : 0)
            setCheckBalance(data.checkBalance ? data.checkBalance : 0)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    const updateCustomer = async (newPayment) => {
        const url = api + '/customer/' + data._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        let newPagos = pagos
        newPagos.push(newPayment)
        setPagos(newPagos)

        const body = {
            payments: newPagos
        }
        switch (newPayment.type) {
            case 'Cash':
                body['cashBalance'] = cashBalance + parseFloat(newPayment.amount)
                setCashBalance(body['cashBalance'])
                data.cashBalance = body['cashBalance']
                break;
            case 'Credit Card':
                body['cardBalance'] = cardBalance + parseFloat(newPayment.amount)
                setCardBalance(body['cardBalance'])
                data.cardBalance = body['cardBalance']
                break;
            case 'Check':
                body['checkBalance'] = checkBalance + parseFloat(newPayment.amount)
                setCheckBalance(body['checkBalance'])
                data.checkBalance = body['checkBalance']
                break;
            default:
                break;
        }

        try {
            const { data } = await axios.patch(url, body)
            setClient(data)
            setPagos(data.payments)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const submit = (e) => {
        e.preventDefault()

        let pago = formData.amount
        if (type === 'Credit Card') {
            pago = amount * (1 + cardCommission)
        }

        const nuevo = {
            amount: pago,
            balance: pago,
            reference: reference,
            type: type,
            at: payDate,
            comments: comments
        }
        updateCustomer(nuevo)
    }

    const DrawList = () => {
        if(pagos.length === 0) return <tr>
            <td colSpan={'6'} className={'text-center'}>No payments registered</td>
        </tr>
        return pagos.map((pago, i) => {
            return pago.balance !==0 && <tr key={i}>
                <td>{new Date(pago.at).toLocaleDateString()}</td>
                <td style={{ fontWeight: "bold" }}>{toCurrency(pago.amount * 1,2)}</td>
                <td style={{ fontWeight: "bold" }}>{toCurrency(pago.balance * 1,2)}</td>
                <td>{pago.type} </td>
                <td> {pago.reference || 'NA'} </td>
                <td> {pago.comments || 'NA'} </td>
            </tr>
        })
    }

    const PaymentTable = () => (
        <table className='table'>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Balance</th>
                    <th>Type</th>
                    <th>Reference</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <DrawList />
            </tbody>
        </table>
    )

    return (
        <div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <b>Cash Balance:</b> {toCurrency(cashBalance,2)}
                </div>
                <div className='col-sm-3'>
                    <b>Credit Card Balance:</b> {toCurrency(cardBalance,2)}
                </div>
                <div className='col-sm-3'>
                    <b>Check Balance:</b> {toCurrency(checkBalance,2)}
                </div>
            </div>
            &nbsp;
            <PaymentTable />
            &nbsp;
            <form className='form' onSubmit={submit}>
                <div className='form-group row'>
                    <div className='col-sm-3'>
                        <input type='number'
                            required
                            step='0.01'
                            className='form-control'            
                            name='amount'
                            placeholder='Amount'
                            width='4'
                            onChange={onChange}                        
                        />
                    </div>
                    <div className='col-sm-3'>
                        <DatePicker 
                            selected={payDate}                
                            onChange={(date) => setPayDate(date)}                                                
                        />
                    </div>
                    <div className='col-sm-3'>
                        <PaymentTypeLoadPicker action={onChangePaymentType} />
                    </div>
                    <div className='col-sm-3'>
                        {
                            type !== 'Cash' &&
                            <input
                                type='text'
                                name='reference'
                                placeholder='Reference'
                                amount={reference}
                                onChange={onChange}
                                className='form-control'
                            />
                        }  
                    </div> 
                    <div className='col-sm-3'>
                        <input
                            type='text'
                            name='comments'
                            placeholder='Comments'
                            onChange={onChange}
                            className='form-control'
                        />
                    </div>
                    <div className='col-sm-3'>
                        <button className="btn btn-success">+</button>
                    </div>                    
                </div>
            </form>

        </div>
    )
}

export default ClientPayments