import { Button, Col, Form, Input, Row, Select, notification } from 'antd'
import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
const { Option } = Select

const NewLogisticsDriver = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const createDriver = async (data) => {
        const user = {
            name: data.name,
            license: data.license,
            phone: `+1${data.phone}`,
        }

        try {
            let url = api + '/logisticsdriver/create' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, user)
            openNotification('success', 'Success', 'Driver created')
            setLoading(false)
            history.push('/Logisticsdriverlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create driver')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        createDriver(values)
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                prefix: '+1'
            }}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='License'
                    name='license'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill license',
                        },
                        {
                            max: 20,
                            message: 'License must be less than 20 characters'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Driver'
        description='Create new Driver'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default NewLogisticsDriver