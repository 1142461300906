import {
    ADD_PLANT,
    GET_PLANTS,
    GET_PLANT,
    UPDATE_PLANT,
    PLANT_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    plant: null,
    plants: []
}

function plantReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_PLANT:
            return {
                ...state,
                plant: payload,
                loading: false
            }
        case GET_PLANTS:
            return {
                ...state,
                plants: payload,
                loading: false
            }
        case GET_PLANT:
            return {
                ...state,
                plant: payload,
                loading: false
            }
        case UPDATE_PLANT:
            return {
                ...state,
                plant: payload,
                loading: false
            }
        case PLANT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default plantReducer