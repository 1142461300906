import React, { Component } from 'react';
import Layout from '../../layout/Layout';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import SchoolsRender from './SchoolsRender';
import ContactsRender from './ContactsRender';
import EmploymentsRender from './EmploymentsRender';
import LanguagesRender from './LanguagesRender';
import axios from 'axios';
import { api } from '../../utils/Api';
import PositionPicker from '../position/PositionPicker';
import EmployeeTypePicker from './EmployeeTypePicker';
import DepartmentPicker from '../department/DepartmentPicker';
import PlantPicker from '../../plant/PlantPicker';
import InterviewCommentsRender from './InterviewCommentsRender';

export default class Employee extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            middleName: '',
            lastName: '',
            address: '',
            addressNumber: '',
            street: '',
            apt: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            age: 'yes',
            sex: 'Male',
            workedBefore: 'no',

            position: '',
            admissionDate: new Date(),
            employed: 'no',

            schools: [],
            showModalSchool: false,
            schoolIndex: 0,
            editSchool: false,
            currentSchool: {},

            contacts: [],
            showModalContact: false,
            contactIndex: 0,
            editContact: false,
            currentContact: {},

            employments: [],
            showModalEmployment: false,
            employmentIndex: 0,
            editEmployment: false,
            currentEmployment: {},

            languages: [],
            showModalLanguage: false,
            languageIndex: 0,
            editLanguage: false,
            currentLanguage: {},

            convicted: 'no',
            convictedDescription: '',

            military: 'no',
            militaryBranch: '',
            militaryRank: '',
            militaryFrom: new Date(),
            militaryTo: new Date(),
            militaryCommitment: 'no',
            militaryCommitmentDescription: '',
            militaryVeteran: 'no',
            militaryDisabledVeteran: 'no',
            militarySpecialDisabledVeteran: 'no',

            type: '',
            department: '',
            plant: '',
            status: '',

            interviews: [],
            showModalInterviewComment: false,
            interviewCommentIndex: 0,
            editInterviewComment: false,
            currentInterviewComment: {},

            specialComments: '',
            wagePerHour: 0
            
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async (e) => {
        e.preventDefault();

        const body = {
            ...this.state
        }

        body.age === 'yes' ? body.age = true : body.age = false;
        body.workedBefore === 'yes' ? body.workedBefore = true : body.workedBefore = false;
        body.employed === 'yes' ? body.employed = true : body.employed = false;
        if(body.convicted === 'yes'){
            body.convicted = true;
            
        }else{
            body.convicted = false;
            delete body.convictedDescription;
        }

        if(body.military === 'yes'){
            body.military = true;

            if(body.militaryCommitment === 'yes'){
                body.militaryCommitment = true;
                
            }else{
                body.militaryCommitment = false;
                delete body.militaryCommitmentDescription;
            }

            body.militaryVeteran === 'yes' ? body.militaryVeteran = true : body.militaryVeteran = false;
            body.militaryDisabledVeteran === 'yes' ? body.militaryDisabledVeteran = true : body.militaryDisabledVeteran = false;
            body.militarySpecialDisabledVeteran === 'yes' ? body.militarySpecialDisabledVeteran = true : body.militarySpecialDisabledVeteran = false;
            
        }else{
            body.military = false;
            delete body.militaryBranch;
            delete body.militaryRank;
            delete body.militaryFrom;
            delete body.militaryTo;
            delete body.militaryCommitment;
            delete body.militaryCommitmentDescription;
            delete body.militaryVeteran;
            delete body.militaryDisabledVeteran;
            delete body.militarySpecialDisabledVeteran;
        }

        delete body.showModalSchool
        delete body.schoolIndex
        delete body.editSchool
        delete body.currentSchool

        delete body.showModalContact
        delete body.contactIndex
        delete body.editContact
        delete body.currentContact

        delete body.showModalEmployment
        delete body.employmentIndex
        delete body.editEmployment
        delete body.currentEmployment

        delete body.showModalLanguage
        delete body.languageIndex
        delete body.editLanguage
        delete body.currentLanguage

        delete body.showModalInterviewComment
        delete body.interviewCommentIndex
        delete body.editInterviewComment
        delete body.currentInterviewComment


        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, body)
            this.props.history.push('/employees')
        } catch (err) {
            console.log(err)
            console.error(err.message)
            return null
        }
    }

    getEmployeeData = async () => {
        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            this.setState({
                name: data.name,
                middleName: data.middleName,
                lastName: data.lastName,
                address: data.address,
                addressNumber: data.addressNumber,
                street: data.street,
                apt: data.apt,
                city: data.city,
                state: data.state,
                zip: data.zip,
                phone: data.phone,
                email: data.email,
                age: data.age ? 'yes' : 'no',
                sex: data.sex,
                workedBefore: data.workedBefore ? 'yes' : 'no',

                position: data.position ? data.position._id : '',
                admissionDate: new Date(data.admissionDate),
                employed: data.employed ? 'yes' : 'no',

                schools: data.schools,
                contacts: data.contacts,
                employments: data.employments,
                languages: data.languages,

                convicted: data.convicted ? 'yes' : 'no',
                convictedDescription: data.convictedDescription ? data.convictedDescription : '',

                military: data.military ? 'yes' : 'no',
                militaryBranch: data.militaryBranch ? data.militaryBranch : '',
                militaryRank: data.militaryRank ? data.militaryRank : '',
                militaryFrom: data.militaryFrom ? new Date(data.militaryFrom) : new Date(),
                militaryTo: data.militaryTo ? new Date(data.militaryTo) : new Date(),
                militaryCommitment: data.militaryCommitment ? 'yes' : 'no',
                militaryCommitmentDescription: data.militaryCommitmentDescription ? data.militaryCommitmentDescription : '',
                militaryVeteran: data.militaryVeteran ? 'yes' : 'no',
                militaryDisabledVeteran: data.militaryDisabledVeteran ? 'yes' : 'no',
                militarySpecialDisabledVeteran: data.militarySpecialDisabledVeteran ? 'yes' : 'no',

                type: data.type,
                department: data.department,
                plant: data.plant,
                status: data.status,

                interviews: data.interviews,
                specialComments: data.specialComments ? data.specialComments : '',
                wagePerHour: data.wagePerHour ? data.wagePerHour : 0
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    updateEmployeeStatus = async (status) => {
        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }


        const body = {
            status: status
        }

        if(status === 'Dismissal'){
            body.dismissalDate = new Date()
            this.setState({
                status: status
            })
        }else{
            body.type = 'Employee'
            this.setState({
                type: 'Employee',
                status: status
            })
        }

        try {
            await axios.patch(url, body)

            if(status === 'Hired') {
                this.sendEmailToManagment()
            }

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    sendEmailToManagment = async () => {
        const url = api + `/emailemployee`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const body = {
            name: `${this.state.name} ${this.state.middleName} ${this.state.lastName}`
        }

        try {
            await axios.post(url, body)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    /**
     * On change status
     * @param {String} status 
     */
    changeStatus = (status) => {
        this.updateEmployeeStatus(status)
    }

    /**
     * On change start date of position
     * @param {Date} date 
     */
    onChangeAdmissionDate = (date) => {
        this.setState({
            admissionDate: date
        });
    }

    /**
     * On change military serve from
     * @param {Date} date 
     */
    onChangeServeFromDate = (date) => {
        this.setState({
            militaryFrom: date
        });
    }
    /**
     * On change military serve to
     * @param {Date} date 
     */
    onChangeServeToDate = (date) => {
        this.setState({
            militaryTo: date
        });
    }

    /**
     * On change position
     * @param {Object} selectedPosition 
     */
    onChangePosition = selectedPosition => {
        if (selectedPosition) {
            this.setState({
                position: selectedPosition.value,
            })
        }
    }

    /**
     * On change employee type
     * @param {Object} selectedEmployeeType 
     */
    onChangeEmployeeType = selectedEmployeeType => {
        if (selectedEmployeeType) {
            this.setState({
                type: selectedEmployeeType.value
            })
        }
    }

    /**
     * On change department
     * @param {Object} selectedDepartment 
     */
    onChangeDepartment = selectedDepartment => {
        if (selectedDepartment) {
            this.setState({
                department: selectedDepartment.value,
            })
        }
    }

    /**
     * On change plant
     * @param {Object} selectedPlant 
     */
    onChangePlant = selectedPlant => {
        if (selectedPlant) {
            this.setState({
                plant: selectedPlant.value
            })
        }
    }

    toggleModalSchool = () => {
        this.setState({
            showModalSchool: !this.state.showModalSchool,
            editSchool: false
        });
    }

    setSchools = (newSchools) => {
        this.setState({
            schools: newSchools
        });
    }

    removeCurrentSchool = (index) => {
        this.setState({
            schools: this.state.schools.filter((v, i) => i !== index)
        })
    }

    SchoolTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalSchool}>Add School</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th># of Years Completed</th>
                        <th>Graduated</th>
                        <th>Mayor/Dregree</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.schools && this.state.schools.length !== 0 ? 
                        this.state.schools.map((school, index) => {
                            return <tr key={index}>
                                <td>{school.name}</td>
                                <td>{school.address}</td>
                                <td>{school.years}</td>
                                <td>
                                    <input 
                                        type='checkbox'
                                        checked={school.graduated}
                                        readOnly
                                    />
                                </td>
                                <td>{school.degree}</td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            schoolIndex: index,
                                            currentSchool: school,
                                            editSchool: true,
                                            showModalSchool: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentSchool(index)}>Delete</button>
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={6} className='text-center'>No schools registered</td>
                        </tr>
                        
                    }
                </tbody>
            </table>
        </div>
    }

    toggleModalContact = () => {
        this.setState({
            showModalContact: !this.state.showModalContact,
            editContact: false
        });
    }

    setContacts = (newContacts) => {
        this.setState({
            contacts: newContacts
        });
    }

    removeCurrentContact = (index) => {
        this.setState({
            contacts: this.state.contacts.filter((v, i) => i !== index)
        })
    }

    ContactTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalContact}>Add Contact</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Home Phone</th>
                        <th>Work Phone</th>
                        <th>Relationship</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.contacts && this.state.contacts.length !== 0 ? 
                        this.state.contacts.map((contact, index) => {
                            return <tr key={index}>
                                <td>{contact.name}</td>
                                <td>{contact.address}</td>
                                <td>{contact.city}</td>
                                <td>{contact.state}</td>
                                <td>{contact.zip}</td>
                                <td>{contact.homePhone}</td>
                                <td>{contact.workPhone}</td>
                                <td>{contact.relationship}</td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            contactIndex: index,
                                            currentContact: contact,
                                            editContact: true,
                                            showModalContact: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentContact(index)}>Delete</button>
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={9} className='text-center'>No contacts registered</td>
                        </tr>
                        
                    }
                </tbody>
            </table>
        </div>
    }

    toggleModalEmployment = () => {
        this.setState({
            showModalEmployment: !this.state.showModalEmployment,
            editEmployment: false
        });
    }

    setEmployments = (newEmployments) => {
        this.setState({
            employments: newEmployments
        });
    }

    removeCurrentEmployment = (index) => {
        this.setState({
            employments: this.state.employments.filter((v, i) => i !== index)
        })
    }

    EmploymentTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalEmployment}>Add Employment</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th>Supervisor</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.employments && this.state.employments.length !== 0 ? 
                        this.state.employments.map((employment, index) => {
                            return <tr key={index}>
                                <td>{employment.company}</td>
                                <td>{employment.address}</td>
                                <td>{employment.phone}</td>
                                <td>{employment.supervisor}</td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            employmentIndex: index,
                                            currentEmployment: employment,
                                            editEmployment: true,
                                            showModalEmployment: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentEmployment(index)}>Delete</button>
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={5} className='text-center'>No employments registered</td>
                        </tr>
                        
                    }
                </tbody>
            </table>
        </div>
    }

    toggleModalLanguage = () => {
        this.setState({
            showModalLanguage: !this.state.showModalLanguage,
            editLanguage: false
        });
    }

    setLanguages = (newLanguages) => {
        this.setState({
            languages: newLanguages
        });
    }

    removeCurrentLanguage = (index) => {
        this.setState({
            languages: this.state.languages.filter((v, i) => i !== index)
        })
    }

    LanguageTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalLanguage}>Add Language</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Level</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.languages && this.state.languages.length !== 0 ? 
                        this.state.languages.map((language, index) => {
                            return <tr key={index}>
                                <td>{language.language}</td>
                                <td>{language.level}</td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            languageIndex: index,
                                            currentLanguage: language,
                                            editLanguage: true,
                                            showModalLanguage: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentLanguage(index)}>Delete</button>
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={5} className='text-center'>No languages registered</td>
                        </tr>
                        
                    }
                </tbody>
            </table>
        </div>
    }
    
    toggleModalInterviewComment = () => {
        this.setState({
            showModalInterviewComment: !this.state.showModalInterviewComment,
            editInterviewComment: false
        });
    }

    setInterviewComments = (newInterviewComments) => {
        this.setState({
            interviews: newInterviewComments
        });
    }

    removeCurrentInterviewComment = (index) => {
        this.setState({
            interviews: this.state.interviews.filter((v, i) => i !== index)
        })
    }

    InterviewCommentsTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalInterviewComment}>Add Interview Comments</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Interviewer</th>
                        <th>Comment</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.interviews && this.state.interviews.length !== 0 ? 
                        this.state.interviews.map((interview, index) => {
                            return <tr key={index}>
                                <td>{`${new Date(interview.date).toLocaleDateString()} ${new Date(interview.date).toLocaleTimeString()}`}</td>
                                <td>{interview.interviewer}</td>
                                <td>{interview.comment}</td>
                                <td>
                                    <button  className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            interviewCommentIndex: index,
                                            currentInterviewComment: interview,
                                            editInterviewComment: true,
                                            showModalInterviewComment: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentInterviewComment(index)}>Delete</button>
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={4} className='text-center'>No interview comments registered</td>
                        </tr>
                        
                    }
                </tbody>
            </table>
        </div>
    }

    /**
     * Handler on change age
     * @param {Event} event 
     */
    handleChangeAge = (event) => {
        this.setState({
            age: event.target.value
        });
    }

    /**
     * Handler on change sex employee
     * @param {Event} event 
     */
    handleChangeSex = (event) => {
        this.setState({
            sex: event.target.value
        });
    }

    /**
     * Handler on change of worked before in the company 
     * @param {Event} event 
     */
    handleChangeWorkedBefore = (event) => {
        this.setState({
            workedBefore: event.target.value
        });
    }

    /**
     * Handler on change of employed 
     * @param {Event} event 
     */
    handleChangeEmployed = (event) => {
        this.setState({
            employed: event.target.value
        });
    }
    
    /**
     * Handler on change of convicted value
     * @param {Event} event 
     */
    handleChangeConvicted = (event) => {
        this.setState({
            convicted: event.target.value
        });
    }

    /**
     * Handler on change of military service value
     * @param {Event} event 
     */
    handleChangeMilitary = (event) => {
        this.setState({
            military: event.target.value
        });
    }

    /**
     * Handler on change of military commitment
     * @param {Event} event 
     */
    handleChangeMilitaryCommitment = (event) => {
        this.setState({
            militaryCommitment: event.target.value
        });
    }

    /**
     * Handler on change of military veteran
     * @param {Event} event 
     */
    handleChangeMilitaryVeteran = (event) => {
        this.setState({
            militaryVeteran: event.target.value
        });
    }

    /**
     * Handler on change of disabled military veteran
     * @param {Event} event 
     */
    handleChangeMilitaryDisabledVeteran = (event) => {
        this.setState({
            militaryDisabledVeteran: event.target.value
        });
    }

    /**
     * Handler on change of disabled military veteran
     * @param {Event} event 
     */
    handleChangeMilitarySpecialDisabledVeteran = (event) => {
        this.setState({
            militarySpecialDisabledVeteran: event.target.value
        });
    }

    navigationUrls = (type) => {
        switch (type) {
            case 'Application':
                this.props.history.push(`/applicationpdf/${this.props.match.params.id}`)
                break;
            case 'Expenses':
                this.props.history.push(`/expenses/${this.props.match.params.id}`)
                break;
            case 'Expensesnoproof':
                this.props.history.push(`/expenseswp/${this.props.match.params.id}`)
                break;
            case 'Writeup':
                this.props.history.push(`/employeewu/${this.props.match.params.id}`)
                break;
            default:
                break;
        }
    }

    componentDidMount(){
        this.getEmployeeData()
    }


    RenderEmployee = () => (
        <form onSubmit={this.onSubmit}>
            <h4>Personal Information</h4>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Name</label>
                    <input className='form-control'
                        name='name'
                        type='text'
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                        placeholder='Name'
                        required
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Middle Name</label>
                    <input className='form-control'
                        name='middleName'
                        type='text'
                        value={this.state.middleName}
                        onChange={e => this.setState({ middleName: e.target.value })}
                        placeholder='Middle Name'
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Last Name</label>
                    <input className='form-control'
                        name='lastName'
                        type='text'
                        value={this.state.lastName}
                        onChange={e => this.setState({ lastName: e.target.value })}
                        placeholder='Last Name'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Current Address</label>
                    <input className='form-control'
                        name='address'
                        type='text'
                        value={this.state.address}
                        onChange={e => this.setState({ address: e.target.value })}
                        placeholder='Address'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Address Number</label>
                    <input className='form-control'
                        name='addressNumber'
                        type='text'
                        value={this.state.addressNumber}
                        onChange={e => this.setState({ addressNumber: e.target.value })}
                        placeholder='Address'
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Street</label>
                    <input className='form-control'
                        name='street'
                        type='text'
                        value={this.state.street}
                        onChange={e => this.setState({ street: e.target.value })}
                        placeholder='Street'
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Apt</label>
                    <input className='form-control'
                        name='apt'
                        type='text'
                        value={this.state.apt}
                        onChange={e => this.setState({ apt: e.target.value })}
                        placeholder='Apt'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>City</label>
                    <input className='form-control'
                        name='city'
                        type='text'
                        value={this.state.city}
                        onChange={e => this.setState({ city: e.target.value })}
                        placeholder='City'
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>State</label>
                    <input className='form-control'
                        name='state'
                        type='text'
                        value={this.state.state}
                        onChange={e => this.setState({ state: e.target.value })}
                        placeholder='State'
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Zip</label>
                    <input className='form-control'
                        name='zip'
                        type='text'
                        value={this.state.zip}
                        onChange={e => this.setState({ zip: e.target.value })}
                        placeholder='Zip'
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className="text-muted">Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Phone"
                        value={this.state.phone}
                        onChange={e => this.setState({ phone: e })}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className="text-muted">Email</label>
                    <input className='form-control'
                        name='email'
                        type='email'
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                        placeholder='Email'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Are you over 18 years of age?</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="yesAge"
                    name="age"
                    value="yes"
                    checked={this.state.age === 'yes'}
                    onChange={this.handleChangeAge}
                    />
                    <label htmlFor="yesAge">Yes</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="noAge"
                    name="age"
                    value="no"
                    checked={this.state.age === 'no'}
                    onChange={this.handleChangeAge}
                    />
                    <label htmlFor="noAge">No</label>
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>Sex: </label>
                    &nbsp;
                    <input
                    type="radio"
                    id="male"
                    name="sex"
                    value="Male"
                    checked={this.state.sex === 'Male'}
                    onChange={this.handleChangeSex}
                    />
                    <label htmlFor="male">Male</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="female"
                    name="sex"
                    value="Female"
                    checked={this.state.sex === 'Female'}
                    onChange={this.handleChangeSex}
                    />
                    <label htmlFor="female">Female</label>
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>Have you worked for this company before?</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="yesWorkedBefore"
                    name="workedBefore"
                    value="yes"
                    checked={this.state.workedBefore === 'yes'}
                    onChange={this.handleChangeWorkedBefore}
                    />
                    <label htmlFor="yesWorkedBefore">Yes</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="noWorkedBefore"
                    name="workedBefore"
                    value="no"
                    checked={this.state.workedBefore === 'no'}
                    onChange={this.handleChangeWorkedBefore}
                    />
                    <label htmlFor="noWorkedBefore">No</label>
                </div>
            </div>
            
            <h4>Contact Information</h4>
            {this.ContactTable()}

            <h4>Position Desired</h4>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Position</label>
                    <PositionPicker action={this.onChangePosition} value={this.state.position} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Date you can start</label>
                    <DatePicker
                        selected={this.state.admissionDate}
                        dateFormat="MM/dd/yyyy"
                        onChange={this.onChangeAdmissionDate}
                        minDate={new Date()}
                    />
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>Are you employed now?</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="yesEmployedNow"
                    name="employed"
                    value="yes"
                    checked={this.state.employed === 'yes'}
                    onChange={this.handleChangeEmployed}
                    />
                    <label htmlFor="yesEmployedNow">Yes</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="noEmployedNow"
                    name="employed"
                    value="no"
                    checked={this.state.employed === 'no'}
                    onChange={this.handleChangeEmployed}
                    />
                    <label htmlFor="noEmployedNow">No</label>
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Department</label>
                    <DepartmentPicker action={this.onChangeDepartment} value={this.state.department} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Plant</label>
                    <PlantPicker action={this.onChangePlant} value={this.state.plant} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Type</label>
                    <EmployeeTypePicker action={this.onChangeEmployeeType} value={this.state.type} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Wage Per Hour</label>
                    <input className='form-control'
                        name='wagePerHour'
                        type='number'
                        value={this.state.wagePerHour}
                        onChange={e => this.setState({ wagePerHour: e.target.valueAsNumber })}
                        placeholder='Wage Per Hour'
                        min={0}
                        step={0.01}
                    />
                </div>
            </div>
            <h4>Educational background and employee history</h4>
            {this.SchoolTable()}
            {this.EmploymentTable()}

            <h4>General</h4>
            {this.LanguageTable()}

            <h4>Security</h4>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Have you been convicted of a felony within the last five years?</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="yesConvicted"
                    name="convicted"
                    value="yes"
                    checked={this.state.convicted === 'yes'}
                    onChange={this.handleChangeConvicted}
                    />
                    <label htmlFor="yesConvicted">Yes</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="noConvicted"
                    name="convicted"
                    value="no"
                    checked={this.state.convicted === 'no'}
                    onChange={this.handleChangeConvicted}
                    />
                    <label htmlFor="noConvicted">No</label>
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>If yes, please explain ( This will not necessarily exclude you from consideration ) </label>
                    {
                        this.state.convicted === 'yes' &&
                        <input className='form-control'
                            name='convictedDescription'
                            type='text'
                            value={this.state.convictedDescription}
                            onChange={e => this.setState({ convictedDescription: e.target.value })}
                            placeholder='Description'
                            required={this.state.convicted === 'yes'}
                        />
                    }
                </div>
            </div>

            <h4>Military</h4>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Have you served in the military?</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="yesMilitary"
                    name="military"
                    value="yes"
                    checked={this.state.military === 'yes'}
                    onChange={this.handleChangeMilitary}
                    />
                    <label htmlFor="yesMilitary">Yes</label>
                    &nbsp;
                    <input
                    type="radio"
                    id="noMilitary"
                    name="military"
                    value="no"
                    checked={this.state.military === 'no'}
                    onChange={this.handleChangeMilitary}
                    />
                    <label htmlFor="noMilitary">No</label>
                </div>
            </div>
            {
                this.state.military === 'yes' &&
                <div className='form-group row'>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Which branch?</label>
                        <input className='form-control'
                            name='militaryBranch'
                            type='text'
                            value={this.state.militaryBranch}
                            onChange={e => this.setState({ militaryBranch: e.target.value })}
                            placeholder='Branch'
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='text-muted'>Serve From</label>
                        <DatePicker
                            selected={this.state.militaryFrom}
                            dateFormat="MM/dd/yyyy"
                            onChange={this.onChangeServeFromDate}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='text-muted'>Serve To</label>
                        <DatePicker
                            selected={this.state.militaryTo}
                            dateFormat="MM/dd/yyyy"
                            onChange={this.onChangeServeToDate}
                            minDate={this.state.militaryFrom}
                        />
                    </div>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Rank</label>
                        <input className='form-control'
                            name='militaryRank'
                            type='text'
                            value={this.state.militaryRank}
                            onChange={e => this.setState({ militaryRank: e.target.value })}
                            placeholder='Rank'
                        />
                    </div>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Do you have any military commitment, including National Guard, that would influence your work schedule?</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="yesCommitment"
                        name="militaryCommitment"
                        value="yes"
                        checked={this.state.militaryCommitment === 'yes'}
                        onChange={this.handleChangeMilitaryCommitment}
                        />
                        <label htmlFor="yesCommitment">Yes</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="noCommitment"
                        name="militaryCommitment"
                        value="no"
                        checked={this.state.militaryCommitment === 'no'}
                        onChange={this.handleChangeMilitaryCommitment}
                        />
                        <label htmlFor="noCommitment">No</label>
                    </div>
                    {
                        this.state.militaryCommitment === 'yes' &&
                        <div className='col-sm-12'>
                            <label className='text-muted'>If yes, explain</label>
                            <input className='form-control'
                                name='militaryCommitmentDescription'
                                type='text'
                                value={this.state.militaryCommitmentDescription}
                                onChange={e => this.setState({ militaryCommitmentDescription: e.target.value })}
                                placeholder='Description'
                                required={this.state.militaryCommitment === 'yes'}
                            />
                        </div>
                    }
                    <div className='col-sm-12'>
                        <label className='text-muted'>Are you a Vietnam Veteran?</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="yesVeteran"
                        name="militaryVeteran"
                        value="yes"
                        checked={this.state.militaryVeteran === 'yes'}
                        onChange={this.handleChangeMilitaryVeteran}
                        />
                        <label htmlFor="yesVeteran">Yes</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="noVeteran"
                        name="militaryVeteran"
                        value="no"
                        checked={this.state.militaryVeteran === 'no'}
                        onChange={this.handleChangeMilitaryVeteran}
                        />
                        <label htmlFor="noVeteran">No</label>
                    </div>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Are you a disabled Veteran?</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="yesDisabledVeteran"
                        name="militaryDisabledVeteran"
                        value="yes"
                        checked={this.state.militaryDisabledVeteran === 'yes'}
                        onChange={this.handleChangeMilitaryDisabledVeteran}
                        />
                        <label htmlFor="yesDisabledVeteran">Yes</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="noDisabledVeteran"
                        name="militaryDisabledVeteran"
                        value="no"
                        checked={this.state.militaryDisabledVeteran === 'no'}
                        onChange={this.handleChangeMilitaryDisabledVeteran}
                        />
                        <label htmlFor="noDisabledVeteran">No</label>
                    </div>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Are you a special disabled Veteran?</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="yesSpecialDisabledVeteran"
                        name="militarySpecialDisabledVeteran"
                        value="yes"
                        checked={this.state.militarySpecialDisabledVeteran === 'yes'}
                        onChange={this.handleChangeMilitarySpecialDisabledVeteran}
                        />
                        <label htmlFor="yesSpecialDisabledVeteran">Yes</label>
                        &nbsp;
                        <input
                        type="radio"
                        id="noSpecialDisabledVeteran"
                        name="militarySpecialDisabledVeteran"
                        value="no"
                        checked={this.state.militarySpecialDisabledVeteran === 'no'}
                        onChange={this.handleChangeMilitarySpecialDisabledVeteran}
                        />
                        <label htmlFor="noSpecialDisabledVeteran">No</label>
                    </div>
                </div>
            }
            <h4>Interview Comments</h4>
            {this.InterviewCommentsTable()}
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Special Comments</label>
                    <input className='form-control'
                        name='specialComments'
                        type='text'
                        value={this.state.specialComments}
                        onChange={e => this.setState({ specialComments: e.target.value })}
                        placeholder='Special Comments'
                    />
                </div>
            </div>

            <div className='my-3'>
                <button type='submit' className='btn btn-success'>Save</button>
                &nbsp;
                <button type='button' className='btn btn-primary' onClick={() => this.navigationUrls('Application')}>Application</button>
                &nbsp;
                {
                    this.state.status === 'Applicant' &&
                    <button type='button' className='btn btn-secondary' onClick={() => this.changeStatus('Hired')}>Hire</button>
                }
                {
                    this.state.status === 'Hired' &&
                    <button type='button' className='btn btn-danger' onClick={() => this.changeStatus('Dismissal')}>Dismissal</button>
                }
                &nbsp;
                <button type='button' className='btn btn-warning' onClick={() => this.navigationUrls('Expenses')}>Expenses</button>
                &nbsp;
                <button type='button' className='btn btn-secondary' onClick={() => this.navigationUrls('Expensesnoproof')}>Expenses Without Proof</button>
                &nbsp;
                <button type='button' className='btn btn-info' onClick={() => this.navigationUrls('Writeup')}>Write Up</button>
                &nbsp;
                <button type='button' className='btn btn-secondary' onClick={() => this.navigationUrls('Training')}>Traning Meeting Record</button>
            </div>
            
        </form>
    )

    render() {
        return <Layout
            title='Employee'
            description='Edit Current Employee'
            className='container col-md-8 offset-md-2'
        >
            {this.RenderEmployee()}
            <Modal
                show={this.state.showModalSchool}
                animation={false}
                onHide={this.toggleModalSchool}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Schools</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SchoolsRender currentSchool={this.state.currentSchool} schools={this.state.schools} setSchools={this.setSchools} toggleModal={this.toggleModalSchool} edit={this.state.editSchool} index={this.state.schoolIndex} />
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.showModalContact}
                animation={false}
                onHide={this.toggleModalContact}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contacts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactsRender currentContact={this.state.currentContact} contacts={this.state.contacts} setContacts={this.setContacts} toggleModal={this.toggleModalContact} edit={this.state.editContact} index={this.state.contactIndex} />
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.showModalEmployment}
                animation={false}
                onHide={this.toggleModalEmployment}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Employments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmploymentsRender currentEmployment={this.state.currentEmployment} employments={this.state.employments} setEmployments={this.setEmployments} toggleModal={this.toggleModalEmployment} edit={this.state.editEmployment} index={this.state.employmentIndex} />
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.showModalLanguage}
                animation={false}
                onHide={this.toggleModalLanguage}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Languages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguagesRender currentLanguage={this.state.currentLanguage} languages={this.state.languages} setLanguages={this.setLanguages} toggleModal={this.toggleModalLanguage} edit={this.state.editLanguage} index={this.state.languageIndex} />
                </Modal.Body>
            </Modal>

            <Modal
                show={this.state.showModalInterviewComment}
                animation={false}
                onHide={this.toggleModalInterviewComment}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Interview Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InterviewCommentsRender currentInterviewComment={this.state.currentInterviewComment} interviews={this.state.interviews} setInterviewComments={this.setInterviewComments} toggleModal={this.toggleModalInterviewComment} edit={this.state.editInterviewComment} index={this.state.interviewCommentIndex} />
                </Modal.Body>
            </Modal>

        </Layout>
    }
}