import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import { Link, useHistory } from 'react-router-dom'
import { Typography, Flex, Row, Col, Button } from 'antd'
import dayjs from 'dayjs'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import { CSVLink } from 'react-csv'
const { Text } = Typography

const optionsPicker = [
    {value: 'All', label: 'All'},
    {value: 'Completed', label: 'With Signature'},
    {value: 'Incompleted', label: 'Without Signature'}
]

const columnsCSV = [
    { key: 'customer', label: 'Customer' },
    { key: 'date', label: 'Date' },
    { key: 'driver', label: 'Driver' },
    { key: 'truck', label: 'Truck' },
    { key: 'deliveryTicket', label: 'Delivery Ticket' },
    { key: 'orderCode', label: 'Order Code' },
    { key: 'jobNumber', label: 'Job ID' }
]

const ETicketReport = () => {
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [loading, setLoading] = useState(true)
    const [signature, setSignature] = useState('All')
    const [loadList, setLoadList] = useState([])
    const refDownload = useRef()
    let history = useHistory()

    /**
     ** Options header (columns)
    */
     const columns = [
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Delivery Ticket',
            dataIndex: 'deliveryTicket',
            key: 'deliveryTicket',
            render: (value, values) => {
                {/* <td><p onClick={() => history.push(`/e-ticket/${load._id}`)}>{load.deliveryTicket}</p></td> */}
                const hyperlink = `http://ec2-3-85-198-38.compute-1.amazonaws.com/${values.id}`
                return <Text>{<a href={hyperlink} target='_blank'>{value}</a>}</Text>
            }
        },
        {
            title: 'Order Code',
            dataIndex: 'orderCode',
            key: 'orderCode',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Job ID',
            dataIndex: 'jobNumber',
            key: 'jobNumber',
            render: (value, values) => {
                return <Link to={'/eticketsjob/' + values.jobId}>{value}</Link>
            }
        },
    ]

    useEffect(() => {
        getLoadList('')
    }, [])

    const getLoadList = async(term) => {
        setLoading(true)
        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/etickets/search?startDate=${start}&endDate=${end}&term=${term}&signature=${signature}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            
            data.map(load => {
                list.push({
                    key: load._id,
                    id: load._id,
                    customer: `${load.job.customer.customerNumber} ${load.job.customer.name}`,
                    date: new Intl.DateTimeFormat('en-US').format(new Date(load.date)),
                    truck: load.truckNumber,
                    driver: load.driver,
                    deliveryTicket: load.deliveryTicket,
                    orderCode: load.orderCode,
                    jobNumber: load.job.number,
                    jobId: load.job._id
                })
            })

            setLoadList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            getLoadList(value)
        }
    }       

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const onChangePicker = (value) => {
        setSignature(value)
    }

    const RenderDates = () => {
        return <CustomSearch 
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        onRangeChange={onRangeChange}
        onSearch={onSearch}
        labelPicker={'Signature'}
        optionsPicker={optionsPicker}
        onChangePicker={onChangePicker}
        defaultValuePicker={signature}
        pickerWidth={'200px'}
        />
    }

    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            <CustomTable 
            columns={columns}
            data={loadList}
            isScroll={false}
            />
        </Flex>
    }

    const RenderDownloadReport = () => {
        return <div>
            <CSVLink 
            data={loadList} 
            headers={columnsCSV} 
            filename={"57concrete-eticket-report.csv"}
            hidden
            ref={refDownload}
            >
                Download report
            </CSVLink>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type='primary' style={{width: '100%'}} onClick={e => refDownload.current.link.click()} loading={loading}>Download Report</Button>
                </Col>
            </Row>
        </div>
    }

    return (
        <Layout
            title='E-Tickets'
            description='Report'
            className='container col-md-10'
        >
            <Flex vertical gap={'large'}>
                <RenderDates />
                <RenderDownloadReport />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default ETicketReport