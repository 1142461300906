import React, { useState, useEffect } from 'react'  
import axios from 'axios'
import Select from 'react-select'

const CertForm = ( { history } ) => {
    const initialState = {
        name: '',
        address: '',
        email: '',
        phone: ''
    }

    const [formData, setFormData] = useState(initialState)
    const [unitPrice, setUnitPrice] = useState(118)
    const [price, setPrice] = useState(0)
    const [yards, setYards] = useState(0)
    const [psi, setPsi] = useState('')

    useEffect(() => {
        calculatePrice()
    }, [unitPrice, yards])


    const {
        name,
        address,
        email,
        phone
    } = formData 

    const submit = (e) => {
        e.preventDefault()
        formData.unitPrice = unitPrice
        formData.price = price 
        formData.psi = psi 

        console.log(formData)
        createPreorder(formData)
        history.push('/confirmation')
    }

    const createPreorder = async (formData) => {
        const url = 'https://jap.57concrete.com/api/preorder'
        try {
            const {data} = await axios.post(url, formData)
            console.log(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const selectProduct = (selected) => {
        console.log(selected)
        if (selected) {
            const {label, value} = selected 
            setUnitPrice(value)
            setPsi(label)
        }        
    }

    const calculatePrice = () => {
        const precio = unitPrice * yards
        console.log(precio)
        setPrice(precio)
    }

    const options = [
        {label: '3000 57Concrete', value: 118},
        {label: '3000 Pea Grava', value: 145},
        {label: '3000 Block Fill', value: 155},
        {label: '3000 Pool', value: 220},
        {label: '3000 Curve', value: 125},
        {label: '4000 57concrete', value:130}
    ]

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    return (
        <div style={{ 
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/fondo2.jpeg'})`,
            /* Full height */
            height: '1078px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>        
            <img src="http://57concrete.com/assets/images/logo/logo-light.png" alt="Logo" style={{width:'20%'}} /> 
            <center>
            <h2>Order your Concrete</h2>
            </center>
            <div className="container col-md-8 offset-md-2">
                <form className="form" onSubmit={submit}>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Name</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='name'
                            className="form-control"
                            value={name}
                            placeholder="Name"
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='text-muted'>Address</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='address'
                            className="form-control"
                            value={address}
                            placeholder="Address"
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Email</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='email'
                            className="form-control"
                            value={email}
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className='text-muted'>Phone</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='phone'
                            className="form-control"
                            value={phone}
                            placeholder="Phone"
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-3'>
                        <label className="text-muted">PSI</label>
                        <Select
                            name="form-field-name"
                            placeholder='PSI...'                        
                            onChange={selectProduct}
                            isClearable={true}
                            isSearchable={true}
                            options={options}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <label className='text-muted'>Unit Price</label>
                        <input 
                            value={unitPrice}    
                            className='form-control'             
                        />
                    </div>
                    <div className='col-sm-3'>
                        <label className="text-muted">Yards</label>
                        <input
                            onChange={(e) => setYards(e.target.value)}
                            type="text"
                            name='yards'
                            className="form-control"
                            value={yards}
                            placeholder="Yards"
                            required
                        />
                    </div>
                    <div className='col-sm-3'>
                        <label className='text-muted'>Price</label>
                        <input
                            
                            value={price}            
                            className="form-control"
                        />
                    </div>
                </div>
                <br/>
                <button className='btn btn-primary'>
                    submit
                </button>
                </form>
            </div>                       
        </div>
    )
}

export default CertForm
