import React, { useEffect, useState, useRef } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Spinner from '../layout/Spinner'
import CreateForecastModal from './CreateForecastModal'
import EditForecastModal from './EditForecastModal'
import { toThousands } from '../utils/Convert'
import { getForecastGroup, deleteForecast } from '../../actions/forecast'
import { api } from '../utils/Api'
import axios from 'axios'


const ForecastBySeller = ({ match, getForecastGroup, deleteForecast, forecast: { forecasts, loading } }) => {

    const sellerID = match.params.id
    // const sellerName = useRef('')
    const sellerForecasts = useRef()
    const customerList = useRef()
    const editCustomer = useRef()
    const editPeriod = useRef()
    const editYards = useRef()
    const editForecast = useRef()

    const [sellerName, setSellerName] = useState('')
    const [titleLabel, setTitleLabel] = useState(`Forecast`)
    const [forecastModalShow, setForecastModalShow] = useState(false)
    const [editModalShow, setEditModalShow] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getForecastGroup()
        getSellerInfo()
    }, [])

    useEffect(() => {
        getForecastGroup()
    }, [refresh])

    if (forecasts) {
        sellerForecasts.current = forecasts.filter(fc => fc._id.seller === sellerID)
    }

    const getSellerInfo = async () => {
        const url = api + `/users/${sellerID}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setSellerName(data.name)
            setTitleLabel(`Forecast for ${data.name}`)
            // sellerName.current = data.name
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Sets values of forecast that was selected and shows modal
    const ForecastEdit = (period, customer, yards, forecastID) => {

        editPeriod.current = period
        editCustomer.current = customer
        editYards.current = yards
        editForecast.current = forecastID

        setEditModalShow(true)
    }

    // Modal callback from edit and create
    const modalCallback = (word) => {
        setRefresh(!refresh)
        if (word === 'edit') setEditModalShow(false)        // EDIT
        if (word === 'create') setForecastModalShow(false)  // CREATE
    }

    const formatDate = (i) => {
        const today = new Date()
        return `${today.getFullYear()}-` + (i < 9 ? `0${i + 1}-01` : `${i + 1}-01`)
    }

    // find customer name from list
    const customerName = id => {
        console.log(`buscar: ${id}`)
        console.log(customerList.current)
        const cliente = customerList.current.filter(cust => cust._id === id)
        console.log(cliente[0])
        const cte = cliente[0].customerNumber + ' ' + cliente[0].name
        console.log(cte)
        return (cte)
    }

    // Create month list for records of each customer
    const monthYards = (yards) => {
        let monthList = [{ month: 'JAN', yards: 0, _id: '' }, { month: 'FEB', yards: 0, _id: '' }, { month: 'MAR', yards: 0, _id: '' }, { month: 'APR', yards: 0, _id: '' }, { month: 'MAY', yards: 0, _id: '' }, { month: 'JUN', yards: 0, _id: '' }, { month: 'JUL', yards: 0, _id: '' }, { month: 'AUG', yards: 0, _id: '' }, { month: 'SEP', yards: 0, _id: '' }, { month: 'OCT', yards: 0, _id: '' }, { month: 'NOV', yards: 0, _id: '' }, { month: 'DEC', yards: 0, _id: '' }]

        yards.forEach((yd) => {
            const yardsExp = yd.yardsExpected
            let month = yd.period
            month = parseInt(month.substring(5, 7))

            monthList[month - 1].yards += yardsExp
            monthList[month - 1]._id += yd._id
        })

        return monthList
    }

    const delForecast = (customer) => {
        console.log('delete customer', customer)
        deleteForecast(customer)
        setRefresh(!refresh)
    }

    const DrawList = () => {
        const list = sellerForecasts.current
        list.sort((a, b) => a._id.customerName > b._id.customerName ? 1 : -1)

        return list.map((item, i) => {
            const plan = item.yards 
            const sumRow = plan.reduce((acc,fcst) => acc + fcst.yardsExpected, 0)

            const monthList = Array.isArray(item.yards) ? monthYards(item.yards) : []

            const customer = item._id.customerName ? item._id.customerName : item._id.customer

            const monthListing = monthList.map((ml, i) =>
                <td className="border" onClick={((e) => ForecastEdit(formatDate(i), customer, ml.yards, ml._id))} key={i + 2} >
                    {ml.yards === 0 ? "" : ml.yards}
                </td>
            )

            return (
                <tr key={i}>
                    <td> {i + 1} </td>
                    <td> {customer} </td>
                    {monthListing}
                    <td className="border" style={{ fontWeight: "bold", textAlign: "right"}}> 
                        {toThousands(sumRow)} 
                    </td>
                    <td>
                        <button
                            className='btn btn-danger' 
                            onClick={(e) => {if (window.confirm('Are you sure you want to delete?')) delForecast(item._id.customer)}}
                        >
                            -
                        </button>
                    </td>
                </tr>
            )
        })
    }

    const RenderForecasts = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Clients</td>
                        <td>JAN</td>
                        <td>FEB</td>
                        <td>MAR</td>
                        <td>APR</td>
                        <td>MAY</td>
                        <td>JUN</td>
                        <td>JUL</td>
                        <td>AUG</td>
                        <td>SEP</td>
                        <td>OCT</td>
                        <td>NOV</td>
                        <td>DEC</td>
                        <td style={{ textAlign: "right"}}>SUM</td>
                    </tr>
                </thead>
                <tbody>
                    {sellerForecasts.current && <DrawList />}
                </tbody>
            </table>
        </div>        
    )

    return (
        <Layout
            title={titleLabel}
            description='Forecast List'
            className='container col-md-10 '
        >
            <div>
                <br />
                <button className='button btn-primary' onClick={(e) => setForecastModalShow(!forecastModalShow)}>
                    New forecast +
                </button>
                <Modal show={forecastModalShow} animation={false} onHide={(e) => setForecastModalShow(!forecastModalShow)}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Forecast</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateForecastModal parentCallback={modalCallback} seller={sellerID} sellerName={sellerName} />
                    </Modal.Body>
                </Modal>

                <Modal show={editModalShow} animation={false} onHide={(e) => setEditModalShow(!editModalShow)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditForecastModal parentCallback={modalCallback} seller={sellerID} customer={editCustomer.current} yards={editYards.current} period={editPeriod.current} forecastID={editForecast.current} />
                    </Modal.Body>
                </Modal>
            </div>
            {
                loading
                    ? <Spinner />
                    : sellerForecasts.current.length > 0
                        ? Array.isArray(forecasts[0].yards)
                            ? <RenderForecasts />
                            : <Spinner />
                        : <h3 className="justify-content-center"> No forecast available for {sellerName} </h3>
                //loading ? <Spinner />
                //: sellerForecasts.current.length > 0 ? <RenderForecasts /> : `No forecast available for ${sellerName}`
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    forecast: state.forecast
})

export default connect(mapStateToProps, { getForecastGroup, deleteForecast })(ForecastBySeller)