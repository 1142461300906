import { Button, Col, Form, Input, Row, notification } from 'antd'
import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'

const NewLogisticsTruck = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const createTruck = async (data) => {
        const truck = {
            truckLicensePlates: data.truckLicensePlates,
            truckVinNumber: data.truckVinNumber,
            trailerLicensePlates: data.trailerLicensePlates,
            trailerVinNumber: data.trailerVinNumber,
            number: data.number,
        }

        try {
            let url = api + '/logisticstruck/create' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, truck)
            openNotification('success', 'Success', 'Truck created')
            setLoading(false)
            history.push('/logisticstrucklist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create truck')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        createTruck(values)
    }


    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Truck Number'
                    name='number'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill truck number'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Truck Plates'
                    name='truckLicensePlates'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill truck plates'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Truck VIN'
                    name='truckVinNumber'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill truck vin'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Trailer Plates'
                    name='trailerLicensePlates'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Trailer VIN'
                    name='trailerVinNumber'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Logistics Truck'
        description='Create new Logistics Truck'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default NewLogisticsTruck