import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_FORECAST,
    GET_FORECAST,
    GET_FORECASTS,
    UPDATE_FORECAST,
    DELETE_FORECAST,
    FORECAST_ERROR,
    BATCH_DELETE
} from './types'

// create new forecast
export const createForecast = (formData) => async (dispatch) => {
    try {
        const { data } = await api.post('/forecast', formData)

        dispatch({
            type: ADD_FORECAST,
            payload: data
        })

        dispatch(setAlert('Added', 'success'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FORECAST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get list of all forecasts
export const getForecastGroup = () => async (dispatch) => {
    try {
        const { data } = await api.get(`/forecastgroup`)

        dispatch({
            type: GET_FORECASTS,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FORECAST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get forecast using period and seller
export const getForecastByPeriodAndSeller = (period, seller) => async (dispatch) => {
    try {
        const { data } = await api.get(`/forecast?period=${period}&seller=${seller}`)

        console.log(data)

        dispatch({
            type: GET_FORECASTS,
            payload: data
        })

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FORECAST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// update a forecast
export const updateForecast = (id, formData) => async (dispatch) => {
    try {
        const { data } = await api.patch(`/forecast/${id}`, formData)

        console.log(data)

        dispatch({
            type: UPDATE_FORECAST,
            payload: data
        })

        dispatch(setAlert('Updated', 'success'))

    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FORECAST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

export const forecastBySeller = (addCustomer) => async (dispatch) => {
    try {
        const { data } = await api.get(`/groupforecast?customer=false`)
        dispatch({
            type: GET_FORECASTS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FORECAST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// delete forecast for customer
export const deleteForecast = custId => async (dispatch) => {
    try {         
        const { data } = await api.delete(`/deleteforecast/${custId}`)
        console.log(data)
        dispatch({
            type: BATCH_DELETE,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: FORECAST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}