import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'

const Credito = props => (
    <tr>        
        <td><Link to={'/credit/'+props.credit._id}>{props.credit.legalCompanyName}</Link></td> 
        <td> {props.credit.businessType} </td>
        <td> {props.credit.address} {props.credit.city}  {props.credit.county} {props.credit.state} {props.credit.zip} 
        </td>
    </tr>
)


export default class CreditList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            creditos: [],
            loading: true
        }
    }

    getCredits = async () => {
        const url = api + '/creditlist' 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)

            this.setState({
                loading: false,
                creditos: data
            })       

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    getMyCredits = async (usr) => {
        const url = api + '/mycompany/' + usr
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const res = await axios.get(url)
            const id = res.data._id

            const urlCredit = api + '/mycreditlist/' + id
            const { data } = await axios.get(urlCredit)
            console.log(data)

            this.setState({
                loading: false,
                creditos: data
            })       

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        const role = localStorage.getItem('role')
        if (role === 'Customer') {
            const userId = localStorage.getItem('userId')
            this.getMyCredits(userId)
        } else {
            console.log('data para vendedor/admin')
            this.getCredits()
        }        
    }

    goCreateApplication = () => {              
        this.props.history.push('/credit')  
    }

    losCreditos() {
        return this.state.creditos.map((currentCredit, i) => {
            return <Credito credit={currentCredit} key={i} />
        })
    }

    renderLoading() {
        return <Spinner />
    }

    renderCredits() {
        return (
            <Fragment>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Business Type</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.losCreditos() }
                    </tbody>
                </table>
                <button className='btn btn-primary' onClick={this.goCreateApplication}>New Credit Application</button>
            </Fragment>
        )
    }

    render() {
        return (
            <Layout
                title='Credit Application'
                description='Credit Application list'
            >                   
                {
                    this.state.loading ?
                    this.renderLoading()
                    : this.renderCredits()
                }
            </Layout>
        )
    }
}