const rolePouredOptions = [
    {value: 'Admin', label: 'Admin'},
    {value: 'QC', label: 'QC'},
]

const rolePouredOptions2 = [
    {value: 'Admin', label: 'Admin'},
    {value: 'Seller', label: 'Seller'},
]

export {
    rolePouredOptions,
    rolePouredOptions2
}