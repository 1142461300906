import React, { Component, Fragment } from 'react'
import {  
    Document, 
    PDFViewer,     
    Page,  
    View,
    Image,
    Text,   
    StyleSheet } from '@react-pdf/renderer'
import axios from 'axios'
import { api } from '../utils/Api'

const styles = StyleSheet.create({
    page: {
      padding: 4,
      margin: 4,
      flexDirection: 'column'
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      '@media max-width: 360': {
        flexDirection: 'column',
      },
    },
    renglon: {     
        flexDirection: 'row',
        width: '100%'
      },
    formTitle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 20,
      textAlign: 'center',
    },
    dateStyle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 16,
      textAlign: 'center'
    },
    infoStyle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 16,
      textAlign: 'center',
      backgroundColor: '#2a4a8b',
      color: '#ffffff',
      padding: 12,
      borderStyle: 'solid'
    },
    renglonStyle: {
      flexDirection: 'row',
      width: '100%'
    },
    textTitles: {
      fontFamily: 'Helvetica',
      fontSize: 14,
      borderStyle: 'solid',
      borderWidth: 2,
      width: '50%',      
      paddingLeft: 10,
      paddingTop: 40,
      paddingBottom: 5,
    },
    text3col: {
      fontFamily: 'Helvetica',
      fontSize: 14,
      borderStyle: 'solid',
      borderWidth: 2,
      width: '33.33%',
      paddingLeft: 10,
      paddingTop: 40,
      paddingBottom: 10,
    },
    textAddress: {
      fontFamily: 'Helvetica',
      fontSize: 14,
      borderStyle: 'solid',
      borderWidth: 2,
      width: '100%',      
      paddingLeft: 10,
      paddingTop: 40,
      paddingBottom: 5,
    },
    textHeader: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 16,
      textAlign: 'center',
      backgroundColor: '#2a4a8b',
      color: '#ffffff',
      padding: 12      
    },
    centerImage: {
      marginLeft: 210,
      marginTop: 100
    },
    image: {
        width:'40%',
        padding: 5        
    }
  })

  export default class ClientForm extends Component {
    constructor (props) {
      super(props)
  
      this.state = {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        contactPhone: '',
        contactFax: '',
        clientId: '',
        seller: '',
        registrationDate: '',
        contactName: '', 
        contactEmail: '',
        accountsPayableContact: '',
        accountsPayableEmail: '',
        accountsPayablePhone: '',
        accountsPayableFax: ''   
      }
    }

    getClient = async () => {              
        const url = api + '/customer/' + this.props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)

            const fecha = new Date(data.createdAt)
            const dia = fecha.getDate()
            const mes = fecha.getMonth() + 1
            const anio = fecha.getFullYear()

            this.setState({
                name: data.name,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                contactPhone: data.contactPhone,
                contactFax: data.contactFax,
                clientId: this.props.match.params,
                loading: false,
                role: localStorage.getItem('role'),
                registrationDate: `${mes}-${dia}-${anio}`,
                contactName: data.contact.name,
                contactEmail: data.contact.email,
                accountsPayableContact: data.accountsPayableContact,
                accountsPayableEmail: data.accountsPayableEmail,
                accountsPayablePhone: data.accountsPayablePhone,
                accountsPayableFax: data.accountsPayableFax
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {        
        this.getClient()
    }

    render() {
        const ClientPrint = () => (
            <Document
                author='57concrete'
                keywords='57concrete'
                subject='57concrete Client Form'
                title='Client Form'
            >
                <Page
                    size='A4'
                    wrap={false}
                    style={styles.page}
                >    
                  <View style={styles.centerImage}>
                    <Image    
                      style={styles.image}                          
                      src={process.env.PUBLIC_URL + '/images/concrete57.png'}               
                    /> 
                  </View>                                               
                  <View style={styles.formTitle}>
                      <Text>New Customer Information Form</Text>
                  </View>  
                  <View style={styles.dateStyle}>
                    <Text>Date: {this.state.registrationDate}</Text>
                  </View>   
                  <View style={styles.infoStyle}>
                    <Text>Company Information</Text>
                  </View>     
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textTitles}>Company Name: {this.state.name}</Text>
                    <Text style={styles.textTitles}>Sales Associate: </Text>
                  </View>  
                  <View>
                    <Text style={styles.textAddress}>Address: {this.state.address}</Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.text3col}>City: {this.state.city}</Text>
                    <Text style={styles.text3col}>State: {this.state.state}</Text>
                    <Text style={styles.text3col}>ZIP code: {this.state.zip}</Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textTitles}>Contact Person: {this.state.contactName}</Text>
                    <Text style={styles.textTitles}>Email: {this.state.contactEmail}</Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textTitles}>Phone: {this.state.contactPhone}</Text>
                    <Text style={styles.textTitles}>Fax: </Text>
                  </View>
                  <View>
                      <Text style={styles.textHeader} >A/P Contact Information</Text>
                  </View>   
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textTitles}>Contact Person: {this.state.accountsPayableContact}</Text>
                    <Text style={styles.textTitles}>Email: {this.state.accountsPayableEmail} </Text>
                  </View> 
                  <View style={styles.renglonStyle}>
                    <Text style={styles.textTitles}>Phone: {this.state.accountsPayablePhone}</Text>
                    <Text style={styles.textTitles}>Fax: {this.state.accountsPayableFax} </Text>
                  </View>                                                    
                </Page>                
            </Document>
        )

        return (
            <Fragment>
                <PDFViewer width="90%" style={{ height: '90vh' }}>
                  <ClientPrint />
                </PDFViewer>
            </Fragment>
        )
    }
}