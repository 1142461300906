import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toCurrency } from '../utils/Convert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { api } from '../utils/Api'
import Modal from 'react-bootstrap/Modal'
import PaymentJob from '../job/PaymentJob'

const JobsPendingItem = ({ job, taxes }) => {
    const [paid, setPaid] = useState(job.paid)
    const [dateStamp, setDateStamp] = useState(job.paidTime)
    const [deposit, setDeposit] = useState(job.deposit ? parseInt(job.deposit) : 0)
    const [unitPrice, setUnitPrice] = useState(job.unitPrice)
    const [showModal, setShowModal] = useState(false)
    const [pendingBalance, setPendingBalance] = useState(job.pendingBalance)
    const [taxAmount, setTaxAmount] = useState(job.taxAmount)
    const [update, setUpdate] = useState(false)
    const [taxType, setTaxType] = useState('')

    useEffect(() => {
        getInvoiceExistance()
    }, [])

    const showDate = fecha => {
        let jobDate = 'NA'
        if (fecha) {
            jobDate = new Date(fecha).toLocaleDateString() + ' at ' + new Date(fecha).toLocaleTimeString([], { timeStyle: 'short' })
        }
        return jobDate
    }

    const paymentsUpdated = (saldo, taxAmount, unitPrice, taxType, paid, paidTime) => {
        setShowModal(false)
        setPendingBalance(saldo)
        setUnitPrice(unitPrice)
        setTaxAmount(taxAmount)
        setTaxType(taxType)
        setPaid(paid)
        if(paid) setDateStamp(paidTime)
        setUpdate(!update)
    }

    const getInvoiceExistance = async () => {
        const url = api + '/jobinvoice/' + job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const { data } = await axios.get(url)
            calculateTaxes(data)
        } catch (err) {
            calculateTaxesNoInvoice()
            return null
        }
    }

    const calculateTaxesNoInvoice = () => {
        //state = 0.0825
        let taxPercentaje = taxes['state']
        setTaxType('State')

        let yards = job.loadYD3 > 0 ? job.loadYD3 : job.targetYD3
        const material = job.unitPrice * yards
        const subtotal = material 
        const taxas = subtotal * taxPercentaje
        const total = subtotal + taxas
        if(typeof job.taxAmount === 'undefined'){
            setTaxAmount(taxas)
        }else{
            setTaxAmount(job.taxAmount)
        }

        if (typeof job.pendingBalance === 'undefined') {
            setPendingBalance(total)
        } else {
            setPendingBalance(job.pendingBalance)
        }
    }

    const calculateTaxes = (invoice) => {
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = taxes['state']
                setTaxType('State')
                break
            case 'county':
                taxPercentaje = taxes['county']
                setTaxType('County')
                break
            case 'exempt':
                taxPercentaje = taxes['exempt']
                setTaxType('Exempt')
                break
            default:
                taxPercentaje = taxes['state']
                setTaxType('Federal')
        }

        let yards = job.loadYD3 > 0 ? job.loadYD3 : job.targetYD3
        const material = job.unitPrice * yards
        const trips = Math.ceil(yards/10)
        const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
        const delivery = invoice.shippingFee || job.deliveryFee      
        const color = invoice.color ? invoice.colorTicket : 0
        const fiber = invoice.fiber ? invoice.fiberBags : 0
        const size = invoice.size ? invoice.sizeBags : 0
        const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
        const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
        const taxas = subtotal * taxPercentaje
        const total = subtotal + taxas
        if(typeof job.taxAmount === 'undefined'){
            setTaxAmount(taxas)
        }else{
            setTaxAmount(job.taxAmount)
        }

        if (typeof job.pendingBalance === 'undefined') {
            setPendingBalance(total)
        } else {
            setPendingBalance(job.pendingBalance)
        }
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    return (
        <tr key={job._id}>
            <td>
                {job.number}
            </td>
            <td>
                {job.customer.customerNumber + ' ' + job.customer.name}
            </td>
            <td>
                {new Date(job.scheduledDate).toLocaleDateString()}
            </td>
            <td>
                {new Date(job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}
            </td>
            <td>{job.shipAddress}</td>
            <td style={{ textAlign: "right" }} >{job.targetYD3} </td>
            <td>
                <input
                    type="number"
                    name="unitPrice"
                    placeholder={toCurrency(unitPrice, 3)}
                    onChange={(e) => setUnitPrice(e.target.value)}
                    disabled
                />
            </td>
            <td>
                {taxType}
            </td>
            <td style={{ textAlign: "right" }} >{toCurrency(taxAmount, 3)} </td>
            <td>
                <button onClick={toggleModal} className='btn btn-primary'>
                    Apply
                </button>

                <Modal
                    show={showModal}
                    animation={false}
                    onHide={toggleModal}
                    size='lg'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Job Payments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PaymentJob jobId={job._id} pcb={paymentsUpdated} />
                    </Modal.Body>
                </Modal>
            </td>
            <td>
                {
                    deposit > 0 ? <FontAwesomeIcon icon={faCheckSquare} /> : null
                }
            </td>
            <td style={{ textAlign: "right" }} > {toCurrency(pendingBalance, 2)} </td>
            <td>
                {paid ? showDate(dateStamp) : "NA"}
            </td>
        </tr>
    )
}

export default (JobsPendingItem)