import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../utils/Api'

export default class PlantPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: true,
            isSearchable: true,
            options: [],
        }
    }

    getPlant = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            this.setState({
                options: data
            })
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getPlant()
    }

    render() {
        const options = this.state.options.map((item) => {
            return {
                value: item._id,
                label: item.name
            }
        })

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Plant...'
                    // value={this.state.value}
                    value={options.filter((o) => { return o.value === this.props.value })}
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
                {
                    this.props.isRequired &&
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={this.props.value}
                        required
                    />
                }
            </div>
        )
    } 
}
