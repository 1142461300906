import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Statistic, Row, Col, Button } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import { CSVLink } from 'react-csv'
const { Text } = Typography

const columnsCSV = [
    { key: 'plant', label: 'Plant' },
    { key: 'averageTime', label: 'Average Time' }
]

const ReportAverageTimeEticket = () => { 
    
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [list, setList] = useState([])
    const refDownload = useRef()

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Average Time',
            dataIndex: 'averageTime',
            key: 'averageTime',
            render: (value) => {
                return <Statistic title="" value={value} precision={0} valueStyle={{fontSize: 14}} />
            }
        }
    ]

    useEffect(() => {
        getLogisticsList()
    }, [startDate, endDate])
    
    const getLogisticsList = async() => {
        setLoading(true)

        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/job/eticket/averagetime?startDate=${start}&endDate=${end}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let list = []
            
            data.map(item => {
                let sumTime = 0
                item.values.map(val => {
                    let time = val.destinationTime ? parseInt(val.destinationTime.split(" ")[0]) : val.destinationMinutes
                    sumTime += time
                })
                list.push({
                    key: item._id,
                    id: item._id,
                    plant: item.plant_details.name,
                    averageTime: Math.ceil(sumTime/item.count)
                })
            })

            setList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            <CustomTable 
            columns={columns}
            data={list}
            isScroll={false}
            />
        </Flex>
    }

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const RenderDates = () => {
        return <CustomSearch 
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        onRangeChange={onRangeChange}
        />
    }

    const RenderDownloadReport = () => {
        return <div>
            <CSVLink 
            data={list} 
            headers={columnsCSV} 
            filename={"57concrete-eticket-averagetime.csv"}
            hidden
            ref={refDownload}
            >
                Download report
            </CSVLink>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type='primary' style={{width: '100%'}} onClick={e => refDownload.current.link.click()} loading={loading}>Download Report</Button>
                </Col>
            </Row>
        </div>
    }

    return (
        <Layout
            title='Average Time Per Plant'
            description='Eticket Report'
            type='large'
        >
            <Flex vertical gap={'large'}>
                <RenderDates />
                <RenderDownloadReport />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default ReportAverageTimeEticket