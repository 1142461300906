import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { faHardHat, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import JobInspectItem from './JobInspectItem'
import { getFutureJobs } from '../../actions/job'
import { jobIdFilter } from '../utils/JobIdFilter'
import Spinner from '../layout/Spinner'

const JobInspection = ({getFutureJobs, job: {jobs, loading}}) => {
    const [change, setChange] = useState(false)
    const [total, setTotal] = useState(0)
    const [totalInspect, setTotalInspect] = useState(0)
    const [jobID, setJobID] = useState("")
    const [showPassed, setShowPassed] = useState(false)

    useEffect(() => {
        getFutureJobs()
        sumas()
    }, [change])

    const sumas = () => {
        if (jobs) {
            let jobsFiltered = jobs.filter((jb) => jb.requireInspection !== false) // Sum of jobs that require inspection
            
            const sumt = jobs.reduce((acc, job) => acc + (typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3) : job.targetYD3), 0)
            const sumi = jobs.filter(job => job.inspectionPassed === true)
                                            .reduce((acc,jb) => acc + (typeof jb.targetYD3 === "string" ? parseFloat(jb.targetYD3) : jb.targetYD3), 0)
            //console.log(sumt )
            //console.log(sumi)
            setTotal(sumt)
            setTotalInspect(sumi)
        }        
    }

    const handleCallback = valor => {
        //console.log('haciendo el callback')
        //console.log(valor)
        setChange(!change)
        return <Spinner />
    }

    const DrawList = () => {
        let jobsFiltered = jobIdFilter(jobID, jobs)
        
        jobsFiltered = jobsFiltered.filter((jb) => jb.requireInspection !== false) // Only show jobs that require inspection

        showPassed === false ? jobsFiltered = jobsFiltered.filter((jb) => jb.inspectionPassed !== true)
            : jobsFiltered = jobsFiltered.filter((jb) => jb.inspectionPassed === true)
        
        //console.log("Jobs: ")
        //console.log(jobsFiltered)
        return jobsFiltered.map((job) => {
            return <JobInspectItem key={job._id} job={job} parentCallBack={handleCallback} />
        })
    }

    const changePassed = () => {
        setShowPassed(!showPassed)
        setChange(!change)
    }

    const RenderJobs = () => (
        <div>
            <div className='row' >
                <div className="col-md-6">
                    <div className='card text-center bg-warning text-white mb-2'>
                        <div className="card-body">
                            <h3>Total Yards</h3>
                            <h4 className="display-4">
                                <FontAwesomeIcon icon={faHardHat} /> {total}
                            </h4>                                        
                        </div>    
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='card text-center bg-success text-white mb-2' onClick={changePassed}>
                        <div className='card-body'>
                            <h3>Total Inspected Yards</h3>
                            <h4 className='display-4'>
                                <FontAwesomeIcon icon={faThumbsUp} /> {totalInspect}                            
                            </h4>
                        </div>
                    </div>
                </div>                                
            </div>
            <div style={{fontSize: '13px'}}> 
            <table className='table'>
                <thead>
                    <tr>
                        <th>JobID</th>  
                        <th>Customer</th>  
                        <th>Order Date</th>
                        <th>Request Time</th>                                                                                    
                        <th>Ship to Address</th>
                        <th>Load YD3</th>
                        <th>Target YD3</th>
                        <th>Dest. time</th>   
                        <th>Job Type</th>                                   
                        <th>PSI</th>
                        <th>Seller</th>
                        <th>Inspection Date</th>
                        <th>Inspection Passed</th>
                        <th>Passed Date</th>    
                        <th>Cancellation</th>
                        <th>Save</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList  />
                </tbody>
            </table>
            </div>
        </div>        
    )

    return(
        <Layout
            title='Jobs'
            description='Job Inspection'
        >
        <div className='col-sm-4'>
                <label className='text-muted'>JobId</label>
                <input
                    type="text"
                    value={jobID}
                    onChange={(e) => setJobID(e.target.value)}
                />
            </div>
            <br />    
            {
                (loading ? <Spinner /> : (jobs ? <RenderJobs /> : <h4>No Jobs </h4>))
            }

        </Layout>
    )
}

const mapStateToProps = state => ({    
    job: state.job
})

export default connect(mapStateToProps, {getFutureJobs})(JobInspection)
