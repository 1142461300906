import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { updateJob } from '../../actions/job'
import axios from 'axios'
// import { sendSms } from '../utils/Sms'
import { api } from '../utils/Api'

const JobAppointmentItem = ({ job, updateJob, parentCallBack }) => {

    const [scheduledDate, setScheduledDate] = useState(job.scheduledDate)
    const [appDate, setAppDate] = useState('')
    const [notifyDate, setNotifyDate] = useState(job.notifyTimeStamp ? job.notifyTimeStamp : '')


    const notify = () => {

        setNotifyDate(new Date())

        const msg = `Job Id #${job.number}, customer: ${job.customer.customerNumber} ${job.customer.name} total Yards: ${typeof job.targetYD3 === "string" ? job.targetYD3 : job.targetYD3.toString()}. Scheduled at ${new Date(job.firstShipmentDate).toLocaleDateString()} ${new Date(job.firstShipmentDate).toLocaleTimeString([], { timeStyle: 'short' })} \n #57concrete`

        // Send SMS
        try {
            if (job.customer) {
                sendSms(job.customer.contactPhone, msg)
            }
            if (job.foremanPhone) {
                sendSms(job.foremanPhone, msg)
            }
            if (job.pmPhone) {
                sendSms(job.pmPhone, msg)
            }
            if (job.supervisorPhone) {
                sendSms(job.supervisorPhone, msg)
            }
            if (job.seller.phone) {
                sendSms(job.seller.phone, msg)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const showDate = (fecha) => {
        let date = fecha !== '' ? `${new Date(fecha).toLocaleDateString()} at ${new Date(fecha).toLocaleTimeString([], { timeStyle: 'short' })}` : "NA"
        return date
    }

    const sendSms = async (phone, msg) => {
        const url = api + '/smsmessagetxt'

        if (!phone) {
            return null
        }

        const smsBody = { phone, msg }

        try {
            await axios.post(url, smsBody)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }


    const onDateChange = (date) => {
        setAppDate(date)
        setScheduledDate(date)
    }


    const closeJob = () => {
        const newData = {
            jobStatus: "ok",
            closeAppointment: true,
        }
        updateJob(job._id, newData)
        parentCallBack(`Edited job: ${job.number}`)
    }

    const saveAppointment = () => {

        const today = new Date()

        const newData = {
            hasAppointment: true,
            firstShipmentDate: appDate,
            notifyTimeStamp: today,
            scheduledDate,
        }

        console.log(newData)
        updateJob(job._id, newData)
        parentCallBack(`Edited job: ${job.number}`)
    }

    return (
        <tr key={job._id}>
            <td>
                <Link to={'/job/' + job._id}>
                    {job.number}
                </Link>
            </td>
            <td>
                {job.customer.customerNumber + ' ' + job.customer.name}
            </td>
            <td>
                {job.shipAddress}
            </td>
            <td>
                {`${new Date(scheduledDate).toLocaleDateString()} at ${new Date(scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })} `}
            </td>
            <td style={{ textAlign: "right" }}>
                {job.targetYD3}
            </td>
            <td>
                {job.psi}
            </td>
            <td>
                {job.jobType ? job.jobType : "NA"}
            </td>
            <td>
                {job.seller ? job.seller.name : "NA"}
            </td>
            <td>
                {
                    job.hasAppointment ?
                        `${new Date(job.firstShipmentDate).toLocaleDateString()} at ${new Date(job.firstShipmentDate).toLocaleTimeString([], { timeStyle: 'short' })} ` :
                        'Not yet'
                }
                <br />
                <DatePicker
                    showTimeSelect
                    selected={appDate}
                    onChange={(date) =>
                        onDateChange(date)
                    }
                />
            </td>
            {
                job.hasAppointment &&
                <td>
                    <button className={notifyDate === "" ? 'btn btn-primary' : 'btn btn-warning'} onClick={(e) => notify()}>
                        Notify
                    </button>
                </td>
            }
            {
                job.hasAppointment &&
                <td> {showDate(notifyDate)} </td>
            }
            <td>
                <button className='btn btn-success' onClick={(e) => saveAppointment()}>
                    Save
                </button>
            </td>
            {
                job.hasAppointment &&
                <td>
                    <button className='btn btn-danger' onClick={(e) => closeJob()}>
                        Close
                    </button>
                </td>
            }
        </tr>
    )
}

export default connect(null, { updateJob })(JobAppointmentItem)
