import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { invoicesForCustomer } from '../../actions/invoice'
import Spinner from '../layout/Spinner'
import { Link } from 'react-router-dom'
import { toCurrency } from '../utils/Convert'

const ClientInvoices = ({match, invoices, loading, invoicesForCustomer}) => {

    useEffect(() => {   
        invoicesForCustomer(match.params.id)
    }, [])
    
    const DrawPaymentsRegistered = ({payments}) => {
        if (payments.length === 0) {
            return <tr>
                <td>No payments</td>
            </tr>
        } else {
            return payments.map(pago => {
                return <tr>
                    <td> {pago.type}: </td>
                    <td style={{ textAlign: "right" }}> {toCurrency(pago.amount,2)} </td>                    
                </tr>
            })
        }      
    }
    
    const DrawList = () => {
        return invoices.map(invoice => {
            console.log(invoice)
            return <tr key={invoice._id}>
                <td>
                    <Link to={`/invoicepdf/${invoice._id}`}>
                        {invoice.invoiceNumber}
                    </Link>                    
                </td>
                <td> {invoice.invoiceCustomerName} </td>
                <td>
                    { new Date(invoice.invoiceDate).toLocaleDateString() }
                </td>
                <td>{invoice.psi} </td>
                <td>{invoice.quantity} </td>
                <td style={{ textAlign: "right" }}>
                    {toCurrency(invoice.amount, 2)} 
                </td>
                <td style={{ textAlign: "right" }}>
                    {toCurrency(invoice.taxAmount, 2)} 
                </td>
                <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    {toCurrency(invoice.invoiceTotal, 2)} 
                </td>
                <td style={{ textAlign: "right" }}>
                    {toCurrency(invoice.balance,2)}
                </td>
                <td>
                    <DrawPaymentsRegistered payments={invoice.payments} />
                </td>
            </tr>
        })
    }

    const InvoiceTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Invoice Number</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>PSI</th>
                        <th>Yards</th>
                        <th style={{ textAlign: "right" }}>Subtotal</th>
                        <th style={{ textAlign: "right" }}>Tax</th>
                        <th style={{ textAlign: "right" }}>Total</th>
                        <th>Balance</th>
                        <th>Payments</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
    )

    return (
        <Layout
            title='Invoices'
            description='Invoice List'
            className='container'
        >
            {
                loading ? <Spinner /> : <InvoiceTable />
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    invoices: state.invoice.invoices,
    loading: state.invoice.loading
})

export default connect(mapStateToProps, {invoicesForCustomer}) (ClientInvoices)