import React, { useState, useEffect } from 'react'
import DriverPicker from '../driver/DriverPicker'
import { connect } from 'react-redux'
import { getJobLoads } from '../../actions/load'
import { getDriverList } from '../../actions/driver'
import Spinner from '../layout/Spinner'

const Load = ({job, getJobLoads, getDriverList, load: {loads, sumYards, loading}, driver: {drivers}}) => {
    console.log(job)
    console.log(loading)
    const [loadChange, setLoadChange] = useState('')

    useEffect(() => {
        getJobLoads(job._id)
        
        if (drivers) {
            console.log('ya tengo drivers')
        } else {
            getDriverList()
        }
        // sumYards()        
    }, [loadChange])

    const DrawLoad = () => {
        if (loads.length > 0) {            
            return loads.map((ld) => {
                return <tr key={ld._id}>
                    <td>{ld.driver.toUpperCase()}</td>
                    <td>{ld.truckNumber} </td>
                    <td>{ld.yards}</td>
                    <td>{ld.deliveryTicket} </td>
                    <td> {ld.orderCode} </td>
                    <td><button className='btn btn-danger' onClick={() => console.log('borrar load')}>-</button></td>
                </tr>
            })
        } else {
            return (
                <tr>
                    <td colSpan="6" style={{'textAlign':'center'}}>
                        No load yet
                    </td>
                </tr>
            )
        }
    }

    const RenderTable = () => (
        <div>
            Load Total = {sumYards}
            <table className='table'>
                <thead>
                    <tr>
                        <th>Driver</th>
                        <th>Truck</th>
                        <th>Load</th>  
                        <th>Ticket</th>
                        <th>OrderCode</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawLoad />                   
                </tbody>
            </table>
        </div>
    )
    
    return (
        <RenderTable />
    )
}

const mapStateToProps = state => ({    
    load: state.load,
    driver: state.driver
})

export default connect(mapStateToProps, {getJobLoads, getDriverList}) (Load)



