import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logout } from '../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import NavDropDown from 'react-bootstrap/NavDropdown'
import NavItem from 'react-bootstrap/NavItem'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import DropdownMenu from 'react-overlays/esm/DropdownMenu'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DropdownButton from 'react-bootstrap/DropdownButton'

const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return { color: '#ff9900' }
    } else {
        return { color: '#ffffff' }
    }
}

// la historia se hereda de los props
const Menu = ({ history, auth: { isAuthenticated, loading }, logout }) => {

    const handleSelect = (e) => {
        switch (e) {
            case 'job':
                history.push('/reportjob')
                break;
            case 'load':
                history.push('/reportload');
                break;
            case 'report_pay_driver':
                history.push('/reportpaymentdriver')
                break;
            case 'clock':
                history.push('/clockreport')
                break;
            case 'trips':
                history.push('/reporttrips')
                break;
            case 'newjob':
                history.push('/newreport')
                break;
            case 'reportlist':
                history.push('/reportlist')
                break;
            case 'capacityreport':
                history.push('/capacity')
                break;
            case 'availablereport':
                history.push('/availability')
                break;
            case 'truckefficiency':
                history.push('/truckefficiency')
                break;
            case 'dashboard':
                history.push('/dashboard')
                break;
            case 'trucktrips':
                history.push('/reporttrucktrips')
                break;
            case 'truckyards':
                history.push('/reporttruckyards')
                break;
            case 'etickets':
                history.push('/reportetickets')
                break;
            case 'inspection':
                history.push('/reportinspections')
                break;
            case 'inspectiondefects':
                history.push('/inspectiondefects')
                break;
            case 'qualitycontrol':
                history.push('/reportqualitycontrol')
                break;
            case 'history':
                history.push('/historyreport')
                break;
            case 'failures':
                history.push('/reportfailures')
                break;
            default:
                break;
        }
    }

    const handleFinance = (e) => {
        switch (e) {
            case 'referral':
                history.push('/referrallist')
                break
            case 'quote':
                history.push('/quotenew')
                break
            case 'credit':
                history.push('/creditlist')
                break
            case 'newjob':
                history.push('/jobnuevo')
                break
            case 'newquote':
                history.push('/newquote')
                break;
            case 'quotelist':
                history.push('/quotelisting')
                break
            case 'dailysales':
                history.push('/dailysales')
                break
            case 'periodsales':
                history.push('/periodsales')
                break
            case 'clientwithinvoice':
                history.push('/clientwithinvoice')
                break
            case 'paymentreport':
                history.push('/paymentreport')
                break;
            case 'mixdesignreport':
                history.push('/mixdesignreport')
                break;
            case 'salesbyseller':
                history.push('/salesbyseller')
                break;
            default:
                break
        }
    }
    const handleSales = (e) => {
        switch (e) {
            case 'salesdashboard':
                history.push('/salesdashboard')
                break;
            case 'quadrants':
                history.push('/quadrants')
                break;
            case 'topcustomers':
                history.push('/topcustomers')
                break;
            case 'toplocalities':
                history.push('/toplocalities')
                break;
            case 'topforeman':
                history.push('/topforeman')
                break;
            case 'reportcustomersales':
                history.push('/reportcustomersales')
                break;
            case 'opportunitystatus':
                history.push('/opportunitystatus')
                break;
            case 'listprice':
                history.push('/listprice')
                break;
            case 'clientlist':
                history.push('/clientlist')
                break;
            case 'bulksms':
                history.push('/bulksms')
                break;
            default:
                break
        }
    }

    const handleRH = (e) => {
        switch (e) {
            case 'employees':
                history.push('/employees')
                break;
            case 'departments':
                history.push('/departments')
                break;
            case 'positions':
                history.push('/positions')
                break;
            case 'emailsvalidation':
                history.push('/emailsvalidation')
                break;
            default:
                break
        }
    }

    return (
        <div>
            <ul className="nav nav-tabs bg-primary">
                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Seller' ||
                        localStorage.getItem('role') === 'Finance')
                    &&
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, '/dashboardmanagement')}
                            to='/dashboardmanagement'
                        >
                            Home
                        </Link>
                    </li>
                }


                {
                    localStorage.getItem('role') !== 'Batch' &&
                    <DropdownButton
                        title="Sales"
                        id="dropdown-menu-align-right"
                        onSelect={handleSales}
                    >
                        {
                            (localStorage.getItem('role') === 'Admin' ||
                            localStorage.getItem('role') === 'Seller' ||
                            localStorage.getItem('role') === 'Finance') && 
                            <DropdownItem eventKey="clientlist">Customers</DropdownItem>
                        }
                        {
                            (localStorage.getItem('role') === 'Admin' ||
                            localStorage.getItem('role') === 'Finance') &&
                            <DropdownItem eventKey="bulksms">SMS</DropdownItem>
                        }
                        <Dropdown.Divider />
                        <DropdownItem eventKey="salesdashboard">Sales Dashboard</DropdownItem>
                        <DropdownItem eventKey="opportunitystatus">Opportunity Status</DropdownItem>
                        <DropdownItem eventKey="quadrants">Quadrants</DropdownItem>
                        <Dropdown.Divider />
                        <DropdownItem eventKey="topcustomers">Top Customers</DropdownItem>
                        <DropdownItem eventKey="toplocalities">Top Localities</DropdownItem>
                        <DropdownItem eventKey="topforeman">Top Foreman</DropdownItem>
                        <Dropdown.Divider />
                        <DropdownItem eventKey="reportcustomersales">Report Customer Sales</DropdownItem>
                        <Dropdown.Divider />
                        <DropdownItem eventKey="listprice">List Price</DropdownItem>
                    </DropdownButton>
                }

                {
                    localStorage.getItem('role') !== 'Batch' &&
                    <DropdownButton
                        title="Job"
                        id="dropdown-menu-align-right"
                        onSelect={handleFinance}
                    >
                        <DropdownItem eventKey="newjob">New Job</DropdownItem>
                        <DropdownItem eventKey="quote">New In Call</DropdownItem>
                        <DropdownItem eventKey="newquote">New Quote</DropdownItem>
                        <DropdownItem eventKey="quotelist">Quote List</DropdownItem>
                    </DropdownButton>
                }

                <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={isActive(history, '/dailyappointment')}
                        to='/dailyappointment'
                    >
                        Daily Appointment
                    </Link>
                </li>
                {
                    (localStorage.getItem('role') === 'Admin' ||
                    localStorage.getItem('role') === 'Finance') &&
                    <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={isActive(history, '/trucksmap')}
                        to='/trucksmap'
                    >
                        Trucks Map
                    </Link>
                </li>}
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={isActive(history, '/lineup')}
                        to='/lineup'
                    >
                        Lineup
                    </Link>
                </li>
                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Finance') &&
                    <DropdownButton
                        title="Finance"
                        id="dropdown-menu-align-right"
                        onSelect={handleFinance}
                    >
                        <DropdownItem eventKey="credit">Credits</DropdownItem>
                        <DropdownItem eventKey="referral">Referrals</DropdownItem>
                        <DropdownItem eventKey="dailysales">Daily Sales</DropdownItem>
                        <DropdownItem eventKey="periodsales">Period Sales</DropdownItem>
                        <DropdownItem eventKey="clientwithinvoice" >Customers with invoices</DropdownItem>
                        <DropdownItem eventKey="paymentreport">Payments Report</DropdownItem>
                        <DropdownItem eventKey="mixdesignreport">Mix Design Invoices</DropdownItem>
                        <DropdownItem eventKey="salesbyseller">Sales by seller</DropdownItem>
                    </DropdownButton>
                }
                <li className='nav-item'>
                    <Link
                        className='nav-link'
                        style={isActive(history, '/uploadlist')}
                        to='/uploadlist'
                    >
                        Uploads
                    </Link>
                </li>
                <DropdownButton
                    title="Reports"
                    id="dropdown-menu-align-right"
                    onSelect={handleSelect}
                >
                    <DropdownItem eventKey="job">Default Job Report</DropdownItem>
                    <DropdownItem eventKey="load">Default Load Report</DropdownItem>
                    <DropdownItem eventKey="newjob">Custom Job Report</DropdownItem>
                    <DropdownItem eventKey="reportlist">Report Catalog</DropdownItem>
                    <Dropdown.Divider />
                    <DropdownItem eventKey="capacityreport">Capacity Report</DropdownItem>
                    <DropdownItem eventKey="availablereport">Availability Report</DropdownItem>
                    <Dropdown.Divider />
                    <DropdownItem eventKey="truckefficiency">Truck Efficiency</DropdownItem>
                    <Dropdown.Divider />
                    <DropdownItem eventKey="clock">Clock Report</DropdownItem>
                    <Dropdown.Divider />
                    <DropdownItem eventKey="trips">Driver Trips</DropdownItem>
                    <DropdownItem eventKey="report_pay_driver">Driver Payment</DropdownItem>
                    <DropdownItem eventKey="trucktrips">Truck Trips</DropdownItem>
                    <DropdownItem eventKey="truckyards">Truck Yards</DropdownItem>
                    <Dropdown.Divider />
                    <DropdownItem eventKey="etickets">E-Tickets Report</DropdownItem>
                    <Dropdown.Divider />
                    {(
                        localStorage.getItem('email') === 'jp@57concrete.com' ||
                        localStorage.getItem('email') === 'ec@57concrete.com' ||
                        localStorage.getItem('email') === 'ac@57concrete.com' ||
                        localStorage.getItem('email') === 'fc@57concrete.com' ||
                        localStorage.getItem('email') === 'hp@57concrete.com' ||
                        localStorage.getItem('email') === 'bp@57concrete.com' ||
                        localStorage.getItem('email') === 'eliud@gmail.com')
                     &&
                    <DropdownItem eventKey="inspection">Inspections Report</DropdownItem>
                    }
                    {(
                        localStorage.getItem('email') === 'jp@57concrete.com' ||
                        localStorage.getItem('email') === 'ec@57concrete.com' ||
                        localStorage.getItem('email') === 'ac@57concrete.com' ||
                        localStorage.getItem('email') === 'fc@57concrete.com' ||
                        localStorage.getItem('email') === 'hp@57concrete.com' ||
                        localStorage.getItem('email') === 'bp@57concrete.com' ||
                        localStorage.getItem('email') === 'eliud@gmail.com')
                     &&
                    <DropdownItem eventKey="inspectiondefects">Inspections Defects</DropdownItem>
                    }
                    {(
                        localStorage.getItem('email') === 'eliud@gmail.com')
                     &&
                    <DropdownItem eventKey="failures">Failures Report</DropdownItem>
                    }
                    <Dropdown.Divider />
                    <DropdownItem eventKey="qualitycontrol">Quality Control Jobs</DropdownItem>
                    {
                        (localStorage.getItem('email') === 'eliud@gmail.com'
                        || localStorage.getItem('email') === 'ec@57concrete.com'
                        )
                        &&
                        <DropdownItem eventKey="history">History of Loads and Jobs</DropdownItem>
                    }
                </DropdownButton>

                {
                    (localStorage.getItem('email') === 'ac@57concrete.com' 
                    || localStorage.getItem('email') === 'eliud@gmail.com'
                    || localStorage.getItem('email') === 'ec@57concrete.com'
                    )
                    &&
                    <DropdownButton
                        title="RH"
                        id="dropdown-menu-align-right"
                        onSelect={handleRH}
                    >
                        <DropdownItem eventKey="employees">Employees</DropdownItem>
                        <DropdownItem eventKey="departments">Departments</DropdownItem>
                        <DropdownItem eventKey="positions">Positions</DropdownItem>
                        {
                            (localStorage.getItem('email') === 'eliud@gmail.com'
                            || localStorage.getItem('email') === 'ec@57concrete.com'
                            )
                            &&
                            <DropdownItem eventKey="emailsvalidation">Email Validation</DropdownItem>
                        }
                        {/* <DropdownItem eventKey="expensespdf">Expenses Report</DropdownItem>
                        <DropdownItem eventKey="expenseswppdf">Expenses Without Proof Report</DropdownItem>
                        <DropdownItem eventKey="employeewupdf">Employee Write Up Report</DropdownItem> */}
                    </DropdownButton> 
                }
                

                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Finance')
                    &&
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, '/settings')}
                            to='/settings'
                        >
                            Settings
                        </Link>
                    </li>
                }
                {
                    (localStorage.getItem('role') === 'Seller') &&
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, `/forecastbyseller/${localStorage.getItem('userId')}`)}
                            to={`/forecastbyseller/${localStorage.getItem('userId')}`}
                        >
                            Forecast
                        </Link>
                    </li>
                }
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={isActive(history, '/signin')}
                        to='/'
                        onClick={logout}
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} /><span className="hidden-md-down">Logout</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

Menu.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps,
    { logout }
)(withRouter(Menu))