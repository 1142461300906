import React from 'react'
import { Link } from 'react-router-dom'

const ProspectionItem = ({prospection}) => {
    return (
        <tr key={prospection._id}>
            <td>
                {prospection.prospectionNumber + ' ' + prospection.name}
            </td>        
            <td>{prospection.address}</td>
            <td> 
                {
                    prospection.contactPhone ? prospection.contactPhone : ''
                } 
            </td>
            <td>
                {
                    prospection.createdBy ? prospection.createdBy.name : ''
                }
            </td>
            <td>
                {
                    prospection.prospectionStatus
                }
            </td>
            <td>
                {
                    prospection.prospectionType
                }
            </td>
            <td>{prospection.paymentType}</td>
            <td>{prospection.quadrantType}</td>
            <td>
                {
                    `${new Date(prospection.createdAt).toLocaleDateString()} - ${new Date(prospection.createdAt).toLocaleTimeString([], { timeStyle: 'short' })}`
                }
            </td>
            <td>
                {
                    (prospection.customerCreation === null || prospection.customerCreation === undefined) ? 
                    '-' :
                    `${new Date(prospection.customerCreation).toLocaleDateString()} - ${new Date(prospection.customerCreation).toLocaleTimeString([], { timeStyle: 'short' })}`
                }
            </td>
            <td><Link to={'/prospection/'+prospection._id}>Edit</Link> </td>
        </tr>
    )
}

export default ProspectionItem
