import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
import axios from 'axios'
import { api } from '../utils/Api'
import { CustomSpinner } from '../utils/CustomComponents'


const AddressJob = props => {
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [destLat, setDestLat] = useState('')
    const [destLng, setDestLng] = useState('')
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState('')
    const [directions, setDirections] = useState([])

    const googleApi = process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU'

    useEffect(() => {
        if(props.plant){
            getPlantInfo()
        }
    }, [])

    const fetchDirections = async (originLat, originLng, destinationLat, destinationLng) => {
        const url = api + `/job/get/directions`        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const {data} = await axios.get(url, {
            params: {
                origin: `${originLat},${originLng}`,
                destination: `${destinationLat},${destinationLng}`,
            },
        });

        if (data.routes && data.routes.length) {
            const route = data.routes[0].legs[0].steps.map(step => ({
                lat: step.end_location.lat,
                lng: step.end_location.lng,
            }))

            let newRoutes = [{lat: originLat, lng: originLng}, ...route]

            setDirections(newRoutes)
        }
    }


    const getPlantInfo = async () => {
        const url = api + `/plant/${props.plant}`        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setLatitude(data.latitude)
            setLongitude(data.longitude)   
            setName(`57Concrete ${data.name}`)       
            if (props.lat && props.lng) {
                setDestLat(props.lat)
                setDestLng(props.lng)
                await fetchDirections(data.latitude, data.longitude, props.lat, props.lng)
            } 
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const defaultMap = {
        center: {
            lat: latitude, 
            lng: longitude
        },
        zoom: 10
    }

    // show text in map
    const AnyReactComponent = ({ text }) => <div style={{width: 300}}>{text}</div>

    const renderPolylines = (map, maps) => {
        if(directions.length > 0){
            let nonGeodesicPolyline = new maps.Polyline({
            path: directions,
            geodesic: false,
            strokeColor: '#000',
            strokeOpacity: 1,
            strokeWeight: 3
            })
            nonGeodesicPolyline.setMap(map)
        
            fitBounds(map, maps)
        }
        
      }
    
    const fitBounds = (map, maps) => {
        var bounds = new maps.LatLngBounds()
        for (let marker of directions) {
          bounds.extend(
            new maps.LatLng(marker.lat, marker.lng)
          )
        }
        map.fitBounds(bounds)
      }

    return (
        <div>
            <div style={{ height: '60vh', width: '100%' }}>     
            {
                loading ? 
                <CustomSpinner />
                :
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApi }}
                    defaultCenter={defaultMap.center}
                    defaultZoom={defaultMap.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => renderPolylines(map, maps)}
                >                        
                    <Marker 
                        lat={latitude}
                        lng={longitude}
                        color="red"
                    />
                    <AnyReactComponent
                        lat={latitude}
                        lng={longitude}
                        text={name}
                    />
                    {
                        destLat &&
                        <Marker
                            lat={destLat}
                            lng={destLng}
                            color="green"
                        />
                    }
                    {
                        destLat &&
                        <AnyReactComponent
                            lat={destLat}
                            lng={destLng}
                            text={props.address}
                        />
                    }
                </GoogleMapReact>    
                
                }
            </div>
        </div>
    )

}

export default AddressJob