import React from 'react'

const Card = ({ title, counter, cardColor, text}) => {
    return (    
        <div className='col-md-4'>
            <div className="card text-center mb-2" style={{backgroundColor: cardColor}}>
                <div className="card-body">
                    <h3>{title}</h3>
                    <h4 className="display-4">
                        {counter}                   
                    </h4>                                        
                    {text}
                </div>                                    
            </div>
        </div>            
    )
}

export default Card
