import React, { Component } from 'react'
import Select from 'react-select'

export default class SlumpPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: true,
            isSearchable: false
        }
    }

    render() {
        const values = [3, 4, 5, 6, 7, 8, 9]
        const options = values.map((val) => {
            return {
                value: val,
                label: val
            }
        })

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Slump...'
                    value={this.state.value}
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    } 
}
