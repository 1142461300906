import { Button, Col, Form, Input, Popconfirm, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import { sitesOptions } from '../../constants/Inventory'
import dayjs from 'dayjs'
const { Option } = Select

const Provider = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getProvider()
    }, [])
    

    const getProvider = async () => {
        setLoading(true)

        try {
            let url = api + `/provider/${props.match.params.id}`
            console.log(url)
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const {data} = await axios.get(url)

            setInitialValues({
                ...initialValues,
                name: data.name,
                email: data.email,
                phone: data.phone.substring(2),
                sites: data.sites,
                contractStartDate: data.contractStartDate ? dayjs(data.contractStartDate) : undefined,
                contractEndDate: data.contractEndDate ? dayjs(data.contractEndDate) : undefined,
                address1: data.address1 ? data.address1 : undefined,
                address2: data.address2 ? data.address2 : undefined,
                city: data.city ? data.city : undefined,
                state: data.state ? data.state : undefined,
                country: data.country ? data.country : undefined,
                zip: data.zip ? data.zip : undefined,
                prefix: '+1'
            })

            setLoading(false)

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to get provider')
            console.error(err.message)
            return null
        }
    }

    const updateProvider = async (data) => {
        setLoading(true)

        let user = {
            name: data.name,
            email: data.email,
            phone: `+1${data.phone}`,
            updatedBy: localStorage.getItem('userId')
        }

        if(data.sites){
            user.sites = data.sites
        }
        if(data.contractStartDate){
            user.contractStartDate = data.contractStartDate
        }
        if(data.contractEndDate){
            user.contractEndDate = data.contractEndDate
        }
        if(data.address1){
            user.address1 = data.address1
        }
        if(data.address2){
            user.address2 = data.address2
        }
        if(data.city){
            user.city = data.city
        }
        if(data.state){
            user.state = data.state
        }
        if(data.country){
            user.country = data.country
        }
        if(data.zip){
            user.zip = data.zip
        }

        try {
            let url = api + `/provider/${props.match.params.id}`
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, user)
            openNotification('success', 'Success', 'Provider updated succesfully')
            setLoading(false)
            props.history.push('/providerlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create provider')
            console.error(err.message)
            return null
        }
    }

    const deleteProvider = async () => {
        setLoading(true)

        const url = api + '/provider/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Provider Deleted')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/providerlist')
            }, 1000);
            
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        updateProvider(values)
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please fill email'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label= 'Sites'
                        name='sites'
                        >
                            <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select sites"
                            options={sitesOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Address 1'
                    name='address1'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Address 2'
                    name='address2'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='City'
                    name='city'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='State'
                    name='state'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Country'
                    name='country'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='ZIP'
                    name='zip'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={18} style={{marginTop: '20px'}}>
                <Col>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Save 
                    </Button>
                </Col>
                {
                    (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com') &&
                    <Col>
                        <Popconfirm
                            title="Delete the provider"
                            description="Are you sure to delete this provider?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteProvider}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                        </Popconfirm>
                    </Col>
                }
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Provider'
        description='Edit Provider'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default Provider