import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { toCurrency } from '../utils/Convert'
import InvoiceDueItem from './InvoiceDueItem'
import { api } from '../utils/Api'

const DailySales = (props) => {
    const [invoiceList, setInvoiceList] = useState([])
    const [sumSale, setSumSale] = useState(0)    
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getInvoices()
    }, [])

    const getInvoices = async () => {
        const url = api + '/invoice?date=2021-05-20'     
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            console.log(data)
            const total = data.reduce((acc, venta) => acc + venta.invoiceTotal, 0)
            setSumSale(total)

            console.log(`venta acumulada: ${total} `)
            setInvoiceList(data)
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }    

    const Deudores = () => {
        if (invoiceList.length > 0) {
            return invoiceList.map((currentInvoice) => {
                return <InvoiceDueItem sale={currentInvoice} key={currentInvoice._id} />
            })
        } else {
            return (
                <tr>
                    <td>
                        Deudores
                    </td>
                </tr>
            )
        }
    }

    const RenderAR = () => {
        return (
            <div>
                <h3>                    
                    Total Sale with taxes: {toCurrency(sumSale, 2)}
                </h3>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Customer</th>  
                            <th>Invoice #</th>  
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Total Amount</th>                                                                                    
                            <th style={{ textAlign: "right" }}>Due days</th>
                            <th style={{ textAlign: "right" }}>Category</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <Deudores />
                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <Layout
            title='Accounts Receivable'
            description='AR Invoices'
        >
            {
                loading ? 
                <RenderLoading /> 
                : <RenderAR />
            }
        </Layout>
    )

}

export default DailySales