import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button, Checkbox } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
const { Text } = Typography

const TruckList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [truckList, setTruckList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return <Flex justify='space-between'>
                    {
                        (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') ? 
                        <Button 
                        type="primary" 
                        icon={<EditFilled twoToneColor={'white'}/>} 
                        onClick={e=> history.push(`/truck/${record.id}`)}
                        />
                        :
                        ''
                    }
                </Flex>
            }
        },
        {
            title: 'Truck Number',
            dataIndex: 'number',
            key: 'number',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (value) => {
                return <Checkbox checked={value} />
            }
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Plates',
            dataIndex: 'plates',
            key: 'plates',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Subplant',
            dataIndex: 'subplant',
            key: 'subplant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
        getTruckList()
    }, [])
    
    const getTruckList = async() => {
        setLoading(true)

        const url = api + '/trucklist'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    number: item.number,
                    active: item.active,
                    make: item.make ? item.make : '-',
                    model: item.model ? item.model : '-',
                    plates: item.plates ? item.plates : '-',
                    plant: item.plant ? item.plant.name: '-',
                    subplant: item.subplant ? item.subplant : '-',
                    vin: item.vin ? item.vin : '-',
                })
            })
            setTruckList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewTruck = () => {
        history.push('/newtruck')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') &&
                <Button
                    onClick={addNewTruck}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                >
                    New Truck
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={truckList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Truck'
            description='Truck List'
        >
            <Flex vertical gap={'large'}>
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default TruckList