import React, {useState, useEffect} from 'react'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'
import { toThousands } from '../utils/Convert'

const ReportTruckYards = () => {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [dataTable, setDataTable] = useState([])
    const [loading, setLoading] = useState(true)
    const [month, setMonth] = useState('')

    useEffect(() => {
        getLoads()
    }, [startDate, endDate])

    const getLoads = async () => {
        let fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        const url = api + `/loadgroupdatetruck?startDate=${fechaIni}&endDate=${fechaFin}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            calculateTotals(data)
            lastMonth()
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const lastMonth = () => {
        var initialDate = new Date(startDate)
        initialDate.setDate(1)
        initialDate.setMonth(initialDate.getMonth()-1)

        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ]

        setMonth(monthNames[initialDate.getMonth()])
    }

    const calculateTotals = (data) => {
        const rangeDates = []
        const outDates = []

        var initialDate = startDate
        initialDate.setHours(0,0,0,0)

        var lastDayLastMonth = new Date(initialDate.getFullYear(), initialDate.getMonth(), 0);

        data.map((object) =>{
            var date = new Date(object._id.year,object._id.month - 1,object._id.day)
            if(date.getTime() >= initialDate.getTime()){
                rangeDates.push(object)
            }else if (date.getTime() < lastDayLastMonth.getTime()){
                outDates.push(object)
            }
        })
        
        const groupByCategoryIn = rangeDates.reduce((group, truck) => {
            const category = truck._id.truck;
            group[category] = group[category] ?? [];
            group[category].push(truck);
            return group;
        }, {});

        const groupByCategoryOut = outDates.reduce((group, truck) => {
            const category = truck._id.truck;
            group[category] = group[category] ?? [];
            group[category].push(truck);
            return group;
        }, {});

        const arrayObjectsIn = Object.values(groupByCategoryIn)
        const arrayObjectsOut = Object.values(groupByCategoryOut)

        const arrayObjectIn = []

        arrayObjectsIn.map((arrayIn) => {
            let objectIn = { truck: arrayIn[0]._id.truck }
            let yards = 0
            arrayIn.map((object) => {
                if(object.yards) yards += object.yards
            })

            objectIn['yards'] = yards
            arrayObjectIn.push(objectIn)
        })

        arrayObjectsOut.map((arrayIn) => {
            let yards = 0
            arrayIn.map((object) => {
                if(object.yards) yards += object.yards
            })

            var i;
            for (i = 0; i < arrayObjectIn.length; i++) {
                if (arrayObjectIn[i].truck === arrayIn[0]._id.truck) {
                    arrayObjectIn[i]['avg'] = (yards / arrayIn.length).toFixed(2)
                }
            }
        })

        arrayObjectIn.map((isIn) => {
            if(isIn.avg === undefined) isIn.avg = '-'
        })

        arrayObjectIn.sort((a, b) => b.yards - a.yards)

        setDataTable(arrayObjectIn)
        setLoading(false)
    }

    const downloadCSVFile = (csv_data) => {

        // Create CSV file object and feed our
        // csv_data into it
        const CSVFile = csv_data

        // Create to temporary link to initiate
        // download process
        var temp_link = document.createElement('a');

        // Download csv file
        temp_link.download = "57concrete-truck-yards-report.csv";
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;

        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);

        // Automatically click the link to trigger download
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    const exportCSV = () => {
        let csv = [];
        const rows = document.querySelectorAll("table tr");
        for (const row of rows.values()) {
            const cells = row.querySelectorAll("td, th");
            const rowText = Array.from(cells).map(cell => cell.innerText);
            csv.push(rowText.join(','));
        }
        const csvFile = new Blob([csv.join('\n')], { type: "text/csv;charset=utf-8;" });
        downloadCSVFile(csvFile);
    }

    const DrawTotal = () => {
        if(dataTable.length !== 0){
            return dataTable.map((row, index) => (
                 <tr key={index}>
                    <td style={{ textAlign: "left", fontWeight: 'bold' }}>
                        {row.truck}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {toThousands(row.yards)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {row.avg === '-' ? row.avg : toThousands(row.avg)}
                    </td>
                </tr>
            ))
        }
        return <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
                No trucks available
            </td>
        </tr>
    }
    
    
    return (
        <Layout
            title='Truck Yards Report'
            description='Yards by Truck'
        >
            <div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className='text-muted'>Start Date</label>
                        <DatePicker
                            minDate={new Date('01/01/2022')}
                            maxDate={new Date()}
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                            }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>End Date</label>
                        <DatePicker
                            minDate={startDate}
                            maxDate={new Date()}
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                            }}
                        />
                    </div>
                </div>
                {
                loading ? <Spinner /> :
                    <div>
                        <br />
                        <button type="button" className="btn btn-primary" onClick={exportCSV}>Download report</button>
                        <br />
                        <div className='row justify-content-center' style={{ fontSize: '13px' }}>
                            <div className="col-auto">
                                <table className='table table-responsive'>
                                    <thead>
                                        <tr>
                                            <th>Truck</th>
                                            <th style={{ textAlign: "center" }}>TOTAL YARDS in Date Range</th>
                                            <th style={{ textAlign: "center" }}>AVERAGE previous month ({month})</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th style={{ textAlign: "center" }}>
                                                {startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString()}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <DrawTotal />
                                    </tbody>
                                </table>
                            </div >
                        </div >
                    </div>
                }
            </div>
        </Layout>
    )
}

export default ReportTruckYards