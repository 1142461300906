import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../utils/Api'

export default class InspectionTypePicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: false,
            isSearchable: true,
            statusList: [
                {'_id': 'ALL', 'status': 'ALL'},
                {'_id': 'PRE', 'status': 'PRE'},
                {'_id': 'REP', 'status': 'REP'},
                {'_id': 'POST', 'status': 'POST'},
            ]
        }
    }

    render() {
        const options = this.state.statusList.map((estatus) => {
            return {
                value: estatus._id,
                label: estatus.status
            }
        })

        return (
            <div style={{fontSize: '10px'}}>
                <Select
                    name="form-field-name"
                    placeholder='Type...'
                    value={options.filter((o) => { return o.label === this.props.value })}
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    } 
}