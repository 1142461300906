import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import ProposalItem from './ProposalItem'
import { api } from '../utils/Api'

const Proposals = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [update, setUpdate] = useState(false)
    const [proposals, setProposals] = useState([])
    const [endDate, setEndDate] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(true)
        const fecha = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        getProposals(fecha)
    }, [])

    useEffect(() => {
    }, [update])

    const getProposals = async () => {
        const url = api + `/allproposals`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            assignData(data) // Fill list with data
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Assign API response to local array "proposals" & calculate totals
    const assignData = (data) => {
        // suma de importe ($)
        const result = data.reduce((acc, proposal) => acc + proposal.loadYD3, 0)
        const totalLoad = Math.round(result * 100) / 100
        localStorage.setItem('loadSumProposals', totalLoad)

        //suma de target
        var suma = 0
        data.forEach(element => {
            let operandos = typeof element.targetYD3 === "string" ? element.targetYD3.split('+') : element.targetYD3
            if (element.proposalStatus !== 'Closure') {
                suma = suma + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
            }
        })
        localStorage.setItem('targetSumProposals', suma)

        setProposals(data)
        setLoading(false)
    }

    const proposalsPerStatus = (status) => {
        if(proposals.length > 0){
            return proposals
            .filter(d => d.proposalStatus === status)
            .map((proposal, i) => {
                return <ProposalItem proposal={proposal} key={i} />
            })
        }else{
            return <tr>
                <td colSpan={15}>Empty data</td>
            </tr>
        }
    }

    const renderLoading = () => {
        return <Spinner />
    }

    const renderProposals = () => (
        <Fragment>
            <h3>
                {`Total YD: ${localStorage.getItem('loadSumProposals')},
                Total Target: ${localStorage.getItem('targetSumProposals')}`}
            </h3>
            {/* <div className="form-group row">
                <div className="col-sm-3">
                    <label className="col-sm-3">Date:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(startDate) => setStartDate(startDate)}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div> */}
            <div
                style={{ fontSize: '13px' }}
            >
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: '10%' }}>Prospection</th>
                            <th>ProposalID</th>
                            <th>Time</th>
                            <th>Prospection Address</th>
                            <th>Target YD3</th>
                            <th>Dest. time</th>
                            <th style={{ width: '4%' }}>Mix Design</th>
                            <th>Slump</th>
                            <th>Pour/Type</th>
                            <th>Status</th>
                            <th>Seller</th>
                            <th>Proposal Creation</th>
                            <th>Job Creation</th>
                            <th style={{ width: '6%' }}>Comments</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {proposalsPerStatus('Contact')}
                        {proposalsPerStatus('Appointment')}
                        {proposalsPerStatus('Proposal')}
                        {proposalsPerStatus('Closure')}
                        {proposalsPerStatus('New Client')} */}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )

    return (
        <Layout
            title='Proposals'
            description='Proposal list'
        >
            {
                loading ?
                    renderLoading()
                    : renderProposals()
            }

        </Layout>
    )
}

export default Proposals
