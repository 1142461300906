import api from '../utils/api'
import {
    GET_PROSPECTIONS,
    PROSPECTION_ERROR,
} from './types'

// paged prospection list
export const pagedProspection = (skip, limit) => async dispatch => {
    try {
        const { data } = await api.get(`/prospection?limit=${limit}&skip=${skip}`)

        dispatch({
            type: GET_PROSPECTIONS,
            payload: data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: PROSPECTION_ERROR,
            payload: { msg: err.response, status: err.response }
        })
    }
}

// prospection search
export const searchProspection = term => async dispatch => {
    try {
        const { data } = await api.get(`prospection/search?term=${term}`)

        dispatch({
            type: GET_PROSPECTIONS,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: PROSPECTION_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}