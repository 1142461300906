import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import SendDriverPreInvoice from '../driverinvoices/SendDriverPreInvoice'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'

export default class DriverPaymentPreInvoice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showModalEmail: false,
            showModalSms: false,
            pdf: '',
            loading: true,
            paymentNumber: '',
            bodyInvoice: {}
        }
    }

    /**
     * Get PDF invoice
     */
    getPDFInvoice = async () => {
        const url2 = api + '/paymentnumber'
        const url = api + '/driverpreinvoicepdf'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const response = await axios.get(url2)
            console.log(response)
            if(response){
                const conceptList = this.props.location.state.concepts
                const selectedList = this.props.location.state.selectedList
                const driver = this.props.location.state.driver
                const startDate = this.props.location.state.startDate
                const endDate = this.props.location.state.endDate
                const bodyInvoice = {
                    conceptList,
                    selectedList,
                    startDate: startDate,
                    endDate: endDate,
                    paymentNumber: response.data.paymentNumber.toString(),
                    driver
                }

                const { data } = await axios.post(url, bodyInvoice)
                this.setState({
                    pdf: data,
                    loading: false,
                    bodyInvoice: bodyInvoice
                })
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    /**
     * Open and close email modal
     * @param {Event} e event of the modal
     */
    toggleModalEmail = (e) => {
        this.setState({
            showModalEmail: !this.state.showModalEmail
        })
    }

    /**
     * Open and close sms modal
     * @param {Event} e event of the modal
     */
    toggleModalSms = (e) => {
        this.setState({
            showModalSms: !this.state.showModalSms
        })
    }

    // getDriverPayment = async () => {
    //     const url = api + `/driverpayment/${'63863cf6fec1f74f346608e4'}`
    //     const token = localStorage.getItem('token')
    //     axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

    //     try{
    //         const {data} = await axios.delete(url)
    //         console.log(data)
    //     } catch (err) {
    //         console.error(err)
    //         return null
    //     }
    // }
    
    componentDidMount() {
        this.getPDFInvoice()
        //this.getDriverPayment()
    }

    render() {

        return (
            <Layout
                title='Driver Invoice PDF'
                description='Invoice'
                className="container col-md-12"
            >
                <Link onClick={this.toggleModalEmail}
                    to='#'
                    className="btn btn-primary"
                >
                    Send invoice by email
                </Link>                
                <Link onClick={this.toggleModalSms}
                    to='#'
                    className="btn btn-success"
                    style={{marginLeft:20}}
                >
                    Send invoice by SMS
                </Link>                
                <Modal show={this.state.showModalEmail} animation={false} onHide={this.toggleModalEmail}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Invoice to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendDriverPreInvoice 
                            bodyInvoice={this.state.bodyInvoice} 
                            pcallback={this.toggleModalEmail}
                            isEmail={true}
                        />
                    </Modal.Body>
                </Modal>  
                <Modal show={this.state.showModalSms} animation={false} onHide={this.toggleModalSms}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Invoice to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendDriverPreInvoice 
                            bodyInvoice={this.state.bodyInvoice} 
                            pcallback={this.toggleModalSms}
                            isEmail={false}
                        />
                    </Modal.Body>
                </Modal>  
                {
                    this.state.loading ? <Spinner/> : <iframe src={this.state.pdf} width='95%' style={{height: '90vh'}} />
                }
                
            </Layout>
        )
    }
}