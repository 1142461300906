import { Button, Col, Form, Input, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import { CustomSpinner } from '../utils/CustomComponents'

const TruckNew = ({history}) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [plantOptions, setPlantOptions] = useState([])
    const [subplantOptions, setSublantOptions] = useState([])
    const [loadingForm, setLoadingForm] = useState(true)
    const [loadingSubplant, setLoadingSubplant] = useState(true)
    const [loading, setLoading] = useState(false)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getPlant()
    }, [])
    
    const getPlant = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let plantList = []
            data.map((item) => {
                plantList.push({
                    label: item.name,
                    value: item._id
                })
            })
            setPlantOptions(plantList)
            setInitialValues({
                ...initialValues,
                make: '',
                model: '',
                plates: '',
                number: '',
                plant: '',
                subplant: '',
                vin: ''
            })
            setLoadingForm(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getSublant = async (currentPlant) => {
        const url = api + `/plant/${currentPlant}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let subplantList = []
            data.subplants.map((item) => {
                subplantList.push({
                    label: item.name,
                    value: item.name
                })
            })
            setSublantOptions(subplantList)
            setLoadingSubplant(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createTruck = async (data) => {
        const truck = {
            make: data.make,
            model: data.model,
            plates: data.plates,
            number: data.number,
            plant: data.plant,
            subplant: data.subplant,
            vin: data.vin
        }

        try {
            let url = api + '/truck' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, truck)
            openNotification('success', 'Success', 'Truck created')
            setLoading(false)
            history.push('/trucklist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create truck')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        createTruck(values)
    }

    const onChangePlant = (value) => {
        form.setFieldsValue({subplant: ''})
        setLoadingSubplant(true)
        getSublant(value)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Make'
                    name='make'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill make'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Model'
                    name='model'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill model'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Plates'
                    name='plates'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill plates'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Truck Number'
                    name='number'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill truck number'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='VIN'
                    name='vin'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill plates'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Plant'
                    name='plant'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a plant'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select plant`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={plantOptions}
                        onChange={onChangePlant}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Subplant'
                    name='subplant'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a subplant'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select subplant`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={subplantOptions}
                        disabled={loadingSubplant}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Truck'
        description='Create new Truck'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm />}
        </Layout>
    )
}

export default TruckNew