const typeIsnpectionOptions = [
    {
        label:'ALL',
        value:'ALL'
    },
    {
        label:'PRE',
        value:'PRE'
    },
    {
        label:'REP',
        value:'REP'
    },
    {
        label:'POST',
        value:'POST'
    },
]

export {
    typeIsnpectionOptions
}