import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
import dayjs from 'dayjs'
const { Text } = Typography

const ProjectList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [projectList, setProjectList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return (localStorage.getItem('email') === 'ec@57concrete.com' || 
              localStorage.getItem('email') === 'eliud@gmail.com' || 
              localStorage.getItem('email') === 'jp@57concrete.com' ||
              localStorage.getItem('email') === 'ji@57concrete.com'
              )
              ?
              <Button 
              type="primary" 
              icon={<EditFilled twoToneColor={'white'}/>} 
              onClick={e=> history.push(`/project/${record.id}`)}
              />
              : 
              ''
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Number of products',
            dataIndex: 'products',
            key: 'products',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
        getProjectList()
    }, [])
    
    const getProjectList = async() => {
        setLoading(true)

        const url = api + '/allprojects'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    name: item.name,
                    customer: item.customer.name,
                    address: item.customer.address || 'N/A',
                    description: item.description || 'N/A',
                    products: item.productPrice.length || 'N/A',
                    startDate: item.start ? dayjs(item.start).format('MM/DD/YYYY') : 'N/A',
                    endDate: item.end ? dayjs(item.end).format('MM/DD/YYYY') : 'N/A',
                })
            })
            setProjectList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewProject = () => {
        history.push('/newproject')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || 
                localStorage.getItem('email') === 'eliud@gmail.com' || 
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ji@57concrete.com'
                )
                &&
                <Button
                    onClick={addNewProject}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                >
                    New Project
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={projectList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Project'
            description='Project List'
        >
            <Flex vertical gap={'large'}>
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default ProjectList