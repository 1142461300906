import React, { useState } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import DatePicker from 'react-datepicker'

const MaterialInventory = () => {
    const [file, setFile] = useState('')
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())

    const onSubmit = async () => {
        console.log('haciendo el submit...')
        const formData = new FormData()
        formData.append('file', file)
        console.log(file)

        const url = api + '/api/txtfile'

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        const day = (selectedDate.getDate() < 10 ? `0${selectedDate.getDate()}` : `${selectedDate.getDate()}`)
        const month = (selectedDate.getMonth() + 1 < 10 ? `0${selectedDate.getMonth() + 1}` : `${selectedDate.getMonth() + 1}`)
        const year = selectedDate.getFullYear()

        const fileDate = `${year}` + month + day

        formData.append('fileDate', fileDate)

        //const { data } = await axios.post(url, formData, dateProcess)
        //console.log(data)
    }

    const picked = (e) => {
        setFile(e.target.files[0])
        setIsFilePicked(true)
    }

    const MaterialInventoryUploadForm = () => (
        <form className='form' onSubmit={onSubmit}>
            <div className="form-group column">
                <div className='col-sm-4'>
                    <label className='text-muted'>File Date</label>
                </div>
                <div className='col-sm-4'>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date)
                        }}
                    />
                </div>
            </div>
            <br />
            <div className='form-group'>
                <input
                    type='file'
                    name='file'
                    id="files"
                    style={{ display: "none" }}
                    onChange={picked}
                />
                <label htmlFor="files" className='btn btn-primary'>Select a .txt file to upload</label>
            </div>
            {isFilePicked ? (
                <div>
                    <p>Filename: {file.name}</p>
                    <p>Filetype: {file.type}</p>
                    <p>Size in Kbytes: {Math.floor(file.size / 1024)}</p>
                    <p>
                        lastModifiedDate:{' '}
                        {file.lastModifiedDate ? file.lastModifiedDate.toLocaleDateString() : ''}
                    </p>
                </div>
            ) : (
                    <p>No file selected</p>
                )}
            <div>
                {file ? <input className='btn btn-success' value='Upload' type='submit' /> : <label className='btn btn-secondary'>Upload</label>}
            </div>
        </form>
    )

    return (
        <Layout
            title='File upload '
            description='Material Inventory file upload'
            className="container col-md-8 offset-md-2"
        >
            <MaterialInventoryUploadForm />
        </Layout>
    )
}

export default MaterialInventory