import React from 'react'
import { Link } from 'react-router-dom'
import { toCurrency } from '../utils/Convert'

const InvoiceDueItem = ({sale}) => {
    return (
        <tr>
            <td>
                {sale.customer.customerNumber + ' ' + sale.customer.name}
            </td>
            <td>                
                <Link to={'/invoicepdf/'+sale._id}>                
                    {sale.invoiceNumber}
                </Link>
            </td>
            <td>
                {new Date(sale.invoiceDate).toLocaleDateString()}
            </td>
            <td style={{ textAlign: "right" }}>
                {toCurrency(sale.invoiceTotal, 2)}
            </td>
            <td style={{ textAlign: "right" }}>
                {sale.dueDays}
            </td>
            <td style={{ textAlign: "right" }}>
                {sale.debitClassification}            
            </td>
        </tr>
    )
}


export default InvoiceDueItem