import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const StatusPicker = (props) => {
    const [currentStatus, setCurrentStatus] = useState({ value: 'revision', label: 'Revision' })

    useEffect(() => {
        switchDefaultValue(props.load.statusPayment)
    }, [])

    const switchDefaultValue = (status) => {
        switch (status) {
            case 'revision':
                setCurrentStatus({ value: 'revision', label: 'Revision' })
                break;
            case 'passed':
                setCurrentStatus({ value: 'passed', label: 'Passed' })
                break;
            case 'process':
                setCurrentStatus({ value: 'process', label: 'In Process' })
                break;
            default:
                setCurrentStatus({ value: 'revision', label: 'Revision' })
                break;
        }
    }

    const options = [
        { value: 'revision', label: 'Revision' },
        { value: 'passed', label: 'Passed' },
        { value: 'process', label: 'In Process' },
    ]

    const onChangeStatus = (status) => {
        props.load['statusPayment'] = status.value
        switchDefaultValue(status.value)
    }

    return (
        <div>
            <Select
                options={options}
                placeholder='Status...'
                onChange={onChangeStatus}
                value={currentStatus}
            />
        </div>
    )
}

export default StatusPicker