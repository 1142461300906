import React, { useEffect, useState } from 'react';
import PsiPickerID from '../psi/PsiPickerID';
import { api } from '../utils/Api';
import axios from 'axios';

const PricesRender = ({currentPrice, prices, setPrices, toggleModal, edit, index, currentPsi, currentPsiName}) => {

    const [customer, setCustomer] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [upperValley, setUpperValley] = useState(0);
    const [midValley, setMidValley] = useState(0);
    const [lowerValley, setLowerValley] = useState(0);
    const [psi, setPsi] = useState('');
    const [psiName, setPsiName] = useState('');

    useEffect(() => {
        setCurrentPrice()
    }, [])


    const createPricePsi = async () => {
        const url = api + `/pricepsi`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const body = {
                customer: customer,
                upperValley: upperValley,
                midValley: midValley,
                lowerValley: lowerValley,
                psi: psi || currentPsi
            }

            const { data } = await axios.post(url, body)

            prices[index].customer = customer;
            prices[index].upperValley = upperValley;
            prices[index].midValley = midValley;
            prices[index].lowerValley = lowerValley;
            prices[index].psi = currentPsi;
            prices[index].psiName = currentPsiName;
            prices[index]._id = data._id;

            setPrices(prices)
            toggleModal();
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const editPricePsi = async () => {
        const url = api + `/pricepsi/${currentPrice._id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const body = {
                customer: customer,
                upperValley: upperValley,
                midValley: midValley,
                lowerValley: lowerValley,
                psi: psi || currentPsi
            }

            await axios.patch(url, body)

            prices[index].customer = customer;
            prices[index].upperValley = upperValley;
            prices[index].midValley = midValley;
            prices[index].lowerValley = lowerValley;
            prices[index].psi = currentPsi;
            prices[index].psiName = currentPsiName;

            setPrices(prices)
            toggleModal();
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    

    const setCurrentPrice = () => {
        if(currentPrice.upperValley !== null && currentPrice.midValley !== null && currentPrice.lowerValley !== null){
            setCustomer(currentPrice.customer);
            setCustomerName(currentPrice.name);
            setUpperValley(currentPrice.upperValley);
            setMidValley(currentPrice.midValley);
            setLowerValley(currentPrice.lowerValley);
            setPsi(currentPrice.psi);
            setPsiName(currentPrice.psiName);
        }else{
            setCustomer(currentPrice.customer);
            setPsi(currentPsi);
            setPsiName(currentPsiName);
            setCustomerName(currentPrice.name);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(currentPrice.upperValley && currentPrice.midValley && currentPrice.lowerValley){
            editPricePsi()
        }else{
            createPricePsi()
        }
    }

    const onChangePsi = (selectedOption) => {
        if(selectedOption){
            setPsi(selectedOption.value)
            setPsiName(selectedOption.label)
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Customer</label>
                    <input className='form-control'
                        name='customerName'
                        type='text'
                        value={customerName}
                        onChange={e => setCustomerName(e.target.value)}
                        placeholder='Customer'
                        readOnly
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Upper Valley</label>
                    <input className='form-control'
                        name='upperValley'
                        type='number'
                        min={0}
                        step={0.01}
                        value={upperValley}
                        onChange={e => setUpperValley(e.target.valueAsNumber)}
                        placeholder='Upper Valley'
                        required
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Mid Valley</label>
                    <input className='form-control'
                        name='midValley'
                        type='number'
                        min={0}
                        step={0.01}
                        value={midValley}
                        onChange={e => setMidValley(e.target.valueAsNumber)}
                        placeholder='Mid Valley'
                        required
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Lower Valley</label>
                    <input className='form-control'
                        name='lowerValley'
                        type='number'
                        min={0}
                        step={0.01}
                        value={lowerValley}
                        onChange={e => setLowerValley(e.target.valueAsNumber)}
                        placeholder='Lower Valley'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-12'>
                    <label className='text-muted'>Psi</label>
                    <PsiPickerID action={onChangePsi} selectedOption={psi || currentPsi} isDisabled={currentPsi === '' ? false : true} />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{'Edit'}</button>
            </div>
            
        </form>
    )
}

export default PricesRender