import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import SendEticket from './SendEticket'
import Layout from '../layout/Layout'

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDU3ZTYzNGRkYmJmODAwMTVjNWEzZGUiLCJpYXQiOjE2OTY4OTA3OTh9.ufWCpd-_E3IvgnRnKYFmlQZ1R-wL3EWUAgFq_OXOX0E"

const EticketModified = ({ match }) => {

    const loadID = match.params.id
    const [loading, setLoading] = useState(true)
    const [showModalEmail, setShowModalEmail] = useState(false)
    const [showModalSms, setShowModalSms] = useState(false)
    const [pdf, setPdf] = useState('')

    useEffect(() => {
        getPdf()
    }, [])

    const getPdf = async () => {
        const url = api + `/eticketpdfmod/${loadID}`
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.post(url)
            setPdf(data)
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    // /**
    //  * Open and close email modal
    //  * @param {Event} e event of the modal
    //  */
    // const toggleModalEmail = (e) => {
    //     setShowModalEmail(!showModalEmail)
    // }

    // /**
    //  * Open and close sms modal
    //  * @param {Event} e event of the modal
    //  */
    // const toggleModalSms = (e) => {
    //     setShowModalSms(!showModalSms)
    // }

    // const sendEmails = async () => {
    //     const url = api + `/eticketsend/${loadID}?type=${'email'}`
    //     axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

    //     try {
    //         await axios.post(url)
    //     } catch (err) {
    //         console.error(err)
    //         return null
    //     }
    // }

    // const sendSms = async () => {
    //     const url = api + `/eticketsend/${loadID}?type=${'sms'}`
    //     axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

    //     try {
    //         await axios.post(url)
    //     } catch (err) {
    //         console.error(err)
    //         return null
    //     }
    // }

    const RenderPDF = () => (
        <div>
            {/* <Link onClick={toggleModalEmail}
                to='#'
                className="btn btn-success"
                style={{marginLeft:20}}
            >
                Send e-ticket by email
            </Link>                
            <Link onClick={toggleModalSms}
                to='#'
                className="btn btn-warning"
                style={{marginLeft:20}}
            >
                Send e-ticket by SMS
            </Link> 
            <Link onClick={sendEmails}
                to='#'
                className="btn btn-primary"
                style={{marginLeft:20}}
            >
                Send e-ticket to all emails
            </Link> 
            <Link onClick={sendSms}
                to='#'
                className="btn btn-secondary"
                style={{marginLeft:20}}
            >
                Send e-ticket to all SMS
            </Link>  */}
            {/* <Modal show={showModalEmail} animation={false} onHide={toggleModalEmail}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send E-Ticket to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendEticket 
                            loadId={loadID} 
                            pcallback={toggleModalEmail}
                            isEmail={true}
                        />
                    </Modal.Body>
                </Modal>  
                <Modal show={showModalSms} animation={false} onHide={toggleModalSms}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send E-Ticket to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendEticket 
                            loadId={loadID} 
                            pcallback={toggleModalSms}
                            isEmail={false}
                        />
                    </Modal.Body>
                </Modal> */}
            <iframe src={pdf} width='95%' style={{height: '90vh'}} />
        </div>
    )


    return (
        <Layout
        title='Modified Eticket'
        description='Eticket'
        >
            {/* <div className="jumbotron">
                <img src="http://57concrete.com/assets/images/logo/logo-light.png" alt="Logo" style={{ width: '20%' }} />
                <h2>E-Ticket</h2>
                <p className="lead"> E-Ticket PDF </p>
            </div> */}
            <div className="container col-md-12" >
                {
                    loading ? 
                    <Spinner /> 
                    :
                    RenderPDF()
                }
            </div>
        </Layout>
    )
}


export default EticketModified
