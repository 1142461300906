import React from 'react'
import { Button, Collapse, Flex, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Layout from './Layout';
const { Text } = Typography

const Settings = (props) => {

    const onChangeOption = (type) => {
        switch (type) {
            case 'sellers':
                props.history.push('/sellerlist')
                break;
            case 'administrators':
                props.history.push('/adminlist')
                break;
            case 'drivers':
                props.history.push('/driverlist')
                break;
            case 'finance':
                props.history.push('/financelist')
                break;
            case 'batch':
                props.history.push('/batchlist')
                break;
            case 'mechanics':
                props.history.push('/mechaniclist')
                break;
            case 'managers':
                props.history.push('/managerlist')
                break;
            case 'trucks':
                props.history.push('/trucklist')
                break;
            case 'emails':
                props.history.push('/emaillist')
                break;
            case 'products':
                props.history.push('/psilist')
                break;
            case 'aggregates':
                props.history.push('/aggregatelist')
                break;
            case 'cogs':
                props.history.push('/coglist')
                break;
            case 'foremans':
                props.history.push('/foremanlist')
                break;
            case 'projects':
                props.history.push('/projectlist')
                break;
            case 'customers':
                props.history.push('/customertype')
                break;
            case 'company':
                props.history.push('/company')
                break;
            case 'plants':
                props.history.push('/plantlist')
                break;
            case 'driverPayments':
                props.history.push('/citydeliveryfee')
                break;
            case 'qc':
                props.history.push('/qclist')
                break;
            default:
                break;
        }
    }

    const items = [
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '1',
          label: 'Users',
          children: <Flex gap="middle" vertical>
            {
              (localStorage.getItem('role') === 'Seller' ||
              localStorage.getItem('role') === 'Admin' ||
              localStorage.getItem('role') === 'Finance' ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 100}} onClick={e => onChangeOption('sellers')}><Flex justify='space-between'><Text style={{color: 'white'}}>Sellers</Text> <RightOutlined /></Flex></Button>
            }
            {
              (localStorage.getItem('role') === 'Admin' ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 150}} onClick={e => onChangeOption('administrators')}><Flex justify='space-between'><Text style={{color: 'white'}}>Administrators</Text> <RightOutlined /></Flex></Button>
            }
            {
              (localStorage.getItem('role') === 'Seller' ||
              localStorage.getItem('role') === 'Admin' ||
              localStorage.getItem('role') === 'Finance' ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 100}} onClick={e => onChangeOption('drivers')}><Flex justify='space-between'><Text style={{color: 'white'}}>Drivers</Text> <RightOutlined /></Flex></Button>
            }
            {
              (localStorage.getItem('role') === 'Admin' ||
              localStorage.getItem('role') === 'Finance' ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 100}} onClick={e => onChangeOption('finance')}><Flex justify='space-between'><Text style={{color: 'white'}}>Finance</Text> <RightOutlined /></Flex></Button>
            }
            {
              (localStorage.getItem('role') === 'Seller' ||
              localStorage.getItem('role') === 'Admin' ||
              localStorage.getItem('role') === 'Finance' ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 100}} onClick={e => onChangeOption('batch')}><Flex justify='space-between'><Text style={{color: 'white'}}>Batch</Text> <RightOutlined /></Flex></Button>
            }
            {
              (localStorage.getItem('manager') === true ||
              localStorage.getItem('director') === true ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 120}} onClick={e => onChangeOption('mechanics')}><Flex justify='space-between'><Text style={{color: 'white'}}>Mechanics</Text> <RightOutlined /></Flex></Button>
            }
            {
              (localStorage.getItem('manager') === true ||
              localStorage.getItem('director') === true ||
              localStorage.getItem('email') === 'eliud@gmail.com'
              ) &&
              <Button type='primary' style={{width: 120}} onClick={e => onChangeOption('managers')}><Flex justify='space-between'><Text style={{color: 'white'}}>Managers</Text> <RightOutlined /></Flex></Button>
            }
            {
              (
                localStorage.getItem('email') === 'ec@57concrete.com' || 
                localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'bp@57concrete.com' ||
                localStorage.getItem('email') === 'vm@57concrete.com' ||
                localStorage.getItem('email') === 'mgr@57concrete.com' ||
                localStorage.getItem('email') === 'ji@57concrete.com'
              ) &&
              <Button type='primary' style={{width: 80}} onClick={e => onChangeOption('qc')}><Flex justify='space-between'><Text style={{color: 'white'}}>QC</Text> <RightOutlined /></Flex></Button>
            }
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '2',
          label: 'Trucks',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 100}}><Flex justify='space-between' onClick={e => onChangeOption('trucks')}><Text style={{color: 'white'}}>Trucks</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '3',
          label: 'Email',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 180}}><Flex justify='space-between' onClick={e => onChangeOption('emails')}><Text style={{color: 'white'}}>Email receipient list</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '4',
          label: 'Products (PSI) / Aggregates / COGS',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 120}}><Flex justify='space-between' onClick={e => onChangeOption('products')}><Text style={{color: 'white'}}>Products</Text> <RightOutlined /></Flex></Button>
            <Button type='primary' style={{width: 130}}><Flex justify='space-between' onClick={e => onChangeOption('aggregates')}><Text style={{color: 'white'}}>Aggregates</Text> <RightOutlined /></Flex></Button>
            <Button type='primary' style={{width: 100}}><Flex justify='space-between' onClick={e => onChangeOption('cogs')}><Text style={{color: 'white'}}>COGS</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '5',
          label: 'Foreman',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 180}}><Flex justify='space-between' onClick={e => onChangeOption('foremans')}><Text style={{color: 'white'}}>Manage Foreman</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '6',
          label: 'Projects',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 180}}><Flex justify='space-between' onClick={e => onChangeOption('projects')}><Text style={{color: 'white'}}>Manage Projects</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '7',
          label: 'Customers',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 200}} onClick={e => onChangeOption('customers')}><Flex justify='space-between'><Text style={{color: 'white'}}>Customer Type List</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
        (localStorage.getItem('role') === 'Seller' ||
        localStorage.getItem('role') === 'Admin' ||
        localStorage.getItem('role') === 'Finance' ||
        localStorage.getItem('email') === 'eliud@gmail.com'
        ) &&
        {
          key: '8',
          label: 'Company',
          children: <Flex gap="middle" vertical>
            <Button type='primary' style={{width: 180}} onClick={e => onChangeOption('company')}><Flex justify='space-between'><Text style={{color: 'white'}}>Manage Company</Text> <RightOutlined /></Flex></Button>
            <Button type='primary' style={{width: 100}} onClick={e => onChangeOption('plants')}><Flex justify='space-between'><Text style={{color: 'white'}}>Plants</Text> <RightOutlined /></Flex></Button>
            <Button type='primary' style={{width: 220}} onClick={e => onChangeOption('driverPayments')}><Flex justify='space-between'><Text style={{color: 'white'}}>Driver Payments Per Trip</Text> <RightOutlined /></Flex></Button>
          </Flex>,
        },
      ];

    const CustomSettings = () => {
        return <Collapse accordion items={items} />
    }

    return (
        <Layout
        title='Settings'
        description='Application configuration'
        >
            <CustomSettings />
        </Layout>
    )
}

export default Settings