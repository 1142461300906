import React, { useEffect, useState, useRef } from 'react'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import axios from 'axios'

const SellerYardDev = ({match}) => {
    const sellerID = match.params.id
    const today = new Date()
    const currentYear = today.getFullYear()
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const [yards, setYards] = useState([])
    const [sellerName, setSellerName] = useState('')
    const [titleLabel, setTitleLabel] = useState("Seller Accomplishment")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        getSellerInfo()
    }, [])

    const getData = async() => {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        const url = api + '/jobsellergroup'

        try {
            const {data} = await axios.get(url)
            const vendedor = data.filter(job => job.seller === sellerID)
            const thisYear = vendedor.filter(job => job.year === currentYear)     
            const iniciative = thisYear.filter(job => job.classification === 'developoment')

            const nuevo = iniciative.map(job => {
                return {
                    customer: job.customerNumber + ' ' + job.name,
                    yards:job.yards,
                    month: job.month
                }
            })
            nuevo.sort((a,b) => a.customer > b.customer ? 1 : -1)
            setYards(nuevo)            
            setLoading(false)
        } catch(err) {
            console.error(err.message)
            return null
        }
    }

    const getSellerInfo = async () => {
        const url = api + `/users/${sellerID}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setSellerName(data.name)
            setTitleLabel(`Performance @${data.name}`)
            // sellerName.current = data.name
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const DrawYards = () => {
        const monthList = months.map((m) => { return { month: m, realYards: 0, } })

        const yardsSold = yards.map(job => {
            return {
                customer: job.customer,
                'JAN': 0,
                'FEB': 0, 
                'MAR': 0, 
                'APR': 0, 
                'MAY': 0, 
                'JUN': 0, 
                'JUL': 0, 
                'AUG': 0, 
                'SEP': 0, 
                'OCT': 0, 
                'NOV': 0, 
                'DEC': 0
            }
        })    

        yards.forEach(job => {
            const cliente = job.customer
            const mes = job.month
            const yarda = job.yards
            const monthName = months[mes-1]
            
            const pos = yardsSold.map(function(e) {return e.customer}).indexOf(cliente)
            // console.log(`cliente: ${cliente} , mes: ${mes}, posicion: ${pos}, monthname: ${monthName}, yarda: ${yarda}`) 
            yardsSold[pos][monthName] = yarda
        })        
        
        return yardsSold.map((job, i) => {   
            return <tr key={i}>
                <td>{job.customer} </td>    
                <td style={{ textAlign: "right"}}> {job.JAN} </td>
                <td style={{ textAlign: "right"}}> {job.FEB} </td>  
                <td style={{ textAlign: "right"}}> {job.MAR} </td>  
                <td style={{ textAlign: "right"}}> {job.APR} </td>  
                <td style={{ textAlign: "right"}}> {job.MAY} </td>  
                <td style={{ textAlign: "right"}}> {job.JUN} </td>  
                <td style={{ textAlign: "right"}}> {job.JUL} </td>  
                <td style={{ textAlign: "right"}}> {job.AUG} </td>  
                <td style={{ textAlign: "right"}}> {job.SEP} </td>  
                <td style={{ textAlign: "right"}}> {job.OCT} </td>  
                <td style={{ textAlign: "right"}}> {job.NOV} </td>  
                <td style={{ textAlign: "right"}}> {job.DEC} </td>               
            </tr>
        })
    }

    const RenderForecasts = () => (
        <div style={{fontSize: '12px'}}>
            <table className='table'>
                <thead>
                    <tr>
                        <td> </td>
                        <td className="text-center"><strong>JAN</strong></td>
                        <td className="text-center"><strong>FEB</strong></td>
                        <td className="text-center"><strong>MAR</strong></td>
                        <td className="text-center"><strong>APR</strong></td>
                        <td className="text-center"><strong>MAY</strong></td>
                        <td className="text-center"><strong>JUN</strong></td>
                        <td className="text-center"><strong>JUL</strong></td>
                        <td className="text-center"><strong>AUG</strong></td>
                        <td className="text-center"><strong>SEP</strong></td>
                        <td className="text-center"><strong>OCT</strong></td>
                        <td className="text-center"><strong>NOV</strong></td>
                        <td className="text-center"><strong>DEC</strong></td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td> Client </td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                        <td className="text-center">REAL</td>
                    </tr>
                </thead>
                <tbody>
                    <DrawYards />
                </tbody>
            </table>
        </div>        
    )

    return (
        <Layout
            title={titleLabel}
            description='Yards sold'
        >
            { 
                loading ? <Spinner /> : <RenderForecasts />
            }            
        </Layout>
    )
} 

export default SellerYardDev