import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { api } from '../utils/Api'
import Modal from 'react-bootstrap/Modal'
import PaymentInvoice from '../invoice/PaymentInvoice'

const Company = props => {
    const [companyName, setCompanyName] = useState('')
    const [address, setAddress] = useState('')
    const [federalTax, setFederalTax] = useState(0)
    const [stateTax, setStateTax] = useState(0)
    const [countyTax, setCountyTax] = useState(0)
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [pushMinutes, setPushMinutes] = useState(10)
    const [fence, setFence] = useState(100)
    const [showModal, setShowModal] = useState(false)


    useEffect(() => {
        getCompanyInfo()
    }, [])

    const getCompanyInfo = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setCompanyName(data.name)
            setAddress(data.address)
            setFederalTax(data.federalTax)
            setStateTax(data.stateTax)
            setCountyTax(data.countyTax)
            setLatitude(data.latitude)
            setLongitude(data.longitude)
            setPushMinutes(data.pushMinutes)
            setFence(data.fence)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const updateCompanyInfo = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const empresa = {
            name: companyName,
            address,
            federalTax,
            stateTax,
            countyTax,
            latitude,
            longitude,
            fence,
            pushMinutes
        }

        try {
            await axios.patch(url, empresa)
            props.history.push('/settings')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const submit = e => {
        e.preventDefault()
        updateCompanyInfo()
    }

    return (
        <Layout
            title='Company'
            description='Company Settings'
            className="container col-md-8 offset-md-2"
        >
            <form className='form' onSubmit={submit}>
                <div className="form-group">
                    <label className="text-muted">Company Name</label>
                    <input
                        type='text'
                        required
                        className='form-control'
                        name='companyName'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>
                <div className='form-group'>
                    <label className="text-muted">Company Address</label>
                    <input
                        type='text'
                        required
                        className='form-control'
                        name='address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className='form-group row'>
                    <div className='col-sm-6'>
                        <label className="text-muted">Latitude</label>
                        <input
                            type='text'
                            required
                            className='form-control'
                            name='latitude'
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Longitude</label>
                        <input
                            type='text'
                            required
                            className='form-control'
                            name='longitude'
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <div className='col-sm-6'>
                        <label className="text-muted">Yards for geolocation</label>
                        <input
                            type='text'
                            required
                            className='form-control'
                            name='fence'
                            value={fence}
                            onChange={(e) => setFence(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Minutes for push messages</label>
                        <input
                            type='text'
                            required
                            className='form-control'
                            name='pushMinutes'
                            value={pushMinutes}
                            onChange={(e) => setPushMinutes(e.target.value)}
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <div className='col-sm-4'>
                        <label className='text-muted'>Fed. Tax (%)</label>
                        <input
                            type='text'
                            name='federalTax'
                            className='form-control'
                            value={federalTax}
                            onChange={(e) => setFederalTax(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>State Tax (%)</label>
                        <input
                            type='text'
                            name='stateTax'
                            className='form-control'
                            value={stateTax}
                            onChange={(e) => setStateTax(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>County Tax (%)</label>
                        <input
                            type='text'
                            name='countyTax'
                            className='form-control'
                            value={countyTax}
                            onChange={(e) => setCountyTax(e.target.value)}
                        />
                    </div>
                </div>
                <div className='my-2'>
                    <button className='btn btn-success'>Save</button>
                </div>
            </form>        
            <br />
            <Link to='/daily'>Daily appointment (dev)</Link>
            <br />
            <Link to='/e-ticket/6203749a516450003007f980'>E-ticket sample (in dev) </Link>
            <br />
            <Link to='/components'>Component list</Link>
            <br />
           
               
        </Layout>
    )
}

export default Company