import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'
/* 
    "product": "3000 PSI",
        "invoiceNumber": "26946",
        "customerName": "H&L Alaniz Builders, LLC",
        "quantity": 68,
        "salesPrice": 104,
        "amount": 7072,
        "arPaid": "Unpaid",
        "salesRep": "Jesus Alberto Cantu",
        "jobNumber": 6946,
        "fileName": "Daily Invoiced CY 04-08-2022.xlsx

        detalle = detalle = {{url}}/api/salesbymixdate/id
*/ 
const SalesMixDetail = (props) => {
    console.log(props.match.params.fileName)

    const[salesMixDetail, setSalesMixDetail] = useState([])
    const[fileName, setFileName] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getSalesMixDetail()
    }, [])


    const getSalesMixDetail = async() => {
        const url = api + '/salesbymixdate/'+ props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const {data} = await axios.get(url)
            //console.log(data)
            try {
                const {data} = await axios.get(url)
                const lista = data.map(file => {
                    return {
                        fileName:file.fileName,
                        product: file.product,
                        invoiceNumber: file.invoiceNumber,
                        customerName: file.customerName,
                        quantity: file.quantity,
                        salesPrice: file.salesPrice,
                        amount: file.amount,
                        arPaid: file.arPaid,
                        salesRep: file.salesRep,
                        jobNumber: file.jobNumber
                    }
                })
                setSalesMixDetail(lista)
                setFileName(lista[0].fileName)
                setLoading(false)
            } catch (err) {
                console.error(err)
                return null
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const DrawList = () => {
        console.log(salesMixDetail)
        return salesMixDetail.map((salesMixDetail, i) => {
            return <tr key={i}>
                <td>{salesMixDetail.product}</td>
                <td>{salesMixDetail.invoiceNumber}</td>
                <td>{salesMixDetail.customerName}</td>
                <td>{salesMixDetail.quantity}</td>
                <td>{salesMixDetail.salesPrice}</td>
                <td>{salesMixDetail.amount}</td>
                <td>{salesMixDetail.arPaid}</td>
                <td>{salesMixDetail.salesRep}</td>
                <td>{salesMixDetail.jobNumber}</td>
            </tr>
        })
    }
    
    const FileTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Invoice Number</th>
                        <th>Customer Name</th>
                        <th>Quantity</th>
                        <th>Sales Price</th>
                        <th>Amount</th>
                        <th>AR Paid</th>
                        <th>Sales Rep</th>
                        <th>Job Number</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList/>
                </tbody>
            </table>
        </div>
    ) 

    return (
        <Layout
            title={"Sales in: " + fileName}
            description='Sales mix in file'
            className='container col-md-8 offset-md-2'
        >
            {
                loading ? <Spinner /> : <FileTable />
            }
        </Layout>
    )
}
 
export default SalesMixDetail