import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_REFERRAL,
    GET_REFERRAL,
    GET_REFERRALS,
    DELETE_REFERRAL,
    REFERRAL_ERROR,
} from './types'

// create new referral
export const createReferral = (formData) => async dispatch => {
    try {
        const { data } = await api.post('/referral', formData)

        dispatch({
            type: ADD_REFERRAL,
            payload: data
        })
    } catch (err) {
        const errors = err.response
        console.log(errors)

        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
        }

        dispatch({
            type: REFERRAL_ERROR,
            payload: {msg: err.response.statatusText, status: err.response.status}
        })
    }
}

// Get referal by id
export const getReferral = id => async dispatch => {
    try {
        const { data } = await api.get(`/referral/${id}`)

        dispatch({
            type: GET_REFERRAL,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: REFERRAL_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
        
    }
}

// full list of referrals
export const getReferralList = () => async dispatch => {
    try {
        const { data } = await api.get('/referral')
        dispatch({
            type: GET_REFERRALS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: REFERRAL_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// delete referral 
export const deleteReferral = id => async dispatch => {
    try {
        const { data } = await api.delete(`/referral/${id}`)

        dispatch({
            type: DELETE_REFERRAL,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: REFERRAL_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

