import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import JobItem from './JobItem'
import { api } from '../utils/Api'
import { jobIdFilter } from '../utils/JobIdFilter'

const DAppointment = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [update, setUpdate] = useState(false)
    const [jobs, SetJobs] = useState([])
    const [sumLoadYd3, setSumLoadY3] = useState(0)
    const [sumTarget, setSumTarget] = useState(0)
    const [sumBalance, setSumBalance] = useState(0)
    const [endDate, setEndDate] = useState(null)
    const [loading, setLoading] = useState(true)
    const [userEmail, setUserEmail] = useState(localStorage.getItem('email'))
    const [jobId, setJobId] = useState('')
    const [waitingInspection, setWaitingInspection] = useState(false)


    useEffect(() => {
        setLoading(true)
        setSumLoadY3(0)
        const fecha = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        console.log("Date Changed");
        getJobs(fecha)
    }, [startDate, waitingInspection])

    useEffect(() => {
        console.log('segundo effect')
    }, [update])


    const loadUpdated = (newLoad) => {
        // console.log('en call back', newLoad)
        // setLoading(true)
        let newSum = sumLoadYd3 + newLoad
        const result = Math.round(newSum * 100) / 100
        console.log("Load Updated: " + result);
        setSumLoadY3(result)
        setUpdate(!update)
        // setLoading(false)
    }

    const getJobs = async (today) => {
        const url = api + `/jobdate?date=${today}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            assignData(data) // Fill list with data
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Assign API response to local array "jobs" & calculate totals
    const assignData = (data) => {
        // suma de importe ($)
        const result = data.reduce((acc, elJob) => acc + elJob.loadYD3, 0)
        const totalLoad = Math.round(result * 100) / 100
        localStorage.setItem('loadSum', totalLoad)

        // suma de target
        var suma = 0
        var sumBalance = 0
        var sumWait = 0
        data.forEach(element => {
            let operandos = typeof element.targetYD3 === "string" ? element.targetYD3.split('+') : element.targetYD3
            if (element.jobStatus !== 'Quote' && element.jobStatus !== 'Waiting Inspection' && element.jobStatus !== 'Wait Inspection') {
                suma = suma + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
            }

            if (element.jobStatus === 'Waiting Inspection' || element.jobStatus === 'Wait Inspection') {
                sumWait = sumWait + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
            }

            var balance = element.balance ? element.balance : 0
            sumBalance = sumBalance + parseFloat(balance)
        })
        // console.log(`YD: ${totalLoad} Target: ${suma}, Balance: ${sumBalance} `)
        localStorage.setItem('targetSum', suma)
        localStorage.setItem('sumBalance', sumBalance)
        localStorage.setItem('waitingInspection', sumWait)

        SetJobs(data)
        setSumLoadY3(totalLoad)
        setSumTarget(suma)
        setSumBalance(sumBalance)
        setLoading(false)
    }

    const waitingInspectionCallback = () => {
        setWaitingInspection(!waitingInspection)
    }

    // call component for table row drawing status !== done
    const losJobs = () => {
        if (jobs.length > 0) {
            return jobs
                .filter(jb => (jb.jobStatus !== 'Operations done (AR)') &&
                    (jb.jobStatus !== 'Quote') &&
                    (jb.jobStatus != 'ok'))
                .map((currentJob, i) => {
                    return <JobItem pcb={loadUpdated} job={currentJob} key={i} action={console.log("CurrentJob " + currentJob)} />
                })
        } else {
            return (
                <tr>
                    <td colSpan="16" style={{ 'textAlign': 'center' }}>
                        No jobs for selected date
                    </td>
                </tr>
            )
        }
    }

    // call component for table row drawing status 
    const jobsForStatus = (status) => {
        if (jobs.length > 0) {
            return jobs
                .filter(jb => jb.jobStatus === status)
                .map((currentJob, i) => {
                    return <JobItem pcb={loadUpdated} job={currentJob} key={i} action={console.log(status)} wic={waitingInspectionCallback} />
                })
        }
    }

    const renderLoading = () => {
        return <Spinner />
    }

    const renderJobs = () => (
        <Fragment>
            <h3>
                {`Total YD: ${localStorage.getItem('loadSum')}, 
                    Total Target: ${localStorage.getItem('targetSum')}, 
                    Total Balance: ${localStorage.getItem('sumBalance')},
                    Waiting Inspection: ${localStorage.getItem('waitingInspection')}`}
            </h3>
            <div className="form-group row">
                <div className="col-sm-3">
                    Date:
                    <DatePicker
                        selected={startDate}
                        onChange={(startDate) => setStartDate(startDate)}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                {/* <div className="col-sm-4">
                    <label className='text-muted'>JobId</label>
                    <input
                        type='text'
                        value={jobId}
                        onChange={event => setJobId(event.target.value)}
                    />
                </div> */}
            </div>
            <div
                style={{ fontSize: '13px' }}
            >
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Confirm</th>
                            <th style={{ width: '10%' }}>Customer</th>
                            <th>JobID</th>
                            <th>Time</th>
                            <th>Job Address</th>
                            <th>Load YD3</th>
                            <th>Target YD3</th>
                            <th>Dest. time</th>
                            <th style={{ width: '4%' }}>Mix Design</th>
                            <th>Slump</th>
                            <th>Pour/Type</th>
                            <th>Status</th>
                            <th>Seller</th>
                            {
                                (userEmail === "mv@57concrete.com" || userEmail === 'ec@57concrete.com' || userEmail === "eliud@gmail.com") &&
                                <th>Hold</th>
                            }
                            <th style={{ width: '6%' }}>Comments</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {losJobs()}
                        {jobsForStatus('Operations done (AR)')}
                        {jobsForStatus('Quote')}
                        {jobsForStatus('ok')}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )

    return (
        <Layout
            title='Daily Appointment'
            description='Job list'
        >
            {
                loading ?
                    renderLoading()
                    : renderJobs()
            }
        </Layout>
    )
}

export default connect()(DAppointment)
