import React from 'react'
import { View, StyleSheet, Image } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  renglon: {     
    width: '33%',
    paddingLeft: 'auto',
    paddingRight: 'auto'
  },
  image: {    
    maxWidth: '100%',
    padding: 2,   
    margin: 5  
  },
})

class Signature extends React.Component {

  render() {
    return(
      <View style={styles.renglon} >
        <Image style={styles.image}                 
            src={this.props.picUrl}
        />
      </View>       
    )
  }
}

export default Signature
