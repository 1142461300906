import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'

const JobHistory = props => {
    const [log, setLog] = useState([])

    useEffect(() => {
        getHistory()
    }, [])

    const getHistory = async () => {
        const url = api + '/jobhistory/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        console.log(url)

        try {   
            const {data} = await axios.get(url)
            setLog(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const DrawList = () => {
        if (log.length > 0) {
            return log.map((history) => {
                return <tr key={history._id}>
                    <td>
                        { 
                            history.customer ? history.customer.customerNumber + ' ' + history.customer.name + ' ph: ' + history.foremanPhone  : 'No customer assigned!'                        
                        } 
                    </td>
                    <td> {history.number} </td>
                    <td>
                        {
                            new Date(history.scheduledDate).toLocaleDateString()
                        }
                    </td>
                    <td>
                        {
                            new Date(history.scheduledDate).toLocaleTimeString([], {timeStyle: 'short'})
                        }
                    </td>
                    <td>{ history.shipAddress}</td>
                    <td>{ history.loadYD3}</td>
                    <td>
                        {
                            history.balance ? 
                            (typeof history.targetYD3 === "string" ? history.targetYD3 : history.targetYD3.toString()) + ' + ' + history.balance :
                            (typeof history.targetYD3 === "string" ? history.targetYD3 : history.targetYD3) + ' + 0' 
                        }
                    </td>
                    <td>
                        {
                            history.destinationTime ? 
                            history.destinationTime : 
                            history.destinationMinutes 
                        }
                    </td>
                    <td>{ history.psi}</td>
                    <td>{ history.slump}</td>
                    <td>{ history.jobPoor + ' / ' + history.jobType}</td>
                    <td>{ history.jobStatus}</td>
                    <td>{ history.seller.name}</td>
                    <td>{ history.createdBy ? history.createdBy.name : 'NA'}</td>
                    <td>
                        { 
                            `${new Date(history.createdAt).toLocaleDateString()} at
                            ${new Date(history.createdAt).toLocaleTimeString([], {timeStyle: 'short'})}
                            `
                        }
                    </td>
                </tr>
            })
        } else {
            return <tr><td colspan="15">Without change log</td></tr>
        }
    }

    return (
        <Layout
            title={'Job History'}
            description='Job change log'
        >   
            <div style={{fontSize: '10px'}}>
            <table className='table'>
                <thead>
                    <tr>
                        <td>Customer</td>
                        <td>id</td>
                        <td>Date</td>
                        <td>Time</td>
                        <td>Address</td>
                        <td>Load YD3</td>
                        <td>Target YD3</td>
                        <td>Dest.Time</td>
                        <td>Mix Design</td>      
                        <td>Slump</td>                          
                        <td>Pour/Type</td>                                
                        <td>Status</td>
                        <td>Seller</td>
                        <td>Changed By</td>
                        <td>Timestamp</td>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
        </Layout>
    )
}

export default JobHistory
