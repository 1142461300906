import React from 'react'
import Select from 'react-select'

const InvoicePicker = ({action, value}) => {
    const options = [
        { value: 'total', label: 'Total' },
        { value: 'tickets', label: 'Per Tickets' },            
      ]
    return (
        <div>
            <Select
                value={value}
                options={options}
                placeholder='Select'
                onChange={action}
            />
        </div>
    )
}

export default InvoicePicker