import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import formatDate from '../utils/FormatDate'
import axios from 'axios'
import { api } from '../utils/Api'
import { toCurrency } from '../utils/Convert'
import InvoiceItem from './InvoiceItem'

const MixDesignInvoices = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(true)
    const [invoiceList, setInvoiceList] = useState([])

    useEffect(() => {
        getInvoices()
    }, [startDate, endDate])

    const getInvoices = async () => {
        const url = api + `/invoice?date=${formatDate(startDate)}&endDate=${formatDate(endDate)}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            if(data.length === 0){
                setInvoiceList([])
            }else{
                const groupByPsi = data.reduce((group, invoice) => {
                    const { psi } = invoice;
                    group[psi] = group[psi] ?? [];
                    group[psi].push(invoice);
                    return group;
                }, {});
                const arrayObjects = Object.values(groupByPsi)
                setInvoiceList(arrayObjects)
            }
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const RenderInvoice = () => {
        if(invoiceList.length > 0){
            return invoiceList.map((invoices) => {
                let totalInvoices = 0
                let totalYards = 0
                invoices.forEach((element) => {
                    totalInvoices += element.invoiceTotal
                    totalYards += element.quantity
                })
                return <div style={{paddingTop: 20}} >
                    <div className='form-group row' style={{maxWidth: '100%'}}>
                        <div className='col-sm-3'><h5>Mix Design {invoices[0].psi}  </h5></div>
                        <div className='col-sm-2'><h5>Invoices: {invoices.length} </h5></div>
                        <div className='col-sm-2'><h5>Yards: {totalYards} </h5></div>
                        <div className='col-sm-2'><h5>Total: {toCurrency(totalInvoices)} </h5></div>
                    </div>

                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Invoice Date</th>
                                <th>Invoice Number</th>
                                <th>Customer</th>
                                <th>PSI</th>
                                <th style={{ textAlign: "right" }}>Total Yards</th>
                                <th style={{ textAlign: "right" }}>Subtotal</th>
                                <th style={{ textAlign: "right" }}>Tax</th>
                                <th style={{ textAlign: "right" }}>Total</th>
                                <th>PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <Ventas invoicesList={invoices} />
                        </tbody>
                    </table>
                    
                </div>
            })
        }else{
            return <div style={{paddingTop: 20, width: '100%', textAlign:'center'}}>No sales for selected range of dates</div>
        }
    }

    const Ventas = (invoicesList) => {
        return invoicesList.invoicesList.map((currentSale) => {
            return <InvoiceItem sale={currentSale} key={currentSale._id} />
        })
    }

    return (
        <Layout
        title='Mix Design Invoices'
        description='Sale List per Mix Design'
        >
            <div className='form-group row' style={{maxWidth: '100%'}}>
                <div className='col-sm-2'>
                    <label className='text-muted'>Select Start Date</label>
                    <DatePicker
                        selected={startDate}
                        minDate={new Date('01/01/2022')}
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                    />
                </div>
                <div className='col-sm-2'>
                    <label className='text-muted'>Select End Date</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        minDate={startDate}
                        maxDate={new Date()}
                    />
                </div>
            </div>
            {
                loading ? <RenderLoading/> : <RenderInvoice/>
            } 
        </Layout>
    )
}

export default MixDesignInvoices