import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import { Col, Form, Row, Select, Input, Button, notification, Radio, DatePicker, Space, TimePicker } from 'antd'
import { customerTypeOptions } from '../../constants/Customer'
import dayjs from 'dayjs'
import {
    InfoCircleOutlined ,
  } from '@ant-design/icons'
const { TextArea } = Input
const { RangePicker } = DatePicker

const Bulksms = (props) => {
    const [phoneList, setPhoneList] = useState([])
    const [phoneAll, setPhoneAll] = useState([])
    const [clientList, setClientList] = useState([])
    const [customerType, setCustomerType] = useState('All')
    const [phoneCount, setPhoneCount] = useState(0)
    const [type, setType] = useState('now')
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm()
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getCustomerList()
    }, [])

    const getCustomerList = async () => {
        const url = api + '/allcustomers'        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setPhoneCount(data.length)
            const reducido = data.map((cliente) => ({
                _id: cliente._id,
                contactPhone: cliente.contactPhone,  
                customerType: cliente.customerType              
            }))

            setClientList(reducido)
            const lista = reducido.map(cliente => cliente.contactPhone)
            setPhoneList(lista)
            setPhoneAll(lista)

            if(props.location.state){
                const listaTemp = reducido
                .filter(cte => cte.customerType === props.location.state.customerType)
                .map(cust => cust.contactPhone)
                setPhoneList(listaTemp)
                setPhoneCount(listaTemp.length)
                setCustomerType(props.location.state.customerType)
            }

            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const sendScheduledDateSMS = async (values) => {
        const url = api + '/smsmessage'        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        let body = {}

        if(values.type === 'datetime'){
            body.customerType = values.customerType.value
            body.message = values.message
            body.scheduleDate = dayjs(values.date).toDate()
            body.phones = phoneList
            body.type = values.type
            body.status = 'Scheduled'
            body.createdBy = localStorage.getItem('userId')
        }else if(values.type === 'now'){
            body.customerType = values.customerType.value
            body.message = values.message
            body.scheduleDate = dayjs(values.date).toDate()
            body.phones = phoneList
            body.type = values.type
            body.status = 'Delivered'
            body.createdBy = localStorage.getItem('userId')
        }else if(values.type === 'range'){
            let dates = []
            let startDate = dayjs(values.dates[0]).toDate()
            let endDate = dayjs(values.dates[1]).toDate()

            /*!
                0 - sunday
                1 - monday
                2 - tuesday
                3 - wednesday
                4 - thursday
                5 - friday
                6 - saturday
            */
            let day = -1
            switch (values.days) {
                case 'Sunday':
                    day = 0
                    break;
                case 'Monday':
                    day = 1
                    break;
                case 'Tuesday':
                    day = 2
                    break;
                case 'Wednesday':
                    day = 3
                    break;
                case 'Thursday':
                    day = 4
                    break;
                case 'Friday':
                    day = 5
                    break;
                case 'Saturday':
                    day = 6
                    break;
                default:
                    break;
            }

            let tempStartDate = startDate
            let tempEndDate = endDate

            const time = dayjs(values.time)
            if(day === -1){
                let temp = new Date(tempStartDate)
                while(tempStartDate <= tempEndDate){
                    dates.push(new Date(temp.setHours(time.toDate().getHours(), time.toDate().getMinutes())))
                    temp = new Date(tempStartDate.setDate(tempStartDate.getDate() + 1))

                }
            }else{
                let temp = new Date(tempStartDate)
                while(tempStartDate <= tempEndDate){
                    if(temp.getDay() === day){
                        dates.push(new Date(temp.setHours(time.toDate().getHours(), time.toDate().getMinutes())))
                    }
                    temp = new Date(tempStartDate.setDate(tempStartDate.getDate() + 1))
                }
            }

            body.customerType = values.customerType.value
            body.message = values.message
            body.startDate = startDate
            body.endDate = endDate
            body.days = values.days
            body.phones = phoneList
            body.type = values.type
            body.dates = dates
            body.status = 'Delivered'
            body.createdBy = localStorage.getItem('userId')
        }

        try {
            await axios.post(url, body)
            openNotification('success', 'Success', 'SMS Created')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/smslist')
            }, 1000)
        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Something happened to create message')
            console.error(err)
            return null
        }
    }

    const onChangeCustomerType = selectedCustomerType => {        
        if (selectedCustomerType) {
            setCustomerType(selectedCustomerType.label)
            const lista = clientList
                            .filter(cte => cte.customerType === selectedCustomerType.label)
                            .map(cust => cust.contactPhone)
            setPhoneList(lista)
            setPhoneCount(lista.length)
        } else {
            setCustomerType('All')
            setPhoneList(phoneAll)
            setPhoneCount(phoneAll.length)
        }       
    }

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    const onFinish = (values) => {
        setLoading(true)
        if(values.message !== undefined){
            let largo = values.message.length
            if((largo < 5) || (largo > 160)){
                setLoading(false)
                openNotification('warning', 'Message Warning', 'Message cannot be less than 5 letters and more than 160')
            }else{
                sendScheduledDateSMS(values)
            }
        }
    }

    const range = (start, end) => {
        const result = []
        for (let i = start; i < end; i++) {
          result.push(i)
        }
        return result
    }

    const disabledDateTime = (date) => {
        const scheduledDateHour = dayjs().hour()

        const minutes = [];
        const currentMinute = dayjs().minute();
        if (dayjs(date).hour() === dayjs().hour()) {
            for (let i = 0; i <= currentMinute + 20; i++) {
                minutes.push(i);
            }
        }else if(dayjs(date).hour() === dayjs().hour() + 1){
            for (let i = 0; i <= (currentMinute + 20) - 60; i++) {
                minutes.push(i);
            }
        }
    
        return {
            disabledMinutes: () => minutes,
            disabledHours: () => range(0, scheduledDateHour),
        }
    }

    return (
        <Layout
            title='SMS'
            description={`Write and send bulk SMS to ${phoneCount} customers`}
            type='small'
        >
            <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                'customerType': props.location.state ? {value: props.location.state.customerType, label: props.location.state.customerType} : null, 
                'message': props.location.state ? props.location.state.message : null, 
                'type': 'now', 
                'days': 'All'
            }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        name='customerType'
                        label='Customer Type'
                        rules={[
                            {
                                required: true,
                                message: 'Select customer type'
                            }
                        ]}
                        >
                            <Select 
                            options={customerTypeOptions}
                            size='large'
                            placeholder='Select customer type'
                            allowClear
                            labelInValue
                            loading={loading}
                            disabled={loading}
                            onChange={onChangeCustomerType}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        name='message'
                        label='Message to send'
                        rules={[
                            {
                                required: true,
                                message: 'Fill message'
                            }
                        ]}
                        >
                            <TextArea 
                            rows={4}
                            disabled={loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        name='type'
                        label='Type Date/Time'
                        >
                            <Radio.Group size='large' onChange={onChangeType} disabled={loading}>
                                <Radio.Button value="now">Now</Radio.Button>
                                <Radio.Button value="datetime">Specific Date</Radio.Button>
                                <Radio.Button value="range">In Range Date</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {
                        type === 'datetime' &&
                        <Col span={24}>
                            <Form.Item
                            name='date'
                            label='Date'
                            rules={[
                                {
                                    required: true,
                                    message: 'Select date'
                                }
                            ]}
                            tooltip={{
                                title: 'Message Scheduling feature currently requires that this time is more than 15 minutes and less than 35 days ahead.',
                                icon: <InfoCircleOutlined />,
                            }}
                            >
                                <DatePicker 
                                format='MM/DD/YYYY HH:mm' 
                                size='large' 
                                showTime={{format: 'HH:mm'}}  
                                hourStep={1}
                                minDate={dayjs()}
                                maxDate={dayjs().add(35, 'days')}
                                disabledTime={disabledDateTime}
                                showNow={false}
                                disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    }
                    {
                        type === 'range' &&
                        <Col span={24}>
                            <Form.Item
                            name='dates'
                            label='Date Range'
                            rules={[
                                {
                                    required: true,
                                    message: 'Select date range'
                                }
                            ]}
                            tooltip={{
                                title: 'Message Scheduling feature currently requires that this time is more than 15 minutes and less than 35 days ahead.',
                                icon: <InfoCircleOutlined />,
                            }}
                            >
                                <RangePicker 
                                format='MM/DD/YYYY' 
                                size='large' 
                                minDate={dayjs()}
                                maxDate={dayjs().add(35, 'days')}
                                showNow={false}
                                disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    }
                    {
                        type === 'range' &&
                        <Col span={24}>
                            <Form.Item
                            name='time'
                            label='Time'
                            rules={[
                                {
                                    required: true,
                                    message: 'Select time'
                                }
                            ]}
                            >
                                <TimePicker 
                                size='large' 
                                format='HH:mm'
                                showNow={false}
                                disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                    }
                    {
                        type === 'range' &&
                        <Col span={24}>
                            <Form.Item
                            name='days'
                            label='Days'
                            >
                                <Radio.Group disabled={loading}>
                                    <Space direction="vertical">
                                        <Radio value={'All'}>All Days</Radio>
                                        <Radio value={'Monday'}>Monday</Radio>
                                        <Radio value={'Tuesday'}>Tuesday</Radio>
                                        <Radio value={'Wednesday'}>Wednesday</Radio>
                                        <Radio value={'Thursday'}>Thursday</Radio>
                                        <Radio value={'Friday'}>Friday</Radio>
                                        <Radio value={'Saturday'}>Saturday</Radio>
                                        <Radio value={'Sunday'}>Sunday</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    }
                    
                </Row>
                {
                    <Button size='large' htmlType='submit' type='primary' loading={loading} disabled={loading}>{customerType === 'all' ? 'Send SMS to all customers' : 'Send SMS to customers of selected type'}</Button>
                }
            </Form>
            {contextHolder}
        </Layout> 
    )
}

export default Bulksms