import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { pagedProspection, searchProspection } from '../../actions/prospection'
import Spinner from '../layout/Spinner'
import ProspectionItem from './ProspectionItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearch,
    faPlusSquare
} from '@fortawesome/free-solid-svg-icons'

const ProspectionList = ({ pagedProspection, searchProspection, prospection: { prospections, loading } }) => {
    const [term, setTerm] = useState('')
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const initialCustomer = 0
        const endCustomer = 15
        pagedProspection(initialCustomer, endCustomer)
    }, [])

    const DrawList = () => {
        if (prospections) {
            return prospections.map((client, i) => {
                return <ProspectionItem prospection={client} key={i}/>
            })
        } else {
            return <tr>
                <td colSpan={11}>Empty data</td>
            </tr>
        }
    }

    const search = () => {
        searchProspection(term)
        setRefresh(!refresh)
    }

    const SearchClient = () => (
        <table >
            <tr>
                <td>
                    <input
                        type='text'
                        name='term'
                        size='20'
                        className="form-control"
                        placeholder="Search Prospection..."
                        defaultValue={term}
                        onBlur={(e) => setTerm(e.target.value)}
                    />
                </td>
                <td>
                    <button onClick={search} className='btn btn-primary'>
                        <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                    </button>
                </td>
                <td>
                    <Link
                        className='btn btn-success'
                        to='/newprospection'
                    >
                        <FontAwesomeIcon icon={faPlusSquare} />&nbsp;New
                    </Link>
                </td>
            </tr>
        </table>
    )

    const ClientTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th className='sticky-headers'>Prospection</th>
                        <th className='sticky-headers'>Address</th>
                        <th className='sticky-headers'>Phone</th>
                        <th className='sticky-headers'>Seller</th>
                        <th className='sticky-headers'>Status</th>
                        <th className='sticky-headers'>Type</th>
                        <th className='sticky-headers'>Payment Type</th>
                        <th className='sticky-headers'>Quadrant Type</th>
                        <th className='sticky-headers'>Prospection Creation</th>
                        <th className='sticky-headers'>Customer Creation</th>
                        <th className='sticky-headers'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
    )

    return (
        <Layout
            title='Prospection'
            description=''
        >
            <SearchClient />
            {
                loading ? <Spinner /> : <ClientTable />
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    prospection: state.prospection
})

export default connect(mapStateToProps, { pagedProspection, searchProspection })(ProspectionList)