import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex} from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
const { Text } = Typography

const SellerList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [customerTypeList, setCustomerTypeList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                return <Text>{value}</Text>
            }
        }
    ]

    useEffect(() => {
        getCustomerTypeList()
    }, [])
    
    const getCustomerTypeList = async() => {
        setLoading(true)

        const url = api + '/customertype'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    type: item.name,
                })
            })
            setCustomerTypeList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            <CustomTable 
            columns={columns}
            data={customerTypeList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Customer Type'
            description='Customer Type List'
        >
            <Flex vertical gap={'large'}>
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default SellerList