import React, { useState } from 'react'

const CommentsModal = ({comments, pcb}) => {

    const [currentComments, setCurrentComments] = useState(comments)
    const [textComment, setTextComment] = useState('')

    const addNewMessage = () => {
        let tempComments = currentComments
        tempComments.push({createdAt: new Date(), comment: textComment})
        setCurrentComments(tempComments)
        setTextComment('')
        pcb(tempComments)
    }

    return (
        <div
            style={{ fontSize: '13px' }}
        >
            <table className='table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    {currentComments.map((comment, i) => {
                        return <tr key={i}>
                            <td style={{ fontSize: '15px' }}>
                            {
                                `${new Date(comment.createdAt).toLocaleDateString()} - ${new Date(comment.createdAt).toLocaleTimeString([], { timeStyle: 'short' })}`
                            }
                            </td>
                            <td>
                                {comment.comment}
                            </td>
                            
                        </tr>
                    })}
                </tbody>
            </table>
            
            <div className='form-group row'>
                <div className='col-sm-9'>
                    <input
                        type='text'
                        name='comment'
                        className='form-control'
                        placeholder='Comment...'
                        value={textComment}
                        onChange={(e) => setTextComment(e.target.value)}
                    />
                </div>
                <div className='col-sm-3'>
                    <button
                        className='btn btn-primary col-sm-12'
                        onClick={addNewMessage}
                    >Add +
                    </button>
                </div>
            </div>

        </div>
    )
}

export default CommentsModal