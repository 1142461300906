import {
    GET_LOYALTIES,
    GET_LOYALTY,
    ADD_LOYALTY,
    LOYALTY_ERROR,
    DELETE_LOYALTY,
    GET_YARD_FORMEN
} from '../actions/types'

const initialState = {
    loyalty: null,
    loyalties: [],
    yards: [],
    loading: true
}

function loyaltyReducer(state = initialState, action) {
    const {type, payload} = action
    switch (type) {
        case ADD_LOYALTY:
            return {
                ...state,
                loyalty: payload,
                loading: false
            }        
        case GET_LOYALTY:
            return {
                ...state,
                loyalty: payload,
                loading: false
            }
        case GET_LOYALTIES:
            return {
                ...state,
                loyalties: payload,
                loading: false
            }
        case GET_YARD_FORMEN:
            return {
                ...state,
                loading: false,
                yards: payload
            }
        case DELETE_LOYALTY:
            return {
                ...state,
                count: payload,
                loading: false
            }
        case LOYALTY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default loyaltyReducer