import { Button, Col, Form, Input, InputNumber, Row, Select, notification, Typography, Space, DatePicker, Modal, Collapse } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import {
    MinusCircleOutlined,
    PlusOutlined,
    SearchOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { CustomSpinner } from '../utils/CustomComponents'
import AddressGeolocation2 from '../map/AddressGeolocation2'
const {Title} = Typography
const { RangePicker } = DatePicker


const optionsZone = [
    { value: 'lower valley', label: 'Lower Valley' },
    { value: 'mid valley', label: 'Mid Valley' },
    { value: 'upper valley', label: 'Upper Valley' }
]

const ProjectNew = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [loadingForm, setLoadingForm] = useState(true)
    const [optionsCustomer, setOptionsCustomer] = useState([])
    const [optionsPsi, setOptionsPsi] = useState([])
    const [initialValues, setInitialValues] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getCustomers()
        getProject()
    }, [])

    const getProject = async () => {

        let psi = await getPsi()

        const url = api + '/project/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let productPriceList = []
            let options = psi
            data.productPrice.map((product) => {
                productPriceList.push({
                    psi: product.psi,
                    specialPrice: product.specialPrice,
                    listPrice: product.listPrice
                })
                let index = options.findIndex(obj => obj.value === product.psi)
                options[index].disabled = true
            })

            let dates = undefined
            if(data.start && data.end){
                let dateArray = []
                dateArray.push(dayjs(data.start))
                dateArray.push(dayjs(data.end))
                dates = dateArray
            }

            setInitialValues({
                name: data.name,
                description: data.description,
                customer: data.customer._id,
                address: data.customer.address,
                productPriceList: productPriceList,
                zone: data.zone,
                dates: dates,
                shipAddress: data.shipAddress,
                shipCity: data.shipCity,
                destinationTime: data.destinationTime,
                distance: data.distance,
                latitude: data.latitude,
                longitude: data.longitude,
            })
            setOptionsPsi(options)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
        

    const getCustomers = async () => {
        const url = api + '/allcustomers'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let { data } = await axios.get(url)
            data = data.filter(customer => customer.creditHold !== true)
            let list = []
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.name
                })
            })   
            setOptionsCustomer(list)
            setLoading(false)
            setLoadingForm(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getPsi = async () => {
        const url = api + '/psi'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url) 
            let list = []
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.psi
                })
            })     
            return list
        } catch (err) {
            console.error(err.message)
            return []
        }
    }

    const onChangeCustomer = async (id) => {
        form.setFieldValue('address', 'Loading Address...')
        const url = api + '/customer/' + id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            form.setFieldValue('address', data.address || 'No address Assigned')
        } catch (err) {
            openNotification('error', 'Error', 'Error while trying to get customer address')
            console.error(err.message)
            return null
        }
    }

    const updateProject = async (data) => {
        try {
            setLoading(true)
            
            let formData = {
                name: data.name,
                description: data.description,
                customer: data.customer,
                zone: data.zone,
                productPrice: data.productPriceList,
                shipAddress: data.shipAddress,
                shipCity: data.shipCity,
                destinationTime: data.destinationTime,
                distance: data.distance,
                latitude: data.latitude,
                longitude: data.longitude,
                updatedBy: localStorage.getItem('userId')
            }

            if(data.dates){
                formData.start = dayjs(data.dates[0]).toDate()
                formData.end = dayjs(data.dates[1]).toDate()
            }
        
            let url = api + '/project/' + props.match.params.id
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, formData)
            openNotification('success', 'Success', 'Project updated')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/projectlist')
            }, 1000);

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to update project')
            console.error(err.message)
            return null
        }
    }
    
    const onChange = (value) => {
        const productPriceList = form.getFieldValue('productPriceList')
        let options = optionsPsi
        let index = optionsPsi.findIndex(obj => obj.value === value)
        options[index].disabled = true
        options.map((item) => {
            if(productPriceList.find(o => o.psi === item.value)){
                item.disabled = true
            }else{
                item.disabled = false
            }
        })
        setOptionsPsi(options)
    }

    const onRemoveItem = (removeFunction, name) => {
        let productPriceList = form.getFieldValue('productPriceList')
        if(productPriceList[name] === undefined){
            removeFunction(name)
        }else if(!productPriceList[name].psi){
            removeFunction(name)
        }else{
            let psi = productPriceList.at(name).psi
            let options = optionsPsi
            let index = optionsPsi.findIndex(obj => obj.value === psi)
            options[index].disabled = false
            setOptionsPsi(options)
            removeFunction(name)
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    // address from modal
    const handleCallback = (childData) => {
        form.setFieldValue('latitude', childData.destLat || undefined)
        form.setFieldValue('longitude', childData.destLng || undefined)
        form.setFieldValue('shipCity', childData.locality || '')
        form.setFieldValue('distance', childData.distance || '')
        form.setFieldValue('destinationTime', childData.duration || '')
        form.setFieldValue('shipAddress', childData.address || '')
        hideModal()
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const hideModal = () => {
        setIsModalOpen(false)
    }

    // reset form fields when modal is form, closed
    const useResetFormOnCloseModal = ({ form, open }) => {
        const prevOpenRef = useRef()
        useEffect(() => {
            prevOpenRef.current = open;
        }, [open])

        const prevOpen = prevOpenRef.current
        useEffect(() => {
            if (!open && prevOpen) {
                form.resetFields();
            }
        }, [form, prevOpen, open])
    }

    const ModalForm = ({ title, open, onCancel }) => {
        useResetFormOnCloseModal({
            open,
        })

        return (
        <Modal title={title} open={open} onCancel={onCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <AddressGeolocation2
                search={open}
                parentCallback={handleCallback}
                single={false}
            />
        </Modal>
        )
    }

    const onFormFinish = (name, {values}) => {
        if (name === 'basicForm') {
            updateProject(values)
        }
    }

    const itemsAddress = [
        {
            key: '1',
            label: 'Address Details',
            children: <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                    name='shipCity'
                    label='City'
                    >
                        <Input size='large' disabled />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                    name='distance'
                    label='Distance'
                    >
                        <Input size='large' disabled />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                    name='destinationTime'
                    label='Duration (minutes)'
                    >
                        <Input size='large' disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name='latitude'
                    label='Latitude'
                    >
                        <InputNumber size='large' disabled style={{width: '100%'}} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name='longitude'
                    label='Longitude'
                    >
                        <InputNumber size='large' disabled style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
        }
    ]

    const CustomForm = () => {
        return <Form.Provider
        onFormFinish={onFormFinish}
        ><Form
            form={form}
            layout='vertical'
            name='basicForm'
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Description'
                    name='description'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill description'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Customer'
                    name='customer'
                    rules={[
                        {
                            required: true,
                            message: 'Please select customer'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsCustomer}
                        placeholder="Please select customer"
                        onChange={onChangeCustomer}
                        filterOption={filterOption}
                        showSearch
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Address'
                    name='address'
                    >
                        <Input size='large' disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Zone'
                    name='zone'
                    rules={[
                        {
                            required: true,
                            message: 'Please select zone'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsZone}
                        placeholder="Please select zone"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={3}>Shipping Address</Title>
                </Col>
                <Col span={6}>
                    <Button size='large' icon={<SearchOutlined />} onClick={showModal} type='primary'>Search Address</Button>
                </Col>
                <Col span={18}>
                    <Form.Item
                    name='shipAddress'
                    label=''
                    >
                        <Input 
                        disabled
                        size='large' 
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Collapse accordion items={itemsAddress} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={3}>Products</Title>
                </Col>
                <Col span={24}>
                    <Form.List name="productPriceList">
                        {(fields, { add, remove }) => (
                            <>
                            {
                            fields.map(({ key, name, ...restField }) => (
                                <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                }}
                                align="baseline"
                                >
                                
                                <Form.Item
                                    {...restField}
                                    name={[name, 'psi']}
                                    label='PSI'
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Missing psi item',
                                    },
                                    ]}
                                >
                                    <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    size='large'
                                    placeholder="Please select psi"
                                    options={optionsPsi}
                                    onChange={e => onChange(e,name)}
                                    filterOption={filterOption}
                                    showSearch
                                    />
                                </Form.Item>
                                <Form.Item
                                {...restField}
                                name={[name, 'specialPrice']}
                                label='Contract Price'
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing contract price',
                                },
                                ]}
                                >
                                    <InputNumber 
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                                <Form.Item
                                {...restField}
                                name={[name, 'listPrice']}
                                label='Spot Price'
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing spot price',
                                },
                                ]}
                                >
                                    <InputNumber 
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => {onRemoveItem(remove,name)}} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add
                                </Button>
                            </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    name='dates'
                    label='Range Dates'
                    >
                        <RangePicker size='large' format={'MM/DD/YYYY'} />
                    </Form.Item>
                </Col>
            </Row>
                
            <Button type="primary" htmlType="submit" loading={loading} size='large'>
                Save
            </Button>
        </Form>
        <ModalForm 
            title="Address" 
            open={isModalOpen} 
            onCancel={hideModal} 
            />
        </Form.Provider>
    }

    return (
        <Layout
        title='Edit Project'
        description='Project'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm /> }
        </Layout>
    )
}

export default ProjectNew