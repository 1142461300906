import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toCurrency } from '../utils/Convert'

const ClientWithInvoiceItem = ({invoice, taxes, pcb}) => {

    const [tax, setTax] = useState(0)
    const [total, setTotal] = useState(0)
    const [material, setMaterial] = useState(0)
    const [balance, setBalance] = useState(0)

    useEffect(() => {
        calculateTaxes(invoice)
    }, [])
    

    const calculateTaxes = (invoice) => {
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = taxes['state']
                break
            case 'county':
                taxPercentaje = taxes['county']
                break
            case 'exempt':
                taxPercentaje = taxes['exempt']
                break
            default:
                taxPercentaje = taxes['state']
        }
        if(invoice.job){
            let yards = invoice.job.loadYD3 && invoice.job.loadYD3 > 0 ? invoice.job.loadYD3 : (typeof invoice.job.targetYD3 === "string" ? parseFloat(invoice.job.targetYD3) : invoice.job.targetYD3)
            const material = invoice.job.unitPrice * yards
            setMaterial(material)
            const trips = Math.ceil(yards/10)
            const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
            const delivery = invoice.shippingFee || invoice.job.deliveryFee      
            const color = invoice.color ? invoice.colorTicket : 0
            const fiber = invoice.fiber ? invoice.fiberBags : 0
            const size = invoice.size ? invoice.sizeBags : 0
            const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
            const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
            const taxas = subtotal * taxPercentaje
            const total = subtotal + taxas
            setTax(taxas)
            setTotal(total)
            if(invoice.payments.length === 0){
                setBalance(total)
            }else{
                setBalance(invoice.balance)
            }
        }else{
            const taxas = invoice.amount * taxPercentaje
            const total = invoice.amount + taxas
            setTax(taxas)
            setTotal(total)
            if(invoice.payments.length === 0){
                setBalance(total)
            }else{
                setBalance(invoice.balance)
            }
        }
    }

    const DrawPaymentsRegistered = ({invoiceId, payments}) => {
        if (payments.length === 0) {
            return <tr>
                <td>No payments</td>
            </tr>
        } else {
            return payments.map((pago, index) => {
                return <tr key={index} >
                    <td>
                        <Link to={{pathname:`/paymentpdf/${invoiceId}`, state: {paymentId: pago._id}}}>
                            {`${pago.type}:${toCurrency(pago.amount,2)}`}
                        </Link>
                    </td>
                </tr>
            })
        }      
    }

    return <tr key={invoice._id}>
        <td>
            <Link to={`/invoicepdf/${invoice._id}`}>
                {invoice.invoiceNumber}
            </Link>                    
        </td>
        <td style={{ maxWidth: 100 }}> 
            {invoice.customer.customerNumber + ' ' + invoice.customer.name}
        </td>
        <td>
            { new Date(invoice.invoiceDate).toLocaleDateString() }
        </td>
        <td>{invoice.psi} </td>
        <td>{invoice.quantity} </td>
        <td style={{ textAlign: "right" }}>
            {toCurrency(material, 2)} 
        </td>
        <td style={{ textAlign: "right" }}>
            {toCurrency(tax, 2)}
        </td>
        <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {toCurrency(total, 2)} 
        </td>
        <td style={{ textAlign: "right" }}>
            {toCurrency(balance,2)}
        </td>
        <td style={{ textAlign: "right" }}>
            <button 
            onClick={() =>{
                pcb(invoice.job._id)
            }   
            } 
            className='btn btn-primary'>
                Apply
            </button> 
        </td>
        <td>
            <table>
                <tbody>
                    <DrawPaymentsRegistered invoiceId={invoice._id} payments={invoice.payments} />
                </tbody>
            </table>
        </td>
    </tr>
}

export default ClientWithInvoiceItem