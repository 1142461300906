import React from 'react'
import { Link } from 'react-router-dom'
import { searchClient } from '../../actions/client'
import { toCurrency } from '../utils/Convert'

const InvoiceItem = ({sale}) => {
    return (
        <tr>
            <td>                
                {new Date(sale.invoiceDate).toLocaleDateString()}
            </td>
            <td>
                {/* <Link to={'/invoiceedit/' + sale._id}>{sale.invoiceNumber}</Link> */}
                {sale.invoiceNumber}
            </td>
            <td>
                {sale.customer.customerNumber + ' ' + sale.customer.name}
            </td>
            <td>
                {sale.psi}
            </td>
            <td style={{ textAlign: "right" }}>
                {sale.quantity}
            </td>
            <td style={{ textAlign: "right" }}>
                {toCurrency(sale.amount,2)}
            </td>
            <td style={{ textAlign: "right" }}>
                {toCurrency(sale.taxAmount,2)}            
            </td>
            <td style={{ textAlign: "right" }}>
                {toCurrency(sale.invoiceTotal, 2)}
            </td>
            <td>
                <Link to={'/invoicepdf/'+sale._id}>                
                    Invoice
                </Link>
            </td>
        </tr>
    )
}


export default InvoiceItem