import React, { useState } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import { cityList } from '../utils/CityList'
import Select from 'react-select'

const CityNew = props => {
    const [city, setCity] = useState('')
    const [fee, setFee] = useState(0)

    const options = cityList

    const setSelected = selected => {
        setCity(selected.value)
    }

    const saveCity = async () => {
        const url = api + '/deliveryfee'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const feeNum = Number(fee)
        const cityFee = {
            city,
            unitPrice: feeNum
        }

        try {
            await axios.post(url, cityFee)
            props.history.push('/citydeliveryfee')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const submit = e => {
        e.preventDefault()
        saveCity()
    }

    return (
        <Layout
            title='Drivers'
            description='Define new driver'
            className="container col-md-8 offset-md-2"
        >
            <form className="form" onSubmit={submit}>
                <div className="form-group">
                    <label className="text-muted">City</label>
                    <Select
                        options={options}
                        onChange={setSelected}
                    />
                </div>
                <div className="form-group">
                    <label className="text-muted">Delivery Fee</label>
                    <input name="Delivery Fee"
                        type="number"
                        className="form-control"
                        value={fee}
                        onChange={(e) => setFee(e.target.value)}
                    />
                </div>
                <div className="my-2">
                    <button className="btn btn-primary">Submit</button>
                </div>
            </form>
        </Layout>
    )
}

export default CityNew