// import React, { useEffect, useState } from 'react'
// import axios from 'axios'
// import Layout from '../layout/Layout'
// import { connect } from 'react-redux'
// import { getAggregateList } from '../../actions/aggregate'
// import { getJobsDate } from '../../actions/job'
// import Spinner from '../layout/Spinner'
// import DatePicker from 'react-datepicker'
// import { api } from '../utils/Api'

// const Production = ({ getAggregateList, getJobsDate, job: { /*jobs,*/ loading: jobsLoading }, aggregate: { aggregates, loading: aggregateLoading } }) => {

//     const [startDate, setStartDate] = useState(new Date())
//     const [finalList, setList] = useState([])
//     const [change, setChange] = useState(false)
//     const [totalGravel, setTotalGravel] = useState(0)
//     const [totalSand, setTotalSand] = useState(0)
//     const [totalCement, setTotalCement] = useState(0)
//     const [totalPeaGravel, setTotalPeaGravel] = useState(0)
//     const [totalLimestone, setTotalLimestone] = useState(0)
//     const [loading, setLoading] = useState(false)

//     useEffect(() => {
//         const today = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`

//         getAggregateList()
//         getJobsDate(today)
//         getJobs(today)

//     }, [startDate, jobsLoading, aggregateLoading])

//     const changeDate = (date) => {
//         console.log(date)
//         setStartDate(date)
//         setChange(!change)
//     }

//     // Axios
//     const getAggregates = async () => {
//         const url = api + '/aggregatelist'
//         const token = localStorage.getItem('token')
//         axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

//         try {
//             const { data } = await axios.get(url)
//         } catch (err) {
//             console.error(err.message)
//             return null
//         }
//     }

//     const getJobs = async (today) => {
//         const url = api + `/jobdate?date=${today}`
//         const token = localStorage.getItem('token')
//         axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

//         try {
//             setLoading(true)
//             const { data } = await axios.get(url)
//             createList(data) // Fill list with data
//             setLoading(false)
//         } catch (err) {
//             console.error(err.message)
//             return null
//         }
//     }

//     const createList = (jobs) => {

//         // Create list with times for filling
//         let productionList = []
//         for (let i = 0; i < 24; i++) {
//             let obj = {}
//             obj['time'] = (i < 10 ? `0${i}:00` : `${i}:00`)
//             obj['gravel'] = 0
//             obj['sand'] = 0
//             obj['cement'] = 0
//             obj['peaGravel'] = 0
//             obj['limestone'] = 0
//             productionList.push(obj)
//         }

//         if (!jobsLoading && jobs) {

//             let noTimeCounter = 0
//             let jobsWithNoTime = []
//             let totalG = 0
//             let totalS = 0
//             let totalC = 0
//             let totalP = 0
//             let totalL = 0

//             //console.log("JOBS: ")
//             //console.log(jobs)

//             for (let i = 0; i < jobs.length; i++) {
//                 let time = ''
//                 let foundAggregate = 0
//                 let gravel = 0
//                 let sand = 0
//                 let cement = 0
//                 let peaGravel = 0
//                 let limestone = 0

//                 try {
//                     //time = (jobs[i].scheduledTime).substring(0, 5)
//                     time = (jobs[i].scheduledDate).substring(11, 13) + ":00" // Que hacer con minutos?
//                 } catch (err) {
//                     console.log(`Job con ID: ${jobs[i]._id} (job number: ${jobs[i].number}) no tiene tiempo`)
//                     time = "00:00" // Asign time 00:00 to jobs with no time
//                     noTimeCounter++
//                     jobsWithNoTime.push(jobs[i].number)
//                 }

//                 const psi = jobs[i].psi
//                 const yd3 = isNaN(jobs[i].targetYD3) ? 0 : jobs[i].targetYD3

//                 //console.log(aggregates)

//                 try {
//                     foundAggregate = aggregates.find(aggregate => aggregate.psi.psi === psi)
//                 } catch (err) {
//                     console.log(`Aggregate: ${psi} not yet created`)
//                 }

//                 if (foundAggregate) {
//                     gravel = isNaN(foundAggregate.gravel) ? 0 : foundAggregate.gravel
//                     sand = isNaN(foundAggregate.sand) ? 0 : foundAggregate.sand
//                     cement = isNaN(foundAggregate.cement) ? 0 : foundAggregate.cement
//                     peaGravel = isNaN(foundAggregate.peaGravel) ? 0 : foundAggregate.peaGravel
//                     limestone = isNaN(foundAggregate.limestone) ? 0 : foundAggregate.limestone
//                 }

//                 // Index of time list, to increase the quantities
//                 const index = productionList.map(i => i.time).indexOf(time)

//                 let currObj = productionList[index]

//                 currObj.gravel += gravel * yd3
//                 currObj.sand += sand * yd3
//                 currObj.cement += cement * yd3
//                 currObj.peaGravel += peaGravel * yd3
//                 currObj.limestone += limestone * yd3

//                 totalG += gravel * yd3
//                 totalS += sand * yd3
//                 totalC += cement * yd3
//                 totalP += peaGravel * yd3
//                 totalL += limestone * yd3

//                 productionList[index] = currObj
//             }

//             setList(productionList)

//             setTotalGravel(totalG)
//             setTotalSand(totalS)
//             setTotalCement(totalC)
//             setTotalPeaGravel(totalP)
//             setTotalLimestone(totalL)

//         } else {
//             setChange(!change)
//         }
//     }

//     const DrawHeader = () => {
//         return <tr key={1}>
//             <td>

//             </td>
//             <td style={{ textAlign: "right", fontWeight: "bold" }}>
//                 {totalGravel.toLocaleString('en-US')}
//             </td>
//             <td style={{ textAlign: "right", fontWeight: "bold" }}>
//                 {totalSand.toLocaleString('en-US')}
//             </td>
//             <td style={{ textAlign: "right", fontWeight: "bold" }}>
//                 {totalCement.toLocaleString('en-US')}
//             </td>
//             <td style={{ textAlign: "right", fontWeight: "bold" }}>
//                 {totalPeaGravel.toLocaleString('en-US')}
//             </td>
//             <td style={{ textAlign: "right", fontWeight: "bold" }}>
//                 {totalLimestone.toLocaleString('en-US')}
//             </td>
//         </tr>
//     }

//     const DrawList = () => {
//         return finalList.map((list) => {
//             return <tr key={list.time}>
//                 <td>
//                     {list.time}
//                 </td>
//                 <td style={{ textAlign: "right" }}>
//                     {list.gravel.toLocaleString('en-US')}
//                 </td>
//                 <td style={{ textAlign: "right" }}>
//                     {list.sand.toLocaleString('en-US')}
//                 </td>
//                 <td style={{ textAlign: "right" }}>
//                     {list.cement.toLocaleString('en-US')}
//                 </td>
//                 <td style={{ textAlign: "right" }}>
//                     {list.peaGravel.toLocaleString('en-US')}
//                 </td>
//                 <td style={{ textAlign: "right" }}>
//                     {list.limestone.toLocaleString('en-US')}
//                 </td>
//             </tr>
//         })
//     }

//     const RenderList = () => (
//         <table className='table'>
//             <tbody>
//                 <DrawHeader />
//             </tbody>
//             <thead>
//                 <tr>
//                     <td>Time</td>
//                     <td style={{ textAlign: "right" }}>57 Gravel</td>
//                     <td style={{ textAlign: "right" }}>Sand</td>
//                     <td style={{ textAlign: "right" }}>Cement</td>
//                     <td style={{ textAlign: "right" }}>PEA Gravel</td>
//                     <td style={{ textAlign: "right" }}>Limestone</td>
//                 </tr>
//             </thead>
//             <tbody>
//                 <DrawList />
//             </tbody>
//         </table>
//     )

//     return (
//         <Layout
//             title='Production'
//             description='Production List'
//             className='container col-md-8 offset-md-2'
//         >
//             <div className='col-sm-2'>
//                 <label className="text-muted">Select Date</label>
//                 <DatePicker
//                     selected={startDate}
//                     onChange={(date) => changeDate(date)}
//                 />
//             </div>
//             {
//                 loading ? <Spinner /> : (aggregates ? <RenderList /> : <h4> {"No data for this date..."} </h4>)
//             }
//         </Layout>
//     )
// }

// const mapStateToProps = state => ({
//     aggregate: state.aggregate,
//     job: state.job
// })

// export default connect(mapStateToProps, { getAggregateList, getJobsDate })(Production)

import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button, Row, Col, DatePicker, Select, Checkbox, TimePicker, Modal, Card, Statistic, Form, Input, Table, Skeleton, notification } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import { jobStatusOptions } from '../../constants/Job'

const { Text, Link, Title } = Typography

const Production = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [loadingTable, setLoadingTable] = useState(false)
    const [date, setDate] = useState(dayjs())
    const [plant, setPlant] = useState(localStorage.getItem('plant') || '61985b6a86825f39cc4aba38')
    const [jobList, setJobList] = useState([])
    const [optionsPlant, setOptionsPlant] = useState([])
    const [disabled, setDisabled] = useState(true)


    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Now',
            dataIndex: 'now',
            key: 'now',
            render: (value) => {
                return <Statistic
                value={value}
                valueStyle={{
                    fontSize: 14
                }}
                />
            }
        },
        {
            title: 'Today',
            dataIndex: 'today',
            key: 'today',
            render: (value) => {
                return <Statistic
                value={value}
                valueStyle={{
                    fontSize: 14
                }}
                />
            }
        }
    ]

    useEffect(() => {
        getJobs(date, plant)
    }, [date,plant])

    useEffect(() => {
        getPlants()
    }, [])

    const getPlants = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let list =[]
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.name
                })
            })
            setOptionsPlant(list)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const getJobs = async(date, plant) => {
        try {
            setLoading(true)
            setDisabled(true)

            let formattedDate = dayjs(date)
            let today = `${formattedDate.year()}-${formattedDate.month()+1}-${formattedDate.date()}`
            const url = api + `/jobpsi?date=${today}&plant=${plant}`

            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            let { data } = await axios.get(url)

            let sandQuantity = 0
            let gravelQuantity = 0
            let peaGravelQuantity = 0
            let cementQuantity = 0
            let sandQuantityPrev = 0
            let gravelQuantityPrev = 0
            let peaGravelQuantityPrev = 0
            let cementQuantityPrev = 0

            data.map((item) => {
                if(item.aggregates !== null){
                    item.aggregates.cogs.map((cog) => {
                        if(cog.cog.material === 'Sand'){
                            if(dayjs(item.scheduledDate).isBefore(formattedDate)){
                                sandQuantityPrev += (cog.quantity * item.targetYD3)
                            }
                            sandQuantity += (cog.quantity * item.targetYD3)
                        }else if(cog.cog.material === 'Cem 1'){
                            if(dayjs(item.scheduledDate).isBefore(formattedDate)){
                                cementQuantityPrev += (cog.quantity * item.targetYD3)
                            }
                            cementQuantity += (cog.quantity * item.targetYD3)
                        }else if(cog.cog.material === 'Gravel 57'){
                            if(dayjs(item.scheduledDate).isBefore(formattedDate)){
                                gravelQuantityPrev += (cog.quantity * item.targetYD3)
                            }
                            gravelQuantity += (cog.quantity * item.targetYD3)
                        }else if(cog.cog.material === 'Pea Gravel'){
                            if(dayjs(item.scheduledDate).isBefore(formattedDate)){
                                peaGravelQuantityPrev += (cog.quantity * item.targetYD3)
                            }
                            peaGravelQuantity += (cog.quantity * item.targetYD3)
                        }
                    })
                }
            })

            const list = [
                {
                    material: 'Sand',
                    now: sandQuantityPrev,
                    today:sandQuantity
                },
                {
                    material: 'Gravel 57',
                    now: gravelQuantityPrev,
                    today:gravelQuantity
                },
                {
                    material: 'Pea Gravel',
                    now: peaGravelQuantityPrev,
                    today:peaGravelQuantity
                },
                {
                    material: 'Cem 1',
                    now: cementQuantityPrev,
                    today:cementQuantity
                },
            ]


            setJobList(list)
            setLoading(false)
            setDisabled(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }



    const onChangeDate = (value) => {
        setDisabled(true)
        setDate(value)
    }

    const onChangePlant = (value) => {
        setDisabled(true)
        setPlant(value)
    }


    const RenderHeader = () => {
        return <Row gutter={16}>
            <Col span={6}>
                <DatePicker size='large' onChange={onChangeDate} value={date} allowClear={false} format={'MM/DD/YYYY'} style={{width: '100%'}} disabled={disabled} />
            </Col>
            <Col span={6}>
                <Select
                size='large'
                options={optionsPlant}
                value={optionsPlant.filter((o) => { return o.value === plant })}
                onChange={onChangePlant}
                style={{width: '100%'}}
                loading={disabled}
                disabled={disabled}
                />
            </Col>
        </Row>
    }

    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            <Table 
            columns={columns}
            dataSource={jobList}
            loading={loadingTable}
            pagination={false}
            />
        </Flex>
    }

    return (
        <Layout
            title='Production'
            description=''
        >
            <Flex vertical gap={'large'}>
                <RenderHeader />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default Production