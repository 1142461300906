const titlesConstants = {
    'id': ['ID', 'ID'],
    'description': ['Description', 'Descripción'],
    'priority': ['Priority', 'Prioridad'],
    'category': ['Category', 'Categoria'],
    'status': ['Status', 'Estatus'],
    'dueDate': ['Due Date', 'Fecha de vencimiento'],
    'staff': ['Staff/Supplier', 'Personal/Proveedor'],
    'buildingGroup': ['Building Group', 'Grupo de Edificios'],
    'buildings': ['Buildings', 'Edificios'],
    'active': ['Active/Locality', 'Activo/Localidad'],
    'estimatedHours': ['Total Estimated Hours', 'Total de horas estimadas'],
}

const statusConstants = {
    'approved': ['Approved', 'Aprobada'],
    'overdue': ['Overdue', 'Atrasada'],
    'cancelled': ['Cancelled', 'Cancelada'],
    'inProgress': ['In Progress', 'En curso'],
    'onHold': ['On Hold', 'En espera'],
    'pending': ['Pending', 'Pendiente'],
    'reopened': ['Reopened', 'Reabierta'],
    'rejected': ['Rejected', 'Rechazada'],
    'closed': ['Closed', 'Cerrada'],
    'finished': ['Finished', 'Terminada']
}

const statusEnglishOptions = [
    {
        label: 'Approved',
        value: 'Approved'
    },
    {
        label: 'Overdue',
        value: 'Overdue'
    },
    {
        label: 'Cancelled',
        value: 'Cancelled'
    },
    {
        label: 'In Progress',
        value: 'In Progress'
    },
    {
        label: 'On Hold',
        value: 'On Hold'
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Reopened',
        value: 'Reopened'
    },
    {
        label: 'Rejected',
        value: 'Rejected'
    },
    {
        label: 'Closed',
        value: 'Closed'
    },
    {
        label: 'Finished',
        value: 'Finished'
    },
]

const statusAllEnglishOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Approved',
        value: 'Approved'
    },
    {
        label: 'Overdue',
        value: 'Overdue'
    },
    {
        label: 'Cancelled',
        value: 'Cancelled'
    },
    {
        label: 'In Progress',
        value: 'In Progress'
    },
    {
        label: 'On Hold',
        value: 'On Hold'
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Reopened',
        value: 'Reopened'
    },
    {
        label: 'Rejected',
        value: 'Rejected'
    },
    {
        label: 'Closed',
        value: 'Closed'
    },
    {
        label: 'Finished',
        value: 'Finished'
    },
]

const priorityEnglishOptions = [
    {
        label:'Low - 7 days',
        value:'Low'
    },
    {
        label:'Medium - 5 days',
        value:'Medium'
    },
    {
        label:'High - 3 days',
        value:'High'
    },
    {
        label:'Urgent - 1 day1',
        value:'Urgent'
    }
]
const categoryEnglishOptions = [
    {
        label: 'General',
        value: 'General'
    }, 
    {
        label: 'Cleaning',
        value: 'Cleaning'
    }, 
    {
        label: 'Electric',
        value: 'Electric'
    }, 
    {
        label: 'Pneumatic System',
        value: 'Pneumatic System'
    },
    {
        label: 'Lightning',
        value: 'Lightning'
    },
    {
        label: 'Plumbing',
        value: 'Plumbing'
    },
    {
        label: 'Hydraulic System',
        value: 'Hydraulic System'
    },
    {
        label: 'External',
        value: 'External'
    },
    {
        label: 'Mechanic',
        value: 'Mechanic'
    },
    {
        label: 'Crystals/Glasses',
        value: 'Crystals/Glasses'
    },
    {
        label: 'Security',
        value: 'Security'
    },
    {
        label: 'Air Conditioning System',
        value: 'Air Conditioning System'
    },
    {
        label: 'Accessories',
        value: 'Accessories'
    },
    {
        label: 'Braking System',
        value: 'Braking System'
    }
]

export {
    titlesConstants,
    statusConstants,
    statusEnglishOptions,
    statusAllEnglishOptions,
    priorityEnglishOptions,
    categoryEnglishOptions
}