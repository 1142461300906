import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Checkbox, Tag } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
const { Text } = Typography

/**
 ** Options header (columns)
*/
const columns = [
    {
        title: 'Driver',
        dataIndex: 'driver',
        key: 'driver',
        render: (value) => {
            return <Text strong>{value}</Text>
        }
    },
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Truck',
        dataIndex: 'truck',
        key: 'truck',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Is full?',
        dataIndex: 'full',
        key: 'full',
        render: (value) => {
            return <Checkbox
                checked={value}
            />
        }
    },
    {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        render: (value) => {
            let color = 'green'
            let text = ''
            if(value === 'DELIVERY'){
                color = 'blue'
                text = 'ON DELIVERY'
            }else if(value === 'PLANT'){
                color = 'magenta'
                text = 'ON PLANT'
            }else{
                color = 'green'
                text = 'ON FREEWAY'
            }
            return <Tag color={color}>{text}</Tag>
        }
    },
    {
        title: 'Mileage Begin',
        dataIndex: 'mileageBegin',
        key: 'mileageBegin',
        render: (value) => {
            return <Text>{`${value} miles`}</Text>
        }
    },
    {
        title: 'Mileage Ending',
        dataIndex: 'mileageEnding',
        key: 'mileageEnding',
        render: (value) => {
            return <Text>{`${value} miles`}</Text>
        }
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
]

const FailuresReport = () => { 
    const [loading, setLoading] = useState(true)
    const [inspectionList, setInspectionList] = useState([])
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())

    useEffect(() => {
      getInspectionList('')
    }, [])
    
    const getInspectionList = async(term) => {
        setLoading(true)

        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/inspections/search?startDate=${start}&endDate=${end}&term=${term}&type=${'FAIL'}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item) => {
                list.push({
                    'driver': item.driver.name,
                    'plant': item.plant.name,
                    'truck': item.truck,
                    'date': dayjs(item.createdAt).format('MM/DD/YYYY HH:mm A'),
                    'full': item.full,
                    'position': item.position,
                    'mileageBegin': item.mileageBegin,
                    'mileageEnding': item.mileageEnding
                })
            })
            setInspectionList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            getInspectionList(value)
        }
    }       

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const RenderSearch = () => {
        return <CustomSearch 
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        loading={loading}
        onRangeChange={onRangeChange}
        onSearch={onSearch}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'}>
            <CustomTable 
            columns={columns}
            data={inspectionList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Failures Report'
            description='Report'
        >
            <Flex vertical gap={'large'}>
                <RenderSearch />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default FailuresReport