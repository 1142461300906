import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DataGrid from 'react-data-grid';
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { containFilter, equalFilter } from './Filters'
import { CSVLink } from "react-csv"
import { api } from '../utils/Api'

const JobReportRun = props => {     
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [dateChange, setDateChange] = useState(true)
    const [originalData, setOriginalData] = useState([])
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [title, setTitle] = useState(null)
    const [filters, setFilters] = useState([])


    useEffect(() => {
        getReport()

        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`

        if (endDate < startDate) {            
            setEndDate(startDate)
            fechaFin = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
        } 
        
        if (dateChange) {
            setLoading(true)
            getJobs(fechaIni, fechaFin)
        } 
    }, [startDate, endDate, update])

    // headers for grid. All columns resizable.
    const mapGridHeaders = (campos) => {
        const headers = campos.map((col) => {
            return {
                key: col.atribute,
                name: col.label,
                resizable: true,
            }
        })        
        setColumns(headers)
    }

    // Get only required attributes for report renderer
    const mapData = data => {
        console.log('filters:')
        console.log(filters)
        // const filtro = containFilter(data, 'jobStatus', 'done')
        // const filtro = equalFilter(data, 'jobStatus', 'ok')
        // console.log(filtro)

        let newArray = []
        newArray = data
        filters.forEach((filtro) => {
            console.log(filtro)
            if (filtro.operator === 'contains') {
                newArray = containFilter(newArray, filtro.field, filtro.value)
            }
        })

        console.log(newArray)

        const nuevo = newArray.map((dato) => {
            let customer = 'NA'
            if (dato.customer !== null) {
                customer = dato.customer.customerNumber + ' ' + dato.customer.name
            } else {
                console.log(dato)
            }

            let seller = 'NA'
            if (dato.seller !== null) {
                seller = dato.seller.name
            }

            return {
                comments: dato.comments,
                destinationMinutes: dato.destinationMinutes,
                balance: dato.balance,
                customer, 
                seller,                
                jobType: dato.jobType,   
                jobStatus: dato.jobStatus,  
                jobPoor: dato.jobPoor,   
                psi: dato.psi,     
                number: dato.number,      
                dateTime: `${new Date(dato.scheduledDate).getMonth()+1}/${new Date(dato.scheduledDate).getDate()}/${new Date(dato.scheduledDate).getFullYear()} - ${new Date(dato.scheduledDate).toLocaleTimeString([], {timeStyle: 'short'})}`,
                shipAddress: dato.shipAddress,    
                loadYD3: dato.loadYD3,
                targetYD3: dato.targetYD3,           
                slump: dato.slump, 
                shipCity: dato.shipCity,
                plant: dato.plant ? dato.plant.name : 'None'                            
            }
        })  
        
        setRows(nuevo)
    }

    // Report data
    const getReport = async () => {
        const url = api + '/report/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            console.log(data)
            setTitle(data.name)
            mapGridHeaders(data.fields)  
            setFilters(data.filters)          
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Data for report
    const getJobs = async (startDate, endDate) => {            
        const url = api + `/jobscheduled?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setOriginalData(data)
            mapData(data)                                   
            setLoading(false)            

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // heders for csv export
    const csvHeaders = () => {
        return columns.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    return (
        <Layout
            title={title ? title.toUpperCase() : 'Report'}
            description='Jobs Report'
        > 
            <div className="form-group row">
                <div className='col-sm-4'>
                    <label className='text-muted'>Start Date</label>
                    <DatePicker 
                        selected={startDate} 
                        onChange={(date)=>{
                            setStartDate(date)
                            setDateChange(true)
                        }}
                    /> 
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>End Date</label>
                    <DatePicker 
                        selected={endDate}                             
                        onChange={(date)=>{
                            setEndDate(date)
                            setDateChange(true)
                        }}                
                    /> 
                </div>                   
            </div>   
            <div>
                {
                    loading ? <Spinner /> :
                    <div>
                        <DataGrid 
                            className='rdg-light' 
                            columns={columns} 
                            rows={rows}
                        />
                        <CSVLink data={rows} headers={csvHeaders()} filename={"57concrete-report.csv"}>
                            Download report
                        </CSVLink>
                    </div>                
                }  
            </div>
        </Layout>
    )
}

export default JobReportRun