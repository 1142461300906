const jobStatusOptions = [
    {'value': 'New Order', 'label': 'New Order'},
    {'value': 'Trouble', 'label': 'Trouble'},
    {'value': 'ok', 'label': 'Poured'},
    {'value': 'Confirm', 'label': 'A/R Confirmed'},
    {'value': 'Operations done (AR)', 'label': 'Operations done (AR)'},
    {'value': 'Scheduled', 'label': 'Scheduled'},
    {'value': 'Inactive', 'label': 'Inactive'},
    {'value': 'Company cancellation', 'label': 'Company cancellation'},
    {'value': 'Customer cancellation', 'label': 'Customer cancellation'},
    {'value': 'Date moved', 'label': 'Date moved'},
    {'value': 'Active', 'label': 'Pouring'},
    {'value': 'Ready to Load', 'label': 'Ready to be scheduled'},
    {'value': 'Quote', 'label': 'Quote'},
    {'value': 'Waiting Inspection', 'label': 'Waiting Inspection'},
]

const jobTypeOptions = [
    {value: 'Chute', label: 'Chute'},
    {value: 'Pomp', label: 'Pomp'},
    {value: 'Buggy', label: 'Buggy'},
    {value: 'Wheel Barrow', label: 'Wheel Barrow'}
]

const jobPourOptions = [
    {value: 'Side Walk', label: 'Side Walk'},
    {value: 'Foundation', label: 'Foundation'},
    {value: 'Curve', label: 'Curve'},
    {value: 'Porche', label: 'Porche'},
    {value: 'Patio', label: 'Patio'},
    {value: 'Driveway', label: 'Driveway'},
    {value: 'Raip Rap', label: 'Raip Rap'},
    {value: 'Block Fill', label: 'Block Fill'},
    {value: 'Ditches', label: 'Ditches'},
    {value: 'Other', label: 'Other'}
]

const slumpOptions = [
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},
    {value: 7, label: 7},
    {value: 8, label: 8},
    {value: 9, label: 9},
]

const delayedPouringElements = [
    {value: 'Wrong number', label: 'Wrong number'},
    {value: 'Customer does not answer', label: 'Customer does not answer'},
    {value: 'Customer is not ready', label: 'Customer is not ready'},
    {value: 'Delay due to previous run', label: 'Delay due to previous run'},
    {value: 'Delay due to plant failure', label: 'Delay due to plant failure'},
    {value: 'Delay due to lack of aggregates', label: 'Delay due to lack of aggregates'},
    {value: 'Delay due to lack of payment', label: 'Delay due to lack of payment'},
    {value: 'Delay due to lack of address', label: 'Delay due to lack of address'},
]

export {
    jobStatusOptions,
    jobTypeOptions,
    jobPourOptions,
    slumpOptions,
    delayedPouringElements
}