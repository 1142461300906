import React, { useState } from 'react'
import axios from 'axios'
import { api } from '../utils/Api'

const SendWriteUp = ({employeeId, pcallback, isEmail, index}) => {
    const [destination, setDestination] = useState('')

    const send = async () => {
        if(destination !== ''){
            const payload = {
                destination,
                index
            }
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            if(isEmail){
                const url = api + `/employeewuemail/${employeeId}`
                try {
                    await axios.post(url, payload)  
                    pcallback()          
                } catch (err) {
                    console.error(err.message)
                    return null
                }
            }else{
                const url = api + `/employeewusms/${employeeId}`
                try {
                    await axios.post(url, payload)  
                    pcallback()          
                } catch (err) {
                    console.error(err.message)
                    return null
                }
            }
        } 
    }

    const changeDestination = e => {
        setDestination(e.target.value)
    }

    return (
        <div>
            <input 
                type='text'
                required
                className='form-control'
                name='destination'
                value={destination}
                onChange={changeDestination}
            />
            <button 
                onClick={send}
                className='btn btn-primary'
            >
                go
            </button>
        </div>                        
    )
}

export default SendWriteUp