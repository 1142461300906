import {
    ADD_EMAIL,
    GET_EMAIL,
    GET_EMAILS,
    UPDATE_EMAIL,
    EMAIL_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    email: null,
    emails: []
}

function emailReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_EMAIL:
            return {
                ...state,
                email: payload,
                loading: false
            }
        case GET_EMAIL:
            return {
                ...state,
                email: payload,
                loading: false
            }
        case GET_EMAILS:
            return {
                ...state,
                emails: payload,
                loading: false
            }
        case UPDATE_EMAIL:
            return {
                ...state,
                email: payload,
                loading: false
            }
        case EMAIL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default emailReducer