import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import axios from 'axios'
import { api } from '../utils/Api'
import { toCurrency } from '../utils/Convert'
import Modal from 'react-bootstrap/Modal'
import ClientPayments from '../client/ClientPayments'
import GeneralPayment from './GeneralPayment'
import { Link } from 'react-router-dom'

const GeneralPayments = (props) => {
    const [invoices, setInvoices] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [loading, setLoading] = useState(true)
    const [results, setResults] = useState(false)
    const [results2, setResults2] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalPayment, setShowModalPayment] = useState(false)
    const [customer, setCustomer] = useState({})
    const [taxes, setTaxes] = useState({})
    const [totalInvoices, setTotalInvoices] = useState(0)
    const [totalPayments, setTotalPayments] = useState(0)
    const [totalBalance, setTotalBalance] = useState(0)
    const [generalPayments, setGeneralPayments] = useState([])

    const columns = [
        { key: 'checkbox', name: '' },
        { key: 'id', name: 'ID' },
        { key: 'date', name: 'Date' },
        { key: 'customer', name: 'Customer' },
        { key: 'address', name: 'Address' },
        { key: 'psi', name: 'PSI' },
        { key: 'amount', name: 'Amount' },
        { key: 'Tax', name: 'Tax' },
        { key: 'total', name: 'Total' },
        { key: 'balance', name: 'Balance' },
        { key: 'payments', name: 'Payments' },
    ]

    const columns2 = [
        { key: 'paymentNumber', name: 'Number' },
        { key: 'date', name: 'Date' },
        { key: 'customer', name: 'Customer' },
        { key: 'invoices', name: 'Invoices' },
        { key: 'total', name: 'Total' },
    ]

    useEffect(() => {
        getTax()
        getCustomerInvoices()
        getCustomer()
        getGeneralPayments()
    }, [])

    useEffect(() => {
        calculateGeneralTotals()
    }, [invoices])

    const getCustomerInvoices = async () => {
        const url = api + '/customerinvoice/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            data.forEach(invoice => {
                invoice['selected'] = false
            });
            setInvoices(data)
            setLoading(false)
            data.length === 0 ? setResults(false) : setResults(true)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getCustomer = async () => {
        const url = api + '/customer/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setCustomer(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getGeneralPayments = async () => {
        const url = api + '/customerpayments/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setGeneralPayments(data)
            data.length === 0 ? setResults2(false) : setResults2(true)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Update List Item's state and Master Checkbox State
    const onItemCheck = (e, item) => {
        let tempList = invoices
        tempList.map((invoice) => {
            if (invoice.invoiceNumber === item.invoiceNumber) {
                invoice.selected = e.target.checked;
            }
        })
        setInvoices(tempList)
        setSelectedList(tempList.filter((e) => e.selected))
    }

    const calculatePayments = (invoice) => {
        let sum = 0
        invoice.payments.forEach(payment => {
            sum += payment.amount
        })
        return sum
    }

    const getTax = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const { data } = await axios.get(url)
            setTaxes({county: data.countyTax, state: data.stateTax, exempt: 0} )
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const calculateGeneralTotals = () => {
        let sumTotal = 0
        let sumPayments = 0
        invoices.map((invoice) => {
            sumTotal += calculateTotals(invoice)
            sumPayments += calculatePayments(invoice)
        })

        setTotalInvoices(sumTotal)
        setTotalPayments(sumPayments)
        setTotalBalance(sumTotal - sumPayments)
    }

    const calculateTotals = (invoice) =>{
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = taxes['state']
                break
            case 'county':
                taxPercentaje = taxes['county']
                break
            case 'exempt':
                taxPercentaje = taxes['exempt']
                break
            default:
                taxPercentaje = taxes['state']
        }
        if(invoice.job){
            let yards = invoice.job.loadYD3 && invoice.job.loadYD3 > 0 ? invoice.job.loadYD3 : (typeof invoice.job.targetYD3 === "string" ? parseFloat(invoice.job.targetYD3) : invoice.job.targetYD3)
            const material = invoice.job.unitPrice * yards
            const trips = Math.ceil(yards/10)
            const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
            const delivery = invoice.shippingFee || invoice.job.deliveryFee      
            const color = invoice.color ? invoice.colorTicket : 0
            const fiber = invoice.fiber ? invoice.fiberBags : 0
            const size = invoice.size ? invoice.sizeBags : 0
            const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
            const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
            const taxas = subtotal * taxPercentaje
            const total = subtotal + taxas
            return total
        }
        return 0
    }

    const calculateAmount = (invoice) =>{
        if(invoice.job){
            let yards = invoice.job.loadYD3 && invoice.job.loadYD3 > 0 ? invoice.job.loadYD3 : (typeof invoice.job.targetYD3 === "string" ? parseFloat(invoice.job.targetYD3) : invoice.job.targetYD3)
            const material = invoice.job.unitPrice * yards
            const trips = Math.ceil(yards/10)
            const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
            const delivery = invoice.shippingFee || invoice.job.deliveryFee      
            const color = invoice.color ? invoice.colorTicket : 0
            const fiber = invoice.fiber ? invoice.fiberBags : 0
            const size = invoice.size ? invoice.sizeBags : 0
            const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
            const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
            return subtotal
        }
        return 0
    }
    const calculateTaxes = (invoice) =>{
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = taxes['state']
                break
            case 'county':
                taxPercentaje = taxes['county']
                break
            case 'exempt':
                taxPercentaje = taxes['exempt']
                break
            default:
                taxPercentaje = taxes['state']
        }
        if(invoice.job){
            let yards = invoice.job.loadYD3 && invoice.job.loadYD3 > 0 ? invoice.job.loadYD3 : (typeof invoice.job.targetYD3 === "string" ? parseFloat(invoice.job.targetYD3) : invoice.job.targetYD3)
            const material = invoice.job.unitPrice * yards
            const trips = Math.ceil(yards/10)
            const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
            const delivery = invoice.shippingFee || invoice.job.deliveryFee      
            const color = invoice.color ? invoice.colorTicket : 0
            const fiber = invoice.fiber ? invoice.fiberBags : 0
            const size = invoice.size ? invoice.sizeBags : 0
            const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
            const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
            const taxas = subtotal * taxPercentaje
            return taxas
        }
        return 0
    }

    const updateGeneralPayments = () => {
        getGeneralPayments()
    }

    const TableRenderer = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        {
                            columns.map((column)=>{
                                return <th scope='col' key={column.key}>{column.name}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        results ?
                        invoices.map((row, key)=>{
                            return <tr key={key} >
                                
                                {
                                    calculateTotals(row) === calculatePayments(row)
                                    ?
                                    <th></th>
                                    :
                                    <th scope="row">
                                        <input
                                            type="checkbox"
                                            checked={row.selected}
                                            className="form-check-input"
                                            id={`rowcheck${row.invoiceNumber}`}
                                            onChange={(e) => onItemCheck(e, row)}
                                        />
                                    </th>
                                }
                                
                                <td><Link to={`/jobtoinvoice/${row.job._id}`}>{row.invoiceNumber}</Link></td>
                                <td>{new Date(row.invoiceDate).toLocaleDateString()}</td>
                                <td>{row.invoiceCustomerName}</td>
                                <td>{row.invoiceAddress}</td>
                                <td>{row.psi}</td>
                                <td>{toCurrency(calculateAmount(row),2)}</td>
                                <td>{toCurrency(calculateTaxes(row),2)}</td>
                                <td>{toCurrency(calculateTotals(row),2)}</td>
                                <td>{toCurrency(calculateTotals(row) - calculatePayments(row),2)}</td>
                                <td>
                                    <table>
                                        <tbody>
                                            <DrawPaymentsRegistered invoiceId={row._id} payments={row.payments} />
                                        </tbody>
                                    </table>
                                </td>
                                
                            </tr>
                        })
                        :
                        <tr><td colSpan='10' className='text-center'>No data to display</td></tr>
                    }
                </tbody>
            </table>
        )
    }

    const TableGeneralPayments = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        {
                            columns2.map((column)=>{
                                return <th scope='col' key={column.key}>{column.name}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        results2 ?
                        generalPayments.map((row, key)=>{
                            return <tr key={key} >
                                <td><Link to={`/generalpaymentpdf/${row._id}`}>{row.paymentNumber}</Link></td>
                                <td>{new Date(row.paymentDate).toLocaleDateString()}</td>
                                <td>{`${row.customer.customerNumber} ${row.customer.name}`}</td>
                                <td>
                                    {
                                        row.invoices.map((invoice) => {
                                            return `${invoice.invoice.invoiceNumber}: ${toCurrency(invoice.amount,2)}`
                                        })
                                    }
                                </td>

                                <td>{toCurrency(row.paymentTotal)}</td>                                
                            </tr>
                        })
                        :
                        <tr><td colSpan='10' className='text-center'>No general payments to display</td></tr>
                    }
                </tbody>
            </table>
        )
    }

    const CustomerRenderer = () => {
        return (
            <div>
                <h1>{customer.name}</h1>
                <div className='form-group row'>
                    <h6 className='col-sm-4'>{`Total Invoices: ${toCurrency(totalInvoices,2)}`}</h6>
                    <h6 className='col-sm-4'>{`Total Payments: ${toCurrency(totalPayments,2)}`}</h6>
                    <h6 className='col-sm-4'>{`Total Balance: ${toCurrency(totalBalance,2)}`}</h6>
                </div>
            </div>
        )
    }

    const DrawPaymentsRegistered = ({invoiceId, payments}) => {
        if (payments.length === 0) {
            return <tr>
                <td>No payments</td>
            </tr>
        } else {
            return payments.map((pago, index) => {
                return <tr key={index} >
                    <td>
                        <Link to={{pathname:`/paymentpdf/${invoiceId}`, state: {paymentId: pago._id}}}>
                            {`${pago.type}:${toCurrency(pago.amount,2)}`}
                        </Link>
                    </td>
                </tr>
            })
        }      
    }

    return (
        <Layout
            title='General Payment'
            description='Payments'
            className='container col-md-10'
        >
            {
                loading ? <Spinner /> :
                <div>
                    <CustomerRenderer />
                    <h2>General Payments</h2>
                    <TableGeneralPayments />
                    <h2>Invoices</h2>
                    <TableRenderer />
                    <button className="btn bg-success text-center text-white" onClick={() => setShowModal(true)} disabled={selectedList.length === 0}> Apply payment </button>
                    &nbsp;
                    <button className='btn bg-primary text-center text-white' onClick={() => setShowModalPayment(true)}> Receive payment </button>
                </div>
            }
            
            <Modal show={showModalPayment} animation={false} onHide={() => setShowModalPayment(false)} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Recieve Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientPayments data={customer}/>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} animation={false} onHide={() => setShowModal(false)} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>General Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GeneralPayment clientId={props.match.params.id} selectedList={selectedList} client={customer} pcb={updateGeneralPayments}/>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default GeneralPayments