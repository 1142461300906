import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import axios from 'axios'
import { api } from '../utils/Api'

const Components = () => {
    useEffect(() => {
        getData()
    }, [])

    const [lista, setLista]  = useState([])


    const getData = async () => {
        const url = api + '/vehiclecomponent'

        try {
            const { data } = await axios.get(url)
            setLista(data.data)         
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const DrawList = () => {
        return lista.map((dato, i) => {
            return <tr key={i}>
                <td>{dato.id}</td>
                <td>{dato.componentCode}</td>
                <td>{dato.description}</td>
            </tr>              
        })
    }

    return (
        <Layout
            title='Components'
            description='Vehicle components'
            className="container col-md-8 offset-md-2"
        >
            <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Componente Code</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
        </Layout>
    )
}

export default Components