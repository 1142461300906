import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { api } from '../utils/Api'
import Layout from '../layout/Layout'
import { dateFormat, toCurrency } from '../utils/Convert'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import { CSVLink } from "react-csv"
import DataGrid from 'react-data-grid';

// Equivalencias y join entre los 3 fuentes de informacion
//
// 1. Para obtener el job a partir del order code: {{url}}/api/ordercodejob/${orderCode}
// 2. order code = orderSummary.code 
// 3. Pare el "sales by mix", el no. de factura == "2"+ jobNumber
// en sales by mix, la cantidad de yardas esta en "quantity"
// en job, las yardas están en "loadYD3"
// en order summary, las yardas están en "shipped"

const ReconciliationReport = () => {

    const [dateReport, setDateReport] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [dateChange, setDateChange] = useState(true)
    
    const [rows, setRows] = useState([])
    const[orderSummaryDetail, setOrderSummaryDetail] = useState([])
    const[salesMixDetail, setSalesMixDetail] = useState([])
    const [jobs, setJobs] = useState([])

    useEffect(() => {
        const mes = dateReport.getMonth()+1
        const dia = dateReport.getDate()
        const vacio = []
        let month = '0'
        let day = '0'
        if (mes > 9) {
            month = mes
        } else {
            month += mes
        }

        if (dia>9) {
            day = dia
        } else {
            day += dia
        }
        setOrderSummaryDetail(vacio)
        setSalesMixDetail(vacio)

        const fecha = `${dateReport.getFullYear()}-${month}-${day}`
        console.log(fecha)
        getData(fecha)
        // getReconciliationData(fecha)
    }, [dateReport])

    const columna = [
        { key: 'order', name: 'Order', resizable: true, },
        { key: 'customerNumber', name: ' Customer Number', resizable: true, },
        { key: 'deliveryAddress', name: 'Delivery Address', resizable: true, },
        { key: 'psi', name: 'PSI', resizable: true, },
        { key: 'firstOJT', name: 'First OJT', resizable: true, },
        { key: 'firstLoad', name: 'First Load', resizable: true, },
        { key: 'ordered', name: 'Ordered', resizable: true, },
        { key: 'shipped', name: 'Shipped', resizable: true, },
        { key: 'loadSize', name: 'Load Size', resizable: true, },
        { key: 'status', name: 'Status', resizable: true, },
        { key: 'product', name: 'Product/Service', resizable: true},
        { key: 'customerName', name: 'Customer name', resizable: true},
        { key: 'quantity', name: 'Quantity', resizable: true},
        { key: 'salesPrice', name: 'Sales Price', resizable: true},
        { key: 'amount', name: 'Amount', resizable: true },
        { key: 'jubNumber', name: 'Job Number', resizable: true},
        { key: 'jobDate', name: 'Date/time', resizable: true},
        { key: 'customer', name: 'Customer', resizable: true},
        { key: 'loadYD3', name: 'Load', resizable: true}
    ]                                

    // Get data order summary
    const getOrderSummaryDetail = async(fecha) => {
        const url = api + `/ordersummary/${fecha}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const {data} = await axios.get(url)
            // console.log(data)
            setOrderSummaryDetail(data)
        } catch (err) {
            setOrderSummaryDetail([])
            console.error(err)
            return null
        }
    }

    // Get data sales mix para tabla
    const getSalesMixDetail = async(fecha) => {        
        const url = api + `/salesbymixdate/${fecha}`
        console.log(url)
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const {data} = await axios.get(url)
            // console.log(data)
            setSalesMixDetail(data)
        } catch (err) {
            setSalesMixDetail([])
            console.error(err)            
            return null
        }
    }

    const getJobs = async (fecha) => {
        const url = api + `/jobdate?date=${fecha}`
        console.log(url)
        try {
            const {data} = await axios.get(url)
            // console.log(data)
            setJobs(data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const getData = (fecha) => {
        const fechaSin = fecha.replace(/-/g, '')
        getJobs(fecha)
        getSalesMixDetail(fechaSin)
        getOrderSummaryDetail(fechaSin)   
        mergeTables()     
        // setTimeout(() => {mergeTables()}, 3000)
    }

    const createRecDoc = async (record) => {
        const url = api + '/reconciliation'

        try {
            await axios.post(url, record)
        } catch (e) {
            console.log(e)
        }
    }

    const getReconciliationData = async (fecha) => {
        const url = api + `/reconciliation/${fecha}`
        console.log(url)
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const {data} = await axios.get(url)
            console.log(data)
            const depurado = data.map(dat => {
                return {
                    order: dat.order,
                    customerNumber: dat.customerNumber,
                    deliveryAddress: dat.deliveryAddress,
                    psi: dat.psi,
                    firstOJT: dat.firstOJT,
                    firstLoad: dat.firstLoad,
                    ordered: dat.ordered,
                    shipped: dat.shipped,
                    loadSize: dat.loadSize,
                    status: dat.status,
                    product: dat.product,
                    customerName: dat.customerName,
                    quantity: dat.quantity,
                    salesPrice: dat.salesPrice,
                    amount: dat.amount,  
                    jobNumber: dat.jobNumber,  
                    jobDate: dat.jobDate,
                    customer: dat.customer,
                    loadYD3: dat.loadYD3,           
                }
            })

            console.log(depurado)
            // setRows(depurado)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const mergeTables = () =>{        
        let max = jobs ? jobs.length : 0
        const smLen = salesMixDetail ? salesMixDetail.length : 0
        max = max > smLen ? max : smLen
        const osLen = orderSummaryDetail ? orderSummaryDetail.length : 0
        max = max > osLen ? max : osLen
        console.log(`tamaño maximo: ${max}`)
        const datos = []
        const fecha = `${dateReport.getFullYear()}-${dateReport.getMonth()+1}-${dateReport.getDate()}`

        for  (let i = 0; i<max; i++ ) {
            const jb = jobs[i]
            const salesMix = salesMixDetail[i] 
            const orderSummary = orderSummaryDetail[i]
            // console.log(salesMix)
            // console.log(orderSummary)            

            // Asignacion de campos order summary
            const order = orderSummary ? orderSummary.order : ''
            const customerNumber = orderSummary ? orderSummary.customerNumber : ''
            const deliveryAddress = orderSummary ? orderSummary.deliveryAddress : ''
            const psi = orderSummary ? orderSummary.psi : ''
            const firstOJT = orderSummary ? orderSummary.firstOJT : ''
            const firstLoad = orderSummary ? orderSummary.firstLoad : ''
            const ordered = orderSummary ? orderSummary.ordered : ''
            const shipped = orderSummary ? orderSummary.shipped : ''
            const loadSize = orderSummary ? orderSummary.loadSize : ''
            const status = orderSummary ? orderSummary.status : ''
            // Asignacion de campos de sales mix
            const product = salesMix ? salesMix.product : ''
            const customerName = salesMix ? salesMix.customerName : ''
            const quantity = salesMix ? salesMix.quantity : ''
            const salesPrice = salesMix ? salesMix.salesPrice : ''
            const amount = salesMix ? salesMix.amount : ''
            // Asignacion de campos de job
            const jobNumber = jb ? jb.number : ''
            const jobDate = jb ? new Date(jb.scheduledDate).toLocaleDateString() + ' at ' +  new Date(jb.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' }) : ''
            const customer = jb ? jb.customer.customerNumber + ' ' + jb.customer.name : ''
            const loadYD3 = jb ? jb.loadYD3 : ''                 

            const record = {
                order,
                customerNumber,
                deliveryAddress,
                psi,
                firstOJT,
                firstLoad,
                ordered,
                shipped,
                loadSize,
                status,
                product,
                customerName,
                quantity,
                salesPrice,
                amount,
                jobNumber,
                jobDate,
                customer,
                loadYD3,
                reportDate: fecha
            }
            // console.log(record)
            // createRecDoc(record)

            datos.push(record)
        }

        console.log(datos)
        setRows(datos)
    }

    // CSV export file headers
    const headers = () => {
        return columna.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const Grid = () => (
        <div>
            <DataGrid
                className='rdg-light'
                columns={columna}
                rows={rows}
            />
            <CSVLink data={rows} headers={headers()} filename={"57concrete-reconciliation.csv"}>
                Download report
            </CSVLink>
        </div>
    )

    return (
        <Layout
            title='Reconciliation'
            description='Report'
         >
           <div className="form-group row">
                <div className='col-sm-4'>
                <label className='text-muted'>Date</label>
                    <DatePicker
                        selected={dateReport}
                        onChange={(date) => setDateReport(date)}
                    />
                </div>
                <br />
                {loading
                ? <Spinner />
                : <Grid />
                }
                
            </div>
        </Layout>
    )

}

export default ReconciliationReport