import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import DailyReadOnlyItem from './DailyReadOnlyItem'
import { toCurrency } from '../utils/Convert'
import { getFutureJobs, getJobsDate } from '../../actions/job'
import { jobIdFilter } from '../utils/JobIdFilter'
import { clientIdFilter } from '../utils/ClientIdFilter'
import { api } from '../utils/Api'
import "./styles.css";
import DailyReadOnlyItemOnTime from './DailyReadOnlyItemOnTime'

let descriptionTitle = new Map([['done', 'Jobs with operations done'], ['pending', 'Jobs pending'], ['inprogress', 'Jobs in progress'],
['movedorders', 'Jobs with date moved'], ['hotshot', 'Hotshot jobs'], ['ontime-true', 'On Time'], ['ontime-false', 'Not On Time']])
let statusMap = new Map([['done', 'Operations done (AR)'], ['inprogress', 'ok'], ['movedorders', 'Date Moved']])
let color = new Map([['done', 'bg-success'], ['pending', 'bg-danger'], ['inprogress', 'bg-warning'], ['hotshot', 'bg-info'], ['ontime-true', 'bg-ontime-true'], ['ontime-false', 'bg-ontime-false']])


const DailyReadOnly = ({ match, job: { jobs, loading }, getFutureJobs, getJobsDate }) => {
    const option = match.params.id
    // en option viene "hotshot"
    const option2 = match.params.id === 'inprogress' ? 'inprogressTarget' : ''
    console.log(`opciones; ${option}, ${option2}`)
    const [jobId, setJobId] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [hasJobs, setHasJobs] = useState(false)
    const [todayJobs, setTodayJobs] = useState([])
    const [loadingAxios, setLoadingAxios] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [dateChange, setDateChange] = useState(false)
    const [update, setUpdate] = useState(false)


    useEffect(() => {
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
        if (dateChange) {
            setLoadingAxios(true)
            getJobsScheduled(fechaIni, fechaFin)
            setDateChange(false)
        } else {

            if (option === 'movedorders') {
                getFutureJobs()
            } else {
                const today = new Date()
                const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : `${today.getMonth() + 1}`
                const day = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`
                const dateJob = `${today.getFullYear()}-${month}-${day}`
                getJobsDate(dateJob)
                todayData(dateJob)
            }
        }


    }, [startDate, endDate])

    useEffect(() => {

    }, [update])

    const todayData = async (dateJob) => {
        const url = api + `/jobdate?date=${dateJob}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoadingAxios(true)
            const { data } = await axios.get(url)
            setTodayJobs(data)
            setLoadingAxios(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getJobsScheduled = async (startDate, endDate) => {

        const url = api + `/jobscheduled?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setTodayJobs(data)
            setUpdate(!update)
            setLoadingAxios(false)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getTotalYD3 = () => {
        let jobsFiltered = filter()
        let total = 0

        // Target YD3:  
        // Load YD3  :     
        if (jobs) {
            if (option === 'pendingPayment' || option === 'appointment' || option === 'inspection' || option === 'movedorders' || option === 'pending' || option2 === 'inprogressTarget') {
                jobsFiltered.forEach(job => { total += typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3) : job.targetYD3})
            } else { // Sum of load YD3
                jobsFiltered.forEach(job => { total += job.loadYD3 })
            }
        }
        return total
    }

    const DrawTotal = () => {
        const cn = (option === 'movedorders' ? 'card text-center text-white mb-2' : `card text-center ${color.get(option)} text-white mb-2`)
        return (
            <div className='row' >
                <div className="col-md-6">
                    <div className={cn} style={{ backgroundColor: option === 'movedorders' && '#FF8C00' }}>
                        <div className="card-body">
                            {option === "inprogress" ? <h3>Load YD3</h3> : <h3>Total YD3</h3>}
                            <h4 className="display-4">
                                {Math.round(getTotalYD3() * 100) / 100}
                            </h4>
                        </div>
                    </div>
                </div>
                {
                    option === "inprogress" &&
                    <div className="col-md-6">
                        <div className={cn} style={{ backgroundColor: option === 'movedorders' && '#FF8C00' }}>
                            <div className="card-body">
                                <h3>Target YD3</h3>
                                <h4 className="display-4">
                                    {Math.round(getTotalYD3('inprogressTarget') * 100) / 100}
                                </h4>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    // returns filtered list
    const filter = () => {
        let jobsFiltered = jobs.filter(job => job.customer.creditHold !== true)

        if (option !== 'movedorders' && todayJobs.length > 0) {
            jobsFiltered = todayJobs.filter(job => job.customer.creditHold !== true)
        }

        if (option === 'pending') { // Pending - Confirm
            //jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Trouble' || job.jobStatus === 'Active' || job.jobStatus === 'Date moved' || job.jobStatus === 'Ready to Load')
            jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Confirm')
        } else if (option === 'hotshot') {
            jobsFiltered = jobsFiltered.filter(job => job.hotShot === true)
        } else if (option === 'ontime-true') {
            jobsFiltered = jobsFiltered.filter(job => job.onTime === true && (job.jobStatus === 'ok' || job.jobStatus === 'Operations done (AR)'))
        } else if (option === 'ontime-false') {
            jobsFiltered = jobsFiltered.filter(job => job.onTime === false && (job.jobStatus === 'ok' || job.jobStatus === 'Operations done (AR)'))
        } else if (option === 'movedorders') {
            jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Date moved')
        } else if (option === 'done') {
            jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'ok')
        } else if (option === 'inprogress') {
            jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Active')
        }

        jobsFiltered = jobIdFilter(jobId, jobsFiltered)
        jobsFiltered = clientIdFilter(customerId, jobsFiltered)
        //console.log(`Jobs filtered`)
        //console.log(jobsFiltered)

        return jobsFiltered
    }


    const DrawList = () => {
        let jobsFiltered = filter()

        if (jobsFiltered.length === 0) return <tr><th>Nothing for {descriptionTitle.get(option)}</th></tr>

        //console.log("DrawList")
        //console.log(jobsFiltered)

        return jobsFiltered.map((job) => {
            return <DailyReadOnlyItem key={job._id} job={job} />
        })
    }

    const DrawListOnTime = () => {
        let jobsFiltered = filter()

        if (jobsFiltered.length === 0) return <tr><th>Nothing for {descriptionTitle.get(option)}</th></tr>

        //console.log("DrawList")
        //console.log(jobsFiltered)

        return jobsFiltered.map((job) => {
            return <DailyReadOnlyItemOnTime key={job._id} job={job} />
        })
    }

    const RenderJobs = () => (
        <div>
            <DrawTotal />
            <div style={{ fontSize: '13px' }}>
                <table className='table'>
                    {(option === "ontime-false" || option === "ontime-true") ?

                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>JobID</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Address</th>
                                <th>Load YD3</th>
                                <th>Target + Balance</th>
                                <th>Dest. time</th>
                                <th>Status</th>
                                <th>First Load</th>
                                <th>Driver</th>
                                <th>Load Ticket</th>
                                <th>Order Code</th>
                                <th>Truck Num</th>
                            </tr>
                        </thead>
                        :
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>JobID</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Address</th>
                                <th>Load YD3</th>
                                <th>Target + Balance</th>
                                <th>Dest. time</th>
                                <th>Mix Design</th>
                                <th>Slump</th>
                                <th>Pour/Type</th>
                                <th>Status</th>
                                <th>Seller</th>
                                <th>Comments</th>
                                <th>Paid</th>
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {(option === "ontime-false" || option === "ontime-true") ? <DrawListOnTime /> : <DrawList />}
                    </tbody>
                </table>
            </div>
        </div>
    )
    const downloadCSVFile = (csv_data) => {

        // Create CSV file object and feed our
        // csv_data into it
        const CSVFile = csv_data

        // Create to temporary link to initiate
        // download process
        var temp_link = document.createElement('a');

        // Download csv file
        temp_link.download = "57concrete-daily-read.csv";
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;

        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);

        // Automatically click the link to trigger download
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    const exportCSV = () => {
        let csv = [];
        const rows = document.querySelectorAll("table tr");
        for (const row of rows.values()) {
            const cells = row.querySelectorAll("td, th");
            const rowText = Array.from(cells).map((cell) => {
                const text = cell.innerText
                return text.replace(',', '')
            })
            //console.log(rowText);
            csv.push(rowText.join(','));
        }
        const csvFile = new Blob([csv.join('\n')], { type: "text/csv;charset=utf-8;" });
        //console.log(csv);
        downloadCSVFile(csvFile);
    }

    return (
        <Layout
            title='Jobs'
            description={descriptionTitle.get(option)}
        >
            <div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className='text-muted'>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>End Date</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                </div>
                <br />
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className='text-muted'>JobId</label>
                        <input
                            type='text'
                            value={jobId}
                            onChange={(e) => setJobId(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>Client</label>
                        <input
                            type='text'
                            value={customerId}
                            onChange={(e) => setCustomerId(e.target.value)}
                        />
                    </div>
                </div>
                <br />
                <button type="button" class="btn btn-primary" onClick={exportCSV}>Download report</button>
            </div>
            <br />

            {(loading || loadingAxios) ? <Spinner /> : <RenderJobs />}
        </Layout>
    )
}


const mapStateToProps = state => ({
    job: state.job
})

export default connect(mapStateToProps, { getFutureJobs, getJobsDate })(DailyReadOnly)