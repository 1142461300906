const toCurrency = (amount, decimals) => {
    if (amount) {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: decimals
        })
    } else {
        return '$0'
    }
}

// no funciona en Safari
const toThousands = (num) => {
    // return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

    // resolviendo a la antigua
    num += '';
    var x = num.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;

}

// no funciona en Safari
const toThousandsWkS = (num) => {
    // return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

    // resolviendo a la antigua
    num += '';
    var x = num.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '.' + 0;
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;

}

const porcentage = (une, duo) => {
    if (duo < une) {
        return Math.ceil(duo * 100 / une)
    } else {
        return Math.ceil(une * 100 / duo)
    }
}

const getDaysInMonth = (month) => {
    const today = new Date()
    const year = today.getFullYear()
    return new Date(year, month, 0).getDate();
}

const dateFormat = (fecha) => {
    return `${fecha.substring(4, 6)}/${fecha.substring(6, 8)}/${fecha.substring(0, 4)}`
}

const miles = cifra => {
    return Math.ceil(cifra / 1000)
}

const formatNumberThousands = (n) => {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}


module.exports = {
    toCurrency,
    toThousands,
    toThousandsWkS,
    porcentage,
    getDaysInMonth,
    dateFormat,
    miles,
    formatNumberThousands
}