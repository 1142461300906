const jobIdFilter = (jobID, jobsList) => {
    if (jobID === "") {
        return jobsList
    } else {
        const filteredJobs = jobsList.filter((job) => {
            let number = job.number.toString()
            return number === jobID
        })
        return filteredJobs
    }
}

module.exports = {
    jobIdFilter
}