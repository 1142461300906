import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { toCurrency } from '../utils/Convert'
import TermPicker from './TermPicker'
import LoadList from '../load/LoadList'
import { api } from '../utils/Api'
import DatePicker from 'react-datepicker'
import formatDate from '../utils/FormatDate'

const Invoice = ({match}) => {
    const [customerName, setCustomerName] = useState('')
    const [invoiceAddress, setInvoiceAddress] = useState('')
    const [stateTax, setStateTax] = useState(0)
    const [countyTax, setCountyTax] = useState(0)
    const [taxAmount, setTaxAmount] = useState(0)
    const [invoiceTax, setInvoiceTax] = useState('')
    const [invoiceDate, setInvoiceDate] = useState('')
    const [unitPrice, setUnitPrice] = useState(0)
    const [paymentTerm, setPaymentTerm] = useState('7')
    const [psi, setPsi] = useState('')
    const [psiDescription, setPsiDescription] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [loadList, setLoadList] = useState([])
    const [amount, setAmount] = useState(0)
    const [fuelSurcharge, setFuelSurcharge] = useState(false)
    const [fuelRate, setFuelRate] = useState(10)
    const [isCreditCardPayment, setIsCreditCardPayment] = useState(false)
    const [creditCardFee, setCreditCardFee] = useState(0.04)
    const [factoring, setFactoring] = useState(false)
    const [applyDiscount, setApplyDiscount] = useState(false)
    const [discount, setDiscount] = useState(0)
    const [discountAmount, setDiscountAmount] = useState(0)
    const [discountReason, setDiscountReason] = useState('')
    const [po, setPO] = useState('')
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [invoiceTotal, setInvoiceTotal] = useState(0)
    useEffect(() => {
        getCompanyInfo()
        getInvoice()
    }, [])

    // Company info
    const getCompanyInfo = async () => {
        const url = api + '/company'        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            console.log(data)
            setStateTax(data.stateTax)
            setCountyTax(data.countyTax)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    // loads from job
    const getLoads = async (jobId) => {
        const url = api + '/load/' + jobId
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            console.log(data)
            setLoadList(data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    // Invoice info
    const getInvoice = async () => {
        console.log(`invoice id: ${match.params.id}`)
        const url = api + '/invoice/' + match.params.id 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            console.log(data)
            
            getLoads(data.job._id)
            setInvoiceNumber(data.invoiceNumber)
            setCustomerName(data.invoiceCustomerName)
            setInvoiceAddress(data.invoiceAddress)
            setPsi(data.psi)
            setPsiDescription(data.psiDescription)
            // setInvoiceDate(new Date(data.invoiceDate))
            setInvoiceDate('2022-04-04')
            setPO(data.PO)
            setInvoiceTax(data.invoiceTax)
            setQuantity(data.quantity)
            setUnitPrice(data.rate)
            setPaymentTerm(data.paymentTerm)
            setIsCreditCardPayment(data.isCreditCardPayment)
            const sub = data.quantity * data.rate
            setAmount(sub)
            setAmount(sub)
            calculateTax(data.invoiceTax)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const changeQuantity = e => {
        const totalLoad = e.target.value
        setQuantity(totalLoad)
        setAmount(totalLoad * unitPrice)
        calculateTax(invoiceTax)
    }

    const onChangeTerm = selectedTerm => {
        console.log(selectedTerm.value)
        setPaymentTerm(selectedTerm.value)
    }

    const onChangeAddress = e => {
        setInvoiceAddress(e.target.value)
    }

    // Tax and total calculator
    const calculateTax = (impuesto = invoiceTax) => {
        let taxPercentaje = 0

        switch (impuesto) {
            case 'state':
                taxPercentaje = stateTax
                break
            case 'county':
                taxPercentaje = countyTax
                break
            case 'exempt':
                taxPercentaje = 0
                break
            default:
                taxPercentaje = countyTax
        }

        let sub = amount 
        if (fuelSurcharge) {
            sub = parseFloat(sub) + parseFloat(fuelRate)
            console.log(`gas: ${fuelRate} . ${sub}`)
        }

        let descuento = 0
        if (applyDiscount) {
            descuento = parseFloat(sub) * parseFloat(discount)
            sub = sub - descuento
            setDiscountAmount(descuento)
        } else {
            setDiscountAmount(0)
            setDiscount(0)
        }

        let tarjeta = 0
        if (isCreditCardPayment) {
            tarjeta = parseFloat(sub) * parseFloat(creditCardFee)            
            sub = sub + tarjeta
            console.log(`credit card fee ${tarjeta} `)
        }

        console.log(`tax %: ${taxPercentaje}, subtotal: ${sub} `)
        const taxas = sub * taxPercentaje
        console.log(`applied tax: ${impuesto} taxas (%): ${taxPercentaje}, taxas($): ${taxas} `)
        const suma = sub + taxas
        console.log(`quantity: ${quantity}, unitPrice: ${unitPrice}, amount: ${amount}, descuento: ${descuento}, tax: ${taxas}, total: ${suma} `)
        setTaxAmount(taxas)
        setInvoiceTotal(suma)
    }

    const updateInvoice = async () => {
        console.log('actualizando factura')
        const url = api + `/invoice/${match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const factura = {
            invoiceDate: formatDate(invoiceDate),
            quantity,
            invoiceAddress,
            amount,
            invoiceTotal
        }
        console.log(factura)

        try {
            await axios.patch(url, factura)
        } catch (err) {
            console.error(err)
            return null
        }
    }    

    const submit = e => {
        e.preventDefault()
        updateInvoice()
    }

    return (
        <Layout
            title='Invoice'
            description={`Edit invoice ${invoiceNumber}`}
            className="container col-md-8 offset-md-2"
        >
            <form className='form' onSubmit={submit}>
                <div className="form-group">
                    <label className="text-muted">Invoice Number</label>
                    <input 
                        type="text"
                        className="form-control"
                        value={invoiceNumber} // Se calcula en con prefijo 2 + jobNumber
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label className="text-muted">Customer</label>
                    <input
                        type='text'                        
                        className='form-control'
                        value={customerName}
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label className="text-muted">Address</label>
                    <input
                        type='text'                    
                        className='form-control'
                        value={invoiceAddress}
                        name='invoiceAddress'
                        onChange={onChangeAddress}
                    />
                </div>  
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className="text-muted">Product</label>
                        <input
                            type='text'                    
                            className='form-control'
                            value={psi}
                            readOnly
                        />               
                    </div>
                    <div className='col-sm-8'>
                        <label className="text-muted"> </label>
                        <input
                            type='text'                    
                            className='form-control'
                            value={psiDescription}
                            readOnly
                        />                        
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className="text-muted">Ship Date</label>    
                        <DatePicker
                            selected={new Date(invoiceDate)}
                            onChange={(date) => setInvoiceDate(date)}
                        />            
                    </div>
                    <div className="col-sm-4">
                        <label className="text-muted">Tax</label>
                        <input
                            type='text'
                            className='form-control'
                            defaultValue={invoiceTax}
                            readOnly
                        />
                    </div> 
                    <div className='col-sm-4'>
                        <label className="text-muted">Rate</label>
                        <input
                            type='text'                    
                            className='form-control'
                            style={{ textAlign: "right" }}
                            value={toCurrency(unitPrice,2)}
                            readOnly
                        />                        
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Quantity</label>
                        <input
                            type='number'                    
                            className='form-control'
                            style={{ textAlign: "right" }}
                            value={quantity}
                            onChange={changeQuantity}
                        />               
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Subtotal</label>
                        <input
                            style={{ textAlign: "right" }}
                            type='text'                    
                            className='form-control'
                            value={toCurrency(amount,2)}
                            readOnly
                        />                        
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <input  name="fuelSurcharge"
                                type="checkbox"
                                checked={fuelSurcharge}
                                onChange={(e) => setFuelSurcharge(!fuelSurcharge)}
                        />
                        <label>&nbsp;Standar Fuel Surcharge</label>
                    </div>
                    {
                        fuelSurcharge &&
                        <div className='col-sm-6'>
                            <label className="text-muted">Fee</label>
                            <input  name="FuelRate"
                                    type="number"
                                    className="form-control"
                                    value={fuelRate}
                                    onChange={(e) => {
                                        setFuelRate(e.target.value)
                                        calculateTax()
                                    }}
                            />
                        </div>
                    }
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <input  name="ccardPayment"
                                type="checkbox"
                                checked={isCreditCardPayment}
                                onChange={(e) => setIsCreditCardPayment(!isCreditCardPayment)}
                        />
                        <label>&nbsp;Credit Card Payment</label>
                    </div>
                    {
                        isCreditCardPayment &&
                        <div className='col-sm-6'>
                            <label className="text-muted">Credit Card Fee (%)</label>
                            <input  name="CreditCardFee"
                                    type="number"
                                    className="form-control"
                                    value={creditCardFee}
                                    onChange={(e) => setCreditCardFee(e.target.value)}
                            />
                        </div>
                    }
                </div>
                <div className="form-group">
                    <input  name="applyDiscount"
                            type="checkbox"
                            checked={applyDiscount}
                            onChange={(e) => {
                                setApplyDiscount(!applyDiscount)                                
                                calculateTax()
                            }}
                    />
                    <label>&nbsp;Apply Discount</label>
                </div>
                {
                    applyDiscount &&
                    <div className="form-group row">
                        <div className='col-sm-3'>
                            <label className="text-muted">Discount (%)</label>
                            <input  name="discount"
                                    type="number"
                                    className="form-control"
                                    value={discount}
                                    onChange={(e) => {
                                        setDiscount(e.target.value)
                                        calculateTax()
                                    }}
                            />
                        </div>
                        <div className='col-sm-9'>
                            <label className="text-muted">Discount Reason</label>
                            <input  name="discountReason"
                                    type="text"
                                    className="form-control"
                                    value={discountReason}
                                    onChange={(e) => setDiscountReason(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <div className='form-group'>
                    <input  name="factoring"
                            type="checkbox"
                            checked={factoring}
                            onChange={(e) => setFactoring(!factoring)}
                    />
                    <label>&nbsp;Requiere Factoring</label>
                </div> 
                <div>
                    <LoadList list={loadList} />
                </div>
                <div className="form-group row">
                    <div className="col-sm-3">
                        <label className="text-muted">Other Charges</label>
                        <input  type="text"
                                style={{ textAlign: "right" }}
                                className="form-control"
                                readOnly
                                value={
                                    fuelSurcharge ? toCurrency(fuelRate,2) : '$0.00'
                                }

                        />
                    </div>
                    <div className="col-sm-3">
                        <label className="text-muted">Discount</label>
                        <input  type="text"
                                style={{ textAlign: "right" }}
                                className="form-control"
                                readOnly
                                value={toCurrency(discountAmount,2)}
                        />
                    </div>
                    <div className="col-sm-3">
                        <label className="text-muted">Tax</label>
                        <input  type="text"
                                style={{ textAlign: "right" }}
                                className="form-control"
                                readOnly
                                value={toCurrency(taxAmount,2)}
                        />
                    </div>                
                    <div className="col-sm-3">
                        <label className="text-muted">Total</label>
                        <input  type="text"
                                style={{ textAlign: "right" }}
                                className="form-control"
                                readOnly
                                value={toCurrency(invoiceTotal,2)}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-6">
                        <label className="text-muted">PO</label>
                        <input  type="text"
                                className="form-control"
                                name="po"
                                value={po}
                                placeholder="Purchase Order"
                                onChange={(e) => setPO(e.target.value)}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label className="text-muted">Term (current: {paymentTerm})</label>
                        <TermPicker action={onChangeTerm} />
                    </div> 
                </div>                
                <div className='my-2'>
                    <button className='btn btn-success'>Save</button>
                </div>
            </form>
        </Layout>
    )
}

export default Invoice