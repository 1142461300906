import { Button, Col, Form, Input, Popconfirm, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api';
import axios from 'axios';
import { CustomSpinner } from '../utils/CustomComponents'
const { Option } = Select

const LogisticsDriver = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(true)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getDriver()
    }, [])
    
    const getDriver = async () => {
        const url = api + '/logisticsdriver/get/' + props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setInitialValues({
                ...initialValues,
                name: data.name,
                phone: data.phone ? data.phone.substring(2) : '',
                license: data.license,
                prefix : '+1'
            })
            setLoadingForm(false)
        } catch (err) {
            setLoadingForm(false)
            openNotification('error', 'Error', 'Error while trying to get driver')
            console.error(err.message)
            return null
        }
    }

    const deleteDriver = async () => {
        setLoading(true)

        const url = api + '/logisticsdriver/delete/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Driver Deleted')
            setLoading(false)
            props.history.push('/logisticsdriverlist')
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const updateDriver = async (data) => {
        const user = {
            name: data.name,
            phone: '+1'+data.phone,
            license: data.license
        }

        try {
            let url = api + '/logisticsdriver/update/' + props.match.params.id  
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, user)
            openNotification('success', 'Success', 'Driver updated')
            setLoading(false)
            props.history.push('/logisticsdriverlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to update driver')
            console.error(err.message)
            return null
        }
    }
    
    const onFinish = (values) => {
        setLoading(true)
        updateDriver(values)
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='License'
                    name='license'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill license',
                        },
                        {
                            max: 20,
                            message: 'License must be less than 20 characters'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Col>
                {
                    (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com') &&
                    <Col>
                        <Popconfirm
                            title="Delete the user"
                            description="Are you sure to delete this user?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteDriver}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                        </Popconfirm>
                    </Col>
                }
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Logistics Driver'
        description='Edit Logistics Driver'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm />}
        </Layout>
    )
}

export default LogisticsDriver