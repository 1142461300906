import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../../layout/Layout'
import Spinner from '../../layout/Spinner'
import { api } from '../../utils/Api'
import { faPlusSquare, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom';
import EmployeeTypePicker from './EmployeeTypePicker'

const Employees = () => {
    const [loading, setLoading] = useState(true)
    const [term, setTerm] = useState('')
    const [type, setType] = useState('All')
    const [employeeList, setEmployeeList] = useState([])
    let history = useHistory()

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        getEmployeeListSearch()
    }

    const getEmployeeListSearch = async() => {
        setLoading(true)

        const url = api + `/employees/search/type?type=${type}&term=${term}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setEmployeeList(data)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    // change employee head
    const onChangeEmployeeType = selectedEmployeeType => {
        if (selectedEmployeeType) {
            setType(selectedEmployeeType.value)
        }
    }

    const RenderTable = () => {
        return <div style={{maxWidth: '100%', marginTop: 20}}>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th>Sex</th>
                        <th>Admission Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        employeeList.length !== 0 ?
                        employeeList.map((employee) =>{
                            return (
                                <tr key={employee._id}>
                                    <td><Link  to={'/employee/'+employee._id}>{`${employee.name} ${employee.middleName} ${employee.lastName}`}</Link></td>
                                    <td>{employee.type}</td>
                                    <td>{employee.status}</td>
                                    <td>{employee.address}</td>
                                    <td>{employee.phone}</td>
                                    <td>{employee.sex}</td>
                                    <td>{new Date(employee.admissionDate).toLocaleDateString()}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan={7} className='text-center'>No employees to display</td></tr>
                    }
                </tbody>
            </table>
        </div>
    }    

    const SearchEmployee = () => (
        <div>
            <div className="form-group row">
                <div className="col-sm-3">
                    <input
                        type='text'
                        name='term'
                        size='40'
                        className="form-control"
                        placeholder="Search..."
                        defaultValue={term}
                        onBlur={(e) => setTerm(e.target.value)}
                    />
                </div>
                <div className='col-sm-3'>
                    <EmployeeTypePicker action={onChangeEmployeeType} value={type} type={'all'} />
                </div>
                <div className="col-sm-2">
                    <button onClick={search} className='btn btn-primary w-100'>
                        <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                    </button>
                </div>
            </div>
            &nbsp;
            <div>
                <Link
                    className='btn btn-success'
                    to='/newemployee'
                >
                    <FontAwesomeIcon icon={faPlusSquare} />&nbsp;New
                </Link>
            </div>
        </div>
    )

    return (
        <Layout
            title='Employees'
            description='List all employees'
            className='container col-md-10'
        >
            <SearchEmployee/>
            {
                loading ? <RenderLoading/> : <RenderTable/>
            } 
        </Layout>
    )
}

export default Employees