import React, { Component } from 'react'
import Select from 'react-select'

export default class PaymentTypeLoadPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: false,
            isSearchable: false,
            options: []
        }
    }

    getData = () => {
        this.setState({
            options: [
                { value: 'Cash', label: 'Cash' },
                { value: 'Check', label: 'Check' },                
                { value: 'Card', label: 'Credit Card' }
            ]
        })        
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        const options = this.state.options.map((item) => {
            return {
                value: item.value,
                label: item.label
            }
        })

        return (
            <div style={{fontSize: '10px'}}>
                <Select
                    name="paymentType"
                    placeholder='Payment type...'
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    }
}
