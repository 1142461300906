import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toCurrency } from '../utils/Convert'
import { Link } from 'react-router-dom'

const DriverInvoices = (props) => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(true)
    const [term, setTerm] = useState('')
    const [loadList, setLoadList] = useState([])
    const [driver, setDriver] = useState('')
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        //search()
        setDriver(props.location.state.driver)
        getDriverPayment()
    }, [])

    // const search = () => {
    //     getLoadList()
    // }

    // const getLoadList = async() => {
    //     setLoading(true)
    //     const start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
    //     const end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

    //     const url = api + `/etickets/search?startDate=${start}&endDate=${end}&term=${term}`
    //     const token = localStorage.getItem('token')
    //     axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

    //     try {
    //         const { data } = await axios.get(url)
    //         setLoadList(data)
    //         setLoading(false)
    //     } catch (err) {
    //         console.error(err.message)
    //         return null
    //     }
    // }

    const getDriverPayment = async () => {
        setLoading(true)
        const url = api + `/driverpayment?driver=${props.location.state.driver}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try{
            const {data} = await axios.get(url)
            setInvoices(data)
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const generatePaymentNumber = (date, paymentNumber) => {
        const currentDate = new Date(date);
        const initialDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - initialDate) /
            (24 * 60 * 60 * 1000));
        var weekNumber = Math.ceil(days / 7);
        return `${currentDate.getFullYear()}-Wk-${weekNumber}-${paymentNumber}`
    }

    const calculateTotals = (invoice) => {
        let total = 0
        invoice.loads.map((load) => {
            total += load.cityFee
        })
        invoice.extras.map((extra) => {
            total += extra.amount
        })
        return total
    }

    const RenderTable = () => {
        return <div style={{maxWidth: '100%', marginTop: 20}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Payment Number</th>
                            <th>Date</th>
                            <th>Driver</th>
                            <th>Paid</th>
                            <th>Total</th>
                            <th>Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            invoices.length === 0 ?
                            <tr> 
                                <td colSpan={6} className='text-center'>
                                    No invoices to display
                                </td>
                            </tr>
                            :
                            invoices.map((invoice) => {
                                return (
                                    <tr key={invoice._id}>
                                        <td>{generatePaymentNumber(invoice.date, invoice.paymentNumber)}</td>
                                        <td>{invoice.date ? invoice.date.substring(0,10) : 'No Date'}</td>
                                        <td>{invoice.driver}</td>
                                        <td>{invoice.paid ? 'True' : 'False'}</td>
                                        <td>{toCurrency(calculateTotals(invoice),2)}</td>
                                        <td><Link to={`/driverinvoice/${invoice._id}`}>Show</Link></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            
        </div>
    }    

    // const SearchCustomer = () => (
    //     <div className="form-group row">
    //         <div className="col-sm-3">
    //             <label className='text-muted'>&nbsp;</label>
    //             <input
    //                 type='text'
    //                 name='term'
    //                 size='40'
    //                 className="form-control"
    //                 placeholder="Search..."
    //                 defaultValue={term}
    //                 onBlur={(e) => setTerm(e.target.value)}
    //             />
    //         </div>
    //         <div className='col-sm-2'>
    //             <label className='text-muted'>Start Date</label>
    //             <DatePicker
    //                 selected={startDate}
    //                 minDate={new Date('01/01/2022')}
    //                 maxDate={new Date()}
    //                 onChange={(date) => setStartDate(date)}
    //             />
    //         </div>
    //         <div className='col-sm-2'>
    //             <label className='text-muted'>End Date</label>
    //             <DatePicker
    //                 selected={endDate}
    //                 onChange={(date) => setEndDate(date)}
    //                 minDate={startDate}
    //                 maxDate={new Date()}
    //             />
    //         </div>
    //         <div className="col-sm-2">
    //             <label className='text-muted'>&nbsp;</label>
    //             <button onClick={search} className='btn btn-primary w-100'>
    //                 <FontAwesomeIcon icon={faSearch} />&nbsp;Search
    //             </button>
    //         </div>
    //     </div>
    // )

    return (
        <Layout
            title='Driver Invoices'
            description='Report'
            className='container col-md-10'
        >
            {/* <SearchCustomer/> */}
            <h2>
                {driver}
            </h2>
            {
                loading ? <RenderLoading/> : <RenderTable/>
            } 

        </Layout>
    )
}

export default DriverInvoices