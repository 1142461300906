import React from 'react'
import Select from 'react-select'

const QuadrantPicker = ({action, value}) => {
    const options = [
        { value: 'CC', label: 'Construction Companies' },
        { value: 'CEM', label: 'Cement / Particular' },
        { value: 'GC', label: 'General Contractors' },              
        { value: 'GOV', label: 'Government & DOT' },
        { value: 'BP', label: 'BePartner' },
      ]
    return (
        <div>
            <Select
                value={value}
                options={options}
                placeholder='Select Quadrant...'
                onChange={action}
            />
        </div>
    )
}

export default QuadrantPicker