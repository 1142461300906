import React, { useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getReferralList } from '../../actions/referral'

const ReferralList = ({getReferralList, referral: { referrals, loading }}) => {
    useEffect(() => {
        getReferralList()
    }, [getReferralList])

    const DrawList = () => {
        return referrals.map((refe) => {
            return <tr key={refe._id}>
                <td>
                    <Link to={`/referral/${refe._id}`} >
                        {refe.customerName}
                    </Link>
                </td>    
                <td>
                    { refe.customerCode || 'NA' }
                </td>            
                <td>
                    {
                        refe.projectName || 'NA'
                    }
                </td>
                <td>
                    { refe.orderCode || 'NA' }
                </td>
                <td>
                    {
                        refe.deliveryTicket || 'NA'
                    }
                </td>
                <td>
                    { new Date(refe.refDate).toLocaleDateString() }
                </td>
            </tr>
        })
    }

    const RenderReferral = () => (
        <table className='table'>
            <thead>
                <tr>
                    <td>Customer</td>
                    <td>Customer Code</td>
                    <td>Project</td>
                    <td>Order Code</td>
                    <td>Delivery Ticket</td>
                    <td>Date</td>
                </tr>
            </thead>
            <tbody>
                <DrawList />
            </tbody>
        </table>
    )
    return (
        <Layout
            title='Referral'
            description='Referral List'
            className='container col-md-8 offset-md-2'
        >                        
            {
                referrals.length > 0 ? <RenderReferral /> : <h4>No referrals yet</h4>
            }
            <div>
                <Link to='/newreferral' className='btn btn-success'>Add Referral</Link>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({    
    referral: state.referral
})

export default connect(mapStateToProps, {getReferralList}) (ReferralList)
