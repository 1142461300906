import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { updateJob } from '../../actions/job'
import Modal from 'react-bootstrap/Modal'
import LoadJob from '../load/LoadJob'
import Load from '../load/Load'

const DailyReadOnlyItem = ({ job }) => {
    console.log(job)

    const [jobStatus, setJobStatus] = useState(job.jobStatus)
    const [comments, setComments] = useState(job.comments)
    const [showLoad, setShowLoad] = useState(false)

    useEffect(() => {
    }, [])

    const showLoads = () => {
        setShowLoad(!showLoad)
        console.log(`mostrar: ${showLoad}`)
    }

    // link job status with color
    const selectColor = estatus => {
        switch (estatus) {
            case 'Trouble':
                return ('#7a7977')
            case 'Inactive':
                return ('#ffffff')
            case 'ok':
                return ('#7ad765')
            case 'Ready to Load':
                return ('#208928')
            case 'Active':
                return ('#fff569')
            case 'Date moved':
                return ('#d9a809')
            case 'Operations done (AR)':
                return ('#fce0a2')
            case 'Company cancellation':
                return ('#e087fa')
            case 'Quote':
                return ('#c3c2c0')
            case 'Confirm':
                return ('#31b9f7')
            default:
                return ('#fff569')
        }
    }

    const onChangeJobStatus = selected => {
        console.log(selected)
        setJobStatus(selected.label)
    }


    return (
        <tr key={job._id} bgcolor={selectColor(jobStatus)} >
            <td>
                <Link to={'/job/' + job._id}>
                    {
                        job.customer.customerNumber + ' ' + job.customer.name + ' ph: ' + job.foremanPhone
                    }
                </Link>
            </td>
            <td>
                {job.number}
            </td>
            <td>
                {new Date(job.scheduledDate).toLocaleDateString()}
            </td>
            <td style={{ fontSize: '15px' }}>
                {new Date(job.scheduledDate).toLocaleTimeString()}
            </td>
            <td>
                {job.shipAddress}
            </td>
            <td>
                {job.loadYD3}
            </td>
            <td>
                {job.balance ? (typeof job.targetYD3 === "string" ? job.targetYD3 : job.targetYD3.toString()) + ' + ' + job.balance : (typeof job.targetYD3 === "string" ? job.targetYD3 : job.targetYD3.toString()) + ' +  0'}
            </td>
            <td>
                {
                    job.destinationTime ?
                        job.destinationTime :
                        job.destinationMinutes
                }
            </td>
            <td>
                {job.psi}
            </td>
            <td>
                {job.slump}
            </td>
            <td>
                {
                    job.jobPoor + ' / ' + job.jobType
                }
            </td>
            <td>
                {jobStatus}
            </td>
            <td>
                {job.seller !== null ? job.seller.name : ""}
            </td>
            <td>
                {job.comments ? job.comments : "No comment"}
            </td>
            <td>
                {job.paid && <FontAwesomeIcon icon={faCheckSquare} />}
            </td>
        </tr>
    )
}

export default DailyReadOnlyItem