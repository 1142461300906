import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_LOAD,
    GET_LOADS,
    GET_LOAD,
    UPDATE_LOAD,
    DELETE_LOAD,
    LOAD_ERROR,
} from './types'

// create new load
export const createLoad = (formData) => async (dispatch) => {
    try {
        const { data } = await api.post('/load', formData)

        console.log(data)

        dispatch({
            type: ADD_LOAD,
            payload: data
        })

        dispatch(setAlert('Load Added', 'success'))
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: LOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get all loads for a job
export const getJobLoads = (jobId) => async (dispatch) => {
    try {
        const { data } = await api.get(`/load/${jobId}`)
        console.log(data)
        dispatch({
            type: GET_LOADS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: LOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}


// get load detail
export const getLoadDetail = loadId => async (dispatch) => {
    try {
        const { data } = await api.get(`/loaddetail/${loadId}`)
        console.log(data)
        dispatch({
            type: GET_LOAD,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: LOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Active loads for production capacity report
export const getLoadCapacity = (start, end) => async (dispatch) => {
    try {
        const { data } = await api.get(`/loadscheduled?startDate=${start}&endDate=${end}`)
        dispatch({
            type: GET_LOADS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: LOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Update load
export const updateLoad = (loadId, formData) => async dispatch => {
    console.log(loadId)
    console.log(formData)
    try {
        const { data } = await api.patch(`/load/${loadId}`, formData)

        dispatch({
            type: UPDATE_LOAD,
            payload: data
        })

        dispatch(setAlert('Load Updated', 'success'))
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: LOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// delete load
export const deleteLoad = (loadId) => async dispatch => {
    try {
        const { data } = await api.delete(`/load/${loadId}`)

        dispatch({
            type: DELETE_LOAD,
            payload: data
        })

        dispatch(setAlert('Load Deleted', 'success'))
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: LOAD_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}