import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const levelList = [
    {value: 'Speak Some', label: 'Speak Some'},
    {value: 'Speak Fluently', label: 'Speak Fluently'},
    {value: 'Read', label: 'Read'},
    {value: 'Write', label: 'Write'},
]

const LanguagesRender = ({currentLanguage, languages, setLanguages, toggleModal, edit, index}) => {

    const [language, setLanguage] = useState('');
    const [level, setLevel] = useState('');

    useEffect(() => {
        setCurrentLanguage()
    }, [edit])
    

    const setCurrentLanguage = () => {
        if(edit){
            setLanguage(currentLanguage.language);
            setLevel(currentLanguage.level);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newLanguage = {
            language,
            level,
        };
        if(edit){
            languages[index].language = language;
            languages[index].level = level;
        }else{
            languages.push(newLanguage);
        }
        setLanguages(languages);
        toggleModal();
    }

    const changeLevel = (selectedOption) => {
        if (selectedOption) {
            setLevel(selectedOption.value)
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Language</label>
                    <input className='form-control'
                        name='language'
                        type='text'
                        value={language}
                        onChange={e => setLanguage(e.target.value)}
                        placeholder='Language'
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Level</label>
                    <Select
                    name="form-field-name"
                    placeholder='Level...'
                    value={levelList.filter((option) => {
                        return option.value === level;
                    })}
                    onChange={changeLevel}
                    isSearchable={true}
                    options={levelList}
                />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
        </form>
    )
}

export default LanguagesRender