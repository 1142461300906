import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import SellerPicker from '../seller/SellerPicker'
import DatePicker from 'react-datepicker'
import JobTypePicker from '../job/JobTypePicker'
import { toCurrency } from '../utils/Convert'
import JobPoorPicker from '../job/JobPoorPicker'
import 'react-datepicker/dist/react-datepicker.css'
import { api } from '../utils/Api'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from 'react-select'
import { components } from "react-select"
import PsiPicker from '../psi/PsiPicker'
import SlumpPicker from '../slump/SlumpPicker'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { cityList } from '../utils/CityList'
import Modal from 'react-bootstrap/esm/Modal'
import AddressGeolocation from '../map/AddressGeolocation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlantPicker from '../plant/PlantPicker'
import ForemanPicker from '../foreman/ForemanPicker'
import ForemanNew from '../foreman/ForemanNuevo'
import SpinnerButton from '../layout/SpinnerButton'

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
export default class QuoteNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerName: '',
            customerNumber: '',
            customer: '',
            name: '',
            email: '',
            sms: '',
            contactPhone: '',
            contactType: 'Email',
            notifiableEvents: [],
            loadYD3: 0,
            targetYD3: '',
            shipAddress: '',
            shipCity: '',
            shipState: 'TX',
            shipZip: '',
            jobForeman: '',
            foremanPhone: '',
            apContact: '',
            apPhone: '',
            contact: '',
            date: new Date(),
            scheduledDate: new Date(),
            time: '08:00 AM',
            destinationMinutes: 0,
            taxExemptJob: false,
            comments: '',
            po: '',
            jobDuration: '',
            unitPrice: '',
            taxPrice: 0,
            slump: '',
            cliente: '',
            jobPoor: '',
            jobType: '',
            number: 0,
            psi: '',
            searchAddress: false,
            selectedOption: '',
            plant: '',
            latitude: '',
            longitude: '',
            showErrorModal: false,
            messageModal: '',
            titleModal: '',
            foreman: '',
            foremanModal: false,
            loading: true,
            readOnlyAddress: true
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    //Labels for notifiable events
    options = [
        { value: 'newJob', label: 'New Job' },
        { value: 'newLoad', label: 'New Load' },
        { value: 'newInvoice', label: 'New Invoice' },
        { value: 'newEticket', label: 'New Eticket' }
    ]
    onChangeDate = date => {
        this.setState({
            scheduledDate: date
        })
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === 'unitPrice') {
            this.setState({
                taxPrice: e.target.value * 1.0825
            })
        }
    }

    changePhone = e => this.setState({contactPhone: e})

    onChangeSMS = e => {
        this.setState({
            sms: e
        });
    }
    // Notifications selection
    onChangeNotifiable = e => {
        if (e) {
            this.setState({
                notifiableEvents: e
            })
        }
    }

    onChangeTime(e) {
        this.setState({
            time: e.target.value
        })
    }

    onChangeSeller = selectedOption => {
        if (selectedOption) {
            this.setState({
                seller: selectedOption.value
            })
        }
    }

    onChangeSlump = selectedSlump => {
        if (selectedSlump) {
            this.setState({
                slump: selectedSlump.value
            })
        }
    }

    onChangePsi = selectedPsi => {
        if (selectedPsi) {
            this.setState({
                psi: selectedPsi.label
            })
        }
    }

    // change plant
    onChangePlant = selectedPlant => {
        if (selectedPlant) {
            this.setState({
                plant: selectedPlant.value,
            })
        }
    }

    // jobPoor option
    onChangeJobPoor = selectedPoor => {
        if (selectedPoor) {
            this.setState({
                jobPoor: selectedPoor.label
            })
        }
    }

    // job type option
    onChangeJobType = selectedType => {
        if (selectedType) {
            this.setState({
                jobType: selectedType.label
            })
        }
    }

    // customer select
    onChangeCustomer = selectedCustomer => {
        if (selectedCustomer) {
            this.setState({
                customer: selectedCustomer.value
            })
        }
    }

    //Toggle error modal
    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal
        })
    }

    onSubmit(e) {
        e.preventDefault()
        // if (this.state.seller) {
        //     this.creteNewJob()
        // } else {
        //     alert('Quote must have a seller assigned')
        // }
        if (!(this.state.searchAddress || this.state.foremanModal)) {
            if (this.state.customerName &&
                this.state.seller &&
                this.state.psi &&
                this.state.shipAddress &&
                this.state.shipCity &&
                (this.state.sms || this.state.email) &&
                this.state.jobForeman &&
                this.state.targetYD3 &&
                this.state.slump &&
                this.state.jobType &&
                this.state.jobPoor &&
                this.state.unitPrice &&
                this.state.contactPhone &&
                this.state.plant) {
                    this.setState({
                        loading: true
                    })
                    this.createNewJob()
            } else {
                let missingFields = []
                if (!this.state.seller) missingFields.push('Seller')
                if (!this.state.customerName) missingFields.push('Customer')
                if (!this.state.jobType) missingFields.push('Job Type')
                if (!this.state.jobPoor) missingFields.push('Job Pour')
                if (!this.state.psi) missingFields.push('Psi')
                if (!this.state.shipAddress) missingFields.push('Shipping Address')
                if (!this.state.shipCity) missingFields.push('Shipping City')
                if (!this.state.jobForeman) missingFields.push('Foreman')
                if (!this.state.targetYD3) missingFields.push('Target YD3')
                if (!this.state.unitPrice) missingFields.push('Unit Price')
                if (!this.state.slump) missingFields.push('Slump')
                if (!this.state.plant) missingFields.push('Plant')
                if (!this.state.email && this.state.contactType === 'Email') missingFields.push('Email')
                if (!this.state.sms && this.state.contactType === 'SMS') missingFields.push('SMS')
                if (!this.state.contactPhone) missingFields.push('Contact Phone')

                let customeMessage = ''
                for (let i = 0; i < missingFields.length; i++) {
                    if (i !== missingFields.length - 1) {
                        customeMessage += missingFields[i] + ', '
                    } else {
                        customeMessage += missingFields[i]
                    }
                }

                this.setState({
                    messageModal: `Missing fields: ${customeMessage}`,
                    titleModal: 'Missing fields to fill'
                })
                this.toggleErrorModal()
                this.setState({
                    loading : false
                })
            }
        }
    }

    createNewJob = async () => {
        const customer = {
            name: this.state.customerName,
            email: this.state.email,
            sms: this.state.sms,
            contactPhone: this.state.contactPhone,
            contactType: this.state.contactType,
            notifiableEvents: this.state.notifiableEvents,
            isProspect: true,
            contactName: this.state.customerName,
            address: this.state.shipAddress,
            city: this.state.shipCity,
            state: this.state.shipState,
            zip: this.state.shipZip,
            quadrantType: 'IC',
            customerNumber: this.state.customerNumber
        }

        const urlCustomer = api + '/customer'

        try {
            const { data } = await axios.post(urlCustomer, customer)

            const job = {
                ...this.state,
                jobStatus: 'Inactive',
                isPhoneQuote: true,
                taxExemptJob: false,
                customer: data._id,
            }

            const url = api + '/job'
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, job)
            this.setState({
                loading: false
            })
            this.props.history.push('/quotelist')
        } catch (err) {
            this.setState({
                loading : false
            })
            console.error(err.message)
            return null
        }
    }

    quoteList = () => {
        this.props.history.push('/quotelist')
    }

    getJobId = async () => {
        const url = api + '/jobid'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                number: data.jobId,
                loading: false
            })

        } catch (err) {
            this.setState({loading : false})
            console.error(err.message)
            return null
        }
    }

    getProspectNumber = async () => {
        const url = api + '/prospectnumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                customerNumber: 'B' + data.prospectNumber.toString(),
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // show/hide search modal
    onShowModal = (e) => {
        this.setState({
            searchAddress: !this.state.searchAddress
        })
    }

    setSelected = selected => {
        this.setState({
            shipCity: selected.label,
            selectedOption: selected
        })
    }

    // Foreman picker on change
    onChangeForeman = selectedForeman => {
        if (selectedForeman) {
            let option = selectedForeman.value.split(',')
            let foremanID = option[0]
            let foremanName = option[1]
            let foremanPhone = option[2]
            this.setState({
                foremanPhone: foremanPhone,
                jobForeman: foremanName,
                foreman: foremanID,
            })
        }
    }

    // show/hide foreman modal
    onShowNewForeman = (e) => {
        this.setState({
            foremanModal: !this.state.foremanModal
        })
    }

    // new foreman modal
    onNewFormanNew = (nuevo) => {
        this.setState({
            foremanModal: false,
            jobForeman: nuevo.firstName + ' ' + nuevo.lastName,
            foremanPhone: nuevo.phone,
            foreman: nuevo.foreman
        })
    }

    // address from modal
    handleCallback = (childData) => {
        const destinationMinutesString = childData.duration.split(" ")[0]
        const destination = parseFloat(destinationMinutesString)
        this.setState({
            shipAddress: childData.address,
            destinationMinutes: destination,
            searchAddress: false,
            shipCity: childData.locality,
            latitude: childData.destLat,
            longitude: childData.destLng,
            readOnlyAddress: false
        })
        let cityFound = cityList.find((city) => city.label === childData.locality)
        if (typeof cityFound !== "undefined") {
            this.setSelected({ value: childData.locality.toLowerCase(), label: childData.locality })
        } else {
            alert(`The city ${childData.locality} is not defined in list, please report to IT team`)
        }
    }


    componentDidMount() {
        this.getJobId()
        this.getProspectNumber()
    }

    JobForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <div className='form-group'>
                <input
                    type='text'
                    className='form-control'
                    readOnly
                    value={'Job ID: ' + this.state.number}
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Customer</label>
                <input
                    type='text'
                    className='form-control'
                    name='customerName'
                    onChange={this.inputChangeHandler}
                    value={this.state.customerName}
                />
            </div>
            <div className="form-group">
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Type (*):</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='Email'
                                checked={this.state.contactType == "Email"}
                                onChange={this.inputChangeHandler}
                            />
                            Email
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='contactType'
                                value='SMS'
                                checked={this.state.contactType == "SMS"}
                                onChange={this.inputChangeHandler}
                            />
                        </label>
                        SMS
                    </div>
                </div>
            </div>
            {this.state.contactType == "Email"
                ? <div className="form-group">
                    <input
                        name="email"
                        onChange={this.inputChangeHandler}
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        placeholder="Contact email"
                    />
                </div>
                : <div className="form-group">
                    <PhoneInput
                        name="sms"
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Customer phone"
                        value={this.state.sms}
                        onChange={this.onChangeSMS}
                    />
                </div>
            }
            <div className="form-group">
                <label className="text-muted">Notifiable events</label>
                <Select
                    options={this.options}
                    // placeholder='Select Fields '
                    onChange={this.onChangeNotifiable}
                    isMulti
                    value={this.state.notifiableEvents}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    components={{
                        Option
                    }}
                    allowSelectAll={true}
                />
            </div>
            <div className='form-group row'>
                <div className='col-sm 6'>
                    <label className='text-muted'>Contact number (*)</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='contactPhone'
                        placeholder='Contact Phone Number'
                        value={this.state.contactPhone}
                        onChange={this.changePhone}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Plant</label>
                    <PlantPicker action={this.onChangePlant} value={this.state.plant} />
                </div>
            </div>

            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>PSI</label>
                    <PsiPicker action={this.onChangePsi} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Slump</label>
                    <SlumpPicker action={this.onChangeSlump} />
                </div>
            </div>

            <div className='form-group'>
                <label className='text-muted'>Seller</label>
                <SellerPicker action={this.onChangeSeller} />
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Type</label>
                    <JobTypePicker action={this.onChangeJobType} placeholder={'Job Type...'} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Pour</label>
                    <JobPoorPicker action={this.onChangeJobPoor} placeholder={'Job Pour...'} />
                </div>
            </div>

            &nbsp;
            
            <div className="form-group row">
                <div className='col-sm-4'>
                    <button className='btn btn-primary' onClick={this.onShowModal} disabled={!this.state.plant}>
                        Search address <FontAwesomeIcon icon={faSearch}  />
                    </button>
                </div>
                <div className='col-sm-8'>
                    <input
                        type="text"
                        name='shipAddress'
                        className="form-control"
                        value={this.state.shipAddress}
                        readOnly={this.state.readOnlyAddress}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <br></br>
            <div className='form-row d-flex justify-content-end'>
                <div className='col-sm-8'>
                    <Select
                        options={cityList}
                        onChange={this.setSelected}
                        value={this.state.selectedOption}
                        placeholder="City..."
                    />
                </div>
            </div>
            <div>
                {
                    this.state.searchAddress &&
                    <Modal show={this.state.searchAddress} animation={false} onHide={this.onShowModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressGeolocation
                                search={this.state.searchAddress}
                                parentCallback={this.handleCallback}
                                single={false}
                                plant={this.state.plant}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
            <div className='form-group'>
                <label className='text-muted'>Time to destination (min)</label>
                <input
                    type="number"
                    name='destinationMinutes'
                    className='form-control'
                    readOnly
                    value={this.state.destinationMinutes}
                />
            </div>
            <div className="form-group row">
                <div className="col-sm-6">
                    <label className="text-muted">Foreman:&nbsp;</label><label>{this.state.jobForeman || ' Not defined yet'}</label>
                    <ForemanPicker action={this.onChangeForeman} selectedOption={this.state.jobForeman} />
                </div>
                <div className="col-sm-6">
                    <br />
                    <button
                        className='button btn-primary'
                        onClick={this.onShowNewForeman}
                    >New foreman +
                    </button>
                    <Modal show={this.state.foremanModal} animation={false} onHide={this.onShowNewForeman}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Foreman</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ForemanNew parentCallback={this.onNewFormanNew} />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Target YD3</label>
                    <input
                        type="number"
                        name='targetYD3'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.targetYD3}
                        placeholder='?'
                    />
                </div>
                <div className='col-sm-6'>
                    <div>
                        <label className="text-muted">Job date/time</label>
                    </div>

                    <DatePicker
                        selected={this.state.scheduledDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        onChange={this.onChangeDate}
                    />
                </div>
            </div>
            <div className="form-group row">
                {/* <div className='col-sm-6'>
                    <div>
                        <label className="text-muted">Job date/time</label>
                    </div>

                    <DatePicker
                        selected={this.state.scheduledDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        onChange={this.onChangeDate}
                    />
                </div> */}
                {/* <div className='col-sm-6'>
                    <label className='text-muted'>Time to destination (min)</label>
                    <input
                        type="text"
                        name='destinationMinutes'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.destinationMinutes}
                    />
                </div> */}
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Unit price</label>
                    <input
                        type="text"
                        name='unitPrice'
                        className="form-control"
                        onChange={this.inputChangeHandler}
                        placeholder="$"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Price with Tax</label>
                    <input
                        type="text"
                        name='taxPrice'
                        readOnly
                        className='form-control'
                        value={toCurrency(this.state.taxPrice, 2)}
                    />
                </div>
            </div>
            <div className='form-group'>
                <label className='text-muted'>Comments</label>
                <input
                    type='text'
                    name='comments'
                    className='form-control'
                    onChange={this.inputChangeHandler}
                />
            </div>
            <div className="my-2">
                    {
                        this.state.loading ?
                        <SpinnerButton type={'primary'}/>
                        :
                        <button className='btn btn-primary' type='submit'> Create </button>
                    }
                &nbsp;
                <input
                    type="text"
                    className="btn btn-secondary"
                    readOnly
                    value="In Call List"
                    onClick={(e) => this.quoteList()}
                />
            </div>
        </form>
    )

    render() {
        return (
            <Layout
                title='In Call'
                description='Create New In Call Job'
                className="container col-md-8 offset-md-2"
            >
                {this.JobForm()}
                <Modal
                    show={this.state.showErrorModal}
                    animation={false}
                    onHide={this.toggleErrorModal}
                    size='lg'
                    centered
                    dialogClassName='modal-width'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.titleModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.messageModal}</p>
                    </Modal.Body>
                </Modal>
            </Layout>
        )
    }
}