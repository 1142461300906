import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Tag, Row, Col } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import { typeIsnpectionOptions } from '../../constants/Inspections'
import { CSVLink } from "react-csv"
const { Text } = Typography

const InspectionDefects = ({history}) => { 
    const [loading, setLoading] = useState(true)
    const [inspectionList, setInspectionList] = useState([])
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [type, setType] = useState('ALL')

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                let color = 'red'
                if(value === 'PRE'){
                    color = 'green'
                }else if(value === 'POST'){
                    color = 'blue'
                }else{
                    color = 'red'
                }
                return <Tag color={color}>{value}</Tag>
            }
        },
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Defects',
            dataIndex: 'defects',
            key: 'defects',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
      getInspectionList('')
    }, [])
    
    const getInspectionList = async(term) => {
        setLoading(true)

        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/inspectionsdefects/search?startDate=${start}&endDate=${end}&term=${term}&type=${type}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            inspectionData(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const inspectionData = (inspections) => {
        let defects = []
        let comments = []
        let list = []
        inspections.map((inspection, index) => {
            if(inspection.cab === true) defects.push('Cab/Doors/Windows')
            if(inspection.body === true) defects.push('Body/Doors')
            if(inspection.oilLeak === true) defects.push('Oil Leak')
            if(inspection.greaseLeak === true) defects.push('Grease Leak')
            if(inspection.coolantLeak === true) defects.push('Coolant Leak')
            if(inspection.fuelLeak === true) defects.push('Fuel Leak')
            if(inspection.otherGeneral === true) defects.push('Other General')
            if(inspection.oilLevel === true) defects.push('Oil Level')
            if(inspection.coolantLevel === true) defects.push('Coolant Level')
            if(inspection.belts === true) defects.push('Belts/Hoses')
            if(inspection.otherEngine === true) defects.push('Other Engine')
            if(inspection.guages === true) defects.push('Guages/Warning Indicators')
            if(inspection.windshield === true) defects.push('Winshield Wiper(s)/Washer(s)')
            if(inspection.horn === true) defects.push('Horn(s)')
            if(inspection.heater === true) defects.push('Heater/Defroser')
            if(inspection.mirrors === true) defects.push('Mirrors')
            if(inspection.steering === true) defects.push('Steering')
            if(inspection.clutch === true) defects.push('Clutch')
            if(inspection.serviceBrakes === true) defects.push('Service Brakes')
            if(inspection.parkingBrakes === true) defects.push('Parking Brakes')
            if(inspection.emergencyBrakes === true) defects.push('Emergency Brakes')
            if(inspection.triangles === true) defects.push('Triangles/Flares')
            if(inspection.fireExtinguisher === true) defects.push('Fire Extinguisher')
            if(inspection.otherSafetyEquipment === true) defects.push('Other Safety Equipment')
            if(inspection.spareFuses === true) defects.push('Spare Fuses')
            if(inspection.seatBelts === true) defects.push('Seat Belts')
            if(inspection.lights === true) defects.push('Lights')
            if(inspection.reflectors === true) defects.push('Reflectors')
            if(inspection.suspension === true) defects.push('Suspension')
            if(inspection.tires === true) defects.push('Tires')
            if(inspection.wheels === true) defects.push('Wheels/Rims/Lugs')
            if(inspection.battery === true) defects.push('Battery')
            if(inspection.exhaust === true) defects.push('Exhaust')
            if(inspection.brakes === true) defects.push('Brakes')
            if(inspection.airLines === true) defects.push('Air Lines')
            if(inspection.lightLine === true) defects.push('Light Line')
            if(inspection.fifthWheel === true) defects.push('Fifth Wheel')
            if(inspection.otherCoupling === true) defects.push('Other Coupling')
            if(inspection.tieDowns === true) defects.push('Tie-Downs')
            if(inspection.rearEndProtection === true) defects.push('Rear-End Protection')
            if(inspection.otherExterior === true) defects.push('Other Exterior')
            if(inspection.allChutesSecure === true) defects.push('All Chutes Secure')
            if(inspection.hydraulicSystem === true) defects.push('Hydraulic System')
            if(inspection.waterSystem === true) defects.push('Water System')
            if(inspection.checkForLeaks === true) defects.push('Check For Leaks')
            if(inspection.hopper === true) defects.push('Hopper')
            if(inspection.controlCables === true) defects.push('Control Cables')
            if(inspection.ladders === true) defects.push('Ladders')
            if(inspection.waterTank === true) defects.push('Water Tank Glass Clean')
            if(inspection.holdDowns === true) defects.push('Hold Downs')
            if(inspection.chainAdjusted === true) defects.push('Chain Adjusted')
            if(inspection.controlLevers === true) defects.push('Control Levers')
            if(inspection.counterWorking === true) defects.push('Counter Working')
            if(inspection.rollerDrum === true) defects.push('Roller & Drum')
            if(inspection.otherMixerUnit === true) defects.push('Other Mixer Unit')
            if(inspection.drainWater === true) defects.push('Drain Water Tanks (Open Water & Drain Valves)')
            if(inspection.mixerControls === true) defects.push('Mixer Controls in Neutral')

            if(inspection.otherGeneral){
                if(inspection.otherGeneralComment) comments.push(inspection.otherGeneralComment)
            }
            if(inspection.otherEngine){
                if(inspection.otherEngineComment) comments.push(inspection.otherEngineComment)
            }
            if(inspection.otherExterior){
                if(inspection.otherExteriorComment) comments.push(inspection.otherExteriorComment)
            }
            if(inspection.otherMixerUnit){
                if(inspection.otherMixerUnitComment) comments.push(inspection.otherMixerUnitComment)
            }
            if(inspection.remarks) comments.push(inspection.remarks)

            let textDefects = ''
            defects.map((defect, index2) => {
                if(index2 === defects.length - 1){
                    textDefects += `${defect}`
                }else{
                    textDefects += `${defect}, `
                }
            })

            let customComments = ''
            if(comments.length === 0){
                customComments = 'None'
            }else{
                for (let index3 = 0; index3 < comments.length; index3++) {
                    const comment = comments[index3];
                    if(index3 === comments.length-1){
                        customComments += `${comment}`
                    }else{
                        customComments += `${comment}, `
                    }
                }
            }

            list.push({
                key: index,
                id: inspection._id,
                driver: inspection.driver ? inspection.driver.name : '',
                plant: inspection.plant ? inspection.plant.name : '',
                truck: inspection.truck,
                type: inspection.type,
                date: dayjs(inspection.createdAt).format('MM/DD/YYYY HH:mm A'),
                defects: textDefects,
                comments: customComments
            })

            defects = []
            comments = []
        })
        setInspectionList(list)
        setLoading(false)
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            getInspectionList(value)
        }
    }       

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const onChangePicker = (value) => {
        setType(value)
    }

    const headers = () => {
        return columns.map((item) => {
            return {
                key: item.key,
                label: item.title
            }
        })
    }

    const RenderSearch = () => {
        return <CustomSearch 
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        loading={loading}
        onRangeChange={onRangeChange}
        onSearch={onSearch}
        labelPicker={'status'}
        optionsPicker={typeIsnpectionOptions}
        onChangePicker={onChangePicker}
        defaultValuePicker={type}
        pickerWidth={'200px'}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'}>
            <CustomTable 
            columns={columns}
            data={inspectionList}
            />
        </Flex>
    }

    const RenderCSV = () => {
        return <CSVLink data={inspectionList} headers={headers()} filename={"57concrete-report-defects.csv"}>
            Download report
        </CSVLink>
    }

    return (
        <Layout
            title='Inspections Defects'
            description='Defects'
        >
            <Flex vertical gap={'large'}>
                <RenderSearch />
                {
                    loading ? <CustomSpinner/> : 
                    <Row gutter={16}>
                        <Col span={24}>
                            <RenderTable/>
                        </Col>
                        <Col span={24}>
                            <RenderCSV />
                        </Col>
                    </Row>
                } 
            </Flex>
        </Layout>
    )
}

export default InspectionDefects