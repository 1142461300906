import React, { Component } from 'react';
import Select from 'react-select';

export default class WarningPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isSearchable: true,
            options: [
                {value: 'First Warning', label: 'First Warning'},
                {value: 'Second Warning', label: 'Second Warning'},
                {value: 'Final Warning', label: 'Final Warning'},
            ]
        }
    }

    render() {
        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Warning...'
                    value={this.state.options.filter((o) => { return o.value === this.props.value })}
                    onChange={this.props.action}
                    isSearchable={this.state.isSearchable}
                    options={this.state.options}
                />
                {
                    this.props.isRequired &&
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={this.props.value}
                        required
                    />
                }
            </div>
        )
    } 
}
