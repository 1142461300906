import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { api } from '../utils/Api'

export default class Credit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            legalCompanyName: '',
            id: '',
            businessType: '',
            address: '',
            city: '',
            county: '',
            state: 'TX',
            zip: '',
            mailingAddress: '',
            mailingCity: '',
            mailingCounty: '',
            mailingState: 'TX',
            mailingZip: '',
            email: '',
            phone: '',
            fax: '',
            fedTaxIdNumber: '',
            salesTaxExempt: false,
            typeOfBusiness: '',
            contractorType: '',
            businessStartDate: '', 
            employeeCount: '',
            shipComplete: false,
            businessLicense: '', 
            openLiens: false,
            apContact: '',
            apPhone: '',
            apFax: '',
            poRequiered: false,
            invoicesByEmail: false,
            primaryEmail: '',
            secondaryEmail: '',
            president: '',
            vicepresident: '', 
            treasurer: '',
            otherOfficer: '',
            insuranceAgent: '',
            insuranceAgentPhone: '',
            references: [],
            gurntors: [],
            role: ''
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    goPdfForm = () => {
        console.log('ide intificado:' + this.state.id)
        const url = '/creditpdf/' + this.state.id
        console.log(url)
        this.props.history.push(url)
    }

    // tax exempt
    onTaxExempt = e => {
        this.setState({
            salesTaxExempt: !this.state.salesTaxExempt
        })
    }

    // PO requiered
    onPORequiered = e => {
        this.setState({
            poRequiered: !this.state.poRequiered
        })
    }

    // invoices by email
    onInvoicesbyEmail = e => {
        this.setState({
            invoicesByEmail: !this.state.invoicesByEmail
        })
    }

    deleteCredit = async () => {
        console.log('delete credit')
        const url = api + '/credit/' + this.props.match.params.id      
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            await axios.delete(url)            
            this.props.history.push('/creditlist')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    getCredit = async () => {
        const url = api + '/credit/' + this.props.match.params.id      
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)

            this.setState({
                legalCompanyName: data.legalCompanyName,
                businessType: data.businessType,
                address: data.address,
                city: data.city,
                county: data.county,
                state: data.state,
                zip: data.zip,
                mailingAddress: data.mailingAddress,
                mailingCity: data.mailingCity,
                mailingCounty: data.mailingCounty,
                mailingState: data.state,
                mailingZip: data.zip,
                email: data.email,
                phone: data.phone,
                fax: data.fax,
                fedTaxIdNumber: data.fedTaxIdNumber,
                salesTaxExempt: data.salesTaxExempt,
                typeOfBusiness: data.typeOfBusiness,
                contractorType: data.contractorType,
                businessStartDate: data.businessStartDate,
                employeeCount: data.employeeCount,
                shipComplete: data.shipComplete,
                businessLicense: data.businessLicense,
                openLiens: data.openLiens,
                apContact: data.apContact,
                apPhone: data.apPhone,
                apFax: data.apFax,
                poRequiered: data.poRequiered,
                invoicesByEmail: data.invoicesByEmail,
                primaryEmail: data.primaryEmail,
                secondaryEmail: data.secondaryEmail,
                president: data.president,
                vicepresident: data.vicepresident,
                treasurer: data.treasurer,
                otherOfficer: data.otherOfficer,
                insuranceAgent: data.insuranceAgent,
                insuranceAgentPhone: data.insuranceAgentPhone,               
                references: data.references,
                gurntors: data.gurntors,
                id: data._id
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    updateCreditApp = async () => {
        const url = api + '/credit/' + this.props.match.params.id      
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const credit = {
            ...this.state
        }

        console.log(credit)

        try {
            await axios.patch(url, credit)
            this.props.history.push('/creditlist')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getCredit()
    }

    onSubmit(e) {
        e.preventDefault()
        console.log('credit application update request')
        this.updateCreditApp()
    }

    renderLoading() {
        return <Spinner />
    } 

    renderGuarnator = () => (
        <div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Guarantors for Purchases of buyer</center>                
            </div>            
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Guarantor's Name</label>
                    <input 
                        type='text'
                        placeholder='First guarantor Name'
                        name='guarantorName1'                        
                        className='form-control'
                        required
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>S/S Number</label>
                    <input 
                        type='text'
                        placeholder='First guarantor S/S Number'
                        name='guarantorSS1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor Phone'
                        name='guarantorPhone1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>             
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <label className='text-muted'>Address</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor Address'
                        name='guarantorAddress1'                        
                        className='form-control'
                        required
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <label className='text-muted'>City</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor City'
                        name='guarantorCity1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>State</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor State'
                        name='guarantorState1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>Zip</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor Zip'
                        name='guarantorZip1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Guarantor's Name</label>
                    <input 
                        type='text'
                        placeholder='Second guarantor Name'
                        name='guarantorName2'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>S/S Number</label>
                    <input 
                        type='text'
                        placeholder='Second guarantor S/S Number'
                        name='guarantorSS2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor Phone'
                        name='guarantorPhone2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>             
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <label className='text-muted'>Address</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor Address'
                        name='guarantorAddress2'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <label className='text-muted'>City</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor City'
                        name='guarantorCity2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>State</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor State'
                        name='guarantorState2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>Zip</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor Zip'
                        name='guarantorZip2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
        </div>
    )

    CreditForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Legal Company Name</label>
                    <input 
                        type='text'
                        placeholder='Company name'
                        name='legalCompanyName'
                        required
                        className='form-control'
                        value={this.state.legalCompanyName}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Business Type</label>
                    <input 
                        type='text'
                        placeholder='type of business'
                        name='businessType'
                        required
                        className='form-control' 
                        value={this.state.businessType}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Street Address</label>
                    <input 
                        type='text'
                        placeholder='Company address'
                        name='address'
                        required
                        className='form-control'
                        value={this.state.address}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>City</label>
                    <input 
                        type='text'
                        placeholder='Company City'
                        name='city'
                        required
                        className='form-control' 
                        value={this.state.city}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                                
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>County</label>
                    <input 
                        type='text'
                        placeholder='Company County'
                        name='county'
                        required
                        value={this.state.county}
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>State</label>
                    <input 
                        type='text'
                        placeholder='Company State'
                        name='state'
                        value={this.state.state}
                        required
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>     
                <div className='col-sm-4'>
                    <label className='text-muted'>Zip</label>
                    <input 
                        type='text'
                        placeholder='Company ZIP'
                        name='zip'
                        required
                        value={this.state.zip}
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <hr />
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Mailing Address</label>
                    <input 
                        type='text'
                        placeholder='Mailing Address'
                        name='mailingAddress'   
                        value={this.state.mailingAddress}                 
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Mailing City</label>
                    <input 
                        type='text'
                        placeholder='Mailing City'
                        name='mailingCity'   
                        value={this.state.mailingCity}                     
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                                
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Mailing County</label>
                    <input 
                        type='text'
                        placeholder='Mailing County'
                        name='mailingCounty'       
                        value={this.state.mailingCounty}                 
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Mailing State</label>
                    <input 
                        type='text'
                        placeholder='Mailing State'
                        name='mailingState'    
                        value={this.state.mailingState}                    
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>     
                <div className='col-sm-4'>
                    <label className='text-muted'>Mailing Zip</label>
                    <input 
                        type='text'
                        placeholder='Mailing ZIP'
                        name='mailingZip' 
                        value={this.state.mailingZip}                       
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <hr />
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Email</label>
                    <input 
                        type='text'
                        placeholder='Email'
                        name='email'                        
                        className='form-control'
                        value={this.state.email}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone'                        
                        className='form-control' 
                        value={this.state.phone}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>     
                <div className='col-sm-4'>
                    <label className='text-muted'>Fax</label>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax'                        
                        className='form-control' 
                        value={this.state.fax}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <hr />
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Federal Tax ID Number</label>
                    <input 
                        type='text'
                        placeholder='TAX ID'
                        name='fedTaxIdNumber'                        
                        className='form-control'
                        value={this.state.fedTaxIdNumber}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <input  name="accept"
                            type="checkbox"
                            checked={this.state.salesTaxExempt}
                            onChange={this.onTaxExempt}
                    />
                    <label>&nbsp;Sales Tax Exempt</label>
                </div>            
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Type of business</label>
                    <input 
                        type='text'
                        placeholder='Type of business'
                        name='typeOfBusiness'                        
                        className='form-control'
                        value={this.state.typeOfBusiness}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Contractor Type</label>
                    <input 
                        type='text'
                        placeholder='Contractor Type'
                        name='contractorType'                        
                        className='form-control' 
                        value={this.state.contractorType}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>               
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Accounts Payable Info</center>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>AP Contact</label>
                    <input 
                        type='text'
                        placeholder='Contact'
                        name='apContact'                        
                        className='form-control'
                        value={this.state.apContact}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='apPhone'                        
                        className='form-control' 
                        value={this.state.apPhone}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-4'>
                    <label className='text-muted'>Fax</label>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='apFax'                        
                        className='form-control' 
                        value={this.state.apFax}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <input  name="poRequired"
                            type="checkbox"
                            checked={this.state.poRequiered}
                            onChange={this.onPORequiered}
                    />
                    <label>&nbsp;PO # required</label>
                </div>
                <div className='col-sm-6'>
                    <input  name="invoicesByEmail"
                            type="checkbox"
                            checked={this.state.invoicesByEmail}
                            onChange={this.onInvoicesbyEmail}
                    />
                    <label>&nbsp;Invoices by Email</label>
                </div>                         
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Primary Email</label>
                    <input 
                        type='text'
                        placeholder='AP Email'
                        name='primaryEmail'                        
                        className='form-control'
                        value={this.state.primaryEmail}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Secondary Email</label>
                    <input 
                        type='text'
                        placeholder='AP Secondary Email'
                        name='secondaryEmail'                        
                        className='form-control' 
                        value={this.state.secondaryEmail}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                           
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Officers</center>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>President</label>
                    <input 
                        type='text'
                        placeholder='President'
                        name='president'                        
                        className='form-control'
                        value={this.state.president}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Vice President</label>
                    <input 
                        type='text'
                        placeholder='Vice President'
                        name='vicepresident'                        
                        className='form-control' 
                        value={this.state.vicepresident}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>              
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Treasurer</label>
                    <input 
                        type='text'
                        placeholder='Treasurer'
                        name='treasurer'                        
                        className='form-control'
                        value={this.state.treasurer}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Other officer</label>
                    <input 
                        type='text' 
                        name='otherOfficer'                        
                        className='form-control' 
                        value={this.state.otherOfficer}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>              
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Insurance Agent</label>
                    <input 
                        type='text'
                        placeholder='Insurance Agent'
                        name='insuranceAgent'                    
                        className='form-control'
                        value={this.state.insuranceAgent}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Insurance Agent Phone</label>
                    <input 
                        type='text' 
                        name='insuranceAgentPhone'    
                        placeholder='Insurance Agent phone'                    
                        className='form-control' 
                        value={this.state.insuranceAgentPhone}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>              
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Trade References</center>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <label className='text-muted'>Business Name</label>
                    <input 
                        type='text'
                        placeholder='Business Name'
                        name='biz1'                        
                        className='form-control'
                        value={this.state.references[1]}
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <label className='text-muted'>Acct #</label>
                    <input 
                        type='text'
                        placeholder='Account #'
                        name='account1'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone1'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-3'>
                    <label className='text-muted'>Fax</label>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax1'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>         
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Business Name'
                        name='biz2'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Account #'
                        name='account2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>         
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Business Name'
                        name='biz3'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Account #'
                        name='account3'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone3'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax3'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>         
            </div>
            {
                this.state.businessType !== 'CORPORATION' &&
                this.renderGuarnator()
            }                        
            <div className="my-2">
                <input className='btn btn-primary'  type='submit' />
                &nbsp;
                <button className='btn btn-dark' onClick={this.goPdfForm}>
                    <FontAwesomeIcon icon={faPrint} /> PDF
                </button>                
                &nbsp;
                {
                    localStorage.getItem('role') === 'Admin' &&
                        <input 
                            type="text" 
                            className="btn btn-danger" 
                            readOnly
                            value="Delete Application"  
                            onClick={(e) => {if (window.confirm('Are you sure you want to delete the Credit Application?')) this.deleteCredit()}}                            
                        /> 
                }                
            </div>    
        </form>
    )

    render() {
        return (
            <Layout
                title='Credit Application'
                description='Edit Credit Application'
                className="container col-md-8 offset-md-2"
            >                   
                {
                    this.state.loading ?
                    this.renderLoading()
                    : this.CreditForm()
                }
            </Layout>
        )
    }
}