import axios from 'axios'
import { setAlert } from './alert'
import { 
    REGISTER_SUCESS, 
    REGISTER_FAIL, 
    USER_LOADED, 
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT
} from './types'
import setAuthToken from '../utils/setAuthToken'


// register user
export const register = ({ name,email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ name, email, password })

    try {
        const res = await axios.post('/api/users', body, config)
        dispatch({
            type: REGISTER_SUCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }

        dispatch({
            type: REGISTER_FAIL
        })
    }
}

// login user
export const login = (email, password, setLoading) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    let correo = email

    if (correo.indexOf('@') === -1) {
        correo = email + '@gmail.com'
    } 

    // const body = JSON.stringify({ email, password })
    const body = {
        email: correo,
        password
    }
    
    try {
        const {data} = await axios.post('/api/login', body, config) 
        // console.log(data)
        // console.log('token', data.token)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })

    } catch (err) {
        console.log(err.response)
        const { data } = err.response

        console.log(data)
        if (data) {
            const errors = data.Errors
            console.log(errors)
            dispatch(setAlert(errors, 'danger'))
            // errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }

        dispatch({
            type: LOGIN_FAIL
        })
    }
}

// logout user
export const logout = () => dispatch => {
    // console.log('action logout')
    dispatch({ type: LOGOUT })
}