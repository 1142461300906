import {
    ADD_REFERRAL,
    GET_REFERRAL,
    GET_REFERRALS,
    DELETE_REFERRAL,
    REFERRAL_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    referral: null,
    referrals: []
}

function referralReducer(state = initialState, action) {
    const { type, payload } = action 

    switch (type) {
        case ADD_REFERRAL:
            return {
                ...state,
                referral: payload,
                loading: false
            }
        case GET_REFERRAL:
            return {
                ...state,
                referral: payload,
                loading: false
            }
        case GET_REFERRALS:
            return {
                ...state,
                referrals: payload,
                loading: false
            }
        case DELETE_REFERRAL:
            return {
                ...state,
                referral: payload,
                loading: false
            }
        case REFERRAL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default referralReducer
