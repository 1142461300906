import React, { useEffect, useState, useRef } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { getLoyaltyGroup, deleteFormanLoyalty } from '../../actions/loyalty'
import CreateLoyalty from './CreateLoyalty'
import Modal from 'react-bootstrap/Modal'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import axios from 'axios'
import { toThousands } from '../utils/Convert'

const LoyaltyBySeller = ({ match, getLoyaltyGroup, deleteFormanLoyalty, loading, loyalties }) => {
    const seller = match.params.id
    const sellerLoyalty = useRef()

    const [titleLabel, setTitleLabel] = useState(`Loyalty Plan`)
    const [sellerName, setSellerName] = useState('')
    const [showCreate, setShowCreate] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getLoyaltyGroup()
        getSellerInfo()
    }, [refresh])

    if (loyalties) {
        sellerLoyalty.current = loyalties.filter(loy => loy.seller === seller)
    }

    const getSellerInfo = async () => {
        const url = api + `/users/${seller}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setSellerName(data.name)
            setTitleLabel(`Loyalty Plan for ${data.name}`)
            // sellerName.current = data.name
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Modal callback from edit and create
    const updateCallBack = (word) => {
        console.log(word)
        setRefresh(!refresh)
        if (word === 'create') setShowCreate(false)  // CREATE
    }

    const deletePlan = (cap) => {
        deleteFormanLoyalty(cap)
        setRefresh(!refresh)             
    }

    const DrawList = () => {
        const list = sellerLoyalty.current
        console.log(list)
        // sort by foreman name
        list.sort((a, b) => a.foremanName > b.foremanName ? 1 : -1)

        // valores distintos de foreman
        const uniques = [...new Set(list.map(item => item.foremanName))]
        console.log(uniques)

        return uniques.map((nombre, i) => {
            const plan = list.filter(nom => nombre === nom.foremanName)
            plan.sort((a,b) => a.month > b.month ? 1 : -1)
            // console.log(plan)
            const sumRow = plan.reduce((acc,loy) => acc + loy.yards, 0)
            console.log(sumRow)

            return (
                <tr key={i}>
                    <td>{i+1} </td>
                    <td>{nombre}</td>
                    <td className='border'>{plan[0].yards}</td>
                    <td className='border'>{plan[1].yards}</td>
                    <td className='border'>{plan[2].yards}</td>
                    <td className='border'>{plan[3].yards}</td>
                    <td className='border'>{plan[4].yards}</td>
                    <td className='border'>{plan[5].yards}</td>
                    <td className='border'>{plan[6].yards}</td>
                    <td className='border'>{plan[7].yards}</td>
                    <td className='border'>{plan[8].yards}</td>
                    <td className='border'>{plan[9].yards}</td>
                    <td className='border'>{plan[10].yards}</td>
                    <td className='border'>{plan[11].yards}</td>
                    <td className="border" style={{ fontWeight: "bold", textAlign: "right"}}> 
                        {toThousands(sumRow)} 
                    </td>
                    <td>
                        <button 
                            className='btn btn-danger' 
                            onClick={(e) => {if (window.confirm('Are you sure you want to delete?')) deletePlan(plan[0].foreman)}}
                        >
                            -
                        </button>
                    </td>
                </tr>
            )
        })
    }

    const RenderLoyalty = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Foreman</td>
                        <td>JAN</td>
                        <td>FEB</td>
                        <td>MAR</td>
                        <td>APR</td>
                        <td>MAY</td>
                        <td>JUN</td>
                        <td>JUL</td>
                        <td>AUG</td>
                        <td>SEP</td>
                        <td>OCT</td>
                        <td>NOV</td>
                        <td>DEC</td>
                        <td>SUM</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        sellerLoyalty.current.length > 0 ? <DrawList /> : <tr><td colSpan='14'>No data</td></tr>
                    }
                </tbody>
            </table>
        </div>        
    )

    return (
        <Layout
            title={titleLabel}
            description='Loyalty plan'
            className='container col-md-10 '
        >
            <button className='button btn-primary' onClick={(e) => setShowCreate(!showCreate)}>
                    New Loyalty Plan +
            </button>
            <Modal show={showCreate} animation={false} onHide={(e) => setShowCreate(!showCreate)}>
                <Modal.Header closeButton>
                    <Modal.Title>New Loyalty Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateLoyalty updateCB={updateCallBack} seller={seller} sellerName={sellerName}/>
                </Modal.Body>
            </Modal>
            <RenderLoyalty />
        </Layout>
    )
}

const mapStateToProps = state => ({
    loading: state.loyalty.loading,
    loyalties: state.loyalty.loyalties
})

export default connect(mapStateToProps, {getLoyaltyGroup, deleteFormanLoyalty}) (LoyaltyBySeller)