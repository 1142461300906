import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import SignaturePad from 'react-signature-canvas'
import BusinessTypePicker from './BusinessTypePicker'
import { api } from '../utils/Api'

export default class CreditNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            legalCompanyName: '',
            businessType: '',
            address: '',
            city: '',
            county: '',
            state: 'TX',
            zip: '',
            mailingAddress: '',
            mailingCity: '',
            mailingCounty: '',
            mailingState: 'TX',
            mailngZip: '',
            email: '',
            phone: '',
            fax: '',
            fedTaxIdNumber: '',
            salesTaxExempt: false,
            typeOfBusiness: '',
            contractorType: '',
            businessStartDate: '', 
            employeeCount: '',
            shipComplete: false,
            businessLicense: '', 
            openLiens: false,
            apContact: '',
            apPhone: '',
            apFax: '',
            poRequiered: false,
            invoicesByEmail: false,
            primaryEmail: '',
            secondaryEmail: '',
            president: '',
            vicepresident: '', 
            treasurer: '',
            otherOfficer: '',
            insuranceAgent: '',
            insuranceAgentPhone: '',
            references: [],
            gurntors: [],
            acceptConditions: false,
            trimmedDataURL: null
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    sigPad = {}

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // limpiar firma
    clearPad = () => {
        this.sigPad.clear()
    }

    // business type picker
    onChangeBusinessType = selectBT => {
        this.setState({
            businessType: selectBT.label
        })

    }

    // gurdar imagen de firma en state
    trim = () => {
        this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
          .toDataURL('image/png')})        
    }

    // Aceptar cambios
    onAcceptConditions = e => {              
        this.setState({
            acceptConditions: !this.state.acceptConditions            
        })
    }

    // tax exempt
    onTaxExempt = e => {
        this.setState({
            salesTaxExempt: !this.state.salesTaxExempt
        })
    }

    // PO requiered
    onPORequiered = e => {
        this.setState({
            poRequiered: !this.state.poRequiered
        })
    }

    // invoices by email
    onInvoicesbyEmail = e => {
        this.setState({
            invoicesByEmail: !this.state.invoicesByEmail
        })
    }

    addReferences = () => {
        let referencias = []

        if (this.state.biz1) {
            let refe = {
                referenceBusinessName: this.state.biz1,
                referenceAccountNumber: this.state.account1,
                referencePhone: this.state.phone1,
                referenceFax: this.state.fax1
            }
            referencias.push(refe)
        }

        if (this.state.biz2) {
            let refe = {
                referenceBusinessName: this.state.biz2,
                referenceAccountNumber: this.state.account2,
                referencePhone: this.state.phone2,
                referenceFax: this.state.fax2
            }
            referencias.push(refe)
        }

        if (this.state.biz3) {
            let refe = {
                referenceBusinessName: this.state.biz3,
                referenceAccountNumber: this.state.account3,
                referencePhone: this.state.phone3,
                referenceFax: this.state.fax3
            }
            referencias.push(refe)
        }

        console.log(referencias)
        return referencias
    }

    addGrutors = () => {
        let avales = []

        if (this.state.guarantorName1) {
            let aval = {
                guarantorName: this.state.guarantorName1,
                guarantorSSNumber: this.state.guarantorSS1,
                guarantorPhone: this.state.guarantorPhone1,
                guarntorAddress: this.state.guarantorAddress1,
                guarantorCity: this.state.guarantorCity1,
                guarantorState: this.state.guarantorState1,
                guarantorZip: this.state.guarantorZip1
            }
            avales.push(aval)
        }

        if (this.state.guarantorName2) {
            let aval = {
                guarantorName: this.state.guarantorName2,
                guarantorSSNumber: this.state.guarantorSS2,
                guarantorPhone: this.state.guarantorPhone2,
                guarntorAddress: this.state.guarantorAddress2,
                guarantorCity: this.state.guarantorCity2,
                guarantorState: this.state.guarantorState2,
                guarantorZip: this.state.guarantorZip2
            }
            avales.push(aval)
        }

        console.log(avales)
        return avales
    }

    createCreditApplication = async () => {
        const url = api + '/credit' 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const referencias = this.addReferences()
        const avales = this.addGrutors()

        const credit = {
            ...this.state
        }

        if (referencias.length > 0) {
            credit.references = referencias
        }

        if (avales.length > 0) {
            credit.gurntors = avales
        }

        try {
            const role = localStorage.getItem('role')
            if (role === 'Customer') {
                const userId = localStorage.getItem('userId')
                const urlCompany = api + '/mycompany/' + userId
                const res = await axios.get(urlCompany)                
                credit.applicant = res.data._id
            } 

            console.log(credit)
            const { data } = await axios.post(url, credit)
            const creditId = data._id
            console.log(`new creditId: ${creditId}`)
            const sigUrl = api + `/creditsignature/${creditId}`
            this.trim()            
        
            const formData = new FormData()
            const blob = this.dataURItoBlob(this.state.trimmedDataURL)
            formData.append('image', blob, 'firma.png')

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            await axios.post(sigUrl, formData, config)
            this.props.history.push('/creditlist')           
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // auxiliar para convertir el string de firma a blob
    dataURItoBlob = dataURI => {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to an ArrayBuffer
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var _ia = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            _ia[i] = byteString.charCodeAt(i);
        }
    
        var dataView = new DataView(arrayBuffer);
        var blob = new Blob([dataView], { type: mimeString });
        return blob;
    }

    renderGuarnator = () => (
        <div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Guarantors for Purchases of buyer</center>                
            </div>            
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Guarantor's Name</label>
                    <input 
                        type='text'
                        placeholder='First guarantor Name'
                        name='guarantorName1'                        
                        className='form-control'
                        required
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>S/S Number</label>
                    <input 
                        type='text'
                        placeholder='First guarantor S/S Number'
                        name='guarantorSS1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor Phone'
                        name='guarantorPhone1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>             
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <label className='text-muted'>Address</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor Address'
                        name='guarantorAddress1'                        
                        className='form-control'
                        required
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <label className='text-muted'>City</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor City'
                        name='guarantorCity1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>State</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor State'
                        name='guarantorState1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>Zip</label>
                    <input 
                        type='text'
                        placeholder='First Guarantor Zip'
                        name='guarantorZip1'                        
                        className='form-control' 
                        required
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Guarantor's Name</label>
                    <input 
                        type='text'
                        placeholder='Second guarantor Name'
                        name='guarantorName2'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>S/S Number</label>
                    <input 
                        type='text'
                        placeholder='Second guarantor S/S Number'
                        name='guarantorSS2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor Phone'
                        name='guarantorPhone2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>             
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <label className='text-muted'>Address</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor Address'
                        name='guarantorAddress2'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <label className='text-muted'>City</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor City'
                        name='guarantorCity2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>State</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor State'
                        name='guarantorState2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>Zip</label>
                    <input 
                        type='text'
                        placeholder='Second Guarantor Zip'
                        name='guarantorZip2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
        </div>
    )

    onSubmit(e) {
        e.preventDefault()

        if (this.state.businessType) {
            this.createCreditApplication()
        } else {
            alert('You must select a Business Type')
        }
        
    }

    CreditForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Legal Company Name</label>
                    <input 
                        type='text'
                        placeholder='Company name'
                        name='legalCompanyName'
                        required
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Business Type</label>
                    <BusinessTypePicker action={this.onChangeBusinessType} />
                </div>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Street Address</label>
                    <input 
                        type='text'
                        placeholder='Company address'
                        name='address'
                        required
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>City</label>
                    <input 
                        type='text'
                        placeholder='Company City'
                        name='city'
                        required
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                                
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>County</label>
                    <input 
                        type='text'
                        placeholder='Company County'
                        name='county'
                        required
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>State</label>
                    <input 
                        type='text'
                        placeholder='Company State'
                        name='state'
                        value={this.state.state}
                        required
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>     
                <div className='col-sm-4'>
                    <label className='text-muted'>Zip</label>
                    <input 
                        type='text'
                        placeholder='Company ZIP'
                        name='zip'
                        required
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <hr />
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Mailing Address</label>
                    <input 
                        type='text'
                        placeholder='Mailing Address'
                        name='mailingAddress'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Mailing City</label>
                    <input 
                        type='text'
                        placeholder='Mailing City'
                        name='mailingCity'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                                
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Mailing County</label>
                    <input 
                        type='text'
                        placeholder='Mailing County'
                        name='mailingCounty'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Mailing State</label>
                    <input 
                        type='text'
                        placeholder='Mailing State'
                        name='mailingState'                        
                        className='form-control' 
                        value={this.state.mailingState}
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>     
                <div className='col-sm-4'>
                    <label className='text-muted'>Mailing Zip</label>
                    <input 
                        type='text'
                        placeholder='Mailing ZIP'
                        name='mailingZip'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <hr />
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>Email</label>
                    <input 
                        type='text'
                        placeholder='Email'
                        name='email'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>     
                <div className='col-sm-4'>
                    <label className='text-muted'>Fax</label>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <hr />
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Federal Tax ID Number</label>
                    <input 
                        type='text'
                        placeholder='TAX ID'
                        name='fedTaxIdNumber'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <input  name="accept"
                            type="checkbox"
                            checked={this.state.salesTaxExempt}
                            onChange={this.onTaxExempt}
                    />
                    <label>&nbsp;Sales Tax Exempt</label>
                </div>     
                {
                    this.state.salesTaxExempt &&
                    <button >upload</button>
                }         
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Type of business</label>
                    <input 
                        type='text'
                        placeholder='Type of business'
                        name='typeOfBusiness'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Contractor Type</label>
                    <input 
                        type='text'
                        placeholder='Contractor Type'
                        name='contractorType'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>               
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Accounts Payable Info</center>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-4'>
                    <label className='text-muted'>AP Contact</label>
                    <input 
                        type='text'
                        placeholder='Contact'
                        name='apContact'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='apPhone'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-4'>
                    <label className='text-muted'>Fax</label>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='apFax'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>           
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <input  name="poRequired"
                            type="checkbox"
                            checked={this.state.poRequiered}
                            onChange={this.onPORequiered}
                    />
                    <label>&nbsp;PO # required</label>
                </div>
                <div className='col-sm-6'>
                    <input  name="invoicesByEmail"
                            type="checkbox"
                            checked={this.state.invoicesByEmail}
                            onChange={this.onInvoicesbyEmail}
                    />
                    <label>&nbsp;Invoices by Email</label>
                </div>             
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Primary Email</label>
                    <input 
                        type='text'
                        placeholder='AP Email'
                        name='primaryEmail'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Secondary Email</label>
                    <input 
                        type='text'
                        placeholder='AP Secondary Email'
                        name='secondaryEmail'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>                           
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Officers</center>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>President</label>
                    <input 
                        type='text'
                        placeholder='President'
                        name='president'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Vice President</label>
                    <input 
                        type='text'
                        placeholder='Vice President'
                        name='vicepresident'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>              
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Treasurer</label>
                    <input 
                        type='text'
                        placeholder='Treasurer'
                        name='treasurer'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Other officer</label>
                    <input 
                        type='text' 
                        name='otherOfficer'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>              
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Insurance Agent</label>
                    <input 
                        type='text'
                        placeholder='Insurance Agent who handles boinding for buyer'
                        name='insuranceAgent'                    
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Insurance Agent Phone</label>
                    <input 
                        type='text' 
                        name='insuranceAgentPhone'    
                        placeholder='Agent phone'                    
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>              
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center>Trade References</center>                
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <label className='text-muted'>Business Name</label>
                    <input 
                        type='text'
                        placeholder='Business Name'
                        name='biz1'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <label className='text-muted'>Acct #</label>
                    <input 
                        type='text'
                        placeholder='Account #'
                        name='account1'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <label className='text-muted'>Phone</label>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone1'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-3'>
                    <label className='text-muted'>Fax</label>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax1'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>         
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Business Name'
                        name='biz2'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Account #'
                        name='account2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax2'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>         
            </div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Business Name'
                        name='biz3'                        
                        className='form-control'
                        onChange={this.inputChangeHandler}                                       
                    />
                </div>
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Account #'
                        name='account3'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>   
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Phone'
                        name='phone3'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>    
                <div className='col-sm-3'>
                    <input 
                        type='text'
                        placeholder='Fax'
                        name='fax3'                        
                        className='form-control' 
                        onChange={this.inputChangeHandler}                                      
                    />
                </div>         
            </div>
            <div>
                <small>
                Buyer certifies that it is solvent and capable of meeting its obligations hereunder, and that all information (including any requested financial statements) provided to Filegonia Materials LLC and/or its subsidiaries and affiliates (“Seller”) is true, accurate and complete. All such information has been submitted for the purpose of obtaining credit. Buyer and each Guarantor authorize Seller to request credit reports from credit bureaus (including consumer reporting agencies) regarding their respective commercial or personal credit and otherwise to investigate their respective creditworthiness before extending credit now or at any time in the future. Buyer also agrees to comply with all applicable bulk sales laws. Buyer agrees that all Terms and Conditions of Sale on the reverse side of this Credit Application, as the same may be amended from time to time, shall apply to all sales and extensions of credit made to Buyer by Seller.
                </small>
                <p>
                    <small>
                        Buyer agrees that all Terms and Conditions of Sale on the Page 2 of this Credit Application, as the same may be amended from time to time, shall apply to all sales and extensions of credit made to Buyer by Seller.
                    </small>                
                </p>                                                
            </div>
            <div style={{ backgroundColor: 'lightgrey' }}>
                <center><strong>TERMS AND CONDITIONS OF SALE</strong> (“Terms”)</center>                
            </div>
            <div className='small'>
                <ol>
                    <li>
                        All references in this document to “Seller” shall include 57 Concrete LLC and / or any parent, subsidiary or affiliate of 57 Concrete LLC (including any division of the foregoing) whether or not performing any or all of the scope hereunder or specifically identified herein. All references to “Buyer” shall include all parent(s), subsidiaries and affiliates of the entity placing the order. Buyer and Seller may be referred to individually as a “Party” and collectively as “Parties”.
                    </li>
                    <li>
                        All sales to Buyer are subject to these Terms, which shall prevail over any inconsistent terms of Buyer’s purchase order or other documents. Additional or different terms and conditions in any way altering or modifying these Terms are expressly objected to and shall not be binding upon Seller unless specifically accepted in writing by Seller’s authorized representative. No modification or alteration of these Terms shall result by Seller’s shipment of goods following receipt of Buyer’s purchase order, or other documents containing additional, conflicting or inconsistent terms. There are no terms, conditions, understandings, or agreements other than those stated herein, and all prior proposals and negotiations are merged herein. These Terms are binding on the Parties, their successors, and permitted assigns.
                    </li>
                    <li>
                        Prices in Seller quotes are subject to change without notice, and all such prices expire and become invalid if not accepted within 10 calendar days from the date of issue, unless otherwise noted by Seller in writing. Price extensions if made must be in writing only. All prices and other terms provided to Buyer shall be kept confidential except to the extent a Party is required by law to disclose the same.
                    </li>
                    <li>
                        Seller shall not be liable for delay or default in delivery resulting from any cause beyond Seller’s reasonable control, including, but not limited to, governmental action, strikes or other labor troubles, fire, damage or destruction of goods, wars (declared or undeclared), acts of terrorism, manufacturers’ shortages, availability or timeliness of transportation, materials, fuels, or supplies, and acts of God (each a “Force Majeure Event”). Upon the occurrence of a Force Majeure Event: (a) the time for Seller’s performance shall be extended reasonably and the Parties shall adjust all affected dates accordingly; (b) the purchase price shall be adjusted for any increased costs to Seller resulting from such Force Majeure Event; and (c) Buyer shall not be entitled to any other remedy.
                    </li>
                    <li>
                        Buyer shall indemnify, defend, and hold Seller its officers, directors, employees and agents harmless from any and all costs (including attorneys’ and accountants’ fees and expenses), liabilities and damages resulting from or related to any third party (including Buyer’s employees) claim, complaint and/or judgment arising from Buyer’s use of any goods furnished hereunder, as well as any negligent, intentional, or tortious act or omission of Buyer or any material breach by Buyer of these Terms.
                    </li>
                    <li>
                        When materials are delivered to Buyer, Buyer shall sign each individual delivery ticket to ensure proper accounting at the time of invoicing. Title and risk of loss shall pass to Buyer at the applicable delivery location. All claims as to which Seller has the risk of loss shall be waived unless Buyer, within 2 calendar days after receipt of the material, gives Seller written notice fully describing the alleged claim.
                    </li>
                    <li>
                        Any change in product specifications, quantities, destinations, shipping schedules, or any other aspect of the scope of goods must be agreed to in writing by Seller, and may result in a price and delivery adjustment by Seller. No credit for goods returned by Buyer shall be given without Seller’s written authorization.
                    </li>
                    <li>
                        Unless otherwise agreed in writing, payment terms are net 30 days from delivery, payable in United States of America (“U.S.”) dollars. Notwithstanding the foregoing, all orders are subject to Seller’s continuing approval of Buyer’s credit. If Buyer’s credit is not approved or becomes unsatisfactory to Seller then Seller, in its sole discretion, may suspend or cancel performance, or require different payment terms, including but not limited to cash on delivery or in advance of shipment. Payments due hereunder shall be made in the form of cash, check, wire transfer or money order, or other tender approved in writing by Seller. Seller may, in its sole discretion, apply Buyer’s payment against any open charges. Past due accounts bear interest at the lesser of 1.5% per month or the maximum rate permitted by applicable law, continuing after Seller obtains judgment against Buyer. Seller may exercise setoff or recoupment to apply to or satisfy Buyer’s outstanding debt. Buyer shall have no right of setoff hereunder, the same being expressly waived hereby. Seller expressly reserves its right to file liens if payment is not received for its materials and expressly disclaims any waiver of lien rights language which may be contained in any future agreements between the Parties hereto. Seller reserves all rights to invoice and be paid for materials provided to Buyer and any terms contained in any of Buyer’s purchase orders or other documents that purport to limit in any way the time or manner within which Seller may invoice are hereby waived by Buyer.
                    </li>
                    <li>
                        Buyer shall pay Seller all costs and expenses of collection, suit, or other legal action brought as a result of the commercial relationship between them, including, but not limited to, all actual attorneys’ and paralegals’ fees, and collection costs, incurred pre-suit, through trial, on appeal, and in any administrative or bankruptcy proceedings. Any cause of action that Seller has against Buyer may be assigned without Buyer’s consent to 57 Concrete LLC or to any affiliate, parent or subsidiary of 57 Concrete LLC
                    </li>
                    <li>
                        This Agreement, Buyer’s account, and the business relationship between Buyer and Seller shall be governed by and construed in accordance with the laws of the state where the applicable project is located without regard to conflicts of laws rules, and specifically excluding the UN Convention on Contracts for the International Sale of Goods. The Parties agree that any legal action arising under or related to this Agreement may be brought in the applicable federal or state court where the project is located, and any right to object to such venue or to assert the inconvenience of such forum is hereby waived.
                    </li>
                    <li>
                        If Buyer fails to comply with these Terms, Seller may terminate or restrict any order immediately upon notice to Buyer. Buyer certifies that it is solvent and that it will advise Seller immediately if it becomes insolvent. Buyer agrees to send Seller written notice of any changes in the form of ownership of Buyer’s business within 5 days of such changes. Buyer and Seller are the only intended beneficiaries of this document, and there are no third party beneficiaries.
                    </li>
                    <li>
                        The invalidity or unenforceability of all or part of these Terms will not affect the validity or enforceability of the other terms. The parties agree to replace any void or unenforceable term with a new term that achieves substantially the same practical and economic effect and is valid and enforceable.
                    </li>
                    <li>
                        The following provisions shall survive termination, cancellation and completed performance of this Agreement as long as necessary to allow the aggrieved party to fully enforce such clauses: 5, 6, 9, 10, 11 and 12.
                    </li>
                </ol>            
            </div>
            {
                this.state.businessType !== 'CORPORATION' &&
                this.renderGuarnator()

            }            
            <div className='my-2'>
                <label className='text-muted'>Signature below:</label>
                <br />
                <SignaturePad 
                    canvasProps={{width: 400, height: 200}}
                    backgroundColor='#f3f5f6'                    
                    ref={(ref) => { this.sigPad = ref }}
                />
            </div>
            <div>
                {/* <button onClick={this.clearPad} className='btn btn-secondary'>
                    Clear Signature
                </button>
                <button onClick={this.trim}>
                    save signture
                </button> */}
            </div>            
            <div className="my-2">
                <input  name="accept"
                        type="checkbox"
                        checked={this.state.acceptConditions}
                        onChange={this.onAcceptConditions}
                />
                <label>&nbsp;Accept terms and conditions in order to proceed.</label>
            </div> 
            <div className="my-2">
                {
                    this.state.acceptConditions && <input type='submit' value='Save' className='btn btn-success'/>
                }                
            </div>    
        </form>
    )

    render() {
        const { trimmedDataURL } = this.state
        return (
            <Layout
                title='Credit Application Form'
                description='Fill in the form'
                className="container col-md-8 offset-md-2"
            >   
                {this.CreditForm()}
                {trimmedDataURL
                ? <img 
                src={trimmedDataURL} 
                alt='signature'
                />
                : null}
        
            </Layout>
        )
    }
}