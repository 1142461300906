const clientIdFilter = (clientId, jobsList) => {
    if (clientId === "") {
        return jobsList
    } else {
        const filteredJobs = jobsList.filter((job) => {
            let number = job.customer.customerNumber.toString()
            let name = job.customer.name.toString()
            return number === clientId || name === clientId
        })
        return filteredJobs
    }
}

module.exports = {
    clientIdFilter
}