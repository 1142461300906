import {
    ADD_AGGREGATE,
    GET_AGGREGATES,
    GET_AGGREGATE,
    AGGREGATE_ERROR,
    UPDATE_AGGREGATE
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    aggregate: null
}

export default function aggregateReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_AGGREGATE:
            return {
                ...state,
                aggregate: payload,
                loading: false
            }
        case GET_AGGREGATE:
            return {
                ...state,
                aggregate: payload,
                loading: false
            }
        case GET_AGGREGATES:
            return {
                ...state,
                aggregates: payload,
                loading: false
            }
        case UPDATE_AGGREGATE:
            return {
                ...state,
                aggregate: payload,
                loading: false
            }
        case AGGREGATE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}
