import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../utils/Api'

export default class SellerPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: true,
            isSearchable: true,
            sellers: []
        }
    }

    getSellers = async () => {
        const url = api + '/userlist/Seller'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            this.setState({
                sellers: data
            })
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getSellers()
    }

    render() {
        const options = this.state.sellers.map((seller) => {
            return {
                value: seller._id,
                label: seller.name
            }
        })

        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Seller...'
                    value={this.state.value}
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    } 
}


