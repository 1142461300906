import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import './styles.css'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const ReportTrips = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [startDay, setStartDay] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [endDay, setEndDay] = useState(new Date());
    const [dateChange, setDateChange] = useState(true)
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(false)
    const [tripTable, setTripTable] = useState([])
    const [totalTripTable, setTotalTripTable] = useState([])
    const [rows, setRows] = useState([])
    const [headers, setHeaders] = useState([])

    useEffect(() => {
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        if (endDate < startDate) {
            setEndDate(startDate)
            fechaFin = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        }

        if (dateChange) {
            setLoading(true)
            getLoads(fechaIni, fechaFin)
        }

    }, [startDate, endDate])

    useEffect(() => {
    }, [update])

    const columna = [
        { key: 'driver', name: 'Driver', resizable: true },
        { key: 'monday', name: 'Monday' },
        { key: 'tuesday', name: 'Tuesday' },
        { key: 'wednesday', name: 'Wednesday' },
        { key: 'thursday', name: 'Thursday' },
        { key: 'friday', name: 'Friday' },
        { key: 'saturday', name: 'Saturday' },
    ]

    const getLoads = async (startDate, endDate) => {
        const firstDay = new Date(getFirstDayOfWeek(startDate));
        firstDay.setDate(firstDay.getDate());

        const lastDay = new Date(getFirstDayOfWeek(endDate));
        lastDay.setDate(lastDay.getDate() + 6);

        const url = api + `/loadgroupdate?startDate=${firstDay}&endDate=${lastDay}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            mapData(data)
            setUpdate(!update)
            setLoading(false)
            setCSV(data)
            // const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const setCSV = (data) => {
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
        const headers = dateRange(fechaIni, fechaFin)
        let driversArr = []
        let newDriversArr = []
        setHeaders(headers)

        data.map((dato) => {
            const strDate = new Date(`${dato._id.month}-${dato._id.day}-${dato._id.year}`)
            let driverObj = {
                driver: dato._id.driver,
                date: strDate,
                loads: dato.count,
                yards: dato.yards
            }
            driversArr.push(driverObj)
        })

        const result = driversArr.reduce(function (r, a) {
            r[a.driver] = r[a.driver] || [];
            r[a.driver].push(a);
            return r;
        }, Object.create(null));

        // console.log(result);
        const resArr = Object.entries(result);


        resArr.map((driver) => {
            for (let i = 0; i < headers.length; i++) {
                let tripFound = driver[1].find(trip => findTrip(trip, new Date(headers[i])));
                if (typeof tripFound !== "undefined") {
                    let driverObj = {
                        name: tripFound.driver,
                        date: tripFound.date.toLocaleDateString(),
                        loads: tripFound.loads,
                        yards: tripFound.yards
                    }
                    newDriversArr.push(driverObj)
                } else {

                }
            }
        });

        setRows(newDriversArr)

        // console.log(headers);
        // console.log(newDriversArr);
    }

    const weekNum = (today) => {
        var currentDate = new Date(today)
        var startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));

        var weekNumber = Math.ceil(days / 7);

        return weekNumber;
    }

    const getFirstDayOfWeek = (d) => {
        const date = new Date(d);
        const day = date.getDay();
        const diff = date.getDate() - day;

        return new Date(date.setDate(diff));
    }

    const getWeeks = () => {
        let weekArr = []

        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        const begWk = weekNum(fechaIni)
        const endWk = weekNum(fechaFin)

        const firstDay = new Date(getFirstDayOfWeek(fechaIni));
        firstDay.setDate(firstDay.getDate() + 1);
        setStartDay(firstDay)

        const lastDay = new Date(getFirstDayOfWeek(fechaFin));
        lastDay.setDate(lastDay.getDate() + 6);
        setEndDay(lastDay)

        for (let i = begWk; i <= endWk; i++) {
            let weekObj = {
                week: i,
                drivers: []
            }
            weekArr.push(weekObj)
        }

        return weekArr

    }

    const dateRange = (startDate, endDate, steps = 1) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    }


    // get only required attributes for report renderer
    const mapData = data => {
        const weekArr = getWeeks()

        data.map((dato) => {
            const strDate = new Date(`${dato._id.month}-${dato._id.day}-${dato._id.year}`)
            const fullDate = weekNum(strDate)
            for (let i = 0; i < weekArr.length; i++) {
                const weekN = weekArr[i].week;
                if (weekN === fullDate) {
                    let driverObj = {
                        driver: dato._id.driver,
                        date: strDate,
                        loads: dato.count,
                        yards: dato.yards
                    }
                    weekArr[i].drivers.push(driverObj)
                }
            }
        })

        const result = weekArr.map((week) => {
            return week.drivers.reduce(function (r, a) {
                r[a.driver] = r[a.driver] || [];
                r[a.driver].push(a);
                return r;
            }, Object.create(null));
        })

        const totalTrips = data.reduce(function (r, a) {
            r[a._id.driver] = r[a._id.driver] || [];
            const strDate = new Date(`${a._id.month}-${a._id.day}-${a._id.year}`)
            r[a._id.driver].push({ driver: a._id.driver, date: strDate, loads: a.count, yards: a.yards });
            return r;
        }, Object.create(null));

        const splitKeyValue = obj => {
            const keys = Object.keys(obj);
            const res = [];
            for (let i = 0; i < keys.length; i++) {
                res.push({
                    'driver': keys[i],
                    'trips': obj[keys[i]]
                });
            };
            return res;
        };

        setTripTable(result)
        setTotalTripTable(splitKeyValue(totalTrips))
    }

    const DrawDates = (index) => {
        let stDate = new Date()
        if (index.index === 1) {
            stDate = new Date(startDay);
        } else {
            stDate = new Date(startDay);
            stDate.setUTCDate(stDate.getUTCDate() + (7 * (index.index - 1)));
        }
        let currentDate = new Date(stDate)
        currentDate.setUTCDate(currentDate.getUTCDate() + 5);
        const arr = dateRange(stDate, currentDate)

        const startDateFormat = new Date(startDate);
        const endDateFormat = new Date(endDate);
        return arr.map((dato, i) => {
            if (
                (dato > startDateFormat && dato < endDateFormat)
                || (dato.toLocaleDateString() === startDateFormat.toLocaleDateString())
                || (dato.toLocaleDateString() === endDateFormat.toLocaleDateString())) {
                return <td key={i} className='sticky-headers' style={{ color: "green" }}>
                    {dato.toLocaleDateString()}
                </td>
            } else {
                return <td key={i} className='sticky-headers'>
                    {dato.toLocaleDateString()}
                </td>
            }

        })

    }
    const findTrip = (trip, date) => {
        if (trip.date.getDate() === date.getDate()) {
            return trip
        } else {
            return null
        }
    }

    const DrawTrips = (props) => {
        let stDate = new Date()
        if (props.index === 1) {
            stDate = new Date(startDay);
        } else {
            stDate = new Date(startDay);
            stDate.setUTCDate(stDate.getUTCDate() + (7 * (props.index - 1)));
        }
        let currentDate = new Date(stDate)
        currentDate.setUTCDate(currentDate.getUTCDate() + 5);
        const rangeDate = dateRange(stDate, currentDate)

        let htmlObj = ``
        let sum = 0

        const weekArr = Object.entries(props.week);
        //console.log(weekArr);

        return weekArr.map(([driver, array]) => {
            htmlObj = `<tr> <td>${driver}</td>`
            for (let i = 0; i < rangeDate.length; i++) {
                let tripFound = array.find(trip => findTrip(trip, new Date(rangeDate[i])));
                if (typeof tripFound !== "undefined") {
                    ////console.log(tripFound);
                    sum += tripFound.loads
                    htmlObj = htmlObj + `<td  style="text-align:left">${tripFound.loads}</td>`
                } else {
                    htmlObj = htmlObj + `<td style="background-color:red; text-align:left" >0</td>`
                }
            }
            htmlObj = htmlObj + `<td style="text-align:right; font-weight:bold">${sum}</td>`
            htmlObj = htmlObj + `</tr>`
            sum = 0
            return ReactHtmlParser(htmlObj)
        });
    }

    const downloadCSVFile = (csv_data) => {

        // Create CSV file object and feed our
        // csv_data into it
        const CSVFile = csv_data

        // Create to temporary link to initiate
        // download process
        var temp_link = document.createElement('a');

        // Download csv file
        temp_link.download = "57concrete-trips-report.csv";
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;

        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);

        // Automatically click the link to trigger download
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    const exportCSV = () => {
        let csv = [];
        const rows = document.querySelectorAll("table tr");
        for (const row of rows.values()) {
            const cells = row.querySelectorAll("td, th");
            const rowText = Array.from(cells).map(cell => cell.innerText);
            csv.push(rowText.join(','));
        }
        const csvFile = new Blob([csv.join('\n')], { type: "text/csv;charset=utf-8;" });
        downloadCSVFile(csvFile);
    }



    const DrawTables = () => {
        let aux = 0
        return tripTable.map((week) => {
            aux++
            if (Object.keys(week).length !== 0) {
                return <div style={{ fontSize: '13px' }}>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='sticky-headers'>Driver</th>
                                <th>Monday</th>
                                <th>Tuesday</th>
                                <th>Wednesday</th>
                                <th>Thursday</th>
                                <th>Friday</th>
                                <th>Saturday</th>
                                <th className='sticky-headers'>TOTAL</th>
                            </tr>
                            <tr>
                                <th></th>
                                <DrawDates index={aux} />
                            </tr>
                        </thead>
                        <tbody>
                            <DrawTrips week={week} index={aux} />
                        </tbody>
                    </table>
                </div >
            } else {
                return <div><p>NO NEW TRIPS</p></div>
            }
        })
    }

    const DrawTotal = () => {
        const rangeDate = dateRange(startDate, endDate)
        let sum = 0
        const newTotal = []

        totalTripTable.map((driver) => {
            for (let i = 0; i < rangeDate.length; i++) {
                let tripFound = driver.trips.find(trip => findTrip(trip, new Date(rangeDate[i])));
                if (typeof tripFound !== "undefined") {
                    ////console.log(tripFound);
                    sum += tripFound.loads
                } else {
                    sum += 0
                }
            }
            let obj = {
                name: driver.driver,
                total: sum
            }
            newTotal.push(obj)

            sum = 0
        });

        return newTotal.map((driver) => {
            if (Object.keys(driver).length !== 0) {
                return <tr>
                    <td style={{ textAlign: "left" }}>
                        {driver.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {driver.total}
                    </td>
                </tr>
            } else {
                return <div><p>NO NEW TRIPS</p></div>
            }
        })
    }


    return (
        <Layout
            title='Trips Report'
            description='Trips by Driver'
        >
            <div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className='text-muted'>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>End Date</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                </div>
            </div>
            {
                loading ? <Spinner /> :
                    <div>
                        <br />
                        <button type="button" class="btn btn-primary" onClick={exportCSV}>Download report</button>
                        <br />
                        <div className='row justify-content-center' style={{ fontSize: '13px' }}>
                            <div class="col-auto">
                                <table className='table table-responsive'>
                                    <thead>
                                        <tr>
                                            <th>Driver</th>
                                            <th style={{ textAlign: "center" }}>TOTAL in Date Range</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th style={{ textAlign: "center" }}>
                                                {startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString()}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <DrawTotal />
                                    </tbody>
                                </table>
                            </div >
                        </div >
                        <br />
                        <DrawTables />
                        <br />
                    </div>
            }
        </Layout>
    )
}
export default ReportTrips