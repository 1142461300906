import React, { useState, useEffect, useRef } from 'react'
import { api } from '../utils/Api'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { dateFormat } from '../utils/Convert'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { set } from 'mongoose'

const OrderListDetail = (props) => {

    const[orderSummaryDetail, setOrderSummaryDetail] = useState([])
    const[fileName, setFileName] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getOrderSummaryDetail()
    }, [])

   const getOrderSummaryDetail = async() => {
        const url = api + '/ordersummary/'+ props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const {data} = await axios.get(url)
            //console.log(data)
            try {
                const {data} = await axios.get(url)
                const lista = data.map(file => {
                    return {
                        fileName: file.fileName,
                        order: file.order,
                        customerNumber: file.customerNumber,
                        deliveryAddress: file.deliveryAddress,
                        psi: file.psi,
                        firstOJT: file.firstOJT,
                        firstLoad: file.firstLoad,
                        sp: file.sp,
                        trv: file.trv,
                        unit: file.unit,
                        ordered: file.ordered,
                        shipped: file.shipped,
                        loadSize: file.loadSize
                    }
                })
                setOrderSummaryDetail(lista)
                setFileName(lista[0].fileName)
                setLoading(false)
            } catch (err) {
                console.error(err)
                return null
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const DrawList = () => {
        console.log(orderSummaryDetail)
        return orderSummaryDetail.map((orderSummaryDetail, i) => {
            return <tr key={i}>
                <td>{orderSummaryDetail.order}</td>
                <td>{orderSummaryDetail.customerNumber}</td>
                <td>{orderSummaryDetail.deliveryAddress}</td>
                <td>{orderSummaryDetail.psi}</td>
                <td>{orderSummaryDetail.firstOJT}</td>
                <td>{orderSummaryDetail.firstLoad}</td>
                <td>{orderSummaryDetail.sp}</td>
                <td>{orderSummaryDetail.trv}</td>
                <td>{orderSummaryDetail.unit}</td>
                <td>{orderSummaryDetail.ordered}</td>
                <td>{orderSummaryDetail.shipped}</td>
                <td>{orderSummaryDetail.loadSize}</td>
            </tr>
        })
    }
    
    const FileTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Order</th>
                        <th>Customer Number</th>
                        <th>Delivery Address</th>
                        <th>PSI</th>
                        <th>First OJT</th>
                        <th>First Load</th>
                        <th>SP </th>
                        <th>TRV</th>
                        <th>Unit</th>
                        <th>Ordered</th>
                        <th>Shipped</th>
                        <th>Load Size</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList/>
                </tbody>
            </table>
        </div>
    )   

    return (
        <Layout
            title={"Orders in: " + fileName}
            description='Orders in file'
            className='container col-md-8 offset-md-2'
        >
            {
                loading ? <Spinner /> : <FileTable />
            }
        </Layout>
    )
}
 
export default OrderListDetail