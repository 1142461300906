import React, { useState, useEffect } from 'react'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import CustomerTypePicker from '../customertype/CustomerTypePicker'
import QuadrantPicker from '../quadrant/QuadrantPicker'
import { api } from '../utils/Api'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

const ClientModal = ({ parentCallback }) => {
    // Valores del objeto a crear
    const initialState = {
        name: '',
        address: '',
        customerType: '',
        contactName: '',
        contactPhone: '',
        accountsPayableEmail: '',
        paymentType: 'PrePay',
        quadrantType: '',
        customerNumber: ''
    }

    // use state para accesar los valores de los componentes
    const [formData, setFormData] = useState(initialState)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [messageModal, setMessageModal] = useState(false)
    const [replicated, setReplicated,] = useState(false)

    // destructuring client object
    const {
        name,
        address,
        customerType,
        contactName,
        contactPhone,
        accountsPayableEmail,
        paymentType,
        quadrantType,
        customerNumber
    } = formData

    // funcion que asigna los valores del los componentes, por ejemplo <input /> al objeto
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    useEffect(() => {
        getCustomerNumber()
        getCustomerByNumber()
    }, [])

    // método para guardar
    const onNewClientNew = e => {
        // obligatorio para que la pantalla no haga "parpadeo"
        // e.preventDefault()    
        
        validateCustomerNumber()

        if (formData.accountsPayableEmail &&
            formData.address &&
            formData.contactName &&
            formData.contactPhone &&
            formData.customerNumber &&
            formData.customerType &&
            formData.name &&
            formData.paymentType &&
            formData.quadrantType) {
            createNewClient()
        } else {
            let missingFields = []
            if (!formData.accountsPayableEmail) missingFields.push('Email')
            if (!formData.address) missingFields.push('Address')
            if (!formData.contactName) missingFields.push('Contact Name')
            if (!formData.contactPhone) missingFields.push('Contact Phone')
            if (!formData.customerNumber) missingFields.push('Customer Number')
            if (!formData.customerType) missingFields.push('Customer Type')
            if (!formData.name) missingFields.push('Customer Name')
            if (!formData.paymentType) missingFields.push('Payment Type')
            if (!formData.quadrantType) missingFields.push('Quadrant Type')

            let customeMessage = ''
            for (let i = 0; i < missingFields.length; i++) {
                if (i !== missingFields.length - 1) {
                    customeMessage += missingFields[i] + ', '
                } else {
                    customeMessage += missingFields[i]
                }
            }
            setMessageModal(`Missing fields: ${customeMessage}`)
            toggleErrorModal()
        }
    }

    //Toggle error modal
    const toggleErrorModal = () => {
        setShowErrorModal(!showErrorModal)
    }

    // payment type picker
    const onChangePaymentType = selectedPayment => {
        console.log(selectedPayment)
        if (selectedPayment) {
            setFormData({ ...formData, paymentType: selectedPayment.label })
        }
    }

    // customer type select
    const onChangeCustomerType = selectedCustomerType => {
        if (selectedCustomerType) {
            setFormData({ ...formData, customerType: selectedCustomerType.label })
        }
    }

    // quadrant type select
    const onChangeQuadrantType = selectedQuadrantType => {
        if (selectedQuadrantType) {
            setFormData({ ...formData, quadrantType: selectedQuadrantType.value })
        }
    }

    const getCustomerNumber = async () => {
        const url = api + '/customernumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setFormData({ ...formData, customerNumber: 'A' + data.customerNumber.toString() })
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getCustomerByNumber = async () => {
        const url = api + `/clientnumber/${formData.customerNumber}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setReplicated(true)
            return true

        } catch (err) {
            console.error(err.message)
            setReplicated(false)
            return false
        }
    }

    const validateCustomerNumber = () => {
        console.log(replicated);
        if (replicated) {
            console.log("Customer Number is replicated!")
            getCustomerNumber()
            console.log("Customer Number is changed!")
        } else {
            console.log("Customer Number has been validated!")
        }
    }

    const createNewClient = async () => {
        const url = api + '/customer'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.post(url, formData)
            const toSend = {
                name,
                customerNumber: data.customerNumber,
                _id: data._id
            }
            parentCallback(toSend)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    return (
        <form className='form'>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Customer Name</label>
                    <input
                        onChange={onChange}
                        type="text"
                        name='name'
                        className="form-control"
                        value={name}
                        placeholder="Customer name"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Name</label>
                    <input
                        type="text"
                        name='contactName'
                        className='form-control'
                        onChange={onChange}
                        value={contactName}
                        placeholder='Contact Name'
                    />
                </div>
                {
                    <div className="form-group">
                        <label className="text-muted">Customer Type:</label>
                        <CustomerTypePicker action={onChangeCustomerType} placeholder={'Customer Type...'}/>
                    </div>
                }
                {
                    <div className="form-group">
                        <label className="text-muted">Quadrant Type:</label>
                        <QuadrantPicker action={onChangeQuadrantType} />
                    </div>
                }
                <div className='col-sm-6'>
                    <label className='text-muted'>Contact Phone</label>
                    <input
                        type="text"
                        name='contactPhone'
                        className='form-control'
                        onChange={onChange}
                        value={contactPhone}
                        placeholder='Contact Phone'
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Address</label>
                    <input
                        type="text"
                        name='address'
                        className='form-control'
                        onChange={onChange}
                        value={address}
                        placeholder='Customer Address'
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Email</label>
                    <input
                        type="text"
                        name='accountsPayableEmail'
                        className='form-control'
                        onChange={onChange}
                        value={accountsPayableEmail}
                        placeholder='Contact Email'
                    />
                </div>
                {
                    //localStorage.getItem('role') === 'Finance' &&
                    <div className="form-group">
                        <label className="text-muted">Payment Type:</label>
                        <PaymentTypePicker action={onChangePaymentType} defaultValue={'PrePay'}/>
                    </div>
                }
            </div>
            <br />
            <div className="form-group row">
                <div className="col-6">
                    <button className='btn btn-primary' onClick={onNewClientNew}> Save </button>
                </div>
            </div>
            <Modal
                show={showErrorModal}
                animation={false}
                onHide={toggleErrorModal}
                size='lg'
                centered
                dialogClassName='modal-width'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Missing fields to fill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{messageModal}</p>
                </Modal.Body>
            </Modal>
        </form>
    )
}

export default ClientModal