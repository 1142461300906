import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { toCurrency, toThousands } from '../utils/Convert'
import JobSummaryItem from '../job/JobSummaryItem'
import InvoiceSummaryItem from '../invoice/InvoiceSummaryItem'
import DatePicker from 'react-datepicker'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import ClientPayments from './ClientPayments'
import { Link } from 'react-router-dom'

const ClientSummary = ({ match }) => {
    const clientID = match.params.id
    const [customerName, setCustomerName] = useState('')
    const [address, setAddress] = useState('')
    const [credit, setCredit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [jobs, setJobs] = useState([])
    const [invoices, setInvoices] = useState([])
    const [jobNumber, setJobNumber] = useState(0)
    const [totalTarget, setTotalTarget] = useState(0)
    const [totalLoad, setTotalLoad] = useState(0)    
    const [sumSub, setSumSub] = useState(0)
    const [sumTax, setSumTax] = useState(0)
    const [sumTotal, setSumTotal] = useState(0)
    const [customerPhone, setCustomerPhone] = useState('Not defined')
    const [term, setTerm] = useState('')
    const [date, setDate] = useState(new Date())
    const [showModal, setShowModal] = useState(false)
    const [client, setClient] = useState({})

    useEffect(() => {
        getClientInfo()
        search()
    }, [])


    const getClientInfo = async () => {
        const url = api + `/customer/${clientID}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            setClient(data)
            setCustomerName(`${data.customerNumber} ${data.name}`)
            setAddress(`${data.address}`)
            
            if (data.contactPhone) {
                setCustomerPhone(data.contactPhone)
            }

            data.paymentType === 'Credit' ? setCredit(true) : setCredit(false)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getJobs = async (year, month, term) => {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const url = api + `/joblist/search?customerId=${clientID}&year=${year}&month=${month}&term=${term}`
            const { data } = await axios.get(url)
            const dataList = []
            let totLoad = 0
            let totTarget = 0
            data.forEach((job) => {
                const sellerName = job.seller ? job.seller.name : ''
                let date = job.scheduledDate
                date = `${new Date(job.scheduledDate).getMonth()+1}/${new Date(job.scheduledDate).getDate()}/${new Date(job.scheduledDate).getFullYear()}`
                dataList.push({ 
                    _id: job._id,
                    number: job.number, 
                    shippingAddress: job.shipAddress, 
                    seller: sellerName, 
                    loadYD3: job.loadYD3, 
                    targetYD3: job.targetYD3, 
                    jobStatus: job.jobStatus,
                    date: date })
                totLoad += job.loadYD3
                totTarget += (typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3) : job.targetYD3)
            })

            setTotalLoad(totLoad)
            setTotalTarget(totTarget)
            setJobs(dataList)
            setJobNumber(dataList.length)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getInvoice = async (year, month, term) => {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        
        try {
            const url = api + `/customerinvoice/search?customerId=${clientID}&year=${year}&month=${month}&term=${term}`
            const { data } = await axios.get(url)
            const dataList = []
            let sTax = 0
            let sSub = 0
            let sTotal = 0
            data.forEach((inv) => {
                let date = inv.invoiceDate
                date = date.substring(0, 10)
                date = `${new Date(inv.invoiceDate).getMonth()+1}/${new Date(inv.invoiceDate).getDate()}/${new Date(inv.invoiceDate).getFullYear()}`

                dataList.push({ 
                    psi: inv.psi, 
                    totalYards: inv.quantity, 
                    subtotal: toCurrency(inv.amount,2), 
                    tax: toCurrency(inv.taxAmount,2), 
                    total: toCurrency(inv.invoiceTotal,2), 
                    date: date,
                    invoiceNumber: inv.invoiceNumber,
                    _id: inv._id,
                    payments: inv.payments,
                    job: inv.job._id,
                    balance: toCurrency(inv.balance,2),
                })

                sTax += inv.taxAmount ? parseInt(inv.taxAmount) : 0
                sSub += inv.amount ? parseInt(inv.amount) : 0
                sTotal += inv.invoiceTotal ? parseInt(inv.invoiceTotal) : 0
            })

            setInvoices(dataList)
            setSumTax(sTax)
            setSumSub(sSub)
            setSumTotal(sTotal)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const search = () => {
        const year = date.getFullYear()
        const month = date.getMonth()+1
        getJobs(year, month, term)
        getInvoice(year, month, term)
    }

    const callInvoice = () => {
        const year = date.getFullYear()
        const month = date.getMonth()+1
        getInvoice(year, month, term)
    }

    const Head = () => (
        <div className="jumbotron jumbotron-fluid">
            <h3 className="display-4">{customerName}</h3>
            <p className="lead">{`${address}, ph: ${customerPhone} `}</p>
            <p className='lead'>
                <b>CREDIT LIMIT:</b> {client.creditAmount ? toCurrency(client.creditAmount,2) : toCurrency(0,2)},
                <b>CREDIT USED:</b> {client.creditAmount ? toCurrency(client.creditUsed,2) : toCurrency(0,2)},
                <b>BALANCE:</b> {client.creditAmount ? toCurrency(client.balance,2) : toCurrency(0,2)}
            </p>
            <table>
                <tr>
                    <td>
                        {
                            credit && <FontAwesomeIcon icon={faCheckSquare} />
                        }
                    </td>
                    <td>Credit</td>
                </tr>
            </table>
            
            <Link className='btn btn-success' to={`/generalpayment/${client._id}`}> General Payment </Link>
            
        </div>        
    )

    const DrawList = () => {
        return jobs.map((jb) => {
            return <JobSummaryItem key={jb._id} job={jb} />
        })
    }

    const DrawInvoice = () => {
        return invoices.map((fact) => {
            return <InvoiceSummaryItem key={fact._id} invoice={fact} getInvoice={callInvoice}/>
        })
    }

    const Grid = () => {
        return (
            <div>
            {
                jobNumber === 0 
                ? <p className="p-2">No Jobs</p>
                : <div>
                    <div className="d-flex justify-content-start">
                        <h4>
                            {`Jobs: ${jobNumber}, 
                            Load YD3: ${toThousands(totalLoad)}, 
                            Target YD3: ${toThousands(totalTarget)}` }
                        </h4>
                    </div> 
                    <JobTable />
                </div>
            }
            </div>
        )
    }

    const JobTable = () => (
        <div style={{ fontSize: '13px' }}>
             <table className='table'>
                 <thead>
                     <tr>
                         <th>Job Id</th>
                         <th>Date</th>
                         <th>Shipping Address</th>
                         <th>Seller</th>
                         <th>Load YD3</th>
                         <th>Target YD3</th>
                         <th>Status</th>   
                         <th>Action</th>                      
                     </tr>
                 </thead>
                 <tbody>
                     <DrawList />
                 </tbody>
            </table>
        </div>       
    )

    const InvoiceTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Number</th>
                        <th>Date</th>
                        <th>PSI</th>
                        <th>Total Yards</th>
                        <th>Subtotal</th>
                        <th>Tax</th>
                        <th>Total</th>
                        <th>Balance</th>
                        <th>Action</th>
                        <th>Payments</th>     
                    </tr>
                </thead>
                <tbody>
                    <DrawInvoice />
                </tbody>
            </table>
        </div>
    )

    const InvoiceGrid = () => {
        return (
            <div>
            {
                invoices.length === 0 
                ? <p className="p-2">No Invoices</p>
                :
                <div> 
                    <div className="d-flex justify-content-start">
                        <h4>                        
                            {`Invoices: ${invoices.length}, 
                            Subtotal: ${toCurrency(sumSub)}, 
                            Taxes: ${toCurrency(sumTax)}, 
                            Total: ${toCurrency(sumTotal)}` }                        
                        </h4>
                    </div> 
                    <InvoiceTable />
                </div>
            }
            </div>
        )
    }

    const SearchCustomer = () => (
        <div className="form-group row">
            <div className="col-sm-3">
                <input
                    type='text'
                    name='term'
                    size='40'
                    className="form-control"
                    placeholder="Search..."
                    defaultValue={term}
                    onBlur={(e) => setTerm(e.target.value)}
                />
            </div>
            <div className="col-sm-2">
                <DatePicker
                    selected={date}
                    showMonthYearPicker
                    dateFormat= 'yyyy-MM'
                    onChange={(date) => setDate(date)}
                    minDate={new Date('2024-01-01')}
                    maxDate={new Date()}
                /> 
            </div>
            <div className="col-sm-2">
                <button onClick={search} className='btn btn-primary w-100'>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                </button>
            </div>
        </div>
    )


    return (
        <Layout
            title='Client Summary'
            description=''
            className='container col-md-10'
        >
            {
                loading ? <Spinner /> 
                : 
                <div>
                    <Head />
                    <br />
                    <SearchCustomer/>
                    <br />
                    <Grid />
                    <br />
                    <InvoiceGrid />
                </div>
            }
        </Layout>
    )
}

export default ClientSummary
