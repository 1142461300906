import { Button, Col, Form, Input, InputNumber, Row, Select, notification, Typography, Space, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import {
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { CustomSpinner } from '../utils/CustomComponents'
const {Title} = Typography
const { RangePicker } = DatePicker


const optionsZone = [
    { value: 'lower valley', label: 'Lower Valley' },
    { value: 'mid valley', label: 'Mid Valley' },
    { value: 'upper valley', label: 'Upper Valley' }
]

const ProjectNew = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [loadingForm, setLoadingForm] = useState(true)
    const [optionsCustomer, setOptionsCustomer] = useState([])
    const [optionsPsi, setOptionsPsi] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getPsi()
        getCustomers()
    }, [])

    const getCustomers = async () => {
        const url = api + '/allcustomers'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let { data } = await axios.get(url)
            data = data.filter(customer => customer.creditHold !== true)
            let list = []
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.name
                })
            })   
            setOptionsCustomer(list)
            setLoading(false)
            setLoadingForm(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getPsi = async () => {
        const url = api + '/psi'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url) 
            let list = []
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.psi
                })
            })     
            setOptionsPsi(list)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onChangeCustomer = async (id) => {
        form.setFieldValue('address', 'Loading Address...')
        const url = api + '/customer/' + id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            form.setFieldValue('address', data.address || 'No address Assigned')
        } catch (err) {
            openNotification('error', 'Error', 'Error while trying to get customer address')
            console.error(err.message)
            return null
        }
    }

    const createProject = async (data) => {
        try {
            setLoading(true)
            
            let formData = {
                name: data.name,
                description: data.description,
                customer: data.customer,
                zone: data.zone,
                productPrice: data.productPriceList,
                createdBy: localStorage.getItem('userId'),
                updatedBy: localStorage.getItem('userId')
            }

            if(data.dates){
                formData.start = dayjs(data.dates[0]).toDate()
                formData.end = dayjs(data.dates[1]).toDate()
            }
        
            let url = api + '/project' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, formData)
            openNotification('success', 'Success', 'Project created')
            setTimeout(() => {
                setLoading(false)
                history.push('/projectlist')
            }, 1000);

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create project')
            console.error(err.message)
            return null
        }
    }
    
    const onChange = (value) => {
        const productPriceList = form.getFieldValue('productPriceList')
        let options = optionsPsi
        let index = optionsPsi.findIndex(obj => obj.value === value)
        options[index].disabled = true
        options.map((item) => {
            if(productPriceList.find(o => o.psi === item.value)){
                item.disabled = true
            }else{
                item.disabled = false
            }
        })
        setOptionsPsi(options)
    }

    const onRemoveItem = (removeFunction, name) => {
        let productPriceList = form.getFieldValue('productPriceList')
        if(productPriceList[name] === undefined){
            removeFunction(name)
        }else if(!productPriceList[name].psi){
            removeFunction(name)
        }else{
            let psi = productPriceList.at(name).psi
            let options = optionsPsi
            let index = optionsPsi.findIndex(obj => obj.value === psi)
            options[index].disabled = false
            setOptionsPsi(options)
            removeFunction(name)
        }
    }

    const onFinish = (values) => {
        createProject(values)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Description'
                    name='description'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill description'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Customer'
                    name='customer'
                    rules={[
                        {
                            required: true,
                            message: 'Please select customer'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsCustomer}
                        placeholder="Please select customer"
                        onChange={onChangeCustomer}
                        filterOption={filterOption}
                        showSearch
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Address'
                    name='address'
                    >
                        <Input size='large' disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Zone'
                    name='zone'
                    rules={[
                        {
                            required: true,
                            message: 'Please select zone'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsZone}
                        placeholder="Please select zone"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={3}>Products</Title>
                </Col>
                <Col span={24}>
                    <Form.List name="productPriceList">
                        {(fields, { add, remove }) => (
                            <>
                            {
                            fields.map(({ key, name, ...restField }) => (
                                <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                }}
                                align="baseline"
                                >
                                
                                <Form.Item
                                    {...restField}
                                    name={[name, 'psi']}
                                    label='PSI'
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Missing psi item',
                                    },
                                    ]}
                                >
                                    <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    size='large'
                                    placeholder="Please select psi"
                                    options={optionsPsi}
                                    onChange={e => onChange(e,name)}
                                    filterOption={filterOption}
                                    showSearch
                                    />
                                </Form.Item>
                                <Form.Item
                                {...restField}
                                name={[name, 'specialPrice']}
                                label='Contract Price'
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing contract price',
                                },
                                ]}
                                >
                                    <InputNumber 
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                                <Form.Item
                                {...restField}
                                name={[name, 'listPrice']}
                                label='Spot Price'
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing spot price',
                                },
                                ]}
                                >
                                    <InputNumber 
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => {onRemoveItem(remove,name)}} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add
                                </Button>
                            </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    name='dates'
                    label='Range Dates'
                    >
                        <RangePicker size='large' />
                    </Form.Item>
                </Col>
            </Row>
                
            <Button type="primary" htmlType="submit" loading={loading} size='large'>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Project'
        description='Create new Project'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm /> }
        </Layout>
    )
}

export default ProjectNew