import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Col, Form, Input, InputNumber, Row, Select, notification } from 'antd'
import { CustomSpinner } from '../utils/CustomComponents'
import { api } from '../utils/Api'
import axios from 'axios'
import { priorityOptions } from '../../constants/Inventory'
const { TextArea } = Input

const NewInventoryRequested = (props) => {
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(true)
    const [providerOptions, setProviderOptions] = useState([])
    const [inventoryOptions, setInventoryOptions] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getProviders()
        getInventory()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getInventory = async() => {
        const url = api + `/inventorylist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((inventory) => {
                list.push({
                    label: inventory.name,
                    value: inventory._id
                })
            })
            setInventoryOptions(list)
            setLoading(false)
            setLoadingButton(false)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining the inventory')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const getProviders = async() => {
        const url = api + `/providerlist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((provider) => {
                list.push({
                    label: `${provider.name} - ${provider.phone}`,
                    value: provider._id
                })
            })
            setProviderOptions(list)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining providers')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const createInventoryRequested = async(values) => {
        setLoadingButton(true)

        const url = api + `/inventoryrequested`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let newBody = {
                ...values,
                createdBy: localStorage.getItem('userId'),
            }

            await axios.post(url, newBody)
            openNotification('success', 'Success', 'Inventory Requested succesfully')
            setTimeout(() => {
                setLoadingButton(false)
                props.history.push('/inventoryrequestedlist')
            }, 1000);
            
        } catch (err) {
            setLoadingButton(false)
            openNotification('error', 'Error', 'Something went wrong while creating request')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        createInventoryRequested(values)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            layout='vertical'
            name='basicForm'
            initialValues={{
                quantity: 0,
                amount: 0,
            }}
            onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Inventory'
                        name='inventory'
                        rules={[
                            {
                                required: true,
                                message: 'Please select an inventory item'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select inventory`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={inventoryOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Provider'
                        name='provider'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a provider'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select provider`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={providerOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Quantity'
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'Please fill quantity'
                            }
                        ]}
                        >
                            <InputNumber 
                            size='large' 
                            style={{width: '100%'}} 
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Amount'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message: 'Please fill amount'
                            }
                        ]}
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Priority'
                        name='priority'
                        rules={[
                            {
                                required: true,
                                message: 'Please select priority'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select priority`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={priorityOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Notes'
                        name='notes'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={18} style={{marginTop: '20px'}}>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loadingButton}>
                            Request 
                        </Button>
                    </Col>
                </Row>
            </Form>
    }


    return (
        <Layout
        title='Request new inventory'
        description='Request Inventory'
        type='medium'
        >
            {contextHolder}
            {
                loading ? <CustomSpinner/> : <CustomForm/>
            } 
        </Layout>
    )
}

export default NewInventoryRequested