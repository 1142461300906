const quadrantTypeOptions = [
    { value: 'CC', label: 'Construction Companies' },
    { value: 'CEM', label: 'Cement / Particular' },
    { value: 'GC', label: 'General Contractors' },              
    { value: 'GOV', label: 'Government & DOT' },
    { value: 'BP', label: 'BePartner' },
]

//Labels for notifiable events

const notifiableOptions = [
    { value: 'newJob', label: 'New Job' },
    { value: 'newLoad', label: 'New Load' },
    { value: 'newInvoice', label: 'New Invoice' },
    { value: 'newEticket', label: 'New Eticket' }
]
// const notifiableOptions = [
//     { value: 'newJob-New Job', label: 'New Job' },
//     { value: 'newLoad-New Load', label: 'New Load' },
//     { value: 'newInvoice-New Invoice', label: 'New Invoice' },
//     { value: 'newEticket-New Eticket', label: 'New Eticket' }
// ]
const customerTypeOptions = [
    {
        "value": "Cement Company",
        "label": "Cement Company"
    },
    {
        "value": "Constructor",
        "label": "Constructor"
    },
    {
        "value": "General Contractor",
        "label": "General Contractor"
    },
    {
        "value": "Incall",
        "label": "Incall"
    },
    {
        "value": "Optimization",
        "label": "Optimization"
    },
    {
        "value": "Residential",
        "label": "Residential"
    },
    {
        "value": "Sub-Contractor",
        "label": "Sub-Contractor"
    }
]

const paymentTypeOptions = [
    {
        "value": "Credit",
        "label": "Credit"
    },
    {
        "value": "PrePay",
        "label": "PrePay"
    },
    {
        "value": "Partial",
        "label": "Partial"
    }
]

const paymentTermOptions = [
    {
        "value": "30",
        "label": "30"
    },
    {
        "value": "7",
        "label": "7"
    },
    {
        "value": "21",
        "label": "21"
    },
    {
        "value": "Prepayment",
        "label": "Prepayment"
    },
    {
        "value": "14",
        "label": "14"
    }
]

export {
    quadrantTypeOptions,
    notifiableOptions,
    customerTypeOptions,
    paymentTypeOptions,
    paymentTermOptions,
}