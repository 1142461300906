import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'

export default class ApplicationEmploymentPDF extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // showModalEmail: false,
            // showModalSms: false,
            pdf: '',
            loading: true
        }
    }

    /**
     * Get PDF application employment
     */
    getPDFApplicationEmployment  = async () => {
        const url = api + `/applicationpdf/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                pdf: data,
                loading: false
            })
        } catch (err) {
            console.error(err)
            return null
        }
    }

    /**
     * Open and close email modal
     * @param {Event} e event of the modal
     */
    toggleModalEmail = (e) => {
        this.setState({
            showModalEmail: !this.state.showModalEmail
        })
    }

    /**
     * Open and close sms modal
     * @param {Event} e event of the modal
     */
    toggleModalSms = (e) => {
        this.setState({
            showModalSms: !this.state.showModalSms
        })
    }

    
    componentDidMount() {
        this.getPDFApplicationEmployment()
    }

    render() {

        return (
            <Layout
                title='Employee Application PDF'
                description='Employee Application'
                className="container col-md-12"
            >
                {
                    this.state.loading ? <Spinner/> : <iframe src={this.state.pdf} width='95%' style={{height: '90vh'}} />
                }
                
            </Layout>
        )
    }
}