const validEmail = (email) => {

    //const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (!email) {
        return false
    }

    if (typeof email !== "string") {
        email = email.toString()
    }

    if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return true
    }
    else {
        return false
    }
}

module.exports = {
    validEmail
}