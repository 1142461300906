import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../utils/Api'


const ForemanPicker = ({ selectedOption, action }) => {

    const [selOption, setSelOption] = useState(selectedOption)
    const [options, setOptions] = useState([])

    useEffect(() => {
        getForeman()
    }, [])

    const getForeman = async () => {
        const url = api + `/foreman`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            const optionsAux = []

            data.forEach((foreman) => {

                let value = `${foreman._id},${foreman.firstName} ${foreman.lastName},${foreman.phone}`
                let aux = `${foreman.firstName} ${foreman.lastName}`
                let label = aux.toUpperCase()

                let obj = {
                    value: value,
                    label: label
                }

                if (foreman.active) {
                    optionsAux.push(obj)
                }
            })

            setOptions(optionsAux)

        } catch (err) {
            console.error(err)
            return null
        }
    }


    return (
        <div>
            {
                options.length > 0
                    ?
                    <Select
                        name="form-field-name"
                        placeholder='Choose foreman...'
                        value={options.filter((option) => {
                            const aux = selectedOption.toUpperCase()
                            return option.label === aux
                        })}
                        onChange={action}
                        isClearable={true}
                        isSearchable={true}
                        options={options}
                    />
                    :
                    <Select
                        name="form-field-name"
                        placeholder='Loading...'
                    />
            }
        </div>
    )
}

export default ForemanPicker
