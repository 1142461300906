import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Statistic } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import dayjs from 'dayjs'
const { Text } = Typography

const LogisticsReportMaterialPerPlant = () => { 
    
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [list, setList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Mine',
            dataIndex: 'mine',
            key: 'mine',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Destination Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Total (Tons)',
            dataIndex: 'count',
            key: 'count',
            render: (value) => {
                return <Statistic title="" value={value} precision={2} valueStyle={{fontSize: 14}} />
            }
        },
        {
            title: 'Total (Cost)',
            dataIndex: 'cost',
            key: 'cost',
            render: (value) => {
                return <Statistic title="" value={value} prefix={'$'} precision={2} valueStyle={{fontSize: 14}} />
            }
        },
    ]

    useEffect(() => {
        getLogisticsList()
    }, [startDate, endDate])
    
    const getLogisticsList = async() => {
        setLoading(true)

        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/logisticstrip/list/materialperplant?startDate=${start}&endDate=${end}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: index,
                    plant: item._id.plant.name,
                    count: item.count,
                    cost: item.count * 10,
                    material: item._id.material,
                    mine: item._id.mine,
                })
            })
            setList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            <CustomTable 
            columns={columns}
            data={list}
            isScroll={false}
            />
        </Flex>
    }

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const RenderDates = () => {
        return <CustomSearch 
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        onRangeChange={onRangeChange}
        />
    }

    return (
        <Layout
            title='Tons Per Material Per Plant'
            description='Logistics Report'
            type='medium'
        >
            <Flex vertical gap={'large'}>
                <RenderDates />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default LogisticsReportMaterialPerPlant