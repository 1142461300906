import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import JobItem from './JobItem'
import { api } from '../utils/Api'
import { Link } from 'react-router-dom'
import PlantPicker from '../plant/PlantPicker'
import Modal from 'react-bootstrap/Modal'
import { toThousands } from '../utils/Convert'
import SubplantPicker from '../plant/SubplantPicker'
import JobsSellerClassification from './JobSellerClassification'

const DAppointment = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [update, setUpdate] = useState(false)
    const [updateAll, setUpdateAll] = useState(false)
    const [jobs, SetJobs] = useState([])
    const [sumLoadYd3, setSumLoadY3] = useState(0)
    const [sumTarget, setSumTarget] = useState(0)
    const [sumBalance, setSumBalance] = useState(0)
    const [endDate, setEndDate] = useState(null)
    const [loading, setLoading] = useState(true)
    const [jobHold, setJobHold] = useState(localStorage.getItem('jobHold') === "true" ? true : false)
    const [waitingInspection, setWaitingInspection] = useState(false)
    const [plant, setPlant] = useState(localStorage.getItem('plant') || '61985b6a86825f39cc4aba38')
    const [plantName, setPlantName] = useState('Mission')
    const [showModal, setShowModal] = useState(false)
    const [subplant, setSubplant] = useState(localStorage.getItem('subplant') || 'All')
    const [scheduledTargetYD3, setScheduledTargetYD3] = useState(0)
    const [pouringTargetYD3, setPouringTargetYD3] = useState(0)
    const [pouringLoadYD3, setPouringLoadYD3] = useState(0)
    const [pouredLoadYD3, setPouredLoadYD3] = useState(0)
    const [readyTargetYD3, setReadyTargetYD3] = useState(0)


    useEffect(() => {
        setLoading(true)
        setSumLoadY3(0)
        const fecha = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        getJobs(fecha, plant, subplant)
    }, [startDate, waitingInspection, updateAll])

    useEffect(() => {
    }, [update])


    const loadUpdated = (newLoad) => {
        let newSum = sumLoadYd3 + newLoad
        const result = Math.round(newSum * 100) / 100
        setSumLoadY3(result)
        setUpdate(!update)
    }

    const getJobs = async (today, plant, subplant) => {
        let url = ''
        if(subplant === 'All'){
            url = api + `/jobdateplant?date=${today}&plant=${plant}`
        }else{
            url = api + `/jobdateplantsub?date=${today}&plant=${plant}&subplant=${subplant}`
        }
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            assignData(data) // Fill list with data
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // change plant
    const onChangePlant = selectedPlant => {
        if (selectedPlant) {
            setPlant(selectedPlant.value)
            setPlantName(selectedPlant.label)
            const fecha = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
            getJobs(fecha, selectedPlant.value, subplant)
        }
    }

    // change plant
    const onChangeSublant = selectedOption => {
        if (selectedOption) {
            setSubplant(selectedOption.label)
            const fecha = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
            getJobs(fecha, plant, selectedOption.label)
        }
    }

    // Assign API response to local array "jobs" & calculate totals
    const assignData = (data) => {

        if(localStorage.getItem('role') === 'Batch'){
            data = data.filter(job => job.subplant === localStorage.getItem('subplant'))
        }

        // suma de importe ($)
        const result = data.reduce((acc, elJob) => acc + elJob.loadYD3, 0)
        const totalLoad = Math.round(result * 100) / 100
        localStorage.setItem('loadSum', totalLoad)

        // suma de target
        var suma = 0
        var sumBalance = 0
        var sumWait = 0
        data.forEach(element => {
            let operandos = typeof element.targetYD3 === "string" ? element.targetYD3.split('+') : element.targetYD3
            if (element.jobStatus !== 'Quote' && element.jobStatus !== 'Waiting Inspection' && element.jobStatus !== 'Wait Inspection') {
                suma = suma + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
            }

            if (element.jobStatus === 'Waiting Inspection' || element.jobStatus === 'Wait Inspection') {
                sumWait = sumWait + (typeof element.targetYD3 === "string" ? parseFloat(operandos[0]) : operandos)
                
            }

            var balance = element.balance ? element.balance : 0
            sumBalance = sumBalance + parseFloat(balance)
        })
        localStorage.setItem('targetSum', suma)
        localStorage.setItem('sumBalance', sumBalance)
        localStorage.setItem('waitingInspection', sumWait)

        // organize total yards and load
        assignDataTarget(data)

        SetJobs(data)
        setSumLoadY3(totalLoad)
        setSumTarget(suma)
        setSumBalance(sumBalance)
        setLoading(false)
    }

    const assignDataTarget = (data) => {
        const scheduledData = data.filter(job => job.jobStatus === 'Scheduled')
        const scheduledYards = scheduledData.reduce((accum, current) => accum + current.targetYD3, 0)
        setScheduledTargetYD3(scheduledYards)

        const pouringData = data.filter(job => job.jobStatus === 'Active')
        const pouringYards = pouringData.reduce((accum, current) => accum + current.targetYD3, 0)
        const pouringLoads = pouringData.reduce((accum, current) => accum + current.loadYD3, 0)
        setPouringTargetYD3(pouringYards)
        setPouringLoadYD3(pouringLoads)

        const pouredData = data.filter(job => job.jobStatus === 'ok')
        const pouredLoads = pouredData.reduce((accum, current) => accum + current.loadYD3, 0)
        setPouredLoadYD3(pouredLoads)

        const readyData = data.filter(job => job.jobStatus === 'Ready to Load')
        const readyYards = readyData.reduce((accum, current) => accum + current.targetYD3, 0)
        setReadyTargetYD3(readyYards)
    }

    const waitingInspectionCallback = () => {
        setWaitingInspection(!waitingInspection)
    }

    // call component for table row drawing status !== done
    const losJobs = () => {
        if (jobs.length > 0) {
            return jobs
                .filter(jb => (jb.jobStatus !== 'Operations done (AR)') &&
                    (jb.jobStatus !== 'ok') &&
                    (jb.jobStatus !== 'Active') &&
                    (jb.jobStatus !== 'Confirm') &&
                    (jb.jobStatus !== 'Ready to Load') &&
                    (jb.jobStatus !== 'Waiting Inspection') &&
                    (jb.jobStatus !== 'Scheduled') &&
                    (jb.jobStatus !== 'New Order')
                )
                .map((currentJob, i) => {
                    return <JobItem pcb={loadUpdated} job={currentJob} key={i} action={setUpdateAll} wic={waitingInspectionCallback} />
                })
        } else {
            return (
                <tr>
                    <td colSpan="19" style={{ 'textAlign': 'center' }}>
                        No jobs for selected date
                    </td>
                </tr>
            )
        }
    }

    // call component for table row drawing status 
    const jobsForStatus = (status) => {
        if (jobs.length > 0) {
            return jobs
                .filter(jb => jb.jobStatus === status)
                .map((currentJob, i) => {
                    return <JobItem pcb={loadUpdated} job={currentJob} key={i} action={setUpdateAll} wic={waitingInspectionCallback}/>
                })
        }
    }

    // call component for table row drawing status Scheduled
    const jobsForStatus2 = (status) => {
        if (jobs.filter(jb => jb.jobStatus === status).length > 0) {
            return jobs
                .filter(jb => jb.jobStatus === status)
                .map((currentJob, i) => {
                    return <JobItem pcb={loadUpdated} job={currentJob} key={i} action={setUpdateAll} wic={waitingInspectionCallback}/>
                })
        } else {
            return (
                <tr>
                    <td colSpan="19" style={{ 'textAlign': 'center' }}>
                        No jobs for selected date
                    </td>
                </tr>
            )
        }
    }

    const renderLoading = () => {
        return <Spinner />
    }

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    const renderJobs = () => (
        <Fragment>
            <h3>
                {`Total YD: ${toThousands(localStorage.getItem('loadSum'))}, 
                    Total Target: ${toThousands(parseFloat(localStorage.getItem('targetSum')).toFixed(2))}, 
                    Total Balance: ${toThousands(parseFloat(localStorage.getItem('sumBalance')).toFixed(2))},
                    Waiting Inspection: ${toThousands(parseFloat(localStorage.getItem('waitingInspection')).toFixed(2))}`}
            </h3>
            <div className="form-group row">
                <div className="col-sm-3">
                    <label className="text-muted">Date:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(startDate) => setStartDate(startDate)}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                {
                    localStorage.getItem('role') !== 'Batch' &&
                    <div className='col-sm-3'>
                        <label className='text-muted'>Plant</label>
                        <PlantPicker action={onChangePlant} value={plant} />
                    </div>
                }
                {
                    localStorage.getItem('role') !== 'Batch' &&
                    <div className='col-sm-3'>
                        <label className='text-muted'>Subplant</label>
                        <SubplantPicker plant={plant} action={onChangeSublant} value={subplant} isRequired={false} isComplete={true} />
                    </div>
                }
            </div>
            <div className='py-2 px-2'>
                <Link to='/heatmap' className='btn btn-primary'>
                    Driver's Heatmap
                </Link>
                &nbsp;
                <button className='btn btn-success' onClick={handleShowModal}>Yards Per Seller</button>
            </div>
            <RenderScheduledJobs />
            <RenderPouringJobs />
            <RenderPouredJobs />
            <RenderRegularJobs />
        </Fragment>
    )

    const RenderScheduledJobs = () => {
        return <div
        style={{ fontSize: '13px' }}
    >
        <h3>Scheduled Jobs</h3>
        <h5>Total TargetYD3: {scheduledTargetYD3}</h5>
        <table className='table'>
            <thead>
                <tr>
                    <th>Confirm</th>
                    <th style={{ width: '10%' }}>Customer</th>
                    <th>JobID</th>
                    <th>Subplant</th>
                    <th>Time</th>
                    <th>Job Address</th>
                    <th>Load YD3</th>
                    <th>Target YD3</th>
                    <th>Dest. time</th>
                    <th style={{ width: '4%' }}>Mix Design</th>
                    <th>Slump</th>
                    <th>Fiber YD3</th>
                    <th>Pour/Type</th>
                    <th>Status</th>
                    <th>Seller</th>
                    <th>Scheduled Date</th>
                    {
                        jobHold &&
                        <th>Hold</th>
                    }
                    <th style={{ width: '6%' }}>Comments</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {jobsForStatus2('Scheduled')}                      
            </tbody>
        </table>
    </div>
    }
    const RenderPouringJobs = () => {
        return <div
        style={{ fontSize: '13px' }}
    >
        <h3>Pouring Jobs</h3>
        <h5>Total TargetYD3: {pouringTargetYD3}</h5>
        <h5>Total LoadYD3: {pouringLoadYD3}</h5>
        <table className='table'>
            <thead>
                <tr>
                    <th>Confirm</th>
                    <th style={{ width: '10%' }}>Customer</th>
                    <th>JobID</th>
                    <th>Subplant</th>
                    <th>Time</th>
                    <th>Job Address</th>
                    <th>Load YD3</th>
                    <th>Target YD3</th>
                    <th>Dest. time</th>
                    <th style={{ width: '4%' }}>Mix Design</th>
                    <th>Slump</th>
                    <th>Fiber YD3</th>
                    <th>Pour/Type</th>
                    <th>Status</th>
                    <th>Seller</th>
                    <th>Scheduled Date</th>
                    {
                        jobHold &&
                        <th>Hold</th>
                    }
                    <th style={{ width: '6%' }}>Comments</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {jobsForStatus2('Active')}                      
            </tbody>
        </table>
    </div>
    }
    const RenderPouredJobs = () => {
        return <div
        style={{ fontSize: '13px' }}
    >
        <h3>Poured Jobs</h3>
        <h5>Total LoadYD3: {pouredLoadYD3}</h5>
        <table className='table'>
            <thead>
                <tr>
                    <th>Confirm</th>
                    <th style={{ width: '10%' }}>Customer</th>
                    <th>JobID</th>
                    <th>Subplant</th>
                    <th>Time</th>
                    <th>Job Address</th>
                    <th>Load YD3</th>
                    <th>Target YD3</th>
                    <th>Dest. time</th>
                    <th style={{ width: '4%' }}>Mix Design</th>
                    <th>Slump</th>
                    <th>Fiber YD3</th>
                    <th>Pour/Type</th>
                    <th>Status</th>
                    <th>Seller</th>
                    <th>Scheduled Date</th>
                    {
                        jobHold &&
                        <th>Hold</th>
                    }
                    <th style={{ width: '6%' }}>Comments</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {jobsForStatus2('ok')}                      
            </tbody>
        </table>
    </div>
    }

    const RenderRegularJobs = () => {
        return <div
        style={{ fontSize: '13px' }}
    >
        <h3>Regular Jobs</h3>
        <h5>Total TargetYD3: {readyTargetYD3}</h5>
        <table className='table'>
            <thead>
                <tr>
                    <th>Confirm</th>
                    <th style={{ width: '10%' }}>Customer</th>
                    <th>JobID</th>
                    <th>Subplant</th>
                    <th>Time</th>
                    <th>Job Address</th>
                    <th>Load YD3</th>
                    <th>Target YD3</th>
                    <th>Dest. time</th>
                    <th style={{ width: '4%' }}>Mix Design</th>
                    <th>Slump</th>
                    <th>Fiber YD3</th>
                    <th>Pour/Type</th>
                    <th>Status</th>
                    <th>Seller</th>
                    <th>Scheduled Date</th>
                    {
                        jobHold &&
                        <th>Hold</th>
                    }
                    <th style={{ width: '6%' }}>Comments</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {jobsForStatus('Operations done (AR)')}
                {jobsForStatus('Ready to Load')}
                {jobsForStatus('Confirm')}
                {jobsForStatus('Waiting Inspection')}
                {jobsForStatus('New Order')}
                {losJobs()}                        
            </tbody>
        </table>
    </div>
    }

    return (
        <Layout
            title='Daily Appointment'
            description='Job list'
        >
            {
                loading ?
                    renderLoading()
                    : renderJobs()
            }
            <Modal show={showModal} animation={false} onHide={handleShowModal} dialogClassName='modal-position'>
                <Modal.Header closeButton>
                    <Modal.Title>Yards Per Seller</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JobsSellerClassification today={`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`} plant={plant}/>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default connect()(DAppointment)
