import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { api } from '../utils/Api'
import { toCurrency } from '../utils/Convert'

const GeneralPayment = ({clientId, selectedList, client, pcb}) => {
    const [customer, setCustomer] = useState({})
    const [selectedPayment, setSelectedPayment] = useState({})
    const [selected, setSelected] = useState(false)
    const [taxes, setTaxes] = useState({})

    useEffect(() => {
        getTax()
        getCustomer()
    }, [])

    const getCustomer = async () => {
        const url = api + '/customer/' + clientId
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setCustomer(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getTax = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const { data } = await axios.get(url)
            setTaxes({county: data.countyTax, state: data.stateTax, exempt: 0} )
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const onChangeValue = (e) =>{
        const filter = customer.payments.filter((item) => item._id === e.target.value)
        setSelectedPayment(filter[0])
        setSelected(true)
    }

    const generatePayment = () =>{
        const url = api + '/customerpaymentnumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` } 

        let generalPayments = {}
        let arrayInvoices = []
        // add payment to invoice
        selectedList.map(async (invoice) => {
            
            try {
                const { data } = await axios.get(url)
                let customerPaymentNumber = data.customerPaymentNumber.toString()
                if(selectedPayment.balance !== 0 && customerPaymentNumber !== null){
                    let newAmount = 0
                    let total = calculateTotals(invoice)
                    let payments = 0
                    invoice.payments.map((payment) => {
                        payments += payment.amount
                    })
                    let balance = total - payments
                    let pago = {}
                    let job = {}
                    if(selectedPayment.balance < balance){
                        newAmount = 0
                        let invoicePayments = invoice.payments
                        const currentAmount = selectedPayment.balance
                        pago = {
                            amount: currentAmount,
                            type: selectedPayment.type,
                            reference: selectedPayment.reference,
                            comments: selectedPayment.comments,
                            at: new Date(),
                            paymentNumber: customerPaymentNumber
                        }
                        arrayInvoices.push({'invoice': invoice._id, 'amount': currentAmount, 'paymentNumber': customerPaymentNumber})
                        invoicePayments.push(pago)
                        invoice.payments = invoicePayments
                        invoice.balance = balance - currentAmount
                        job['pendingBalance'] = balance - currentAmount
                        job['deposit'] = payments + pago.amount
                        job['paid'] = false
                        job['paidTime'] = null
                        switch (selectedPayment.type) {
                            case 'Cash':
                                client.cashBalance = client.cashBalance - parseFloat(currentAmount)
                                break;
                            case 'Credit Card':
                                client.cardBalance = client.cardBalance - parseFloat(currentAmount)
                                pago['isCreditCardPayment'] = true
                                break;
                            case 'Check':
                                client.checkBalance = client.checkBalance - parseFloat(currentAmount)
                                break;
                            default:
                                break;
                        }
                    }else{
                        newAmount = selectedPayment.balance - balance
                        let invoicePayments = invoice.payments
                        pago = {
                            amount: balance,
                            type: selectedPayment.type,
                            reference: selectedPayment.reference,
                            comments: selectedPayment.comments,
                            at: new Date(),
                            paymentNumber: customerPaymentNumber
                        }
                        arrayInvoices.push({'invoice': invoice._id, 'amount': balance, 'paymentNumber': customerPaymentNumber})
                        invoicePayments.push(pago)
                        invoice.payments = invoicePayments
                        invoice.balance = 0
                        invoice.invoiceStatus = 'paid'
                        job['paid'] = true
                        job['paidTime'] = new Date()
                        job['pendingBalance'] = 0
                        job['deposit'] = payments + pago.amount
                        switch (selectedPayment.type) {
                            case 'Cash':
                                client.cashBalance = client.cashBalance - parseFloat(balance)
                                break;
                            case 'Credit Card':
                                client.cardBalance = client.cardBalance - parseFloat(balance)
                                pago['isCreditCardPayment'] = true
                                break;
                            case 'Check':
                                client.checkBalance = client.checkBalance - parseFloat(balance)
                                break;
                            default:
                                break;
                        }
                    }

                    pago['job'] = invoice.job._id
                    pago['createdBy'] = localStorage.getItem('userId')

                    addPayment(pago)

                    updateInvoicePayment(invoice)
                    updateCustomerPayments(newAmount, client)
                    updateJob(job, invoice)
                    
                }
                if(selectedList[selectedList.length - 1]._id === invoice._id && arrayInvoices.length !==0){
                    const url2 = api + '/generalpaymentnumber'

                    try {
                        const { data } = await axios.get(url2)
                        const sum = arrayInvoices.reduce((accumulator, payment) => {
                            return accumulator + payment.amount;
                          }, 0);
                        const formData = {
                            'invoices': arrayInvoices,
                            'customer': clientId,
                            'paymentNumber': data.generalPaymentNumber.toString(),
                            'paymentDate': new Date(),
                            'paymentTotal': sum
                        }
                        createGeneralPayment(formData)
                        pcb()
                    } catch (err) {
                        console.error(err.message)
                        return null
                    }
                }
            } catch (err) {
                console.error(err)
                return null
            }
            
        })  

        setSelected(false)
    }

    const createGeneralPayment = async (formData) => {
        const url = api + '/generalpayment/'

        try {
            const { data } = await axios.post(url, formData)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateJob = async (formData, invoice) => {
        const url = api + '/job/' + invoice.job._id

        try {
            const { data } = await axios.patch(url, formData)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addPayment = async (formData) => {
        const url = api + '/payment'
        try {
            await axios.post(url, formData)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateInvoicePayment = async (invoice) => {
        const url = api + '/invoice/' + invoice._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const body = {
            'payments': invoice.payments,
            'balance': invoice.balance,
        }

        try {
            await axios.patch(url, body)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateCustomerPayments = async (balanceAmount, client) => {
        const url = api + '/customer/' + clientId
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        let payments = customer.payments
        const index = payments.findIndex(payment => {
            return payment._id === selectedPayment._id;
        });
        
        if (index !== -1) {
            payments[index].balance = balanceAmount;
        }

        const body = {
            'payments': payments,
            'cashBalance': client.cashBalance,
            'cardBalance': client.cardBalance,
            'checkBalance': client.checkBalance
        }

        try {
            await axios.patch(url, body)
            getCustomer()
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const calculateTotals = (invoice) =>{
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = taxes['state']
                break
            case 'county':
                taxPercentaje = taxes['county']
                break
            case 'exempt':
                taxPercentaje = taxes['exempt']
                break
            default:
                taxPercentaje = taxes['state']
        }
        if(invoice.job){
            let yards = invoice.job.loadYD3 && invoice.job.loadYD3 > 0 ? invoice.job.loadYD3 : (typeof invoice.job.targetYD3 === "string" ? parseFloat(invoice.job.targetYD3) : invoice.job.targetYD3)
            const material = invoice.job.unitPrice * yards
            const trips = Math.ceil(yards/10)
            const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
            const delivery = invoice.shippingFee || invoice.job.deliveryFee      
            const color = invoice.color ? invoice.colorTicket : 0
            const fiber = invoice.fiber ? invoice.fiberBags : 0
            const size = invoice.size ? invoice.sizeBags : 0
            const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
            const subtotal = material + ((delivery + fuelSur + color + fiber + size + miscelaneos) * trips)
            const taxas = subtotal * taxPercentaje
            const total = subtotal + taxas
            return total
        }
        return 0
    }


    return (
        <div>
            <p>Select payment receipt:</p>
            <div onChange={onChangeValue} className='form-group row'>
                {
                    customer.payments && customer.payments.map((payment, key) => {
                        return (
                            payment.balance !== 0 && <div className='col-sm-2' key={key}>
                                <input type="radio" value={payment._id} name="payments" /> {`${new Date(payment.at).toLocaleDateString()}: ${toCurrency(payment.balance)}`}
                            </div>
                        )
                    })                  
                }
            </div>
            {selected && <button className='btn btn-success' onClick={generatePayment}>Pay</button>}
        </div>
    )
}

export default GeneralPayment