// import React, { useEffect, useState } from 'react'
// import {
//     Document,
//     PDFViewer,
//     Page,
//     View,
//     Image,
//     Text,
//     StyleSheet
// } from '@react-pdf/renderer'
// import Layout from '../layout/Layout'
// import axios from 'axios'
// import { connect } from 'react-redux'
// import { getJobLoads } from '../../actions/load'
// import { getJob } from '../../actions/job'
// import Spinner from '../layout/Spinner'
// import { toCurrency } from '../utils/Convert'
// import { api } from '../utils/Api'
// const styles = StyleSheet.create({
//     page: {
//         padding: 4,
//         margin: 4,
//         flexDirection: 'column'
//     },
//     container: {
//         flex: 1,
//         flexDirection: 'row',
//         '@media max-width: 360': {
//             flexDirection: 'column',
//         },
//     },
//     renglonStyle: {
//         flexDirection: 'row',
//         width: '95%'
//     },
//     textPageTitle: {
//         fontFamily: 'Helvetica',
//         fontSize: 28,
//         textAlign: 'right'
//     },
//     text2Col: {
//         fontFamily: 'Helvetica',
//         fontSize: 12,
//         borderBottom: 1,
//         borderBottomStyle: 'solid',
//         borderRightStyle: 'solid',
//         width: '50%',
//         marginTop: 10
//     },
//     textCol: {
//         fontFamily: 'Helvetica',
//         fontSize: 9,
//         border: 1,
//         borderBottomStyle: 'solid',
//         width: '25%',
//         marginTop: 8
//     },
//     textTotales: {
//         fontFamily: 'Helvetica-Bold',
//         fontSize: 9,
//         border: 1,
//         padding: 2,
//         width: '14.23%'
//     },
//     invoiceHeader: {
//         fontFamily: 'Helvetica',
//         fontSize: 10
//     },
//     factoring: {
//         fontFamily: 'Helvetica',
//         fontSize: 8
//     },
//     invoiceHeaderBold: {
//         fontFamily: 'Helvetica-Bold',
//         fontSize: 10,
//     },
//     customer: {
//         fontFamily: 'Helvetica',
//         fontSize: 10,
//         marginTop: 20,
//         marginLeft: 10,
//         textTransform: 'uppercase',
//     },
//     customerAddress: {
//         fontFamily: 'Helvetica',
//         fontSize: 10,
//         marginLeft: 10,
//     },
//     leftImage: {
//         marginLeft: 5,
//         marginTop: 10
//     },
//     image: {
//         width: '25%',
//         padding: 3
//     },
//     row: {
//         flex: 1,
//         flexDirection: 'row',
//         // flexGrow: 1,
//     },
//     left: {
//         width: '50%',
//         flexGrow: 0,
//         paddingLeft: 20,
//         flexShrink: 1,
//         borderRightStyle: 'solid',
//         borderRight: 3
//         // flexBasis: 200,
//     },
//     right: {
//         padding: 5,
//         flexShrink: 1,
//         flexGrow: 2,
//     },
//     detailHeader: {
//         fontFamily: 'Helvetica',
//         fontSize: 12,
//         textTransform: 'uppercase',
//         backgroundColor: 'grey',
//         color: 'white',
//         width: '95%',
//         borderStyle: 'solid',
//         borderWidth: 2,
//         borderColor: 'black'
//     },
//     textYards: {
//         fontFamily: 'Helvetica',
//         fontSize: 10,
//         width: '25%',
//         textAlign: 'right'
//     },
//     textSmallTable: {
//         fontFamily: 'Helvetica',
//         fontSize: 10,
//         width: '25%'
//     },
// })

// const QuotePdf = ({ match, getJobLoads, load: { loads, loading: loadsLoading }, getJob, job: { job, loading: jobLoading } }) => {
//     const [company, setCompany] = useState(null)


//     useEffect(() => {
//         getCompanyInfo()
//         getJob(match.params.id)
//         getJobLoads(match.params.id)
//     }, [])

//     useEffect(() => {
//         job && console.log(job.taxPrice)
//     }, [job])

//     const getCompanyInfo = async () => {
//         const url = api + '/company'
//         const token = localStorage.getItem('token')
//         axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

//         try {
//             const { data } = await axios.get(url)
//             setCompany(data)
//         } catch (err) {
//             console.error(err)
//             return null
//         }
//     }

//     const DrawLoads = () => {
//         return (
//             <>
//                 <View style={styles.renglonStyle}>
//                     <Text style={styles.textCol}>
//                         Ship Date
//                         </Text>
//                     <Text style={styles.textCol}>
//                         Product Code
//                         </Text>
//                     <Text style={styles.textCol}>
//                         Delivery Ticket
//                         </Text>
//                     <Text style={styles.textCol}>
//                         Order Code
//                         </Text>
//                     <Text style={styles.textCol}>
//                         Yards
//                         </Text>
//                 </View>
//                 {
//                     loads.map((load) => {
//                         return (
//                             <View style={styles.renglonStyle} key={load._id}>
//                                 <Text style={styles.textSmallTable}>
//                                     {new Date(load.date).toLocaleDateString()}
//                                 </Text>
//                                 <Text style={styles.textSmallTable}>
//                                     {job.psi}
//                                 </Text>
//                                 <Text style={styles.textSmallTable}>
//                                     {load.deliveryTicket}
//                                 </Text>
//                                 <Text style={styles.textSmallTable}>
//                                     {load.orderCode}
//                                 </Text>
//                                 <Text style={styles.textYards}>
//                                     {load.yards}
//                                 </Text>
//                             </View>
//                         )
//                     })
//                 }
//             </>
//         )
//     }

//     const DrawTotals = () => {
//         return (
//             <View style={styles.renglonStyle}>
//                 <Text style={styles.textTotales}>
//                     {toCurrency((job ? getTotal() : ""), 2)} Subtotal
//                 </Text>
//                 <Text style={styles.textTotales}>
//                     {job ? job.targetYD3 : ""} Yards
//                 </Text>
//                 <Text style={styles.textTotales}>
//                     {toCurrency((job ? job.unitPrice : ""), 2)} Unit Price
//                 </Text>
//                 <Text style={styles.textTotales}>
//                     {toCurrency((job ? job.deliveryFee : ""), 2)} Delivery Fee
//                 </Text>
//                 <Text style={styles.textTotales}>
//                     {toCurrency((job ? job.deposit : ""), 2)} Deposit
//                 </Text>
//                 <Text style={styles.textTotales}>
//                     {toCurrency((job ? getTaxAmount() : ""), 2)} Tax
//                 </Text>
//                 <Text style={styles.textTotales}>
//                     {toCurrency((job ? getTotalWithTax() : ""), 2)} Total
//                 </Text>
//             </View>
//         )
//     }

//     const getTaxAmount = () => {
//         let total = 0
//         if (job) {
//             total = getTotal()
//             total *= 0.0825
//         }
//         return total
//     }

//     const getTotal = () => {
//         let total = 0
//         if (job) {
//             total = (job.unitPrice ? job.targetYD3 ? (typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3): job.targetYD3) * job.unitPrice : 0 : 0) + (job.deliveryFee ? job.deliveryFee : 0) + (job.taxAmount ? job.taxAmount : 0) - (job.deposit ? job.deposit : 0)
//         }
//         return total
//     }

//     const getTotalWithTax = () => {
//         let total = 0
//         if (job) {
//             total = getTotal()
//             let tax = job ? job.taxAmount ? job.taxAmount : 0 : 0
//             total *= 1.0825
//         }
//         return total
//     }

//     const DrawPdf = () => {
//         return <Document
//             author='57concrete'
//             keywords='57concrete'
//             subject='57concrete Invoice'
//             title='Invoice'
//         >
//             <Page
//                 size='A4'
//                 wrap={false}
//                 style={styles.page}
//             >
//                 <View style={styles.leftImage}>
//                     <Image
//                         style={styles.image}
//                         src={process.env.PUBLIC_URL + '/images/concrete57.png'}
//                     />
//                 </View>
//                 <View style={styles.renglonStyle}>
//                     <Text style={styles.textPageTitle}>QUOTE</Text>
//                 </View>
//                 <View style={styles.renglonStyle}>
//                     <View style={styles.left}>
//                         <Text style={styles.invoiceHeader}>
//                             {company ? company.address : ""}
//                         </Text>
//                         <Text style={styles.invoiceHeaderBold}>
//                             {company ? company.name : ""}
//                         </Text>
//                         <Text style={styles.customer}>
//                             {job ? job.customer.name : ""}
//                         </Text>
//                         <Text style={styles.customerAddress}>
//                             {job ? job.customer.address : ""}
//                         </Text>
//                     </View>
//                     <View style={styles.right}>
//                         <Text style={styles.invoiceHeader}>Date: {new Date(job ? job.scheduledDate : "").toLocaleDateString()}</Text>
//                         <Text style={styles.invoiceHeader}>
//                             Job No: {job ? job.number : ""}
//                         </Text>
//                         <Text style={styles.invoiceHeader}>
//                             Legal Address: {job ? job.customer.address : ""}
//                         </Text>
//                         <Text style={styles.invoiceHeader}>
//                             Account No: {job ? job.customer.customerNumber : ""}
//                         </Text>
//                         <Text style={styles.invoiceHeader}>
//                             Account Name:   {job ? job.customer.name : ""}
//                         </Text>
//                     </View>
//                 </View>
//                 <DrawLoads />
//                 <DrawTotals />
//             </Page>
//         </Document>
//     }


//     return (
//         <Layout
//             title='Quote PDF'
//             description='Quote'
//             className="container col-md-12"
//         >
//             <PDFViewer width="95%" style={{ height: '90vh' }}>
//                 {jobLoading || loadsLoading ? "No PDF" : (job ? <DrawPdf /> : "No PDF")}
//             </PDFViewer>
//         </Layout>
//     )
// }

// const mapStateToProps = state => ({
//     load: state.load,
//     job: state.job
// })

// export default connect(mapStateToProps, { getJobLoads, getJob })(QuotePdf)


import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import SendQuote from './SendQuote'

export default class QuotePdf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showModalEmail: false,
            showModalSms: false,
            pdf: '',
            loading: true
        }
    }

    /**
     * Get PDF Quote
     */
    getPDFQuote  = async () => {
        const url = api + `/quotepdf/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.post(url)
            this.setState({
                pdf: data,
                loading: false
            })
        } catch (err) {
            console.error(err)
            return null
        }
    }

    /**
     * Open and close email modal
     * @param {Event} e event of the modal
     */
    toggleModalEmail = (e) => {
        this.setState({
            showModalEmail: !this.state.showModalEmail
        })
    }

    /**
     * Open and close sms modal
     * @param {Event} e event of the modal
     */
    toggleModalSms = (e) => {
        this.setState({
            showModalSms: !this.state.showModalSms
        })
    }

    componentDidMount() {
        this.getPDFQuote()
    }

    render() {

        return (
            <Layout
                title='Quote PDF'
                description='Quote'
                className="container col-md-12"
            >
                <Link onClick={this.toggleModalEmail}
                    to='#'
                    className="btn btn-primary"
                >
                    Send quote by email
                </Link>                
                <Link onClick={this.toggleModalSms}
                    to='#'
                    className="btn btn-success"
                    style={{marginLeft:20}}
                >
                    Send quote by SMS
                </Link>                
                <Modal show={this.state.showModalEmail} animation={false} onHide={this.toggleModalEmail}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Quote to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendQuote 
                            jobId={this.props.match.params.id} 
                            pcallback={this.toggleModalEmail}
                            isEmail={true}
                        />
                    </Modal.Body>
                </Modal>  
                <Modal show={this.state.showModalSms} animation={false} onHide={this.toggleModalSms}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Quote to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendQuote 
                            jobId={this.props.match.params.id} 
                            pcallback={this.toggleModalSms}
                            isEmail={false}
                        />
                    </Modal.Body>
                </Modal>  
                {
                    this.state.loading ? <Spinner/> : <iframe src={this.state.pdf} width='95%' style={{height: '90vh'}} />
                }
                
            </Layout>
        )
    }
}