import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import Modal from 'react-bootstrap/esm/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import AddressGeolocation from '../map/AddressGeolocation'
import SubplantModal from './SubplantModal'

const Plant = props => {
    useEffect(() => {
        getPlant()
    }, [])

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [number, setNumber] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [id, setId] = useState(null)
    const [searchAddress, setSearchAddress] = useState(false)
    const [haulingCost, setHaulingCost] = useState('')
    const [onEditHauling, setOnEditHauling] = useState(false)
    const [subplants, setSubplants] = useState([])
    const [subplantModal, setSubplantModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const subplant = useRef('')
    const edit  = useRef(false)


    const getPlant = async () => {
        const url = api + '/plant/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setName(data.name)
            setAddress(data.address)
            setNumber(data.number)
            setId(data._id)
            setLongitude(data.longitude)
            setLatitude(data.latitude)
            setHaulingCost(data.haulingCost)
            setSubplants(data.subplants)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }


    const updatePlant = async () => {
        const url = api + '/plant/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const planta = {
                number,
                name,
                address,
                latitude,
                longitude,
                haulingCost,
                subplants
            }
            await axios.patch(url, planta)
            if(onEditHauling) createHaulingcost(planta)
            props.history.push('/plantlist')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createHaulingcost = async (formData) => {
        const url = api + '/haulingcost'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const haulingCostBody = {
                cost: formData.haulingCost,
                plant: id,
                createdBy: localStorage.getItem('userId')
            }
            await axios.post(url, haulingCostBody)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        updatePlant()
    }

    // show/hide search modal
    const onShowModal = () => {
        setSearchAddress(!searchAddress)
    }

    // show/hide subplant modal
    const onShowSubplantModal = () => {
        setSubplantModal(!subplantModal)
        edit.current = false;
    }

    const selectCurrentSubplant = (currentSubplant) => {
        subplant.current = currentSubplant;
        edit.current = true;
        setSubplantModal(!subplantModal)
    }

    // address from modal
    const handleCallback = (childData) => {
        setLatitude(childData.destLat)
        setLongitude(childData.destLng)
        setAddress(childData.address)
        setSearchAddress(!searchAddress)
    }

    const removeCurrentSubplant = (index) => {
        setSubplants(subplants.filter((v, i) => i !== index));
    }

    const RenderSubplants = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Production Capacity</th>
                        <th>Slump Rack Time</th>
                        <th>Time To Load Truck</th>
                        <th>Washout On Site</th>
                        <th>Start Time</th>
                        <th>Closing Time</th>
                        <th>Radio Attention</th>
                        <th>Diesel Performance</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        subplants.length === 0 ?
                        <tr>
                            <td colSpan={10} className='text-center'>No subplants registered</td>
                        </tr>
                        :
                        subplants.map((subplant, index) => (
                            <tr key={index}>
                                <td>{subplant.name}</td>
                                <td>{subplant.productionCapacity}</td>
                                <td>{subplant.slumpRackTime}</td>
                                <td>{subplant.timeToLoadTruck}</td>
                                <td>{subplant.washoutOnSite}</td>
                                <td>{`${new Date(subplant.startTime).getHours()} : ${new Date(subplant.startTime).getMinutes()}`}</td>
                                <td>{`${new Date(subplant.closingTime).getHours()} : ${new Date(subplant.closingTime).getMinutes()}`}</td>
                                <td>{subplant.radioAttention}</td>
                                <td>{subplant.dieselPerformance}</td>
                                <td>
                                    <input type='checkbox' checked={subplant.active} readOnly />
                                </td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        selectCurrentSubplant(subplant);
                                        setCurrentIndex(index)
                                    }}
                                    >Edit</button>
                                    {/* &nbsp;
                                    <button className='btn btn-danger' onClick={() => removeCurrentSubplant(index)}>Delete</button> */}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    const PlantRender = () => {
        return (
            <form className='form' onSubmit={onSubmit}>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Plant Number</label>
                        <input
                            type="text"
                            name='number'
                            className='form-control'
                            onChange={(e) => setNumber(e.target.value)}
                            value={number}
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Name</label>
                        <input
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            name='name'
                            className="form-control"
                            value={name}
                        />
                    </div>
                </div>
                <div>
                <label className="text-muted"></label>
            </div>
            <div className="form-group row">
                <div className='col-sm-4'>
                    <button className='btn btn-primary' onClick={onShowModal} type='button'>
                        Search address <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                {
                    searchAddress &&
                    <Modal show={searchAddress} animation={false} onHide={onShowModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressGeolocation
                                search={searchAddress}
                                parentCallback={handleCallback}
                                single={true}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
                <div className="form-group row">
                    <div className='col-sm-12'>
                        <label className='text-muted'>Address</label>
                        <input
                            type="text"
                            name='address'
                            className='form-control'
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Latitude</label>
                    <input
                        onChange={(e) => setLatitude(e.target.value)}
                        type="number"
                        name='latitude'
                        className="form-control"
                        value={latitude}
                        placeholder="Latitude"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Longitude</label>
                    <input
                        type="number"
                        name='longitude'
                        className='form-control'
                        onChange={(e) => setLongitude(e.target.value)}
                        value={longitude}
                        placeholder='Longitude'
                        required
                    />
                </div>
            </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Hauling Cost</label>
                        <input
                            type="number"
                            name='haulingCost'
                            className='form-control'
                            onChange={(e) => {
                                setOnEditHauling(true)
                                setHaulingCost(e.target.value)
                            }}
                            value={haulingCost}
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <div className='col-sm-12'>
                        <label className='text-muted'>Subplants</label>
                        <div>
                            <button type='button' className='btn btn-primary' onClick={onShowSubplantModal}>+ Add</button>
                        </div>
                        <RenderSubplants />
                    </div>
                </div>

                <div className="my-2">
                    <button className='btn btn-success' type='submit'> Save </button>
                </div>
            </form>
        )
    }

    return (
        <Layout
            title='Plant'
            description='Update plant information'
            className="container col-md-8 offset-md-2"
        >
            {PlantRender()}
            {
                subplantModal &&
                <Modal show={subplantModal} animation={false} onHide={onShowSubplantModal} size='sm' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Subplant</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SubplantModal subplants={subplants} setSubplants={setSubplants} toggleModal={onShowSubplantModal} currentPlant={subplant.current} edit={edit.current} index={currentIndex} />
                    </Modal.Body>
                </Modal>
            }
        </Layout>
    )
}

export default Plant