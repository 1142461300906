import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'

const City = (props) => {
    const [city, setCity] = useState('')
    const [unitPrice, setUnitPrice] = useState(0)
    const [id, setId] = useState('')
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getCity()
    }, [])

    const setFee = e => {
        setUnitPrice(e.target.value)
    }

    const submit = e => {
        e.preventDefault()
        updateCity()
    }

    const getCity = async () => {
        const url = api + '/deliveryfee/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setCity(data.city)
            setUnitPrice(data.unitPrice)
            setId(data._id)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateCity = async () => {
        const url = api + '/deliveryfee/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const feeNum = Number(unitPrice)
            const cityFee = {
                unitPrice: feeNum
            }
            await axios.patch(url, cityFee)
            props.history.push('/citydeliveryfee')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const deleteCity = async () => {
        const url = api + '/deliveryfee/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            props.history.push('/citydeliveryfee')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    return (
        <Layout
            title='City'
            description='Update or delete Driver Payment Peer Trip'
            className="container col-md-8 offset-md-2"
        >
            <form className="form" onSubmit={submit}>
                <div className="form-group">
                    <label className="text-muted">City</label>
                    <input
                        type='text'
                        value={city}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label className="text-muted">Delivery Fee</label>
                    <input name="Delivery Fee"
                        type="number"
                        className="form-control"
                        value={unitPrice}
                        onChange={setFee}
                    />
                </div>
                <div className="my-2">
                    <div class="container">
                        <div class="row">
                            <div class="col-auto mr-auto">
                                <button className="btn btn-primary">Submit</button>
                                &nbsp;
                                <button className='btn btn-danger' onClick={(e) => { if (window.confirm('Are you sure you want to delete the Driver payment fee?')) deleteCity() }} >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default City