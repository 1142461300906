import React, { Component } from 'react';
import Layout from '../../layout/Layout';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { api } from '../../utils/Api';
import WriteUpRender from './WriteUpRender';

export default class EmployeeWriteUp extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            writeups: [],
            showModalWriteUps: false,
            writeupIndex: 0,
            editWriteUp: false,
            currentWriteUp: {},
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async (newWriteUps) => {

        newWriteUps.map((writeup) => {
            let offenses = []
            writeup.offenses.map((offense) =>{
                offenses.push(offense.value)
            })
            writeup.offenses = offenses
        })

        const body = {
            writeups: newWriteUps
        }

        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, body)
        } catch (err) {
            console.log(err)
            console.error(err.message)
            return null
        }
    }

    goBack = (e) => {
        this.props.history.goBack()
    }

    navigatePdf = (index) => {
        this.props.history.push({pathname: `/employeewupdf/${this.props.match.params.id}`, state: {index}})
    }

    getEmployeeData = async () => {
        const url = api + `/employee/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let currentWriteUps = []

            data.writeups.map((writeup) =>{

                let offenses = []

                writeup.offenses.map((offense) =>{
                    offenses.push({'label': offense, 'value': offense})
                })

                currentWriteUps.push({
                'warning': writeup.warning,
                'other': writeup.other,
                'description': writeup.description,
                'planImprovement': writeup.planImprovement,
                'consequences': writeup.consequences,
                'offenses': offenses,
                'date': writeup.date,
                })
            })

            this.setState({
                writeups: currentWriteUps,
                name: data.name
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    toggleModalWriteUps = () => {
        this.setState({
            showModalWriteUps: !this.state.showModalWriteUps,
            editWriteUp: false
        });
    }

    setWriteUps = (newWriteUps) => {
        this.setState({
            writeups: newWriteUps
        });
        this.onSubmit(newWriteUps)
    }

    removeCurrentWriteUp = (index) => {
        this.setState({
            writeups: this.state.writeups.filter((v, i) => i !== index)
        })
    }

    WriteUpTable = () => {
        return <div>
            <button type='button' className='btn btn-primary' onClick={this.toggleModalWriteUps}>Add Write Up</button>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Warning</th>
                        <th>Offenses</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.writeups && this.state.writeups.length !== 0 ? 
                        this.state.writeups.map((writeup, index) => {
                            return <tr key={index}>
                                <td>{new Date(writeup.date).toLocaleDateString()}</td>
                                <td>{writeup.warning}</td>
                                <td>
                                    {
                                        writeup.offenses.map((offense, index2) =>(
                                            <div key={index2}>
                                                {offense.value}
                                            </div>
                                        ))
                                    }
                                    {writeup.other != '' && <div>{writeup.other}</div>}
                                </td>
                                <td>
                                    <button className='btn btn-secondary' onClick={() => {
                                        this.setState({
                                            writeupIndex: index,
                                            currentWriteUp: writeup,
                                            editWriteUp: true,
                                            showModalWriteUps: true
                                        });
                                    }}
                                    type='button'
                                    >Edit</button>
                                    &nbsp;
                                    <button className='btn btn-primary' type='button' onClick={() => this.navigatePdf(index)}>PDF</button>
                                    {/* <button className='btn btn-danger' type='button' onClick={() => this.removeCurrentWriteUp(index)}>Delete</button> */}
                                </td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={4} className='text-center'>No writeups registered</td>
                        </tr>
                    }
                </tbody>
            </table>
            <button type='button' className='btn btn-success' onClick={this.goBack}>Go back</button>
            {/* &nbsp; */}
            {/* <button type='button' className='btn btn-secondary' onClick={this.navigatePdf}>Write Up PDF</button> */}
        </div>
    }


    componentDidMount(){
        this.getEmployeeData()
    }


    render() {
        return <Layout
            title={`Employee: ${this.state.name}`}
            description='Employee Write Up'
            className='container col-md-8 offset-md-2'
        >
            {this.WriteUpTable()}
            <Modal
                show={this.state.showModalWriteUps}
                animation={false}
                onHide={this.toggleModalWriteUps}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Write Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WriteUpRender currentWriteUp={this.state.currentWriteUp} writeups={this.state.writeups} setWriteUps={this.setWriteUps} toggleModal={this.toggleModalWriteUps} edit={this.state.editWriteUp} index={this.state.writeupIndex} />
                </Modal.Body>
            </Modal>
        </Layout>
    }
}