import React from 'react'
import Select from 'react-select'

const BusinessTypePicker = props => {

    const options = [
        { value: 'LLC', label: 'LLC' },
        { value: 'LLP', label: 'LLP' },
        { value: 'SOLE PROPIETORSHIP', label: 'SOLE PROPIETORSHIP' },    
        { value: 'CORPORATION', label: 'CORPORATION' }                
      ]

    return (
        <div>
            <Select
                options={options}
                placeholder='Select Business Type'
                onChange={props.action}
            />
        </div>
    )
}

export default BusinessTypePicker

