import React, { Component } from 'react';
import Select from 'react-select';

export default class OffensePicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isSearchable: true,
            options: [
                {value: 'Tardiness/Leaving Early', label: 'Tardiness/Leaving Early'},
                {value: 'Absenteeism', label: 'Absenteeism'},
                {value: 'Violation of Company Policies', label: 'Violation of Company Policies'},
                {value: 'Substandard Work', label: 'Substandard Work'},
                {value: 'Violation of Safety Rules', label: 'Violation of Safety Rules'},
                {value: 'Rudeness to Customers/Coworkers', label: 'Rudeness to Customers/Coworkers'},
            ]
        }
    }

    render() {
        return (
            <div>
                <Select
                    name="form-field-name"
                    placeholder='Offense...'
                    isMulti
                    value={this.props.value}
                    onChange={this.props.action}
                    isSearchable={this.state.isSearchable}
                    options={this.state.options}
                />
            </div>
        )
    } 
}
