import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateJob } from '../../actions/job'

const JobInspectItem = ({ updateJob, job, parentCallBack }) => {

    const [cancelled, setCancelled] = useState(false)
    const [inspected, setInspected] = useState(job.inspectionPassed)
    const [datePassed, setDatePassed] = useState(job.inspectionPassedTimeStamp ? job.inspectionPassedTimeStamp : null)

    useEffect(() => {
    }, [])

    const showDate = fecha => {
        let jobDate = 'NA'
        if (fecha) {
            jobDate = new Date(fecha).toLocaleDateString() + ' at ' + new Date(fecha).toLocaleTimeString([], { timeStyle: 'short' })
        }
        return jobDate
    }

    const savejob = () => {
        console.log('update jobid', job._id)
        const payload = {
            inspectionPassed: inspected,
            inspectionPassedTimeStamp: datePassed
        }

        if (cancelled) {
            payload.jobStatus = 'Company Cancellation'
        }

        //console.log(payload)
        updateJob(job._id, payload)
        parentCallBack('update job')
    }

    const clickInspection = () => {
        setInspected(!inspected)
        datePassed ? setDatePassed(null) : setDatePassed(new Date())
    }

    return (
        <tr key={job._id}>
            <td>
                <Link to={'/job/' + job._id}>
                    {job.number}
                </Link>
            </td>
            <td>
                {job.customer.customerNumber + ' ' + job.customer.name}
            </td>
            <td>
                {new Date(job.scheduledDate).toLocaleDateString()}
            </td>
            <td>
                {new Date(job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}
            </td>
            <td>
                {job.shipAddress}
            </td>
            <td>{job.loadYD3} </td>
            <td>{job.targetYD3} </td>
            <td>{job.destinationTime} </td>
            <td>{job.jobType} </td>
            <td>{job.psi} </td>
            <td>{job.seller ? job.seller.name : "NA"} </td>
            <td>
                {showDate(job.inspectionTimeStamp)}
            </td>
            <td>
                <input name="inspection"
                    type="checkbox"
                    checked={inspected}
                    onChange={(e) => clickInspection()}
                />
            </td>
            <td>
                {showDate(datePassed)}
            </td>
            <td>
                <input name="cancellation"
                    type="checkbox"
                    checked={cancelled}
                    onChange={(e) => setCancelled(!cancelled)}
                />
            </td>
            <td>
                <input className="btn btn-success" type="submit" value="save" onClick={savejob} />
            </td>
        </tr>
    )
}

export default connect(null, { updateJob })(JobInspectItem) 
