import React, { useState } from 'react'
import Select from 'react-select'
import { components } from "react-select"
import Layout from '../layout/Layout'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { api } from '../utils/Api'

const NewReport = props => {
    const [fields, setFields] = useState([])
    const [filterArray, setFilterArray] = useState([])
    const [currentFilter, setCurrentFilter] = useState(null)
    const [currentOperator, setCurrentOperator] = useState(null)
    const [currentValue, setCurrentValue] = useState(null)
    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)
    const [campos, setCampos] = useState(fields)

    // useEffect(() => {
    //     console.log(`iteracion? ${conta}`)
    //     setConta(conta + 1)
    // }, [selectedOption])

    // Job report field options
    const options = [
        { value: 'customer', label: 'Customer'},
        { value: 'number', label: 'Job ID'},
        { value: 'dateTime', label: 'Date/time'},
        { value: 'shipAddress', label: 'Address'},
        { value: 'jobType', label:'Job Type'},
        { value: 'loadYD3', label:'Load'},
        { value: 'targetYD3', label:'Target'},
        { value: 'jobStatus', label:'Status'},
        { value: 'jobPoor', label: 'Pour'},
        { value: 'slump', label: 'Slump'},
        { value: 'destinationMinutes', label: 'Destination Time'},
        { value: 'psi', label: 'PSI'},
        { value: 'seller', label: 'Seller'},
        { value: 'balance', label: 'Balance' },
        { value: 'comments', label: 'Comments'},
        { value: 'shipCity', label: 'Locality' },
        { value: 'plant', label: 'Plant' }
    ]    

    const filterOption = [
        { value: 'customer', label: 'Customer'},
        { value: 'jobStatus', label: 'Job Status'},
        { value: 'psi', label: 'PSI'}
    ]

    const numericOperator = [
        { value: '=', label: 'equal' },
        { value: '<', label: 'Less than'},
        { value: '>', label: 'More than'},
        { value: '!=', label: 'Not equal'},
        { value: 'contains', label: 'Contains'},
    ]

    
    // Aux component in order to show checkbox in selected fields
    const Option = props => {
    return (
        <div>
        <components.Option {...props}>
            <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </components.Option>
        </div>
    );
    };

    const seleccionados = selected => {
        setFields(selected)
    }

    const addFilter = () => {
        const filtro = {
            field: currentFilter,
            operator: currentOperator,
            value: currentValue,
        }

        console.log(filtro)

        setFilterArray([...filterArray, filtro])
        console.log(filterArray)
    }

    const setFilter = selected => {
        if (selected) {
            setCurrentFilter(selected.value)
        }        
    }

    const setOperator = selected => {
        if (selected) {
            // console.log('set operator')
            // console.log(selected)
            setCurrentOperator(selected.value)
        }
    }

    
    const deleteFilter = element => {
        console.log(element)
        const newArray = filterArray.filter(item => item !== element)
        console.log(newArray)
        setFilterArray(newArray)
    }

    const saveReport = async () => {
        const url = api + '/report'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        console.log('saving fields...')
        const fieldsOrder = fields.map((campo, i) => {
            return {
                atribute: campo.value,
                label: campo.label,
                order: i,
            }
        })

        const report = {
            name, 
            description,
            fields: fieldsOrder,
            filters: filterArray,
            createdBy: localStorage.getItem('userId')
        }

        console.log(report)

        try {
            const { data } = await axios.post(url, report)
            console.log(data)
            const { _id } = data
            props.history.push(`/reportjobrun/${_id}`)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        console.log('submit...')
        saveReport()         
    }


    // Draw filters on screen
    const RenderFilters = () => {
        if (filterArray.length > 0) {
            return filterArray.map((filt, i) => {
                return (
                <tr key={i}>
                    <td>
                        {filt.field}
                    </td>
                    <td>
                        {filt.operator}
                    </td>
                    <td>
                        {filt.value}
                    </td>
                    <td>
                        <button className='btn btn-danger' onClick={() => deleteFilter(filt)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </td>
                </tr> )
            })
        } else {
            return null
        }
    }

    // Defined filter table
    const RenderTable = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        <th>Filter</th>
                        <th>Operator</th>  
                        <th>Value</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <RenderFilters />                   
                </tbody>
            </table>
        )
    }

    return (
        <Layout
            title='New job report'
            description='Define report criteria'
            className="container col-md-8 offset-md-2"
        >   
        <div>            
        
            <div className='form'>
                <h3>1. Report Name</h3>
                <input
                    type='text'
                    className='form-control'
                    required
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <label className='text-muted'>Report description</label>
                <input
                    type='text'
                    className='form-control'
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className='col-sm-8'>            
                <h3>2. Select Fields</h3>
                <Select
                    options={options}
                    // placeholder='Select Fields '
                    onChange={seleccionados}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    components={{
                        Option
                    }}
                    allowSelectAll={true}
                />
            </div>
            {
                (fields.length > 0) &&
                <div>
                    <h3>3. Select Filters</h3>
                    <RenderTable />
                    
                    <div className='form-group row'>
                        <div className='col-sm-4'>
                            <Select 
                                options={filterOption}
                                onChange={setFilter}
                            />
                        </div>
                        <div className='col-sm-3'>                    
                            <Select 
                                options={numericOperator}
                                onChange={setOperator}
                            />
                        </div>
                        <div className='col-sm-3'>                    
                            <input 
                                type='text'
                                className='form-control'
                                onChange={(e) => setCurrentValue(e.target.value)}
                            />
                        </div>
                        <div className='col-sm-2'>
                            <button className="btn btn-success" onClick={addFilter}>+</button>
                        </div>
                    </div>
                    <div className='col-sm-3'>    
                    <br />  
                        <button className="btn btn-primary" onClick={saveReport}>   
                            <FontAwesomeIcon icon={faPlay} />&nbsp;Run
                        </button>
                    </div>                    
                </div>
            }   
                                     
        </div>
        </Layout>
    )
}

export default NewReport