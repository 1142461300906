import React, { useState, useEffect } from 'react'
import { toCurrency } from '../utils/Convert'

const JobItemCompany = props => {

    return (
        <tr className='small' >    
            <td>{props.job.name}</td>                                   
            <td>{props.job.customer.address}</td>
            <td>
                {props.job.shipAddress} {props.job.shipCity} {props.job.shipState} {props.job.shipZip}
            </td>
            <td>
                { props.job.loadYD3 }
            </td>
            <td>{props.job.targetYD3}</td>
            <td>{new Date(props.job.scheduledDate).toLocaleDateString()}</td>
            <td>{props.job.scheduledTime}</td>
            <td>{props.job.destinationMinutes}</td>
            <td style={{ textAlign: "right" }}>
                { toCurrency(props.job.unitPrice, 0) }
            </td>                    
        </tr>    
    )
}

export default JobItemCompany