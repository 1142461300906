import React, { useState, useEffect } from 'react'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'
import axios from 'axios'
import {
    Line,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    Legend,
    Tooltip
} from 'recharts'
import { toThousands, toThousandsWkS } from '../utils/Convert'
import { CSVLink } from "react-csv"
import Layout from '../layout/Layout'
import Modal from 'react-bootstrap/Modal'

const ReportCustomerSales = () => {
    const [loading, setLoading] = useState(true);
    const [previousYear, setPreviousYear] = useState(new Date().getFullYear() - 1);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [chartData, setChartData] = useState([]);
    const [customerSales, setCustomerSales] = useState([]);
    const [namedChartData, setNamedChartData] = useState([]);
    const [loadModal, setLoadModal] = useState(false)
    const [chartDataModal, setChartDataModal] = useState([])
    const [nameModal, setNameModal] = useState('Undefined')


    const headers = [
        { key: 'name', label: 'Customer' },
        { key: 'prevYear', label: previousYear },
        { key: 'currYear', label: currentYear }
    ]
    const headers2 = [
        { key: 'month', label: 'Month' },
        { key: 'yards', label: 'Yards' }
    ]

    useEffect(() => {
        setLoading(true)
        getSalesData()
    }, [])

    useEffect(() => {
        if (chartData) {
            nameSet()
        } else {
            setLoading(true)
        }
    }, [chartData])


    const nameSet = async () => {
        setLoading(true)
        await Promise.all(chartData.map(async (customer) => {
            const dataCustomer = await getCustomerNames(customer)
            customer.name = dataCustomer
            return customer
        })).then((data) => {
            setNamedChartData(data)
            setLoading(false)
        })

    }


    const getSalesData = async () => {
        setLoading(true)
        const url = api + `/jobgroupedcustomer?startDate=${previousYear}-01-01&endDate=${previousYear}-12-31`
        const url2 = api + `/jobgroupedcustomer?startDate=${currentYear}-01-01&endDate=${currentYear}-12-31`
        const url3 = api + `/jobgrpcustomer?startDate=${currentYear}-01-01&endDate=${currentYear}-12-31`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const req1 = axios.get(url)
        const req2 = axios.get(url2)
        const req3 = axios.get(url3)


        axios.all([req1, req2, req3]).then(axios.spread((...responses) => {
            const { data } = responses[0]
            const previous = data
            let res = responses[1]
            let res2 = responses[2]
            const current = res.data
            const sortedPrev = sortCustomers(previous)
            const sortedCurr = sortCustomers(current).slice(0, 20)
            const chartDataArr = reduceCustomers(sortedCurr, sortedPrev)
            setChartData(chartDataArr)
            setCustomerSales(res2.data)


        }))
            .catch(errors => {
                console.log(errors)
                return null
            })
    }

    const getCustomerNames = async (customer) => {
        const url = api + `/customer/${customer.name}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.name
        } catch (err) {
            console.log(err)
            return null
        }
    }

    const reduceCustomers = (sortedCurr, sortedPrev) => {
        let comparedClientArr = []
        sortedCurr.map((customer) => {
            const comparedClient = sortedPrev
                .find((e) => e._id.customer === customer._id.customer)
            if (typeof comparedClient === "undefined") {
                let customerObj = {
                    name: customer._id.customer,
                    prevYear: 0,
                    currYear: customer.yards,
                    _id: customer._id.customer
                }
                comparedClientArr.push(customerObj)
            } else {
                let customerObj = {
                    name: customer._id.customer,
                    prevYear: comparedClient.yards,
                    currYear: customer.yards,
                    _id: customer._id.customer
                }
                comparedClientArr.push(customerObj)
            }


        })

        return (comparedClientArr)

    }

    const sortCustomers = (data) => {

        const sorted = data.sort((a, b) => {
            if (a.yards === b.yards) {
                // If two elements have same number, then the one who has larger rating.average wins
                return b.jobs - a.jobs;
            } else {
                // If two elements have different number, then the one who has larger number wins
                return b.yards - a.yards;
            }
        });

        return sorted
    }

    const toggleModal = (e) => {
        if (loadModal) {
            setLoadModal(false);
        } else {
            const customerSale = customerSales
                .filter((customer) => customer._id.customer === e._id)
                .sort((a, b) => a._id.month - b._id.month)
                .map((customer) => Object.assign({}, customer, { month: changeMonthtoName(customer._id.month), name: e.name }))

            setNameModal(e.name)
            setChartDataModal(customerSale)

            setLoadModal(true);
        }
    }

    const changeMonthtoName = (month) => {
        switch (month) {
            case 1:
                month = "JAN"
                break;
            case 2:
                month = "FEB"
                break;
            case 3:
                month = "MAR"
                break;
            case 4:
                month = "APR"
                break;
            case 5:
                month = "MAY"
                break;
            case 6:
                month = "JUN"
                break;
            case 7:
                month = "JUL"
                break;
            case 8:
                month = "AUG"
                break;
            case 9:
                month = "SEP"
                break;
            case 10:
                month = "OCT"
                break;
            case 11:
                month = "NOV"
                break;
            case 12:
                month = "DEC"
                break;

            default:
                break;
        }
        return month
    }

    const TopCustomers = () => {
        return (
            <div>
                <Modal size='lg' show={loadModal} animation={false} onHide={toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{nameModal} {currentYear} sales </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CSVLink data={chartDataModal} headers={headers2} filename={`57concrete-${nameModal}-${currentYear}-report.csv`}>
                            Download report
                        </CSVLink>
                        <BarChart width={600} height={500} data={chartDataModal} >
                            <CartesianGrid />
                            <XAxis
                                type="category"
                                dataKey="month"
                            />
                            <YAxis
                                type="number"
                                width={250}
                                height={500}
                            />
                            <Tooltip />
                            <Legend />
                            <Bar name="yards" dataKey="yards" stackId="a" fill="#72ca5d" />
                        </BarChart>

                    </Modal.Body>
                </Modal>
                <BarChart width={1000} height={800} data={namedChartData} layout="vertical" onClick={(data) => toggleModal(data.activePayload[1].payload)} >
                    <CartesianGrid />
                    <XAxis type="number" />
                    <YAxis
                        dataKey="name"
                        type="category"
                        width={250}
                        height={500}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar name={currentYear} dataKey="currYear" stackId="a" fill="#72ca5d" />
                    <Bar name={previousYear} dataKey="prevYear" stackId="a" fill="#8884d8" />
                </BarChart>
            </div>
        )
    }

    return (
        <div>
            <Layout
                title='Report Customer Sales'
                description='Top 20 customer sales'
                className='container'
            >

                {
                    loading ? <Spinner /> : <TopCustomers />
                }
                <CSVLink data={namedChartData} headers={headers} filename={"57concrete-top20customers-report.csv"}>
                    Download report
                </CSVLink>
            </Layout>
        </div>
    )
}


export default ReportCustomerSales