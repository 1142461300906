import React, { useEffect, useRef, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Tag, Tooltip, Typography, notification } from 'antd'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import { priorityEnglishOptions, statusConstants, categoryEnglishOptions } from '../../constants/workOrders'
import {
    CloseOutlined,
    CloudUploadOutlined,
    PlayCircleFilled,
    PauseCircleFilled,
    CheckOutlined,
    FilePdfOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { api } from '../utils/Api'
import axios from 'axios'
const { Text } = Typography
const { TextArea } = Input
const dateFormat = 'MM/DD/YYYY'
const dateTimeFormat = 'MM/DD/YYYY HH:mm'

/**
 ** Options header (columns)
*/
const columns = [
    {
        title: 'Modified Date',
        dataIndex: 'date',
        key: 'date',
        render: (value) => {
            return <Text strong>{value}</Text>
        }
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Original Status',
        dataIndex: 'originalStatus',
        key: 'originalStatus',
        render: (value) => {
            let color = 'default'
            if(value === 'Approved'){
                color = 'green'
            }else if(value === 'Overdue'){
                color = 'red'
            }else if(value === 'Cancelled'){
                color = 'default'
            }else if(value === 'In Progress'){
                color = 'magenta'
            }else if(value === 'On Hold'){
                color = 'cyan'
            }else if(value === 'Pending'){
                color = 'gold'
            }else if(value === 'Reopened'){
                color = 'lime'
            }else if(value === 'Rejected'){
                color = 'orange'
            }else if(value === 'Finished'){
                color = 'blue'
            }else if(value === 'Closed'){
                color = 'geekblue'
            }

            return <Tag color={color}>{value}</Tag>
        }
    },
    {
        title: 'Actual Status',
        dataIndex: 'actualStatus',
        key: 'actualStatus',
        render: (value) => {
            let color = 'default'
            if(value === 'Approved'){
                color = 'green'
            }else if(value === 'Overdue'){
                color = 'red'
            }else if(value === 'Cancelled'){
                color = 'default'
            }else if(value === 'In Progress'){
                color = 'magenta'
            }else if(value === 'On Hold'){
                color = 'cyan'
            }else if(value === 'Pending'){
                color = 'gold'
            }else if(value === 'Reopened'){
                color = 'lime'
            }else if(value === 'Rejected'){
                color = 'orange'
            }else if(value === 'Finished'){
                color = 'blue'
            }else if(value === 'Closed'){
                color = 'geekblue'
            }

            return <Tag color={color}>{value}</Tag>
        }
    },
]

const WorkOrder = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState ({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [language, setLanguage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(true)
    const [status, setStatus] = useState('')
    const [truck, setTruck] = useState('')
    const [history, setHistory] = useState([])
    const [optionsMechanics, setOptionsMechanics] = useState([])
    const [optionsInventory, setOptionsInventory] = useState([])
    const [inventoryList, setInventoryList] = useState([])
    const refButton = useRef()
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getMechanicList()
        getWO()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getWO = async() => {
        setLoading(true)

        const url = api + `/wo/${props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setStatus(data.status)
            setTruck(data.inspection.truck ? data.inspection.truck.number : 'None')
            let listHistory = []
            data.history.map((item, index)=> {
                listHistory.push({
                    key: index,
                    date: dayjs(item.createdAt).format(dateTimeFormat),
                    username: item.user ? item.user.name : '',
                    details: item.details,
                    originalStatus: item.originalStatus ? item.originalStatus : '',
                    actualStatus: item.actualStatus ? item.actualStatus : '',
                })
            })
            setHistory(listHistory)

            let invoice = []
            if(data.invoiceClient) invoice.push('Client')
            if(data.invoiceMaintenance) invoice.push('Maintenance')

            let mechanics = []
            data.mechanics.map((mechanic) => {
                mechanics.push(mechanic._id)
            })

            let inventories = []
            let inventoryList2 = await getInventoryList()
            if(data.inventories){
                data.inventories.map((inventory) => {
                    inventories.push({
                        inventory: inventory.inventory,
                        quantity: inventory.quantity,
                        quantityEnable: inventoryList2.find(o => o.value == inventory.inventory).quantity
                    })
                    let index = inventoryList2.findIndex(o => o.value == inventory.inventory)
                    if(index >= 0){
                        inventoryList2[index].disabled = true
                    }else{
                        inventoryList2[index].disabled = false
                    }
                })
            }
            setOptionsInventory(inventoryList2)

            setInitialValues({
                ...initialValues,
                number: data.number,
                status: data.status,
                category: data.category ? data.category : '',
                priority: data.priority ? data.priority : '',
                description: data.description,
                estimatedHours: data.estimatedHours ? data.estimatedHours : 0,
                invoice: invoice,
                startDate: data.startDate ? dayjs(data.startDate) : '',
                dueDate: data.dueDate ? dayjs(data.dueDate) : '',
                finishedDate: data.finishedDate ? dayjs(data.finishedDate) : '',
                closedDate: data.closedDate ? dayjs(data.closedDate) : '',
                mechanics: mechanics,
                createdBy: 'System',
                inventories: inventories
            })
            
            setInventoryList(inventories)
            setLoading(false)
            setLoadingButton(false)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining the data')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const getMechanicList = async() => {
        const url = api + `/userlist/Mechanic`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let mechanics = []
            data.map((mechanic) => {
                mechanics.push({
                    label: mechanic.name,
                    value: mechanic._id,
                })
            })
            setOptionsMechanics(mechanics)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getInventoryList = async() => {

        const url = api + `/inventorylist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let inventories = []
            data.map((inventory) => {
                inventories.push({
                    label: inventory.name,
                    value: inventory._id,
                    disabled: inventory.quantity === 0 || inventory.type === 'Out Of Stock',
                    quantity: inventory.quantity
                })
            })
            return inventories
        } catch (err) {
            console.error(err.message)
            return []
        }
    }

    const updateWO = async(type, customBody, status) => {
        const url = api + `/wo/${props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoadingButton(true)
            let newBody = {}

            if(type === 'status'){
                newBody['history'] = customBody
                newBody['status'] = status
            }else if(type === 'comment'){
                newBody['history'] = customBody
            }else{
                if(customBody.inventories){
                    let newInventories = customBody.inventories.map((inventory2) => {
                        if(inventoryList.find(({ inventory, quantity }) => inventory == inventory2.inventory && quantity == inventory2.quantity)){
                            inventory2.exist = true
                            inventory2.same = true
                        }else if(inventoryList.find(({ inventory, quantity }) => inventory == inventory2.inventory && quantity != inventory2.quantity)){
                            inventory2.exist = true
                            inventory2.same = false
                        }else if(inventoryList.find(({ inventory }) => inventory !== inventory2.inventory)){
                            inventory2.exist = false
                            inventory2.same = false
                        }else{
                            inventory2.exist = false
                            inventory2.same = false
                        }
                        return inventory2
                    })
                    newBody.inventories = newInventories
                }

                newBody.category= customBody['category']
                newBody.priority= customBody['priority']
                newBody.description= customBody['description']
                newBody.invoiceClient= customBody['invoice'].includes('Client')
                newBody.invoiceMaintenance= customBody['invoice'].includes('Maintenance')
                newBody.mechanics= customBody['mechanics']
            }

            newBody['updatedBy'] = localStorage.getItem('userId')

            const { data } = await axios.patch(url, newBody)

            if(type === 'status'){
                openNotification('success', 'Success', 'Status updated succesfully')
            }else if(type === 'comment'){
                openNotification('success', 'Success', 'Comment added succesfully')
            }else{
                openNotification('success', 'Success', 'Work order updated succesfully')
            }

            if(refButton.current === 'goBack'){
                props.history.goBack()
            }else if(refButton.current === 'stay'){
                let listHistory = []
                data.history.map((item, index)=> {
                    if(index === 0){
                        listHistory.push({
                            key: index,
                            date: dayjs(item.createdAt).format(dateTimeFormat),
                            username: localStorage.getItem('userName'),
                            details: item.details,
                            originalStatus: item.originalStatus ? item.originalStatus : '',
                            actualStatus: item.actualStatus ? item.actualStatus : '',
                        })
                    }else{
                        listHistory.push({
                            key: index,
                            date: dayjs(item.createdAt).format(dateTimeFormat),
                            username: item.user ? item.user.name : '',
                            details: item.details,
                            originalStatus: item.originalStatus ? item.originalStatus : '',
                            actualStatus: item.actualStatus ? item.actualStatus : '',
                        })
                    }
                })

                let invoice = []
                if(data.invoiceClient) invoice.push('Client')
                if(data.invoiceMaintenance) invoice.push('Maintenance')

                let inventories = []
                let inventoryList2 = await getInventoryList()
                if(data.inventories){
                    data.inventories.map((inventory) => {
                        inventories.push({
                            inventory: inventory.inventory,
                            quantity: inventory.quantity,
                            quantityEnable: inventoryList2.find(o => o.value == inventory.inventory).quantity
                        })
                        let index = inventoryList2.findIndex(o => o.value == inventory.inventory)
                        if(index >= 0){
                            inventoryList2[index].disabled = true
                        }else{
                            inventoryList2[index].disabled = false
                        }
                    })
                }
                form.setFieldValue('inventories', inventories)

                setInitialValues({
                    ...initialValues,
                    startDate: data.startDate ? dayjs(data.startDate) : '',
                    dueDate: data.dueDate ? dayjs(data.dueDate) : '',
                    finishedDate: data.finishedDate ? dayjs(data.finishedDate) : '',
                    closedDate: data.closedDate ? dayjs(data.closedDate) : '',
                    estimatedHours: data.estimatedHours ? data.estimatedHours : 0,
                    descrption: data.descrption ? data.descrption : '',
                    category: data.category ? data.category : '',
                    priority: data.priority ? data.priority : '',
                    invoice: invoice,
                    mechanics: data.mechanics,
                })
                setInventoryList(inventories)
                setOptionsInventory(inventoryList2)
                setHistory(listHistory)
                setLoadingButton(false)
            }else{
                let listHistory = []
                data.history.map((item, index)=> {
                    if(index === 0){
                        listHistory.push({
                            key: index,
                            date: dayjs(item.createdAt).format(dateTimeFormat),
                            username: localStorage.getItem('userName'),
                            details: item.details,
                            originalStatus: item.originalStatus ? item.originalStatus : '',
                            actualStatus: item.actualStatus ? item.actualStatus : '',
                        })
                    }else{
                        listHistory.push({
                            key: index,
                            date: dayjs(item.createdAt).format(dateTimeFormat),
                            username: item.user ? item.user.name : '',
                            details: item.details,
                            originalStatus: item.originalStatus ? item.originalStatus : '',
                            actualStatus: item.actualStatus ? item.actualStatus : '',
                        })
                    }
                })

                let invoice = []
                if(data.invoiceClient) invoice.push('Client')
                if(data.invoiceMaintenance) invoice.push('Maintenance')
                setHistory(listHistory)
                setInitialValues({
                    ...initialValues,
                    startDate: data.startDate ? dayjs(data.startDate) : '',
                    dueDate: data.dueDate ? dayjs(data.dueDate) : '',
                    finishedDate: data.finishedDate ? dayjs(data.finishedDate) : '',
                    closedDate: data.closedDate ? dayjs(data.closedDate) : '',
                    estimatedHours: data.estimatedHours ? data.estimatedHours : 0,
                    descrption: data.descrption ? data.descrption : '',
                    category: data.category ? data.category : '',
                    priority: data.priority ? data.priority : '',
                    invoice: invoice
                })
                setLoadingButton(false)
            }

        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong at updating')
            console.error(err.message)
            return null
        }
    }
    
    
    const showModal = () => {
        setIsModalOpen(true)
    }

    const hideModal = () => {
        setIsModalOpen(false)
    }

    const getDocumentPdf = () => {
        props.history.push(`/workorderpdf/${props.match.params.id}`)
    }

    // reset form fields when modal is form, closed
    const useResetFormOnCloseModal = ({ form, open }) => {
        const prevOpenRef = useRef()
        useEffect(() => {
            prevOpenRef.current = open;
        }, [open])

        const prevOpen = prevOpenRef.current
        useEffect(() => {
            if (!open && prevOpen) {
                form.resetFields();
            }
        }, [form, prevOpen, open])
    }

    const ModalForm = ({ title, open, onCancel }) => {
        const [form] = Form.useForm()

        useResetFormOnCloseModal({
            form,
            open,
        })

        const onOk = () => {
            form.submit()
        }

        return (
        <Modal title={title} open={open} onOk={onOk} onCancel={onCancel}>
            <Form form={form} layout="vertical" name="commentForm">
                <Form.Item
                    name="comment"
                    label="Comment"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required'
                        }
                    ]}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
        )
    }

    const addNewStatusHistory = (value) => {
        const newHistory = {
            originalStatus: status,
            actualStatus: value,
            details: 'Status Change',
            user: localStorage.getItem('userId'),
        }
        updateWO('status', newHistory, value);
        setStatus(value)
    }
    const addNewCommentHistory = (comment) => {
        const newHistory = {
            originalStatus: status,
            actualStatus: status,
            details: comment,
            user: localStorage.getItem('userId'),
        }
        updateWO('comment', newHistory);
    }

    const onChangeStatus = (value) => {
        addNewStatusHistory(value)
    }

    const CustomStatus = () => {
        let color = 'default'
        let text = 'None'
        if(status === 'Approved'){
            color = 'green'
            text = statusConstants['approved'][language]
        }else if(status === 'Overdue'){
            color = 'red'
            text = statusConstants['overdue'][language]
        }else if(status === 'Cancelled'){
            color = 'default'
            text = statusConstants['cancelled'][language]
        }else if(status === 'In Progress'){
            color = 'magenta'
            text = statusConstants['inProgress'][language]
        }else if(status === 'On Hold'){
            color = 'cyan'
            text = statusConstants['onHold'][language]
        }else if(status === 'Pending'){
            color = 'gold'
            text = statusConstants['pending'][language]
        }else if(status === 'Reopened'){
            color = 'lime'
            text = statusConstants['reopened'][language]
        }else if(status === 'Rejected'){
            color = 'orange'
            text = statusConstants['rejected'][language]
        }else if(status === 'Finished'){
            color = 'blue'
            text = statusConstants['finished'][language]
        }else if(status === 'Closed'){
            color = 'geekblue'
            text = statusConstants['closed'][language]
        }

        return <Col><Tag color={color} style={{width: '100%', textAlign:'center', padding: '5px 10px'}}>{text}</Tag></Col>
    }

    const CustomButtons = () => {
        if(status === 'Approved'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Cancel Work Order'><Button type='primary' danger icon={<CloseOutlined />} onClick={e => onChangeStatus('Cancelled')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Finish Work Order'><Button type='primary' style={{backgroundColor:'blue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Finished')}/></Tooltip>
                </Col>
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Close Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Closed')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Set Work Order In Progress'><Button type='primary' style={{backgroundColor:'lightgreen'}} icon={<PlayCircleFilled />} onClick={e => onChangeStatus('In Progress')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Place Work Order On Hold'><Button type='primary' style={{backgroundColor:'orange'}} icon={<PauseCircleFilled />} onClick={e => onChangeStatus('On Hold')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'Cancelled'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Reopen Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Reopened')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'Pending'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Approve Work Order'><Button type='primary' style={{backgroundColor:'lightgreen'}} danger icon={<CheckOutlined />} onClick={e => onChangeStatus('Approved')}/></Tooltip>
                    </Col>
                }
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Cancel Work Order'><Button type='primary' danger icon={<CloseOutlined />} onClick={e => onChangeStatus('Cancelled')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'Finished'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Close Work Order'><Button type='primary' style={{backgroundColor:'blue'}} icon={<CloudUploadOutlined />}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Reopen Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Reopened')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'Overdue'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Cancel Work Order'><Button type='primary' danger icon={<CloseOutlined />} onClick={e => onChangeStatus('Cancelled')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Finish Work Order'><Button type='primary' style={{backgroundColor:'blue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Finished')}/></Tooltip>
                </Col>
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Close Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Closed')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Place Work Order On Hold'><Button type='primary' style={{backgroundColor:'orange'}} icon={<PauseCircleFilled />} onClick={e => onChangeStatus('On Hold')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'In Progress'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Cancel Work Order'><Button type='primary' danger icon={<CloseOutlined />} onClick={e => onChangeStatus('Cancelled')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Finish Work Order'><Button type='primary' style={{backgroundColor:'blue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Finished')}/></Tooltip>
                </Col>
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Close Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Closed')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Place Work Order On Hold'><Button type='primary' style={{backgroundColor:'orange'}} icon={<PauseCircleFilled />} onClick={e => onChangeStatus('On Hold')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'On Hold'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Close Work Order'><Button type='primary' style={{backgroundColor:'blue'}} icon={<CloudUploadOutlined />}/></Tooltip>
                    </Col>
                }   
                <Col>
                    <Tooltip title='Reopen Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Reopened')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'Reopened'){
            return <Row gutter={16}>
                <CustomStatus />
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Cancel Work Order'><Button type='primary' danger icon={<CloseOutlined />} onClick={e => onChangeStatus('Cancelled')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Finish Work Order'><Button type='primary' style={{backgroundColor:'blue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Finished')}/></Tooltip>
                </Col>
                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Col>
                        <Tooltip title='Close Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Closed')}/></Tooltip>
                    </Col>
                }
                <Col>
                    <Tooltip title='Set Work Order In Progress'><Button type='primary' style={{backgroundColor:'lightgreen'}} icon={<PlayCircleFilled />} onClick={e => onChangeStatus('In Progress')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Place Work Order On Hold'><Button type='primary' style={{backgroundColor:'orange'}} icon={<PauseCircleFilled />} onClick={e => onChangeStatus('On Hold')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }else if(status === 'Closed'){
            return <Row gutter={16}>
                <CustomStatus />
                <Col>
                    <Tooltip title='Reopen Work Order'><Button type='primary' style={{backgroundColor:'lightblue'}} icon={<CloudUploadOutlined />} onClick={e => onChangeStatus('Reopened')}/></Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Add comment'><Button type='primary' onClick={showModal}>Add Comment</Button></Tooltip>
                </Col>
            </Row>
        }
        return <div></div>
    }

    const onChange = (value) => {
        const inventories = form.getFieldValue('inventories')
        const updatedInventories = inventories.map(inventory => {
            if (inventory !== undefined && inventory.inventory === value) {
                return {
                ...inventory,
                quantityEnable: optionsInventory.find(o => o.value === value).quantity
                }
            }
            return inventory
        })
        form.setFieldsValue({ inventories: updatedInventories })

        let options = optionsInventory
        let index = optionsInventory.findIndex(obj => obj.value === value)
        options[index].disabled = true
        options.map((item) => {
            if(updatedInventories.find(o => o.inventory === item.value)){
                item.disabled = true
            }else{
                item.disabled = false
            }
        })
        setOptionsInventory(options)
    }

    const onRemoveItem = (removeFunction, name) => {
        let inventories = form.getFieldValue('inventories')
        if(inventories[name] === undefined){
            removeFunction(name)
        }else if(!inventories[name].inventory){
            removeFunction(name)
        }else{
            let inventory = inventories.at(name).inventory
            let options = optionsInventory
            let index = optionsInventory.findIndex(obj => obj.value === inventory)
            options[index].disabled = false
            setOptionsInventory(options)
            removeFunction(name)
        }
    }

    const info = (items) => {
        Modal.info({
          title: 'Inventory exceed quantity enabled',
          content: (
            <Row gutter={8}>
                {
                    items.map((item) => (
                        <Col><Text>{`Item ${optionsInventory.find(o => o.value === item.inventory).label} exceed the quantity of ${item.quantity - item.quantityEnable}`}</Text></Col>
                    ))
                }
            </Row>
          ),
          onOk() {}
        })
    }

    const validateInventory = (values) => {
        let items = []
        if(values.inventories.length > 0){
            values.inventories.map((inventory) => {
                if(inventory.quantity > inventory.quantityEnable){
                    items.push(inventory)
                }
            })

            if(items.length > 0){
                info(items)
            }else{
                //update
                updateWO('submit', values)
            }
        }else{
            //update
            updateWO('submit', values)
        }
    }

    const onFormFinish = (name, {values}) => {
        if (name === 'commentForm') {
            addNewCommentHistory(values.comment)
            setIsModalOpen(false);
        }
        if (name === 'basicForm') {
            validateInventory(values)
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form.Provider
        onFormFinish={onFormFinish}
        >
            <Form
            layout='vertical'
            name='basicForm'
            form={form}
            initialValues={
                initialValues
            }
            >
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                        label='ID'
                        name='number'
                        >
                            <Input size='large' disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Category'
                        name='category'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a category'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select category`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={categoryEnglishOptions}
                            disabled={localStorage.getItem('role') === 'Mechanic'}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Priority'
                        name='priority'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a priority'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select priority`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={priorityEnglishOptions}
                            disabled={localStorage.getItem('role') === 'Mechanic'}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Text>Status</Text>
                    </Col>
                    <Col span={24}>
                        <CustomButtons />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Description'
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: 'Please fill a description',
                            }
                        ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label= 'Start Date'
                        name='startDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label= 'Due Date'
                        name='dueDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label= 'Finished Date'
                        name='finishedDate'
                        >
                            <DatePicker 
                            format={dateTimeFormat}
                            size='large'
                            showTime
                            disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label= 'Closed Date'
                        name='closedDate'
                        >
                            <DatePicker 
                            format={dateTimeFormat}
                            size='large'
                            showTime
                            disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label= 'Total Estimated Hours'
                        name='estimatedHours'
                        >
                            <Input size='large' disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label= 'Created By'
                        name='createdBy'
                        >
                            <Input size='large' disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="invoice" label="Invoice">
                            <Checkbox.Group>
                                <Row>
                                    <Col span={12}>
                                        <Checkbox
                                        value="Client"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={localStorage.getItem('role') === 'Mechanic'}
                                        >
                                            Client
                                        </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox
                                        value="Maintenance"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={localStorage.getItem('role') === 'Mechanic'}
                                        >
                                            Maintenance
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label= 'Active'
                        name='truck'
                        >
                            <Tag color='gold'>{`Truck ${truck}`} </Tag>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label= 'Mechanics'
                        name='mechanics'
                        >
                            <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            size='large'
                            placeholder="Please select mechanics"
                            options={optionsMechanics}
                            disabled={localStorage.getItem('role') === 'Mechanic'}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Text>Assign Inventory Items</Text>
                    </Col>
                    <Col span={24}>
                        <Form.List name="inventories">
                            {(fields, { add, remove }) => (
                                <>
                                {
                                fields.map(({ key, name, ...restField }) => (
                                    <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                    >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'inventory']}
                                        label='Inventory'
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Missing inventory item',
                                        },
                                        ]}
                                    >
                                        <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        size='large'
                                        placeholder="Please select inventory"
                                        options={optionsInventory}
                                        onChange={e => onChange(e,name)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'quantity']}
                                        label='Quantity'
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Missing quantity',
                                        },
                                        ]}
                                    >
                                        <InputNumber 
                                        size='large' 
                                        style={{width: '100%'}} 
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'quantityEnable']}
                                        label='Quantity Enable'
                                    >
                                        <InputNumber 
                                        disabled
                                        size='large' 
                                        style={{width: '100%'}} 
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => {onRemoveItem(remove,name)}} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add inventory
                                    </Button>
                                </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                

                {
                    localStorage.getItem('role') !== 'Mechanic' &&
                    <Row gutter={16}>
                        <Col span={24}>
                            <Text>Document PDF</Text>
                        </Col>
                        <Col span={24}>
                            <Button htmlType='button' type='primary' icon={<FilePdfOutlined />} onClick={getDocumentPdf} />
                        </Col>
                    </Row>
                }
                <Row gutter={16}>
                    <Col span={24}>
                        <Text>Status History</Text>
                    </Col>
                    <Col span={24}>
                        <CustomTable 
                        columns={columns}
                        data={history}
                        />
                    </Col>
                </Row>
                <Row gutter={18} style={{marginTop: '20px'}}>
                    <Col>
                        <Button htmlType="submit" type="primary" name='goBack' loading={loadingButton} onClick={e => refButton.current = 'goBack'}>
                            Save and Go Back
                        </Button>
                    </Col>
                    <Col>
                        <Button htmlType="submit" type="primary" name='stay' loading={loadingButton} onClick={e => refButton.current = 'stay'}>
                            Save and Stay
                        </Button>
                    </Col>
                </Row>
                
            </Form>
            <ModalForm 
            title="Add comments to status detail history" 
            open={isModalOpen} 
            onCancel={hideModal} 
            />
        </Form.Provider>
    }


    return (
        <Layout
        title='Work Order'
        description='Edit work order'
        type='medium'
        >
            {contextHolder}
            {
                loading ? <CustomSpinner/> : <CustomForm/>
            } 
        </Layout>
    )
}

export default WorkOrder