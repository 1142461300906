import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import { toCurrency } from '../utils/Convert'
import DatePicker from 'react-datepicker'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { api } from '../utils/Api'
import SendReport from './SendReport'

const PaymentReport = () => {
    const [dateReport, setDateReport] = useState(new Date())
    const [pagos, setPagos] = useState(0)
    const [sumaCon, setSumaCon] = useState(0)
    const [change, setChange] = useState(false)
    const [payments, setPayments] = useState([])
    const [spin, setSpin] = useState(true)
    const [report, setReport] = useState(null)
    const [email, setEmail] = useState('')
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        console.log('primer efecto')
        paymentsAtDate(dateReport)
    }, [dateReport])

    useEffect(() => {
        console.log('segundo efecto')
        if (change) {
            paymentsAtDate(dateReport)
        }
    }, [change])

    const handleInputChange = (e) => {
        setEmail({
            ...email,
            [e.target.name]: e.target.value
        })
    }

    const createReport = () => {
        const restaCon = pagos - sumaCon

        const rep = {
            date: dateReport,
            pagos: pagos,
            sumaCon: sumaCon,
            restaCon: restaCon
        }
        setReport(rep)
        setShowModal(!showModal)

        return report
    }
    const calculateTotal = (pago) => {
        const yards = pago.job.loadYD3 || (typeof pago.job.targetYD3 === "string" ? parseFloat(pago.job.targetYD3) : pago.job.targetYD3)
        const material = pago.job.unitPrice * yards
        const trips = Math.ceil(yards / 10)
        const fuel = pago.job.fuelSurcharge * trips
        const otherChanges = pago.job.otherCharges || 0
        const deliveryFee = pago.job.deliveryFee || 0
        const subtotal = material + fuel + otherChanges + deliveryFee
        const taxas = subtotal * 0.0825
        const total = subtotal + taxas
        console.log(`yardas: ${yards}, material: ${material}, subtotal:${subtotal}, taxas:${taxas}, total: ${total}`)
        return total
    }

    // const sendEmail = async (email) => {
    //     setSpin(true)
    //     const url = api + '/paymentreport/' + email
    //     const token = localStorage.getItem('token')
    //     axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

    //     const restaCon = pagos - sumaCon

    //     const rep = {
    //         date: dateReport,
    //         pagos: pagos,
    //         sumaCon: sumaCon,
    //         restaCon: restaCon
    //     }
    //     setReport(rep)
    //     try {
    //         await axios.post(url, report)
    //         paymentsAtDate(dateReport)
    //         setSpin(false)
    //     } catch (err) {
    //         console.error(err)
    //         return null
    //     }
    // }

    const toggleModal = () => {
        setShowModal(!showModal)
    }
    const paymentsAtDate = async (date) => {
        setSpin(true)
        const url = api + '/paymentat/' + date
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setPayments(data)
            console.log(data)
            const suma = data.reduce((acc, pago) => acc + pago.amount, 0)
            const validado = data.filter(pago => pago.received === true).reduce((acc, pay) => acc + pay.amount, 0)
            setPagos(suma)
            setSumaCon(validado)
            setSpin(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const updatePayment = async (id, status) => {
        const url = api + `/payment/${id}`

        const cambia = {
            received: !status
        }

        try {
            await axios.patch(url, cambia)
            setChange(true)
            setSpin(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const received = (id) => {
        setSpin(true)
        setChange(false)
        console.log("Recibido: " + change);
        const pago = payments.filter(pay => pay._id === id)
        console.log("pago: " + pago[0].received)
        const recibido = pago[0].received
        updatePayment(id, recibido)
    }

    const DrawList = () => {
        let jobNo = 0
        let saldo = 0
        let suma = 0
        let sumaCon = 0
        return payments.map((pago) => {
            const total = calculateTotal(pago)
            // console.log(`job anterior: ${jobNo}, nuevo: ${pago.job.number}`)

            if (jobNo === pago.job.number) {
                saldo = saldo - pago.amount
            } else {
                saldo = total - pago.amount
            }

            jobNo = pago.job.number

            return <tr key={pago._id}>
                <td>
                    <Link to={`/job/${pago.job._id}`}>
                        {pago.job.number}
                    </Link>
                </td>
                <td>
                    {
                        new Date(pago.job.scheduledDate).toLocaleDateString() + ' at ' + new Date(pago.job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })
                    }
                </td>
                <td>
                    <Link to={`/clientsummary/${pago.job.customer._id}`}>
                        {pago.job.customer.customerNumber + ' ' + pago.job.customer.name}
                    </Link>
                </td>
                <td style={{ textAlign: "right" }}>{toCurrency(pago.job.jobTotal || total, 2)} </td>
                <td>{new Date(pago.at).toLocaleDateString()}</td>
                <td>
                    {pago.createdBy ? pago.createdBy.name : ''}
                </td>
                <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    {toCurrency(pago.amount, 2)}
                </td>
                <td>{pago.type}</td>
                <td style={{ textAlign: "right" }}>{toCurrency(saldo, 2)}</td>
                <td>{pago.received ?
                    <input name='received'
                        type='checkbox'
                        checked={true}
                        onChange={() => received(pago._id)}

                    /> :

                    <input name='received'
                        type='checkbox'
                        onChange={() => received(pago._id)}

                    />}
                </td>
            </tr>
        })
    }

    const PaymentTable = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Job</th>
                        <th>Job Date/Time</th>
                        <th>Customer</th>
                        <th>Job Total</th>
                        <th>Payment date</th>
                        <th>By user</th>
                        <th>Payment amount</th>
                        <th>Payment type</th>
                        <th>Balance</th>
                        <th>Received</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        </div>
    )

    return (
        <Layout
            title='Payments report'
            description=''
        >
            <div className="form-group row">
                <div className='col-sm-3'>
                    <label className='text-muted'>Date</label>
                    <DatePicker
                        selected={dateReport}
                        onChange={(date) => setDateReport(date)}
                    />
                </div>
                <div className='col-sm-3'>
                    Payments: {toCurrency(pagos, 2)}
                </div>
                <div className='col-sm-3'>
                    Payments received: {toCurrency(sumaCon, 2)}
                </div>
                <div className='col-sm-3'>
                    Payments not received: {toCurrency(pagos - sumaCon, 2)}
                </div>
            </div>
            {/* <Link onClick={createReport}
                to='#'
                className="btn btn-primary"
            >
                Send report by email
            </Link>
            <Modal show={showModal} animation={false} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Invoice to:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SendReport
                        payload={report}
                        pcallback={toggleModal}
                    />
                </Modal.Body>
            </Modal> */}
            {
                spin ? <Spinner /> : <PaymentTable />
            }
        </Layout >
    )
}

export default PaymentReport