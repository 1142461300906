import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { createBuildDevelopment } from '../../actions/buildDevelopment'


const NewBuildDevelopment = ({ createBuildDevelopment, history }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const onSubmit = e => {
        e.preventDefault()

        const formData = {
            name,
            description
        }

        createBuildDevelopment(formData)
        history.push('/builddevelopment')
    }

    const BuildForm = () => (
        <form className='form'>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Name</label>
                    <input
                        onChange={e => setName(e.target.value)}
                        type="text"
                        name='name'
                        className="form-control"
                        value={name}
                        placeholder="name"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Description</label>
                    <input
                        onChange={e => setDescription(e.target.value)}
                        type="text"
                        name='description'
                        className='form-control'
                        value={description}
                        placeholder='description'
                        required
                    />
                </div>
            </div>
            <br />
            <div className="form-group row">
                <div class="col-6">
                    <button className='btn btn-primary' onClick={onSubmit}> Save </button>
                </div>
            </div>
        </form>
    )

    return (
        <Layout
            title='Build Development'
            description='New Build Development registration form'
            className='container col-md-8 offset-md-2'
        >
            {BuildForm()}
        </Layout>
    )
}

const mapStateToProps = state => ({
    user: state.auth.usuario
})

export default connect(mapStateToProps, { createBuildDevelopment })(NewBuildDevelopment)