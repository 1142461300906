import { Component } from "react";
import Layout from "../../layout/Layout";
import DepartmentPicker from "../department/DepartmentPicker";
import { api } from "../../utils/Api";
import axios from "axios";

export default class NewPosition extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            department:'',
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async (e) => {
        e.preventDefault();

        let body = {
            name: this.state.name
        }

        if(this.state.department !== '') body.department = this.state.department

        const url = api + `/position`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.post(url, body)
            this.props.history.push('/positions')
        } catch (err) {
            console.error(err.message)
            return null
        } 
    }

    // change department
    onChangeDepartment = selectedDepartment => {
        if (selectedDepartment) {
            this.setState({
                department: selectedDepartment.value,
            })
        }
    }

    RenderNewPosition = () => (
        <form onSubmit={this.onSubmit}> 
            <div className="form-group row">
                <div className="col-sm-6">
                    <label className="text-muted">Name</label>
                    <input
                    name = 'name'
                    type = 'text'
                    value = {this.state.name}
                    onChange={e => this.setState({name:e.target.value})}
                    placeholder="Name"
                    className="form-control"
                    required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Department</label>
                    <DepartmentPicker action={this.onChangeDepartment} value={this.state.department} />
                </div>
                &nbsp;
            </div>
            
            <button className="btn btn-success" type="submit">Add</button>
        </form>
    )

    render(){
        return <Layout
        title="Position"
        description="Create New Position"
        className={'container col-md-8 offset-md-2'}
        >
            {this.RenderNewPosition()}
        </Layout>
    }
}