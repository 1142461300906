import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'

const ReportList = props => {
    const [reportList, setReportList] = useState([])
    const [update, setUpdate] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getReportList()
    }, [update])

    // Get report list
    const getReportList = async () => {
        const url = api + '/reports'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            setLoading(false)
            //console.log(data)
            setReportList(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Delete report
    const deleteReport = async id => {
        const url = api + '/report/' + id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.delete(url)
            setUpdate(!update)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const DrawList = () => {
        if (reportList.length > 0) {
            return reportList.map((report) => {
                return <tr key={report._id} >
                    <td><Link to={`/reportjobrun/${report._id}`}> {report.name} </Link> </td>
                    <td>{report.description}</td>
                    <td>Job</td>
                    <td>{report.createdBy ? report.createdBy.name : "NA"}</td>
                    <td>
                        <button className='btn btn-danger' onClick={() => deleteReport(report._id)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </td>
                </tr>
            })
        } else {
            return (
                <tr>
                    <td>No reports yet</td>
                </tr>
            )
        }
    }

    return (
        <Layout
            title='Reports'
            description='Report List'
            className="container col-md-8 offset-md-2"
        >
            <table className='table'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Scope</th>
                        <th>Author</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? <Spinner /> : <DrawList />}
                </tbody>
            </table>
            <Link to='/newreport' className='btn btn-success'>+</Link>
        </Layout>
    )
}

export default ReportList