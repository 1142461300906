import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Row, Col, DatePicker } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
const { Text } = Typography

const LogisticsReportInspectionDriver = ({history}) => { 
    const [loading, setLoading] = useState(true)
    const [inspectionList, setInspectionList] = useState([])
    const [date, setDate] = useState(dayjs())

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'PRE',
            dataIndex: 'preDate',
            key: 'preDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'POST',
            dataIndex: 'postDate',
            key: 'postDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        }
    ]

    useEffect(() => {
        getDriverList()
    }, [date])

    const getDriverList = async() => {
        setLoading(true)

        const url = api + '/logisticsdriver/list'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            getInspectionList(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const getInspectionList = async(drivers) => {
        setLoading(true)
        let start = date.subtract(1, 'day')
        const startDate = `${start.year()}-${start.month() + 1}-${start.date()}`
        const endDate = `${date.year()}-${date.month() + 1}-${date.date()}`

        const url = api + `/inspections/logistics/date?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            drivers.map((driver) => {
                let dataDriver = data.filter((item) => item.logisticsDriver._id === driver._id)

                if(dataDriver.length > 0){
                    dataDriver.map((driverItem) => {
                        let newObjectList = {}
                        newObjectList.driver = driver.name.toUpperCase()
                        newObjectList.pre = driverItem._id
                        newObjectList.preDate = dayjs(driverItem.createdAt).format('MM/DD/YYYY HH:mm A')

                        if(driverItem.post){
                            newObjectList.post = driverItem.post._id
                            newObjectList.postDate = dayjs(driverItem.post.createdAt).format('MM/DD/YYYY HH:mm A')
                        }else{
                            newObjectList.post = ''
                            newObjectList.postDate = ''
                        }
                        list.push(newObjectList)
                        newObjectList = {}
                    })
                }else{
                    list.push({
                        driver: driver.name.toUpperCase(),
                        pre: '',
                        post: ''
                    })
                }
            })

            let sortedData = list.sort(function(a, b) {
                let preA = a.pre;
                let preB = b.pre;
                
                if (preA ^ preB) {
                  return !preA ? 1 : -1;
                }
                
                return preB.localeCompare(preA);
            })
            setInspectionList(sortedData)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }     

    const onChangeDate = (date) => {
        setDate(date)
    }
    const RenderDate = () => {
        return <DatePicker 
        style={{width: 200}}
        allowClear={false}
        size='large'
        value={date}
        format={'MM/DD/YYYY'}
        loading={loading}
        onChange={onChangeDate}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'}>
            <CustomTable 
            columns={columns}
            data={inspectionList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Inspections Driver Report'
            description='Logistics Report'
        >
            <Flex vertical gap={'large'}>
                <RenderDate />
                {
                    loading ? 
                    <CustomSpinner/> 
                    : 
                    <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default LogisticsReportInspectionDriver