import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import SendInvoice from './SendInvoice'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'

export default class InvoicePDF extends Component {
    constructor(props) {
        super(props)

        this.state = {
            invoice: {},
            company: {},
            yards: [],
            yardSum: 0,
            subtotal: 0,
            showModalEmail: false,
            showModalSms: false,
            trip: 0,
            loadsData:{},
            pdf: '',
            loading: true
        }
    }

    /**
     * Get company information
     */
    getCompanyInfo = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                company: data
            })
        } catch (err) {
            console.error(err)
            return null
        }
    }

    /**
     * Get PDF invoice
     */
    getPDFInvoice = async () => {
        const url = api + `/invoicepdf/${this.props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                pdf: data,
                loading: false
            })
        } catch (err) {
            console.error(err)
            return null
        }
    }

    /**
     * Open and close email modal
     * @param {Event} e event of the modal
     */
    toggleModalEmail = (e) => {
        this.setState({
            showModalEmail: !this.state.showModalEmail
        })
    }

    /**
     * Open and close sms modal
     * @param {Event} e event of the modal
     */
    toggleModalSms = (e) => {
        this.setState({
            showModalSms: !this.state.showModalSms
        })
    }

    /**
     * Get invoice information
     */
    getInvoice = async () => {
        const url = api + '/invoice/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                invoice: data,
            })
            const job = this.state.invoice.job._id
            this.getLoadInfo(job, data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    /**
     * 
     * @param {Object} dataInvoice invoice information
     * @param {Object} dataLoads loads information
     */
    calcTotals = (dataInvoice, dataLoads) => {
        const material = dataInvoice.amount
        const tripCharge = dataInvoice.fuelRate * dataLoads.length
        const shippingFee = dataInvoice.shippingFee * dataLoads.length
        const creditCardCost = dataInvoice.creditCardCost 
        const colorCharge = dataInvoice.colorTicket * dataLoads.length
        const fiberCharge = dataInvoice.fiberBags * dataLoads.length 
        const sizeCharge = dataInvoice.sizeBags * dataLoads.length
        const miscelaneosFee = dataInvoice.miscelaneosFee * dataLoads.length

        const subtotal = material + tripCharge + shippingFee + colorCharge + fiberCharge + sizeCharge + miscelaneosFee + creditCardCost
        this.setState({
            subtotal
        })
    }

    /**
     * Yards detail
     * @param {String} job job id 
     * @param {Object} dataInvoice invoice information
     */
    getLoadInfo = async (job, dataInvoice) => {
        const url = api + '/load/' + job
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            const totalLoad = data.reduce((acc, load) => acc + load.yards, 0)

            this.setState({
                yards: data,
                yardSum: totalLoad,
                trip: data.length
            })
            this.calcTotals(dataInvoice, data)
            this.calculateTotalsPerTicket(dataInvoice, data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    /**
     * Calculate discount amount of the invoice
     */
    calculateDiscount = () => {
        if(this.state.invoice.isDiscount){
            return this.state.invoice.discountAmount
        }
        return 0
    }

    /**
     * Calculate totals on invoice per ticket
     * @param {Object} dataInvoice invoice information
     * @param {Array} loads array of loads objects
     */
    calculateTotalsPerTicket = (dataInvoice,loads) => {
        let tripCharge = 0 
        let shippingFee = 0
        let colorFee = 0
        let fiberFee = 0
        let sizeFee = 0
        let miscelaneosFee = 0
        let material = dataInvoice.amount
        
        loads.map((load) => {
            if(load.fuelSurcharge) tripCharge += load.fuelRate
            if(load.shipping) shippingFee += load.shippingFee
            if(load.color) colorFee += load.colorTicket * 50
            if(load.fiber) fiberFee += load.fiberBags * 20
            if(load.size) sizeFee += load.sizeBags * 20
            if(load.miscelaneos) miscelaneosFee += load.miscelaneosFee
        })

        let subtotal = tripCharge + shippingFee + colorFee + fiberFee + sizeFee + miscelaneosFee + material 

        let totalsPerTicket = {
            tripCharge: tripCharge,
            shippingFee: shippingFee,
            colorFee: colorFee,
            fiberFee: fiberFee,
            sizeFee: sizeFee,
            miscelaneosFee: miscelaneosFee,
            subtotal: subtotal,
        }

        this.setState({
            loadsData: totalsPerTicket
        })
    }
    
    componentDidMount() {
        this.getPDFInvoice()
    }

    render() {

        return (
            <Layout
                title='Invoice PDF'
                description='Invoice'
                className="container col-md-12"
            >
                <Link onClick={this.toggleModalEmail}
                    to='#'
                    className="btn btn-primary"
                >
                    Send invoice by email
                </Link>                
                <Link onClick={this.toggleModalSms}
                    to='#'
                    className="btn btn-success"
                    style={{marginLeft:20}}
                >
                    Send invoice by SMS
                </Link>                
                <Modal show={this.state.showModalEmail} animation={false} onHide={this.toggleModalEmail}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Invoice to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendInvoice 
                            invoiceId={this.props.match.params.id} 
                            pcallback={this.toggleModalEmail}
                            isEmail={true}
                        />
                    </Modal.Body>
                </Modal>  
                <Modal show={this.state.showModalSms} animation={false} onHide={this.toggleModalSms}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Invoice to:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendInvoice 
                            invoiceId={this.props.match.params.id} 
                            pcallback={this.toggleModalSms}
                            isEmail={false}
                        />
                    </Modal.Body>
                </Modal>  

                {
                    this.state.loading ? <Spinner/> : <iframe src={this.state.pdf} width='95%' style={{height: '90vh'}} />
                }
                
            </Layout>
        )
    }
}