import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import formatDate from '../utils/FormatDate'
import axios from 'axios'
import { api } from '../utils/Api'
import { toCurrency, toThousands } from '../utils/Convert'
import SellerPicker from './SellerPicker'

const SalesBySeller = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [salesBySeller, setSalesBySeller] = useState([])
    const [seller, setSeller] = useState('')
    const [sellerName, setSellerName] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        if(seller !== '') getJobs(seller)
    }, [startDate, endDate])

    useEffect(() => {
      if(seller === '') setMessage('Please select a Seller')
    }, [])
    

    const getJobs = async (currentSeller) => {
        const url = api + `/jobsbyseller/${currentSeller}?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            let jobs = []
            let loads = []
            const { data } = await axios.get(url)

            if(data.length !== 0){
                let ids = []
                data.map((item) => {
                    ids.push(item._id)
                    jobs.push({
                        unitPrice: item.unitPrice,
                        job: item._id,
                        customer: item.customer.name,
                        loads: []
                    })
                })

                const url = api + '/loadsbyjobs'
                const body = {
                    jobIds: ids,
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                }
                const response = await axios.post(url, body)
                const loadsResponse = response.data
                let finalList = []

                if(loadsResponse.length !== 0){
                    loadsResponse.map((item) => {
                        loads.push({
                            job: item.job,
                            yards: item.yards
                        })
                    })
                
                    for (let i = 0; i < jobs.length; i++) {
                        const element = jobs[i];
                        for (let j = 0; j < loads.length; j++) {
                            const element2 = loads[j];
                            if(element2.job === element.job){
                                element.loads.push(element2)
                            }
                        }
                    }

                    const groupByCustomer = jobs.reduce((group, object) => {
                        const { customer } = object;
                        group[customer] = group[customer] ?? [];
                        group[customer].push(object);
                        return group;
                    }, {})

                    const arrayObjects = Object.values(groupByCustomer)

                    arrayObjects.map((item) => {
                        let yards = 0
                        let unitPrice = 0
                        for (let is = 0; is < item.length; is++) {
                            const element = item[is];
                            for (let il = 0; il < item[is].loads.length; il++) {
                                const element2 = item[is].loads[il];
                                yards += element2.yards
                                unitPrice += (element.unitPrice * element2.yards)
                            }
                        }

                        finalList.push({
                            seller: sellerName,
                            customer: item[0].customer,
                            yards: yards,
                            amount: unitPrice,
                            jobs: item.length
                        })
                    })
                    setSalesBySeller(finalList)
                }else{
                    setMessage('Seller do not contain loads')
                }
                                
            }else{
                setMessage('Seller do not contain jobs')
            }
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const RenderSales = () => {
        if(salesBySeller.length > 0){
                return <div style={{paddingTop: 20}} >
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Seller</th>
                                <th>Customer</th>
                                <th>YD3</th>
                                <th>Amount</th>
                                <th># Jobs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesBySeller.map((item) => (
                                    <tr>
                                        <td>{item.seller}</td>
                                        <td>{item.customer}</td>
                                        <td>{toThousands(item.yards)}</td>
                                        <td>{toCurrency(item.amount,2)}</td>
                                        <td>{toThousands(item.jobs)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    
                </div>
        }else{
            return <div style={{paddingTop: 20, width: '100%', textAlign:'center'}}>{message}</div>
        }
    }

    const onChangeSeller = selectedOption => {
        if (selectedOption) {
            setSeller(selectedOption.value)
            setSellerName(selectedOption.label)
            getJobs(selectedOption.value)
        }
    }

    return (
        <Layout
        title='Sales By Seller'
        description='Sales By Seller And Clients'
        >
            <div className='form-group row' style={{maxWidth: '100%'}}>
                <div className='col-sm-2'>
                    <label className='text-muted'>Start Date</label>
                    <DatePicker
                        selected={startDate}
                        minDate={new Date('01/01/2022')}
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                    />
                </div>
                <div className='col-sm-2'>
                    <label className='text-muted'>End Date</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        minDate={startDate}
                        maxDate={new Date()}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Seller</label>
                    <SellerPicker action={onChangeSeller} />
                </div>
            </div>
            {
                loading ? <RenderLoading/> : <RenderSales/>
            } 
        </Layout>
    )
}

export default SalesBySeller