import {
    GET_USER,
    USER_ERROR
} from '../actions/types'

const initialState = {
    loading: true,
    seller: null,
}

function userReducer(state = initialState, action) {
    const {type, payload} = action 
    switch(type) {
        case GET_USER:
            return {
                ...state,
                seller: payload,
                loading: false
            }
        case USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default userReducer
