import React, { Component } from 'react'
import Layout from '../layout/Layout'
import axios from 'axios'
import { api } from '../utils/Api'

export default class ClientConfirmation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            sms: '',
            customerNumber: '',
            contactType: 'Email',
            notifiableEvents: [],
            password: '',
            password2: '',
            contactPhone: '',
            contactFax: '',
            companyName: '',
            address: '',
            city: '',
            state: 'TX',
            zip: '',
            accountsPayableContact: '',
            accountsPayableEmail: '',
            accountsPayablePhone: '',
            accountsPayableFax: ''
        }
    }

    // api request
    getClient = async () => {
        const url = api + '/customer/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            this.setState({
                name: data.name,
                email: data.email,
                sms: data.sms,
                contactType: data.contactType,
                notifiableEvents: data.notifiableEvents,
                customerNumber: data.customerNumber,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                contactPhone: data.contactPhone,
                contactFax: data.contactFax,
                clientId: this.props.match.params,
                loading: false,
                role: localStorage.getItem('role')
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getClient()
    }

    render() {
        return (
            <Layout
                title='Customer Registration '
                description='You have been registered'
                className="container col-md-8 offset-md-2"
            >
                Successful registration.
                Customer number: <strong>{this.state.customerNumber}</strong>
            </Layout>
        )
    }
}