import React, {useEffect, useState} from 'react'
import Layout from '../layout/Layout'
import { Link, useHistory } from 'react-router-dom'
import { api } from '../utils/Api'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlusSquare
} from '@fortawesome/free-solid-svg-icons'

const OpportunityStatus = () => {
    let history = useHistory()
    const [prospections, setProspections] = useState([])

    const showStatus = (status) => {
        history.push(`/opportunitystatus/${status}`)
    }

    useEffect(() => {
        getProspections()
    }, [])
    
    const getProspections = async () => {
        const url = api + `/allprospections`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setProspections(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const showTotal = (option, ydOrJobs) => {
        let prospectionsFiltered = []
        prospectionsFiltered = prospections.filter(prospections => prospections.prospectionStatus === option)

        const quantity = prospectionsFiltered.length
        let yd3 = 0

        // prospectionsFiltered.forEach(proposal => { yd3 += parseInt(proposal.targetYD3) })
        // yd3 = yd3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add commas 
        // return (ydOrJobs === "yd" ? yd3 : quantity)
        return quantity
    }

    const RenderStatus = () => {
        return (
            <div>            
                <div className='row'>
                    <div className="col-md-4" onClick={(e) => showStatus("contact")}>
                        <div className='card text-center bg-success text-white mb-2'>
                            <div className="card-body">
                                <h3>Contact</h3>
                                <h4 className="display-4">
                                    {/* YD: {showTotal('Contact', 'yd')} */}
                                </h4>
                                <hr />
                                <h4>Prospections: {showTotal('Contact', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" onClick={(e) => showStatus("appointment")}>
                        <div className='card text-center bg-warning text-white mb-2'>
                            <div className="card-body">
                                <h3>Appointment</h3>
                                <h4 className="display-4">
                                    {/* YD: {showTotal('Appointment', 'yd')} */}
                                </h4>
                                <hr />
                                <h4>Prospections: {showTotal('Appointment', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" onClick={(e) => showStatus("proposal")}>
                        <div className='card text-center text-white mb-2' style={{ backgroundColor: '#FF8C00' }}>
                            <div className="card-body">
                                <h3>Proposal</h3>
                                <h4 className="display-4">
                                    {/* YD: {showTotal('Proposal', 'yd')} */}
                                </h4>
                                <hr />
                                <h4>Prospections: {showTotal('Proposal', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6" onClick={(e) => showStatus("deniedproposals")}>
                        <div className='card text-center bg-danger text-white mb-2'>
                            <div className="card-body">
                                <h3>Proposal Denied</h3>
                                <h4 className="display-4">
                                    {/* YD: {showTotal('Proposal Denied', 'yd')} */}
                                </h4>
                                <hr />
                                <h4>Prospections: {showTotal('Denied', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" onClick={(e) => showStatus("acceptedproposals")}>
                        <div className='card text-center bg-info text-white mb-2'>
                            <div className="card-body">
                                <h3>Proposal Accepted</h3>
                                <h4 className="display-4">
                                    {/* YD: {showTotal('Proposar Accepted', 'yd')} */}
                                </h4>
                                <hr />
                                <h4>Prospections: {showTotal('Accepted', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='row'>
                    <div className="col-md-6" onClick={(e) => showStatus("closure")}>
                        <div className='card text-center bg-danger text-white mb-2'>
                            <div className="card-body">
                                <h3>Closure</h3>
                                <h4 className="display-4">
                                    YD: {showTotal('Closure', 'yd')}
                                </h4>
                                <hr />
                                <h4>Proposals: {showTotal('Closure', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" onClick={(e) => showStatus("newclient")}>
                        <div className='card text-center bg-info text-white mb-2'>
                            <div className="card-body">
                                <h3>New Client</h3>
                                <h4 className="display-4">
                                    YD: {showTotal('New Client', 'yd')}
                                </h4>
                                <hr />
                                <h4>Proposals: {showTotal('New Client', 'jobs')}</h4>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }

    return (
        <Layout
            title='Dashboard Opportunities'
            description=''
        >
            &nbsp;
            <Link to='/newprospection' className="btn btn-primary "><FontAwesomeIcon icon={faPlusSquare} />&nbsp;New Prospection</Link>
            <p/>
            <RenderStatus />
            <p/>
            <Link to='/prospectionlist' className="btn btn-primary col-md-12">Prospections</Link>
            
        </Layout>
    )
}

export default OpportunityStatus