import {
    GET_PROSPECTIONS,
    PROSPECTION_ERROR,
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    prospection: null
}

export default function prospectionReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PROSPECTIONS:
            return {
                ...state,                
                prospections: payload,                
                loading: false
            }
        case PROSPECTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}
