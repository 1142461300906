import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'

const InterviewCommentsRender = ({currentInterviewComment, interviews, setInterviewComments, toggleModal, edit, index}) => {

    const [comment, setComment] = useState('');
    const [interviewer, setInterviewer] = useState('');
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        setCurrentInterviewComment()
    }, [edit])
    

    const setCurrentInterviewComment = () => {
        if(edit){
            setComment(currentInterviewComment.comment);
            setInterviewer(currentInterviewComment.interviewer);
            setDate(currentInterviewComment.date);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newInterviewComment = {
            comment,
            interviewer,
            date,
        };
        if(edit){
            interviews[index].comment = comment;
            interviews[index].interviewer = interviewer;
            // interviews[index].date = date;
        }else{
            interviews.push(newInterviewComment);
        }
        setInterviewComments(interviews);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Date</label>
                    <DatePicker
                        selected={date}
                        disabled
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Interviewer</label>
                    <input className='form-control'
                        name='interviewer'
                        type='text'
                        value={interviewer}
                        onChange={e => setInterviewer(e.target.value)}
                        placeholder='Interviewer'
                        required
                    />
                </div>
                <div className='col-sm-12'>
                    <label className='text-muted'>Comment</label>
                    <input className='form-control'
                        name='comment'
                        type='text'
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        placeholder='Comment'
                        required
                    />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
        </form>
    )
}

export default InterviewCommentsRender