import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'   
import { connect } from 'react-redux'
import { getPlantNumber } from '../../actions/plant'
import { getLoadCapacity } from '../../actions/load'
import { 
    Line, 
    ComposedChart,
    CartesianGrid, 
    XAxis, 
    Bar,
    Legend,
    Tooltip
} from 'recharts'

const Aviability = ({ getPlantNumber, getLoadCapacity, plant: { plant }, load: {loads, loading} }) => {
    const [capDate, setCapDate] = useState(new Date())

    useEffect(() => {
        getPlantNumber('01')
        
        // const hoy = new Date()
        // const today = `${hoy.getFullYear()}-${hoy.getMonth()+1}-${hoy.getDate()}`
        // const final = `${hoy.getFullYear()}-12-31`
        // console.log(`Hoy: ${today} fin de año: ${final}`)

        const fecha = `${capDate.getFullYear()}-${capDate.getMonth()+1}-${capDate.getDate()}`
        console.log(fecha)
        // getLoadCapacity('2021-12-2','2021-12-2')
        getLoadCapacity(fecha, fecha)
    }, [capDate])

    // tiempo total 
    // destinationMinutes * 2 + slumpRackTime + timeToLoad + washoutOnSite
    // batch: 8 min
    // slumprack: 10 min
    // pour: 20 min
    // washout: 10 min
    //
    // Secuencia: batch + slumprack + travel (ida) + pour + washout + travel (regreso)
    // timeToload --> slumprack
    const calculos = () => {
        const duracionDefault = 25
        const pourTime = 20
        // console.log(plant)

        if (loads) {
            console.log(loads)
            const tiempos = loads.map((load) => {
                const duracion = parseInt(load.job.destinationTime) || duracionDefault
                const [hora, min, seg] = new Date(load.job.scheduledDate).toLocaleTimeString('en-GB').split(':')
                return {
                    hora,
                    truck: load.truckNumber,
                    fecha: new Date(load.job.scheduledDate).toLocaleDateString(),
                    yards: load.yards,
                    ttime: duracion * 2 + plant.slumpRackTime + plant.timeToLoadTruck + plant.washoutOnSite + pourTime
                }
            })
            console.log(tiempos)

            const resum = tiempos.reduce((nuevo, load) => {
                // console.log(load)
                if (!nuevo[load.hora]) {
                    nuevo[load.hora] = load.ttime / 60
                } else {
                    nuevo[load.hora] += (load.ttime / 60)
                }
                return nuevo
            }, {})

            console.log(resum)

            let datos = []
            for (let i=0; i<24; i++) {
                let hr = ''
                let duration = 0

                if (i<10) {                    
                    hr = `0${i}`                    
                }  else {
                    hr = `${i}`
                }

                duration = resum[hr] || 0

                const point = {
                    hr: hr,
                    duration,
                    trucks: 10
                }
                datos.push(point)
            }
            console.log(datos)

            return (
                <ComposedChart width={600} height={300} data={datos}>
                    <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hr" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="duration" fill="#1e387d" />
                        <Line type="monotone" dataKey="trucks" stroke="#eb1a20" />
                </ComposedChart>
            )
        }
        
    }

    return (
        <Layout
            title='Reports'
            description='Avialability Report'
            className='container col-md-8 offset-md-2'
        >
        Select Date
        <DatePicker 
            selected={capDate} 
            onChange={(date) => setCapDate(date)}
        />
        {
            loads.length > 0 ? calculos() : <h3>No data for selected date</h3> 
        }
    </Layout>
    )
}

const mapStateToProps = state => ({
    plant: state.plant,
    load: state.load
})

export default connect(mapStateToProps, {getPlantNumber, getLoadCapacity}) (Aviability)