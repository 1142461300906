import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'

const ExpensesWithoutProofRender = ({currentExpense, expenses, setExpenses, toggleModal, edit, index}) => {

    const [concept, setConcept] = useState('');
    const [observation, setObservation] = useState('');
    const [amount, setAmount] = useState(0);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        setCurrentExpense()
    }, [edit])
    

    const setCurrentExpense = () => {
        if(edit){
            setObservation(currentExpense.observation);
            setConcept(currentExpense.concept);
            setAmount(currentExpense.amount);
            setDate(new Date(currentExpense.date));
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newExpense = {
            observation,
            concept,
            amount,
            date
        };
        if(edit){
            expenses[index].observation = observation;
            expenses[index].concept = concept;
            expenses[index].amount = amount;
            expenses[index].date = date;
        }else{
            expenses.push(newExpense);
        }
        setExpenses(expenses);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Date</label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Concept</label>
                    <input className='form-control'
                        name='concept'
                        type='text'
                        value={concept}
                        onChange={e => setConcept(e.target.value)}
                        placeholder='Concept'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Amount</label>
                    <input className='form-control'
                        name='amount'
                        type='number'
                        value={amount}
                        onChange={e => setAmount(e.target.valueAsNumber)}
                        placeholder='Amount'
                        min={0}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Observation</label>
                    <input className='form-control'
                        name='observation'
                        type='text'
                        value={observation}
                        onChange={e => setObservation(e.target.value)}
                        placeholder='Observation'
                        required
                    />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
            
        </form>
    )
}

export default ExpensesWithoutProofRender