import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../../layout/Layout'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { api } from '../../utils/Api'
import RolePicker from './RolePicker'
import Modal from 'react-bootstrap/Modal';

export default class EmailValidation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            workEmail: '',
            phone: '',
            password: '',
            role: '',
            jobHold: false,
            showModal: false,
            modalMessage: '',
            userMessage: ''
        }

        this.onSubmit = this.onSubmit.bind(this)        
    }

    onChangeName = e => {
        this.setState({
            name: e.target.value
        })
    }

    onChangeEmail = e => {
        this.setState({
            workEmail: e.target.value
        })
    }

    onChangePassword = e => {
        this.setState({
            password: e.target.value
        })
    }

    onChangePhone = e => {
        this.setState({ phone: e })
    }

    onChangeJobHold = e => {
        this.setState({ jobHold: e.target.checked })
    }

    /**
     * On change employee role
     * @param {Object} selectedRole 
     */
    onChangeRole = selectedRole => {
        if (selectedRole) {
            this.setState({
                role: selectedRole.value
            })
        }
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    saveEmployee = async () => {

        try {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            let user = {
                workEmail: this.state.workEmail,
                password: this.state.password,
                role: this.state.role
            }
    
            if(this.state.role === 'Finance' || this.state.role === 'Admin'){
                user.jobHold = this.state.jobHold
            }

            const url = api + `/employee/${this.props.match.params.id}`

            await axios.patch(url, user)

            this.props.history.push('/emailsvalidation')

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    getEmployee = async () => {
        try {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            let url = api + `/employee/${this.props.match.params.id}`

            const { data } = await axios.get(url)

            this.setState({
                name: data.middleName ? `${data.name} ${data.middleName} ${data.lastName}` : `${data.name} ${data.lastName}` ,
                workEmail: data.workEmail ? data.workEmail : '',
                password: data.password ? data.password : '',
                phone: data.phone ? data.phone : '',
                role: data.role ? data.role : '',
                jobHold: data.jobHold
            })

            if(data.workEmail){
                const duplicado = await this.duplicatedUser(data.workEmail)
                if (duplicado) {
                    this.setState({
                        userMessage: '*** Employee is a current user ***'
                    })
                } else {
                    this.setState({
                        userMessage: '*** Employee is not user yet ***'
                    })
                }
            }else{
                this.setState({
                    userMessage: '*** Employee is not an user yet ***'
                })
            }

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    duplicatedUser = async (email) => {
        const url = api + '/user/' + email
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getEmployee()
    }

    onSubmit(e) {
        e.preventDefault()
        this.saveEmployee()
    }

    sendEmail = async (e) => {
        e.preventDefault()
        if(this.state.workEmail === '' || this.state.password === '' || this.state.role === ''){
            this.setState({
                showModal: true,
                modalMessage: 'You must fill all the fields to send the new email'
            })
        }else{
            const duplicado = await this.duplicatedUser(this.state.workEmail)
            if (duplicado) {
                this.setState({
                    showModal: true,
                    modalMessage: 'Error creating the user. Duplicated email.'
                })
            } else {
                this.createUser()
            }
        } 
    }

    createUser = async () => {
        try {
            const url = api + `/users`
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            let user = {
                name: this.state.name,
                email: this.state.workEmail,
                password: this.state.password,
                role: this.state.role
            }
    
            if(this.state.role === 'Finance' || this.state.role === 'Admin'){
                user.jobHold = this.state.jobHold
            }

            await axios.post(url, user)

            this.sendEmailToManagment()

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    sendEmailToManagment = async () => {
        try {
            const url = api + `/emailnewuser`
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const body = {
                name: this.state.name,
                email: this.state.workEmail,
                password: this.state.password
            }

            await axios.post(url, body)
            this.saveEmployee()
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    EmailValidationForm = () => (
        <form className="form" onSubmit={this.onSubmit}>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input 
                    onChange={this.onChangeName} 
                    type="text" 
                    required
                    className="form-control"
                    value={this.state.name}
                    placeholder="Seller name"  
                    readOnly                  
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input 
                    onChange={this.onChangeEmail}
                    type="email" 
                    required
                    className="form-control" 
                    value={this.state.workEmail}
                    placeholder="Work email"
                />            
            </div>
            <div className="form-group">
                <label className="text-muted">Password</label>
                <input 
                    onChange={this.onChangePassword}
                    type="password" 
                    className="form-control" 
                    value={this.state.password}                    
                    minLength='5'
                    placeholder='Password'
                />
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Seller phone"
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                        required
                        disabled
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Role</label>
                    <RolePicker action={this.onChangeRole} value={this.state.role} />
                </div>
            </div>
            {
                (this.state.role === 'Admin' || this.state.role === 'Finance') &&
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Allow Job Hold</label>
                        &nbsp;
                        <input 
                            type='checkbox'
                            checked={this.state.jobHold}
                            onChange={this.onChangeJobHold}
                        />
                    </div>
                </div>
            }
            <div>
                <b>{this.state.userMessage}</b>
            </div>
            <div className="my-2">
                <button type='submit' className="btn btn-success">Save</button>
                &nbsp;
                <button onClick={this.sendEmail} type='button' className="btn btn-primary">Send</button>
            </div>            
        </form>
    )

    render() {
        return (
            <Layout
                title='Email Validation'
                description='Fiil the form in order to create a new user'
                className="container col-md-8 offset-md-2"
            >   
                {this.EmailValidationForm()}
                <Modal
                    show={this.state.showModal}
                    animation={false}
                    onHide={this.toggleModal}
                    size='sm'
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Send email error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalMessage}
                    </Modal.Body>
                </Modal>
            </Layout>
        )
    }
}