const statusOptions = [ 
    { value: 'Active', label: 'Active' },
    { value: 'In Storage', label: 'In Storage' },
    { value: 'Disposed', label: 'Disposed' },              
    { value: 'Delivery', label: 'Delivery' },
    { value: 'Sold Out', label: 'Sold Out' },
]

const statusAllOptions = [ 
    { value: 'All', label: 'All' },
    { value: 'Active', label: 'Active' },
    { value: 'In Storage', label: 'In Storage' },
    { value: 'Disposed', label: 'Disposed' },              
    { value: 'Delivery', label: 'Delivery' },
    { value: 'Sold Out', label: 'Sold Out' },
]

const typeOptions = [
    { value: 'Initial', label: 'Initial' },
    { value: 'In Stock', label: 'In Stock' },
    { value: 'Out Of Stock', label: 'Out Of Stock' }
]

const categoryOptions = [
    {
        "value": "Electric Drum Beck",
        "label": "Electric Drum Beck"
    },
    {
        "value": "Consumable",
        "label": "Consumable"
    },
    {
        "value": "Spare Part",
        "label": "Spare Part"
    },
    {
        "value": "Critical Spare Part",
        "label": "Critical Spare Part"
    }
]

const containerOptions = [
    {
        "value": "Main",
        "label": "Main"
    },
    {
        "value": "Rack 1",
        "label": "Rack 1"
    },
    {
        "value": "Rack 2",
        "label": "Rack 2"
    },
    {
        "value": "Rack 3",
        "label": "Rack 3"
    },
]

const measurementUnitOptions = [
    {
        "value": "miles",
        "label": "Miles"
    },
    {
        "value": "gallons",
        "label": "Gallons"
    },
]

const sitesOptions = [
    {
        "value": "57Concrete",
        "label": "57Concrete"
    },
    {
        "value": "Mission Plant 1",
        "label": "Mission Plant 1"
    },
    {
        "value": "Mission Plant 2",
        "label": "Mission Plant 2"
    },
    {
        "value": "Sab Benito Plant 1",
        "label": "San Benito Plant 1"
    },
]

const priorityOptions = [
    {
        "value": "Low",
        "label": "Low"
    },
    {
        "value": "Medium",
        "label": "Medium"
    },
    {
        "value": "High",
        "label": "High"
    },
]

export {
    statusOptions,
    typeOptions,
    categoryOptions,
    containerOptions,
    measurementUnitOptions,
    sitesOptions,
    statusAllOptions,
    priorityOptions
}