// Load capacity
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { 
    Line, 
    ComposedChart,
    CartesianGrid, 
    XAxis, 
    Bar,
    Legend,
    Tooltip
} from 'recharts'
import DatePicker from 'react-datepicker'   
import { connect } from 'react-redux'
import { getPlantNumber } from '../../actions/plant'
import { getLoadCapacity } from '../../actions/load'

const Capacity = ({ getPlantNumber, getLoadCapacity, plant: { plant }, load: {loads, loading} }) => {
    const [capDate, setCapDate] = useState(new Date())

    useEffect(() => {
        getPlantNumber('01')
        
        // const hoy = new Date()
        // const today = `${hoy.getFullYear()}-${hoy.getMonth()+1}-${hoy.getDate()}`
        // const final = `${hoy.getFullYear()}-12-31`
        // console.log(`Hoy: ${today} fin de año: ${final}`)

        const fecha = `${capDate.getFullYear()}-${capDate.getMonth()+1}-${capDate.getDate()}`
        // getLoadCapacity('2021-12-2','2021-12-2')
        getLoadCapacity(fecha, fecha)
    }, [capDate])

    // tiempo total 
    // destinationMinutes * 2 + slumpRackTime + timeToLoad + washoutOnSite
    const calculos = () => {
        const duracionDefault = 25

        if (loads) {
            const tiempos = loads.map((load) => {
                const duracion = parseInt(load.job.destinationTime) || duracionDefault
                const [hora, min, seg] = new Date(load.job.scheduledDate).toLocaleTimeString('en-GB').split(':')
                return {
                    duracion,
                    hora,
                    fecha: new Date(load.job.scheduledDate).toLocaleDateString(),
                    yards: load.yards,
                    ttime: duracion * 2 + plant.slumpRackTime + plant.timeToLoadTruck + plant.washoutOnSite
                }
            })

            const resum = tiempos.reduce((nuevo, load) => {
                // console.log(load)
                if (!nuevo[load.hora]) {
                    nuevo[load.hora] = load.yards
                } else {
                    nuevo[load.hora] += load.yards
                }
                return nuevo
            }, {}) 

            let datos = []
            for (let i=0; i<24; i++) {
                let yard = 0
                let hr = ''

                if (i<10) {                    
                    hr = `0${i}`                    
                }  else {
                    hr = `${i}`
                }

                yard = resum[hr] || 0

                const point = {
                    hr: hr,
                    yard,
                    max: 50
                }
                datos.push(point)
            }

            return (
                <ComposedChart width={600} height={300} data={datos}>
                    <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hr" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="yard" fill="#1e387d" />
                        <Line type="monotone" dataKey="max" stroke="#eb1a20" />
                </ComposedChart>
            )
        }
    }

    return (
        <Layout
            title='Reports'
            description='Capacity Report'
            className='container col-md-8 offset-md-2'
        >
            <DatePicker 
                selected={capDate} 
                onChange={(date) => setCapDate(date)}
            />
        { loads.length > 0 ? calculos() : <h3>No data for selected date</h3> }
    </Layout>
    )
}

const mapStateToProps = state => ({
    plant: state.plant,
    load: state.load
})

export default connect(mapStateToProps, {getPlantNumber, getLoadCapacity}) (Capacity)

