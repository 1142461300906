import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getBuildDevelopment, updateBuildDevelopment, deleteBuildDevelopment } from '../../actions/buildDevelopment'
import Spinner from '../layout/Spinner'

const BuildDevelopment = ({ match: { params }, getBuildDevelopment, updateBuildDevelopment, deleteBuildDevelopment, build_development: { build_development, loading }, history }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        getBuildDevelopment(params.id)
    }, [])

    useEffect(() => {
        if (build_development) {
            setName(build_development.name)
            setDescription(build_development.description)
        }
    }, [build_development])

    const onSubmit = e => {
        e.preventDefault()

        const formData = {
            name,
            description
        }

        updateBuildDevelopment(params.id, formData)
        history.push('/builddevelopment')
    }

    const onDelete = e => {
        e.preventDefault()

        deleteBuildDevelopment(params.id)
        history.push('/builddevelopment')
    }

    const BuildForm = () => (
        <form className='form'>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Name</label>
                    <input
                        onChange={e => setName(e.target.value)}
                        type="text"
                        name='name'
                        className="form-control"
                        value={name}
                        placeholder="name"
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Description</label>
                    <input
                        onChange={e => setDescription(e.target.value)}
                        type="text"
                        name='description'
                        className='form-control'
                        value={description}
                        placeholder='description'
                        required
                    />
                </div>
            </div>
            <br />
            <div className="form-group row">
                <div class="col-6">
                    <button className='btn btn-primary' onClick={onSubmit}> Edit </button>
                </div>
                <div class="col-6">
                    <button className='btn btn-danger' onClick={onDelete}> Delete </button>
                </div>
            </div>
        </form>
    )

    return (
        <Layout
            title='Build Development Edit'
            description='Edit Build Development'
            className='container col-md-8 offset-md-2'
        >
            {BuildForm()}
        </Layout>
    )
}

const mapStateToProps = state => ({
    build_development: state.build_development
})


export default connect(mapStateToProps, { getBuildDevelopment, updateBuildDevelopment, deleteBuildDevelopment })(BuildDevelopment)