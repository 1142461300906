import {
    ADD_BUILD_DEVELOPMENT,
    GET_BUILD_DEVELOPMENT,
    GET_BUILD_DEVELOPMENTS,
    UPDATE_BUILD_DEVELOPMENT,
    DELETE_BUILD_DEVELOPMENT,
    BUILD_DEVELOPMENT_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    build_development: null,
    build_developments: [],
}

function developmentReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_BUILD_DEVELOPMENT:
            return {
                ...state,
                build_developments: payload,
                loading: false
            }
        case GET_BUILD_DEVELOPMENT:
            return {
                ...state,
                build_development: payload,
                loading: false
            }
        case GET_BUILD_DEVELOPMENTS:
            return {
                ...state,
                build_developments: payload,
                loading: false
            }
        case UPDATE_BUILD_DEVELOPMENT:
            return {
                ...state,
                build_developments: payload,
                loading: false
            }
        case DELETE_BUILD_DEVELOPMENT:
            return {
                ...state,
                build_developments: payload,
                loading: false
            }
        case BUILD_DEVELOPMENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default developmentReducer