import React from 'react'
import './EticketMarker.css'

const EticketMarker = props => {
    const { color, name } = props
    return (
        <div>
          <div
            className="pin-effect bounce-effect"
            style={{ backgroundColor: color, cursor: 'pointer' }}
            title={name}
          />
          <div className="pulse-effect" />
        </div>
      );
  }

  export default EticketMarker