import {
    ADD_JOB,
    GET_JOB,
    GET_JOBS,
    JOB_ERROR,
    UPDATE_JOB,
    DELETE_JOB,
    GET_YARDS
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    // job: null
}

export default function jobReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_JOB:
            return {
                ...state,
                ...payload,
                loading: false
            }
        case GET_JOB:
            return {
                ...state,
                job: payload,
                loading: false
            }
        case GET_JOBS:
            const laSum = payload.reduce((acc,job) => acc + job.loadYD3, 0)            
            return { 
                ...state,
                jobs: payload,
                sumLoad: laSum,
                loading: false
            }
        case JOB_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case UPDATE_JOB:
            return {
                ...state,
                job: payload,
                loading: false
            }
        case DELETE_JOB:
            return {
                ...state,
                job: payload,
                loading: false
            }
        case GET_YARDS:
            return {
                ...state,
                yards: payload,
                loading: false
            }
        default:
            return state
    }
}

