// Receive a date and format it
export default function formatDate(date) {
    if (date) {
        let formated = `${date.getFullYear()}-`
        formated += date.getMonth() + 1 > 9 ? `${date.getMonth() + 1}-` : `0${date.getMonth() + 1}-`
        formated += date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`
        return formated
    } else {
        return ""
    }
}