import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import { toCurrency } from '../utils/Convert'
import axios from 'axios'
import { getFutureJobs, updateJob, getJobsNowOn } from '../../actions/job'
import JobsPendingItem from './JobsPendingItem'
import { jobIdFilter } from '../utils/JobIdFilter'
import { api } from '../utils/Api'

const JobsPending = ({ getFutureJobs, getJobsNowOn, job: { jobs, loading } }) => {
    const [jobId, setJobId] = useState("")
    const [stateTax, setStateTax] = useState(null)
    const [totalTomorrow, setTotalTomorrow] = useState(0)
    const [totalFuture, setTotalFuture] = useState(0)
    const [payedToggle, setPayedToggle] = useState(false)
    const [taxes, setTaxes] = useState({})

    useEffect(() => {
        getJobsNowOn()
        getTax()
    }, [])

    useEffect(() => {
        getTotals()
    }, [jobs])

    const getTax = async () => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const { data } = await axios.get(url)
            setTaxes({county: data.countyTax, state: data.stateTax, exempt: 0} )
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const getTotals = () => {
        const today = new Date()
        let tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)

        if (jobs != undefined && jobs.length > 0) {
            const tax = (stateTax != null ? stateTax : 0.0825)
            const cashJobs = jobs.filter(job => job.customer.paymentType !== 'Credit')
            const fechas = cashJobs.map(orden => {
                return {
                    fecha: new Date(orden.scheduledDate).toLocaleDateString(),
                    totales: (orden.unitPrice * orden.targetYD3) + (tax * orden.unitPrice) + (orden.deliveryFee ? orden.deliveryFee : 0)
                }
            })

            const manana = new Date(tomorrow).toLocaleDateString()

            const sumManana = fechas.filter(yd => yd.fecha === manana).reduce((acc, viaje) => acc + parseFloat(viaje.totales), 0)
            const sumFuture = fechas.filter(yd => yd.fecha > manana).reduce((acc, viaje) => acc + parseFloat(viaje.totales), 0)
            setTotalTomorrow(sumManana)
            setTotalFuture(sumFuture)
        }
    }

    const changeToggle = () => {
        setPayedToggle(prev => !prev)
    }

    const DrawList = () => {
        const filteredJobs = jobIdFilter(jobId, jobs)
        let cashJobs = filteredJobs.filter(job => job.customer.paymentType !== 'Credit')
        cashJobs = cashJobs.filter(job => job.paymentType !== 'Credit')
        const filtrados = []
        cashJobs.forEach(jb => {
            const balance = jb.pendingBalance
            if (typeof balance === 'undefined') {
                filtrados.push(jb)
            } else if (balance >= 0) {
                filtrados.push(jb)
            }
        })

        return filtrados.filter((jb) => jb.paid === payedToggle).map((job) => {
            return <JobsPendingItem key={job._id} job={job} taxes={taxes} />
        })
    }


    const RenderJobs = () => (
        <div>
            <div className='row' >
                <div className="col-md-6">
                    <div className='card text-center bg-success text-white mb-2'>
                        <div className="card-body">
                            <h3>Total Tomorrow</h3>
                            <h4 className="display-4">
                                {toCurrency(totalTomorrow, 2)}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6" onClick={changeToggle}>
                    <div className='card text-center bg-primary text-white mb-2'>
                        <div className='card-body'>
                            <h3>Payments</h3>
                            <h4 className='display-4'>
                                {toCurrency(totalFuture, 2)}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: '13px' }}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>JobID</th>
                            <th>Customer</th>
                            <th>Order Date</th>
                            <th>Request Time</th>
                            <th>Ship to Address</th>
                            <th>Target YD3</th>
                            <th>Price</th>
                            <th>Tax</th>
                            <th>Taxes</th>
                            <th>Payments</th>
                            <th></th>
                            <th>Balance</th>
                            <th>Date stamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <DrawList />
                    </tbody>
                </table>
            </div>
        </div>
    )

    return (
        <Layout
            title='Jobs'
            description='Jobs with pending payment'
        >
            <div className='col-sm-4'>
                <label className='text-muted'>JobId</label>
                <input
                    type="text"
                    value={jobId}
                    onChange={(e) => setJobId(e.target.value)}
                />
            </div>
            <br />
            {
                loading ? <Spinner /> : (jobs ? <RenderJobs /> : <h4>No Jobs </h4>)
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    job: state.job
})

export default connect(mapStateToProps, { getFutureJobs, updateJob, getJobsNowOn })(JobsPending)