const containFilter = (array, field, criteria) => {
    return array.filter((record) => record[field].includes(criteria))
}

const equalFilter = (array, field, criteria) => {
    return array.filter((record) => record[field] === criteria)
}

const notEqualFilter = (array, field, criteria) => {
    return array.filter((record) => record[field] !== criteria)
}

module.exports = {
    containFilter,
    equalFilter,
    notEqualFilter
}