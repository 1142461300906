import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import SellerPicker from '../seller/SellerPicker'
import DatePicker from 'react-datepicker'
import SlumpPicker from '../slump/SlumpPicker'
import JobTypePicker from '../job/JobTypePicker'
import JobPoorPicker from '../job/JobPoorPicker'
import ClientPicker from '../client/ClientPicker'
import { toCurrency } from '../utils/Convert'
import JobstatusPicker from '../job/JobStatusPicker'
import PsiPicker from '../psi/PsiPicker'
import 'react-datepicker/dist/react-datepicker.css'
import AddressGeolocation from '../map/AddressGeolocation'
import Modal from 'react-bootstrap/Modal'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { api } from '../utils/Api'

export default class Quote extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerName: '',
            customerNumber: '',
            customer: '',
            customerId: '',
            sellerName: '',
            name: '',
            loadYD3: '',
            targetYD3: '',
            balance: '',
            jobDescription: '',
            jobStatus: '',
            shipAddress: '',
            shipCity: '',
            shipState: 'TX',
            shipZip: '',
            jobForeman: '',
            foremanPhone: '',
            apContact: '',
            apEmail: '',
            apPhone: '',
            scheduledDate: '',
            dateChanged: false,
            scheduledTime: '',
            destinationMinutes: '',
            taxExemptJob: false,
            comments: '',
            driver: '',
            po: '',
            jobDuration: '',
            unitPrice: '',
            taxPrice: 0,
            slump: '',
            jobPoor: '',
            jobType: '',
            number: 0,
            date: new Date(),
            isPhoneQuote: false,
            seller: '',
            psi: '',
            destinationTime: '',
            distance: '',
            latitude: '',
            longitude: '',
            searchAddress: false,
            paymentType: '',
            approved: false,
            inspectionTimeStamp: new Date(),
            hotShot: false,
            supervisorPhone: '',
            pmPhone: '',
            supervisorName: '',
            projectManager: '',
            isJob: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === 'unitPrice') {
            this.setState({
                taxPrice: e.target.value * 1.0825
            })
        }

        if (e.target.name === 'shipAddress') {
            this.setState({
                latitude: null,
                longitude: null,
                destinationTime: null,
                distance: 0
            })
        }
    }

    unitPriceInput = e => {
        let value = e.target.value
        if (value >= 0 && value <= 2000) {
            this.setState({ [e.target.name]: value })
            this.setState({
                taxPrice: value * 1.0825
            })
        }
    }

    onChangeDate = date => {
        this.setState({
            date,
            dateChanged: true,
            scheduledDate: date,
            jobStatus: 'Date moved'
        })
    }

    onChangeSlump = selectedSlump => {
        if (selectedSlump) {
            this.setState({
                slump: selectedSlump.value
            })
        }
    }

    // jobPoor option
    onChangeJobPoor = selectedPoor => {
        if (selectedPoor) {
            this.setState({
                jobPoor: selectedPoor.label
            })
        }
    }

    // change PSI
    onChangePsi = selectedPsi => {
        if (selectedPsi) {
            this.setState({
                psi: selectedPsi.label
            })
        }
    }

    // payment type
    onChangePaymentType = selectedPayment => {
        if (selectedPayment) {
            this.setState({
                paymentType: selectedPayment.label
            })
        }
    }

    // job type option
    onChangeJobType = selectedType => {
        if (selectedType) {
            this.setState({
                jobType: selectedType.label
            })
        }
    }

    // customer select
    onChangeCustomer = selectedCustomer => {
        if (selectedCustomer) {
            this.setState({
                customer: selectedCustomer.value
            })
        }
    }

    // change job status
    onChangeJobStatus = selectedOption => {
        if (selectedOption) {
            this.setState({
                jobStatus: selectedOption.label,
                approved: true
            })
        }
    }

    // new seller asignation
    onChangeSeller = selectedOption => {
        if (selectedOption) {
            this.setState({
                seller: selectedOption.value
            })
        }
    }

    // change quote to job
    onQuoteStatusChange = e => {
        this.setState({
            isJob: !this.state.isJob
        })
    }

    // show/hide search modal
    onShowModal = e => {
        this.setState({
            searchAddress: !this.state.searchAddress
        })
    }

    // foreman phone
    onChangeForemanPhone = e => {
        this.setState({
            foremanPhone: e
        })
    }

    onChangeSupervisorPhone = e => {
        this.setState({
            supervisorPhone: e
        })
    }

    onChangePMPhone = e => {
        this.setState({
            pmPhone: e
        })
    }

    onChangeInspectionDate = date => {
        this.setState({
            inspectionTimeStamp: date
        })
    }

    // address from modal
    handleCallback = (childData) => {
        this.setState({
            shipAddress: childData.address,
            destinationTime: childData.duration,
            distance: childData.distance,
            latitude: childData.destLat,
            longitude: childData.destLng,
            searchAddress: false
        })
    }

    updateJob = async () => {
        const job = {
            name: this.state.name,
            jobDescription: this.state.jobDescription,
            shipAddress: this.state.shipAddress,
            jobForeman: this.state.jobForeman,
            foremanPhone: this.state.foremanPhone,
            apContact: this.state.apContact,
            apPhone: this.state.apPhone,
            contractor: this.state.contractor,
            contractorContact: this.state.contractorContact,
            contractorAddress: this.state.contractorAddress,
            contractorCity: this.state.contractorCity,
            contractorPhone: this.state.contractorPhone,
            contractorState: this.state.contractorState,
            contractorType: this.state.contractorType,
            contractorZip: this.state.contractorZip,
            loadYD3: this.state.loadYD3,
            targetYD3: this.state.targetYD3,
            balance: this.state.balance,
            jobStatus: this.state.jobStatus,
            scheduledDate: this.state.scheduledDate,
            slump: this.state.slump,
            jobType: this.state.jobType,
            jobPoor: this.state.jobPoor,
            unitPrice: this.state.unitPrice,
            seller: this.state.seller,
            psi: this.state.psi,
            destinationTime: this.state.destinationTime,
            distance: this.state.distance,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            paymentType: this.state.paymentType,
            approved: this.state.approved,
            updatedBy: localStorage.getItem('userId'),
            supervisorPhone: this.state.supervisorPhone,
            pmPhone: this.state.pmPhone,
            supervisorName: this.state.supervisorName,
            projectManager: this.state.projectManager,
            isJob: this.state.isJob,
        }

        if (this.state.customer) {
            job.customer = this.state.customer
        }

        const url = api + '/job/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, job)
            this.props.history.push('/dailyappointment')
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    validatePsi = () => {
        if (this.state.psi) {
            return true
        } else {
            alert('PSI must be selected')
            return false
        }
    }

    validateSeller = () => {
        if (this.state.seller) {
            return true
        } else {
            alert('seller must be selected')
            return false
        }
    }

    validateAttributeDefinition = (attribute, msg) => {
        if (attribute) {
            return true
        } else {
            alert(msg)
            return false
        }
    }

    onSubmit(e) {
        e.preventDefault()
        if (this.validateAttributeDefinition(this.state.seller, 'Seller must be selected') &&
            this.validateAttributeDefinition(this.state.psi, 'PSI must be selected') &&
            this.validateAttributeDefinition(this.state.shipAddress, 'You need to define ship address')) {
            this.updateJob()
        }
    }

    getJob = async () => {
        const url = api + '/job/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            this.setState({
                customerName: data.customer.name,
                customerNumber: data.customer.customerNumber,
                sellerName: data.seller ? data.seller.name : 'Unasigned seller',
                seller: data.seller ? data.seller._id : '',
                name: data.name,
                scheduledDate: data.scheduledDate,
                scheduledTime: data.scheduledTime,
                loadYD3: data.loadYD3,
                targetYD3: typeof data.targetYD3 === "string" ? parseFloat(data.targetYD3) : data.targetYD3,
                balance: data.balance,
                jobDescription: data.jobDescription,
                jobStatus: data.jobStatus,
                customerId: data.customer._id,
                shipAddress: data.shipAddress,
                jobForeman: data.jobForeman,
                foremanPhone: data.foremanPhone,
                apContact: data.apContact,
                apEmail: data.apEmail,
                apPhone: data.apPhone,
                destinationMinutes: data.destinationMinutes,
                contractor: data.contractor,
                contractorContact: data.contractorContact,
                contractorPhone: data.contractorPhone,
                contractorAddress: data.contractorAddress,
                contractorCity: data.contractorCity,
                contractorState: data.contractorState,
                contractorZip: data.contractorZip,
                contractorType: data.contractorType,
                taxExemptJob: data.taxExemptJob,
                comments: data.comments,
                driver: data.driver,
                po: data.po,
                jobDuration: data.jobDuration,
                unitPrice: data.unitPrice,
                taxPrice: data.unitPrice * 1.0825,
                slump: data.slump,
                jobType: data.jobType,
                jobPoor: data.jobPoor,
                number: data.number,
                isPhoneQuote: data.isPhoneQuote,
                paid: data.paid,
                cliente: data.customer.customerNumber + ' ' + data.customer.name,
                psi: data.psi,
                destinationTime: data.destinationTime,
                distance: data.distance,
                latitude: data.latitude,
                longitude: data.longitude,
                paymentType: data.paymentType,
                approved: data.approved,
                hotShot: data.hotShot,
                supervisorPhone: data.supervisorPhone,
                pmPhone: data.pmPhone,
                supervisorName: data.supervisorName,
                projectManager: data.projectManager,
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }

    deleteJob = async () => {
        const url = api + '/job/' + this.props.match.params.id
        const urlLoad = api + '/loadjob/' + this.props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }


        const req1 = axios.delete(url)
        const req2 = axios.delete(urlLoad)

        // llamada simultanea a los 2 borrados
        axios.all([req1, req2]).then(axios.spread((...responses) => {
            const res1 = responses[0]
            const res2 = responses[1]

            this.props.history.push('/dailyappointment')

        })).catch(errors => {
            console.error(errors.message)
            return null
        })
    }

    jobPhotos = () => {
        const url = '/jobphotos/' + this.props.match.params.id
        this.props.history.push(url)
    }

    jobInvoice = () => {
        const url = '/invoice/' + this.props.match.params.id
        this.props.history.push(url)
    }

    showHistory = () => {
        const url = '/jobhistory/' + this.state.number
        this.props.history.push(url)
    }

    showQuote = () => {
        const url = '/quotepdf/' + this.props.match.params.id
        this.props.history.push(url)
    }

    JobForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <label className='text-muted'>Job ID</label>
            <div className='form-group'>
                <input
                    type='text'
                    className='form-control'
                    readOnly
                    value={this.state.number}
                />
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Current Customer</label>
                    <input
                        type='text'
                        name='name'
                        className='form-control'
                        readOnly
                        defaultValue={this.state.cliente}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>New customer</label>
                    <ClientPicker action={this.onChangeCustomer} />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Seller</label>
                    <input
                        type='text'
                        name='seller'
                        className='form-control'
                        readOnly
                        defaultValue={this.state.sellerName}
                    />
                </div>
                {
                    localStorage.getItem('role') === 'Admin' &&
                    <div className='col-sm-6'>
                        <label className='text-muted'>New Seller</label>
                        <SellerPicker action={this.onChangeSeller} />
                    </div>
                }
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job name</label>
                    <input
                        type='text'
                        name='name'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.name ? this.state.name : ''}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Description</label>
                    <input
                        type='text'
                        name='jobDescription'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.jobDescription ? this.state.jobDescription : ''}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Type: {this.state.jobType} </label>
                    <JobTypePicker action={this.onChangeJobType} placeholder={'Job Type...'}/>
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Pour: {this.state.jobPoor} </label>
                    <JobPoorPicker action={this.onChangeJobPoor} placeholder={'Job Pour...'}/>
                </div>
            </div>
            <div className="form-group">
                <label className='text-muted'>PSI: {this.state.psi} </label>
                <PsiPicker action={this.onChangePsi} />
            </div>
            <div className="form-group row">
                <label className='text-muted'>Ship Address</label>
                <div className='col-sm-12'>
                    <input
                        type="text"
                        name='shipAddress'
                        className="form-control"
                        onChange={this.inputChangeHandler}
                        value={this.state.shipAddress}
                    />
                </div>
                {/* <div className='col-sm-4'>
                    <button 
                        className='btn btn-primary' 
                        onClick={this.onShowModal}
                    >
                        Change address <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>                                   */}
            </div>
            <div>
                {
                    this.state.searchAddress &&
                    <Modal show={this.state.searchAddress} animation={false} onHide={this.onShowModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressGeolocation
                                search={true}
                                parentCallback={this.handleCallback}
                                single={false}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Foreman</label>
                    <input
                        type='text'
                        name='jobForeman'
                        className='form-control'
                        placeholder='Job foreman'
                        value={this.state.jobForeman}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Foreman Phone </label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Foreman phone"
                        value={this.state.foremanPhone}
                        onChange={this.onChangeForemanPhone}
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor</label>
                    <input
                        type='text'
                        name='supervisorName'
                        className='form-control'
                        placeholder='Supervisor name'
                        value={this.state.supervisorName}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='supervisorPhone'
                        placeholder="Phone number"
                        value={this.state.supervisorPhone}
                        onChange={this.onChangeSupervisorPhone}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Project Manager</label>
                    <input
                        type='text'
                        name='projectManager'
                        className='form-control'
                        placeholder='PM Name'
                        value={this.state.projectManager}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Project Manager Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='pmPhone'
                        placeholder="PM Phone"
                        value={this.state.pmPhone}
                        onChange={this.onChangePMPhone}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Tax Exempt Job</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='taxExemptJob'
                                value='Yes'
                                onChange={this.inputChangeHandler}
                            />
                            Yes
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='taxExemptJob'
                                value='No'
                                checked
                                onChange={this.inputChangeHandler}
                            />
                        </label>
                        No
                    </div>
                </div>
                {
                    localStorage.getItem('role') === 'Finance' &&
                    <div className='col-sm-6'>
                        <label className='text-muted'>
                            Payment type: {this.state.paymentType}
                        </label>
                        <PaymentTypePicker action={this.onChangePaymentType} />
                    </div>
                }
            </div>
            <hr />
            <div className="form-group row">
                <div className='col-sm-4'>
                    <label className='text-muted'>Target YD3</label>
                    <input
                        type="text"
                        name='targetYD3'
                        className='form-control'
                        required
                        onChange={this.inputChangeHandler}
                        value={this.state.targetYD3}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Balance</label>
                    <input
                        type="number"
                        name='balance'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.balance}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Job Duration</label>
                    <input
                        type="text"
                        name='jobDuration'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <div>
                        <label className="text-muted">
                            Current Job Date: {new Date(this.state.scheduledDate).toLocaleDateString()}
                            &nbsp;
                        - Time: {new Date(this.state.scheduledDate).toLocaleTimeString()}
                        </label>
                    </div>
                    <div>
                        New date:
                        <DatePicker
                            selected={this.state.date}
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            onChange={this.onChangeDate}
                        />
                    </div>
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Time to destination (min)</label>
                    <input
                        type="text"
                        name='destinationMinutes'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.destinationMinutes}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Unit price</label>
                    <input
                        type="text"
                        name='unitPrice'
                        className="form-control"
                        value={this.state.unitPrice}
                        onChange={this.unitPriceInput}
                        placeholder="$"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Price with Tax</label>
                    <input
                        type="text"
                        name='taxPrice'
                        readOnly
                        className='form-control'
                        value={toCurrency(this.state.taxPrice, 2)}
                    />
                </div>
            </div>
            {/* <div className='form-group'>
                <label className='text-muted'>Purchase Order #</label>
                <input 
                    type='text'
                    name='po'
                    className='form-control'      
                    onChange={this.inputChangeHandler}  
                    value={this.state.po}                                          
                />
            </div> */}
            <div className='form-group'>
                <label className='text-muted'>Comments</label>
                <input
                    type='text'
                    name='comments'
                    className='form-control'
                    onChange={this.inputChangeHandler}
                    value={this.state.comments}
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Current Slump: {this.state.slump} </label>
                <SlumpPicker action={this.onChangeSlump} />
            </div>
            <div className="my-2">
                <input name="accept"
                    type="checkbox"
                    checked={this.state.isJob}
                    onChange={this.onQuoteStatusChange}
                />
                <label>&nbsp;Confirm Job</label>
            </div>
            <div className="my-2">
                <input className='btn btn-success' value='Save' type='submit' />
                &nbsp;
                {/* <input 
                    type="text"
                    readOnly
                    value="Create invoice"
                    className="btn btn-primary"
                    onClick={(e) => this.jobInvoice()}
                /> */}
                {' '}
                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Finance') &&
                    <input
                        type="text"
                        className="btn btn-danger"
                        readOnly
                        value="Delete Quote"
                        onClick={(e) => { if (window.confirm('Are you sure you want to delete the quote?')) this.deleteJob() }}
                    />
                }
                &nbsp;
                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Seller') &&
                    <button
                        className='btn btn-secondary'
                        onClick={this.jobPhotos}
                    >
                        Photos
                    </button>
                }
                &nbsp;
                {
                    (localStorage.getItem('role') === 'Admin' ||
                        localStorage.getItem('role') === 'Finance') &&
                    <button
                        className='btn btn-warning'
                        onClick={this.showHistory}
                    >
                        Change Log
                     </button>
                }
                &nbsp;
                {
                    <button
                        className='btn btn-primary'
                        onClick={this.showQuote}
                    >
                        Quote PDF
                     </button>
                }
            </div>

        </form>
    )

    componentDidMount() {
        this.getJob()
    }

    render() {
        return (
            <Layout
                title={'Quote ID: ' + this.state.number}
                description='View/Change quote properties'
                className="container col-md-8 offset-md-2"
            >
                {this.JobForm()}
            </Layout>
        )
    }
}
