import {
    ADD_TRUCK,
    GET_TRUCK,
    GET_TRUCKS,
    UPDATE_TRUCK,
    TRUCK_ERROR
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    truck: null,
    trucks: []
}

function truckReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_TRUCK:
            return {
                ...state,
                truck: payload,
                loading: false
            }
        case GET_TRUCK:
            return {
                ...state,
                truck: payload,
                loading: false
            }
        case GET_TRUCKS:
            return {
                ...state,
                trucks: payload,
                loading: false
            }
        case UPDATE_TRUCK:
            return {
                ...state,
                truck: payload,
                loading: false
            }
        case TRUCK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default truckReducer