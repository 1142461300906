import PropTypes from 'prop-types';

// InfoWindow component
const InfoWindowComponent = (props) => {
    const { truck } = props;
    const infoWindowStyle = {
      position: 'relative',
      bottom: 150,
      left: '-45px',
      width: 220,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      fontSize: 14,
      zIndex: 100,
      borderRadius: 10
    };
  
    let color = '#bbb'
    let text = 'Not moving'
  
    if(truck.direction === 1 && truck.plant === -1){
      color = 'yellow'
      text = 'Back to plant'
    }else if((truck.direction === -1 && truck.plant === -1) || (truck.direction === 0 && truck.plant === -1)){
      color = 'green'
      text = 'In Progress'
    }else if(truck.plant === 0){
      color = '#ED1C24'
      text = 'On Mission plant'
    }else if(truck.plant === 1){
      color = '#233F86'
      text = 'On San Benito plant'
    }
  
  //   switch (truck.direction) {
  //     case 1:
  //         color = 'yellow'
  //         text = 'Back to plant'
  //         break;
  //     case -1:
  //         color = 'green'
  //         text = 'In Progress'
  //         break;
  //     default:
  //         break;
  //   }
  
    return (
      <div style={infoWindowStyle}>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          Truck {truck.number}
        </div>
        <div style={{ fontSize: 14}}>
          <b>Latitude:</b> {truck.latitude}
        </div>
        <div style={{ fontSize: 14}}>
          <b>Longitude:</b> {truck.longitude}
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{borderRadius: '50%', width: 10, height: 10, backgroundColor: `${color}`, marginRight: 10}} />
          {text}
        </div>
      </div>
    );
};

InfoWindowComponent.propTypes = {
    hover: PropTypes.bool.isRequired,
    truck: PropTypes.shape({
        number: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
};

export default InfoWindowComponent;