import React from 'react'
import Select from 'react-select'

const TermPicker = props => {

    const options = [
        { value: '7', label: '7' },
        { value: '14', label: '14' },
        { value: '21', label: '21' },
        { value: '30', label: '30' }              
      ]

    return (
        <div>
            <Select
                options={options}
                placeholder='Term'
                onChange={props.action}
            />
        </div>
    )
}

export default TermPicker