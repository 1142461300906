import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

const SubplantModal = ({subplants, setSubplants, toggleModal, currentSubplant, edit, index}) => {

    const [name, setName] = useState('')
    const [productionCapacity, setProductionCapacity] = useState('')
    const [timeToLoadTruck, setTimeToLoadTruck] = useState('')
    const [slumpRackTime, setSlumpRackTime] = useState('')
    const [washoutOnSite, setWashoutOnSite] = useState('')
    const [startTime, setStartTime] = useState(new Date())
    const [closingTime, setClosingTime] = useState(new Date())
    const [radioAttention, setRadioAttention] = useState('')
    const [dieselPerformance, setDieselPerformance] = useState('')
    const [active, setActive] = useState(true)

    useEffect(() => {
        assignData()
    }, [edit])

    const assignData = () => {
        if(edit){
            setName(subplants[index].name)
            setProductionCapacity(subplants[index].productionCapacity)
            setTimeToLoadTruck(subplants[index].timeToLoadTruck)
            setSlumpRackTime(subplants[index].slumpRackTime)
            setWashoutOnSite(subplants[index].washoutOnSite)
            setStartTime(new Date(subplants[index].startTime))
            setClosingTime(new Date(subplants[index].closingTime))
            setRadioAttention(subplants[index].radioAttention)
            setDieselPerformance(subplants[index].dieselPerformance)
            setActive(subplants[index].active)
        }
    }
    
    const onSubmit = (e) => {
        e.preventDefault()

        const newSubplant = {
            'name': name,
            'productionCapacity': productionCapacity,
            'timeToLoadTruck': timeToLoadTruck,
            'slumpRackTime': slumpRackTime,
            'washoutOnSite': washoutOnSite,
            'startTime': startTime,
            'closingTime': closingTime,
            'radioAttention': radioAttention,
            'dieselPerformance': dieselPerformance,
            'active': active,
        };
        if(edit){
            subplants[index]['name'] = name
            subplants[index]['productionCapacity'] = productionCapacity
            subplants[index]['timeToLoadTruck'] = timeToLoadTruck
            subplants[index]['slumpRackTime'] = slumpRackTime
            subplants[index]['washoutOnSite'] = washoutOnSite
            subplants[index]['startTime'] = startTime
            subplants[index]['radioAttention'] = radioAttention
            subplants[index]['closingTime'] = closingTime
            subplants[index]['dieselPerformance'] = dieselPerformance
            subplants[index]['active'] = active
        }else{
            subplants.push(newSubplant)
        }
        setSubplants(subplants);
        toggleModal();
    }

    return (
        <form className='form' onSubmit={onSubmit}>
            <h3>{edit ? 'Edit Subplant' : 'Create Subplant'}</h3>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <p className="text-muted">Name</p>
                    <input
                        type="text"
                        name='name'
                        className='form-control'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className="text-muted">Production Capacity</label>
                    <input
                        onChange={(e) => setProductionCapacity(e.target.value)}
                        type="number"
                        name='productionCapacity'
                        className="form-control"
                        value={productionCapacity}
                        required
                        step={0.01}
                        min={0}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Time to load truck</label>
                    <input
                        type="number"
                        name='timeToLoadTruck'
                        className='form-control'
                        onChange={(e) => setTimeToLoadTruck(e.target.value)}
                        value={timeToLoadTruck}
                        required
                        step={0.01}
                        min={0}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className="text-muted">Slump rack time</label>
                    <input
                        onChange={(e) => setSlumpRackTime(e.target.value)}
                        type="number"
                        name='slumpRackTime'
                        className="form-control"
                        value={slumpRackTime}
                        required
                        step={0.01}
                        min={0}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Washout on site</label>
                    <input
                        type="number"
                        name='washoutOnSite'
                        className='form-control'
                        onChange={(e) => setWashoutOnSite(e.target.value)}
                        value={washoutOnSite}
                        required
                        step={0.01}
                        min={0}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Radio Attention</label>
                    <input
                        type="number"
                        name='radioAttention'
                        className='form-control'
                        onChange={(e) => setRadioAttention(e.target.value)}
                        value={radioAttention}
                        required
                        step={0.01}
                        min={0}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Start Time</label>
                    <DatePicker
                    selected={startTime}
                    onChange={date => setStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Closing Time</label>
                    <DatePicker
                    selected={closingTime}
                    onChange={date => setClosingTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    required
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>Diesel Performance</label>
                    <input
                        type="number"
                        name='dieselPerformance'
                        className='form-control'
                        onChange={(e) => setDieselPerformance(e.target.value)}
                        value={dieselPerformance}
                        required
                        step={0.01}
                        min={0}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Active</label>
                    &nbsp;
                    <input
                        type="checkbox"
                        name='active'
                        onChange={(e) => setActive(e.target.checked)}
                        checked={active}
                    />
                </div>
                
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>

        </form>
    )
}

export default SubplantModal