import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_CLIENT,
    UPDATE_CLIENT,
    GET_CLIENT,
    GET_CLIENTS,
    DELETE_CLIENT,
    CLIENT_ERROR,
    GET_CLIENTS_ALL,
    CLIENT_GROUP
} from './types'

// Create new client
export const createClient = (formData) => async dispatch => {
    try {
        const { data } = await api.post('/customer', formData)

        dispatch({
            type: ADD_CLIENT,
            payload: data
        })

        dispatch(setAlert('Customer Added', 'success'))
    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
        }

        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// Get client (customer POV)
export const getMyCompany = id => async dispatch => {
    try {
        const res = await api.get(`/mycompany/${id}`)

        console.log(res.data)

        dispatch({
            type: GET_CLIENT,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// Update client info
export const updateCompany = (clientId, formData) => async dispatch => {
    try {
        const res = await api.patch(`/customer/${clientId}`, formData)

        dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
        })

        dispatch(setAlert('Client updated', 'success'))
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// client list for a seller
export const getClientList = id => async dispatch => {
    console.log(`mi lista de clientes, seller: ${id}`)
    try {
        const res = await api.get(`/myclientlist/${id}`)
        console.log(res.data)

        dispatch({
            type: GET_CLIENTS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// Whole client list (admin)
export const getAllClientList = () => async dispatch => {
    try {
        const { data } = await api.get('/allcustomers')

        dispatch({
            type: GET_CLIENTS_ALL,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// Delete client (admin)
export const deleteClient = id => async dispatch => {
    try {
        const res = await api.delete(`/customer/${id}`)

        dispatch({
            type: DELETE_CLIENT,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// paged client list
export const pagedClient = (skip, limit) => async dispatch => {
    try {
        const { data } = await api.get(`/customer?limit=${limit}&skip=${skip}`)

        dispatch({
            type: GET_CLIENTS,
            payload: data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response, status: err.response }
        })
    }
}

// get client type
export const typeClient = type => async dispatch => {
    try {
        const { data } = await api.get(`/custclassification/${type}`)

        dispatch({
            type: GET_CLIENTS,
            payload: data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// client search
export const searchClient = term => async dispatch => {
    console.log(`search by term: ${term}`)
    try {
        const { data } = await api.get(`customers/search?term=${term}`)
        console.log(data)

        dispatch({
            type: GET_CLIENTS,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// list customers with invoices
export const customerWithInvoice = () => async dispatch => {
    try {
        const { data } = await api.get('groupinvoice')
        dispatch({
            type: GET_CLIENTS,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// list customers with invoices on search
export const customerWithInvoiceSearch = (year, month, term) => async dispatch => {
    try {
        const { data } = await api.get(`groupinvoice/search?year=${year}&month=${month}&term=${term}`)
        dispatch({
            type: GET_CLIENTS,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: err.response.statatusText, status: err.response.status }
        })
    }
}

// gruped client
export const groupedCustomers = () => async dispatch => {
    try {
        const { data } = await api.get('custclassgroup')
        dispatch({
            type: CLIENT_GROUP,
            payload: data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: CLIENT_ERROR,
            payload: { msg: 'Could not group customers', status: 404 }
        })
    }
}
