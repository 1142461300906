import React, { Component, Fragment } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import DatePicker from 'react-datepicker'
import JobItemCompany from '../job/JobItemCompany'
import { api } from '../utils/Api'

export default class DailyAppointment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            sumLoadYd3: 0,
            date: new Date(),
            changeDate: false,
            loading: true
        }
    }

    onChangeDate = date => {
        this.setState({
            date,
            changeDate: true
        })
    }

    getJobs = async () => {        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const url = api + '/mycompany/' + this.props.match.params.id        
            const result = await axios.get(url)
            const id = result.data._id

            const urlJob = api + '/joblist/' + id
            const { data } = await axios.get(urlJob)

            const totalLoad = data.reduce((acc,elJob) => acc + elJob.loadYD3, 0)            

            this.setState({
                loading: false,
                jobs: data,
                sumLoadYd3: totalLoad                
            })       
            
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidUpdate() {        
        if (this.state.changeDate) {
            this.getJobs()

            this.setState({
                changeDate: false
            })
        }        
    }

    componentDidMount() {
        this.getJobs()
    }

    losJobs() {
        if (this.state.jobs.length > 0) {
            return this.state.jobs.map((currentJob, i) => {
                return <JobItemCompany job={currentJob} key={i} />
            })
        } else {
            return (
                <tr>
                    <td colSpan="16" style={{'textAlign':'center'}}>
                        No jobs for customer
                    </td>
                </tr>
            )
        }
    }

    renderLoading() {
        return <Spinner />
    }

    renderJobs() {
        return (
            <Fragment>
                <h3>Total YD: {this.state.sumLoadYd3}</h3>
                {/* <div>
                    Date: 
                    <DatePicker 
                        selected={this.state.date} 
                        onChange={this.onChangeDate}                                                
                    /> 
                </div>               */}
                <div
                    style={{fontSize: '15px'}}
                >
                               
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Job</th>                                                       
                            <th>Main Address</th>
                            <th>Job Address</th>
                            <th>Load YD3</th>
                            <th>Target YD3</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Time to destination</th>                            
                            <th>Unit Price</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        { this.losJobs() }
                    </tbody>
                </table>    
                </div>            
            </Fragment>
        )
    }

    render() {
        return (
            <Layout
                title='Daily Appointment for company'
                description='Job list'
            >                   
                {
                    this.state.loading ?
                    this.renderLoading()
                    : this.renderJobs()
                }
            </Layout>
        )
    }
}