import {
    ADD_INVOICE,
    GET_INVOICE,
    GET_INVOICES,
    INVOICE_ERROR,
    UPDATE_INVOICE,
} from '../actions/types'

const initialState = {
    loading: true,
    invoice: '',
    invoices: []
}

function invoiceReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_INVOICE:
            return {
                ...state,
                invoice: payload,
                loading: false
            }
        case GET_INVOICE:
            return {
                ...state,
                invoice: payload,
                loading: false
            }
        case GET_INVOICES:
            return {
                ...state,
                invoices: payload,
                loading: false
            }
        case UPDATE_INVOICE:
            return {
                ...state,
                invoice: payload,
                loading: false
            }
        case INVOICE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default invoiceReducer

