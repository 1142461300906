import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import axios from 'axios'
import { Flex, Radio, Table, Typography } from 'antd'
import { CustomSpinner } from '../utils/CustomComponents'
const { Text } = Typography
const JobsSellerStatus = ({today, plant}) => {

  const [jobs, setJobs] = useState([])
  const [jobs2, setJobs2] = useState([])
  const [loading, setLoading] = useState(true)
  const [type, setType] = useState('Operations Done')

  /**
   ** Options header (columns)
  */
  const columns = [
    {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'seller',
        width: 100,
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Target YD3',
        dataIndex: 'targetYD3',
        key: 'targetYD3',
        width: 60,
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Load YD3',
        dataIndex: 'loadYD3',
        key: 'loadYD3',
        width: 60,
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
  ]

  useEffect(() => {
    getJobs2(today, plant)
  }, [today])

  const assignOPjobs = (jobs) => {
    const groupByCustomer = jobs.reduce((group, object) => {
      const { name } = object.seller;
      group[name] = group[name] ?? [];
      group[name].push(object);
      return group;
    }, {})

    const arrayObjects = Object.values(groupByCustomer)

    let finalList = []

    arrayObjects.map((item) => {
      let loadYards = 0
      let targetYards = 0
      let sellerName = item[0].seller.name
      for (let is = 0; is < item.length; is++) {
          const element = item[is];
          loadYards += element.loadYD3
          targetYards += element.targetYD3
      }

      finalList.push({
          seller: sellerName,
          loadYD3: loadYards,
          targetYD3: targetYards,
      })
    })

    setJobs(finalList)
  }
  const assingScheduledJobs = (jobs) => {
    const groupByCustomer = jobs.reduce((group, object) => {
      const { name } = object.seller;
      group[name] = group[name] ?? [];
      group[name].push(object);
      return group;
    }, {})

    const arrayObjects = Object.values(groupByCustomer)

    let finalList = []

    arrayObjects.map((item) => {
      let loadYards = 0
      let targetYards = 0
      let sellerName = item[0].seller.name
      for (let is = 0; is < item.length; is++) {
          const element = item[is];
          loadYards += element.loadYD3
          targetYards += element.targetYD3
      }

      finalList.push({
          seller: sellerName,
          loadYD3: loadYards,
          targetYD3: targetYards,
      })
    })

    setJobs2(finalList)
  }

  const getJobs2 = async (today, plant) => {
    const url = api + `/jobdateplant?date=${today}&plant=${plant}`
    const token = localStorage.getItem('token')
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

    try {
      const { data } = await axios.get(url)
      
      const filteredJobs = data.filter(job => job.jobStatus === 'Operations done (AR)' || job.jobStatus === 'ok' || job.jobStatus === 'Confirm')
      const filteredJobs2 = data.filter(job => job.jobStatus === 'Scheduled')

      assignOPjobs(filteredJobs)
      assingScheduledJobs(filteredJobs2)

      setLoading(false)

    } catch (err) {
      console.error(err)
      return null
    }
  }

  const OPTable = () => {
    return <Table 
      columns={columns}
      dataSource={jobs}
      pagination={false}
      />
  }

  const ScheduledTable = () => {
    return <Table 
      columns={columns}
      dataSource={jobs2}
      pagination={false}
      />
  }

  const onChangeType = (e) => {
    setType(e.target.value)
  }

  const RenderTable = () => {
    return <Flex vertical gap={'middle'}>
      <Radio.Group value={type} onChange={onChangeType} buttonStyle="solid">
        <Radio.Button value="Operations Done">Operations Done</Radio.Button>
        <Radio.Button value="Scheduled">Scheduled</Radio.Button>
      </Radio.Group>
      {
        type === 'Operations Done' &&
        <OPTable />
      }
      {
        type === 'Scheduled' &&
        <ScheduledTable />
      }
    </Flex>
  }

  
  return (
    <Flex vertical gap={'large'}>
      {
        loading ? <CustomSpinner/> : <RenderTable/>
      } 
    </Flex>
  )
}

export default JobsSellerStatus