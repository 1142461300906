import api from '../utils/api'
import { setAlert } from './alert'
import {
    GET_INVOICE,
    ADD_INVOICE,
    GET_INVOICES,
    UPDATE_INVOICE,
    INVOICE_ERROR,
} from './types'

// add new invoice
export const newInvoice = (formData) => async (dispatch) => {
    try {
        const { data } = await api.post('/invoice', formData)
        console.log(data)
        dispatch({
            type: ADD_INVOICE,
            payload: data
        })
    } catch (err) {
        console.err(err)
        dispatch({
            type: INVOICE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// get invoice by id
export const getInvoice = id => async (dispatch) => {
    try {
        const { data } = await api.get(`/invoice/${id}`)
        dispatch({
            type: GET_INVOICE,
            payload: data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: INVOICE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// update invoice properties
export const updateInvoice = (invoiceId, formData) => async (dispatch) => {
    console.log(formData)
    try {
        const { data } = await api.patch(`/invoice/${invoiceId}`, formData)

        dispatch({
            type: UPDATE_INVOICE,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: INVOICE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// list invoices for customer
export const invoicesForCustomer = clientId => async (dispatch) => {
    try {
        const { data } = await api.get(`/customerinvoice/${clientId}`)

        dispatch({
            type: GET_INVOICES,
            payload: data
        })
    } catch (err) {
        dispatch({
            type: INVOICE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}
