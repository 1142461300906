import { Button, Col, Form, Input, InputNumber, Row, Select, notification, Typography, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import {
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons'
const {Title} = Typography

const AggregateNew = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [optionsCog, setOptionsCog] = useState([])
    const [optionsPsi, setOptionsPsi] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getCogs()
        getPsi()
    }, [])

    const getCogs = async () => {
        const url = api + '/cog'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url) 
            let list = []
            data.map((item) => {
                list.push({
                    value: `${item._id} ${item.unitOfConversion} ${item.unitPriceConversion}`,
                    label: item.material
                })
            })     
            setOptionsCog(list)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getPsi = async () => {
        const url = api + '/psi'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url) 
            let list = []
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.psi
                })
            })     
            setOptionsPsi(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createAggregate = async (data) => {
        try {
            setLoading(true)
            let currentCogs = []
            
            data.cogs.map((cog) => {
                let cogArray = cog.cog.split(" ")
                currentCogs.push({
                    cog: cogArray[0],
                    quantity: cog.quantity
                })
            })

            const formData = {
                name: data.name,
                psi: data.psi,
                cogs: currentCogs,
                createdBy: localStorage.getItem('userId'),
                updatedBy: localStorage.getItem('userId')
            }
        
            let url = api + '/aggregate' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, formData)
            openNotification('success', 'Success', 'Aggregate created')
            setTimeout(() => {
                setLoading(false)
                history.push('/aggregatelist')
            }, 1000);

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create aggregate')
            console.error(err.message)
            return null
        }
    }

    const sixPointDecimal = number => Number(number).toFixed(6);
    
    const onChange = (value) => {
        let total = 0
        const cogs = form.getFieldValue('cogs')
        const updatedCogs = cogs.map(cog => {
            if (cog !== undefined && cog.cog === value) {
                let cogArray = cog.cog.split(" ")
                if(cog.quantity !== undefined) total += (cog.quantity * cogArray[2])
                return {
                ...cog,
                unit: cogArray[1],
                price: sixPointDecimal(cogArray[2]),
                subtotal: cog.quantity !== undefined ? sixPointDecimal(cog.quantity * cogArray[2]) : 0,
                }
            }
            total += (cog.quantity * cog.price)
            return cog
        })
        form.setFieldsValue({ cogs: updatedCogs })
        form.setFieldsValue({ total: sixPointDecimal(total) })

        let options = optionsCog
        let index = optionsCog.findIndex(obj => obj.value === value)
        options[index].disabled = true
        options.map((item) => {
            if(updatedCogs.find(o => o.cog === item.value)){
                item.disabled = true
            }else{
                item.disabled = false
            }
        })
        setOptionsCog(options)
    }

    const onChangeQuantity = (value, indexValue) => {
        let total = 0
        const cogs = form.getFieldValue('cogs')
        const updatedCogs = cogs.map((cog, index2) => {
            if (cog.cog !== undefined && indexValue === index2) {
                let cogArray = cog.cog.split(" ")
                total += (value * cogArray[2])
                return {
                ...cog,
                quantity: value,
                unit: cogArray[1],
                price: sixPointDecimal(cogArray[2]),
                subtotal: sixPointDecimal(value * cogArray[2]),
                }
            }else if(cog.cog === undefined){
                total += 0
            }else{
                total += (cog.quantity * cog.price)
            }
            
            return cog
        })
        form.setFieldsValue({ cogs: updatedCogs })
        form.setFieldsValue({ total: sixPointDecimal(total) })
    }

    const onRemoveItem = (removeFunction, name) => {
        let cogs = form.getFieldValue('cogs')
        let total = form.getFieldValue('total')
        if(cogs[name] === undefined){
            removeFunction(name)
        }else if(!cogs[name].cog){
            removeFunction(name)
        }else{
            let cog = cogs.at(name).cog
            let subtotal = cogs.at(name).subtotal
            let newTotal = total - subtotal
            let options = optionsCog
            let index = optionsCog.findIndex(obj => obj.value === cog)
            options[index].disabled = false
            form.setFieldsValue({ total: sixPointDecimal(newTotal) })
            setOptionsCog(options)
            removeFunction(name)
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const onFinish = (values) => {
        createAggregate(values)
    }

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                total: 0
            }}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={3}>Product Details</Title>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Psi'
                    name='psi'
                    rules={[
                        {
                            required: true,
                            message: 'Please select psi'
                        }
                    ]}
                    >
                        <Select 
                        size='large' 
                        options={optionsPsi}
                        placeholder="Please select psi"
                        filterOption={filterOption}
                        optionFilterProp="children"
                        showSearch
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={3}>Cogs</Title>
                </Col>
                <Col span={24}>
                    <Form.List name="cogs">
                        {(fields, { add, remove }) => (
                            <>
                            {
                            fields.map(({ key, name, ...restField }) => (
                                <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                }}
                                align="baseline"
                                >
                                <Form.Item
                                {...restField}
                                name={[name, 'quantity']}
                                label='Quantity'
                                rules={[
                                {
                                    required: true,
                                    message: 'Missing quantity',
                                },
                                ]}
                                >
                                    <InputNumber 
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    onChange={e => onChangeQuantity(e,name)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'cog']}
                                    label='Cog'
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Missing cog item',
                                    },
                                    ]}
                                >
                                    <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    size='large'
                                    placeholder="Please select cog"
                                    options={optionsCog}
                                    onChange={e => onChange(e,name)}
                                    />
                                </Form.Item>
                                
                                <Form.Item
                                    {...restField}
                                    name={[name, 'unit']}
                                    label='Unit'
                                >
                                    <Input disabled size='large' />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'price']}
                                    label='Price'
                                >
                                    <InputNumber 
                                    disabled
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'subtotal']}
                                    label='Subtotal'
                                >
                                    <InputNumber 
                                    disabled
                                    size='large' 
                                    style={{width: '100%'}} 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => {onRemoveItem(remove,name)}} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Cog
                                </Button>
                            </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item
                        name='total'
                        label='Total'
                        >
                            <InputNumber 
                            size='large' 
                            style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            disabled  
                            />
                        </Form.Item>
                    </Col>
                </Row>
                
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Aggregate'
        description='Create new Aggregate'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default AggregateNew