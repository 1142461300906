import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../../layout/Layout'
import Spinner from '../../layout/Spinner'
import { api } from '../../utils/Api'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

const Departments = () => {
    const [loading, setLoading] = useState(true)
    const [departmentList, setDepartmentList] = useState([])

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        getDepartmentListSearch()
    }

    const getDepartmentListSearch = async() => {
        setLoading(true)

        const url = api + `/department`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setDepartmentList(data)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const RenderTable = () => {
        return <div style={{maxWidth: '100%', marginTop: 20}}>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Head</th>
                        <th>Parent Department</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        departmentList.length !== 0 ?
                        departmentList.map((department) =>{
                            return (
                                <tr key={department._id}>
                                    <td><Link  to={'/department/'+department._id}>{`${department.name}`}</Link></td>
                                    <td>{department.head ? `${department.head.name} ${department.head.middleName} ${department.head.lastName}` : 'NA'}</td>
                                    <td>{department.pDepartment ? department.pDepartment.name : 'NA'}</td>
                                    <td>
                                        <input 
                                            type='checkbox'
                                            checked={department.active}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan={7} className='text-center'>No departments to display</td></tr>
                    }
                </tbody>
            </table>
        </div>
    }    

    return (
        <Layout
            title='Departments'
            description='List all departments'
            className='container col-md-10'
        >
            <div>
                <Link
                    className='btn btn-success'
                    to='/newdepartment'
                >
                    <FontAwesomeIcon icon={faPlusSquare} />&nbsp;New
                </Link>
            </div>
            &nbsp;
            {
                loading ? <RenderLoading/> : <RenderTable/>
            } 
        </Layout>
    )
}

export default Departments