import { Button, Col, Flex, Form, Input, InputNumber, Row, Select, Table, Typography, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    FilePdfOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import { api } from '../utils/Api'
import axios from 'axios'
import formatDate from '../utils/FormatDate';
import dayjs from 'dayjs'

const { Text } = Typography

const LoadJob2 = ({job}) => {
    const [optionsDriver, setOptionsDriver] = useState([])
    const [optionsTruck, setOptionsTruck] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [loadSum, setLoadSum] = useState(0)
    const [loadList, setLoadList] = useState([])
    const [form] = Form.useForm()
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return <Flex gap={'small'}>
                <Button 
                type="primary" 
                danger
                icon={<MinusCircleOutlined twoToneColor={'white'}/>} 
                disabled={
                    (localStorage.getItem('email') === "vm@57concrete.com" ||
                    localStorage.getItem('email') === "fc@57concrete.com" ||
                    localStorage.getItem('email') === "jp@57concrete.com" ||
                    localStorage.getItem('email') === "ec@57concrete.com" ||
                    localStorage.getItem('email') === "eliud@gmail.com"
                    ) ? false : true
                }
                onClick={e => deleteLoad(record)}
                />
              </Flex>
            }
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Load',
            dataIndex: 'yards',
            key: 'yards',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Ticket',
            dataIndex: 'deliveryTicket',
            key: 'deliveryTicket',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Order Code',
            dataIndex: 'orderCode',
            key: 'orderCode',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Sequence',
            dataIndex: 'sequence',
            key: 'sequence',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'E-Ticket',
            dataIndex: 'eticket',
            key: 'eticket',
            render: (_, record) => {
                return <Button 
                type="primary" 
                icon={<FilePdfOutlined twoToneColor={'white'}/>} 
                htmlType='button'
                href={`http://ec2-3-85-198-38.compute-1.amazonaws.com/${record.id}`}
                target='_blank'
                // onClick={e=> history.push(`/e-ticket/${record.id}`)}
                />
            }
        },
    ]

    useEffect(() => {
        getValues()
    }, [])

    const getValues = async() => {
        Promise.all([getTrucks(), getDrivers(), getLoadData()]).then((values) => {
            setDisabled(false)
        })
    }

    const getTrucks = async () => {
        const url = api + '/trucklist'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item) => {
                list.push({
                    value: item._id,
                    label: item.number
                })
            })
            setOptionsTruck(list)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getDrivers = async () => {
        const url = api + '/driver/list'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            const filteredData = data.filter((item) => item.active === true)
            let list = []
            filteredData.map((item) => {
                list.push({
                    value: item._id,
                    label: item.name.toUpperCase()
                })
            })
            setOptionsDriver(list)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getLoadData = async () => {
        const url = api + '/load/' + job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    driver: item.driver,
                    truck: item.truckNumber,
                    yards: item.yards,
                    date: dayjs(item.date).toDate().toLocaleTimeString(),
                    deliveryTicket: item.deliveryTicket,
                    orderCode: item.orderCode,
                    sequence: item.sequence
                })
            })
            setLoadList(list)
            const suma = list.map(viaje => viaje.yards).reduce((acc, acum) => acc + acum, 0)
            setLoadSum(suma)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // delete load
    const deleteLoad = async (load) => {
        setDisabled(true)
        const url = api + `/load/${load.id}`
        const urlHistory = api + `/history`
        const urlJob = api + `/job/${job._id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const bodyHistory = {
                date: new Date(),
                customer: job.customer._id,
                user: localStorage.getItem('userId'),
                type: 'Load',
                yards: load.yards,
                number: load.deliveryTicket
            }
            await axios.post(urlHistory, bodyHistory)
            await axios.delete(url)


            /*! Update load sum general */
            const currentSum = parseFloat(localStorage.getItem('loadSum'))
            const newSum = currentSum - load.yards
            const result = Math.round(newSum * 100) / 100
            localStorage.setItem('loadSum', result)

            await getLoadData()

            /*! New sum load job */
            let newSumLoad = loadSum - load.yards
            const jobPatch = {
                loadYD3: newSumLoad
            }
            await axios.patch(urlJob, jobPatch)
            setDisabled(false)
            openNotification('info', 'Info', 'Load Deleted')

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Send SMS to company contact and foreman
    const sendSMS = async (phone, values) => {
        const url = api + '/smsmessagetxt'

        let msg = `Your order with ${values.yards} yards has been loaded. `
        msg += `Ticket: ${values.deliveryTicket}. Driver: ${values.driver.label}.`

        if (values.truck !== '0') {
            msg += ' Truck No. ' + values.truck.label
        }

        msg += ' #57concrete'

        const smsBody = {
            phone,
            msg
        }

        try {
            await axios.post(url, smsBody)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    /*
        Send SMS messages only to registered phones
    */
    const sendMessages = (values) => {
        if (job.customer.contactPhone) {
            sendSMS(job.customer.contactPhone, values)
        }
        if (job.foremanPhone) {
            sendSMS(job.foremanPhone, values)
        }
        if (job.pmPhone) {
            sendSMS(job.pmPhone, values)
        }
        if (job.supervisorPhone) {
            sendSMS(job.supervisorPhone, values)
        }

    }

    // save new load
    const saveLoadData = async (sequenceNumber, values) => {
        setDisabled(true)
        const url = api + '/load'
        const urlJob = api + `/job/${job._id}`
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        const newLoad = {
            yards: values.yards,
            driver: values.driver.label,
            driverId: values.driver.value,
            job: job._id,
            date: new Date(),
            deliveryTicket: values.deliveryTicket,
            orderCode: values.orderCode,
            truckNumber: values.truck.label,
            truck: values.truck.value,
            sequence: sequenceNumber,
            createdBy: userId,
            updatedBy: userId
        }

        try {
            await axios.post(url, newLoad)

            /*! Manage sum load */
            const currentSum = parseFloat(localStorage.getItem('loadSum'))
            const newSumYardsTotal = currentSum + values.yards
            const resultNewLoadSum = Math.round(newSumYardsTotal * 100) / 100
            localStorage.setItem('loadSum', resultNewLoadSum)

            await getLoadData()

            const suma = loadSum + newLoad.yards
            const job = {
                loadYD3: suma
            }
            await axios.patch(urlJob, job)
            setDisabled(false)
            form.resetFields()
            openNotification('success', 'Success', 'Load Created')
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const editDriverTruck = async (values) => {
        const url = api + '/driver/update/' + values.driver.value
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        const driverNewTruck = {
            truck: {
                _id: values.truck.value,
                number: values.truck.label
            }
        }
        try {
            await axios.patch(url, driverNewTruck)
        } catch (error) {
            console.error(error.message)
            return null
        }
    }

    // check sequence
    const checkSequence = async (values) => {

        const startDate = new Date()

        const url = api + `/loadsequence?startDate=${formatDate(startDate)}&sequence=${values.sequence}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.post(url)

            if(data.loadId){
                const body = {
                    sequence : data.newSequence,
                    updatedBy: localStorage.getItem('userId')
                }
                updateLoad(data.loadId, body)
                saveLoadData(data.sequence, values)
                editDriverTruck(values)
                sendMessages(values)
            }else{
                saveLoadData(data.sequence, values)
                editDriverTruck(values)
                sendMessages(values)
            }
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // upload load sequence
    const updateLoad = async (loadId, body) => {
        const url = api + `/load/${loadId}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, body)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        checkSequence(values)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const RenderForm = () => {
        return <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='driver'
                        label='Driver'
                        rules={[
                        {
                            required: true,
                            message: 'Missing driver item',
                        },
                        ]}
                    >
                        <Select
                        style={{
                            width: '100%',
                        }}
                        size='large'
                        placeholder="Please select driver"
                        options={optionsDriver}
                        disabled={disabled}
                        labelInValue
                        optionFilterProp="children"
                        filterOption={filterOption}
                        showSearch
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='truck'
                        label='Truck'
                        rules={[
                        {
                            required: true,
                            message: 'Missing truck item',
                        },
                        ]}
                    >
                        <Select
                        style={{
                            width: '100%',
                        }}
                        size='large'
                        placeholder="Please select truck"
                        options={optionsTruck}
                        disabled={disabled}
                        labelInValue
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='yards'
                        label='Load'
                        rules={[
                            {
                                required: true,
                                message: 'Missing load quantity',
                            }
                        ]}
                    >
                        <InputNumber 
                        size='large' 
                        style={{width: '100%'}} 
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        disabled={disabled}
                        step={'0.1'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='deliveryTicket'
                        label='Delivery Ticket'
                        rules={[
                            {
                                required: true,
                                message: 'Missing delivery ticket',
                            }
                        ]}
                    >
                        <Input 
                        size='large' 
                        disabled={disabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='orderCode'
                        label='Order Code'
                        rules={[
                            {
                                required: true,
                                message: 'Missing order code',
                            }
                        ]}
                    >
                        <Input 
                        size='large' 
                        disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='sequence'
                        label='Sequence'
                        rules={[
                            {
                                required: true,
                                message: 'Missing load sequence',
                            }
                        ]}
                    >
                        <InputNumber 
                        size='large' 
                        style={{width: '100%'}} 
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        disabled={disabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType='submit' block size='large' icon={<PlusOutlined />} loading={disabled}>
                Add Load
            </Button>
        </Form>
    }

    const RenderFooter = () => {
        return <Text strong>{`Total Load: ${loadSum}`}</Text>
    }

    const RenderTable = () => {
        return <Table 
            columns={columns}
            dataSource={loadList}
            pagination={false}
            loading={disabled}
            footer={RenderFooter}
        />
    }

    return (
        <Flex vertical gap={'large'}>
            {contextHolder}
            <RenderTable />
            <RenderForm />
        </Flex>
        
    )
}

export default LoadJob2