import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Select, notification, Typography, Tag } from 'antd'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import { api } from '../utils/Api'
import axios from 'axios'
import { categoryOptions, containerOptions, measurementUnitOptions, statusOptions, typeOptions } from '../../constants/Inventory'
const { TextArea } = Input
const { Text, Title } = Typography
const dateFormat = 'MM/DD/YYYY'
const dateTimeFormat = 'MM/DD/YYYY HH:mm'

/**
 ** Options header (columns)
*/
const columns = [
    {
        title: 'Modified Date',
        dataIndex: 'date',
        key: 'date',
        render: (value) => {
            return <Text strong>{value}</Text>
        }
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Previous Cost',
        dataIndex: 'prevCost',
        key: 'prevCost',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Actual Cost',
        dataIndex: 'actualCost',
        key: 'actualCost',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Previous Quantity',
        dataIndex: 'prevQuantity',
        key: 'prevQuantity',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Actual Quantity',
        dataIndex: 'actualQuantity',
        key: 'actualQuantity',
        render: (value) => {
            return <Text>{value}</Text>
        }
    },
    {
        title: 'Original Status',
        dataIndex: 'originalStatus',
        key: 'originalStatus',
        render: (value) => {
            switch (value) {
                case 'Active':
                    return <Tag color='green'>{value}</Tag>
                case 'In Storage':
                    return <Tag color='blue'>{value}</Tag>
                case 'Disposed':
                    return <Tag color='yellow'>{value}</Tag>
                case 'Delivery':
                    return <Tag color='cyan'>{value}</Tag>
                case 'Sold Out':
                    return <Tag color='red'>{value}</Tag>
                default:
                    return <Tag color='default'>None</Tag>
            }
        }
    },
    {
        title: 'Actual Status',
        dataIndex: 'actualStatus',
        key: 'actualStatus',
        render: (value) => {
            switch (value) {
                case 'Active':
                    return <Tag color='green'>{value}</Tag>
                case 'In Storage':
                    return <Tag color='blue'>{value}</Tag>
                case 'Disposed':
                    return <Tag color='yellow'>{value}</Tag>
                case 'Delivery':
                    return <Tag color='cyan'>{value}</Tag>
                case 'Sold Out':
                    return <Tag color='red'>{value}</Tag>
                default:
                    return <Tag color='default'>None</Tag>
            }
        }
    },
]

const Inventory = (props) => {
    const [initialValues, setInitialValues] = useState ({})
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(true)
    const [providerOptions, setProviderOptions] = useState([])
    const [status, setStatus] = useState('')
    const [cost, setCost] = useState(0)
    const [history, setHistory] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getProviders()
        getInventory()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getInventory = async() => {
        const url = api + `/inventory/${props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)

            let costActual = 0
            let costLast = 0
            let costAverage = 0
            if(data.costPart.length > 0){
                if(data.costPart.length > 1){
                    costLast = data.costPart[1].cost
                    costActual = data.costPart[0].cost
                }else{
                    costActual = data.costPart[0].cost
                }
                data.costPart.map((part) => {
                    costAverage += part.cost
                })

                if(costAverage === 0){
                    costAverage = 0
                }else{
                    costAverage = (costAverage / data.costPart.length).toFixed(2)
                }
            }

            setInitialValues({
                ...initialValues,
                number: data.number,
                name: data.name,
                partNumber: data.partNumber ? data.partNumber : undefined,
                status: data.status,
                type: data.type,
                measurementUnit: data.measurementUnit ? data.measurementUnit : undefined,
                category: data.category ? data.category : undefined,
                manufacturerNumber: data.manufacturerNumber ? data.manufacturerNumber : undefined,
                model: data.model ? data.model : undefined,
                upc: data.upc ? data.upc : undefined,
                provider: data.provider ? data.provider._id : undefined,
                providerInfo: data.providerInfo ? data.providerInfo : undefined,
                notes: data.notes ? data.notes : undefined,
                invoices: data.invoices ? data.invoices : undefined,
                instalationDate: data.instalationDate ? dayjs(data.instalationDate) : undefined,
                manufacturerGuaranteeDate: data.manufacturerGuaranteeDate ? dayjs(data.manufacturerGuaranteeDate) : undefined,
                clientGuaranteeStartDate: data.clientGuaranteeStartDate ? dayjs(data.clientGuaranteeStartDate) : undefined,
                clientGuaranteeEndDate: data.clientGuaranteeEndDate ? dayjs(data.clientGuaranteeEndDate) : undefined,
                notesGuaranteeWorkForce: data.notesGuaranteeWorkForce ? data.notesGuaranteeWorkForce : undefined,
                notesGuaranteeParts: data.notesGuaranteeParts ? data.notesGuaranteeParts : undefined,
                storageLocation: data.storageLocation ? data.storageLocation : undefined,
                container: data.container ? data.container : undefined,
                quantity: data.quantity ? data.quantity : 0,
                costActual: costActual,
                costLast: costLast,
                costAverage: costAverage,
                salePrice: data.salePrice ? data.salePrice : 0,
                min: data.min ? data.min : 0,
                max: data.max ? data.max : 0,
                quantityIncrease: 0,
                quantityDecrease: 0
            })
            setStatus(data.status)
            setCost(costActual)

            let listHistory = []
            data.history.map((item, index)=> {
                listHistory.push({
                    key: index,
                    date: dayjs(item.createdAt).format(dateTimeFormat),
                    username: item.user ? item.user.name : '',
                    details: item.details,
                    originalStatus: item.originalStatus ? item.originalStatus : '',
                    actualStatus: item.actualStatus ? item.actualStatus : '',
                    actualCost: item.actualCost ? item.actualCost : 0,
                    prevCost: item.prevCost ? item.prevCost : 0,
                    actualQuantity: item.actualQuantity ? item.actualQuantity : 0,
                    prevQuantity: item.prevQuantity ? item.prevQuantity : 0,
                })
            })
            setHistory(listHistory)

            setLoading(false)
            setLoadingButton(false)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining the inventory')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const getProviders = async() => {
        const url = api + `/providerlist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((provider) => {
                list.push({
                    label: `${provider.name} - ${provider.phone}`,
                    value: provider._id
                })
            })
            setProviderOptions(list)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining providers')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const updateInventory = async(values) => {
        setLoadingButton(true)

        const url = api + `/inventory/${props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let newBody = {
                ...values,
                updatedBy: localStorage.getItem('userId')
            }

            if(values.instalationDate){
                newBody.instalationDate = dayjs(values.instalationDate).toDate()
            }
            if(values.manufacturerGuaranteeDate){
                newBody.manufacturerGuaranteeDate = dayjs(values.manufacturerGuaranteeDate).toDate()
            }
            if(values.clientGuaranteeStartDate){
                newBody.clientGuaranteeStartDate = dayjs(values.clientGuaranteeStartDate).toDate()
            }
            if(values.clientGuaranteeEndDate){
                newBody.clientGuaranteeEndDate = dayjs(values.clientGuaranteeEndDate).toDate()
            }

            let newHistory = []
            if(values.status !== status){
                newHistory.push({
                    originalStatus: status,
                    actualStatus: values.status,
                    details: 'Status Change',
                    actualQuantity: values.quantity,
                    prevQuantity: values.quantity,
                    user: localStorage.getItem('userId')
                })
            }

            if(values.costActual !== cost){
                newHistory.push({
                    originalStatus: status,
                    actualStatus: values.status,
                    prevCost: cost,
                    actualCost: values.costActual,
                    actualQuantity: values.quantity,
                    prevQuantity: values.quantity,
                    details: 'Cost Change',
                    user: localStorage.getItem('userId')
                })
                newBody.costPart = { cost: values.costActual }
            }
            newBody.history = newHistory

            if(values.quantityIncrease > 0){
                const actualQuantity = (values.quantity + values.quantityIncrease)
                newHistory.push({
                    originalStatus: status,
                    actualStatus: values.status,
                    prevCost: cost,
                    actualCost: values.costActual,
                    actualQuantity: actualQuantity,
                    prevQuantity: values.quantity,
                    details: 'Quantity Change (+)',
                    user: localStorage.getItem('userId')
                })
                newBody.quantity = actualQuantity
            }

            if(values.quantityDecrease > 0){
                const actualQuantity = (values.quantity - values.quantityDecrease)
                newHistory.push({
                    originalStatus: status,
                    actualStatus: values.status,
                    prevCost: cost,
                    actualCost: values.costActual,
                    actualQuantity: actualQuantity,
                    prevQuantity: values.quantity,
                    details: 'Quantity Change (-)',
                    user: localStorage.getItem('userId')
                })
                newBody.quantity = actualQuantity
            }

            delete newBody.costActual
            delete newBody.costLast
            delete newBody.costAverage
            delete newBody.quantityIncrease
            delete newBody.quantityDecrease

            await axios.patch(url, newBody)
            openNotification('success', 'Success', 'Inventory created succesfully')
            setTimeout(() => {
                setLoadingButton(false)
                props.history.push('/inventorylist')
            }, 1000);
            
        } catch (err) {
            setLoadingButton(false)
            openNotification('error', 'Error', 'Something went wrong while updating inventory')
            console.error(err.message)
            return null
        }
    }

    const deleteInventory = async () => {
        setLoadingButton(true)

        const url = api + '/inventory/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Inventory Deleted')
            setTimeout(() => {
                setLoadingButton(false)
                props.history.push('/inventorylist')
            }, 1000);
            
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        updateInventory(values)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            layout='vertical'
            name='basicForm'
            initialValues={
                initialValues
            }
            onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                        label='ID'
                        name='number'
                        >
                            <InputNumber size='large' style={{width: '100%'}} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label='Name'
                            name= 'name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill name'
                                }
                            ]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='Part #'
                            name= 'partNumber'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Status'
                        name='status'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a status'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select status`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={statusOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Part Type'
                        name='type'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a type'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select type`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={typeOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Category'
                        name='category'
                        >
                            <Select
                            showSearch
                            placeholder={`Select category`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={categoryOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Measurement Unit'
                        name='measurementUnit'
                        >
                            <Select
                            showSearch
                            placeholder={`Select unit`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={measurementUnitOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label='Manufacturer #'
                            name= 'manufacturerNumber'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='Model'
                            name= 'model'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='UPC Code'
                            name= 'upc'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Provider'
                        name='provider'
                        >
                            <Select
                            showSearch
                            placeholder={`Select provider`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={providerOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Provider Information'
                        name='providerInfo'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                        label='Current Quantity'
                        name='quantity'
                        >
                            <InputNumber size='large' style={{width: '100%'}} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                        label='Increase Quantity (+)'
                        name='quantityIncrease'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                        label='Decrease Quantity (-)'
                        name='quantityDecrease'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Title level={4}>Part Cost</Title>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                        label='Actual Cost'
                        name='costActual'
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label='Last Cost'
                        name='costLast'
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label='Average Cost'
                        name='costAverage'
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label='Sale Price'
                        name='salePrice'
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Minimum Level'
                        name='min'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Maximum Level'
                        name='max'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Notes'
                        name='notes'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Invoices'
                        name='invoices'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                        label= 'Instalation Date'
                        name='instalationDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'

                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label= 'Manufacturer Guarantee Date'
                        name='manufacturerGuaranteeDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label= 'Client Guarantee Start Date'
                        name='clientGuaranteeStartDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label= 'Client Guarantee End Date'
                        name='clientGuaranteeEndDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Guarantee Notes - Work Force'
                        name='notesGuaranteeWorkForce'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Guarantee Notes - Parts'
                        name='notesGuaranteeParts'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Storage Location'
                        name='storageLocation'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        label='Container'
                        name='container'
                        >
                            <Select
                            showSearch
                            placeholder={`Select container`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={containerOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Title level={4}>Status/Cost History</Title>
                    </Col>
                    <Col span={24}>
                        <CustomTable 
                        columns={columns}
                        data={history}
                        />
                    </Col>
                </Row>
                <Row gutter={18} style={{marginTop: '20px'}}>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loadingButton}>
                            Save 
                        </Button>
                    </Col>
                    {
                        (localStorage.getItem('email') === 'eliud@gmail.com' || localStorage.getItem('email') === 'ec@57concrete.com') &&
                        <Col>
                            <Popconfirm
                                title="Delete the inventory"
                                description="Are you sure to delete this inventory?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={deleteInventory}
                            >
                                <Button danger type='primary' htmlType='button' loading={loadingButton}>Delete</Button>
                            </Popconfirm>
                        </Col>
                    }
                </Row>
            </Form>
    }


    return (
        <Layout
        title='Inventory'
        description='Edit Inventory'
        type='medium'
        >
            {contextHolder}
            {
                loading ? <CustomSpinner/> : <CustomForm/>
            } 
        </Layout>
    )
}

export default Inventory