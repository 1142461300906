import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import axios from 'axios'
import { api } from '../utils/Api'

const TruckEfficiency = () => {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loads, setLoads] = useState([])
    const [trucks, setTrucks] = useState(1)
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalTrips, setTotalTrips] = useState(0)
    const [totalCyPerTicket, setCyPerTicket] = useState(0)
    const [totalTripsPerTruck, setTripsPerTruck] = useState(0)
    const [isLoading, setLoading] = useState(true)
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]


    useEffect(() => {
        const start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
        getLoads(start, end)
    }, [startDate, endDate, totalVolume])


    const getLoads = async (start, end) => {
        const url = api + `/loadscheduled?startDate=${start}&endDate=${end}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            const { data } = await axios.get(url)
            setLoads(data)
            setLoading(false)

            getTotals(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getTotals = (data) => {
        let totV = 0
        let totT = 0
        let loopDate = new Date(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`)
        let dateList = []

        while (loopDate <= endDate) {
            dateList.push(loopDate)
            loopDate = new Date(loopDate.setDate(loopDate.getDate() + 1))
        }
        dateList.pop()

        dateList.map((dia) => {
            const { dow, date, volume, trips, cyPerYear, tripsPerTruck, trucks } = getDayData(dia, data)

            totT += trips
            totV += volume
        })

        setTotalTrips(totT)
        setTotalVolume(totV)
        setCyPerTicket(totV / totT)
        setTripsPerTruck(totT / trucks)
    }

    const getDayData = (fecha, data) => {
        let volume = 0
        let trips = 0
        let day = `${fecha.getFullYear()}-`

        day += (fecha.getMonth() + 1 < 10 ? `0${fecha.getMonth() + 1}-` : `${fecha.getMonth() + 1}-`)
        day += (fecha.getDate() < 10 ? `0${fecha.getDate()}` : `${fecha.getDate()}`)

        const filteredData = (data ? data.filter(load => (load.date).substring(0, 10) === day) : loads.filter(load => (load.date).substring(0, 10) === day))

        filteredData.forEach((fData) => {
            volume += fData.yards
            trips += 1
        })

        const obj = {
            dow: weekday[fecha.getDay()],
            date: day,
            volume: volume,
            trips: trips,
            cyPerYear: volume / trips,
            tripsPerTruck: trips / trucks,
            trucks: trucks
        }

        return obj
    }

    const DrawList = () => {
        let loopDate = new Date(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`)
        let dateList = []

        while (loopDate <= endDate) {
            dateList.push(loopDate)
            loopDate = new Date(loopDate.setDate(loopDate.getDate() + 1))
        }
        dateList.pop()

        return dateList.map((dia) => {
            const { dow, date, volume, trips, cyPerYear, tripsPerTruck, trucks } = getDayData(dia)

            return <tr key={date}>
                <td>
                    {dow}
                </td>
                <td>
                    {date}
                </td>
                <td>
                    {volume}
                </td>
                <td>
                    {trips}
                </td>
                <td>
                    {cyPerYear.toFixed(3) || "NA"}
                </td>
                <td>
                    {tripsPerTruck.toFixed(3) || "NA"}
                </td>
                <td>
                    {trucks}
                </td>
            </tr>
        })
    }


    const RenderList = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <th>DOW</th>
                        <th>Date</th>
                        <th>Total Volume</th>
                        <th>Trips</th>
                        <th>Cy per ticket</th>
                        <th>Trips Per Truck</th>
                        <th>Trucks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td>
                            Total
                        </td>
                        <td>

                        </td>
                        <td>
                            {totalVolume}
                        </td>
                        <td>
                            {totalTrips}
                        </td>
                        <td>
                            {totalCyPerTicket.toFixed(3)}
                        </td>
                        <td>
                            {totalTripsPerTruck.toFixed(3)}
                        </td>
                        <td>

                        </td>
                    </tr>
                </tbody>
                <tbody>
                    {DrawList()}
                </tbody>
            </table>
        </div >
    )

    return (
        <Layout
            title='Truck'
            description='Truck Efficiency Lists'
            className='container col-md-8 offset-md-2'
        >
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Start date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>End date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Trucks </label>
                    <input
                        onChange={(e) => setTrucks(e.target.value)}
                        type="number"
                        name='trucks'
                        className="form-control"
                        value={trucks}
                        placeholder="Trucks"
                    />
                </div>
            </div>
            {isLoading ? <Spinner /> : RenderList()}
        </Layout>
    )
}


export default TruckEfficiency