import React, {useState} from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../../actions/auth'
import { Redirect } from 'react-router-dom'
import { Button, Flex, Form, Input } from 'antd'

// todos los props deben estar como argumentos en la funcion principal
const Signin = ({ login, isAuthenticated, user }, history) => {

    const [loading, setLoading] = useState(false)

    const clickSubmit = async (values) => {
        setLoading(true)
        // llamar action/reducer:
        await login(values.email, values.password, setLoading)
        setLoading(false)
    }

    const LoginForm = () => (                    
        <Form
        layout='vertical'
        name="signin"
        onFinish={clickSubmit}
        autoComplete="on"
      >
        <Form.Item
            label="Email/Phone"
            name="email"
            rules={[
                {
                required: true,
                message: 'Please input your email!',
                },
            ]}
        >
          <Input size='large' autoComplete='on'/>
        </Form.Item>
    
        <Form.Item
            label="Password"
            name="password"
            rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
                {
                    min: 5,
                    message: 'Password must be at least 5 characters!',
                },
            ]}
        >
          <Input.Password size='large' autoComplete="on" />
        </Form.Item>
    
    
        <Flex justify='center'>
            <Button type="primary" htmlType="submit" size='large' loading={loading} >
                Access
            </Button>
        </Flex>
            
      </Form>
        
    )

    // mandar un redirect cuando el usuario ya esta autorizado.
    if (isAuthenticated) {
        localStorage.setItem('role', user.role) 
        const userId = localStorage.getItem('userId')
        
        if (user.role === 'Customer') {                                       
            return <Redirect to={`/dailycustomer/${userId}`} />
        }else if(user.role === 'Mechanic') {                                       
            return <Redirect to={`/workorders/${userId}`} />
        } else {
            return <Redirect to='/dailyappointment' />
        }        
    }

    return (
         <Layout 
            title="Sign in" 
            description="Sign in"
            type='small'
        >            
            {LoginForm()}            
        </Layout>
    )
}

Signin.propTypes = {
    login: PropTypes.func.isRequired,
    // isAuthenticated: PropTypes.bool.isRequired,
    // user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.usuario
})

export default connect(
    mapStateToProps, 
    { login }
)(Signin)

