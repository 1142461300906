import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { CSVLink } from "react-csv"

const SellerModal = ({showSellerModal, toggleSellerModal, selectedSeller, data}) => {

    const [tableCsvContent, setTableCsvContent] = useState([])

    useEffect(() => {
        const results = data.filter(function (item) {
            return item.seller.name === selectedSeller
        })

        let currentData = []
        results.forEach((item) => {
            const row = {
                date: item.scheduledDate.substring(0,10),
                quadrantName: quadrantName(item.customer.quadrantType),
                quadrantType: item.customer.quadrantType,
                seller: item.seller.name,
                customer: item.customer.name,
                customerNumber: item.customer.customerNumber,
                volume: item.loadYD3,
            }
            currentData.push(row)
        })

        currentData.sort(function(a,b){
            return new Date(a.date) - new Date(b.date);
        });
        setTableCsvContent(currentData)
    }, [data])

    const quadrantName = (quadrantType) => {
        let type = ''
        switch (quadrantType) {
            case 'CC':
                type = 'Construction Companies'
                break;
            case 'CEM':
                type = 'Cement / Particular'
                break;
            case 'GC':
                type = 'General Contractors'
                break;
            case 'GOV':
                type = 'Government & DOT'
                break;
            case 'IC':
                type = 'In Call'
                break;
            default:
                type = ''
                break;
        }
        return type
    }

    const columns = [
        { key: 'date', name: 'Date' },
        { key: 'quadrantName', name: 'Quadrant Name' },
        { key: 'quadrantType', name: 'Quadrant Type'},
        { key: 'seller', name: 'Seller' },
        { key: 'customer', name: 'Customer' },
        { key: 'customerNumber', name: 'Customer Number' },
        { key: 'volume', name: 'Volume (Yards)' },
    ]

    const headers = () => {
        const columnsCSV = columns
        return columnsCSV.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const TableRender = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        {
                            columns.map((column) => (
                                <th scope="col" key={column.key}>{column.name}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        tableCsvContent.map((item,key) => {
                            return (
                                <tr key={key}>
                                    <td>{item.date}</td>
                                    <td>{item.quadrantName}</td>
                                    <td>{item.quadrantType}</td>
                                    <td>{item.seller}</td>
                                    <td>{item.customer}</td>
                                    <td>{item.customerNumber}</td>
                                    <td>{item.volume.toFixed(2)}</td>
                                </tr> 
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }
    

    return (
        <Modal
            show={showSellerModal}
            animation={false}
            onHide={toggleSellerModal}
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title>{selectedSeller} Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TableRender/>
                <CSVLink data={tableCsvContent} headers={headers()} filename={`57concrete-${selectedSeller.toLowerCase()}-report.csv`}>
                    Download report
                </CSVLink>
            </Modal.Body>
        </Modal>
    )
}

export default SellerModal