import React from 'react'
import Menu from './Menu'
import Alert from './Alert'
import CustomMenu from './CustomMenu'
import { Layout as CustomLayout, Image, Space, theme, Typography } from 'antd'
const { Content, Footer, Header } = CustomLayout
const { Title, Text} = Typography

/**
 ** You can put descriptions based on title, description, className and children components 
*/
const Layout = ({
    title='Title', 
    description='Desc', 
    className, 
    children,
    type = 'large'
}) => {
        const {
            token: { colorBgContainer, borderRadiusLG,  },
        } = theme.useToken();

        const contentSize = () => {
            switch (type) {
                case 'large':
                    return {
                        padding: '2% 2%'
                    }
                case 'medium':
                    return {
                        padding: '2% 10%'
                    }
            
                default:
                    return {
                        padding: '5% 30%'
                    }
            }
        }

        return <CustomLayout style={{minHeight: '100vh'}}>
        <Header
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
            
        >
            <CustomMenu />
            <Alert />
        </Header>
        <Content
            style={contentSize()}
        >
            <div
                style={{
                    background: colorBgContainer,
                    minHeight: 280,
                    padding: 24,
                    borderRadius: borderRadiusLG,
                }}
            >
                <Image
                width={250}
                src="http://57concrete.com/assets/images/logo/logo-light.png"
                preview={false}
                />
                <Title style={{margin: '0 0 5px'}}>{title}</Title>
                <Text style={{fontSize: 20}} type='secondary'>{description}</Text>
                {children}
            </div>
        </Content>
        <Footer
            style={{
            textAlign: 'center',
            }}
        >
            57Concrete ©{new Date().getFullYear()}
        </Footer>
    
    </CustomLayout>
}

export default Layout

// valores default a los parametros "title" y "description"
// const Layout = ({
//     title='Title', 
//     description='Desc', 
//     className, 
//     children
// }) => (
//     <div>
//         <Menu />
//         {/* <Alert /> */}
//         <div className="jumbotron">
//             <img src="http://57concrete.com/assets/images/logo/logo-light.png" alt="Logo" style={{width:'20%'}} />  
//             <h2>{title}</h2>
//             <p className="lead">{description} </p>
//         </div>
//         <div className={className}>{children}</div>
//     </div>
// )

// export default Layout