import React, { Component } from 'react'
import axios from 'axios'
import Spinner from '../layout/Spinner'
import Layout from '../layout/Layout'
import Card from './Card'
import { 
    LineChart, 
    Line, 
    CartesianGrid, 
    XAxis, 
    YAxis,
    BarChart, 
    Bar,
    Legend,
    Tooltip
} from 'recharts'
import { api } from '../utils/Api'

export default class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            loads: [],
            sumYD3: 0,
            troubleCount: 0,
            okCount: 0,
            inProgressCount: 0,
            dateMovedCount: 0,
            operationsCount: 0,
            cancellationCount: 0,
            creditCount: 0,
            clientCount: 0,
            loading: false,
            jobcount: [],
            loadcount: [],
            drivercount: [],
            sellercount: []
        }
    }

    getCounters = () => {
        const url = api + '/creditcount' 
        const url2 = api + '/customercount' 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        const req1 = axios.get(url)
        const req2 = axios.get(url2)

        // llamada simultanea a los 2 contadores
        axios.all([req1, req2]).then(axios.spread((...responses) => {
            const res1 = responses[0]
            const res2 = responses[1]

            this.setState({
                creditCount: res1.data.count,
                clientCount: res2.data.count
            })
        })).catch(errors => {
            console.error(errors.message)
            return null
        })
    }

    getLoads = async () => {        
        const url = api + '/loadscheduled?startDate=2024-1-1&endDate=2024-01-31' 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)

            let loadCount = []
            data.reduce((group, load) => {
                // console.log(load.date)
                const date = load.date ? load.date.split('T')[0] : '2024-01-01'

                const index = loadCount.findIndex(item => item.date === date)
                const yards = load.yards ? load.yards : 0                

                if (index >= 0) {
                    const newcount = loadCount[index].count + 1
                    const newyards = loadCount[index].yards + yards
                    loadCount[index].count = newcount
                    loadCount[index].yards = newyards
                } else {     
                    const newItem = {
                        date,
                        yards,
                        count: 1
                    }
                    loadCount.push(newItem)
                }
            })

            this.setState({
                loadcount: loadCount,
                loads: data
            })  
            this.groupDrivers()

        } catch (err) {
            console.error(err.message)
            return null
        }

    }

    getJobs = async () => {
        const url = api + '/job' 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            // console.log(data)
            const totalJobs = data.reduce((acc, eljob) => acc + eljob.loadYD3, 0)
            
            const troubleJobs = data.filter(job => job.jobStatus === 'Trouble')
            const okJobs = data.filter(job => job.jobStatus === 'ok')
            const inProgressJobs = data.filter(job => job.jobStatus === 'In progress')
            const dateMovedJobs = data.filter(job => job.jobStatus === 'Date moved')
            const operationJobs = data.filter(job => job.jobStatus === 'Operations done (AR)')
            const cancelledJobs = data.filter(job => job.jobStatus === 'Company cancellation')

            this.setState({
                jobs: data,
                sumYD3: totalJobs,
                troubleCount: troubleJobs.length,
                okCount: okJobs.length,
                inProgressCount: inProgressJobs.length,
                dateMovedCount: dateMovedJobs.length,
                operationsCount: operationJobs.length,
                cancellationCount: cancelledJobs.length
            })

            let jobCount = []
            data.reduce((agrupado, job) => {
                const date = job.scheduledDate.split('T')[0]

                const index = jobCount.findIndex((item => item.date === date))

                if (index >= 0) {
                    // console.log(`indice encontrado: ${index}`)
                    // console.log(jobCount)
                    const newcount = jobCount[index].count + 1
                    // console.log(`objeto: ${jobCount[index].count}, nuevo valor: ${newcount} `)
                    jobCount[index].count = newcount
                } else {                
                    const newItem = {
                        date,
                        count: 1
                    }
                    // console.log(newItem)
                    jobCount.push(newItem)
                }                                
            })
            
            this.groupSellers()

            this.setState({
                jobcount: jobCount
            })

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    groupSellers = () => {
        let sellerCount = []

        this.state.jobs.reduce((group, job) => {
            const seller = job.seller ? job.seller.name : 'Unasigned'
            const yards = job.loadYD3 ? job.loadYD3 : 0

            const index = sellerCount.findIndex(item => item.seller === seller)

            if (index >= 0) {
                const newcount = sellerCount[index].count + 1
                const newyards = sellerCount[index].yards + yards
                sellerCount[index].count = newcount
                sellerCount[index].yards = newyards
            } else {
                const newItem = {
                    seller,
                    count: 1,
                    yards
                }

                sellerCount.push(newItem)
            }
        })

        this.setState({
            sellercount: sellerCount
        })
    }

    groupDrivers = () => {
        let driverCount = []

        this.state.loads.reduce((group, load) => {
            const driver = load.driver ? load.driver : 'Unasigned'
            const yards = load.yards ? load.yards : 0

            const index = driverCount.findIndex(item => item.driver === driver)

            if (index >= 0) {
                const newcount = driverCount[index].count + 1
                const newyards = driverCount[index].yards + yards
                driverCount[index].count = newcount
                driverCount[index].yards = newyards
            } else {
                const newItem = {
                    driver,
                    yards,
                    count: 1
                }

                driverCount.push(newItem)
            }            
        })    

        this.setState({
            drivercount: driverCount
        })
    }

    // ejemplo de agrupacion por fecha
    groupSample = () => {
        const data = [
            { notes: 'Game was played', time: '2017-10-04T20:24:30+00:00', sport: 'hockey', owner: 'steve', players: '10', game_id: 1},
            { notes: 'Game was played', time: '2017-10-04T12:35:30+00:00', sport: 'lacrosse', owner: 'steve', players: '6', game_id: 2 },
            { notes: 'Game was played', time: '2017-10-14T20:32:30+00:00', sport: 'hockey', owner: 'steve', players: '4', game_id: 3 },
            { notes: 'Game was played', time: '2017-10-04T10:12:30+00:00', sport: 'hockey', owner: 'henry', players: '10', game_id: 4 },
            { notes: 'Game was played', time: '2017-10-14T20:34:30+00:00', sport: 'soccer', owner: 'john', players: '12', game_id: 5 }
          ];
          
          // this gives an object with dates as keys
          const groups = data.reduce((groups, game) => {
            const date = game.time.split('T')[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(game);
            return groups;
          }, {});
          
          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              games: groups[date]
            };
          });
          
          console.log(groupArrays)
    }

    componentDidMount() {
        this.getJobs()
        this.getCounters()
        this.getLoads()
    }

    cardLayout = () => (
        <div className='container'>
            <div className='row'>                
                    <Card 
                        title='In progress'
                        counter={this.state.inProgressCount}
                        cardColor='#fff569'
                        text='Jobs' 
                    />
                    <Card 
                        title = 'OK'
                        counter={this.state.okCount}
                        cardColor='#7ad765'
                        text='Jobs'
                    />  
                    <Card 
                        title = 'Operations done'
                        counter={this.state.operationsCount}
                        cardColor='#6295ec'
                        text='Jobs'
                    />      
                    <Card 
                        title='Date moved'
                        counter={this.state.dateMovedCount}
                        cardColor='#d9a809'
                        text='Jobs' 
                    />  
                    <Card 
                        title='Trouble'
                        counter={this.state.troubleCount}
                        cardColor='#7a7977'
                        text='Jobs' 
                    />     
                    <Card 
                        title='Cancelled'
                        counter={this.state.cancellationCount}
                        cardColor='#e087fa'
                        text='Jobs' 
                    />     
                    <Card 
                        title='Clients'
                        counter={this.state.clientCount}
                        cardColor='#b6b8bb'
                        text='Total' 
                    /> 
                    <Card 
                        title='Credit Ap.'
                        counter={this.state.creditCount}
                        cardColor='#b6b8bb'
                        text='Total' 
                    /> 
                    <Card 
                        title='YD3'
                        counter={this.state.sumYD3}
                        cardColor='#1cc46a'
                        text='Sum' 
                    />                    
            </div>
            
        </div>
    )

    chartExample = () => {
        const data = [{name: 'Jan', uv: 400, pv: 2400, amt: 2400},
                    {name: 'Feb', uv: 500, pv: 300, amt: 2200},
                    {name: 'Mar', uv: 320, pv: 1500, amt: 3000}
    ]
        return (
            <LineChart width={400} height={300} data={data} >
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
            </LineChart>
        )
    }

    barJobs = () => {
        return (
            <BarChart width={300} height={200} data={this.state.jobcount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#1e387d" />
            </BarChart>
        )
    }

    barJobsBySeller = () => {
        return (
            <BarChart width={300} height={200} data={this.state.sellercount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="seller" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#1e387d" />
            </BarChart>
        )
    }

    barLoads = () => {
        return (
            <BarChart width={300} height={200} data={this.state.loadcount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#1e387d" />
            </BarChart>
        )
    }

    barYards = () => {
        return (
            <BarChart width={300} height={200} data={this.state.loadcount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <Tooltip />
                <Legend />
                <Bar dataKey="yards" fill="#1e387d" />
            </BarChart>
        )
    }

    barLoadsByDriver = () => {        
        return (            
            <BarChart width={300} height={200} data={this.state.drivercount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="driver" />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#1e387d" />
            </BarChart>
        )
    }

    barYardsByDriver = () => {
        return (            
            <BarChart width={1000} height={200} data={this.state.drivercount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="driver" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="yards" fill="#1e387d" />
            </BarChart>
        )
    }

    barYardsBySeller = () => {
        return (            
            <BarChart width={300} height={200} data={this.state.sellercount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="seller" />
                <Tooltip />
                <Legend />
                <Bar dataKey="yards" fill="#1e387d" />
            </BarChart>
        )
    }

    barExample = () => {
    const data = [
        {
          name: "Page A",
          uv: 4000,
          pv: 2400,
          amt: 2400
        },
        {
          name: "Page B",
          uv: 3000,
          pv: 1398,
          amt: 2210
        },
        {
          name: "Page C",
          uv: 2000,
          pv: 9800,
          amt: 2290
        },
        {
          name: "Page D",
          uv: 2780,
          pv: 3908,
          amt: 2000
        },
        {
          name: "Page E",
          uv: 1890,
          pv: 4800,
          amt: 2181
        },
        {
          name: "Page F",
          uv: 2390,
          pv: 3800,
          amt: 2500
        },
        {
          name: "Page G",
          uv: 3490,
          pv: 4300,
          amt: 2100
        }
      ]

        return (
            <BarChart width={400} height={300} data={data}>               
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <Tooltip />
                <Legend />
                <Bar dataKey="amt" fill="#8884d8" />
            </BarChart>
        )
    }

    renderLoading() {
        return <Spinner />
    }

    renderCharts = () => {
        return (
            <div className='container'>
                    <div className='row'>
                        <div className='col-sm'>
                            <h3 >Yards/seller</h3>
                            {
                                this.barYardsBySeller()
                            }
                        </div>
                        <div className='col-sm'>
                            <h3 >Jobs/seller</h3>
                            {
                                this.barJobsBySeller()
                            }
                        </div>
                        <div className='col-sm'>
                            <h3>
                                Jobs/date
                            </h3>
                            {
                                this.barJobs()
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm'>
                            <h3>
                                Yards/date
                            </h3>
                            {
                                this.barYards()
                            }
                        </div>
                        <div className='col-sm'>
                            <h3>Loads/date</h3>
                            {
                                this.barLoads()
                            }
                        </div>
                        <div className='col-sm'>
                            <h3>
                                Loads/driver
                            </h3>
                            {
                                this.barLoadsByDriver()
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm'>
                            <h3>
                                Yards/driver
                            </h3>
                            {
                                this.barYardsByDriver()
                            }
                        </div>                        
                    </div>
                </div>   
        )
    }

    render() {
        return (
            <Layout
                title='Dashboard'
                description=''
            >   
                {
                    this.state.loading ?
                    this.renderLoading() 
                    : this.renderCharts()
                }
                                         
                {/* {
                    this.state.loading ?
                    this.renderLoading()
                    : this.cardLayout()
                }                                              */}
            </Layout>
        )
    }

}


        