import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, DatePicker, Segmented, Table, Statistic, Button, Modal, Divider, Form, Row, Col } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import { CSVLink } from 'react-csv'
const { Text, Title } = Typography
const { RangePicker } = DatePicker

const lineupColumns = [
    { key: 'driver', label: 'Driver' },
    { key: 'truck', label: 'Truck' },
    { key: 'yards', label: 'Yards' },
    { key: 'date2', label: 'Date' },
    { key: 'customer', label: 'Customer' },
    { key: 'jobNumber', label: 'Job ID' },
    { key: 'deliveryTicket', label: 'Ticket' },
    { key: 'orderCode', label: 'Order Code' },
    { key: 'sequence', label: 'Sequence' },
]

const Lineup = () => { 
    
    const [loading, setLoading] = useState(true)
    const [jobListMission1, setJobListMission1] = useState([])
    const [jobListMission2, setJobListMission2] = useState([])
    const [jobListSanBenito1, setJobListSanBenito1] = useState([])
    const [date, setDate] = useState(dayjs())
    const [currentTable, setCurrentTable] = useState('mission1')
    const [minutesMission1, setMinutesMission1] = useState(0)
    const [minutesMission2, setMinutesMission2] = useState(0)
    const [minutesSanBenito1, setMinutesSanBenito1] = useState(0)
    const [timeMinutes, setTimeMinutes] = useState(0)
    const [openTimeModal, setOpenTimeModal] = useState(false)
    const [calculatedTime, setCalculatedTime] = useState(0)
    const [loadingTime, setLoadingTime] = useState(false)
    const [form] = Form.useForm()
    const refDownload = useRef()

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'YD3',
            dataIndex: 'yards',
            key: 'yards',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return <Text >{`${dayjs(value).toDate().toLocaleDateString()} - ${dayjs(value).toDate().toLocaleTimeString([], { timeStyle: 'short' })}`}</Text>
            }
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Job ID',
            dataIndex: 'jobNumber',
            key: 'jobNumber',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Ticket',
            dataIndex: 'deliveryTicket',
            key: 'deliveryTicket',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Order Code',
            dataIndex: 'orderCode',
            key: 'orderCode',
            render: (value) => {
                return <Text >{value}</Text>
            }
        },
        {
            title: 'Sequence',
            dataIndex: 'sequence',
            key: 'sequence',
            render: (value) => {
                return <Text >{value}</Text>
            }
        }
    ]

    useEffect(() => {
        let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`
        getLoadList(dateString)
    }, [])
    
    const getLoadList = async(date) => {
        setLoading(true)
        const url = api + `/loaddate?date=${date}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    sequence: item.sequence,
                    driver: item.driver,
                    truck: item.truckNumber,
                    orderCode: item.orderCode,
                    yards: item.yards,
                    deliveryTicket: item.deliveryTicket,
                    date: item.createdAt,
                    plant: item.job.plant.name,
                    subplant: item.job.subplant ? item.job.subplant : '-',
                    customer: item.job.customer.name,
                    jobNumber: item.job.number,
                    date2: `${dayjs(item.createdAt).toDate().toLocaleDateString()} - ${dayjs(item.createdAt).toDate().toLocaleTimeString([], { timeStyle: 'short' })}`
                })
            })

            let currentHours1 = 0
            let plant1 = list.filter(job => job.plant === 'Mission' && job.subplant === 'P1')
            for (let index = 0; index < plant1.length - 1; index++) {
                const element = plant1[index]
                const element2 = plant1[index + 1]
                currentHours1 += (Math.abs(new Date(element.date) - new Date(element2.date)) / 36e5)
            }
            let minutes1 = (currentHours1 / plant1.length) * 60
            setMinutesMission1(minutes1 || 0)

            let currentHours2 = 0
            let plant2 = list.filter(job => job.plant === 'Mission' && job.subplant === 'P2')
            for (let index = 0; index < plant2.length - 1; index++) {
                const element = plant2[index]
                const element2 = plant2[index + 1]
                currentHours2 += (Math.abs(new Date(element.date) - new Date(element2.date)) / 36e5)
            }
            let minutes2 = (currentHours2 / plant2.length) * 60
            setMinutesMission2(minutes2 || 0)

            let currentHours3 = 0
            let plant3 = list.filter(job => job.plant === 'San Benito')
            for (let index = 0; index < plant3.length - 1; index++) {
                const element = plant3[index]
                const element2 = plant3[index + 1]
                currentHours3 += (Math.abs(new Date(element.date) - new Date(element2.date)) / 36e5)
            }
            let minutes3 = (currentHours3 / plant3.length) * 60
            setMinutesSanBenito1(minutes3 || 0)

            setTimeMinutes(minutes1 || 0)

            plant1.forEach((row, index) => {
                row.sequence = index + 1
                return plant1
            })
            plant2.forEach((row, index) => {
                row.sequence = index + 1
                return plant1
            })
            plant3.forEach((row, index) => {
                row.sequence = index + 1
                return plant1
            })
            setJobListMission1(plant1)
            setJobListMission2(plant2)
            setJobListSanBenito1(plant3)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const calculateLoadTime = async(dates) => {
        setLoadingTime(true)
        const startDate = dayjs(dates[0]).toDate()
        const endDate = dayjs(dates[1]).toDate()
        const url = api + `/loaddatedetail?startDate=${startDate}&endDate=${endDate}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            if(currentTable === 'mission1'){
                let currentHours1 = 0
                let plant1 = data.filter(job => job.job.plant.name === 'Mission' && job.job.subplant === 'P1')
                for (let index = 0; index < plant1.length - 1; index++) {
                    const element = plant1[index]
                    const element2 = plant1[index + 1]
                    currentHours1 += (Math.abs(new Date(element.createdAt) - new Date(element2.createdAt)) / 36e5)
                }
                let minutes1 = (currentHours1 / plant1.length) * 60
                setCalculatedTime(minutes1 || 0)
            }else if(currentTable === 'mission2'){
                let currentHours2 = 0
                let plant2 = data.filter(job => job.job.plant.name === 'Mission' && job.job.subplant === 'P2')
                for (let index = 0; index < plant2.length - 1; index++) {
                    const element = plant2[index]
                    const element2 = plant2[index + 1]
                    currentHours2 += (Math.abs(new Date(element.createdAt) - new Date(element2.createdAt)) / 36e5)
                }
                let minutes2 = (currentHours2 / plant2.length) * 60
                setCalculatedTime(minutes2 || 0)
            }else if(currentTable === 'sanbenito1'){
                let currentHours3 = 0
                let plant3 = data.filter(job => job.job.plant.name === 'San Benito')
                for (let index = 0; index < plant3.length - 1; index++) {
                    const element = plant3[index]
                    const element2 = plant3[index + 1]
                    currentHours3 += (Math.abs(new Date(element.createdAt) - new Date(element2.createdAt)) / 36e5)
                }
                let minutes3 = (currentHours3 / plant3.length) * 60
                setCalculatedTime(minutes3 || 0)
            }
            setLoadingTime(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onChangeSegment = (value) => {
        setCurrentTable(value)
        if(value === 'mission1') setTimeMinutes(minutesMission1)
        if(value === 'mission2') setTimeMinutes(minutesMission2)
        if(value === 'sanbenito1') setTimeMinutes(minutesSanBenito1)
    }

    const RenderTable = () => {
        return <Flex gap='large' justify='center' vertical>
            <Statistic title="Average Time" value={timeMinutes} precision={0} suffix={'minutes'}/>
            <Button size='large' type='primary' onClick={handleTimeOpen} style={{width: 200}}>Calculate Average Time</Button>
            <Flex gap="small" align="center" vertical>
                <Segmented
                onChange={onChangeSegment}
                value={currentTable}
                options={[
                    {
                        label: (
                            <div style={{padding: 20}}>
                                <Title level={2}>Mission Plant</Title>
                                <Text style={{fontSize: 20}} strong>Subplant P1</Text>
                            </div>
                        ),
                        value: 'mission1',
                    },
                    {
                        label: (
                            <div style={{padding: 20}}>
                                <Title level={2}>Mission Plant</Title>
                                <Text style={{fontSize: 20}} strong>Subplant P2</Text>
                            </div>
                        ),
                        value: 'mission2',
                    },
                    {
                        label: (
                            <div style={{padding: 20}}>
                                <Title level={2}>San Benito Plant</Title>
                                <Text style={{fontSize: 20}} strong>Subplant P1</Text>
                            </div>
                        ),
                        value: 'sanbenito1',
                    },
                ]}
                />
            </Flex>
            {
                currentTable === 'mission1' &&
                <Table 
                columns={columns}
                dataSource={jobListMission1}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
                />
            }
            {
                currentTable === 'mission2' &&
                <Table 
                columns={columns}
                dataSource={jobListMission2}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
                />
            }
            {
                currentTable === 'sanbenito1' &&
                <Table 
                columns={columns}
                dataSource={jobListSanBenito1}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
                />
            }
        </Flex>
    }

    const onChangeDate = (date) => {
        let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`
        getLoadList(dateString)
        setDate(date)
    }

    const RenderDate = () => {
        return <DatePicker value={date} format={'MM/DD/YYYY'} onChange={onChangeDate} size='large' style={{width: 200}} />
    }

    const handleTimeCancel = () => {
        setOpenTimeModal(false)
        form.resetFields()
        setCalculatedTime(0)
    }

    const handleTimeOpen = () => {
        setOpenTimeModal(true)
    }

    const onFinish = (values) => {
        calculateLoadTime(values.dates)
    }

    const RenderForm = () => (
        <Form
        form={form}
        layout='vertical'
        onSubmit={e => e.preventDefault()}
        onFinish={onFinish}
        >
            <Form.Item
            name='dates'
            label='Dates'
            rules={[
                {
                    required: true,
                    message: 'Dates must be selected'
                }
            ]}
            >
                <RangePicker showTime format='MM/DD/YYYY HH:mm'  />
            </Form.Item>
            <Button htmlType='submit' size='large' type='primary' loading={loadingTime}>Calculate</Button>
            
        </Form>
    )

    const RenderStatistic = () => {
        return <Statistic title="Average Time" value={calculatedTime} precision={0} suffix={'minutes'} loading={loadingTime}/>
    }

    const RenderModalTime = () => {
        return <Modal title="Calculate Average Time" forceRender={false} open={openTimeModal} onCancel={handleTimeCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <RenderForm/>
            <Divider /> 
            <RenderStatistic />            
        </Modal>
    }

    const RenderDownloadReport = () => {
        return <div>
            <CSVLink 
            data={currentTable === 'mission1' ? jobListMission1 : (currentTable === 'mission2' ? jobListMission2 : jobListSanBenito1)} 
            headers={lineupColumns} 
            filename={"57concrete-lineup.csv"}
            hidden
            ref={refDownload}
            >
                Download report
            </CSVLink>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type='primary' style={{width: '100%'}} onClick={e => refDownload.current.link.click()}>Download Report </Button>
                </Col>
            </Row>
        </div>
    }

    return (
        <Layout
            title='Lineup'
            description='Load List'
        >
            <Flex vertical gap={'small'}>
                <RenderDate />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
                <RenderModalTime />
                <RenderDownloadReport />
            </Flex>
        </Layout>
    )
}

export default Lineup