import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, notification } from 'antd'
import { CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import { api } from '../utils/Api'
import axios from 'axios'
import { categoryOptions, containerOptions, measurementUnitOptions, statusOptions, typeOptions } from '../../constants/Inventory'
const { TextArea } = Input
const dateFormat = 'MM/DD/YYYY'

const NewInventory = (props) => {
    const [initialValues, setInitialValues] = useState ({})
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(true)
    const [providerOptions, setProviderOptions] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getProviders()
        getInventoryNumber()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getInventoryNumber = async() => {
        const url = api + `/inventorynumber`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setInitialValues({
                ...initialValues,
                number: data.inventoryNumber,
                quantity: 0,
                cost: 0,
                salePrice: 0,
                min: 0,
                max: 0,
            })
            setLoading(false)
            setLoadingButton(false)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining the inventory number')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const getProviders = async() => {
        const url = api + `/providerlist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((provider) => {
                list.push({
                    label: `${provider.name} - ${provider.phone}`,
                    value: provider._id
                })
            })
            setProviderOptions(list)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining providers')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const createInventory = async(values) => {
        setLoadingButton(true)

        const url = api + `/inventory`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let newBody = {
                ...values,
                costPart: [{ cost: values.cost }],
                createdBy: localStorage.getItem('userId')
            }

            if(values.instalationDate){
                newBody.instalationDate = dayjs(values.instalationDate).toDate()
            }
            if(values.manufacturerGuaranteeDate){
                newBody.manufacturerGuaranteeDate = dayjs(values.manufacturerGuaranteeDate).toDate()
            }
            if(values.clientGuaranteeStartDate){
                newBody.clientGuaranteeStartDate = dayjs(values.clientGuaranteeStartDate).toDate()
            }
            if(values.clientGuaranteeEndDate){
                newBody.clientGuaranteeEndDate = dayjs(values.clientGuaranteeEndDate).toDate()
            }

            const newHistory = [{
                originalStatus: '',
                actualStatus: values.status,
                details: 'Initial Creation',
                actualCost: values.cost,
                prevCost: 0,
                actualQuantity: values.quantity,
                prevQuantity: 0,
                user: localStorage.getItem('userId')
            }]
            newBody.history = newHistory

            await axios.post(url, newBody)
            openNotification('success', 'Success', 'Inventory created succesfully')
            setLoadingButton(false)
            setTimeout(() => {
                props.history.push('/inventorylist')
            }, 1000);
            
        } catch (err) {
            setLoadingButton(false)
            openNotification('error', 'Error', 'Something went wrong while creating inventory')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        createInventory(values)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            layout='vertical'
            name='basicForm'
            initialValues={
                initialValues
            }
            onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                        label='ID'
                        name='number'
                        >
                            <InputNumber size='large' style={{width: '100%'}} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label='Name'
                            name= 'name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill name'
                                }
                            ]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='Part #'
                            name= 'partNumber'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Status'
                        name='status'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a status'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select status`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={statusOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Part Type'
                        name='type'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a type'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select type`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={typeOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Category'
                        name='category'
                        >
                            <Select
                            showSearch
                            placeholder={`Select category`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={categoryOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Measurement Unit'
                        name='measurementUnit'
                        >
                            <Select
                            showSearch
                            placeholder={`Select unit`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={measurementUnitOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label='Manufacturer #'
                            name= 'manufacturerNumber'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='Model'
                            name= 'model'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='UPC Code'
                            name= 'upc'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Provider'
                        name='provider'
                        >
                            <Select
                            showSearch
                            placeholder={`Select provider`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={providerOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Provider Information'
                        name='providerInfo'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                        label='Quantity'
                        name='quantity'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                        label='Cost'
                        name='cost'
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                        label='Sale Price'
                        name='salePrice'
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Minimum Level'
                        name='min'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Maximum Level'
                        name='max'
                        >
                            <InputNumber size='large' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Notes'
                        name='notes'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Invoices'
                        name='invoices'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                        label= 'Instalation Date'
                        name='instalationDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'

                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label= 'Manufacturer Guarantee Date'
                        name='manufacturerGuaranteeDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label= 'Client Guarantee Start Date'
                        name='clientGuaranteeStartDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                        label= 'Client Guarantee End Date'
                        name='clientGuaranteeEndDate'
                        >
                            <DatePicker 
                            format={dateFormat}
                            size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Guarantee Notes - Work Force'
                        name='notesGuaranteeWorkForce'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Guarantee Notes - Parts'
                        name='notesGuaranteeParts'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Storage Location'
                        name='storageLocation'
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        label='Container'
                        name='container'
                        >
                            <Select
                            showSearch
                            placeholder={`Select container`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={containerOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={18} style={{marginTop: '20px'}}>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loadingButton}>
                            Save 
                        </Button>
                    </Col>
                </Row>
            </Form>
    }


    return (
        <Layout
        title='Create new inventory'
        description='New Inventory'
        type='medium'
        >
            {contextHolder}
            {
                loading ? <CustomSpinner/> : <CustomForm/>
            } 
        </Layout>
    )
}

export default NewInventory