import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import PaymentJob from '../job/PaymentJob'
import { toCurrency } from '../utils/Convert'

const InvoiceSummaryItem = ({invoice, getInvoice}) => {
    const [showInvoicePayments, setShowInvoicePayments] = useState(false)

    const toggleInvoice = e => {
        setShowInvoicePayments(!showInvoicePayments)
    }

    const paymentsUpdated = () => {
        setShowInvoicePayments(!showInvoicePayments)
        const timer = setTimeout(() => {
            getInvoice()
            }, 1000);
        return () => clearTimeout(timer);
    }

    const DrawPaymentsRegistered = ({payments}) => {
        if (payments.length === 0) {
            return <tr>
                <td>No payments</td>
            </tr>
        } else {
            return payments.map((pago, index) => {
                return <tr key={index}>
                    <td> {pago.type}: </td>
                    <td style={{ textAlign: "right" }}> {toCurrency(pago.amount,2)} </td>                    
                </tr>
            })
        }      
    }

    return (
        <tr key={invoice._id}>
            <td> {invoice.invoiceNumber} </td>
            <td> {invoice.date} </td>
            <td> {invoice.psi} </td>
            <td> {invoice.totalYards} </td>
            <td> {invoice.subtotal} </td>
            <td> {invoice.tax} </td>
            <td style={{ fontWeight: "bold" }}> {invoice.total} </td>
            <td> {invoice.balance} </td>
            <td>                                
                <button onClick={toggleInvoice} className='btn btn-primary'>
                    Apply
                </button>               
                <Modal
                    show={showInvoicePayments}
                    animation={false}
                    onHide={toggleInvoice}
                    size='lg'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Invoice Payments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PaymentJob jobId={invoice.job} pcb={paymentsUpdated}/>
                    </Modal.Body>
                </Modal>
            </td>
            <td>
                <table>
                    <tbody>
                        <DrawPaymentsRegistered payments={invoice.payments}/>
                    </tbody>
                </table>
            </td>
        </tr>
    )
}

export default InvoiceSummaryItem