import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
const { Text } = Typography

const BatchList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [batchList, setBatchList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') ? 
                <Button 
                type="primary" 
                icon={<EditFilled twoToneColor={'white'}/>} 
                onClick={e=> history.push(`/batch/${record.id}`)}
                />
                :
                ''
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Subplant',
            dataIndex: 'subplant',
            key: 'subplant',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        
    ]

    useEffect(() => {
        getBatchList()
    }, [])
    
    const getBatchList = async() => {
        setLoading(true)

        const url = api + '/userlist/Batch'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    name: item.name,
                    email: item.email,
                    plant: item.plant.name,
                    subplant: item.subplant,
                    latitude: item.latitude ? item.latitude : 'N/A',
                    longitude: item.longitude ? item.longitude : 'N/A'
                })
            })
            setBatchList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewBatch = () => {
        history.push('/batchnew')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') &&
                <Button
                    onClick={addNewBatch}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                >
                    New Batch
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={batchList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Batch'
            description='Batch List'
        >
            <Flex vertical gap={'large'}>
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default BatchList