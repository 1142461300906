import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { api } from '../utils/Api'

export default class ClientPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: '',
            isClearable: true,
            isSearchable: true,
            options: []
        }
    }

    getData = async () => {
        const url = api + '/allcustomers'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)            
            this.setState({
                options: data
            })
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        const options = this.state.options.map((item) => {
            return {
                value: item._id,
                label: item.customerNumber + ' ' + item.name
            }
        })

        return (
            <div style={{fontSize: '10px'}}>
                <Select
                    name="form-field-name"
                    placeholder='Customer...'
                    value={this.state.value}
                    onChange={this.props.action}
                    isClearable={this.state.isClearable}
                    isSearchable={this.state.isSearchable}
                    options={options}
                />
            </div>
        )
    } 
}

