import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, notification, Modal, Typography } from 'antd'
import { CustomSpinner } from '../utils/CustomComponents'
import { api } from '../utils/Api'
import axios from 'axios'
import { priorityOptions } from '../../constants/Inventory'
const { TextArea } = Input
const { Text } = Typography

const InventoryRequested = (props) => {
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState ({})
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(true)
    const [providerOptions, setProviderOptions] = useState([])
    const [inventoryOptions, setInventoryOptions] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()


    useEffect(() => {
        getProviders()
        getInventory()
        getInventoryRequested()
    }, [])

    const info = (description) => {
        Modal.info({
          title: 'Approved Validation',
          content: (
            <div>
                <Text>{description}</Text>
            </div>
          ),
          onOk() {}
        })
    }

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getInventory = async() => {
        const url = api + `/inventorylist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((inventory) => {
                list.push({
                    label: inventory.name,
                    value: inventory._id
                })
            })
            setInventoryOptions(list)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining the inventory')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const getProviders = async() => {
        const url = api + `/providerlist`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((provider) => {
                list.push({
                    label: `${provider.name} - ${provider.phone}`,
                    value: provider._id
                })
            })
            setProviderOptions(list)
        } catch (err) {
            openNotification('error', 'Error', 'Something went wrong while obtaining providers')
            setLoading(true)
            console.error(err.message)
            return null
        }
    }

    const getInventoryRequested = async() => {
        const url = api + `/inventoryrequested/${props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setInitialValues({
                ...initialValues,
                provider: data.provider,
                inventory: data.inventory,
                quantity: data.quantity,
                amount: data.amount,
                priority: data.priority ? data.priority : '',
                approved: data.approved,
                notes: data.notes ? data.notes : ''
            })
            setLoading(false)
            setLoadingButton(false)
        } catch (err) {
            setLoadingButton(false)
            openNotification('error', 'Error', 'Something went wrong while obtaining request')
            console.error(err.message)
            return null
        }
    }

    const updateInventoryRequested = async(values) => {
        setLoadingButton(true)

        const url = api + `/inventoryrequested/${props.match.params.id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            let newBody = {
                ...values,
                updatedBy: localStorage.getItem('userId'),
            }

            if(values.approved === true){
                newBody.approvedBy = localStorage.getItem('userId')
            }

            await axios.patch(url, newBody)
            openNotification('success', 'Success', 'Requested updated succesfully')
            setTimeout(() => {
                setLoadingButton(false)
                props.history.push('/inventoryrequestedlist')
            }, 1000);
            
        } catch (err) {
            setLoadingButton(false)
            openNotification('error', 'Error', 'Something went wrong while updating request')
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        if(values.approved === true){
            if(values.amount <= 2000 && localStorage.getItem('manager') == true){
                updateInventoryRequested(values)
            }else if(values.amount <= 5000 && localStorage.getItem('director') == true){
                updateInventoryRequested(values)
            }else if(values.amount > 5000 && localStorage.getItem('email') === 'ap@57concrete.com'){
                updateInventoryRequested(values)
            }else{
                if(values.amount <= 2000){
                    info('A manager or director must approve this request')
                }else if(values.amount > 2000 && values.amount <= 5000){
                    info('A director must approve this request')
                }else if(values.amount > 5000){
                    info('AP must approve this request')
                }
                form.setFieldsValue({approved: false})
            }
        }else{
            updateInventoryRequested(values)
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            name='basicForm'
            initialValues={initialValues}
            onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Inventory'
                        name='inventory'
                        rules={[
                            {
                                required: true,
                                message: 'Please select an inventory item'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select inventory`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={inventoryOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Provider'
                        name='provider'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a provider'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select provider`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={providerOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Quantity'
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'Please fill quantity'
                            }
                        ]}
                        >
                            <InputNumber 
                            size='large' 
                            style={{width: '100%'}} 
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Amount'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message: 'Please fill amount'
                            }
                        ]}
                        >
                            <InputNumber 
                            size='large' style={{width: '100%'}} 
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Priority'
                        name='priority'
                        rules={[
                            {
                                required: true,
                                message: 'Please select priority'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select priority`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={priorityOptions}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="approved"
                            label='Approve Request'
                            valuePropName="checked"
                        >
                            <Checkbox disabled={form.getFieldValue('approved')}>
                                Approved
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row> 

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                        label='Notes'
                        name='notes'
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={18} style={{marginTop: '20px'}}>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loadingButton}>
                            Save Changes 
                        </Button>
                    </Col>
                </Row>
            </Form>
    }


    return (
        <Layout
        title='Edit Inventory Requested'
        description='Request Inventory'
        type='medium'
        >
            {contextHolder}
            {
                loading ? <CustomSpinner/> : <CustomForm/>
            } 
        </Layout>
    )
}

export default InventoryRequested