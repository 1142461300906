import {
    ADD_FORECAST,
    GET_FORECAST,
    GET_FORECASTS,
    UPDATE_FORECAST,
    DELETE_FORECAST,
    FORECAST_ERROR,
    BATCH_DELETE
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    forecast: null,
    forecasts: [],
}

function forecastReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_FORECAST:
            return {
                ...state,
                forecasts: payload,
                loading: false
            }
        case GET_FORECAST:
            return {
                ...state,
                forecast: payload,
                loading: false
            }
        case GET_FORECASTS:
            return {
                ...state,
                forecasts: payload,
                loading: false
            }
        case UPDATE_FORECAST:
            return {
                ...state,
                forecasts: payload,
                loading: false
            }        
        case DELETE_FORECAST:
            return {
                ...state,
                forecasts: payload,
                loading: false
            }
        case BATCH_DELETE:
            return {
                ...state,
                count: payload,
            }
        case FORECAST_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default forecastReducer