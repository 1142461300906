import React, { useEffect, useState, useRef } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'

const LoyaltyAcomplishment = ({match, vendedor, foremans}) => {
    const seller = match.params.id 
    const thisYear = new Date().getFullYear()

    const [foremen, setForemen] = useState([])
    const [sellerName, setSellerName] = useState('')
    const [titleLabel, setTitleLabel] = useState("Loyalty plan implementation")
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const meses = ['ENE', 'FBR', 'MRZ','ABR', 'MYO','JNO','JLO','AGO', 'STM','OTB','NVM','DIC']
    const [rows, setRows] = useState([])


    useEffect(() => {
        getSeller(seller)        
        lealtad()
    }, [])

    const getSeller = async(seller) => {
        const url = api + `/users/${seller}`

        try {
            const {data} = await axios.get(url)
            const vendedor = data.name
            setTitleLabel(`Loyalty plan for ${vendedor}`)
        } catch (err) {
            console.log(err)
            return null
        }
    }

    const groupData = (plan, real) => {
        const format = plan.map(cap => {
            return {
                real: cap.real,
                foreman: cap._id.foreman,
                foremanName: cap._id.foremanName,
                yards: cap.yards,
                month: cap._id.month,
                year: cap._id.year,
                seller: cap._id.seller
            }
        })
        const planVendedor = format.filter(dato => (dato.seller === seller) && (dato.year === thisYear))
        planVendedor.sort((a,b) => a.month > b.month ? 1 : -1 )

        const capataz = planVendedor.map(cap => cap.foremanName)
        const unique = capataz.filter((v, i, a) => a.indexOf(v) === i)

        const yardas = real.map(yarda => {
            return {
                yards: yarda.yards,
                foremanName: yarda._id.foremanName.toUpperCase(),
                month: yarda._id.month,
                seller: yarda._id.seller,
                year: yarda._id.year
            }
        })
        const realVendedor = yardas.filter(dato => (dato.seller === seller) && (dato.year === thisYear))

        const venta = []

        unique.forEach(capataz => {
            const yd = planVendedor.filter(yad => yad.foremanName === capataz)

            const foremanSold = {
                foremanName: capataz,
                'JAN': 0, // en inglés para plan
                'ENE': 0, // en español para real
                'FEB': 0, 
                'FBR': 0,
                'MAR': 0,
                'MRZ': 0, 
                'APR': 0,
                'ABR': 0, 
                'MAY': 0,
                'MYO': 0, 
                'JUN': 0,
                'JNO': 0, 
                'JUL': 0,
                'JLO': 0, 
                'AUG': 0,
                'AGO': 0,
                'SEP': 0,
                'STM': 0, 
                'OCT': 0,
                'OTB': 0, 
                'NOV': 0,
                'NVM': 0, 
                'DEC': 0,
                'DIC': 0
            }        

            yd.forEach(plan => {
                const mes = plan.month - 1
                const month = months[mes]
                const periodo = meses[mes]
                const real = realVendedor.filter(yar => (yar.foremanName === capataz) && (yar.month === plan.month))
                
                let ventaReal = 0
                if (real.length > 0) {
                    ventaReal = real[0].yards
                }
                plan.real = ventaReal
                foremanSold[month] = ventaReal
                foremanSold[periodo] = plan.yards
                
            })
            venta.push(foremanSold)
        })

        setRows(venta)
    }

    const lealtad = async () => {
        const url = api + '/grouployalty?foreman=true' 
        const url2 = api + '/jobgroupforeman'

        const req1 = axios.get(url)
        const req2 = axios.get(url2)

        axios.all([req1, req2]).then(axios.spread((...responses) => {
            const {data} = responses[0]
            const plan = data
            let res = responses[1]
            const yarda = res.data

            groupData(plan, yarda)
        })).catch(errors => {
            console.log(errors)
            return null
        })
    }

    const DrawYards = () => {
        return rows.map((forman, i) => {
            return <tr key={i}>
                <td>{forman.foremanName}</td>
                <td style={{ textAlign: "right"}}>{forman.JAN}</td>
                <td style={{ textAlign: "right"}}>{forman.ENE}</td>
                <td style={{ textAlign: "right"}}>{forman.FEB}</td>
                <td style={{ textAlign: "right"}}>{forman.FBR}</td>
                <td style={{ textAlign: "right"}}>{forman.MAR}</td>
                <td style={{ textAlign: "right"}}>{forman.MRZ}</td>
                <td style={{ textAlign: "right"}}>{forman.APR}</td>
                <td style={{ textAlign: "right"}}>{forman.ABR}</td>
                <td style={{ textAlign: "right"}}>{forman.MAY}</td>
                <td style={{ textAlign: "right"}}>{forman.MYO}</td>
                <td style={{ textAlign: "right"}}>{forman.JUN}</td>
                <td style={{ textAlign: "right"}}>{forman.JNO}</td>
                <td style={{ textAlign: "right"}}>{forman.JUL}</td>
                <td style={{ textAlign: "right"}}>{forman.JLO}</td>
                <td style={{ textAlign: "right"}}>{forman.AUG}</td>
                <td style={{ textAlign: "right"}}>{forman.AGO}</td>
                <td style={{ textAlign: "right"}}>{forman.SEP}</td>
                <td style={{ textAlign: "right"}}>{forman.STM}</td>
                <td style={{ textAlign: "right"}}>{forman.OCT}</td>
                <td style={{ textAlign: "right"}}>{forman.OTB}</td>
                <td style={{ textAlign: "right"}}>{forman.NOV}</td>
                <td style={{ textAlign: "right"}}>{forman.NVM}</td>
                <td style={{ textAlign: "right"}}>{forman.DEC}</td>
                <td style={{ textAlign: "right"}}>{forman.DIC}</td>
            </tr>
        })
    }

    const RenderLoyalty = () => (
        <div style={{ fontSize: '13px' }}>
            <table className='table'>
                <thead>
                    <tr>
                        <td></td>
                        <td style={{ textAlign: "center"}} colSpan='2'>JAN</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>FEB</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>MAR</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>APR</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>MAY</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>JUN</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>JUL</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>AUG</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>SEP</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>OCT</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>NOV</td>
                        <td style={{ textAlign: "center"}} colSpan='2'>DEC</td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td> Foreman </td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>
                        <td style={{ textAlign: "right"}}>PLAN</td>
                        <td style={{ textAlign: "right"}}>REAL</td>  
                    </tr>
                </thead>
                <tbody>
                    <DrawYards />
                </tbody>
            </table>
        </div>        
    )

    return (
        <Layout
            title={titleLabel}
            description='Loyalty plan'
        >
            <RenderLoyalty />
        </Layout>
    )
}

export default LoyaltyAcomplishment