import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DataGrid from 'react-data-grid';
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { CSVLink } from "react-csv"
import { api } from '../utils/Api'
import PlantPicker from '../plant/PlantPicker';

const ReportLoad = () => {
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [dateChange, setDateChange] = useState(true)
    const [originalData, setOriginalData] = useState([])
    const [loading, setLoading] = useState(true)
    const [plant, setPlant] = useState('61985b6a86825f39cc4aba38')

    useEffect(() => {
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`

        if (endDate < startDate) {            
            setEndDate(startDate)
            fechaFin = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
        } 
        
        if (dateChange) {
            setLoading(true)
            getLoads(fechaIni, fechaFin)
        }        
        
    }, [startDate, endDate, plant])

    const columna = [
        { key: 'date', name: 'Date', resizable: true},
        { key: 'shipAddress', name: 'Address', resizable: true},
        { key: 'driver', name: 'Driver', resizable: true},
        { key: 'yards', name: 'Yards'},
        { key: 'deliveryTicket', name: 'Ticket'},
        { key: 'orderCode', name: 'Order Code'},
        { key: 'plant', name: 'Plant'},
        { key: 'subplant', name: 'Subplant'},
        { key: 'createdBy', name: 'Created By'},
        { key: 'updatedBy', name: 'Updated By'},
    ]

    const headers = () => {
        return columna.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    // get only required attributes for report renderer
    const mapData = data => {
        const nuevo = data.map((dato) => {
            return {
                date: new Date(dato.date).toLocaleDateString(),
                yards: dato.yards,     
                driver: dato.driver,
                deliveryTicket: dato.deliveryTicket,
                orderCode: dato.orderCode,         
                shipAddress: dato.job.shipAddress,      
                plant: dato.job.plant.name,
                subplant: dato.job.subplant,
                createdBy: dato.createdBy ? dato.createdBy.email : 'Not assigned',  
                updatedBy: dato.updatedBy ? dato.updatedBy.email : 'Not assigned'  
            }
        })  

        setRows(nuevo)
    }

    const getLoads = async (startDate, endDate) => {            
        const url = api + `/loadscheduledplant?startDate=${startDate}&endDate=${endDate}&plant=${plant}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setOriginalData(data)
            mapData(data)            
            setColumns(columna)            
            setLoading(false)           
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onChangePlant = selectedOption => {
        if (selectedOption) {
            setPlant(selectedOption.value)
        }
    }

    return (
        <Layout
        title='Report'
        description='Loads Report'
    >         
        <div>
            <div className="form-group row">
                <div className='col-sm-4'>
                    <label className='text-muted'>Start Date</label>
                    <DatePicker 
                        selected={startDate} 
                        onChange={(date)=>{
                            setStartDate(date)
                            setDateChange(true)
                        }}
                    /> 
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>End Date</label>
                    <DatePicker 
                        selected={endDate}                             
                        onChange={(date)=>{
                            setEndDate(date)
                            setDateChange(true)
                        }}                
                    /> 
                </div>                 
                <div className='col-sm-4'>
                    <label className='text-muted'>Plant</label>
                    <PlantPicker action={onChangePlant} value={plant} />
                </div>                 
            </div>                
        </div>  
        {
            loading ? <Spinner /> :
            <div>
            <DataGrid 
                className='rdg-light' 
                columns={columns} 
                rows={rows}                                 
            />    
            <CSVLink data={rows} headers={headers()} filename={"57concrete-report.csv"}>
                Download report
            </CSVLink>
            </div>
            
        }                    
    </Layout>
    )
}

export default ReportLoad