import {
    GET_TARGETS,
    GET_YARDS_SOLD,
    TARGET_ERROR
} from '../actions/types'

const initialState = {
    loading: true,
    targets: [],
    yardsSold: []
}

function targetReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_TARGETS:
            return {
                ...state,
                targets: payload,
                loading: false
            }
        case GET_YARDS_SOLD:
            return {
                ...state,
                yardsSold: payload,
                loading: false
            }
        case TARGET_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default targetReducer