import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button, Tag } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
import dayjs from 'dayjs'
const { Text } = Typography

const SMSList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [smsList, setSmsList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return <Flex justify='space-between'>
                    {
                        (
                            localStorage.getItem('email') === 'ec@57concrete.com' || 
                            localStorage.getItem('email') === 'eliud@gmail.com' ||
                            localStorage.getItem('email') === 'jp@57concrete.com' ||
                            localStorage.getItem('email') === 'rl@57concrete.com'
                        ) && 
                        <Button 
                        type="primary" 
                        icon={<EditFilled twoToneColor={'white'}/>} 
                        onClick={e=> history.push(`/sms/${record.id}`)}
                        />
                    }
                </Flex>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
                let color = 'default'
                let text = value
                switch (value) {
                    case 'Scheduled':
                        color = 'blue'
                        break;
                    case 'Delivered':
                        color = 'green'
                        break;
                    case 'Canceled':
                        color = 'red'
                        break;
                    default:
                        value = 'None'
                        break;
                }
                return <Tag color={color}>{text}</Tag>
            }
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Schedule Date',
            dataIndex: 'scheduleDate',
            key: 'scheduleDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Customer Type',
            dataIndex: 'customerType',
            key: 'customerType',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
        let isMounted = true
        getSMSList()
        return () => { isMounted = false }
    }, [])
    
    const getSMSList = async() => {
        setLoading(true)

        const url = api + '/smsmessage'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    message: item.message,
                    status: item.status,
                    customerType: item.customerType,
                    scheduleDate: item.scheduleDate ? `${dayjs(item.scheduleDate).toDate().toLocaleDateString()} - ${dayjs(item.scheduleDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-',
                    startDate: item.startDate ? `${dayjs(item.startDate).toDate().toLocaleDateString()} - ${dayjs(item.startDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-',
                    endDate: item.endDate ? `${dayjs(item.endDate).toDate().toLocaleDateString()} - ${dayjs(item.endDate).toDate().toLocaleTimeString([], { timeStyle: 'short' })}` : '-'
                })
            })
            setSmsList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewSMS = () => {
        history.push('/bulksms')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (
                    localStorage.getItem('email') === 'ec@57concrete.com' || 
                    localStorage.getItem('email') === 'eliud@gmail.com' ||
                    localStorage.getItem('email') === 'jp@57concrete.com' ||
                    localStorage.getItem('email') === 'rl@57concrete.com'
                ) &&
                <Button
                    onClick={addNewSMS}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                >
                    New SMS
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={smsList}
            />
        </Flex>
    }

    return (
        <Layout
            title='SMS'
            description='SMS List'
        >
            <Flex vertical gap={'large'}>
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default SMSList