import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const EmploymentsRender = ({currentEmployment, employments, setEmployments, toggleModal, edit, index}) => {

    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [supervisor, setSupervisor] = useState('');

    useEffect(() => {
        setCurrentEmployment()
    }, [edit])
    

    const setCurrentEmployment = () => {
        if(edit){
            setCompany(currentEmployment.company);
            setAddress(currentEmployment.address);
            setPhone(currentEmployment.phone);
            setSupervisor(currentEmployment.supervisor);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const newEmployment = {
            company,
            address,
            phone,
            supervisor,
        };
        if(edit){
            employments[index].company = company;
            employments[index].address = address;
            employments[index].phone = phone;
            employments[index].supervisor = supervisor;
        }else{
            employments.push(newEmployment);
        }
        setEmployments(employments);
        toggleModal();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Company</label>
                    <input className='form-control'
                        name='companyName'
                        type='text'
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        placeholder='Company Name'
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Address</label>
                    <input className='form-control'
                        name='contactAddress'
                        type='text'
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        placeholder='Address'
                        required
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Phone"
                        value={phone}
                        onChange={e => setPhone(e)}
                        required
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor</label>
                    <input className='form-control'
                        name='supervisor'
                        type='text'
                        value={supervisor}
                        onChange={e => setSupervisor(e.target.value)}
                        placeholder='Supervisor'
                        required
                    />
                </div>
            </div>
            &nbsp;
            <div>
                <button type='submit' className='btn btn-success'>{edit ? 'Edit' : 'Add'}</button>
            </div>
            
        </form>
    )
}

export default EmploymentsRender