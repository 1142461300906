import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import { api } from '../utils/Api'

const initialState = {
    plant: '',
    deliveryTicket: '',
    customerName: '',
    projectName: '',
    deliveryAddress: '',
    orderPONumber: '',
    unitPrice: 0,
    loadSize: 0,
    quantity: 0,
    batchData: '',
    customerCode: '',
    orderCode: '',
    yards: 0,
    slump: '',
    codeMix: '',
    driver: '',
    truckNumber: '',
    cumulativeQuantity: 0,
    decreasingQuantity: 0,
    productDescription: '',
    amount: 0,
    authorizedName: '',
    waterAdded: 0,
    timeToJob: '',
    arriveJobTime: '',
    startUnloadTime: '',
    finishUnloadTime: '',
    arriveTime: '',
}

const Referral = props => {
    useEffect(() => {
        getReferral()
    }, [])

    const [formData, setFormData] = useState(initialState)
    const [refDate, setRefDate] = useState(null) 

    const {
        plant,
        customerName,
        orderPONumber,
        unitPrice,
        projectName,
        deliveryTicket,
        deliveryAddress,
        loadSize,
        quantity,
        batchData,
        customerCode,
        orderCode,
        yards,
        slump,
        codeMix,
        driver,
        truckNumber,
        cumulativeQuantity,
        decreasingQuantity,
        productDescription,
        amount,
        authorizedName,
        waterAdded,
        timeToJob,
        arriveJobTime,
        startUnloadTime,
        finishUnloadTime,
        arriveTime
    } = formData

    const getReferral = async () => {
        const url = api + '/referral/' + props.match.params.id 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}        

        try {
            const { data } = await axios.get(url)
            setFormData(data)  
            setRefDate(new Date(data.refDate))    
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateReferral = async () => {
        const url = api + '/referral/' + props.match.params.id 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}   
        
        console.log(url)

        try {
            formData.refDate = refDate
            // quitar los atributos de control de mongo
            delete formData.createdAt
            delete formData.updatedAt
            delete formData._id
            delete formData.__v
            console.log(formData)
            await axios.patch(url, formData)                    
            props.history.push('/referrallist')      
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const deleteReferral = async () => {
        const url = api + '/referral/' + props.match.params.id 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}        

        try {
            const { data } = await axios.delete(url)
            props.history.push('/referrallist')                         
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
    
    const onSubmit = e => {
        e.preventDefault()
        console.log('actualizar')
        updateReferral()
    }

    return (
        <Layout
                title='Referral'
                description='Referral Detail'
                className="container col-md-8 offset-md-2"
            >     
            <form className='form' onSubmit={onSubmit}>
            <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Customer Name</label>
                        <input 
                            onChange={onChange} 
                            type="text" 
                            name='customerName'
                            className="form-control" 
                            value={customerName}
                            required
                            placeholder="Customer Name"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Customer Code</label>
                        <input
                            type="text"
                            name='customerCode'
                            className='form-control'
                            onChange={onChange}
                            value={customerCode}
                            placeholder='Project'
                        />
                    </div>                         
                </div>
                <div className="form-group row">                     
                    <div className='col-sm-6'>
                        <label className='text-muted'>Project Name</label>
                        <input
                            type="text"
                            name='projectName'
                            className='form-control'
                            onChange={onChange}
                            value={projectName}
                            placeholder='Project'
                        />
                    </div>     
                    <div className='col-sm-6'>
                        <label className="text-muted">Order Code</label>
                        <input 
                            onChange={onChange} 
                            type="text" 
                            name='orderCode'
                            className="form-control" 
                            value={orderCode}
                            placeholder="Order Code"
                        />                
                    </div>                     
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <input 
                            onChange={onChange} 
                            type="text" 
                            name='deliveryTicket'
                            className="form-control" 
                            value={deliveryTicket}
                            placeholder="Delivery Ticket"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Ticket Date</label>
                        <DatePicker 
                            selected={refDate} 
                            onChange={(date) => setRefDate(date)}                                                
                        />
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Delivery Address</label>
                        <input 
                            onChange={onChange} 
                            type="text" 
                            name='deliveryAddress'
                            className="form-control" 
                            value={deliveryAddress}
                            placeholder="Address"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Order P.O. Number</label>
                        <input 
                            onChange={onChange} 
                            type="text" 
                            name='orderPONumber'
                            className="form-control" 
                            value={orderPONumber}
                            placeholder="P.O."
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Load Size</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1' 
                            name='loadSize'
                            className="form-control" 
                            value={loadSize}
                            placeholder="Load"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Yards Ordered</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1' 
                            name='yards'
                            className="form-control" 
                            value={yards}
                            placeholder="Yards"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Slump</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='slump'
                            className="form-control" 
                            value={slump}
                            placeholder="Slump"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Code Mix Design</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='codeMix'
                            className="form-control" 
                            value={codeMix}
                            placeholder="Code Mix"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Driver</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='driver'
                            className="form-control" 
                            value={driver}
                            placeholder="Driver"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Truck</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='truckNumber'
                            className="form-control" 
                            value={truckNumber}
                            placeholder="Truck No."
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Quantity</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1' 
                            name='quantity'
                            className="form-control" 
                            value={quantity}
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Cumulative Quantity</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1' 
                            name='cumulativeQuantity'
                            className="form-control" 
                            value={cumulativeQuantity}
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Decreasing Quantity</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1'
                            name='decreasingQuantity'
                            className="form-control" 
                            value={decreasingQuantity}
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Product Description</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='productDescription'
                            className="form-control" 
                            value={productDescription}
                            placeholder="Description"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Unit Price</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1' 
                            name='unitPrice'
                            className="form-control" 
                            value={unitPrice}
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Amount</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1' 
                            name='amount'
                            className="form-control" 
                            value={amount}
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Batch data</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='batchData'
                            className="form-control" 
                            value={batchData}
                            placeholder="Batch data"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Water Added in Gallons</label>
                        <input 
                            onChange={onChange} 
                            type="number"
                            step='0.1'
                            name='waterAdded'
                            className="form-control" 
                            value={waterAdded}
                            placeholder="Water added"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Truck time to job</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='timeToJob'
                            className="form-control" 
                            value={timeToJob}
                            placeholder="To job"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Truck time arrive job</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='arriveJobTime'
                            className="form-control" 
                            value={arriveJobTime}
                            placeholder="Arrive job"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Truck Start unload time</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='startUnloadTime'
                            className="form-control" 
                            value={startUnloadTime}
                            placeholder="Start unload"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Truck finish unload time</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='finishUnloadTime'
                            className="form-control" 
                            value={finishUnloadTime}
                            placeholder="Finish unload"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className='text-muted'>Truck arrive time</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='arriveTime'
                            className="form-control" 
                            value={arriveTime}
                            placeholder="Arrive Plant"
                        />                
                    </div>  
                    <div className='col-sm-6'>
                        <label className='text-muted'>Authorization Name</label>
                        <input 
                            onChange={onChange} 
                            type="text"
                            name='authorizedName'
                            className="form-control" 
                            value={authorizedName}
                            placeholder="Authorized by"
                        />  
                    </div>                         
                </div>
                <div className="form-group row">
                    <div className='col-sm-2'>
                        <input className='btn btn-primary' placeholder='Save' type='submit' />                 
                    </div>  
                    <div className='col-sm-2'>
                        <input 
                            type='text' 
                            onClick={(e) => {if (window.confirm('Are you sure you want to delete the Referral?')) deleteReferral() }} 
                            size='8'
                            className='btn btn-danger' 
                            readOnly
                            defaultValue='Delete'
                        />
                    </div>  
                    <div className='col-sm-2'>
                        <Link 
                            to={'/referralpdf/'+props.match.params.id}
                            className='btn btn-dark'
                        >   
                            <FontAwesomeIcon icon={faPrint} /> PDF 
                        </Link>
                    </div>                          
                </div>             
            </form>    
        </Layout>
    )
}

export default Referral