import React, { useState } from 'react'
import { validEmail } from '../utils/Validate'
import { api } from '../utils/Api'
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const ForemanNuevo = ({parentCallback}) => {

    const initialState = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
    }

    const [formData, setFormData] = useState(initialState)
    const [validWarning, setValidWarning] = useState(false) // Used for e-mail

    const {
        firstName,
        lastName,
        email,
        phone,
        address,
    } = formData

    const onSubmit = async (e) => {
        e.preventDefault()

        if (phone.length > 0 && firstName.length > 0 && lastName.length > 0) {
            setValidWarning(false)

            if (validEmail(email) === true || email === '') {
                createForeman()
            } else {
                setValidWarning(true)
            }
            
        } else {
            alert('Foreman\'s name and phone number are required')
        }
    }

    const createForeman = async () => {
        const url = api + '/foreman'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.post(url, formData)
            // Enviar data
            const mayordomo = {
                firstName,
                lastName,
                phone,
                foreman: data._id
            }
            // pass data to parent
            parentCallback(mayordomo)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const changePhone = e => setFormData({ ...formData, phone: e })

    return (
        <div>
            {
                validWarning
                    ? <div className="form-group row">
                        <label className="form-control btn-danger text-center"> e-mail is not valid </label>
                    </div>
                    : null
            }
            <form className='form' onSubmit={onSubmit}>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">Foreman Name</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='firstName'
                            className="form-control"
                            value={firstName}
                            placeholder="first name"
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Foreman Last Name</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='lastName'
                            className="form-control"
                            value={lastName}
                            placeholder="last name"
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-6'>
                        <label className="text-muted">E-mail Address</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='email'
                            className={validEmail(email) ? "form-control border border-success" : "form-control border border-danger"}
                            value={email}
                            placeholder="e-mail"
                            required
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Phone Number</label>
                        <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='phone'
                        placeholder="Phone number"
                        value={phone}
                        onChange={changePhone}
                        required
                    />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-sm-12'>
                        <label className="text-muted">Address</label>
                        <input
                            onChange={onChange}
                            type="text"
                            name='address'
                            className="form-control"
                            value={address}
                            placeholder="address"
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="form-group row">
                    <div className="col-6">
                        <button className='btn btn-primary' type='submit'> Save </button>
                    </div>
                </div>
            </form>
        </div>
    )
}


export default ForemanNuevo