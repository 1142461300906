import { Button, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import { customerTypeOptions, notifiableOptions, paymentTermOptions, paymentTypeOptions, quadrantTypeOptions } from '../../constants/Customer'
const { Option } = Select

const ClientRegistration = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [customerNumber, setCustomerNumber] = useState('')
    const [contactType, setContactType] = useState('Email')
    // const [customerTypeOptions, setCustomerTypeOptions] = useState([])
    // const [paymentTypeOptions, setPaymentTypeOptions] = useState([])
    // const [paymentTermOptions, setPaymentTermOptions] = useState([])
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getCustomerNumber()
    }, [])

    const getCustomerNumber = async () => {
        const url = api + '/customernumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setCustomerNumber('A' + data.customerNumber.toString())
            setLoading(false)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const duplicatedUserPhone = async (phone) => {
        const url = api + `/userphone/+1${phone}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const duplicatedUserEmail = async (email) => {
        const url = api + '/useremail/' + email
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createCompany = async (datas) => {
        let correo = ''
        if (datas.email) {
            correo = datas.email
        } else {
            correo = datas.contactPhone + '@gmail.com'
        }

        const user = {
            name: datas.name,
            email: correo,
            phone: `+1${datas.contactPhone}`,
            password: datas.password,
            role: 'Customer'
        }

        try {
            let url = api + '/users'
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const { data } = await axios.post(url, user)
            const contact = data.user._id
            const companyName = datas.companyName
            const company = {
                ...datas,
                'name': companyName,
                'contact': contact,
                'customerNumber': customerNumber
            }

            if(datas.notifiableEvents){
                let values = []
                datas.notifiableEvents.map((item) => {
                    values.push({
                        value: item.value,
                        label: item.label
                    })
                })
                company.notifiableEvents = values
            }

            url = api + '/customer'

            const respuesta = await axios.post(url, company)
            openNotification('success', 'Success', 'Customer created')
            setLoading(false)
            history.push(`/clientconfirmation/${respuesta.data._id}`)
        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create user')
            console.error(err)
            return null
        }
    }
    
    const validateUser = async (values) => {

        if(values.contactType === 'Email'){
            const duplicadoEmail = await duplicatedUserEmail(values.email)
            const duplicadoPhone = await duplicatedUserPhone(values.contactPhone)
            
            if (duplicadoEmail) {
                openNotification('warning', 'Duplicated user', 'User already exist with Email')
                setLoading(false)
            } else if(duplicadoPhone){
                openNotification('warning', 'Duplicated user', 'User already exist with Phone')
                setLoading(false)
            }else {
                createCompany(values)
            }
        }else{
            const duplicadoPhone = await duplicatedUserPhone(values.contactPhone)
            if(duplicadoPhone){
                openNotification('warning', 'Duplicated user', 'User already exist with Phone')
                setLoading(false)
            }else {
                createCompany(values)
            }
        }
    }

    const onChangeContactType = (e) => {
        setContactType(e.target.value)
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                prefix: '+1',
                contactType: 'Email',
                paymentType: 'PrePay',
                creditRequested: false,
                state: 'TX',
                creditAmount: 0,
                contactFax: '',
                accountsPayableFax: '',
                accountsPayableEmail: '',
                notifiableEvents: []
            }}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Company Name'
                    name='companyName'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill company name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Customer Type'
                    name='customerType'
                    rules={[
                        {
                            required: true,
                            message: 'Please select customer type'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select customer type`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={customerTypeOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Quadrant Type'
                    name='quadrantType'
                    rules={[
                        {
                            required: true,
                            message: 'Please select quadrant type'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select quadrant type`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={quadrantTypeOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Contact Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Contact Phone'
                    name='contactPhone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password',
                        },
                        {
                            min: 5,
                            message: 'Password must have at least 5 characters'
                        }
                        ]}
                        hasFeedback
                    >
                        <Input.Password size='large' />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        {
                            min: 5,
                            message: 'Password must have at least 5 characters'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={4}>
                    <Form.Item
                        name="contactType"
                        label="Contact Type"
                        rules={[
                            {
                            required: true,
                            message: 'Please pick a type!',
                            },
                        ]}
                        >
                        <Radio.Group onChange={onChangeContactType} size='large'>
                            <Radio.Button value="SMS">SMS</Radio.Button>
                            <Radio.Button value="Email">Email</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {
                    contactType === 'Email' ?
                    <Col span={20}>
                        <Form.Item
                        label='Contact Email'
                        name='email'
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail',
                            },
                            {
                                required: true,
                                message: 'Please fill email'
                            }
                        ]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    :
                    <Col span={20}>
                        <Form.Item
                        label='Customer Phone'
                        name='sms'
                        rules={[
                            {
                                required: true,
                                message: 'Please fill phone',
                            },
                            {
                                len: 10,
                                max: 10,
                                message: 'Phone number must have 10 numbers'
                            }
                        ]}
                        >
                            <Input size='large' addonBefore={prefixSelector} />
                        </Form.Item>
                    </Col>
                }
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Notifiable Events'
                    name='notifiableEvents'
                    >
                        <Select
                        showSearch
                        labelInValue
                        placeholder={`Select notifiable events`}
                        size='large'
                        mode="multiple"
                        options={notifiableOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Company Address'
                    name='address'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill address'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Company City'
                    name='city'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill city'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Company State'
                    name='state'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill state'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Company ZIP'
                    name='zip'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill zip'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Contact Fax Number'
                    name='contactFax'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='A/P Contact Name'
                    name='accountsPayableContact'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill contact name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='A/P Contact Email'
                    name='accountsPayableEmail'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='A/P Contact Phone'
                    name='accountsPayablePhone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='A/P Contact Fax'
                    name='accountsPayableFax'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Payment Type'
                    name='paymentType'
                    rules={[
                        {
                            required: true,
                            message: 'Please select payment type'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select payment type`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={paymentTypeOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Payment Term'
                    name='paymentTerm'
                    rules={[
                        {
                            required: true,
                            message: 'Please select payment term'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select payment term`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={paymentTermOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Credit Amount'
                    name='creditAmount'
                    >
                        <InputNumber size='large' style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="creditRequested"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            Request Credit
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Customer'
        description='Create new Customer'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default ClientRegistration