import {
    ADD_PAYMENT,
    GET_PAYMENTS,
    PAYMENT_ERROR,
    UPDATE_PAYMENT,
    DELETE_PAYMENT
} from '../actions/types'

const initialState = {
    loading: true,
    payments: []
}

function paymentReducer(state = initialState, action) {
    const {type, payload} = action
    switch (type) {
        case ADD_PAYMENT:
            return {
                ...state,
                payment: payload,
                loading: false
            }
        case UPDATE_PAYMENT:
            return {
                ...state,
                loading: false,
                payment: payload
            }
        case GET_PAYMENTS:
            return {
                ...state,
                payments: payload,
                loading:false
            }
        case DELETE_PAYMENT:
            return {
                ...state,
                payment: payload,
                loading: false
            }
        case PAYMENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default paymentReducer