import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import { CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import {
    DeleteOutlined,
    CloseOutlined,
    RetweetOutlined
} from '@ant-design/icons'
const { TextArea } = Input

const SMSEdit = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [initialValues, setInitialValues] = useState({})
    const [loadingForm, setLoadingForm] = useState(true)
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getSms()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getSms = async () => {
        try {
            let url = api + `/smsmessage/${props.match.params.id}` 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const { data } = await axios.get(url)

            setInitialValues({
                ...initialValues,
                scheduleDate: data.scheduleDate ? dayjs(data.scheduleDate) : null,
                message: data.message,
                customerType: data.customerType,
                status: data.status,
                type: data.type
            })
            setLoadingForm(false)
            setLoading(false)

        } catch (err) {
            openNotification('error', 'Error', 'Error while trying to get sms')
            console.error(err.message)
            return null
        }
    }

    const deleteSMS = async () => {
        const url = api + '/smsmessage/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            await axios.delete(url)
            openNotification('info', 'Info', 'SMS Deleted')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/smslist')
            }, 1000)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const cancelSMS = async () => {
        const url = api + '/smsmessagecancel/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            setLoading(true)
            await axios.post(url)
            openNotification('info', 'Info', 'SMS Canceled')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/smslist')
            }, 1000)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const rescheduleSMS = async () => {
        props.history.push({
            pathname: '/bulksms',
            state: { customerType: form.getFieldValue('customerType'), message: form.getFieldValue('message') }
        })
    }
    

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Message'
                    name='message'
                    >
                        <TextArea rows={4} disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Schedule Date'
                    name='scheduleDate'
                    >
                        <DatePicker 
                        format='MM/DD/YYYY HH:mm' 
                        size='large'
                        style={{width: '100%'}} 
                        disabled 
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Customer Type'
                    name='customerType'
                    >
                        <Input size='large' disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                {
                    (
                        localStorage.getItem('email') === 'ec@57concrete.com' || 
                        localStorage.getItem('email') === 'eliud@gmail.com' ||
                        localStorage.getItem('email') === 'jp@57concrete.com' ||
                        localStorage.getItem('email') === 'rl@57concrete.com'
                    ) &&
                    <Col>
                        <Popconfirm
                            title="Delete the SMS"
                            description="Are you sure to delete this sms?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteSMS}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading} icon={<DeleteOutlined twoToneColor={'white'}/>}>Delete</Button>
                        </Popconfirm>
                    </Col>
                }
                {
                    ((
                        localStorage.getItem('email') === 'ec@57concrete.com' || 
                        localStorage.getItem('email') === 'eliud@gmail.com' ||
                        localStorage.getItem('email') === 'jp@57concrete.com' ||
                        localStorage.getItem('email') === 'rl@57concrete.com'
                    ) && form.getFieldValue('status') !== 'Canceled') &&
                    <Col>
                        <Popconfirm
                            title="Cancel the SMS"
                            description="Are you sure to cancel this sms?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={cancelSMS}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading} icon={<CloseOutlined twoToneColor={'white'}/>}>Cancel SMS</Button>
                        </Popconfirm>
                    </Col>
                }
                <Col>
                    <Popconfirm
                        title="Reschedule the SMS"
                        description="Are you sure to reschedule this sms?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={rescheduleSMS}
                    >
                        <Button type='primary' htmlType='button' loading={loading} icon={<RetweetOutlined twoToneColor={'white'}/>}>Reschedule SMS</Button>
                    </Popconfirm>
                </Col>                
            </Row>
        </Form>
    }

    return (
        <Layout
        title='SMS'
        description='SMS Attributes'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner /> : <CustomForm />}
        </Layout>
    )
}

export default SMSEdit