import React, { useEffect } from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPlantList } from '../../actions/plant'
import Spinner from '../layout/Spinner'
import { toCurrency } from '../utils/Convert'


const PlantList = ({ getPlantList, plant: { plants, loading } }) => {
    useEffect(() => {
        getPlantList()
    }, [getPlantList])

    const DrawList = () => {
        return plants.map((planta) => {
            return <tr key={planta._id}>
                <td>
                    <Link to={`/plant/${planta._id}`} >
                        {planta.number}
                    </Link>
                </td>
                <td>
                    {planta.name || 'NA'}
                </td>
                <td>
                    {planta.address || 'NA'}
                </td>
                {/* <td>
                    {planta.productionCapacity || 'NA'}
                </td>
                <td>
                    {planta.timeToLoadTruck || 'NA'}
                </td>
                <td>
                    {planta.slumpRackTime || 'NA'}
                </td>
                <td>
                    {planta.washoutOnSite || 'NA'}
                </td>
                <td>
                    {new Date(planta.startTime).toLocaleString('en-US', { hour: 'numeric', hour12: true }) || 'NA'}
                </td>
                <td>
                    {new Date(planta.closingTime).toLocaleString('en-US', { hour: 'numeric', hour12: true }) || 'NA'}
                </td>
                <td>
                    {planta.radioAttention || planta.radioAttention == 0 ? planta.radioAttention : 'NA'}
                </td>
                <td>
                    {planta.dieselPerformance || planta.dieselPerformance == 0 ? planta.dieselPerformance : 'NA'}
                </td> */}
                <td>
                    {planta.haulingCost || planta.haulingCost == 0 ? toCurrency(planta.haulingCost,2) : 'NA'}
                </td>
                <td>
                    {
                        planta.subplants.map((subplant, k)=> (
                            <p key={k}>{subplant.name}</p>
                        ))
                    }
                </td>
            </tr>
        })
    }

    const RenderPlants = () => (
        <table className='table'>
            <thead>
                <tr>
                    <th>Plant Number</th>
                    <th>Name</th>
                    <th>Address</th>
                    {/* <th>Production Capacity</th>
                    <th>Time to load truck</th>
                    <th>Slump Rack time</th>
                    <th>Washout on site</th>
                    <th>Start Time</th>
                    <th>Closing Time</th>
                    <th>Radio Attention</th>
                    <th>Diesel Performance</th> */}
                    <th>Hauling Cost</th>
                    <th>Subplants</th>
                </tr>
            </thead>
            <tbody>
                <DrawList />
            </tbody>
        </table>
    )

    return (
        <Layout
            title='Plant'
            description='Plant List'
            className='container col-md-8 offset-md-2'
        >
            {
                loading ? <Spinner /> : (plants.length > 0 ? <RenderPlants /> : <h4>No Plants yet</h4>)
            }
            <div>
                <Link to='/newplant' className='btn btn-success'>Add plant</Link>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    plant: state.plant
})

export default connect(mapStateToProps, { getPlantList })(PlantList)