import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import axios from 'axios'
import { Flex, Radio, Statistic, Table, Typography } from 'antd'
import { CustomSpinner } from '../utils/CustomComponents'
const { Text } = Typography

const JobsSellerClassification = ({today, plant}) => {

    const [jobs, setJobs] = useState([])
    const [jobs2, setJobs2] = useState([])
    const [loading, setLoading] = useState(true)
    const [type, setType] = useState('Residential')

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Seller',
            dataIndex: 'seller',
            key: 'seller',
            width: 100,
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Scheduled',
            dataIndex: 'scheduled',
            key: 'scheduled',
            width: 60,
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Poured',
            dataIndex: 'poured',
            key: 'poured',
            width: 60,
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Pouring',
            dataIndex: 'pouring',
            key: 'pouring',
            width: 60,
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Jobs',
            dataIndex: 'jobs',
            key: 'jobs',
            width: 60,
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
        getJobs2(today, plant)
    }, [today])


    const assignData = (jobs) => {
        const groupByCustomer = jobs.reduce((group, object) => {
            const { name } = object.seller;
            group[name] = group[name] ?? [];
            group[name].push(object);
            return group;
        }, {})

        const arrayObjects = Object.values(groupByCustomer)

        let finalList = []

        arrayObjects.map((item) => {
            let scheduled = 0
            let pouring = 0
            let poured = 0
            let count = 0
            let sellerName = item[0].seller.name
            for (let is = 0; is < item.length; is++) {
                const element = item[is];
                
                if(element.jobStatus === 'Scheduled'){
                    scheduled += element.targetYD3
                    count++
                }else if(element.jobStatus === 'Active'){
                    pouring += element.loadYD3
                    count++
                }else if(element.jobStatus === 'ok'){
                    poured += element.loadYD3
                    count++
                }
            }

            finalList.push({
                seller: sellerName,
                scheduled: scheduled,
                pouring: pouring,
                poured: poured,
                jobs: count,
            })
        })
        
        return finalList
    }

    const getJobs2 = async (today, plant) => {
        const url = api + `/jobdateplant?date=${today}&plant=${plant}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
        const { data } = await axios.get(url)
        const filteredResidentialJobs = data.filter((item) => 
            item.seller.name.toUpperCase() === 'CORPORATE' || 
            item.seller.name.toUpperCase() === 'JESUS CANTU' || 
            item.seller.name.toUpperCase() === 'EDGAR BRIONES' || 
            item.seller.name.toUpperCase() === 'CRISTIAN TORRES' || 
            item.seller.name.toUpperCase() === 'FERNANDO RAMIREZ' ||
            item.seller.name.toUpperCase() === 'EDUARDO SALGADO' ||
            item.seller.name.toUpperCase() === 'DAVID SANTAMARIA' ||
            item.seller.name.toUpperCase() === 'ERIK PECINA' ||
            item.seller.name.toUpperCase() === 'OSCAR VALADEZ' ||
            item.seller.name.toUpperCase() === 'HECTOR PERALTA'
        )
        const filteredCommercialJobs = data.filter((item) => 
            item.seller.name.toUpperCase() === 'JUAN HERNANDEZ' || 
            item.seller.name.toUpperCase() === 'CARLOS MANUEL CASTAÑOS' ||
            item.seller.name.toUpperCase() === 'FERMIN AVENDAÑO'
        )

        let residential = assignData(filteredResidentialJobs)
        let commercial = assignData(filteredCommercialJobs)
        setJobs(residential)
        setJobs2(commercial)

        setLoading(false)

        } catch (err) {
        console.error(err)
        return null
        }
    }

    const summary = (pageData) => {
        let totalScheduled = 0
        let totalPoured = 0
        let totalPouring = 0
        let totalJobs = 0
    
        pageData.forEach(({ scheduled, poured, pouring, jobs }) => {
            totalScheduled += scheduled
            totalPoured += poured
            totalPouring += pouring
            totalJobs += jobs
        })
        
        return (
            <>
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text style={{fontSize: 12}} strong>{'Total'}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalScheduled} style={{fontWeight: 'bold'}} precision={2} valueStyle={{fontSize: 12}} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalPoured} style={{fontWeight: 'bold'}} precision={2} valueStyle={{fontSize: 12}} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalPouring} style={{fontWeight: 'bold'}} precision={2} valueStyle={{fontSize: 12}} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Statistic title="" value={totalJobs} style={{fontWeight: 'bold'}} precision={2} valueStyle={{fontSize: 12}} />
                </Table.Summary.Cell>
            </Table.Summary.Row>
            </>
        )
    }

    const ResidentialTable = () => {
        return <Table 
        columns={columns}
        dataSource={jobs}
        pagination={false}
        summary={summary}
        />
    }

    const CommercialTable = () => {
        return <Table 
        columns={columns}
        dataSource={jobs2}
        pagination={false}
        summary={summary}
        />
    }

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    const RenderTable = () => {
        return <Flex vertical gap={'middle'}>
        <Radio.Group value={type} onChange={onChangeType} buttonStyle="solid">
            <Radio.Button value="Residential">Residential</Radio.Button>
            <Radio.Button value="Commercial">Commercial</Radio.Button>
        </Radio.Group>
        {
            type === 'Residential' &&
            <ResidentialTable />
        }
        {
            type === 'Commercial' &&
            <CommercialTable />
        }
        </Flex>
    }

  
    return (
        <Flex vertical gap={'large'}>
        {
            loading ? <CustomSpinner/> : <RenderTable/>
        } 
        </Flex>
    )
}

export default JobsSellerClassification