import InfoWindowComponent from "./InfoWindowComponent";
import PropTypes from 'prop-types';

// Marker component
const MarkerComponent = ({ show, truck, hover }) => {
    const markerStyle = {
      border: '1px solid white',
      borderRadius: '50%',
      height: 20,
      width: 20,
      backgroundColor: hover ? '#ED1C24' : '#233F86',
      cursor: 'pointer',
      zIndex: 10,
      display: 'flex',
      transform: hover ? 'scale(2,2)' : 'scale(1,1)',
    };
    
    const textStyle = {
      margin: 'auto',
      color: 'white'
    }
  
    return (
      <>
        <div style={markerStyle} className='effect' >
          <p style={textStyle}>{truck.number}</p>
        </div>
        {show && <InfoWindowComponent truck={truck} hover={hover} />}
      </>
    );
  };

MarkerComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    hover: PropTypes.bool.isRequired,
    truck: PropTypes.shape({
        number: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
};

export default MarkerComponent;