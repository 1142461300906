import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import InvoicePerTicket from '../invoice/InvoicePerTicket'

const LoadList = ({list, setList, calculateTotals, isTotal}) => {

    const [toggleModal, setToggleModal] = useState(false)
    const [ticket, setTicket] = useState({})
    const [loadID, setLoadID] = useState('')

    const viajes = () => {
        return list.map((viaje) => {
            return (
                <tr key={viaje._id}>
                    <td>
                        {viaje.deliveryTicket ? viaje.deliveryTicket : 'NA'}
                    </td>
                    <td>
                        {viaje.orderCode ? viaje.orderCode : 'NA'}
                    </td>
                    <td>
                        {viaje.yards ? viaje.yards : '0'}
                    </td>
                    {
                        !isTotal &&
                        <td>
                            <Link 
                                onClick={(e) => {
                                    setLoadID(viaje._id)
                                    setTicket(list[list.findIndex((obj) => obj._id == viaje._id)])
                                    
                                    setToggleModal(true)
                                }}
                                to='#'
                                className="btn btn-primary"
                            >
                                Edit
                            </Link>   
                        </td>
                    }
                    
                </tr>
            )
        })
    }

    /**
     * Update load selected
     * @param {Object} data current ticket 
     */
    const updateLoad = (data) => {
        const index = list.findIndex((obj) => obj._id == loadID)
        list[index] =  data
        setList(list)
    }

    /**
     * Save current load (ticket) changes
     */
    const onSave = () => {
        updateLoad(ticket)
        calculateTotals()
    }
        
    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Delivery Ticket</th>
                        <th>Order Code</th>
                        <th>Yards</th>
                    </tr>
                </thead>
                <tbody>
                    {viajes()}
                </tbody>
            </table>
            <Modal show={toggleModal} animation={false} onHide={() => setToggleModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Ticket #{ticket.deliveryTicket}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InvoicePerTicket ticket={ticket} setTicket={setTicket}/>
                </Modal.Body>
                <Modal.Footer>
                    <Link 
                    to='#'
                    className="btn btn-success"
                    onClick={() => {
                        onSave()
                        setToggleModal(false)
                    }}
                    >
                        Save changes
                    </Link>
                </Modal.Footer>
            </Modal> 
        </div>
    )
}

export default LoadList