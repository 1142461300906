import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'

const ClockReport = () => {
    const [clockList, setClockList] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getClockList()
    }, [])

    // Calculate working hours
    const workingHr = (start, end) => {
        if (start && end) {
            const ini = new Date(start)
            const fin = new Date(end)
            const work = Math.ceil(Math.abs(fin-ini)/1000/60/60)
            return work
        } else {
            return 0
        }
    }

    // Get Data
    const getClockList = async () => {
        const url = api + '/checkdate?date=2022-4-25' 
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}   

        try {
            const { data } = await axios.get(url)
            console.log(data)
            const bitacora = data.map((hora) => {
                return {
                    driverName: hora.driver.name,
                    start: hora.clockIn,
                    end: hora.clockOut,
                    work: workingHr(hora.clockIn, hora.clockOut)
                }                
            })
            setClockList(bitacora)
            setLoading(false)
            console.log(bitacora)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }


    const DrawList = () => {
        if (clockList.length > 0) {
            return clockList.map((reloj, i) => {
                return <tr key={i}>
                    <td>{reloj.driverName} </td>                    
                    <td>
                        {new Date(reloj.start).toLocaleTimeString([], {timeStyle: 'short'})} 
                    </td>
                    <td>
                        {
                            reloj.end ? 
                            new Date(reloj.end).toLocaleTimeString([], {timeStyle: 'short'}) :
                            'NA'
                        } 
                    </td>
                    <td> {reloj.work} </td>
                </tr>
            })
        } else {
            return (
                <tr>
                    <td>No clock times</td>
                </tr>
            )
        }
    }

    const RenderClock = () => {
        return (
            <table className='table'>
                <thead>
                    <tr>
                        <td>Driver Name</td>
                        <td>Start time</td>
                        <td>End Time</td>
                        <td>Working hours</td>
                    </tr>
                </thead>
                <tbody>
                    <DrawList />
                </tbody>
            </table>
        )
    }

    return(
        <Layout
                title='Driver Woking Hours Report'
                description='Driver List'
                className="container col-md-8 offset-md-2"
            >                   
            {
                loading ? <Spinner /> : <RenderClock />
            }            
        </Layout>
    )
}

export default ClockReport
