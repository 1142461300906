import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import SellerPicker from '../seller/SellerPicker'
import PsiPicker from '../psi/PsiPicker'
import axios from 'axios'
import { api } from '../utils/Api'
import formatDate from '../utils/FormatDate'
import Spinner from '../layout/Spinner'
import Modal from 'react-bootstrap/Modal';
import PricesRender from './PricesRender'
import PsiPickerID from '../psi/PsiPickerID'
import { CSVLink } from "react-csv"

const ListPrice = () => {

    const [sellerId, setSellerID] = useState('')
    const [seller, setSeller] = useState({})
    const [sellerName, setSellerName] = useState('')
    const [psi, setPsi] = useState('')
    const [psiName, setPsiName] = useState('')
    const [startDate, setStartDate] = useState(new Date('2024-01-01'))
    const [endDate, setEndDate] = useState(new Date())
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)
    const [showModalPrice, setShowModalPrice] = useState(false)
    const [priceIndex, setPriceIndex] = useState(0)
    const [editPrice, setEditPrice] = useState(false)
    const [currentPrice, setCurrentPrice] = useState({})

    const columna = [
        { key: 'name', name: 'Customer'},
        { key: 'upperValley', name: 'Upper Valley'},
        { key: 'midValley', name: 'Mid Valley'},
        { key: 'lowerValley', name: 'Lower Valley'},
        { key: 'psiName', name:'Psi'}
    ]

    const headers = () => {
        return columna.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const getSellerClientList = async () => {
        const url = api + `/customersbyseller/${sellerId}?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}&psi=${psi}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setCustomers(data)
            setLoading(false)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const toggleModalPrice = () => {
        setShowModalPrice(!showModalPrice)
        setEditPrice(false)
    }

    const onChangeSeller = (selectedOption) => {
        if(selectedOption){
            setSeller(selectedOption)
            setSellerID(selectedOption.value)
            setSellerName(selectedOption.label)
        }
    }
    const onChangePsi = (selectedOption) => {
        if(selectedOption){
            setPsi(selectedOption.value)
            setPsiName(selectedOption.label)
        }
    }

    const onSearch = () => {
        setLoading(true)
        getSellerClientList()
    }

    const editCurrentCustomer = (customer, index) => {
        setPriceIndex(index)
        setCurrentPrice(customer)
        setEditPrice(true)
        setShowModalPrice(true)
    }

    const setPrices = (newPrices) => {
        setCustomers(newPrices)
    }

    const RenderTable = () => (
        <div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Upper Valley</th>
                        <th>Mid Valley</th>
                        <th>Lower Valley</th>
                        <th>PSI</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        customers.length === 0 ?
                        <tr>
                            <td colSpan={6} className='text-center'> No customers to display </td>
                        </tr>
                        :
                        customers.map((customer, index) =>(
                            <tr key={index}>
                                <td>{customer.name}</td>
                                <td>{customer.upperValley ? customer.upperValley : 'N/A'}</td>
                                <td>{customer.midValley ? customer.midValley : 'N/A'}</td>
                                <td>{customer.lowerValley ? customer.lowerValley : 'N/A'}</td>
                                <td>{customer.psiName ? customer.psiName : 'N/A'}</td>
                                <td>
                                    <button type='button' className='btn btn-primary' onClick={() => editCurrentCustomer(customer, index)}>Edit</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <CSVLink data={customers} headers={headers()} filename={"57concrete-report-prices.csv"}>
                Download report
            </CSVLink>
        </div>
    )


    const MenuBar = () => (
        <div className='form-group row'>
            <div className='col-sm-4'>
                <label className='text-muted'>Seller: {sellerName}</label>
                <SellerPicker action={onChangeSeller} />
            </div>
            <div className='col-sm-4'>
                <label className='text-muted'>PSI: {psiName}</label>
                <PsiPickerID action={onChangePsi} selectedOption={psi} isDisabled={false} />
            </div>
            <div className='col-sm-4'>
                <div><label className='text-muted'>{""}</label></div>
                <button className='btn btn-primary' onClick={onSearch}> Search </button>
            </div>
        </div>
    )


    return (
        <Layout
            title='List Price'
            description='List price per customer'
            className='container col-md-8 offset-md-2'
        >
            <MenuBar />
            {loading ? <Spinner /> : RenderTable()}
            <Modal
                show={showModalPrice}
                animation={false}
                onHide={toggleModalPrice}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Prices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PricesRender currentPrice={currentPrice} prices={customers} setPrices={setPrices} toggleModal={toggleModalPrice} edit={editPrice} index={priceIndex} currentPsi={psi} currentPsiName={psiName} />
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default ListPrice