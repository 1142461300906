import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { faCalendarDay, faCalendarWeek } from "@fortawesome/free-solid-svg-icons"
import JobAppointmentItem from './JobAppointmentItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { getFutureJobs } from '../../actions/job'
import Spinner from '../layout/Spinner'
import { jobIdFilter } from '../utils/JobIdFilter'

const JobAppointment = ({ getFutureJobs, job: { jobs, loading } }) => {
    const [totalTomorrow, setTotalTomorrow] = useState(0)
    const [totalFuture, setTotalFuture] = useState(0)
    const [change, setChange] = useState(false)
    const [jobID, setJobID] = useState("")
    const [hasAppointment, setHasAppointment] = useState(false)
    const [loadingCallback, setLoadingCallback] = useState(false)
    const [jobList, setJobList] = useState([]) // To use useEffect when jobs is updated

    useEffect(() => {
        getFutureJobs()
        sumas()
        setJobList(jobs)
    }, [change, loading, jobList])

    const sumas = () => {
        const today = new Date()
        //console.log("today => ", today)
        //console.log(jobs)

        let tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)
        //console.log("tomorrow => ", tomorrow)

        if (jobs) {
            const fechas = jobs.map(orden => {
                return {
                    fecha: new Date(orden.scheduledDate).toLocaleDateString(),
                    yardas: typeof orden.targetYD3 === "string" ? orden.targetYD3 : orden.targetYD3
                }
            })
            //console.log(fechas)
            const manana = new Date(tomorrow).toLocaleDateString()
            //console.log(`mañana_ ${manana}`)

            const sumManana = fechas.filter(yd => yd.fecha === manana).reduce((acc, viaje) => acc + viaje.yardas, 0)
            //console.log(sumManana)
            const sumFuture = fechas.filter(yd => yd.fecha !== manana).reduce((acc, viaje) => acc + viaje.yardas, 0)
            //console.log(sumFuture)
            setTotalTomorrow(sumManana)
            setTotalFuture(sumFuture)
        }
    }

    const handleCallback = (msg) => {
        console.log(msg)
        setChange(prev => !prev)
        //setChange(!change)
    }

    const DrawList = () => {
        let jobsFiltered = jobs.filter(job => job.customer.creditHold !== true)
        jobsFiltered = jobIdFilter(jobID, jobs)
        return jobsFiltered.filter((jb) => jb.hasAppointment === hasAppointment).map((job) => {
            return <JobAppointmentItem key={job._id} job={job} parentCallBack={handleCallback} />
        })
    }

    const changeToggle = () => {
        setHasAppointment(!hasAppointment)
        setChange(!change)
    }

    const RenderJobs = () => (
        <div>
            <div className='row' >
                <div className="col-md-6">
                    <div className='card text-center bg-success text-white mb-2'>
                        <div className="card-body">
                            <h3>Ready to Appointment</h3>
                            <h4 className="display-4">
                                <FontAwesomeIcon icon={faCalendarDay} /> {totalTomorrow}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6" onClick={changeToggle}>
                    <div className='card text-center bg-primary text-white mb-2'>
                        <div className='card-body'>
                            <h3>Ready to Schedule</h3>
                            <h4 className='display-4'>
                                <FontAwesomeIcon icon={faCalendarWeek} /> {totalFuture}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: '13px' }}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>JobID</th>
                            <th>Customer</th>
                            <th>Ship to Address</th>
                            <th>Date/time</th>
                            <th>Target YD3</th>
                            <th>PSI</th>
                            <th>Job Type</th>
                            <th>Seller</th>
                            <th>Appointment</th>
                            {hasAppointment && <th>Notify</th>}
                            {hasAppointment && <th>Notify Date</th>}
                            <th>Set Appointment</th>
                            {hasAppointment && <th>Close</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <DrawList />
                    </tbody>
                </table>
            </div>
        </div>
    )

    return (
        <Layout
            title='Jobs'
            description='Job Appointment'
        >
            <div className='col-sm-4'>
                <label className='text-muted'>JobId</label>
                <input
                    type="text"
                    value={jobID}
                    onChange={(e) => setJobID(e.target.value)}
                />
            </div>
            <br />
            {
                (loading || loadingCallback) ? <Spinner /> : (jobs ? <RenderJobs /> : <h4>No Jobs </h4>)
            }
        </Layout>
    )
}

const mapStateToProps = state => ({
    job: state.job
})

export default connect(mapStateToProps, { getFutureJobs })(JobAppointment)