import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import SellerPicker from '../seller/SellerPicker'
import DatePicker from 'react-datepicker'
import SlumpPicker from '../slump/SlumpPicker'
import JobTypePicker from '../job/JobTypePicker'
import JobPoorPicker from '../job/JobPoorPicker'
import { toCurrency } from '../utils/Convert'
import PsiPicker from '../psi/PsiPicker'
import 'react-datepicker/dist/react-datepicker.css'
import AddressGeolocation from '../map/AddressGeolocation'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import PaymentTypePicker from '../paymenttype/PaymentTypePicker'
import ProjectPicker from '../project/ProjectPicker'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { api } from '../utils/Api'
import ClientPaymentPicker from '../client/ClientPaymentPicker'
import { cityList } from '../utils/CityList'
import Select from 'react-select'
import PlantPicker from '../plant/PlantPicker'
import ForemanPicker from '../foreman/ForemanPicker'
import ForemanNew from '../foreman/ForemanNuevo'
import SpinnerButton from '../layout/SpinnerButton'

export default class NewQuote extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerName: '',
            customerNumber: '',
            customer: '',
            name: '',
            loadYD3: 0,
            targetYD3: '',
            shipAddress: '',
            shipCity: '',
            shipState: 'TX',
            shipZip: '',
            foreman: '',
            jobForeman: '',
            foremanPhone: '',
            foremanModal: false,
            apContact: '',
            apPhone: '',
            contact: '',
            date: new Date(),
            scheduledDate: new Date(),
            time: '08:00 AM',
            destinationMinutes: '30',
            taxExemptJob: false,
            comments: '',
            po: '',
            jobDuration: '',
            unitPrice: '',
            taxPrice: 0,
            slump: '',
            cliente: '',
            jobPoor: '',
            jobType: '',
            number: 0,
            seller: '',
            psi: '',
            balance: '',
            destinationTime: '',
            distance: '',
            latitude: '',
            longitude: '',
            searchAddress: false,
            paid: false,
            paymentType: '',
            approved: false,
            projectList: [],
            project: '',
            projectData: null,
            inspectionTimeStamp: null,
            supervisorPhone: '',
            supervisorName: '',
            pmPhone: '',
            projectManager: '',
            flag: false,
            selectedOption: '',
            plant: '',
            showErrorModal: false,
            loading: true,
            readOnlyAddress: true
        }

        this.onChangeJobName = this.onChangeJobName.bind(this)
        this.onChangeTargetYD3 = this.onChangeTargetYD3.bind(this)
        this.onChangeAddress = this.onChangeAddress.bind(this)
        this.onChangeContact = this.onChangeContact.bind(this)
        this.onChangeTime = this.onChangeTime.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChangePaymentType = this.onChangePaymentType.bind(this)
        this.onChangeCustomer = this.onChangeCustomer.bind(this)
    }

    onChangeJobName(e) {
        this.setState({
            name: e.target.value
        })
    }

    onChangeTargetYD3(e) {
        this.setState({
            targetYD3: e.target.value
        })
    }

    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        })
    }

    onChangeContact(e) {
        this.setState({
            contact: e.target.value
        })
    }

    onChangeDate = date => {
        this.setState({
            scheduledDate: date
        })
    }

    onChangeInspectionDate = date => {
        this.setState({
            inspectionTimeStamp: date
        })
    }

    onChangePlant = selectedPlant => {
        if (selectedPlant) {
            this.setState({
                plant: selectedPlant.value,
            })
        }
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === 'unitPrice') {
            this.setState({
                taxPrice: e.target.value * 1.0825
            })
        }
    }

    unitPriceInput = e => {
        let value = e.target.value
        if (value >= 0 && value <= 2000) {
            this.setState({ [e.target.name]: value })
            this.setState({
                taxPrice: value * 1.0825
            })
        }
    }

    onChangeTime(e) {
        this.setState({
            time: e.target.value
        })
    }

    onChangeSeller = selectedOption => {
        if (selectedOption) {
            this.setState({
                seller: selectedOption.value
            })
        }
    }

    onChangeSlump = selectedSlump => {
        if (selectedSlump) {
            this.setState({
                slump: selectedSlump.value
            })
        }
    }

    // jobPoor option
    onChangeJobPoor = selectedPoor => {
        if (selectedPoor) {
            this.setState({
                jobPoor: selectedPoor.label
            })
        }
    }

    // job type option
    onChangeJobType = selectedType => {
        if (selectedType) {
            this.setState({
                jobType: selectedType.label
            })
        }
    }

    // customer select
    onChangeCustomer = selectedCustomer => {
        if (selectedCustomer) {
            const values = selectedCustomer.value.split(',')
            const number = selectedCustomer.label.split(' ', 1)
            let customerName = selectedCustomer.label
            customerName = customerName.replace(`${number} `, '')
            this.getProjects(values[0])
            this.setState({
                customer: values[0],
                paymentType: values[1],
                customerName: customerName,
                customerNumber: number[0],
                flag: false
            })
        }
    }

    // change PSI
    onChangePsi = selectedPsi => {
        if (selectedPsi) {
            this.setState({
                psi: selectedPsi.label
            })
            this.setPsiContractPrice(selectedPsi)
        }
    }

    // payment type
    onChangePaymentType = selectedPayment => {
        if (selectedPayment) {
            this.setState({
                paymentType: selectedPayment.label,
                approved: true,
                flag: false
            })
        }
    }

    // foreman phone
    onChangeForemanPhone = e => {
        this.setState({
            foremanPhone: e
        })
    }

    // project option
    onChangeProject = selectedProject => {
        if (selectedProject) {
            this.setState({
                project: selectedProject.value,
            })

            this.getProjectInfo(selectedProject.value, this.state.projectList)
        }
    }


    onChangeSupervisorPhone = e => {
        this.setState({
            supervisorPhone: e
        })
    }

    onChangePMPhone = e => {
        this.setState({
            pmPhone: e
        })
    }

    validatePhone = (phone) => {
        const phoneno = /^\d{10}$/
        const expresion = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        if (phone.match(expresion)) {
            return true
        } else {
            alert("Invalid phone number")
            return false
        }
    }

    // show/hide search modal
    onShowModal = (e) => {
        this.setState({
            searchAddress: !this.state.searchAddress
        })
    }

    setSelected = selected => {
        this.setState({
            shipCity: selected.label,
            selectedOption: selected
        })
    }

    // address from modal
    handleCallback = (childData) => {
        this.setState({
            shipAddress: childData.address,
            destinationTime: childData.duration,
            distance: childData.distance,
            latitude: childData.destLat,
            longitude: childData.destLng,
            searchAddress: false,
            shipCity: childData.locality,
            readOnlyAddress: false
        })
        let cityFound = cityList.find((city) => city.label === childData.locality)
        if (typeof cityFound !== "undefined") {
            this.setSelected({ value: childData.locality.toLowerCase(), label: childData.locality })
        } else {
            alert(`The city ${childData.locality} is not defined in list, please report to IT team`)
        }
    }

    //Toggle error modal
    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal
        })
    }

    // new foreman modal
    onNewFormanNew = (nuevo) => {
        this.setState({
            foremanModal: false,
            jobForeman: nuevo.firstName + ' ' + nuevo.lastName,
            foremanPhone: nuevo.phone,
            foreman: nuevo.foreman
        })
    }

    // Foreman picker on change
    onChangeForeman = selectedForeman => {
        if (selectedForeman) {
            let option = selectedForeman.value.split(',')
            let foremanID = option[0]
            let foremanName = option[1]
            let foremanPhone = option[2]
            this.setState({
                foremanPhone: foremanPhone,
                jobForeman: foremanName,
                foreman: foremanID,
            })
        }
    }

    // show/hide foreman modal
    onShowNewForeman = (e) => {
        this.setState({
            foremanModal: !this.state.foremanModal
        })
    }

    onSubmit(e) {
        e.preventDefault()

        if (!(this.state.searchAddress || this.state.foremanModal)) {
            if (this.state.customer &&
                this.state.seller &&
                this.state.psi &&
                this.state.shipAddress &&
                this.state.shipCity &&
                this.state.jobForeman &&
                this.state.targetYD3 &&
                this.state.slump &&
                this.state.paymentType &&
                this.state.jobType &&
                this.state.jobPoor &&
                this.state.unitPrice &&
                this.state.plant) {
                    this.setState({
                        loading: true
                    })
                    this.createNewJob()
            } else {
                let missingFields = []
                if (!this.state.seller) missingFields.push('Seller')
                if (!this.state.customer) missingFields.push('Customer')
                if (!this.state.jobType) missingFields.push('Job Type')
                if (!this.state.jobPoor) missingFields.push('Job Pour')
                if (!this.state.psi) missingFields.push('Psi')
                if (!this.state.shipAddress) missingFields.push('Shipping Address')
                if (!this.state.shipCity) missingFields.push('Shipping City')
                if (!this.state.jobForeman) missingFields.push('Foreman')
                if (!this.state.paymentType) missingFields.push('Payment Type')
                if (!this.state.targetYD3) missingFields.push('Target YD3')
                if (!this.state.unitPrice) missingFields.push('Unit Price')
                if (!this.state.slump) missingFields.push('Slump')
                if (!this.state.plant) missingFields.push('Plant')

                let customeMessage = ''
                for (let i = 0; i < missingFields.length; i++) {
                    if (i !== missingFields.length - 1) {
                        customeMessage += missingFields[i] + ', '
                    } else {
                        customeMessage += missingFields[i]
                    }
                }

                this.setState({
                    messageModal: `Missing fields: ${customeMessage}`,
                    titleModal: 'Missing fields to fill'
                })
                this.toggleErrorModal()
                this.setState({
                    loading : false
                })
            }
        }
    }

    // POST
    createNewJob = async () => {
        const user = localStorage.getItem('userId')
        const job = {
            ...this.state,
            'firstShipementDate': this.state.date,
            'scheduledTime': this.state.time,
            'taxExemptJob': false,
            seller: this.state.seller,
            shipAddress: this.state.shipAddress,
            destinationTime: this.state.destinationTime,
            distance: this.state.distance,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            shipCity: this.state.shipCity,
            paymentType: this.state.paymentType,
            approved: this.state.approved,
            createdBy: user,
            updatedBy: user,
            inspectionTimeStamp: this.state.inspectionTimeStamp,
            isJob: false // IT IS A QUOTE
        }

        if(job.project === '') delete job.project

        try {
            const url = api + '/job'
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, job)
            this.setState({
                loading: false
            })
            this.props.history.push('/dailyappointment')
        } catch (err) {
            this.setState({
                loading : false
            })
            console.error(err.message)
            return null
        }
    }

    getJobId = async () => {
        const url = api + '/jobid'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                number: data.jobId,
                seller: localStorage.getItem('userId'),
                loading: false
            })

        } catch (err) {
            this.setState({loading : false})
            console.error(err.message)
            return null
        }
    }

    getProjects = async (projectID) => {
        const url = api + '/projectcustomer/' + projectID
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            this.setState({
                projectList: data
            })
        } catch (err) {
            console.error(err)
            return null
        }
    }

    getProjectInfo = (projectID, projectList) => {
        const projSelected = projectList.filter(proj => proj._id === projectID)
        this.setState({
            projectData: projSelected
        })
    }

    projectValid = (start, end) => {
        const todayDate = new Date()

        // FORMAT: MM/DD/YYYY
        start = `${start.substring(5, 7)}/${start.substring(8, 10)}/${start.substring(0, 4)}`
        end = `${end.substring(5, 7)}/${end.substring(8, 10)}/${end.substring(0, 4)}`

        let currentDate = new Date().toJSON().slice(0, 8)
        currentDate += (todayDate.getDate() > 9) ? `${todayDate.getDate()}` : `0${todayDate.getDate()}`

        const from = new Date(start)
        const to = new Date(end)
        const check = new Date(currentDate)

        return (check > from && check < to)
    }

    setPsiContractPrice = (selectedPsi) => {

        // Project Data is already selected
        if (this.state.projectData) {
            const psiUsed = this.state.projectData[0].productPrice.filter(proj => proj.psi === selectedPsi.label)

            // Psi selected is used in the Project
            if (psiUsed.length > 0) {

                if (this.state.projectData[0].start && this.state.projectData[0].end) {
                    const valid = this.projectValid(this.state.projectData[0].start, this.state.projectData[0].end)

                    if (valid) {
                        this.setState({
                            unitPrice: psiUsed[0].specialPrice,
                            taxPrice: psiUsed[0].specialPrice * 1.0825
                        })
                    } else {
                        this.setState({
                            unitPrice: psiUsed[0].listPrice,
                            taxPrice: psiUsed[0].listPrice * 1.0825
                        })
                    }
                }
            } else {
                this.setState({
                    unitPrice: 0,
                    taxPrice: 0
                })
            }
        }
    }

    componentDidMount() {
        this.getJobId()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.customer !== this.state.customer) {
            this.setState({
                flag: true
            })
        }
        if (prevState.paymentType !== this.state.paymentType) {
            this.setState({
                flag: true
            })
        }
    }

    JobForm = () => (
        <form className='form' onSubmit={this.onSubmit}>
            <div className='form-group'>
                <input
                    type='text'
                    className='form-control'
                    readOnly
                    value={`Job ID:  ${this.state.number}`}
                />
            </div>
            {
                localStorage.getItem('role') === 'Seller' &&
                <div className='form-group'>
                    <input
                        type='text'
                        readOnly
                        value={`Seller: ${localStorage.getItem('userName')}`}
                        className='form-control'
                    />
                </div>
            }
            <div className='form-group'>
                <label className='text-muted'>Customer</label>
                {/* <ClientPicker action={this.onChangeCustomer} /> */}
                <ClientPaymentPicker selectedOption={this.state.customer} action={this.onChangeCustomer} />
            </div>
            {
                localStorage.getItem('role') === 'Admin' &&
                <div className='form-group'>
                    <label className='text-muted'>Seller</label>
                    <SellerPicker action={this.onChangeSeller} />
                </div>

            }
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Type</label>
                    <JobTypePicker action={this.onChangeJobType} placeholder={'Job Type...'} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Job Pour</label>
                    <JobPoorPicker action={this.onChangeJobPoor} placeholder={'Job Pour...'} />
                </div>
            </div>
            <div className="form-group">
                <label className='text-muted'>Project (optional)</label>
                <ProjectPicker action={this.onChangeProject} projectList={this.state.projectList} />
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className='text-muted'>PSI</label>
                    <PsiPicker action={this.onChangePsi} />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Plant</label>
                    <PlantPicker action={this.onChangePlant} value={this.state.plant} />
                </div>
            </div>
            <div>
                <label className="text-muted">Ship to Address</label>
            </div>
            <div className="form-group row">
                <div className='col-sm-4'>
                    <button type='button' className='btn btn-primary' onClick={this.onShowModal} disabled={!this.state.plant} >
                        Search address <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                <div className='col-sm-8'>
                    <input
                        type="text"
                        name='shipAddress'
                        className="form-control"
                        value={this.state.shipAddress}
                        readOnly={this.state.readOnlyAddress}
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <br></br>
            <div className='form-row d-flex justify-content-end'>
                <div className='col-sm-8'>
                    <Select
                        options={cityList}
                        onChange={this.setSelected}
                        value={this.state.selectedOption}
                        placeholder="City..."
                    />
                </div>
            </div>
            <div>
                {
                    this.state.searchAddress &&
                    <Modal show={this.state.searchAddress} animation={false} onHide={this.onShowModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressGeolocation
                                search={this.state.searchAddress}
                                parentCallback={this.handleCallback}
                                single={false}
                                plant={this.state.plant}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
            <div className='form-group'>
                <label className='text-muted'>Time to destination</label>
                <input
                    type="text"
                    name='destinationTime'
                    className='form-control'
                    readOnly
                    value={this.state.destinationTime}
                />
            </div>
            {/* <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Foreman</label>
                    <input
                        type='text'
                        name='jobForeman'
                        className='form-control'
                        placeholder='Foreman name'
                        value={this.state.jobForeman}
                        required
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'> Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='foremanPhone'
                        placeholder="Foreman Phone"
                        required
                        value={this.state.foremanPhone}
                        onChange={this.onChangeForemanPhone}
                    />
                </div>
            </div> */}
            <div className="form-group row">
                <div className="col-sm-6">
                    <label className="text-muted">Foreman:&nbsp;</label><label>{this.state.jobForeman || ' Not defined yet'}</label>
                    <ForemanPicker action={this.onChangeForeman} selectedOption={this.state.jobForeman} />
                </div>
                <div className="col-sm-6">
                    <br />
                    <button
                        className='button btn-primary'
                        onClick={this.onShowNewForeman}
                    >New foreman +
                    </button>
                    <Modal show={this.state.foremanModal} animation={false} onHide={this.onShowNewForeman}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Foreman</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ForemanNew parentCallback={this.onNewFormanNew} />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor</label>
                    <input
                        type='text'
                        name='supervisorName'
                        className='form-control'
                        placeholder='Supervisor name'
                        value={this.state.supervisorName}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Supervisor Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='supervisorPhone'
                        placeholder="Phone number"
                        value={this.state.supervisorPhone}
                        onChange={this.onChangeSupervisorPhone}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Project Manager</label>
                    <input
                        type='text'
                        name='projectManager'
                        className='form-control'
                        placeholder='PM Name'
                        value={this.state.projectManager}
                        onChange={this.inputChangeHandler}
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Project Manager Phone</label>
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        name='pmPhone'
                        placeholder="PM Phone"
                        value={this.state.pmPhone}
                        onChange={this.onChangePMPhone}
                    />
                </div>
            </div>
            <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Tax Exempt Job</label>
                    <div className='radio'>
                        <label>
                            <input
                                type='radio'
                                name='taxExemptJob'
                                value='Yes'
                                onChange={this.inputChangeHandler}
                            />
                            Yes
                        </label>
                        {' '}
                        <label>
                            <input
                                type='radio'
                                name='taxExemptJob'
                                value='No'
                                checked
                                onChange={this.inputChangeHandler}
                            />
                        </label>
                        No
                    </div>
                </div>
                {
                    //localStorage.getItem('role') === 'Finance' &&
                    this.state.paymentType !== '' && this.state.flag === true &&
                    <div className='col-sm-6'>
                        <label className='text-muted'>Payment type</label>
                        <PaymentTypePicker action={this.onChangePaymentType} defaultValue={this.state.paymentType}/>
                    </div>
                }
            </div>
            <hr />
            <div className="form-group row">
                <div className='col-sm-4'>
                    <label className='text-muted'>Target YD3</label>
                    <input
                        type="number"
                        name='targetYD3'
                        className='form-control'
                        onChange={this.onChangeTargetYD3}
                        value={this.state.targetYD3}
                        placeholder='?'
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Balance</label>
                    <input
                        type="number"
                        name='balance'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                        value={this.state.balance}
                    />
                </div>
                <div className='col-sm-4'>
                    <label className='text-muted'>Job Duration</label>
                    <input
                        type="text"
                        name='jobDuration'
                        className='form-control'
                        onChange={this.inputChangeHandler}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <div>
                        <label className="text-muted">Job date/time</label>
                    </div>
                    <DatePicker
                        selected={this.state.scheduledDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        onChange={this.onChangeDate}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-sm-6'>
                    <label className="text-muted">Unit price</label>
                    <input
                        type="text"
                        name='unitPrice'
                        className="form-control"
                        onChange={this.unitPriceInput}
                        value={this.state.unitPrice}
                        placeholder="$"
                    />
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Price with Tax</label>
                    <input
                        type="text"
                        name='taxPrice'
                        readOnly
                        className='form-control'
                        value={toCurrency(this.state.taxPrice, 2)}
                    />
                </div>
            </div>
            <div className='form-group'>
                <label className='text-muted'>Comments</label>
                <input
                    type='text'
                    name='comments'
                    className='form-control'
                    onChange={this.inputChangeHandler}
                />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Slump</label>
                <SlumpPicker action={this.onChangeSlump} />
            </div>
            <div className="my-2">
                {
                    this.state.loading ?
                    <SpinnerButton type={'primary'}/>
                    :
                    <button className='btn btn-primary' type='submit'> Create </button>
                }
            </div>
        </form>
    )

    render() {
        return (
            <Layout
                title='Quote '
                description='Create New Quote'
                className="container col-md-8 offset-md-2"
            >
                {this.JobForm()}
                <Modal
                    show={this.state.showErrorModal}
                    animation={false}
                    onHide={this.toggleErrorModal}
                    size='lg'
                    centered
                    dialogClassName='modal-width'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.titleModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.messageModal}</p>
                    </Modal.Body>
                </Modal>
            </Layout>
        )
    }
}