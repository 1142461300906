import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { api } from '../utils/Api'

const TruckPicker = props => {
    const [select, setSelect] = useState("")
    const [trucks, setTrucks] = useState([])

    useEffect(() => {
        getTrucks()
    }, [trucks])

    const getTrucks = async () => {
        const url = api + '/trucklist'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setTrucks(data)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }


    const options = trucks.map((truck) => {
        return {
            value: truck._id,
            label: truck.number
        }
    })


    return (
        <div>
            <Select
                name="form-field-name"
                placeholder='Truck Number...'
                isClearable={true}
                isSearchable={true}
                options={options}
                onChange={props.action}
            />
        </div>
    )
}
export default TruckPicker