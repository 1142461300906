import React from 'react'
import Select from 'react-select'

const DiscountPicker = ({action, value}) => {
    const options = [
        { value: 'percentageAfter', label: 'Percentage (%) After Taxes' },
        { value: 'percentageBefore', label: 'Percentage (%) Before Taxes' },
        { value: 'amountAfter', label: 'Amount ($) After Taxes' },            
        { value: 'amountBefore', label: 'Amount ($) Before Taxes' },            
      ]
    return (
        <div>
            <Select
                value={value}
                options={options}
                placeholder='Select'
                onChange={action}
            />
        </div>
    )
}

export default DiscountPicker