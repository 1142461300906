import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Table, Statistic, Row, Col, Button } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner } from '../utils/CustomComponents'
import dayjs from 'dayjs'
import { CSVLink } from 'react-csv'
const { Text } = Typography

const columnsCSV = [
    { key: 'truck', label: 'Truck' },
    { key: 'driver', label: 'Driver' },
    { key: 'deliveryTicket', label: 'Delivery Ticket' },
    { key: 'orderCode', label: 'Order Code' },
    { key: 'yards', label: 'Yards' }
]

const EticketReportTripsPerTruck = () => { 
    
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [list, setList] = useState([])
    const [listCSV, setListCSV] = useState([])
    const refDownload = useRef()

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Trips #',
            dataIndex: 'count',
            key: 'count',
            render: (value) => {
                return <Statistic title="" value={value} precision={0} valueStyle={{fontSize: 14}} />
            }
        },
    ]

    const expandedRowRender = (row) => {
        const columns = [
            {
                title: 'Truck',
                dataIndex: 'truck',
                key: 'truck',
                render: (value) => {
                    return <Text>{value}</Text>
                }
            },
            {
                title: 'Driver',
                dataIndex: 'driver',
                key: 'driver',
                render: (value) => {
                    return <Text>{value}</Text>
                }
            },
            {
                title: 'Delivery Ticket',
                dataIndex: 'deliveryTicket',
                key: 'deliveryTicket',
                render: (value) => {
                    return <Text>{value}</Text>
                }
            },
            {
                title: 'Order Code',
                dataIndex: 'orderCode',
                key: 'orderCode',
                render: (value) => {
                    return <Text>{value}</Text>
                }
            },
            {
                title: 'Yards',
                dataIndex: 'yards',
                key: 'yards',
                render: (value) => {
                    return <Statistic title="" value={value} precision={2} valueStyle={{fontSize: 14}} />
                }
            },
        ]

        let trips = row.trips.map((item, index) => {
            item.key = index
            return item
        })

        return <Table columns={columns} dataSource={trips} pagination={false} />
    }

    useEffect(() => {
        getEticketList()
    }, [startDate, endDate])
    
    const getEticketList = async() => {
        setLoading(true)
        setListCSV([])

        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/eticket/list/tripspertruck?startDate=${start}&endDate=${end}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            let listRows = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    truck: item._id,
                    count: item.count,
                    trips: item.trips
                })
                item.trips.map((trip) => {
                    listRows.push(
                        {
                            driver: trip.driver,
                            truck: trip.truck,
                            yards: trip.yards,
                            deliveryTicket: trip.deliveryTicket,
                            orderCode: trip.orderCode
                        }
                    )
                })
            })
            list = list.sort((a,b) => a.truck - b.truck)
            setList(list)
            setListCSV(listRows)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            <Table 
            columns={columns}
            dataSource={list}
            expandable={{
                expandedRowRender,
            }}
            pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10,20,50,100],
                defaultPageSize: 10,
                showSizeChanger: true
            }}
            scroll={{
                x: true
            }}
            style={{
                minWidth: '100%',
            }}
        />
        </Flex>
    }

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const RenderDates = () => {
        return <CustomSearch 
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        onRangeChange={onRangeChange}
        />
    }

    const RenderDownloadReport = () => {
        return <div>
            <CSVLink 
            data={listCSV} 
            headers={columnsCSV} 
            filename={"57concrete-eticket-trips-per-truck.csv"}
            hidden
            ref={refDownload}
            >
                Download report
            </CSVLink>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type='primary' style={{width: '100%'}} onClick={e => refDownload.current.link.click()} loading={loading}>Download Report</Button>
                </Col>
            </Row>
        </div>
    }

    return (
        <Layout
            title='Total Trips Per Truck'
            description='Eticket Report'
            type='medium'
        >
            <Flex vertical gap={'large'}>
                <RenderDates />
                <RenderDownloadReport />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default EticketReportTripsPerTruck