import React, {useState, useEffect} from 'react'
import { toCurrency } from '../utils/Convert'

const InvoicePerTicket = ({ticket,setTicket}) => {
    const [fuelSurcharge, setFuelSurcharge] = useState(false)
    const [fuelRate, setFuelRate] = useState(25)
    const [shipping, setShipping] = useState(false)
    const [shippingFee, setShippingFee] = useState(0)
    const [color, setColor] = useState(false)
    const [colorTicket, setColorTicket] = useState(0)
    const [fiber, setFiber] = useState(false)
    const [fiberBags, setFiberBags] = useState(0)
    const [size, setSize] = useState(false)
    const [sizeBags, setSizeBags] = useState(0)
    const [miscelaneos, setMiscelaneos] = useState(false)
    const [miscelaneosFee, setMiscelaneosFee] = useState(0)
    const [miscelaneosReason, setMiscelaneosReason] = useState('')

    useEffect(() => {
        setFuelSurcharge(ticket.fuelSurcharge)
        setFuelRate(ticket.fuelRate)
        setShipping(ticket.shipping)
        setShippingFee(ticket.shippingFee)
        setColor(ticket.color)
        setColorTicket(ticket.colorTicket)
        setFiber(ticket.fiber)
        setFiberBags(ticket.fiberBags)
        setSize(ticket.size)
        setSizeBags(ticket.sizeBags)
        setMiscelaneos(ticket.miscelaneos)
        setMiscelaneosFee(ticket.miscelaneosFee)
        setMiscelaneosReason(ticket.miscelaneosReason)
    }, [])

    /**
     * 
     * @param {Object} object current object variable
     * @param {*} value value to assign into the object
     */
    const updateData = (object, value) => {
        setTicket((prevState) => ({
            ...prevState,
            [object]: value
        }))
    }

    /**
     * 
     * @param {Boolean} checked checkbox is checked
     * @param {Object} object current object variable
     * @param {*} emptyValue empty value to assign into the object
     * @param {*} value value to assign into the object
     */
    const onChangeCheckData = (checked, object, emptyValue, value) =>{
        if(!checked){
            updateData(object, emptyValue)
        }else{
            updateData(object, value)
        }
    }
    
    return (
    <div>
        <div className="form-group row" >
                <div className='col-sm-6'>
                    <input name="fuelSurcharge"
                        type="checkbox"
                        checked={fuelSurcharge}
                        onChange={(e) => {
                            setFuelSurcharge((prevValue) => !prevValue)
                            updateData('fuelSurcharge', e.target.checked)
                            onChangeCheckData(e.target.checked, 'fuelRate', parseFloat(0), parseFloat(fuelRate))
                        }}
                    />
                    <label>&nbsp;Standar Fuel Surcharge</label>
                </div>
                {
                    fuelSurcharge &&
                    <div className='col-sm-6'>
                        <label className="text-muted">Fee</label>
                        <input name="FuelRate"
                            type="number"
                            className="form-control"
                            value={fuelRate}
                            onChange={(e) => {
                                setFuelRate(e.target.value)
                                updateData('fuelRate', parseFloat(e.target.value))
                            }}
                        />
                    </div>
                }
                
            </div>
            <div className="form-group row" >
                <div className='col-sm-6'>
                    <input name="shipping"
                        type="checkbox"
                        checked={shipping}
                        onChange={(e) => {
                            setShipping((prevValue) => !prevValue)
                            updateData('shipping', e.target.checked)
                            onChangeCheckData(e.target.checked, 'shippingFee', parseFloat(0), parseFloat(shippingFee))
                        }}
                    />
                    <label>&nbsp;Shipping fee</label>
                </div>
                {
                    shipping &&
                    <div className='col-sm-6'>
                        <label className="text-muted">Fee</label>
                        <input name="shipping"
                            type="number"
                            className="form-control"
                            value={shippingFee}
                            onChange={(e) => {
                                setShippingFee(e.target.value)
                                updateData('shippingFee', parseFloat(e.target.value))
                            }}
                        />
                    </div>
                }
            </div>
            <div className="form-group row" >
                <div className='col-sm-6'>
                    <input name="color"
                        type="checkbox"
                        checked={color}
                        onChange={(e) => {
                            setColor((prevValue) => !prevValue)
                            updateData('color', e.target.checked)
                            onChangeCheckData(e.target.checked, 'colorTicket', parseFloat(0), parseFloat(colorTicket))
                            onChangeCheckData(e.target.checked, 'colorCharge', parseFloat(0), parseFloat(colorTicket * 50))
                        }}
                    />
                    <label>&nbsp;Color</label>
                </div>
            </div>
            {
                color &&
                <div className='form-group row' >
                    <div className='col-sm-6'>
                        <label className="text-muted">Ticket</label>
                        <input name="Color"
                            type="number"
                            className="form-control"
                            value={colorTicket}
                            onChange={(e) => {
                                setColorTicket(e.target.value)
                                updateData('colorTicket', e.target.value)
                                updateData('colorCharge', parseFloat(e.target.value * 50))
                            }}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Color charge</label>
                        <input name='colorTotal'
                            style={{ textAlign: "right" }}
                            className="form-control"
                            readOnly
                            value={toCurrency(colorTicket * 50,2)}
                        />
                    </div>
                </div>
            }
            <div className="form-group row" >
                <div className='col-sm-6'>
                    <input name="fiber"
                        type="checkbox"
                        checked={fiber}
                        onChange={(e) => {
                            setFiber((prevValue) => !prevValue)
                            updateData('fiber', e.target.checked)
                            onChangeCheckData(e.target.checked, 'fiberBags', parseFloat(0), parseFloat(fiberBags))
                            onChangeCheckData(e.target.checked, 'fiberCharge', parseFloat(0), parseFloat(fiberBags * 20))
                        }}
                    />
                    <label>&nbsp;Fiber</label>
                </div>
            </div>
            {
                fiber &&
                <div className='form-group row' >
                    <div className='col-sm-6'>
                        <label className="text-muted">Bags</label>
                        <input name="Fiber"
                            type="number"
                            className="form-control"
                            value={fiberBags}
                            onChange={(e) => {
                                setFiberBags(e.target.value)
                                updateData('fiberBags', e.target.value)
                                updateData('fiberCharge', parseFloat(e.target.value * 20))
                            }}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Fiber charge</label>
                        <input name='fiberTotal'
                            style={{ textAlign: "right" }}
                            className="form-control"
                            readOnly
                            value={toCurrency(fiberBags * 20,2)}
                        />
                    </div>
                </div>
            }
            <div className="form-group row" >
                <div className='col-sm-6'>
                    <input name="size"
                        type="checkbox"
                        checked={size}
                        onChange={(e) => {
                            setSize((prevValue) => !prevValue)
                            updateData('size', e.target.checked)
                            onChangeCheckData(e.target.checked, 'sizeBags', parseFloat(0), parseFloat(sizeBags))
                            onChangeCheckData(e.target.checked, 'sizeCharge', parseFloat(0), parseFloat(sizeBags * 20))
                        }}
                    />
                    <label>&nbsp;Size</label>
                </div>
            </div>
            {
                size &&
                <div className='form-group row' >
                    <div className='col-sm-6'>
                        <label className="text-muted">Bags</label>
                        <input name="Size"
                            type="number"
                            className="form-control"
                            value={sizeBags}
                            onChange={(e) => {
                                setSizeBags(e.target.value)
                                updateData('sizeBags', e.target.value)
                                updateData('sizeCharge', parseFloat(e.target.value * 20))
                            }}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Size charge</label>
                        <input name='sizeTotal'
                            style={{ textAlign: "right" }}
                            className="form-control"
                            readOnly
                            value={toCurrency(sizeBags*20,2)}
                        />
                    </div>
                </div>
            }
            <div className="form-group row" >
                <div className='col-sm-6'>
                    <input name="miscelaneos"
                        type="checkbox"
                        checked={miscelaneos}
                        onChange={(e) => {
                            setMiscelaneos((prevValue) => !prevValue)
                            updateData('miscelaneos', e.target.checked)
                            onChangeCheckData(e.target.checked, 'miscelaneosFee', parseFloat(0), parseFloat(miscelaneosFee))
                            onChangeCheckData(e.target.checked, 'miscelaneosReason', '', miscelaneosReason)
                        }}
                    />
                    <label>&nbsp;Miscelaneos</label>
                </div>
            </div>
            {
                miscelaneos &&
                <div className='form-group row' >
                    <div className='col-sm-6'>
                        <label className="text-muted">Fee</label>
                        <input name="Miscelaneos"
                            type="number"
                            className="form-control"
                            value={miscelaneosFee}
                            onChange={(e) => {
                                setMiscelaneosFee(e.target.value)
                                updateData('miscelaneosFee', parseFloat(e.target.value))
                            }}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <label className="text-muted">Miscelaneos Reason</label>
                        <input name="miscelaneosReason"
                            type="text"
                            className="form-control"
                            value={miscelaneosReason}
                            onChange={(e) => {
                                setMiscelaneosReason(e.target.value)
                                updateData('miscelaneosReason', e.target.value)
                            }}
                        />
                    </div>
                </div>
            }
    </div>
  )
}

export default InvoicePerTicket