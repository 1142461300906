import React from 'react'
import { Link } from 'react-router-dom'

const JobSummaryItem = ({job}) => {
    return (
        <tr key={job._id}>
            <td>{job.number} </td>
            <td>{job.date} </td>
            <td> {job.shippingAddress} </td>
            <td> {job.seller} </td>
            <td> {job.loadYD3} </td>
            <td> {job.targetYD3} </td>
            <td> {job.jobStatus} </td>
            <td>                
                {
                    job.jobStatus === 'Operations done (AR)' &&
                    <Link 
                        to={`/jobtoinvoice/${job._id}`} 
                        className='btn btn-primary'
                    >
                        Invoice
                    </Link>
                }
            </td>
        </tr>
    )
}

export default JobSummaryItem