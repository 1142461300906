export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCESS = 'REGISTER_SUCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
// Client
export const ADD_CLIENT = 'ADD_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENTS_ALL = 'GET_CLIENTS_ALL'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const CLIENT_ERROR = 'CLIENT_ERROR'
export const CLIENT_GROUP = 'CLIENT_GROUP'
// Job
export const ADD_JOB = 'ADD_JOB'
export const UPDATE_JOB = 'UPDATE_JOB'
export const GET_JOB = 'GET_JOB'
export const GET_JOBS = 'GET_JOBS'
export const DELETE_JOB = 'DELETE_JOB'
export const JOB_ERROR = 'JOB_ERROR'
export const GET_YARDS = 'GET_YARDS'
// Truck
export const ADD_TRUCK = 'ADD_TRUCK'
export const GET_TRUCK = 'GET_TRUCK'
export const GET_TRUCKS = 'GET_TRUCKS'
export const UPDATE_TRUCK = 'UPDATE_TRUCK'
export const DELETE_TRUCK = 'DELETE_TRUCK'
export const TRUCK_ERROR = 'TRUCK_ERROR'
// Referral
export const ADD_REFERRAL = 'ADD_REFERRAL'
export const GET_REFERRALS = 'GET_REFERRALS'
export const GET_REFERRAL = 'GET_REFERRAL'
export const DELETE_REFERRAL = 'DELETE_REFERRAL'
export const REFERRAL_ERROR = 'REFERRAL_ERROR'
// Plant
export const ADD_PLANT = 'ADD_PLANT'
export const GET_PLANTS = 'GET_PLANTS'
export const GET_PLANT = 'GET_PLANT'
export const UPDATE_PLANT = 'UPDATE_PLANT'
export const PLANT_ERROR = 'PLANT_ERROR'
// Load
export const ADD_LOAD = 'ADD_LOAD'
export const GET_LOAD = 'GET_LOAD'
export const GET_LOADS = 'GET_LOADS'
export const UPDATE_LOAD = 'UPDATE_LOAD'
export const DELETE_LOAD = 'DELETE_LOAD'
export const LOAD_ERROR = 'LOAD_ERRORS'
// Project
export const ADD_PROJECT = 'ADD_PROJECT'
export const GET_PROJECT = 'GET_PROJECT'
export const GET_PROJECTS = 'GET_PROJECTS'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const PROJECT_ERROR = 'PROJECT_ERROR'
// Aggregate
export const ADD_AGGREGATE = 'ADD_AGGREGATE'
export const GET_AGGREGATE = 'GET_AGGREGATE'
export const GET_AGGREGATES = 'GET_AGGREGATES'
export const UPDATE_AGGREGATE = 'UPDATE_AGGREGATE'
export const AGGREGATE_ERROR = 'AGGREGATE_ERROR'
// Driver
export const GET_DRIVERS = 'GET_DRIVERS'
export const DRIVER_ERROR = 'DRIVER_ERROR'
// Cog
export const ADD_COG = 'ADD_COG'
export const GET_COGS = 'GET_COGS'
export const GET_COG = 'GET_COG'
export const UPDATE_COG = 'UPDATE_COG'
export const DELETE_COG = 'DELETE_COG'
export const COG_ERROR = 'COG_ERROR'
// Emails
export const ADD_EMAIL = 'ADD_EMAIL'
export const GET_EMAILS = 'GET_EMAILS'
export const GET_EMAIL = 'GET_EMAIL'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const DELETE_EMAIL = 'DELETE_EMAIL'
export const EMAIL_ERROR = 'EMAIL_ERROR'
// Foreman
export const ADD_FOREMAN = 'ADD_FOREMAN'
export const GET_FOREMANS = 'GET_FOREMANS'
export const GET_FOREMAN = 'GET_FOREMAN'
export const UPDATE_FOREMAN = 'UPDATE_FOREMAN'
export const DELETE_FOREMAN = 'DELETE_FOREMAN'
export const FOREMAN_ERROR = 'FOREMAN_ERROR'
// Build Development
export const ADD_BUILD_DEVELOPMENT = 'ADD_BUILD_DEVELOPMENT'
export const GET_BUILD_DEVELOPMENTS = 'GET_BUILD_DEVELOPMENTS'
export const GET_BUILD_DEVELOPMENT = 'GET_BUILD_DEVELOPMENT'
export const UPDATE_BUILD_DEVELOPMENT = 'UPDATE_BUILD_DEVELOPMENT'
export const DELETE_BUILD_DEVELOPMENT = 'DELETE_BUILD_DEVELOPMENT'
export const BUILD_DEVELOPMENT_ERROR = 'BUILD_DEVELOPMENT_ERROR'
// Forecast
export const ADD_FORECAST = 'ADD_FORECAST'
export const GET_FORECASTS = 'GET_FORECASTS'
export const GET_FORECAST = 'GET_FORECAST'
export const UPDATE_FORECAST = 'UPDATE_FORECAST'
export const DELETE_FORECAST = 'DELETE_FORECAST'
export const FORECAST_ERROR = 'FORECAST_ERROR'
export const BATCH_DELETE = 'BATCH_DELETE'
// Target yard sold
export const GET_TARGETS = 'GET_TARGETS'
export const GET_YARDS_SOLD = 'GET_YARDS'
export const TARGET_ERROR = 'TARGET_ERROR'
// Invoice
export const ADD_INVOICE = 'ADD_INVOICE'
export const GET_INVOICE = 'GET_INVOICE'
export const GET_INVOICES = 'GET_INVOICES'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const INVOICE_ERROR = 'INVOICE_ERROR'
// loyalty plan
export const ADD_LOYALTY = 'ADD_LOYALTY'
export const GET_LOYALTY = 'GET_LOYALTY'
export const GET_LOYALTIES = 'GET_LOYALTIES'
export const UPDATE_LOYALTY = 'UPDATE_LOYALTY'
export const LOYALTY_ERROR = 'LOYALTY_ERROR'
export const DELETE_LOYALTY = 'DELETE_LOYALTY'
export const GET_YARD_FORMEN = 'GET_YARD_FOREMEN'
// payments
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const GET_PAYMENT = 'GET_PAYMENT'
export const DELETE_PAYMENT = 'DELETE_PAYMENT'
// user
export const GET_USER = 'GET_USER'
export const USER_ERROR = 'USER_ERROR'
// prospection
export const GET_PROSPECTIONS = 'GET_PROSPECTIONS'
export const PROSPECTION_ERROR = 'PROSPECTION_ERROR'
