import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import Spinner from '../layout/Spinner'
import InvoiceItem from './InvoiceItem'
import { toCurrency } from '../utils/Convert'
import { api } from '../utils/Api'
import DatePicker from 'react-datepicker'
import formatDate from '../utils/FormatDate'

const PeriodSales = (props) => {
    const [invoiceList, setInvoiceList] = useState([])
    const [loading, setLoading] = useState(true)
    const [dailySale, setDailySale] = useState(0)
    const [date, setDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    useEffect(() => {
        getInvoices()
    }, [date])


    const getInvoices = async () => {
        const tomorrow = new Date()
        tomorrow.setDate(date.getDate() + 1)
        const url = api + `/invoice?date=${formatDate(date)}&endDate=${formatDate(endDate)}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            console.log(data)
            const total = data.reduce((acc, venta) => acc + venta.invoiceTotal, 0)

            // const totalLoad = data.reduce((acc,elJob) => acc + elJob.loadYD3, 0)  
            console.log(`venta del dia: ${total} `)
            setInvoiceList(data)
            setDailySale(total)
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const Ventas = () => {
        if (invoiceList.length > 0) {
            return invoiceList.map((currentSale) => {
                return <InvoiceItem sale={currentSale} key={currentSale._id} />
            })
        } else {
            return (
                <tr>
                    <td colSpan="7">
                        No sales for selected date
                    </td>
                </tr>
            )
        }
    }

    const RenderInvoice = () => {
        return (
            <div>
                <h3>
                    Total Sale with taxes: {toCurrency(dailySale, 2)}
                </h3>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Invoice Number</th>
                            <th>Customer</th>
                            <th>PSI</th>
                            <th style={{ textAlign: "right" }}>Total Yards</th>
                            <th style={{ textAlign: "right" }}>Subtotal</th>
                            <th style={{ textAlign: "right" }}>Tax</th>
                            <th style={{ textAlign: "right" }}>Total</th>
                            <th>PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Ventas />
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Layout
            title='Period Sales'
            description='Sale List'
        >
            <div className='row'>
                <div className='col-sm-2'>
                    <label className="text-muted">Start Date</label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                    />
                    <br />
                </div>
                <div className='col-sm-2'>
                    <label className="text-muted">End Date</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                    />
                    <br />
                </div>
            </div>
            
            {
                loading ?
                    <RenderLoading />
                    : <RenderInvoice />
            }
        </Layout>
    )
}

export default PeriodSales