import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { getProjectsCustomer } from '../../actions/project'

const ProjectPicker = ({ customer, projectList, action, getProjectsCustomer, project: { projects, loading } }) => {
    const [options, setOptions] = useState([])
    useEffect(() => {
        if (projectList) {
            const opciones = projectList.map(proy => {
                return {
                    value: proy._id,
                    label: proy.name
                }
            })
            setOptions(opciones)
        }
        if (customer) {
            getProjectsCustomer(customer)
            console.log(projects)
            const opciones = projects.map(proy => {
                return {
                    value: proy._id,
                    label: proy.name
                }
            })
            console.log(opciones)
            setOptions(opciones)
        }
    }, [projectList])

    return (
        <Select
            placeholder='Projects...'
            onChange={action}
            isClearable={true}
            isSearchable={true}
            options={options}
        />
    )
}

const mapStateToProps = state => ({
    project: state.project
})

export default connect(mapStateToProps, { getProjectsCustomer })(ProjectPicker)