import {
    ADD_LOAD,
    GET_LOAD,
    GET_LOADS,
    LOAD_ERROR,
    UPDATE_LOAD,
    DELETE_LOAD
} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    loading: true,
    loads: []
}

function loadReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ADD_LOAD:
            return {
                ...state,
                loads: payload,
                loading: false
            }
        case GET_LOAD:
            return {
                ...state,
                loads: payload,
                loading: false
            }
        case GET_LOADS:
            const sumYards = payload.map(viaje => viaje.yards).reduce((acc, yd) => acc + yd, 0)
            return {
                ...state,
                loads: payload,
                sumYards,
                loading: false
            }
        case UPDATE_LOAD:
            return {
                ...state,
                loads: payload,
                loading: false
            }
        case DELETE_LOAD:
            return {
                ...state,
                loads: payload,
                loading: false
            }
        case LOAD_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}

export default loadReducer
