const cityList = [
    { value: `abbott`, label: `Abbott` },
    { value: `abernathy`, label: `Abernathy` },
    { value: `abilene`, label: `Abilene` },
    { value: `ackerly`, label: `Ackerly` },
    { value: `addison`, label: `Addison` },
    { value: `adrian`, label: `Adrian` },
    { value: `agua dulce`, label: `Agua Dulce` },
    { value: `alamo`, label: `Alamo` },
    { value: `alamo heights`, label: `Alamo Heights` },
    { value: `alba`, label: `Alba` },
    { value: `albany`, label: `Albany` },
    { value: `aledo`, label: `Aledo` },
    { value: `alice`, label: `Alice` },
    { value: `allen`, label: `Allen` },
    { value: `alma`, label: `Alma` },
    { value: `alpine`, label: `Alpine` },
    { value: `alto`, label: `Alto` },
    { value: `alton`, label: `Alton` },
    { value: `alvarado`, label: `Alvarado` },
    { value: `alvin`, label: `Alvin` },
    { value: `alvord`, label: `Alvord` },
    { value: `amarillo`, label: `Amarillo` },
    { value: `ames`, label: `Ames` },
    { value: `amherst`, label: `Amherst` },
    { value: `anahuac`, label: `Anahuac` },
    { value: `anderson`, label: `Anderson` },
    { value: `andrews`, label: `Andrews` },
    { value: `angleton`, label: `Angleton` },
    { value: `angus`, label: `Angus` },
    { value: `anna`, label: `Anna` },
    { value: `annetta`, label: `Annetta` },
    { value: `annetta north`, label: `Annetta North` },
    { value: `annetta south`, label: `Annetta South` },
    { value: `annona`, label: `Annona` },
    { value: `anson`, label: `Anson` },
    { value: `anthony`, label: `Anthony` },
    { value: `anton`, label: `Anton` },
    { value: `appleby`, label: `Appleby` },
    { value: `aquilla`, label: `Aquilla` },
    { value: `aransas pass`, label: `Aransas Pass` },
    { value: `archer city`, label: `Archer City` },
    { value: `arcola`, label: `Arcola` },
    { value: `argyle`, label: `Argyle` },
    { value: `arlington`, label: `Arlington` },
    { value: `arp`, label: `Arp` },
    { value: `asherton`, label: `Asherton` },
    { value: `aspermont`, label: `Aspermont` },
    { value: `athens`, label: `Athens` },
    { value: `atlanta`, label: `Atlanta` },
    { value: `aubrey`, label: `Aubrey` },
    { value: `aurora`, label: `Aurora` },
    { value: `austin`, label: `Austin` },
    { value: `austwell`, label: `Austwell` },
    { value: `avery`, label: `Avery` },
    { value: `avinger`, label: `Avinger` },
    { value: `azle`, label: `Azle` },
    { value: `bailey`, label: `Bailey` },
    { value: `bailey's prairie`, label: `Bailey's Prairie` },
    { value: `baird`, label: `Baird` },
    { value: `balch springs`, label: `Balch Springs` },
    { value: `balcones heights`, label: `Balcones Heights` },
    { value: `ballinger`, label: `Ballinger` },
    { value: `balmorhea`, label: `Balmorhea` },
    { value: `bandera`, label: `Bandera` },
    { value: `bangs`, label: `Bangs` },
    { value: `bardwell`, label: `Bardwell` },
    { value: `barry`, label: `Barry` },
    { value: `barstow`, label: `Barstow` },
    { value: `bartlett`, label: `Bartlett` },
    { value: `bartonville`, label: `Bartonville` },
    { value: `bastrop`, label: `Bastrop` },
    { value: `bay city`, label: `Bay City` },
    { value: `bayou vista`, label: `Bayou Vista` },
    { value: `bayside`, label: `Bayside` },
    { value: `baytown`, label: `Baytown` },
    { value: `bayview`, label: `Bayview` },
    { value: `beach city`, label: `Beach City` },
    { value: `bear creek`, label: `Bear Creek` },
    { value: `beasley`, label: `Beasley` },
    { value: `beaumont`, label: `Beaumont` },
    { value: `beckville`, label: `Beckville` },
    { value: `bedford`, label: `Bedford` },
    { value: `bedias`, label: `Bedias` },
    { value: `bee cave`, label: `Bee Cave` },
    { value: `beeville`, label: `Beeville` },
    { value: `bellaire`, label: `Bellaire` },
    { value: `bellevue`, label: `Bellevue` },
    { value: `bellmead`, label: `Bellmead` },
    { value: `bells`, label: `Bells` },
    { value: `bellville`, label: `Bellville` },
    { value: `belton`, label: `Belton` },
    { value: `benavides`, label: `Benavides` },
    { value: `benbrook`, label: `Benbrook` },
    { value: `benjamin`, label: `Benjamin` },
    { value: `berryville`, label: `Berryville` },
    { value: `bertram`, label: `Bertram` },
    { value: `beverly hills`, label: `Beverly Hills` },
    { value: `bevil oaks`, label: `Bevil Oaks` },
    { value: `big lake`, label: `Big Lake` },
    { value: `big sandy`, label: `Big Sandy` },
    { value: `big spring`, label: `Big Spring` },
    { value: `big wells`, label: `Big Wells` },
    { value: `bishop`, label: `Bishop` },
    { value: `bishop hills`, label: `Bishop Hills` },
    { value: `blackwell`, label: `Blackwell` },
    { value: `blanco`, label: `Blanco` },
    { value: `blanket`, label: `Blanket` },
    { value: `bloomburg`, label: `Bloomburg` },
    { value: `blooming grove`, label: `Blooming Grove` },
    { value: `blossom`, label: `Blossom` },
    { value: `blue mound`, label: `Blue Mound` },
    { value: `blue ridge`, label: `Blue Ridge` },
    { value: `blum`, label: `Blum` },
    { value: `boerne`, label: `Boerne` },
    { value: `bogata`, label: `Bogata` },
    { value: `bonham`, label: `Bonham` },
    { value: `bonney`, label: `Bonney` },
    { value: `booker`, label: `Booker` },
    { value: `borger`, label: `Borger` },
    { value: `bovina`, label: `Bovina` },
    { value: `bowie`, label: `Bowie` },
    { value: `boyd`, label: `Boyd` },
    { value: `brackettville`, label: `Brackettville` },
    { value: `brady`, label: `Brady` },
    { value: `brazoria`, label: `Brazoria` },
    { value: `brazos country`, label: `Brazos Country` },
    { value: `breckenridge`, label: `Breckenridge` },
    { value: `bremond`, label: `Bremond` },
    { value: `brenham`, label: `Brenham` },
    { value: `briarcliff`, label: `Briarcliff` },
    { value: `briaroaks`, label: `Briaroaks` },
    { value: `bridge city`, label: `Bridge City` },
    { value: `bridgeport`, label: `Bridgeport` },
    { value: `broaddus`, label: `Broaddus` },
    { value: `bronte`, label: `Bronte` },
    { value: `brookshire`, label: `Brookshire` },
    { value: `brookside village`, label: `Brookside Village` },
    { value: `browndell`, label: `Browndell` },
    { value: `brownfield`, label: `Brownfield` },
    { value: `brownsboro`, label: `Brownsboro` },
    { value: `brownsville`, label: `Brownsville` },
    { value: `brownwood`, label: `Brownwood` },
    { value: `bruceville-eddy`, label: `Bruceville-Eddy` },
    { value: `bryan`, label: `Bryan` },
    { value: `bryson`, label: `Bryson` },
    { value: `buckholts`, label: `Buckholts` },
    { value: `buda`, label: `Buda` },
    { value: `buffalo`, label: `Buffalo` },
    { value: `buffalo gap`, label: `Buffalo Gap` },
    { value: `buffalo springs`, label: `Buffalo Springs` },
    { value: `bullard`, label: `Bullard` },
    { value: `bulverde`, label: `Bulverde` },
    { value: `bunker hill village`, label: `Bunker Hill Village` },
    { value: `burkburnett`, label: `Burkburnett` },
    { value: `burke`, label: `Burke` },
    { value: `burleson`, label: `Burleson` },
    { value: `burnet`, label: `Burnet` },
    { value: `burton`, label: `Burton` },
    { value: `byers`, label: `Byers` },
    { value: `bynum`, label: `Bynum` },
    { value: `cactus`, label: `Cactus` },
    { value: `caddo mills`, label: `Caddo Mills` },
    { value: `caldwell`, label: `Caldwell` },
    { value: `callisburg`, label: `Callisburg` },
    { value: `calvert`, label: `Calvert` },
    { value: `cameron`, label: `Cameron` },
    { value: `campbell`, label: `Campbell` },
    { value: `camp wood`, label: `Camp Wood` },
    { value: `canadian`, label: `Canadian` },
    { value: `caney city`, label: `Caney City` },
    { value: `canton`, label: `Canton` },
    { value: `canyon`, label: `Canyon` },
    { value: `carbon`, label: `Carbon` },
    { value: `carl's corner`, label: `Carl's Corner` },
    { value: `carmine`, label: `Carmine` },
    { value: `carrizo springs`, label: `Carrizo Springs` },
    { value: `carrollton`, label: `Carrollton` },
    { value: `carthage`, label: `Carthage` },
    { value: `cashion community`, label: `Cashion Community` },
    { value: `castle hills`, label: `Castle Hills` },
    { value: `castroville`, label: `Castroville` },
    { value: `cedar hill`, label: `Cedar Hill` },
    { value: `cedar park`, label: `Cedar Park` },
    { value: `celeste`, label: `Celeste` },
    { value: `celina`, label: `Celina` },
    { value: `center`, label: `Center` },
    { value: `centerville`, label: `Centerville` },
    { value: `chandler`, label: `Chandler` },
    { value: `channing`, label: `Channing` },
    { value: `charlotte`, label: `Charlotte` },
    { value: `chester`, label: `Chester` },
    { value: `chico`, label: `Chico` },
    { value: `childress`, label: `Childress` },
    { value: `chillicothe`, label: `Chillicothe` },
    { value: `china`, label: `China` },
    { value: `china grove`, label: `China Grove` },
    { value: `chireno`, label: `Chireno` },
    { value: `christine`, label: `Christine` },
    { value: `cibolo`, label: `Cibolo` },
    { value: `cisco`, label: `Cisco` },
    { value: `clarendon`, label: `Clarendon` },
    { value: `clarksville`, label: `Clarksville` },
    { value: `clarksville city`, label: `Clarksville City` },
    { value: `claude`, label: `Claude` },
    { value: `clear lake shores`, label: `Clear Lake Shores` },
    { value: `cleburne`, label: `Cleburne` },
    { value: `cleveland`, label: `Cleveland` },
    { value: `clifton`, label: `Clifton` },
    { value: `clint`, label: `Clint` },
    { value: `clute`, label: `Clute` },
    { value: `clyde`, label: `Clyde` },
    { value: `coahoma`, label: `Coahoma` },
    { value: `cockrell hill`, label: `Cockrell Hill` },
    { value: `coffee city`, label: `Coffee City` },
    { value: `coldspring`, label: `Coldspring` },
    { value: `coleman`, label: `Coleman` },
    { value: `college station`, label: `College Station` },
    { value: `colleyville`, label: `Colleyville` },
    { value: `collinsville`, label: `Collinsville` },
    { value: `colmesneil`, label: `Colmesneil` },
    { value: `colorado city`, label: `Colorado City` },
    { value: `columbus`, label: `Columbus` },
    { value: `comanche`, label: `Comanche` },
    { value: `combes`, label: `Combes` },
    { value: `combine`, label: `Combine` },
    { value: `commerce`, label: `Commerce` },
    { value: `como`, label: `Como` },
    { value: `conroe`, label: `Conroe` },
    { value: `converse`, label: `Converse` },
    { value: `cool`, label: `Cool` },
    { value: `coolidge`, label: `Coolidge` },
    { value: `cooper`, label: `Cooper` },
    { value: `coppell`, label: `Coppell` },
    { value: `copperas cove`, label: `Copperas Cove` },
    { value: `copper canyon`, label: `Copper Canyon` },
    { value: `corinth`, label: `Corinth` },
    { value: `corpus christi`, label: `Corpus Christi` },
    { value: `corrigan`, label: `Corrigan` },
    { value: `corsicana`, label: `Corsicana` },
    { value: `cottonwood`, label: `Cottonwood` },
    { value: `cottonwood shores`, label: `Cottonwood Shores` },
    { value: `cotulla`, label: `Cotulla` },
    { value: `coupland`, label: `Coupland` },
    { value: `cove`, label: `Cove` },
    { value: `covington`, label: `Covington` },
    { value: `coyote flats`, label: `Coyote Flats` },
    { value: `crandall`, label: `Crandall` },
    { value: `crane`, label: `Crane` },
    { value: `cranfills gap`, label: `Cranfills Gap` },
    { value: `crawford`, label: `Crawford` },
    { value: `creedmoor`, label: `Creedmoor` },
    { value: `cresson`, label: `Cresson` },
    { value: `crockett`, label: `Crockett` },
    { value: `crosbyton`, label: `Crosbyton` },
    { value: `cross plains`, label: `Cross Plains` },
    { value: `cross roads`, label: `Cross Roads` },
    { value: `cross timber`, label: `Cross Timber` },
    { value: `crowell`, label: `Crowell` },
    { value: `crowley`, label: `Crowley` },
    { value: `crystal city`, label: `Crystal City` },
    { value: `cuero`, label: `Cuero` },
    { value: `cumby`, label: `Cumby` },
    { value: `cuney`, label: `Cuney` },
    { value: `cushing`, label: `Cushing` },
    { value: `cut and shoot`, label: `Cut and Shoot` },
    { value: `daingerfield`, label: `Daingerfield` },
    { value: `daisetta`, label: `Daisetta` },
    { value: `dalhart`, label: `Dalhart` },
    { value: `dallas`, label: `Dallas` },
    { value: `dalworthington gardens`, label: `Dalworthington Gardens` },
    { value: `danbury`, label: `Danbury` },
    { value: `darrouzett`, label: `Darrouzett` },
    { value: `dawson`, label: `Dawson` },
    { value: `dayton`, label: `Dayton` },
    { value: `dayton lakes`, label: `Dayton Lakes` },
    { value: `dean`, label: `Dean` },
    { value: `decatur`, label: `Decatur` },
    { value: `decordova`, label: `DeCordova` },
    { value: `deer park`, label: `Deer Park` },
    { value: `de kalb`, label: `De Kalb` },
    { value: `de leon`, label: `De Leon` },
    { value: `dell city`, label: `Dell City` },
    { value: `del rio`, label: `Del Rio` },
    { value: `denison`, label: `Denison` },
    { value: `denton`, label: `Denton` },
    { value: `denver city`, label: `Denver City` },
    { value: `deport`, label: `Deport` },
    { value: `desoto`, label: `DeSoto` },
    { value: `detroit`, label: `Detroit` },
    { value: `devers`, label: `Devers` },
    { value: `devine`, label: `Devine` },
    { value: `diboll`, label: `Diboll` },
    { value: `dickens`, label: `Dickens` },
    { value: `dickinson`, label: `Dickinson` },
    { value: `dilley`, label: `Dilley` },
    { value: `dimmitt`, label: `Dimmitt` },
    { value: `dish`, label: `DISH` },
    { value: `dodd city`, label: `Dodd City` },
    { value: `dodson`, label: `Dodson` },
    { value: `domino`, label: `Domino` },
    { value: `donna`, label: `Donna` },
    { value: `dorchester`, label: `Dorchester` },
    { value: `double oak`, label: `Double Oak` },
    { value: `douglassville`, label: `Douglassville` },
    { value: `draper`, label: `Draper` },
    { value: `dripping springs`, label: `Dripping Springs` },
    { value: `driscoll`, label: `Driscoll` },
    { value: `dublin`, label: `Dublin` },
    { value: `dumas`, label: `Dumas` },
    { value: `duncanville`, label: `Duncanville` },
    { value: `eagle lake`, label: `Eagle Lake` },
    { value: `eagle pass`, label: `Eagle Pass` },
    { value: `early`, label: `Early` },
    { value: `earth`, label: `Earth` },
    { value: `east bernard`, label: `East Bernard` },
    { value: `eastland`, label: `Eastland` },
    { value: `east mountain`, label: `East Mountain` },
    { value: `easton`, label: `Easton` },
    { value: `east tawakoni`, label: `East Tawakoni` },
    { value: `ector`, label: `Ector` },
    { value: `edcouch`, label: `Edcouch` },
    { value: `eden`, label: `Eden` },
    { value: `edgecliff village`, label: `Edgecliff Village` },
    { value: `edgewood`, label: `Edgewood` },
    { value: `edinburg`, label: `Edinburg` },
    { value: `edmonson`, label: `Edmonson` },
    { value: `edna`, label: `Edna` },
    { value: `edom`, label: `Edom` },
    { value: `el campo`, label: `El Campo` },
    { value: `el cenizo`, label: `El Cenizo` },
    { value: `eldorado`, label: `Eldorado` },
    { value: `electra`, label: `Electra` },
    { value: `elgin`, label: `Elgin` },
    { value: `elkhart`, label: `Elkhart` },
    { value: `el lago`, label: `El Lago` },
    { value: `ellinger`, label: `Ellinger` },
    { value: `elmendorf`, label: `Elmendorf` },
    { value: `el paso`, label: `El Paso` },
    { value: `elsa`, label: `Elsa` },
    { value: `emhouse`, label: `Emhouse` },
    { value: `emory`, label: `Emory` },
    { value: `enchanted oaks`, label: `Enchanted Oaks` },
    { value: `encinal`, label: `Encinal` },
    { value: `ennis`, label: `Ennis` },
    { value: `escobares`, label: `Escobares` },
    { value: `estelline`, label: `Estelline` },
    { value: `euless`, label: `Euless` },
    { value: `eureka`, label: `Eureka` },
    { value: `eustace`, label: `Eustace` },
    { value: `evant`, label: `Evant` },
    { value: `everman`, label: `Everman` },
    { value: `fairchilds`, label: `Fairchilds` },
    { value: `fairfield`, label: `Fairfield` },
    { value: `fair oaks ranch`, label: `Fair Oaks Ranch` },
    { value: `fairview`, label: `Fairview` },
    { value: `falfurrias`, label: `Falfurrias` },
    { value: `falls city`, label: `Falls City` },
    { value: `farmers branch`, label: `Farmers Branch` },
    { value: `farmersville`, label: `Farmersville` },
    { value: `farwell`, label: `Farwell` },
    { value: `fate`, label: `Fate` },
    { value: `fayetteville`, label: `Fayetteville` },
    { value: `ferris`, label: `Ferris` },
    { value: `flatonia`, label: `Flatonia` },
    { value: `florence`, label: `Florence` },
    { value: `floresville`, label: `Floresville` },
    { value: `flower mound`, label: `Flower Mound` },
    { value: `floydada`, label: `Floydada` },
    { value: `follett`, label: `Follett` },
    { value: `forest hill`, label: `Forest Hill` },
    { value: `forney`, label: `Forney` },
    { value: `forsan`, label: `Forsan` },
    { value: `fort stockton`, label: `Fort Stockton` },
    { value: `fort worth`, label: `Fort Worth` },
    { value: `franklin`, label: `Franklin` },
    { value: `frankston`, label: `Frankston` },
    { value: `fredericksburg`, label: `Fredericksburg` },
    { value: `freeport`, label: `Freeport` },
    { value: `freer`, label: `Freer` },
    { value: `friendswood`, label: `Friendswood` },
    { value: `friona`, label: `Friona` },
    { value: `frisco`, label: `Frisco` },
    { value: `fritch`, label: `Fritch` },
    { value: `frost`, label: `Frost` },
    { value: `fruitvale`, label: `Fruitvale` },
    { value: `fulshear`, label: `Fulshear` },
    { value: `fulton`, label: `Fulton` },
    { value: `gainesville`, label: `Gainesville` },
    { value: `galena park`, label: `Galena Park` },
    { value: `gallatin`, label: `Gallatin` },
    { value: `galveston`, label: `Galveston` },
    { value: `ganado`, label: `Ganado` },
    { value: `garden ridge`, label: `Garden Ridge` },
    { value: `garland`, label: `Garland` },
    { value: `garrett`, label: `Garrett` },
    { value: `garrison`, label: `Garrison` },
    { value: `gary city`, label: `Gary City` },
    { value: `gatesville`, label: `Gatesville` },
    { value: `georgetown`, label: `Georgetown` },
    { value: `george west`, label: `George West` },
    { value: `gholson`, label: `Gholson` },
    { value: `giddings`, label: `Giddings` },
    { value: `gilmer`, label: `Gilmer` },
    { value: `gladewater`, label: `Gladewater` },
    { value: `glenn heights`, label: `Glenn Heights` },
    { value: `glen rose`, label: `Glen Rose` },
    { value: `godley`, label: `Godley` },
    { value: `goldsmith`, label: `Goldsmith` },
    { value: `goldthwaite`, label: `Goldthwaite` },
    { value: `goliad`, label: `Goliad` },
    { value: `golinda`, label: `Golinda` },
    { value: `gonzales`, label: `Gonzales` },
    { value: `goodlow`, label: `Goodlow` },
    { value: `goodrich`, label: `Goodrich` },
    { value: `gordon`, label: `Gordon` },
    { value: `goree`, label: `Goree` },
    { value: `gorman`, label: `Gorman` },
    { value: `graford`, label: `Graford` },
    { value: `graham`, label: `Graham` },
    { value: `granbury`, label: `Granbury` },
    { value: `grandfalls`, label: `Grandfalls` },
    { value: `grand prairie`, label: `Grand Prairie` },
    { value: `grand saline`, label: `Grand Saline` },
    { value: `grandview`, label: `Grandview` },
    { value: `granger`, label: `Granger` },
    { value: `granite shoals`, label: `Granite Shoals` },
    { value: `granjeno`, label: `Granjeno` },
    { value: `grapeland`, label: `Grapeland` },
    { value: `grapevine`, label: `Grapevine` },
    { value: `grays prairie`, label: `Grays Prairie` },
    { value: `greenville`, label: `Greenville` },
    { value: `gregory`, label: `Gregory` },
    { value: `grey forest`, label: `Grey Forest` },
    { value: `groesbeck`, label: `Groesbeck` },
    { value: `groom`, label: `Groom` },
    { value: `groves`, label: `Groves` },
    { value: `groveton`, label: `Groveton` },
    { value: `gruver`, label: `Gruver` },
    { value: `gun barrel city`, label: `Gun Barrel City` },
    { value: `gunter`, label: `Gunter` },
    { value: `gustine`, label: `Gustine` },
    { value: `hackberry`, label: `Hackberry` },
    { value: `hale center`, label: `Hale Center` },
    { value: `hallettsville`, label: `Hallettsville` },
    { value: `hallsburg`, label: `Hallsburg` },
    { value: `hallsville`, label: `Hallsville` },
    { value: `haltom city`, label: `Haltom City` },
    { value: `hamilton`, label: `Hamilton` },
    { value: `hamlin`, label: `Hamlin` },
    { value: `happy`, label: `Happy` },
    { value: `hardin`, label: `Hardin` },
    { value: `harker heights`, label: `Harker Heights` },
    { value: `harlingen`, label: `Harlingen` },
    { value: `hart`, label: `Hart` },
    { value: `haskell`, label: `Haskell` },
    { value: `haslet`, label: `Haslet` },
    { value: `hawk cove`, label: `Hawk Cove` },
    { value: `hawkins`, label: `Hawkins` },
    { value: `hawley`, label: `Hawley` },
    { value: `hays`, label: `Hays` },
    { value: `hearne`, label: `Hearne` },
    { value: `heath`, label: `Heath` },
    { value: `hebron`, label: `Hebron` },
    { value: `hedley`, label: `Hedley` },
    { value: `hedwig village`, label: `Hedwig Village` },
    { value: `helotes`, label: `Helotes` },
    { value: `hemphill`, label: `Hemphill` },
    { value: `hempstead`, label: `Hempstead` },
    { value: `henderson`, label: `Henderson` },
    { value: `henrietta`, label: `Henrietta` },
    { value: `hereford`, label: `Hereford` },
    { value: `hewitt`, label: `Hewitt` },
    { value: `hickory creek`, label: `Hickory Creek` },
    { value: `hico`, label: `Hico` },
    { value: `hidalgo`, label: `Hidalgo` },
    { value: `hideaway`, label: `Hideaway` },
    { value: `higgins`, label: `Higgins` },
    { value: `highland haven`, label: `Highland Haven` },
    { value: `highland park`, label: `Highland Park` },
    { value: `highland village`, label: `Highland Village` },
    { value: `hill country village`, label: `Hill Country Village` },
    { value: `hillcrest`, label: `Hillcrest` },
    { value: `hillsboro`, label: `Hillsboro` },
    { value: `hilshire village`, label: `Hilshire Village` },
    { value: `hitchcock`, label: `Hitchcock` },
    { value: `holiday lakes`, label: `Holiday Lakes` },
    { value: `holland`, label: `Holland` },
    { value: `holliday`, label: `Holliday` },
    { value: `hollywood park`, label: `Hollywood Park` },
    { value: `hondo`, label: `Hondo` },
    { value: `honey grove`, label: `Honey Grove` },
    { value: `hooks`, label: `Hooks` },
    { value: `horizon city`, label: `Horizon City` },
    { value: `horseshoe bay`, label: `Horseshoe Bay` },
    { value: `houston`, label: `Houston` },
    { value: `howardwick`, label: `Howardwick` },
    { value: `howe`, label: `Howe` },
    { value: `hubbard`, label: `Hubbard` },
    { value: `hudson`, label: `Hudson` },
    { value: `hudson oaks`, label: `Hudson Oaks` },
    { value: `hughes springs`, label: `Hughes Springs` },
    { value: `humble`, label: `Humble` },
    { value: `hunters creek village`, label: `Hunters Creek Village` },
    { value: `huntington`, label: `Huntington` },
    { value: `huntsville`, label: `Huntsville` },
    { value: `hurst`, label: `Hurst` },
    { value: `hutchins`, label: `Hutchins` },
    { value: `hutto`, label: `Hutto` },
    { value: `huxley`, label: `Huxley` },
    { value: `idalou`, label: `Idalou` },
    { value: `impact`, label: `Impact` },
    { value: `indian lake`, label: `Indian Lake` },
    { value: `industry`, label: `Industry` },
    { value: `ingleside`, label: `Ingleside` },
    { value: `ingleside on the bay`, label: `Ingleside on the Bay` },
    { value: `ingram`, label: `Ingram` },
    { value: `iola`, label: `Iola` },
    { value: `iowa colony`, label: `Iowa Colony` },
    { value: `iowa park`, label: `Iowa Park` },
    { value: `iraan`, label: `Iraan` },
    { value: `iredell`, label: `Iredell` },
    { value: `irving`, label: `Irving` },
    { value: `italy`, label: `Italy` },
    { value: `itasca`, label: `Itasca` },
    { value: `ivanhoe`, label: `Ivanhoe` },
    { value: `jacinto city`, label: `Jacinto City` },
    { value: `jacksboro`, label: `Jacksboro` },
    { value: `jacksonville`, label: `Jacksonville` },
    { value: `jamaica beach`, label: `Jamaica Beach` },
    { value: `jarrell`, label: `Jarrell` },
    { value: `jasper`, label: `Jasper` },
    { value: `jayton`, label: `Jayton` },
    { value: `jefferson`, label: `Jefferson` },
    { value: `jersey village`, label: `Jersey Village` },
    { value: `jewett`, label: `Jewett` },
    { value: `joaquin`, label: `Joaquin` },
    { value: `johnson city`, label: `Johnson City` },
    { value: `jolly`, label: `Jolly` },
    { value: `jones creek`, label: `Jones Creek` },
    { value: `jonestown`, label: `Jonestown` },
    { value: `josephine`, label: `Josephine` },
    { value: `joshua`, label: `Joshua` },
    { value: `jourdanton`, label: `Jourdanton` },
    { value: `junction`, label: `Junction` },
    { value: `justin`, label: `Justin` },
    { value: `karnes city`, label: `Karnes City` },
    { value: `katy`, label: `Katy` },
    { value: `kaufman`, label: `Kaufman` },
    { value: `keene`, label: `Keene` },
    { value: `keller`, label: `Keller` },
    { value: `kemah`, label: `Kemah` },
    { value: `kemp`, label: `Kemp` },
    { value: `kempner`, label: `Kempner` },
    { value: `kendleton`, label: `Kendleton` },
    { value: `kenedy`, label: `Kenedy` },
    { value: `kenefick`, label: `Kenefick` },
    { value: `kennard`, label: `Kennard` },
    { value: `kennedale`, label: `Kennedale` },
    { value: `kerens`, label: `Kerens` },
    { value: `kermit`, label: `Kermit` },
    { value: `kerrville`, label: `Kerrville` },
    { value: `kilgore`, label: `Kilgore` },
    { value: `killeen`, label: `Killeen` },
    { value: `kingsbury`, label: `Kingsbury` },
    { value: `kingsville`, label: `Kingsville` },
    { value: `kirby`, label: `Kirby` },
    { value: `kirbyville`, label: `Kirbyville` },
    { value: `kirvin`, label: `Kirvin` },
    { value: `knollwood`, label: `Knollwood` },
    { value: `knox city`, label: `Knox City` },
    { value: `kosse`, label: `Kosse` },
    { value: `kountze`, label: `Kountze` },
    { value: `kress`, label: `Kress` },
    { value: `krugerville`, label: `Krugerville` },
    { value: `krum`, label: `Krum` },
    { value: `kurten`, label: `Kurten` },
    { value: `kyle`, label: `Kyle` },
    { value: `la blanca`, label: `La Blanca` },
    { value: `lacoste`, label: `LaCoste` },
    { value: `lacy-lakeview`, label: `Lacy-Lakeview` },
    { value: `ladonia`, label: `Ladonia` },
    { value: `la feria`, label: `La Feria` },
    { value: `lago vista`, label: `Lago Vista` },
    { value: `la grange`, label: `La Grange` },
    { value: `la grulla`, label: `La Grulla` },
    { value: `laguna vista`, label: `Laguna Vista` },
    { value: `la joya`, label: `La Joya` },
    { value: `lake bridgeport`, label: `Lake Bridgeport` },
    { value: `lake city`, label: `Lake City` },
    { value: `lake dallas`, label: `Lake Dallas` },
    { value: `lake jackson`, label: `Lake Jackson` },
    { value: `lakeport`, label: `Lakeport` },
    { value: `lakeside`, label: `Lakeside` },
    { value: `lakeside`, label: `Lakeside` },
    { value: `lakeside city`, label: `Lakeside City` },
    { value: `lake tanglewood`, label: `Lake Tanglewood` },
    { value: `lakeview`, label: `Lakeview` },
    { value: `lakeway`, label: `Lakeway` },
    { value: `lakewood village`, label: `Lakewood Village` },
    { value: `lake worth`, label: `Lake Worth` },
    { value: `la marque`, label: `La Marque` },
    { value: `lamesa`, label: `Lamesa` },
    { value: `lampasas`, label: `Lampasas` },
    { value: `lancaster`, label: `Lancaster` },
    { value: `la porte`, label: `La Porte` },
    { value: `laredo`, label: `Laredo` },
    { value: `latexo`, label: `Latexo` },
    { value: `la vernia`, label: `La Vernia` },
    { value: `la villa`, label: `La Villa` },
    { value: `lavon`, label: `Lavon` },
    { value: `la ward`, label: `La Ward` },
    { value: `lawn`, label: `Lawn` },
    { value: `league city`, label: `League City` },
    { value: `leakey`, label: `Leakey` },
    { value: `leander`, label: `Leander` },
    { value: `leary`, label: `Leary` },
    { value: `lefors`, label: `Lefors` },
    { value: `leona`, label: `Leona` },
    { value: `leonard`, label: `Leonard` },
    { value: `leon valley`, label: `Leon Valley` },
    { value: `leroy`, label: `Leroy` },
    { value: `levelland`, label: `Levelland` },
    { value: `lewisville`, label: `Lewisville` },
    { value: `lexington`, label: `Lexington` },
    { value: `liberty`, label: `Liberty` },
    { value: `liberty hill`, label: `Liberty Hill` },
    { value: `lincoln park`, label: `Lincoln Park` },
    { value: `lindale`, label: `Lindale` },
    { value: `linden`, label: `Linden` },
    { value: `lindsay`, label: `Lindsay` },
    { value: `lipan`, label: `Lipan` },
    { value: `little elm`, label: `Little Elm` },
    { value: `littlefield`, label: `Littlefield` },
    { value: `little river-academy`, label: `Little River-Academy` },
    { value: `live oak`, label: `Live Oak` },
    { value: `liverpool`, label: `Liverpool` },
    { value: `livingston`, label: `Livingston` },
    { value: `llano`, label: `Llano` },
    { value: `lockhart`, label: `Lockhart` },
    { value: `lockney`, label: `Lockney` },
    { value: `log cabin`, label: `Log Cabin` },
    { value: `lometa`, label: `Lometa` },
    { value: `lone oak`, label: `Lone Oak` },
    { value: `lone star`, label: `Lone Star` },
    { value: `longview`, label: `Longview` },
    { value: `loraine`, label: `Loraine` },
    { value: `lorena`, label: `Lorena` },
    { value: `lorenzo`, label: `Lorenzo` },
    { value: `los fresnos`, label: `Los Fresnos` },
    { value: `los indios`, label: `Los Indios` },
    { value: `los ybanez`, label: `Los Ybanez` },
    { value: `lott`, label: `Lott` },
    { value: `lovelady`, label: `Lovelady` },
    { value: `lowry crossing`, label: `Lowry Crossing` },
    { value: `lubbock`, label: `Lubbock` },
    { value: `lucas`, label: `Lucas` },
    { value: `lueders`, label: `Lueders` },
    { value: `lufkin`, label: `Lufkin` },
    { value: `luling`, label: `Luling` },
    { value: `lumberton`, label: `Lumberton` },
    { value: `lyford`, label: `Lyford` },
    { value: `lytle`, label: `Lytle` },
    { value: `mabank`, label: `Mabank` },
    { value: `mcallen`, label: `McAllen` },
    { value: `mccamey`, label: `McCamey` },
    { value: `mcgregor`, label: `McGregor` },
    { value: `mckinney`, label: `McKinney` },
    { value: `mclean`, label: `McLean` },
    { value: `mclendon-chisholm`, label: `McLendon-Chisholm` },
    { value: `madisonville`, label: `Madisonville` },
    { value: `magnolia`, label: `Magnolia` },
    { value: `malakoff`, label: `Malakoff` },
    { value: `malone`, label: `Malone` },
    { value: `manor`, label: `Manor` },
    { value: `mansfield`, label: `Mansfield` },
    { value: `manvel`, label: `Manvel` },
    { value: `marble falls`, label: `Marble Falls` },
    { value: `marfa`, label: `Marfa` },
    { value: `marietta`, label: `Marietta` },
    { value: `marion`, label: `Marion` },
    { value: `marlin`, label: `Marlin` },
    { value: `marquez`, label: `Marquez` },
    { value: `marshall`, label: `Marshall` },
    { value: `mart`, label: `Mart` },
    { value: `martindale`, label: `Martindale` },
    { value: `mason`, label: `Mason` },
    { value: `matador`, label: `Matador` },
    { value: `mathis`, label: `Mathis` },
    { value: `maud`, label: `Maud` },
    { value: `maypearl`, label: `Maypearl` },
    { value: `meadow`, label: `Meadow` },
    { value: `meadowlakes`, label: `Meadowlakes` },
    { value: `meadows place`, label: `Meadows Place` },
    { value: `megargel`, label: `Megargel` },
    { value: `melissa`, label: `Melissa` },
    { value: `melvin`, label: `Melvin` },
    { value: `memphis`, label: `Memphis` },
    { value: `menard`, label: `Menard` },
    { value: `mercedes`, label: `Mercedes` },
    { value: `meridian`, label: `Meridian` },
    { value: `merkel`, label: `Merkel` },
    { value: `mertens`, label: `Mertens` },
    { value: `mertzon`, label: `Mertzon` },
    { value: `mesquite`, label: `Mesquite` },
    { value: `mexia`, label: `Mexia` },
    { value: `miami`, label: `Miami` },
    { value: `midland`, label: `Midland` },
    { value: `midlothian`, label: `Midlothian` },
    { value: `midway`, label: `Midway` },
    { value: `milano`, label: `Milano` },
    { value: `mildred`, label: `Mildred` },
    { value: `miles`, label: `Miles` },
    { value: `milford`, label: `Milford` },
    { value: `miller's cove`, label: `Miller's Cove` },
    { value: `millican`, label: `Millican` },
    { value: `millsap`, label: `Millsap` },
    { value: `mineola`, label: `Mineola` },
    { value: `mineral wells`, label: `Mineral Wells` },
    { value: `mingus`, label: `Mingus` },
    { value: `mission`, label: `Mission` },
    { value: `missouri city`, label: `Missouri City` },
    { value: `mobeetie`, label: `Mobeetie` },
    { value: `mobile city`, label: `Mobile City` },
    { value: `monahans`, label: `Monahans` },
    { value: `mont belvieu`, label: `Mont Belvieu` },
    { value: `montgomery`, label: `Montgomery` },
    { value: `moody`, label: `Moody` },
    { value: `moore station`, label: `Moore Station` },
    { value: `moran`, label: `Moran` },
    { value: `morgan`, label: `Morgan` },
    { value: `morgan's point`, label: `Morgan's Point` },
    { value: `morgan's point resort`, label: `Morgan's Point Resort` },
    { value: `morton`, label: `Morton` },
    { value: `moulton`, label: `Moulton` },
    { value: `mountain city`, label: `Mountain City` },
    { value: `mount calm`, label: `Mount Calm` },
    { value: `mount enterprise`, label: `Mount Enterprise` },
    { value: `mount pleasant`, label: `Mount Pleasant` },
    { value: `mount vernon`, label: `Mount Vernon` },
    { value: `muenster`, label: `Muenster` },
    { value: `muleshoe`, label: `Muleshoe` },
    { value: `mullin`, label: `Mullin` },
    { value: `munday`, label: `Munday` },
    { value: `murchison`, label: `Murchison` },
    { value: `murphy`, label: `Murphy` },
    { value: `mustang`, label: `Mustang` },
    { value: `mustang ridge`, label: `Mustang Ridge` },
    { value: `nacogdoches`, label: `Nacogdoches` },
    { value: `naples`, label: `Naples` },
    { value: `nash`, label: `Nash` },
    { value: `nassau bay`, label: `Nassau Bay` },
    { value: `natalia`, label: `Natalia` },
    { value: `navarro`, label: `Navarro` },
    { value: `navasota`, label: `Navasota` },
    { value: `nazareth`, label: `Nazareth` },
    { value: `nederland`, label: `Nederland` },
    { value: `needville`, label: `Needville` },
    { value: `nevada`, label: `Nevada` },
    { value: `newark`, label: `Newark` },
    { value: `new berlin`, label: `New Berlin` },
    { value: `new boston`, label: `New Boston` },
    { value: `new braunfels`, label: `New Braunfels` },
    { value: `newcastle`, label: `Newcastle` },
    { value: `new chapel hill`, label: `New Chapel Hill` },
    { value: `new deal`, label: `New Deal` },
    { value: `new fairview`, label: `New Fairview` },
    { value: `new home`, label: `New Home` },
    { value: `new hope`, label: `New Hope` },
    { value: `new london`, label: `New London` },
    { value: `new summerfield`, label: `New Summerfield` },
    { value: `newton`, label: `Newton` },
    { value: `new waverly`, label: `New Waverly` },
    { value: `neylandville`, label: `Neylandville` },
    { value: `niederwald`, label: `Niederwald` },
    { value: `nixon`, label: `Nixon` },
    { value: `nocona`, label: `Nocona` },
    { value: `nolanville`, label: `Nolanville` },
    { value: `nome`, label: `Nome` },
    { value: `noonday`, label: `Noonday` },
    { value: `nordheim`, label: `Nordheim` },
    { value: `normangee`, label: `Normangee` },
    { value: `north cleveland`, label: `North Cleveland` },
    { value: `northlake`, label: `Northlake` },
    { value: `north richland hills`, label: `North Richland Hills` },
    { value: `novice`, label: `Novice` },
    { value: `oak grove`, label: `Oak Grove` },
    { value: `oak leaf`, label: `Oak Leaf` },
    { value: `oak point`, label: `Oak Point` },
    { value: `oak ridge`, label: `Oak Ridge` },
    { value: `oak ridge`, label: `Oak Ridge` },
    { value: `oak ridge north`, label: `Oak Ridge North` },
    { value: `oak valley`, label: `Oak Valley` },
    { value: `oakwood`, label: `Oakwood` },
    { value: `o'brien`, label: `O'Brien` },
    { value: `odem`, label: `Odem` },
    { value: `odessa`, label: `Odessa` },
    { value: `o'donnell`, label: `O'Donnell` },
    { value: `oglesby`, label: `Oglesby` },
    { value: `old river-winfree`, label: `Old River-Winfree` },
    { value: `olmos park`, label: `Olmos Park` },
    { value: `olney`, label: `Olney` },
    { value: `olton`, label: `Olton` },
    { value: `omaha`, label: `Omaha` },
    { value: `olmito`, label: `Olmito` },
    { value: `onalaska`, label: `Onalaska` },
    { value: `opdyke west`, label: `Opdyke West` },
    { value: `orange`, label: `Orange` },
    { value: `orange grove`, label: `Orange Grove` },
    { value: `orchard`, label: `Orchard` },
    { value: `ore city`, label: `Ore City` },
    { value: `overton`, label: `Overton` },
    { value: `ovilla`, label: `Ovilla` },
    { value: `oyster creek`, label: `Oyster Creek` },
    { value: `paducah`, label: `Paducah` },
    { value: `paint rock`, label: `Paint Rock` },
    { value: `palacios`, label: `Palacios` },
    { value: `palestine`, label: `Palestine` },
    { value: `palisades`, label: `Palisades` },
    { value: `palmer`, label: `Palmer` },
    { value: `palmhurst`, label: `Palmhurst` },
    { value: `palm valley`, label: `Palm Valley` },
    { value: `palmview`, label: `Palmview` },
    { value: `pampa`, label: `Pampa` },
    { value: `panhandle`, label: `Panhandle` },
    { value: `panorama village`, label: `Panorama Village` },
    { value: `pantego`, label: `Pantego` },
    { value: `paradise`, label: `Paradise` },
    { value: `paris`, label: `Paris` },
    { value: `parker`, label: `Parker` },
    { value: `pasadena`, label: `Pasadena` },
    { value: `pattison`, label: `Pattison` },
    { value: `patton village`, label: `Patton Village` },
    { value: `payne springs`, label: `Payne Springs` },
    { value: `pearland`, label: `Pearland` },
    { value: `pearsall`, label: `Pearsall` },
    { value: `pecan gap`, label: `Pecan Gap` },
    { value: `pecan hill`, label: `Pecan Hill` },
    { value: `pecos`, label: `Pecos` },
    { value: `pelican bay`, label: `Pelican Bay` },
    { value: `penelope`, label: `Penelope` },
    { value: `peñitas`, label: `Peñitas` },
    { value: `penitas`, label: `Penitas` },
    { value: `perryton`, label: `Perryton` },
    { value: `petersburg`, label: `Petersburg` },
    { value: `petrolia`, label: `Petrolia` },
    { value: `petronila`, label: `Petronila` },
    { value: `pflugerville`, label: `Pflugerville` },
    { value: `pharr`, label: `Pharr` },
    { value: `pilot point`, label: `Pilot Point` },
    { value: `pine forest`, label: `Pine Forest` },
    { value: `pinehurst`, label: `Pinehurst` },
    { value: `pine island`, label: `Pine Island` },
    { value: `pineland`, label: `Pineland` },
    { value: `piney point village`, label: `Piney Point Village` },
    { value: `pittsburg`, label: `Pittsburg` },
    { value: `plains`, label: `Plains` },
    { value: `plainview`, label: `Plainview` },
    { value: `plano`, label: `Plano` },
    { value: `plantersville`, label: `Plantersville` },
    { value: `pleak`, label: `Pleak` },
    { value: `pleasanton`, label: `Pleasanton` },
    { value: `pleasant valley`, label: `Pleasant Valley` },
    { value: `plum grove`, label: `Plum Grove` },
    { value: `point`, label: `Point` },
    { value: `point blank`, label: `Point Blank` },
    { value: `point comfort`, label: `Point Comfort` },
    { value: `point venture`, label: `Point Venture` },
    { value: `ponder`, label: `Ponder` },
    { value: `port aransas`, label: `Port Aransas` },
    { value: `port arthur`, label: `Port Arthur` },
    { value: `port isabel`, label: `Port Isabel` },
    { value: `portland`, label: `Portland` },
    { value: `port lavaca`, label: `Port Lavaca` },
    { value: `port neches`, label: `Port Neches` },
    { value: `post`, label: `Post` },
    { value: `post oak bend city`, label: `Post Oak Bend City` },
    { value: `poteet`, label: `Poteet` },
    { value: `poth`, label: `Poth` },
    { value: `pottsboro`, label: `Pottsboro` },
    { value: `powell`, label: `Powell` },
    { value: `poynor`, label: `Poynor` },
    { value: `prairie view`, label: `Prairie View` },
    { value: `premont`, label: `Premont` },
    { value: `presidio`, label: `Presidio` },
    { value: `primera`, label: `Primera` },
    { value: `princeton`, label: `Princeton` },
    { value: `progreso`, label: `Progreso` },
    { value: `progreso lakes`, label: `Progreso Lakes` },
    { value: `prosper`, label: `Prosper` },
    { value: `providence village`, label: `Providence Village` },
    { value: `putnam`, label: `Putnam` },
    { value: `pyote`, label: `Pyote` },
    { value: `quanah`, label: `Quanah` },
    { value: `queen city`, label: `Queen City` },
    { value: `quinlan`, label: `Quinlan` },
    { value: `quintana`, label: `Quintana` },
    { value: `quitaque`, label: `Quitaque` },
    { value: `quitman`, label: `Quitman` },
    { value: `ralls`, label: `Ralls` },
    { value: `rancho viejo`, label: `Rancho Viejo` },
    { value: `ranger`, label: `Ranger` },
    { value: `rankin`, label: `Rankin` },
    { value: `ransom canyon`, label: `Ransom Canyon` },
    { value: `ravenna`, label: `Ravenna` },
    { value: `raymondville`, label: `Raymondville` },
    { value: `red lick`, label: `Red Lick` },
    { value: `red oak`, label: `Red Oak` },
    { value: `redwater`, label: `Redwater` },
    { value: `refugio`, label: `Refugio` },
    { value: `reklaw`, label: `Reklaw` },
    { value: `reno`, label: `Reno` },
    { value: `reno`, label: `Reno` },
    { value: `retreat`, label: `Retreat` },
    { value: `rhome`, label: `Rhome` },
    { value: `rice`, label: `Rice` },
    { value: `richardson`, label: `Richardson` },
    { value: `richland`, label: `Richland` },
    { value: `richland hills`, label: `Richland Hills` },
    { value: `richland springs`, label: `Richland Springs` },
    { value: `richmond`, label: `Richmond` },
    { value: `richwood`, label: `Richwood` },
    { value: `riesel`, label: `Riesel` },
    { value: `rio bravo`, label: `Rio Bravo` },
    { value: `rio grande city`, label: `Rio Grande City` },
    { value: `rio hondo`, label: `Rio Hondo` },
    { value: `rio vista`, label: `Rio Vista` },
    { value: `rising star`, label: `Rising Star` },
    { value: `river oaks`, label: `River Oaks` },
    { value: `riverside`, label: `Riverside` },
    { value: `roanoke`, label: `Roanoke` },
    { value: `roaring springs`, label: `Roaring Springs` },
    { value: `robert lee`, label: `Robert Lee` },
    { value: `robinson`, label: `Robinson` },
    { value: `robstown`, label: `Robstown` },
    { value: `roby`, label: `Roby` },
    { value: `rochester`, label: `Rochester` },
    { value: `rockdale`, label: `Rockdale` },
    { value: `rockport`, label: `Rockport` },
    { value: `rocksprings`, label: `Rocksprings` },
    { value: `rockwall`, label: `Rockwall` },
    { value: `rocky mound`, label: `Rocky Mound` },
    { value: `rogers`, label: `Rogers` },
    { value: `rollingwood`, label: `Rollingwood` },
    { value: `roma`, label: `Roma` },
    { value: `roman forest`, label: `Roman Forest` },
    { value: `ropesville`, label: `Ropesville` },
    { value: `roscoe`, label: `Roscoe` },
    { value: `rosebud`, label: `Rosebud` },
    { value: `rose city`, label: `Rose City` },
    { value: `rose hill acres`, label: `Rose Hill Acres` },
    { value: `rosenberg`, label: `Rosenberg` },
    { value: `ross`, label: `Ross` },
    { value: `rosser`, label: `Rosser` },
    { value: `rotan`, label: `Rotan` },
    { value: `round mountain`, label: `Round Mountain` },
    { value: `round rock`, label: `Round Rock` },
    { value: `round top`, label: `Round Top` },
    { value: `rowlett`, label: `Rowlett` },
    { value: `roxton`, label: `Roxton` },
    { value: `royse city`, label: `Royse City` },
    { value: `rule`, label: `Rule` },
    { value: `runaway bay`, label: `Runaway Bay` },
    { value: `runge`, label: `Runge` },
    { value: `rusk`, label: `Rusk` },
    { value: `sabinal`, label: `Sabinal` },
    { value: `sachse`, label: `Sachse` },
    { value: `sadler`, label: `Sadler` },
    { value: `saginaw`, label: `Saginaw` },
    { value: `st. hedwig`, label: `St. Hedwig` },
    { value: `st. jo`, label: `St. Jo` },
    { value: `st. paul`, label: `St. Paul` },
    { value: `salado`, label: `Salado` },
    { value: `san angelo`, label: `San Angelo` },
    { value: `san antonio`, label: `San Antonio` },
    { value: `san augustine`, label: `San Augustine` },
    { value: `san benito`, label: `San Benito` },
    { value: `sanctuary`, label: `Sanctuary` },
    { value: `san diego`, label: `San Diego` },
    { value: `sandy oaks`, label: `Sandy Oaks` },
    { value: `sandy point`, label: `Sandy Point` },
    { value: `san elizario`, label: `San Elizario` },
    { value: `san felipe`, label: `San Felipe` },
    { value: `sanford`, label: `Sanford` },
    { value: `sanger`, label: `Sanger` },
    { value: `san juan`, label: `San Juan` },
    { value: `san leanna`, label: `San Leanna` },
    { value: `san marcos`, label: `San Marcos` },
    { value: `san patricio`, label: `San Patricio` },
    { value: `san perlita`, label: `San Perlita` },
    { value: `san saba`, label: `San Saba` },
    { value: `sansom park`, label: `Sansom Park` },
    { value: `santa anna`, label: `Santa Anna` },
    { value: `santa clara`, label: `Santa Clara` },
    { value: `santa fe`, label: `Santa Fe` },
    { value: `santa rosa`, label: `Santa Rosa` },
    { value: `savoy`, label: `Savoy` },
    { value: `schertz`, label: `Schertz` },
    { value: `schulenburg`, label: `Schulenburg` },
    { value: `scotland`, label: `Scotland` },
    { value: `scottsville`, label: `Scottsville` },
    { value: `scurry`, label: `Scurry` },
    { value: `seabrook`, label: `Seabrook` },
    { value: `seadrift`, label: `Seadrift` },
    { value: `seagoville`, label: `Seagoville` },
    { value: `seagraves`, label: `Seagraves` },
    { value: `sealy`, label: `Sealy` },
    { value: `seguin`, label: `Seguin` },
    { value: `selma`, label: `Selma` },
    { value: `seminole`, label: `Seminole` },
    { value: `seven oaks`, label: `Seven Oaks` },
    { value: `seven points`, label: `Seven Points` },
    { value: `seymour`, label: `Seymour` },
    { value: `shady shores`, label: `Shady Shores` },
    { value: `shallowater`, label: `Shallowater` },
    { value: `shamrock`, label: `Shamrock` },
    { value: `shavano park`, label: `Shavano Park` },
    { value: `shenandoah`, label: `Shenandoah` },
    { value: `shepherd`, label: `Shepherd` },
    { value: `sherman`, label: `Sherman` },
    { value: `shiner`, label: `Shiner` },
    { value: `shoreacres`, label: `Shoreacres` },
    { value: `silsbee`, label: `Silsbee` },
    { value: `silverton`, label: `Silverton` },
    { value: `simonton`, label: `Simonton` },
    { value: `sinton`, label: `Sinton` },
    { value: `skellytown`, label: `Skellytown` },
    { value: `slaton`, label: `Slaton` },
    { value: `smiley`, label: `Smiley` },
    { value: `smithville`, label: `Smithville` },
    { value: `smyer`, label: `Smyer` },
    { value: `snook`, label: `Snook` },
    { value: `snyder`, label: `Snyder` },
    { value: `socorro`, label: `Socorro` },
    { value: `somerset`, label: `Somerset` },
    { value: `somerville`, label: `Somerville` },
    { value: `sonora`, label: `Sonora` },
    { value: `sour lake`, label: `Sour Lake` },
    { value: `south houston`, label: `South Houston` },
    { value: `southlake`, label: `Southlake` },
    { value: `southmayd`, label: `Southmayd` },
    { value: `south mountain`, label: `South Mountain` },
    { value: `south padre island`, label: `South Padre Island` },
    { value: `southside place`, label: `Southside Place` },
    { value: `spearman`, label: `Spearman` },
    { value: `splendora`, label: `Splendora` },
    { value: `spofford`, label: `Spofford` },
    { value: `spring branch`, label: `Spring Branch` },
    { value: `springlake`, label: `Springlake` },
    { value: `springtown`, label: `Springtown` },
    { value: `spring valley village`, label: `Spring Valley Village` },
    { value: `spur`, label: `Spur` },
    { value: `stafford`, label: `Stafford` },
    { value: `stagecoach`, label: `Stagecoach` },
    { value: `stamford`, label: `Stamford` },
    { value: `stanton`, label: `Stanton` },
    { value: `staples`, label: `Staples` },
    { value: `star harbor`, label: `Star Harbor` },
    { value: `stephenville`, label: `Stephenville` },
    { value: `sterling city`, label: `Sterling City` },
    { value: `stinnett`, label: `Stinnett` },
    { value: `stockdale`, label: `Stockdale` },
    { value: `stockton bend`, label: `Stockton Bend` },
    { value: `stratford`, label: `Stratford` },
    { value: `strawn`, label: `Strawn` },
    { value: `streetman`, label: `Streetman` },
    { value: `sudan`, label: `Sudan` },
    { value: `sugar land`, label: `Sugar Land` },
    { value: `sullivan city`, label: `Sullivan City` },
    { value: `sulphur springs`, label: `Sulphur Springs` },
    { value: `sundown`, label: `Sundown` },
    { value: `sunnyvale`, label: `Sunnyvale` },
    { value: `sunray`, label: `Sunray` },
    { value: `sunrise beach village`, label: `Sunrise Beach Village` },
    { value: `sunset valley`, label: `Sunset Valley` },
    { value: `sun valley`, label: `Sun Valley` },
    { value: `surfside beach`, label: `Surfside Beach` },
    { value: `sweeny`, label: `Sweeny` },
    { value: `sweetwater`, label: `Sweetwater` },
    { value: `taft`, label: `Taft` },
    { value: `tahoka`, label: `Tahoka` },
    { value: `talco`, label: `Talco` },
    { value: `talty`, label: `Talty` },
    { value: `tatum`, label: `Tatum` },
    { value: `taylor`, label: `Taylor` },
    { value: `taylor lake village`, label: `Taylor Lake Village` },
    { value: `taylor landing`, label: `Taylor Landing` },
    { value: `teague`, label: `Teague` },
    { value: `tehuacana`, label: `Tehuacana` },
    { value: `temple`, label: `Temple` },
    { value: `tenaha`, label: `Tenaha` },
    { value: `terrell`, label: `Terrell` },
    { value: `terrell hills`, label: `Terrell Hills` },
    { value: `texarkana`, label: `Texarkana` },
    { value: `texas city`, label: `Texas City` },
    { value: `texhoma`, label: `Texhoma` },
    { value: `texline`, label: `Texline` },
    { value: `the colony`, label: `The Colony` },
    { value: `the hills`, label: `The Hills` },
    { value: `thompsons`, label: `Thompsons` },
    { value: `thorndale`, label: `Thorndale` },
    { value: `thornton`, label: `Thornton` },
    { value: `thorntonville`, label: `Thorntonville` },
    { value: `thrall`, label: `Thrall` },
    { value: `three rivers`, label: `Three Rivers` },
    { value: `throckmorton`, label: `Throckmorton` },
    { value: `tiki island`, label: `Tiki Island` },
    { value: `timbercreek canyon`, label: `Timbercreek Canyon` },
    { value: `timpson`, label: `Timpson` },
    { value: `tioga`, label: `Tioga` },
    { value: `tira`, label: `Tira` },
    { value: `toco`, label: `Toco` },
    { value: `todd mission`, label: `Todd Mission` },
    { value: `tolar`, label: `Tolar` },
    { value: `tomball`, label: `Tomball` },
    { value: `tom bean`, label: `Tom Bean` },
    { value: `tool`, label: `Tool` },
    { value: `toyah`, label: `Toyah` },
    { value: `trent`, label: `Trent` },
    { value: `trenton`, label: `Trenton` },
    { value: `trinidad`, label: `Trinidad` },
    { value: `trinity`, label: `Trinity` },
    { value: `trophy club`, label: `Trophy Club` },
    { value: `troup`, label: `Troup` },
    { value: `troy`, label: `Troy` },
    { value: `tulia`, label: `Tulia` },
    { value: `turkey`, label: `Turkey` },
    { value: `tuscola`, label: `Tuscola` },
    { value: `tye`, label: `Tye` },
    { value: `tyler`, label: `Tyler` },
    { value: `uhland`, label: `Uhland` },
    { value: `uncertain`, label: `Uncertain` },
    { value: `union grove`, label: `Union Grove` },
    { value: `union valley`, label: `Union Valley` },
    { value: `universal city`, label: `Universal City` },
    { value: `university park`, label: `University Park` },
    { value: `uvalde`, label: `Uvalde` },
    { value: `valentine`, label: `Valentine` },
    { value: `valley mills`, label: `Valley Mills` },
    { value: `valley view`, label: `Valley View` },
    { value: `van`, label: `Van` },
    { value: `van alstyne`, label: `Van Alstyne` },
    { value: `van horn`, label: `Van Horn` },
    { value: `vega`, label: `Vega` },
    { value: `venus`, label: `Venus` },
    { value: `vernon`, label: `Vernon` },
    { value: `victoria`, label: `Victoria` },
    { value: `vidor`, label: `Vidor` },
    { value: `vinton`, label: `Vinton` },
    { value: `volente`, label: `Volente` },
    { value: `von ormy`, label: `Von Ormy` },
    { value: `waco`, label: `Waco` },
    { value: `waelder`, label: `Waelder` },
    { value: `wake village`, label: `Wake Village` },
    { value: `waller`, label: `Waller` },
    { value: `wallis`, label: `Wallis` },
    { value: `walnut springs`, label: `Walnut Springs` },
    { value: `warren city`, label: `Warren City` },
    { value: `waskom`, label: `Waskom` },
    { value: `watauga`, label: `Watauga` },
    { value: `waxahachie`, label: `Waxahachie` },
    { value: `weatherford`, label: `Weatherford` },
    { value: `webberville`, label: `Webberville` },
    { value: `webster`, label: `Webster` },
    { value: `weimar`, label: `Weimar` },
    { value: `weinert`, label: `Weinert` },
    { value: `weir`, label: `Weir` },
    { value: `wellington`, label: `Wellington` },
    { value: `wellman`, label: `Wellman` },
    { value: `wells`, label: `Wells` },
    { value: `weslaco`, label: `Weslaco` },
    { value: `west`, label: `West` },
    { value: `westbrook`, label: `Westbrook` },
    { value: `west columbia`, label: `West Columbia` },
    { value: `westlake`, label: `Westlake` },
    { value: `west lake hills`, label: `West Lake Hills` },
    { value: `weston`, label: `Weston` },
    { value: `weston lakes`, label: `Weston Lakes` },
    { value: `west orange`, label: `West Orange` },
    { value: `westover hills`, label: `Westover Hills` },
    { value: `west tawakoni`, label: `West Tawakoni` },
    { value: `west university place`, label: `West University Place` },
    { value: `westworth village`, label: `Westworth Village` },
    { value: `wharton`, label: `Wharton` },
    { value: `wheeler`, label: `Wheeler` },
    { value: `white deer`, label: `White Deer` },
    { value: `whiteface`, label: `Whiteface` },
    { value: `whitehouse`, label: `Whitehouse` },
    { value: `white oak`, label: `White Oak` },
    { value: `whitesboro`, label: `Whitesboro` },
    { value: `white settlement`, label: `White Settlement` },
    { value: `whitewright`, label: `Whitewright` },
    { value: `whitney`, label: `Whitney` },
    { value: `wichita falls`, label: `Wichita Falls` },
    { value: `wickett`, label: `Wickett` },
    { value: `willis`, label: `Willis` },
    { value: `willow park`, label: `Willow Park` },
    { value: `wills point`, label: `Wills Point` },
    { value: `wilmer`, label: `Wilmer` },
    { value: `wilson`, label: `Wilson` },
    { value: `wimberley`, label: `Wimberley` },
    { value: `windcrest`, label: `Windcrest` },
    { value: `windom`, label: `Windom` },
    { value: `windthorst`, label: `Windthorst` },
    { value: `winfield`, label: `Winfield` },
    { value: `wink`, label: `Wink` },
    { value: `winnsboro`, label: `Winnsboro` },
    { value: `winona`, label: `Winona` },
    { value: `winters`, label: `Winters` },
    { value: `wixon valley`, label: `Wixon Valley` },
    { value: `wolfe city`, label: `Wolfe City` },
    { value: `wolfforth`, label: `Wolfforth` },
    { value: `woodbranch`, label: `Woodbranch` },
    { value: `woodcreek`, label: `Woodcreek` },
    { value: `woodloch`, label: `Woodloch` },
    { value: `woodsboro`, label: `Woodsboro` },
    { value: `woodson`, label: `Woodson` },
    { value: `woodville`, label: `Woodville` },
    { value: `woodway`, label: `Woodway` },
    { value: `wortham`, label: `Wortham` },
    { value: `wylie`, label: `Wylie` },
    { value: `yantis`, label: `Yantis` },
    { value: `yoakum`, label: `Yoakum` },
    { value: `yorktown`, label: `Yorktown` },
    { value: `zavalla`, label: `Zavalla` },

]

module.exports = {
    cityList
}; 