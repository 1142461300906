import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import { api } from '../utils/Api'
import Spinner from '../layout/Spinner'

const CityDeliveryFee = () => {

    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getCityList()
    }, [])

    const getCityList = async () => {
        const url = api + `/deliveryfee`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setCities(data)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const DrawList = () => {
        if (cities.length > 0) {
            return cities.map((city) => {
                return <tr key={city._id}>
                    <td>
                        {city.city.charAt(0).toUpperCase() + city.city.slice(1)}
                    </td>
                    <td>
                        {city.unitPrice}
                    </td>
                    <td>
                        <Link to={`/citydeliveryfee/${city._id}`}>edit</Link>
                    </td>
                </tr>
            })
        } else {
            return (
                <tr>
                    <td>No cities yet</td>
                </tr>
            )
        }
    }

    const RenderCities = () => {
        return (
            <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <td>City</td>
                            <td>Unit Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        <DrawList />
                    </tbody>
                </table>
                <Link className='btn btn-success' to='/citynew'>Add City</Link>
            </div>
        )
    }


    return (
        <Layout
            title='Driver Payment Peer Trip'
            description='City List'
            className='container col-md-8 offset-md-2'
        >
            {
                loading ? <Spinner /> : <RenderCities />
            }
        </Layout>
    )
}

export default CityDeliveryFee