import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import client from './client'
import job from './job'
import truck from './truck'
import referral from './referral'
import plant from './plant'
import load from './load'
import project from './project'
import aggregate from './aggregate'
import driver from './driver'
import cog from './cog'
import emails from './emails'
import foreman from './foreman'
import build_development from './buildDevelopment'
import forecast from './forecast'
import target from './target'
import invoice from './invoice'
import loyalty from './loyalty'
import payment from './payment'
import user from './user'
import prospection from './prospection'

export default combineReducers({
    alert,
    auth,
    client,
    job,
    truck,
    referral,
    plant,
    load,
    project,
    aggregate,
    driver,
    cog,
    emails,
    foreman,
    build_development,
    forecast,
    target,
    invoice,
    loyalty,
    payment,
    user,
    prospection
})