import api from '../utils/api'
import { setAlert } from './alert'
import {
    ADD_PROJECT,
    GET_PROJECT,
    GET_PROJECTS,
    UPDATE_PROJECT,
    PROJECT_ERROR
} from './types'

// Create new project
export const createProject = (formData) => async dispatch => {
    try {
        const { data } = await api.post('/project', formData)
        dispatch({
            type: ADD_PROJECT,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PROJECT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// update project attribs
export const updateProject = (projectId, formData) => async dispatch => {
    console.log(formData)
    try {
        const { data } = await api.patch(`/project/${projectId}`, formData)

        dispatch({
            type: UPDATE_PROJECT,
            payload: data
        })

        dispatch(setAlert('Project Updated', 'success'))
    } catch (err) {
        dispatch({
            type: PROJECT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get list of projects
export const getProjectList = () => async dispatch => {
    try {
        const { data } = await api.get(`/allprojects`)
        dispatch({
            type: GET_PROJECTS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PROJECT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get projects for specific customer
export const getProjectsCustomer = id => async dispatch => {
    try {
        const { data } = await api.get(`/projectcustomer/${id}`)
        console.log(data)
        dispatch({
            type: GET_PROJECTS,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PROJECT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get specific project
export const getProject = id => async dispatch => {
    try {
        const { data } = await api.get(`/project/${id}`)
        dispatch({
            type: GET_PROJECT,
            payload: data
        })
    } catch (err) {
        console.error(err.response)
        dispatch({
            type: PROJECT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}