import api from '../utils/api'
import { setAlert } from './alert'
import {
    GET_DRIVERS,
    DRIVER_ERROR
} from './types'


// driver list
export const getDriverList = () => async (dispatch) => {    
    try {
        const { data } = await api.get('/driver/list')
        console.log(data)
        dispatch({
            type: GET_DRIVERS,
            payload: data
        })
    } catch (err) {
        console.err(err.response)
        dispatch({
            type: DRIVER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}
