import React, { Component, Fragment } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import Layout from '../layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCheckSquare, 
    faSearch,
    faPlusSquare 
} from '@fortawesome/free-solid-svg-icons'
import { api } from '../utils/Api'

const Cliente = ({cliente}) => (
    <tr>
        <td>
            <Link to={`/clientsummary/${cliente._id}`}>
                    {cliente.customerNumber + ' ' + cliente.name}
            </Link>
        </td>        
        <td>{cliente.address}</td>
        <td> 
            {
                cliente.contactPhone ? cliente.contactPhone : ''
            } 
        </td>
        <td>
            {
                cliente.customerType
            }
        </td>
        <td>
            { cliente.classification }
        </td>
        <td>
            {
                cliente.creditRequested && <FontAwesomeIcon icon={faCheckSquare} />
            }
        </td>
        <td>
            {
                cliente.creditHold && <FontAwesomeIcon icon={faCheckSquare} />
            }
        </td>
        <td>
            {
                cliente.isProspect && <FontAwesomeIcon icon={faCheckSquare} />
            }
        </td>
        <td><Link to={'/client/'+cliente._id}>Edit</Link> </td>
    </tr>
)

export default class ListaCliente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clients: [],
            term: '',
            loading: true
        }
    }

    inputChangeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    getClientes = async () => {
        const url = api + '/allcustomers'

        try {
            const { data } = await axios.get(url)
            this.setState({
                loading: false,
                clients: data
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }

    searchClients = async () => {
        const url = api + `/customers/search?term=${this.state.term}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url) 

            this.setState({
                loading: false,
                term: '',
                clients: data
            })
        } catch (err) {
            console.error(err)
            return null
        }
    }
    
    componentDidMount() {
        this.getClientes()
    }

    renderLoading() {
        return <Spinner />
    }

    losClientes() {
        return this.state.clients.map((currentClient, i) => {
            return <Cliente cliente={currentClient} key={i} />
        })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.renderLoading()
        this.searchClients()
    }

    renderClients() {
        return (
            <Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className='form-group row'>
                        <div className='col-sm-4'>
                            <input
                                type='text'
                                name='term'
                                size='10'
                                className="form-control"
                                placeholder="Search Client..."
                                onChange={this.inputChangeHandler}
                                value={this.state.term}
                            />
                        </div>   
                        <div className='col-sm-2'>
                            <button onClick={this.onSubmit} className='btn btn-primary'>
                                <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                            </button>
                        </div>
                        <div className='col-sm-3'>
                            <input
                                type='text'
                                name='reset'
                                defaultValue='All'
                                className='btn btn-info'
                                onClick={this.getClientes}
                                readOnly
                            />                            
                        </div>
                        <div className='col-sm-3'>                            
                            <Link 
                                className='btn btn-success' 
                                to='/newclient'
                            >  
                                <FontAwesomeIcon icon={faPlusSquare} />&nbsp;New
                            </Link>
                        </div>                                            
                    </div>                    
                </form>
                <div style={{ fontSize: '13px' }}>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Customer</th>                            
                                <th>Address</th>
                                <th>Phone</th>
                                <th>Type</th>
                                <th>Classification</th>
                                <th>Credit Hold</th>
                                <th>Request Credit?</th>
                                <th>Phone Quote?</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.losClientes()}
                        </tbody>
                    </table>
                </div>                
            </Fragment>
        )        
    }

    render() {
        return (
            <Layout
                title='Client'
                description='Client List'
            >   
                {                     
                    this.state.loading ?
                    this.renderLoading()
                    : this.renderClients() 
                }
            </Layout>
        )
    }
}
