import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
const { Text } = Typography

const ForemanList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [foremanList, setForemanList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return <Flex justify='space-between'>
                    {
                        (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') ? 
                        <Button 
                        type="primary" 
                        icon={<EditFilled twoToneColor={'white'}/>} 
                        onClick={e=> history.push(`/foreman/${record.id}`)}
                        />
                        :
                        ''
                    }
                </Flex>
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'corporateSales',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
    ]

    useEffect(() => {
        getForemanList('')
    }, [])
    
    const getForemanList = async(term) => {
        setLoading(true)

        const url = api + `/foremanlist?term=${term}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    name: `${item.firstName} ${item.lastName}`,
                    email: item.email,
                    phone: item.phone,
                    address: item.address
                })
            })
            setForemanList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewForeman = () => {
        history.push('/newforeman')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || localStorage.getItem('email') === 'eliud@gmail.com') &&
                <Button
                    onClick={addNewForeman}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                >
                    New Foreman
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={foremanList}
            />
        </Flex>
    }

    const onSearch = (value, _e, info) => {
        if(info?.source === 'input'){
            getForemanList(value)
        }
    } 

    const RenderSearch = () => {
        return <CustomSearch 
            onSearch={onSearch}
            loading={loading}
        />
    }

    return (
        <Layout
            title='Foreman'
            description='Foreman List'
        >
            <Flex vertical gap={'large'}>
                <RenderSearch />
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default ForemanList