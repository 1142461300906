import React, { useEffect, useState } from 'react'
import { toCurrency } from '../utils/Convert'
import PaymentTypeLoadPicker from '../paymenttype/PaymentTypeLoadPicker'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { api } from '../utils/Api'
import TaxPicker from '../invoice/TaxPicker'
import Modal from 'react-bootstrap/Modal'

const PaymentJob = ({ jobId, pcb }) => {
    const [total, setTotal] = useState(0)   
    const [material, setMaterial] = useState(0) 
    const [taxAmount, setTaxAmount] = useState(0)
    const [sumPayment, setSumPayments] = useState(0)
    const [payDate, setPayDate] = useState(new Date())
    const [deposit, setDeposit] = useState(0)
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [fuelSurcharge, setFuelSurcharge] = useState(0)
    const [fuel, setFuel] = useState(0)
    const [trips, setTrips] = useState(0)
    const [balance, setBalance] = useState(0)
    const [pagos, setPagos] = useState([])
    const [update, setUpdate] = useState(false)
    const [first, setFirst] = useState(true)
    const [job, setJob] = useState({})
    const [invoiceId, setInvoiceId] = useState('')
    const [invoiceTax, setInvoiceTax] = useState({label:'State',value:'state'})
    const [tax, setTax] = useState(0)
    const [colorTicket, setColorTicket] = useState(0)
    const [fiberBags, setFiberBags] = useState(0)
    const [sizeBags, setSizeBags] = useState(0)
    const [miscelaneosFee, setMiscelaneosFee] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)
    const [yards, setYards] = useState(0)
    const [companyInfo, setCompanyInfo] = useState([])
    const [showError, setShowError] = useState(false)

    const cardCommission = 0.04 

    const initialState = {
        amount: 0,
        at: payDate,
        type: 'Cash',
        comments: '',
        reference: ''
    }

    const [formData, setFormData] = useState(initialState)

    const {
        amount,
        at, 
        comments,
        type,
        reference,
        paymentNumber
    } = formData

    useEffect(() => {
        const timer = setTimeout(() => {
            getPayments()
            if (first) {
                getJob()
                setFirst(false)
            }
          }, 1000);
          return () => clearTimeout(timer);
    }, [update])

    const getPayments = async () => {
        const url = api + '/payment/' + jobId
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const {data} = await axios.get(url)
            setPagos(data)
            const sumaPago = data.reduce((acc, pago) => acc + pago.amount, 0)
            setSumPayments(sumaPago)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getCompanyInfo = async (job, invoice) => {
        const url = api + '/company'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        try {
            const { data } = await axios.get(url)
            setCompanyInfo(data)
            calculateTotal(job, invoice, data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const updateBalance = (paymentAmount, newPayment) => {
        const sum = parseFloat((sumPayment + parseFloat(paymentAmount)).toFixed(2))
        const totalAmount = parseFloat(total.toFixed(2))

        if(totalAmount - sum >= 0.0){
            addPayment(newPayment) 

            setSumPayments(sum)
            const saldo = totalAmount - sum
            const deposit = sum
            setDeposit(deposit)
            setBalance(saldo)

            const jobChanges = {
                deposit,
                pendingBalance: saldo,         
            }
            
            updateJob(jobChanges)
        }else{
            setShowError(!showError)
        }  
    }

    const addPayment = async (formData) => {
        const url = api + '/payment'
        try {
            const { data } = await axios.post(url, formData)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateJob = async (formData) => {
        const url = api + '/job/' + job._id

        try {
            const { data } = await axios.patch(url, formData)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateInvoice = async (formData) => {
        const url = api + '/invoice/' + invoiceId

        try {
            const { data } = await axios.patch(url, formData)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getJob = async () => {
        const url = api + '/job/' + jobId
        try {
            const {data} = await axios.get(url)
            setJob(data)
            getInvoiceExistance(data)
        } catch(err) {
            console.error(err.message)
            return null
        }
    }

    const getInvoiceExistance = async (job) => {
        const url = api + '/jobinvoice/' + job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setInvoiceId(data._id)
            getCompanyInfo(job, data)
        } catch (err) {
            let quantity = job.loadYD3
            if (quantity === 0) {
                quantity = typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3) : job.targetYD3
            }
            const subtotal = quantity * job.unitPrice
            const invoice = {
                customer: job.customer._id,
                job: job._id,
                psi: job.psi,
                psiDescription: job.psi,
                invoiceNumber: `2${job.number}`,
                invoiceCustomerName: job.customer.customerNumber + ' ' + job.customer.name,
                invoiceAddress: job.customer.address,
                invoiceDate: job.scheduledDate,
                fuelRate: 0,
                deposit: 0,
                rate: job.unitPrice,
                amount: subtotal,
                quantity,
                invoiceTotal: subtotal //no taxes
            }
            createInvoice(invoice, job)
            return null
        }
    }

    const createInvoice = async (invoice, job) => {
        const url = api + '/invoice'
        try {
            const { data } = await axios.post(url, invoice)
            setInvoiceId(data._id)
            getCompanyInfo(job, data)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const calculateTotal = (job, invoice, companyInfo) => {
        let taxPercentaje = 0
        switch (invoice.invoiceTax) {
            case 'state':
                taxPercentaje = companyInfo.stateTax
                setInvoiceTax({ value: 'state', label: 'State' })
                break
            case 'county':
                taxPercentaje = companyInfo.countyTax
                setInvoiceTax({ value: 'county', label: 'County' })
                break
            case 'exempt':
                setInvoiceTax({ value: 'exempt', label: 'Exempt' })
                taxPercentaje = 0
                break
            default:
                taxPercentaje = companyInfo.stateTax
                setInvoiceTax({ value: 'state', label: 'State' })
        }
        setTax(taxPercentaje)
        const yards = job.loadYD3 || job.targetYD3
        setYards(yards)
        const material = job.unitPrice * yards
        setUnitPrice(job.unitPrice)
        setMaterial(material)
        const trips = Math.ceil(yards/10)
        setTrips(trips)
        const fuelSur = invoice.fuelSurcharge ? invoice.fuelRate : 0
        setFuelSurcharge(fuelSur)
        const fuel = fuelSur * trips 
        setFuel(fuel)
        const delivery = invoice.shippingFee || deliveryFee      
        setDeliveryFee(delivery)
        const anticipo = job.deposit || deposit
        setDeposit(anticipo)
        const color = invoice.color ? invoice.colorTicket : 0
        setColorTicket(color)
        const fiber = invoice.fiber ? invoice.fiberBags : 0
        setFiberBags(fiber)
        const size = invoice.size ? invoice.sizeBags : 0
        setSizeBags(size)
        const miscelaneos = invoice.miscelaneos ? invoice.miscelaneosFee : 0
        setMiscelaneosFee(miscelaneos)
        const subtotal = material + fuel  + ((delivery + color + fiber + size + miscelaneos) * trips)
        const taxas = subtotal * taxPercentaje
        setTaxAmount(taxas)
        const total = subtotal + taxas
        let balance = 0
        if(job.pendingBalance || job.pendingBalance === 0){
            balance = job.pendingBalance
        }else{
            balance = total
        }
        setTotal(total)
        setBalance(balance)

    }

    const deletePago = async (id, amount) => {
        const url = api + `/payment/${id}`
        try {
            await axios.delete(url)
            const saldo = balance + amount
            setBalance(saldo)
            const payload = {
                pendingBalance: saldo,
                paid: false,
                paidTime: null
            }
            const invoiceStatusChanges = {
                invoiceStatus: 'new'
            }
            updateJob(payload)
            updateInvoice(invoiceStatusChanges)
            setUpdate(!update)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updatePagos = (type, value) => {
        const valor = parseFloat(value)
        let subtotal = material
        switch (type) {
            case 'deliveryFee':
                if(value !== ''){
                    subtotal += (valor * trips) + (fuelSurcharge * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setDeliveryFee(valor)
                }else{
                    subtotal += (fuelSurcharge * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setDeliveryFee(0)
                }
                break;
            case 'fuelSurcharge':
                if(value !== ''){
                    subtotal += (valor * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setFuelSurcharge(valor)
                    setFuel(valor*trips)
                }else{
                    subtotal += (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setFuelSurcharge(0)
                    setFuel(0)
                }
                break;
            case 'colorTicket':
                if(value !== ''){
                    subtotal += (valor * trips) + (deliveryFee * trips) + (fuelSurcharge * trips) + (fiberBags * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setColorTicket(valor)
                }else{
                    subtotal += (deliveryFee * trips) + (fuelSurcharge * trips) + (fiberBags * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setColorTicket(0)
                }
                break;
            case 'fiberBags':
                if(value !== ''){
                    subtotal += (valor * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fuelSurcharge * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setFiberBags(valor)
                }else{
                    subtotal += (deliveryFee * trips) + (colorTicket * trips) + (fuelSurcharge * trips) + (sizeBags * trips) + (miscelaneosFee * trips)
                    setFiberBags(0)
                }
                break;
            case 'sizeBags':
                if(value !== ''){
                    subtotal += (valor * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (fuelSurcharge * trips) + (miscelaneosFee * trips)
                    setSizeBags(valor)
                }else{
                    subtotal += (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (fuelSurcharge * trips) + (miscelaneosFee * trips)
                    setSizeBags(0)
                }
                break;
            case 'miscelaneosFee':
                if(value !== ''){
                    subtotal += (valor * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (fuelSurcharge * trips)
                    setMiscelaneosFee(valor)
                }else{
                    subtotal += (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (fuelSurcharge * trips)
                    setMiscelaneosFee(0)
                }
                break;
            case 'unitPrice':
                if(value !== ''){
                    setUnitPrice(valor)
                    subtotal = valor * yards
                    setMaterial(subtotal)
                    subtotal += (miscelaneosFee * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (fuelSurcharge * trips)
                }else{
                    setUnitPrice(0)
                    setMaterial(0)
                    subtotal = (miscelaneosFee * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (fuelSurcharge * trips)
                }
                const taxCalc = subtotal * tax
                const subTax = subtotal + taxCalc
                const balanceCalc = subTax - sumPayment
                setTaxAmount(taxCalc)
                setTotal(subTax)
                setBalance(balanceCalc)
                break;
            default:
                break;
        }
        if(type !== 'unitPrice'){
            const taxCalc = subtotal * tax
            const subTax = subtotal + taxCalc
            const balanceCalc = subTax - sumPayment
            setTaxAmount(taxCalc)
            setTotal(subTax)
            setBalance(balanceCalc)
        }
        
    }

    const saveChanges = () => {
        let paid = false
        let paidTime = null
        if(unitPrice > 0 && balance === 0){
            paid = true
            paidTime = new Date()
            const invoiceStatusChanges = {
                invoiceStatus: 'paid'
            }
            updateInvoice(invoiceStatusChanges)
        }

        const jobChanges = {
            deliveryFee,
            fuelSurcharge,
            fuel,
            deposit,
            pendingBalance: balance,    
            unitPrice,
            taxAmount,
            paid,
            paidTime,       
        }

        let paymentsInvoice = []
        pagos.map((payment) => {
            const item = {
                amount: payment.amount,
                type: payment.type,
                reference: payment.reference,
                at: payment.at,
                comments: payment.comments,
                paymentNumber: payment.paymentNumber
            }
            paymentsInvoice.push(item)
        })

        const invoiceChanges = {
            shippingFee: deliveryFee,
            shipping: deliveryFee === 0 ? false : true,
            fuelRate: fuelSurcharge,
            fuelSurcharge: fuelSurcharge === 0 ? false : true,
            colorTicket,
            color: colorTicket === 0 ? false : true,
            fiberBags,
            fiber: fiberBags === 0 ? false : true,
            sizeBags,
            size: sizeBags === 0 ? false : true,
            miscelaneosFee,
            miscelaneos: miscelaneosFee === 0 ? false : true,
            payments: paymentsInvoice,
            deposit: deposit,
            taxAmount,
            invoiceTotal: total,
            invoiceTax: invoiceTax.value,
            rate: unitPrice,
            quantity: yards,
            balance
        }
        updateJob(jobChanges)
        updateInvoice(invoiceChanges)
        pcb(balance, taxAmount, unitPrice, invoiceTax.label, paid, paidTime)
    }

    const onChangePaymentType = selectedOption => {
        if (selectedOption) {
            setFormData({...formData, type: selectedOption.label})
        }
    } 

    const submit = async (e) => {
        e.preventDefault()

        const url = api + '/customerpaymentnumber'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` } 

        try {
            
        
            const { data } = await axios.get(url)
            let customerPaymentNumber = data.customerPaymentNumber.toString()
            if(customerPaymentNumber !== null){
                let pago = formData.amount
                if (type === 'Credit Card') {
                    pago = amount * (1 + cardCommission)
                }

                const nuevo = {
                    amount: pago,
                    reference: reference,
                    job: job._id,
                    comments: comments,
                    type: type,
                    at: payDate,
                    paymentNumber: customerPaymentNumber,
                    createdBy:localStorage.getItem('userId')       
                }

                updateBalance(pago, nuevo)
                setUpdate(!update)
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onChangeTax = (selectedTax) => {
        const impuesto = selectedTax.value
        setInvoiceTax(selectedTax)

        let taxPercentaje = 0
        switch (impuesto) {
            case 'state':
                taxPercentaje = companyInfo.stateTax
                break
            case 'county':
                taxPercentaje = companyInfo.countyTax
                break
            case 'exempt':
                taxPercentaje = 0
                break
            default:
                taxPercentaje = companyInfo.stateTax
        }
        setTax(taxPercentaje)

        const subtotal = material + (miscelaneosFee * trips) + (deliveryFee * trips) + (colorTicket * trips) + (fiberBags * trips) + (sizeBags * trips) + (fuelSurcharge * trips)
        const taxCalc = subtotal * taxPercentaje
        const subTax = subtotal + taxCalc
        const balanceCalc = subTax - sumPayment
        setTaxAmount(taxCalc)
        setTotal(subTax)
        setBalance(balanceCalc)
    }

    const toggleError = () => {
        setShowError(!showError)
    }

    const DrawList = () => {
        return pagos.map((pago, i) => {
            return <tr key={i}>
                <td>{new Date(pago.at).toLocaleDateString()}</td>
                <td style={{ textAlign: "right", fontWeight: "bold" }}>{toCurrency(pago.amount,2)}</td>
                    <td>{pago.type} </td>
                    <td> {pago.reference || 'NA'} </td>
                    <td> {pago.comments || 'NA'} </td>
                    <td><button className='btn btn-danger' onClick={() => deletePago(pago._id, pago.amount)}>-</button></td>
            </tr>
        })
    }

    const PaymentTable = () => (
        <table className='table'>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Reference</th>
                    <th>Comments</th>
                    <th></th>                    
                </tr>
            </thead>
            <tbody>
                <DrawList />
            </tbody>
        </table>
    )

    const Header = () => (
        <div>
            <h4>Job: {job.number}</h4>           
            <table>
                <tbody>
                    <tr>
                        <td>Tax</td>
                        <td>
                            <TaxPicker action={onChangeTax} value={invoiceTax} />
                        </td>
                        <td style={{ width: "30%" }}></td>
                        <td>Price</td>
                        <td>
                            <input type='number'
                                name='unitPrice'
                                className='form-control'
                                step='0.01'
                                min='0'
                                defaultValue={unitPrice}
                                onBlur={(e) => updatePagos('unitPrice', e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Material</td>
                        <td>
                            <input type='text'
                                value={toCurrency(material,2)}
                                className='form-control'
                                readOnly
                                style={{ textAlign: "right", fontWeight: "bold" }}
                            />
                        </td>
                        <td style={{ width: "30%" }}></td>
                        <td>Delivery Fee</td>
                        <td>
                            <input type='number'
                                name='deliveryFee'
                                className='form-control'
                                step='0.01'
                                min='0'
                                defaultValue={deliveryFee}
                                onBlur={(e) => updatePagos('deliveryFee', e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Tax Amount</td>
                        <td>
                            <input type='text'
                                value={toCurrency(taxAmount,2)}
                                className='form-control'
                                readOnly
                                style={{ textAlign: "right", fontWeight: "bold" }}
                            />
                        </td>
                        <td></td>
                        <td>Fuel Surcharge</td>
                        <td>
                            <input type='number'
                                name='fuelSurcharge'  
                                className='form-control'  
                                step='0.01' 
                                defaultValue={fuelSurcharge} 
                                onBlur={(e) => updatePagos('fuelSurcharge', e.target.value)}                          
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Job Total</td>
                        <td>
                            <input type='text'
                                value={toCurrency(total,2)}
                                className='form-control'
                                readOnly
                                style={{ textAlign: "right", fontWeight: "bold" }}
                            />
                        </td>
                        <td></td>
                        <td>Color</td>  
                        <td>
                            <input type='number'
                                className='form-control'
                                name='colorTicket'
                                min='0'
                                step='0.1'
                                defaultValue={colorTicket}
                                onBlur={(e) => updatePagos('colorTicket', e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Payments</td>
                        <td>
                            <input type='text'
                                readOnly
                                className='form-control'
                                value={toCurrency(sumPayment,2)}
                                style={{ textAlign: "right", fontWeight: "bold" }}
                            />
                        </td>
                        <td></td>
                        <td>Fiber</td>  
                        <td>
                            <input type='number'
                                className='form-control'
                                name='fiberBags'
                                min='0'
                                step='0.1'
                                defaultValue={fiberBags}
                                onBlur={(e) => updatePagos('fiberBags', e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Balance</td>
                        <td>
                            <input type='text'
                                readOnly
                                className='form-control'
                                value={toCurrency(balance,2)}
                                style={{ textAlign: "right", fontWeight: "bold" }}
                            />
                        </td>
                        <td></td>
                        <td>Size</td>  
                        <td>
                            <input type='number'
                                className='form-control'
                                name='sizeBags'
                                min='0'
                                step='0.1'
                                defaultValue={sizeBags}
                                onBlur={(e) => updatePagos('sizeBags', e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Miscelaneos</td>  
                        <td>
                            <input type='number'
                                className='form-control'
                                name='miscelaneosFee'
                                min='0'
                                step='0.1'
                                defaultValue={miscelaneosFee}
                                onBlur={(e) => updatePagos('miscelaneosFee', e.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

    return (
        <div>
            <Header />
            {
                pagos.length > 0 && <PaymentTable />
            }
            <form className='form' onSubmit={submit}>
                <div className='form-group row'>
                    <div className='col-sm-3'>
                        <input type='number'
                            required
                            step='0.01'
                            className='form-control'            
                            name='amount'
                            placeholder='Amount'
                            width='4'
                            onChange={onChange}                        
                        />
                    </div>
                    <div className='col-sm-3'>
                        <DatePicker 
                            selected={payDate}                
                            onChange={(date) => setPayDate(date)}                                                
                        />
                    </div>
                    <div className='col-sm-3'>
                        <PaymentTypeLoadPicker action={onChangePaymentType} />
                    </div>
                    <div className='col-sm-3'>
                        {
                            type !== 'Cash' &&
                            <input
                                type='text'
                                name='reference'
                                placeholder='Reference'
                                amount={reference}
                                onChange={onChange}
                                className='form-control'
                            />
                        }  
                    </div> 
                    <div className='col-sm-3'>
                        <input
                            type='text'
                            name='comments'
                            placeholder='Comments'
                            onChange={onChange}
                            className='form-control'
                        />
                    </div>
                    <div className='col-sm-3'>
                        <button className="btn btn-success">+</button>
                    </div>                    
                </div>
            </form>
            <div>
                <button className='btn btn-primary' onClick={saveChanges} >Save</button> 
            </div>
            <Modal
                    show={showError}
                    animation={false}
                    onHide={toggleError}
                    size='sm'
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Payment can't be bigger than actual balance</p>
                    </Modal.Body>
                </Modal>
        </div>
    )
}

export default PaymentJob